

	import {Options, Vue} from "vue-property-decorator";
	import ClinicLogo from "@/views/patient_user/clinic/components/ClinicLogo.vue";
	import WhitelabelPrimaryLogo from "@/views/public/whitelabel/components/WhitelabelPrimaryLogo.vue";

	@Options({components: {WhitelabelPrimaryLogo, ClinicLogo}})
	export default class VirtualCareBanner extends Vue
	{
		get titleText()
		{
			return this.$mhat("VirtualCareBanner.TitleText");
		}

		get messageText()
		{
			return this.$mhat("VirtualCareBanner.MessageText");
		}
	}
