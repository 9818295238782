
	import { Options, Vue, Prop, Watch } from "vue-property-decorator";
	import uuidv4 from "uuid/v4";
	import { ButtonColor, ButtonColorPattern, ButtonSize } from "./types";
	import {IonButton} from "@ionic/vue";

	@Options({components: {IonButton}, emits: ["click"]})
	export default class BaseButton extends Vue
	{
		// ==========================================================================
		// Properties
		// ==========================================================================

		@Prop({type: Boolean}) disabledStyle: boolean;
		@Prop({type: Boolean}) disabled: boolean;
		@Prop({type: Boolean}) iconOnly: boolean;
		@Prop({type: Boolean}) noTextTransform: boolean;
		@Prop({type: String, default: "button"}) type: string;

		@Prop({type: String}) buttonRadius: string;
		@Prop({type: String}) buttonLRPadding: string;

		@Prop({type: Boolean}) small: boolean; // deprecated
		@Prop({type: Boolean}) primary: boolean; // deprecated
		@Prop({type: String, default: ButtonColor.PRIMARY}) color: ButtonColor;
		@Prop({type: String, default: ButtonColorPattern.DEFAULT}) pattern: ButtonColorPattern;
		@Prop({type: String, default: ButtonSize.DEFAULT}) size: ButtonSize;

		public newKey = uuidv4();

		@Watch("disabled")
		onDisabledChange()
		{
			this.newKey = uuidv4();
		}

		public onClick()
		{
			if (!this.disabled)
			{
				this.$emit("click");
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get isSubmitButton()
		{
			return this.type === "submit";
		}

		get buttonClasses()
		{
			const {disabledStyle, iconOnly, noTextTransform, disabled, color, pattern} = this;
			const oldPrimary = this.primary;
			return {
				disabledStyle,
				"icon-only": iconOnly,
				"no-text-transform": noTextTransform,
				"button-disabled": disabled, // ionic class that doesn't seem to get applied properly sometimes

				// colour patterns
				colored: pattern === ButtonColorPattern.COLORED,
				"dark-hover": pattern === ButtonColorPattern.DARK_HOVER,
				transparent: pattern === ButtonColorPattern.TRANSPARENT,
				fill: pattern === ButtonColorPattern.FILL || oldPrimary,
				"fill-light": pattern === ButtonColorPattern.FILL_LIGHT,
				"fill-dark": pattern === ButtonColorPattern.FILL_DARK,
				"colored-text-border": pattern === ButtonColorPattern.COLORED_TEXT_BORDER,

				primary: color === ButtonColor.PRIMARY || oldPrimary,
				secondary: color === ButtonColor.SECONDARY,
				info: color === ButtonColor.INFO,
				success: color === ButtonColor.SUCCESS,
				warning: color === ButtonColor.WARNING,
				danger: color === ButtonColor.DANGER,
				"greyscale-dark": color === ButtonColor.GREYSCALE_DARK,
				"greyscale-light": color === ButtonColor.GREYSCALE_LIGHT,
			};
		}

		get containerClasses()
		{
			const {size, iconOnly, small} = this;

			return {
				small: size === ButtonSize.SMALL || small,
				"small-n-wide": size === ButtonSize.SMALL_N_WIDE,
				"x-small": size === ButtonSize.X_SMALL,
				"icon-only": iconOnly,
			};
		}

		get buttonStyles(): string
		{
			const style: any = {};

			if (this.buttonRadius)
			{
				style["border-radius"] = this.buttonRadius;
			}

			if (this.buttonLRPadding)
			{
				style["--padding-start"] = this.buttonLRPadding;
				style["--padding-end"] = this.buttonLRPadding;
			}

			return style;
		}
	}
