import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b7a85a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mha-font-body-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, _mergeProps({
    class: "micro-button",
    "button-radius": "4px",
    "button-l-r-padding": _ctx.buttonLRPadding,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 16, ["button-l-r-padding"]))
}