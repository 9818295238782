
	import {Options, Vue, Prop} from "vue-property-decorator";

	import SmallButton from "@/components/Buttons/SmallButton.vue";
	import {NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {UserProfileController} from "../../profile/controllers/UserProfileController";
	import ProfileInputFields from "../../profile/components/ProfileInputFields.vue";
	import {PublicAPI} from "@/lib/services/Api";
	import {ProfileController} from "../../profile/controllers/ProfileController";
	import LoadingQueue from "@/lib/LoadingQueue";
	import ClinicLogo from "@/views/patient_user/clinic/components/ClinicLogo.vue";
	import AppHeader from "@/components/Header/AppHeader.vue";
	import OrganizationStore, {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";
	import OrgImage from "@/components/Organization/OrgImage.vue";
	import modalBaseController from "@/lib/modal/modalBaseController";
	import {modalController} from "@ionic/vue";

	@Options({components: {OrgImage, ClinicLogo, ProfileInputFields, SmallButton, AppHeader}})
	export default class PatientProfileModal extends modalBaseController
	{
		@Prop() clinic: ClinicProfile;
		@Prop() showError: boolean;
		@Prop() loading: boolean;
		@Prop() active: boolean;
		@Prop() handleSubmit: () => void;
		@Prop() handleCancel: () => void;
		@Prop() visibleProfileFields: string[];
		@Prop({default: () => new UserProfileController()}) profileController: ProfileController;
		@Prop({type: Boolean, default: false})
		showInstructionText: boolean;

		public ORG_ASSET_TYPE = ORG_ASSET_TYPE;

		private profileFields: string[] = [];
		private loadingQueue: LoadingQueue = new LoadingQueue();

		beforeUnmount()
		{
			WebNotification.$clear(NotifyEvent.ConnectionErrorMHA);
			WebNotification.$clear(NotifyEvent.NoPatientRecordFound);
		}

		// override
		public async onCreated()
		{
			const modal = await modalController.getTop();
			modal.onDidDismiss().then(() =>
			{
				if (!this.loadingQueue.isLoading && this.handleCancel)
				{
					this.handleCancel();
				}
			});

			this.profileFields = this.visibleProfileFields;
			if (!this.profileFields)
			{
				PublicAPI().getClinicRequiredProfileFields(this.clinic.id)
					.then((response) =>
					{
						this.profileFields = response.data;
					},
					);
			}
		}

		public closeModal()
		{
			return modalController.dismiss();
		}

		public submit()
		{
			this.loadingQueue.pushLoadingState();
			this.profileController.onSave()
				.then((response) =>
				{
					// dismiss modal must occur before handleSubmit.
					// this is because all routing is blocked while modals are active.
					this.dismissModal();
					this.handleSubmit();
				})
				.catch((error) =>
				{
					this.dismissModal();
					console.error(error);
				});
		}

		public navigateBack()
		{
			this.closeModal();
		}

		get canSubmit()
		{
			return this.profileController.isRequiredFieldsValid(this.visibleProfileFields) && !this.loadingQueue.isLoading;
		}

		get message()
		{
			let message = this.$mhat("PatientProfileModal.ConfirmationMessage");

			if (this.clinic)
			{
				message = `${this.$mhat("PatientProfileModal.NotRegisteredMessage", {clinicName: this.clinic.name})} ${message}`;
			}

			return message;
		}

		get userProfileIdList()
		{
			return this.profileFields;
		}

		get isWhiteLabel()
		{
			return Boolean(OrganizationStore.organizationProfile);
		}
	}
