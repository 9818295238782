

	import {Options, Vue} from "vue-property-decorator";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import PatientClinicService from "@/lib/clinics/PatientClinic.service";
	import NotificationService from "@/components/Notification/NotificationService";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {NotificationSeverity} from "@/lib/types/Notifier";
	import {ErrorCode} from "@/open_api/generated";

	@Options({components: {}})
	export default class NavigatorBookingLandingPage extends Vue
	{
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;

		public isLoading = false;
		// true / false depending on if the patient has an navigator assigned to them or not (uses Juno MRP system).
		public patientHasNavigatorAssignment = false;

		// ==========================================================================
		// Vue lifecycle
		// ==========================================================================

		public async created(): Promise<void>
		{
			const patientClinicService = new PatientClinicService();
			const fusionClinicId = OrganizationStore.organizationProfile.primary_clinic_id;

			try
			{
				this.isLoading = true;

				if (!(await patientClinicService.isConnectedToClinic(fusionClinicId)))
				{
					await patientClinicService.connectToClinic(fusionClinicId);
				}

				this.patientHasNavigatorAssignment = (await patientClinicService.getClinicProviders(fusionClinicId)).length > 0;
			}
			catch (error)
			{
				if (error instanceof ErrorResponse)
				{
					if (!error.is(ErrorCode.IncompleteProfile))
					{
						NotificationService.notificationDismiss(
							this.$mhat("NavigatorBookingLandingPage.ErrorMessage"),
							error.message,
							NotificationSeverity.Critical);
					}
				}
				else
				{
					NotificationService.genericErrorNotification();
				}
			}
			finally
			{
				this.isLoading = false;
			}
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public goToAqsBooking(): void
		{
			this.$mhaRouterPush(this.Route.AqsBooking.AppointmentType,
				{
					params: {clinicId: OrganizationStore.organizationProfile.primary_clinic_id},
					query: {backRoute: this.Route.Fusion.NavigatorBookingLandingPage, bookAppointment: "true"},
				});
		}

		public toBooking(): void
		{
			this.$mhaRouterPush(this.Route.Booking.Provider,
				{
					params: {clinicId: OrganizationStore.organizationProfile.primary_clinic_id},
				});
		}
	}
