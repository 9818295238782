
	import {Options, Prop} from "vue-property-decorator";
	import {createTimer, Timer} from "@/lib/utils/timer";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import WhitelabelPrimaryLogo from "@/views/public/whitelabel/components/WhitelabelPrimaryLogo.vue";
	import WaitingRoomOverlay from "@/views/patient_user/telehealth/components/WaitingRoomOverlay.vue";
	import {AqsPatientAPI} from "@/lib/services/Api";
	import LoadingQueue from "@/lib/LoadingQueue";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {TelehealthCallEndBehaviour} from "@/open_api/generated";
	import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";
	import {Route} from "@/router/router";

	@Options({components: {CorporateFooter, WhitelabelPrimaryLogo}})
	export default class OnDemandExamRoom extends WaitingRoomOverlay
	{
		public waitingTimer: Timer = null;
		public waitingTime = "";
		private loadingQueue: LoadingQueue = new LoadingQueue();

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		mounted()
		{
			this.waitingTimer = createTimer((time) => this.waitingTime = time, 1000);
			this.waitingTimer.start();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public cancel()
		{
			this.loadingQueue.pushLoadingState();
			WebNotification.$emit({
				event: NotifyEvent.Generic,
				type: NotificationType.Confirm,
				severity: NotificationSeverity.Reminder,
				title: this.$mhat("OnDemandExamRoom.CancelAppointmentTitle"),
				message: this.$mhat("OnDemandExamRoom.CancelAppointmentConfirmationMessage"),
				confirm: {
					message: this.$mhat("OnDemandExamRoom.Yes"),
					callback: async () =>
					{
						try
						{
							await AqsPatientAPI().dequeueQueuedAppointment(
								this.clinicId,
								this.queueId,
								this.queuedAppointmentId);
						}
						finally
						{
							// no matter what happens leave the telehealth session.
							this.loadingQueue.popLoadingState();
							this.$emit("exit");
						}
					},
				},
				dismiss: {
					message: this.$mhat("OnDemandExamRoom.No"),
					callback: () =>
					{
						this.loadingQueue.popLoadingState();
					},
				},
			});
		}

		/**
		 * Go back to the home page
		 */
		public onBackToHome(): void
		{
			const crossFrameService = new CrossFrameCommunicationService();
			if (crossFrameService.hasParentFrame)
			{
				// let any parent frames know we want to go back to the home page
				crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateToHome});
			}
			this.$mhaRouterPush(Route.Home);
		}
	}
