import "array-flat-polyfill";
import "@ionic/core/css/ionic.bundle.css";
import "@webcomponents/webcomponentsjs/webcomponents-bundle.js";
import "@/assets/mha-icons/style.css";
import "@/lib/FB/FBInitialization.js";
import "@/lib/Google/GoogleAnalyticsInit.js";
import "@/router/guards/global/nativeAppVersionGuards";

import {createApp} from "vue";
import {Vue} from "vue-class-component";
import {createI18n} from "vue-i18n";
import {IonicVue} from "@ionic/vue";
import uuidv4 from "uuid/v4";
import App from "./App.vue";
import store from "./lib/vuex/store";
import router, {allRoutes, Route} from "./router/router";
import AuthStore from "@/lib/vuex/auth.store";
import MHABackendLogger from "@/lib/utils/MHABackendLogger";

import FooterBar from "./components/FooterBar.vue";
import HeaderBar from "./components/Header/HeaderBar.vue";
import MobileHeaderBar from "./components/Header/MobileHeaderBar.vue";
import Notifier from "./components/Notification/types/Notifier.vue";
import BaseModal from "./components/Modals/BaseModal.vue";
import BaseButton from "./components/Buttons/BaseButton.vue";
import AppointmentCard from "@/views/patient_user/appointment/components/AppointmentCard.vue";
import RouteButton from "./components/Buttons/RouteButton.vue";
import Calendar from "./components/Calendar/Calendar.vue";
import Toggle from "./components/Inputs/Toggle.vue";
import HeaderLogo from "./components/Header/HeaderLogo.vue";
import BaseInput from "./components/Inputs/BaseInput.vue";
import TextField from "./components/Inputs/TextField.vue";
import SelectMenu from "./components/Inputs/SelectMenu.vue";
import DateSelector from "./components/Inputs/DateSelector.vue";
import MessageInput from "./components/Inputs/MessageInput.vue";
import FileChooser from "./components/Inputs/FileChooser.vue";
import TypingLoader from "./components/TypingLoader.vue";
import SplitView from "./components/Layout/SplitView.vue";
import Dropdown from "./components/Dropdown.vue";
import Checkbox from "./components/Inputs/Checkbox.vue";

import PasswordField from "@/components/Inputs/PasswordField.vue";
import LoaderButton from "@/components/Buttons/LoaderButton.vue";
import CloseButton from "@/components/Buttons/CloseButton.vue";
import CardFeed from "@/components/Layout/CardFeed.vue";

import PageWrapper from "@/components/Layout/PageWrapper.vue";
import ContentWrapper from "@/components/Layout/ContentWrapper.vue";
import ScrollContainer from "@/components/Layout/ScrollContainer.vue";
import EventStateSpinner from "@/components/EventState/EventStateSpinner.vue";
import EventStateSpinnerSearch from "@/components/EventState/EventStateSpinnerSearch.vue";
import ClinicSearchSpinner from "@/components/EventState/ClinicSearchSpinner.vue";
import SmallButton from "@/components/Buttons/SmallButton.vue";
import Services from "@/lib/services/Services";
import FloatingActionButton from "@/components/Buttons/FloatingActionButton.vue";
import ActionButton from "@/components/Buttons/ActionButton.vue";
import ListFilterDropdown from "@/components/Inputs/ListFilterDropdown.vue";
import ModalHeader from "@/components/Header/ModalHeader.vue";
import TabBar from "@/components/Menu/TabBar.vue";
import DeviceInfo from "@/lib/DeviceInfo";
import {RouteType} from "@/router/types";
import {RouteLocation, RouteRecord} from "vue-router";
import {pickerController} from "@ionic/core";
import {ButtonColor, ButtonColorPattern, ButtonSize} from "@/components/Buttons/types";
import {getGlobalPropertiesHash} from "@/router/routingGlobalProperties";
import i18n, {$mhat} from "./i18n";
import moment from "moment";

export const app = createApp(App);

/* Prefer importing components on pages that require them
* over global import here. Global import disrupts code completion */
app.component("BaseButton", BaseButton);
app.component("LoaderButton", LoaderButton);
app.component("CloseButton", CloseButton);
app.component("ActionButton", ActionButton);
app.component("RouteButton", RouteButton);
app.component("SmallButton", SmallButton);

app.component("AppointmentCard", AppointmentCard);
app.component("Toggle", Toggle);
app.component("MobileHeaderBar", MobileHeaderBar);
app.component("FloatingActionButton", FloatingActionButton);
app.component("HeaderLogo", HeaderLogo);
app.component("HeaderBar", HeaderBar);
app.component("ModalHeader", ModalHeader);
app.component("FooterBar", FooterBar);
app.component("PageWrapper", PageWrapper);
app.component("ContentWrapper", ContentWrapper);
app.component("ScrollContainer", ScrollContainer);
app.component("EventStateSpinner", EventStateSpinner);
app.component("EventStateSpinnerSearch", EventStateSpinnerSearch);
app.component("ClinicSearchSpinner", ClinicSearchSpinner);

app.component("Notifier", Notifier);
app.component("Calendar", Calendar);
app.component("Loader", TypingLoader);
app.component("BaseInput", BaseInput);
app.component("TextField", TextField);
app.component("PasswordField", PasswordField);
app.component("SelectMenu", SelectMenu);
app.component("DateSelector", DateSelector);
app.component("MessageInput", MessageInput);
app.component("SplitView", SplitView);
app.component("Dropdown", Dropdown);
app.component("ListFilterDropdown", ListFilterDropdown);
app.component("BaseModal", BaseModal);
app.component("CardFeed", CardFeed);
app.component("Checkbox", Checkbox);
app.component("TabBar", TabBar);

app.config.globalProperties.$logout = function(logoutRoute: string | RouteRecord = Route.Login.Home)
{
	Services.PatientLogin.logout();
	if (logoutRoute)
	{
		this.$mhaRouterPush(logoutRoute);
	}
};

app.config.globalProperties.$isCloudMd = DeviceInfo.isCloudMd;
app.config.globalProperties.$isMobile = DeviceInfo.isMobile();
app.config.globalProperties.$browser = DeviceInfo.getBrowser();
app.config.globalProperties.Route = Route;
app.config.globalProperties.ButtonColor = ButtonColor;
app.config.globalProperties.ButtonColorPattern = ButtonColorPattern;
app.config.globalProperties.ButtonSize = ButtonSize;
app.config.globalProperties.AuthStore = AuthStore;
app.config.globalProperties.$uuid = uuidv4;

app.config.globalProperties.$mhaRouterPush = function(
	_route: string | RouteLocation,
	opts: RouteLocation = null,
	onComplete?,
	onAbort?,
)
{
	let route = _route;

	// merge params with global params hash
	if (opts)
	{
		opts.params = {...opts.params, ...getGlobalPropertiesHash()};
	}

	if (typeof route === "string")
	{
		if (allRoutes.includes(route as string))
		{
			route = {name: route as RouteType, ...opts};
		}
	}
	else
	{
		route.params = {...route.params, ...getGlobalPropertiesHash()};
	}

	if (typeof route === "string" && route.match(/^https:\/\//))
	{
		// route is absolute url. Hard route to location.
		location.href = route;
	}
	else
	{
		this.$router.push(route, onComplete, onAbort);
	}
};

app.config.globalProperties.$mhaRouterReplace = function(
	_route: string | RouteLocation,
	opts: RouteLocation = null,
	onComplete?,
	onAbort?,
)
{
	let route = _route;

	// merge params with global params hash
	if (opts)
	{
		opts.params = {...opts.params, ...getGlobalPropertiesHash()};
	}

	if (typeof route === "string")
	{
		if (allRoutes.includes(route as string))
		{
			route = {name: route as RouteType, ...opts};
		}
	}
	this.$router.replace(route, onComplete, onAbort);
};

// mha i18n translation function. Uses default i18n implementation unless organization overrides text
app.config.globalProperties.$mhat = $mhat;

// Globally set the moment locale based on the browser language
const locale = window.navigator.language || "en";
moment.locale(locale);

Vue.registerHooks([
	"beforeRouteEnter",
	"beforeRouteLeave",
	"beforeRouteUpdate",
]);

MHABackendLogger.init(app);
app.use(IonicVue);
app.use(store);
app.use(router);
app.use(i18n);

router.isReady().then(() =>
{
	app.mount("#app");
});
