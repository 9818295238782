import EventStateSpinnerModal from "@/components/EventState/EventStateSpinnerModal.vue";
import {EventStateSpinnerModalProps} from "@/components/EventState/types";
import {modalController} from "@ionic/vue";

export class EventStateSpinnerModalController
{
	public static presentLoader(props = {
		spinnerModalPropsCall: (): EventStateSpinnerModalProps =>
		{
			return {
				isLoading: true,
			} as EventStateSpinnerModalProps;
		},
		parentCtx: this,
	} as any)
	{
		return this.openModal(props);
	}

	private static openModal(props: any)
	{
		return new Promise((resolve, reject) =>
		{
			modalController.create({
				component: EventStateSpinnerModal,
				componentProps: props,
				showBackdrop: true,
				backdropDismiss: false,
				cssClass: "fullscreen",
			}).then((modal) =>
			{
				modal.present();
				resolve(modal);
			}).catch((error) =>
			{
				reject(error);
			});
		});
	}
}
