import {ScheduleSlotTransfer} from "@/open_api/generated";
import moment, {Moment} from "moment";
import {DATE_FORMAT} from "@/constants";

export class DateUtils
{
	public static daysOfWeek(): string[]
	{
		return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
	}

	public static slotToMoment(slot: ScheduleSlotTransfer): Moment
	{
		return moment(`${slot.date}T${slot.start_time}`);
	}

	public static slotToFriendlyTime(slot: ScheduleSlotTransfer): string
	{
		return this.slotToMoment(slot).format(DATE_FORMAT.H_MM_A);
	}

	public static humanDateTimeString(date: Moment): string
	{
		return date.format(DATE_FORMAT.HUMAN_DATETIME);
	}

	public static humanDateString(date: Moment): string
	{
		return date.format(DATE_FORMAT.DATE_STRING);
	}

	public static humanTimeString(date: Moment): string
	{
		return date.format(DATE_FORMAT.H_MM_A);
	}
}
