import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content-wrapper" }
const _hoisted_2 = { class: "content-body-top-layout-wrapper" }
const _hoisted_3 = { class: "content-footer-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundStyleWrapper = _resolveComponent("BackgroundStyleWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, _mergeProps(_ctx.$attrs, { class: _ctx.contentClass }), {
    default: _withCtx(() => [
      _createVNode(_component_BackgroundStyleWrapper, _mergeProps(_ctx.$attrs, { style: _ctx.contentStyleOverride }), {
        background: _withCtx(() => [
          _renderSlot(_ctx.$slots, "background")
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["content-header-container", _ctx.contentHeaderClass])
            }, [
              _renderSlot(_ctx.$slots, "content-header")
            ], 2),
            (!_ctx.contentFullHeight)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["content-body-container", _ctx.contentBodyClass]),
                  style: _normalizeStyle(_ctx.contentBodyStyle)
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["content-body-container-top", _ctx.contentBodySlotClass])
                    }, [
                      _renderSlot(_ctx.$slots, "content-top")
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["content-body-container-center", _ctx.contentBodySlotClass])
                    }, [
                      _renderSlot(_ctx.$slots, "content-center")
                    ], 2)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["content-body-container-bottom", _ctx.contentBodyBottomSlotClass])
                  }, [
                    _renderSlot(_ctx.$slots, "content-bottom")
                  ], 2)
                ], 6))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["content-body-container", _ctx.contentBodyClass]),
                  style: _normalizeStyle(_ctx.contentBodyStyle)
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["content-body-container-full d-flex flex-col flex-item-grow", _ctx.contentBodySlotClass])
                  }, [
                    _renderSlot(_ctx.$slots, "content")
                  ], 2)
                ], 6)),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "content-footer")
            ])
          ])
        ]),
        _: 3
      }, 16, ["style"])
    ]),
    _: 3
  }, 16, ["class"]))
}