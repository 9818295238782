import {detect} from "detect-browser";
import { LoggingAPI } from "@/lib/services/Api";
import {ErrorCode, ErrorReport, LogMessageLogLevelEnum} from "@/open_api/generated/api";
import moment from "moment";
import {DATE_FORMAT} from "@/constants";
import {LoginToken} from "@/lib/models/LoginToken";
import wasmFile from "source-map/lib/mappings.wasm";
import axios, { AxiosPromise } from "axios";

import sourceMap, {SourceMapConsumer} from "source-map";
import {exists, noNull} from "@/lib/utils/prototype/String";
import {LOGIN_TOKEN_KEY} from "@/lib/vuex/auth.store";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import {App} from "vue";

enum ErrorType {
	Window = "Javascript/Window",
	Vue = "Vue",
	Promise = "Unhandled Promise Rejection",
	Route = "Vue Routing",
}

export default class MHABackendLogger
{
	// any errors in this list will NOT be logged to the backend.
	public static readonly IGNORE_ERROR_TYPES = [
		ErrorCode.Authentication,
		ErrorCode.IncompleteProfile,
	];

	/**
	 * Initialize error handler
	 *
	 * @param vueApp Vue app
	 */
	public static init(vueApp: App)
	{
		// @ts-ignore (Module does not expose it's own *required* initialize function...)
		sourceMap.SourceMapConsumer.initialize({
			"lib/mappings.wasm": wasmFile,
		});

		window.onerror = MHABackendLogger.handleWindowError;
		window.onunhandledrejection = MHABackendLogger.handlePromiseError;
		vueApp.config.errorHandler = MHABackendLogger.handleVueError;
	}

	/**
	 * log a debug message to the backend server
	 * @param message - message to log
	 */
	public static debug(message: string | any): AxiosPromise<void>
	{
		return MHABackendLogger.logToBackend(LogMessageLogLevelEnum.Debug, message);
	}

	/**
	 * log a info message to the backend server
	 * @param message - message to log
	 */
	public static info(message: string | any): AxiosPromise<void>
	{
		return MHABackendLogger.logToBackend(LogMessageLogLevelEnum.Info, message);
	}

	/**
	 * log a warn message to the backend server
	 * @param message - message to log
	 */
	public static warn(message: string | any): AxiosPromise<void>
	{
		return MHABackendLogger.logToBackend(LogMessageLogLevelEnum.Warn, message);
	}

	/**
	 * log a error message to the backend server
	 * @param message - message to log
	 */
	public static error(message: string | any): AxiosPromise<void>
	{
		return MHABackendLogger.logToBackend(LogMessageLogLevelEnum.Error, message);
	}

	/**
	 * Callback for window errors, missing polyfills and other errors that occur outside the vue context.
	 * Errors captured by this handler will propagate out of the function after being handled by this callback
	 *
	 * @param message
	 * @param url
	 * @param line
	 * @param column
	 * @param error
	 */
	public static handleWindowError(message, url, line, column, error)
	{
		MHABackendLogger.mapStack(error.message, error.stack).then((mappedStack) =>
		{
			const errorInfo = {
				error_type: ErrorType.Window,
				message,
				stack_trace: mappedStack,
				location: url,
				extra_info: line + ":" + column,
			};

			console.error("unhandled window error", error);
			MHABackendLogger.reportError(errorInfo, MHABackendLogger.userFromToken());
		});

		return false; // propagate out to any other error handlers, return true instead to stop propagation.
	}

	/**
	 * Callback handler for unhandled promise rejections.
	 *
	 * @param event UnhandledRejectionEvent
	 */
	public static async handlePromiseError(event: PromiseRejectionEvent): Promise<void>
	{
		const err = event.reason as Error;
		const userId = MHABackendLogger.userFromToken();

		// check if error is ignored
		if (err && err instanceof ErrorResponse)
		{
			if (MHABackendLogger.IGNORE_ERROR_TYPES.includes(err.code))
			{
				// error is ignored don't log.
				return;
			}
		}

		let mappedStack = null;
		if (err && err.message && err.stack)
		{
			mappedStack = (await MHABackendLogger.mapStack(err.message, err.stack));
		}

		const errorInfo = {
			error_type: ErrorType.Promise,
			message: MHABackendLogger.decodeEventReason(event.reason),
			stack_trace: mappedStack,
			location: window.location.href,
			extra_info: event.type,
		};

		if (event.reason?.code === ErrorCode.SessionExpired)
		{
			console.warn("session expired", event);
			MHABackendLogger.warn(errorInfo);
		}
		else
		{
			console.error("unhandled promise error", event);
			MHABackendLogger.reportError(errorInfo, userId);
		}
	}

	/**
	 * Callback handler for errors encountered while in the Vue application (ie: in component renders, watchers, etc)
	 * Errors encountered within the Vue application don't propagate out of it. (as of Vue 2.x)
	 *
	 * @param err Vue error
	 * @param vm Vue instance
	 * @param info Additional lifecycle info about when the error was encountered
	 */

	public static handleVueError(err, vm, info)
	{
		console.error(err); // otherwise errors won't appear in the browser console
		const userId = MHABackendLogger.userFromToken();

		MHABackendLogger.mapStack(err.message, err.stack).then((mappedStack) =>
		{
			const errorInfo = {
				error_type: ErrorType.Vue,
				message: err.message,
				stack_trace: mappedStack,
				location: vm.$options.name,
				extra_info: info,
			};

			console.error("unhandled vue error", err, vm, info);
			MHABackendLogger.reportError(errorInfo, userId);
		});
	}

	/**
	 * Callback handler for unmatched routes
	 *
	 * @param to VueRouter Route
	 * @param from VueRouter Route
	 * @param vm Vue instance
	 */
	public static handleRouteError(to, from, vm)
	{
		const errorInfo = {
			error_type: ErrorType.Route,
			message: to.path + " does not match a known route",
			stack_trace: "no stack trace available",
			location: vm.$options.name,
			extra_info: "previous URL: " + from.path,
		};

		const userId = MHABackendLogger.userFromToken();

		console.error("unhandled route error", to, from, vm);
		MHABackendLogger.reportError(errorInfo, userId);
	}

	/**
	 * Post error to server API
	 *
	 * @param errorInfo
	 * @param userName
	 */
	protected static reportError(errorInfo, userName = null)
	{
		const browser = detect();

		const browserInfo = {
			name: browser.name,
			version: browser.version,
			os: browser.os,
		};

		const payload: ErrorReport = {
			user_name: userName,
			date_time: moment().format(DATE_FORMAT.DATETIME),
			error_info: errorInfo,
			browser_info: browserInfo,
		};

		LoggingAPI().reportError(payload).catch(
			(error) =>
			{
				console.error(error);
				// This needs to be caught.
				// If the service is down, the rejection will cause an unhandledPromiseException,
				// which will try (and fail) to post itself, which will cause another unhandledPromiseException, etc...
				// generating an infinite loop.
			});
	}

	private static TRACE_STARTLINE = "\n    ";
	private static LOCATION_REGEX = /\(?http[s]?:\/\/(.+:\d+:\d+)\)?/;

	/**
	 * Attempt to get the current user from local storage.  We don't get it from the UserStore to avoid the possibility
	 * of an infinite loop (an error in the UserStore will cause an error to be thrown, which will error out when asking
	 * the UserStore for the user, etc)
	 */
	private static userFromToken(): string
	{
		let token = null;
		let userId = "unknown";

		if (!navigator.cookieEnabled)
		{
			return userId;
		}

		token = localStorage.getItem(LOGIN_TOKEN_KEY);

		if (token)
		{
			const decoded = new LoginToken(token);

			if (decoded)
			{
				userId = decoded.email;
			}
		}

		return userId;
	}

	/**
	 * Map the provided minified stacktrace to the non-minified version.  Requires that the current script have
	 * a .map file of the same name (name.js --> name.js.map)
	 *
	 * @param reason error reason, used as the first line of the mapped stack trace
	 * @param stackTrace minified trace.
	 */
	private static async mapStack(reason: string, stackTrace: string): Promise<string>
	{
		let mappedStack = reason;
		if (!stackTrace)
		{
			return mappedStack;
		}

		const splitTrace = stackTrace.split("\n").filter(MHABackendLogger.filterNonLocatingLines);
		const filesLinesCols = splitTrace.map(MHABackendLogger.processStackLine);

		// Cache map files locally to prevent re-downloading mapping data from previous loop iterations.
		const fileData: any = {};

		for (const fileLineCol of filesLinesCols)
		{
			try
			{
				const mapFileUrl = document.location.protocol + "//" + fileLineCol.file + ".map";

				if (!fileData.mapFileUrl)
				{
					const mapFile = await axios.get(mapFileUrl);
					fileData.mapFileUrl = mapFile.data;
				}

				// @ts-ignore
				const orig = await sourceMap.SourceMapConsumer.with(fileData.mapFileUrl, null, (consumer) =>
				{
					return consumer.originalPositionFor({line: fileLineCol.line, column: fileLineCol.col});
				});

				if (!orig.source || orig.source.includes("node_modules"))
				{
					continue;
				}

				mappedStack += MHABackendLogger.prettyFormat(orig, fileLineCol);
			}
			catch (error)
			{
				mappedStack += MHABackendLogger.prettyFormat(null, fileLineCol);
			}
		}

		return mappedStack;
	}

	/**
	 * Helper function.  Extracts column and line information.
	 *
	 * @param stackLine
	 */
	private static processStackLine(stackLine): any
	{
		const matches = stackLine.match(MHABackendLogger.LOCATION_REGEX);

		// Javascript regex syntax.  First index is the full match, additional indices are specific capturing groups
		if (matches.length !== 2)
		{
			return {col: null, line: null, trace: stackLine.trim()};
		}
		else
		{
			const fileColLine = matches[1].split(":");
			return {
				file: fileColLine[0],
				line: parseInt(fileColLine[1], 10),
				col: parseInt(fileColLine[2], 10),
				trace: stackLine.trim(),
			};
		}
	}

	/**
	 * Helper function.  Format new stacktrace line.
	 *
	 * @param sourceInfo original stack parameters after attempt at mapping
	 * @param minifiedInfo minified stack parameters
	 */
	private static prettyFormat(sourceInfo, minifiedInfo)
	{
		if (!sourceInfo || !sourceInfo.source || !exists(sourceInfo.line) || !exists(sourceInfo.column))
		{
			return `${MHABackendLogger.TRACE_STARTLINE}${minifiedInfo.trace} << unable to map line >>`;
		}

		let source = sourceInfo.source;
		const webPackPrefix = "webpack://";

		if (source.toLowerCase().startsWith(webPackPrefix))
		{
			source = source.slice(webPackPrefix.length, source.length);
		}

		sourceInfo.name = !sourceInfo.name ? "" : sourceInfo.name;

		return `${MHABackendLogger.TRACE_STARTLINE}at ${sourceInfo.name} (${source}:${sourceInfo.line}:${sourceInfo.column})`;
	}

	/**
	 * Helper method.  Filter parameter to remove lines which don't contain error location information.
	 * @param sourceLine
	 */
	private static filterNonLocatingLines(sourceLine)
	{
		return sourceLine && MHABackendLogger.LOCATION_REGEX.test(sourceLine);
	}

	/**
	 * Post a log message to the backend
	 * @param logLevel - the level at which the message should be posted
	 * @param error - the message or error object to post
	 */
	private static logToBackend(logLevel: LogMessageLogLevelEnum, error: string | any): AxiosPromise<void>
	{
		if (error instanceof String)
		{
			return LoggingAPI().log({log_level: logLevel, log_message: error as string});
		}
		else if (error instanceof Error)
		{
			MHABackendLogger.mapStack(error.message, error.stack).then((mappedStack) =>
			{
				return LoggingAPI().log({log_level: logLevel, log_message: error.name + ": " + mappedStack});
			});
		}
		else
		{
			// just in case there is some other object
			return LoggingAPI().log({log_level: logLevel, log_message: MHABackendLogger.decodeEventReason(error)});
		}
	}

	/**
	 * convert event reason to a string
	 * @param reason - the reason to convert
	 */
	private static decodeEventReason(reason: any): string
	{
		if (reason)
		{
			if (reason instanceof String)
			{
				return reason.toString();
			}
			else
			{
				// convert to json (1 level deep)
				return JSON.stringify(reason, (key, value) =>
				{
					return key && value && typeof value !== "number" ? "" + value : value;
				});
			}
		}
		return "undefined or null error";
	}
}
