

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {LabelPosition} from "@/components/Inputs/types";

	@Options({})
	export default class InputLayout extends Vue
	{
		@Prop({type: Boolean, default: false}) hideLabel: boolean;
		@Prop({type: Boolean, default: false}) hideValidationMessage: boolean;
		@Prop({type: String}) label: string;
		@Prop({type: String}) forInputId: string;
		@Prop({type: String}) labelColor: string;
		@Prop({type: Object}) labelStyle: any;
		@Prop({type: String, default: LabelPosition.TOP}) public labelPosition: LabelPosition;
		@Prop({type: Boolean, default: true}) isValid: boolean;
		@Prop({type: String, default: "*Invalid Format"}) validationMessage: string;
		@Prop({type: Boolean}) disabled: boolean;
		@Prop({type: Boolean}) required: boolean;
		@Prop({type: Boolean}) compact: boolean;
		@Prop({type: Boolean}) noPadding: boolean;
		@Prop({type: Boolean}) pristine: boolean;
		@Prop({type: Boolean}) noStyle: boolean;
		@Prop({type: Boolean}) error: boolean;

		public onLabelClicked()
		{
			this.$emit("click");
		}

		get labelClasses()
		{
			const {disabled, compact, noPadding, required, noStyle, isValid, error, pristine} = this;
			return {
				disabled,
				compact,
				nopadding: noPadding,
				nostyle: noStyle,
				required,
				valid: isValid,
				invalid: !isValid,
				pristine,
				error,
			};
		}

		get labelTop(): boolean
		{
			return this.labelPosition === LabelPosition.TOP;
		}

		get labelRight(): boolean
		{
			return this.labelPosition === LabelPosition.RIGHT;
		}
	}

