

	import {Options, Vue} from "vue-property-decorator";

	import ProfileHeaderBar from "./components/ProfileHeaderBar.vue";
	import ProfileControlButtons from "./components/ProfileControlButtons.vue";
	import {UserProfileController} from "./controllers/UserProfileController";
	import ProfileInputFields from "./components/ProfileInputFields.vue";
	import {presentIonLoader, profileSavedToastOpts} from "@/lib/utils/helpers/ionic";
	import {ProfileController} from "./controllers/ProfileController";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

	@Options({components: {ProfileInputFields, ProfileHeaderBar, ProfileControlButtons}})
	export default class AccountHome extends Vue
	{
		controller: ProfileController = new UserProfileController();
		profileHasChanges = false;

		public async onSave()
		{
			const saveMethod = this.controller.onSave.bind(this.controller);
			const toastOpts = profileSavedToastOpts;

			await presentIonLoader.call(this, saveMethod, {toastOpts});
			this.profileHasChanges = false;
		}

		public onProfileChange()
		{
			this.profileHasChanges = true;
		}

		public onCancel()
		{
			this.$mhaRouterPush(this.Route.Home);
		}

		public allFieldsValid()
		{
			return this.controller.isFieldsValid();
		}
	}
