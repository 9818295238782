import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6aa72363"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar-displays" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalendarDays = _resolveComponent("CalendarDays")!

  return (_ctx.month)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({ calendar: true, disabled: _ctx.disabled})
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_CalendarDays, {
            onDate: _ctx.handleDateSelect,
            month: _ctx.month,
            "active-dates": _ctx.activeDates
          }, null, 8, ["onDate", "month", "active-dates"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}