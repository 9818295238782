
	import { Options } from "vue-property-decorator";
	import WaitingRoomOverlay from "@/views/patient_user/telehealth/components/WaitingRoomOverlay.vue";
	import KioskBranded from "@/views/kiosk_user/components/KioskBranded.vue";
	import AppButton from "@/views/kiosk_user/components/AppButton.vue";
	import { createTimer, Timer } from "@/lib/utils/timer";
	import KioskWrapper from "@/views/kiosk_user/components/KioskWrapper.vue";
	import { Pharmacy } from "@/lib/kiosk/pharmacy.model";
	import { NotificationSeverity, NotificationType, NotifyEvent, WebNotification } from "@/lib/types/Notifier";
	import KioskStore from "@/lib/vuex/modules/Kiosk.store";
	import { KioskWorkflow } from "@/views/kiosk_user/types";

	@Options({components: {KioskBranded, AppButton, KioskWrapper}})
	export default class KioskWaitingRoomOverlay extends WaitingRoomOverlay
	{
		public waitingTimer: Timer = null;
		public waitingTime = "";

		mounted()
		{
			this.waitingTimer = createTimer((time) => this.waitingTime = time, 1000);
			this.waitingTimer.start();
		}

		public cancel()
		{
			WebNotification.$emit({
				event: NotifyEvent.Generic,
				type: NotificationType.Confirm,
				severity: NotificationSeverity.Reminder,
				title: this.$mhat("KioskWaitingRoomOverlay.CancelAppointmentNotificationTitle"),
				message: this.$mhat("KioskWaitingRoomOverlay.CancelAppointmentNotificationMessage"),
				confirm: {
					message: this.$mhat("KioskWaitingRoomOverlay.YesButtonText"),
					callback: () =>
					{
						this.$emit("exit");
					},
				},
				dismiss: {
					message: this.$mhat("KioskWaitingRoomOverlay.NoButtonText"),
				},
			});
		}

		get pharmacy(): Pharmacy
		{
			return this.AuthStore.loggedInKiosk?.pharmacy;
		}
	}
