
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {AqsPatientAPI, PublicAPI} from "@/lib/services/Api";
	import {SessionData} from "@/lib/telehealth/models/sessionData.model";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
	import TelehealthWrapper from "@/components/telehealth/TelehealthWrapper.vue";
	import PatientTelehealthHeader from "@/views/patient_user/appointment/components/PatientTelehealthHeader.vue";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import OnDemandBookingTelehealthWaitingRoomOverlay from "@/views/patient_user/odb/telehealth/OnDemandBookingTelehealthWaitingRoomOverlay.vue";
	import TelehealthIOSBase from "@/components/telehealth/TelehealthIOSBase.vue";
	import IOSTelehealth from "@/lib/telehealth/ios/IOSTelehealth";
	import {IOSCallData} from "@/lib/NativeBridge";
	import {reactive} from "vue";
	import TelehealthInterface, {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
	import {IonPage, IonChip} from "@ionic/vue";
	import AqsTelehealthApiService from "@/lib/telehealth/api/AqsTelehealthApiService";
	import MHABackendLogger from "@/lib/utils/MHABackendLogger";

	@Options({
		components: {
			TelehealthWrapper, PatientTelehealthHeader, ChatView, OnDemandBookingTelehealthWaitingRoomOverlay, IonPage, IonChip,
		},
	})
	export default class OnDemandBookingTelehealthIos extends TelehealthIOSBase
	{
		// ==========================================================================
		// Vue Life Cycle Hooks
		// ==========================================================================

		public async onCreated(): Promise<void>
		{
			this.telehealthEngine = reactive(new IOSTelehealth()) as TelehealthInterface;
			this.telehealthApiService = new AqsTelehealthApiService(this.clinicId, this.queueId, this.queuedAppointmentId);
		}

		public async onMounted(): Promise<void>
		{
			await this.telehealthEngine.initialize(this.callData(await this.fetchSessionData()), null, null);
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public callData(sessionData: SessionData): IOSCallData
		{
			if (sessionData)
			{
				const {sessionId, token, apiKey} = sessionData;
				return {
					sessionToken: token,
					sessionId,
					appointmentId: this.queuedAppointmentId,
					apiKey: apiKey.toString(),
					handle: "",
					voip: this.isCallKit(),
					videoEnabled: !this.isVideoDisabled(),
				};
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get clinicId(): string
		{
			return OrganizationStore.organizationProfile.primary_clinic_id;
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		protected onCallStateChange(state: TELEHEALTH_STATE): void
		{
			// Vue has a really hard time tracking changes caused by the native iOS code.
			// force update
			if (this.$refs.ionPage)
			{
				(this.$refs.ionPage as typeof IonPage).$forceUpdate();
			}
		}

		protected async fetchSessionData(): Promise<SessionData>
		{
			try
			{
				return new SessionData((await AqsPatientAPI().getTelehealthSession(
					OrganizationStore.organizationProfile.primary_clinic_id,
					this.queueId,
					this.queuedAppointmentId)).data);
			}
			catch (e)
			{
				NotificationService.notify(
					{
						event: NotifyEvent.Generic,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Critical,
						title: "Telehealth Connection Error",
						message: "An error has occurred preventing the Telehealth connection.",
					});

				throw e;
			}
		}
	}
