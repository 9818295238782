import TelehealthApiServiceInterface from "@/lib/telehealth/api/TelehealthApiServiceInterface";
import {SessionData} from "@/lib/telehealth/models/sessionData.model";
import LogEvent from "@/lib/telehealth/models/LogEvent";
import {TeleCallState} from "@/lib/telehealth/models/telehealth.types";
import {KioskAPI} from "@/lib/services/Api";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import AuthStore from "@/lib/vuex/auth.store";
import {BrowserInfo} from "detect-browser";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import ChatMessageToTelehealthChatMessageDtoConverter from "@/lib/telehealth/conversion/ChatMessageToTelehealthChatMessageDtoConverter";
import {TelehealthChatMessageDto} from "@/open_api/generated";
import TelehealthChatMessageDtoToChatMessageConverter from "@/lib/telehealth/conversion/TelehealthChatMessageDtoToChatMessageConverter";

export default class KioskTelehealthApiService implements TelehealthApiServiceInterface
{
	protected _appointmentId: string;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(appointmentId)
	{
		this._appointmentId = appointmentId;
	}

	// ==========================================================================
	// TelehealthApiServiceInterface Implementation
	// ==========================================================================

	/**
	 * fetch telehealth session data.
	 */
	public async fetchSessionData(): Promise<SessionData>
	{
		return new SessionData((await KioskAPI().getKioskPatientTelehealthSessionData(this._appointmentId)).data);
	}

	/**
	 * log a telehealth event to the server.
	 * @param event - the event to log.
	 */
	public async logEvent(event: LogEvent): Promise<void>
	{
		KioskAPI().logEventToPatientKioskTelehealthSession(this._appointmentId, event.asTelehealthLogEventDto);
	}

	/**
	 * Ping the telehealth session. Providing the server with the current state of the telehealth call.
	 * @param callState - the current state of the call.
	 */
	public async pingSession(callState: TeleCallState): Promise<void>
	{
		await KioskAPI().pingKioskTelehealthSession(this._appointmentId, {status: callState.toString()});
	}

	/**
	 * get information about the local telehealth client.
	 * @param browserInfo - information about the users browser
	 */
	public async getLocalClientData(browserInfo: BrowserInfo): Promise<TelehealthClient>
	{
		const user = AuthStore.loggedInUser;

		return new TelehealthClient(
			browserInfo,
			user.email,
			user.firstName,
			user.lastName,
			user.userType,
		);
	}

	/**
	 * record a chat message for this telehealth session.
	 * @param message - the message to record
	 * @return the newly recorded message
	 */
	public async recordChatMessage(message: ChatMessage): Promise<ChatMessage>
	{
		const chatMessageDto: TelehealthChatMessageDto = (await KioskAPI().addKioskPatientChatMessageToAppointment(
			this._appointmentId,
			new ChatMessageToTelehealthChatMessageDtoConverter().convert(message))).data;
		return new TelehealthChatMessageDtoToChatMessageConverter().convert(chatMessageDto);
	}

	/**
	 * load chat history from DB.
	 * @return array of chat messages for the telehealth appointment.
	 */
	public async getChatHistory(): Promise<ChatMessage[]>
	{
		return new TelehealthChatMessageDtoToChatMessageConverter()
			.convertList((await KioskAPI().getKioskPatientAppointmentChatMessages(this._appointmentId)).data);
	}
}
