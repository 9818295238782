import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeaderBar = _resolveComponent("MobileHeaderBar")!

  return ($props.hidden)
    ? (_openBlock(), _createBlock(_component_MobileHeaderBar, {
        key: 0,
        "has-close": true
      }))
    : (_openBlock(), _createBlock(_component_MobileHeaderBar, _mergeProps({ key: 1 }, $data.headerProps, { "header-text": $options.getHeaderText }), null, 16, ["header-text"]))
}