import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2bdbaa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "clinic-connections"
}
const _hoisted_2 = { class: "section-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DependentsHeaderBar = _resolveComponent("DependentsHeaderBar")!
  const _component_ProfileControlButtons = _resolveComponent("ProfileControlButtons")!
  const _component_ProfileInputFields = _resolveComponent("ProfileInputFields")!
  const _component_BasicList = _resolveComponent("BasicList")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_ctx.profileController)
    ? (_openBlock(), _createBlock(_component_PageWrapper, { key: 0 }, _createSlots({
        "content-header": _withCtx(() => [
          _createVNode(_component_DependentsHeaderBar, { headerText: _ctx.getHeaderText }, null, 8, ["headerText"])
        ]),
        _: 2
      }, [
        (!_ctx.loadingQueue.isLoading)
          ? {
              name: "content-top",
              fn: _withCtx(() => [
                (_ctx.profileController.isEditMode())
                  ? (_openBlock(), _createBlock(_component_ProfileControlButtons, {
                      key: 0,
                      onSaveFunction: _ctx.onSave,
                      onCancelFunction: _ctx.cancel,
                      disableSave: !_ctx.allFieldsValid()
                    }, null, 8, ["onSaveFunction", "onCancelFunction", "disableSave"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_ProfileInputFields, {
                  controller: _ctx.profileController,
                  requiredInputIds: _ctx.userProfileIdList,
                  onProfileChange: _ctx.onProfileChange,
                  includeFieldLabels: "",
                  includeDependentFields: ""
                }, null, 8, ["controller", "requiredInputIds", "onProfileChange"]),
                (_ctx.profileController.isEditMode() && !_ctx.isCloudMd)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createElementVNode("h5", _hoisted_2, _toDisplayString(this.$mhat("DependentsProfile.ConnectedClinicTitle")), 1),
                      _createVNode(_component_BasicList, {
                        list: _ctx.clinicList,
                        "on-click": _ctx.deleteClinicConnection,
                        danger: "",
                        "icon-class-right": "icon-cancel"
                      }, null, 8, ["list", "on-click"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            }
          : undefined,
        (_ctx.loadingQueue.isLoading)
          ? {
              name: "content-center",
              fn: _withCtx(() => [
                _createVNode(_component_EventStateSpinner, {
                  "loading-title": this.$mhat('DependentsProfile.LoadingTitle'),
                  "loading-message": this.$mhat('DependentsProfile.LoadingMessage'),
                  "is-loading": true
                }, null, 8, ["loading-title", "loading-message"])
              ])
            }
          : undefined,
        (!_ctx.loadingQueue.isLoading)
          ? {
              name: "content-bottom",
              fn: _withCtx(() => [
                (!_ctx.profileController.isEditMode())
                  ? (_openBlock(), _createBlock(_component_BaseButton, {
                      key: 0,
                      pattern: _ctx.ButtonColorPattern.FILL,
                      color: _ctx.ButtonColor.PRIMARY,
                      disabled: !_ctx.allFieldsValid(),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveAndContinue()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(this.$mhat("DependentsProfile.SaveAndContinueButtonText")), 1)
                      ]),
                      _: 1
                    }, 8, ["pattern", "color", "disabled"]))
                  : _createCommentVNode("", true),
                (_ctx.profileController.isEditMode() && !_ctx.isCloudMd)
                  ? (_openBlock(), _createBlock(_component_BaseButton, {
                      key: 1,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addNewRoute()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(this.$mhat("DependentsProfile.AddAConnectionButtonText")), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.profileController.isEditMode())
                  ? (_openBlock(), _createBlock(_component_BaseButton, {
                      key: 2,
                      pattern: _ctx.ButtonColorPattern.TRANSPARENT,
                      color: _ctx.ButtonColor.DANGER,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteDependent()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(this.$mhat("DependentsProfile.DeleteDependentButtonText")), 1)
                      ]),
                      _: 1
                    }, 8, ["pattern", "color"]))
                  : _createCommentVNode("", true)
              ])
            }
          : undefined
      ]), 1024))
    : _createCommentVNode("", true)
}