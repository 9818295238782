import AbstractConverter from "@/lib/conversion/AbstractConverter";
import OneTimeCode from "@/lib/oneTimeCode/model/OneTimeCode";
import {OneTimeCodeDto} from "@/open_api/generated";

export default class OneTimeCodeToOneTimeCodeDtoConverter extends AbstractConverter<OneTimeCode, OneTimeCodeDto>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: OneTimeCode): OneTimeCodeDto
	{
		return {
			code_type: from.type,
			code: from.code,
			expire_at_utc: from.expireAt?.toDate(),
		};
	}
}
