import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14ccb18d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 3,
  class: "icon-delete right-icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_input_layout = _resolveComponent("input-layout")!

  return (_openBlock(), _createBlock(_component_input_layout, {
    label: _ctx.label,
    "for-input-id": _ctx.label,
    "hide-label": _ctx.hideLabel,
    "label-color": _ctx.labelColor,
    "label-style": {zIndex: _ctx.zIndex},
    "validation-message": _ctx.getValidationMessage,
    "is-valid": _ctx.isValid,
    "hide-validation-message": _ctx.hideValidationMessage,
    disabled: _ctx.disabled,
    required: _ctx.required,
    compact: _ctx.compact,
    pristine: _ctx.isPristine,
    "no-style": _ctx.nostyle,
    error: _ctx.error,
    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onLabelClicked()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["mha basic-input", _ctx.inputClasses])
      }, [
        (_ctx.iconLeft)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: _normalizeClass([_ctx.iconLeft, "left-icon"])
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.textArea)
          ? (_openBlock(), _createBlock(_component_ion_textarea, {
              key: 1,
              ref: "input",
              class: _normalizeClass({paddingLeft: _ctx.paddingLeft || _ctx.iconLeft, upper: _ctx.upper, lower: _ctx.lower}),
              id: _ctx.label,
              name: _ctx.label,
              disabled: _ctx.disabled,
              autocapitalize: _ctx.autocapitalize,
              autocomplete: _ctx.autocomplete,
              autocorrect: "on",
              value: _ctx.value,
              onIonChange: _ctx.onInput,
              onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('focus'))),
              onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur'))),
              placeholder: _ctx.placeholder,
              "aria-label": _ctx.label,
              "auto-grow": _ctx.autoGrow,
              rows: _ctx.rows,
              maxlength: _ctx.maxLength
            }, null, 8, ["class", "id", "name", "disabled", "autocapitalize", "autocomplete", "value", "onIonChange", "placeholder", "aria-label", "auto-grow", "rows", "maxlength"]))
          : (_openBlock(), _createBlock(_component_ion_input, {
              key: 2,
              ref: "input",
              type: _ctx.type,
              class: _normalizeClass({paddingLeft: _ctx.paddingLeft || _ctx.iconLeft, upper: _ctx.upper, lower: _ctx.lower}),
              id: _ctx.label,
              name: _ctx.label,
              disabled: _ctx.disabled,
              autocapitalize: _ctx.autocapitalize,
              autocomplete: _ctx.autocomplete,
              autocorrect: "on",
              value: _ctx.value,
              onIonChange: _ctx.onInput,
              onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus'))),
              onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('blur'))),
              onKeydown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onKeyDown($event))),
              onPaste: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onPaste($event))),
              placeholder: _ctx.placeholder,
              "aria-label": _ctx.label,
              maxlength: _ctx.maxLength
            }, null, 8, ["type", "class", "id", "name", "disabled", "autocapitalize", "autocomplete", "value", "onIonChange", "placeholder", "aria-label", "maxlength"])),
        (_ctx.invalid && !_ctx.disabled && !_ctx.iconRight)
          ? (_openBlock(), _createElementBlock("i", _hoisted_1))
          : (_ctx.iconRight)
            ? (_openBlock(), _createElementBlock("i", {
                key: 4,
                class: _normalizeClass([_ctx.iconRight, "right-icon"]),
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('right-click')))
              }, null, 2))
            : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["label", "for-input-id", "hide-label", "label-color", "label-style", "validation-message", "is-valid", "hide-validation-message", "disabled", "required", "compact", "pristine", "no-style", "error"]))
}