import {Patient} from "@/lib/patient/Patient";
import {DependentAPI, PatientAPI} from "@/lib/services/Api";
import {Action} from "vuex-module-decorators";
import DependentStore from "@/lib/vuex/modules/Dependent.store";

export default class DependentService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public static async getDependent(dependentId: string): Promise<Patient>
	{
		const {data} = await DependentAPI().getDependent(dependentId);
		return Patient.fromDto(data);
	}

	/**
	 * get a specific dependent by id from the cache.
	 * @param dependentId - the dependent to get
	 * @return the requested dependent or null.
	 */
	public static getCachedDependent(dependentId: string): Patient
	{
		return DependentStore.dependentMap[dependentId];
	}

	public static async getDependents(): Promise<Patient[]>
	{
		const {data} = await PatientAPI().getDependents();
		return data.map((dependent) => Patient.fromDto(dependent));
	}

	/**
	 * check if the provided Id is a dependent of the logged in user
	 * @param patientId - the patient to check
	 * @return true / false
	 */
	public static isCachedDependent(patientId: string): boolean
	{
		return !!DependentStore.dependentMap[patientId];
	}

	/**
	 * update all dependents attached to the current patient time zones to reflect the current browser time zone
	 */
	public static async updateAllDependentTzToCurrentBrowserTz(): Promise<void>
	{
		await Promise.all(DependentStore.dependents.map((dependent) => this.updateDependentTzToCurrentBrowserTz(dependent.id)));
	}

	/**
	 * update the TZ of the specified dependent to be the current browser TZ
	 * @param dependentId - id of dependent to update
	 */
	public static async updateDependentTzToCurrentBrowserTz(dependentId: string): Promise<void>
	{
		let dependent = DependentStore.dependent(dependentId);
		if (!dependent)
		{
			dependent = await this.getDependent(dependentId);
		}

		dependent.timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
		return DependentAPI().updateDependent(dependentId, dependent.toProfileTransferObj());
	}
}
