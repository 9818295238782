
	import HeaderTitle from "@/components/Header/HeaderTitle.vue";

	export default {
		name: "AppHeader",

		props: {
			title: String,
			closeText: String,
		},

		components: {HeaderTitle},
	};
