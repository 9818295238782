

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {getResources} from "@/lib/utils/Resource";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";

	@Options({})
	export default class SignupSpinner extends Vue
	{
		@Prop({type: Boolean}) isLoading: boolean;
		@Prop({type: Boolean}) isError: boolean;
		@Prop({type: Boolean}) isSuccessPartial: boolean;
		@Prop({type: Object}) clinic: ClinicProfile;
		@Prop({type: String}) patientName: string;
		@Prop({type: String}) patientEmail: string;
		@Prop({type: Boolean, default: false}) darkStyle: boolean;

		public onReset()
		{
			this.$emit("reset");
		}

		get clinicName(): string
		{
			return (this.clinic) ? this.clinic.name : this.$mhat("SignupSpinner.GenericClinicName");
		}

		get loadingTitle(): string
		{
			return this.$mhat("SignupSpinner.LoadingTitle", {patientName: this.patientName});
		}

		get successTitle(): string
		{
			if (this.isSuccessPartial)
			{
				return this.$mhat("SignupSpinner.PartialAccountCreationSuccessTitle");
			}
			else
			{
				return this.$mhat("SignupSpinner.AccountCreationSuccessTitle");
			}
		}

		get successMessage(): string[]
		{
			let messages = null;

			if (this.isSuccessPartial)
			{
				messages = [this.$mhat("SignupSpinner.PartialAccountCreationSuccessMessage",
					{patientEmail: this.patientEmail, clinicName: this.clinicName}),
				];
			}
			else
			{
				messages = [this.$mhat("SignupSpinner.AccountCreationSuccessMessage",
					{patientEmail: this.patientEmail}),
				];
			}

			return messages;
		}

		get successIcon(): string
		{
			return (this.isSuccessPartial) ? "icon-question" : "icon-check";
		}

		get brandName()
		{
			return getResources().app_name;
		}

		get loadingMessage()
		{
			return this.$mhat("SignupSpinner.LoadingMessage", {appName: this.brandName});
		}
	}
