import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createBlock(_component_ion_fab, {
    vertical: _ctx.vertical,
    horizontal: _ctx.horizontal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_fab_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
        disabled: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.icon)
          }, null, 2)
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 8, ["vertical", "horizontal"]))
}