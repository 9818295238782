import {ClinicConnectionRequest, ClinicUpdateLinkMethod} from "@/open_api/generated";

export interface ClinicConnectionParams
{
	clinicId: string;
	connectionMethod: ClinicUpdateLinkMethod;
	connectionRequest?: ClinicConnectionRequest;
}

export enum PendingPatientResponse
{
	Confirm = "confirm",
	Deny = "deny",
}
