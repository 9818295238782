import OneTimeCode from "@/lib/oneTimeCode/model/OneTimeCode";
import {PatientAPI} from "@/lib/services/Api";
import OneTimeCodeFactory from "@/lib/oneTimeCode/factory/OneTimeCodeFactory";
import {OneTimeCodeType} from "@/lib/oneTimeCode/model/OneTimeCodeType";
import OneTimeCodeToOneTimeCodeDtoConverter from "@/lib/oneTimeCode/converter/OneTimeCodeToOneTimeCodeDtoConverter";
import OneTimeCodeDtoToOneTimeCodeConverter from "@/lib/oneTimeCode/converter/OneTimeCodeDtoToOneTimeCodeConverter";

export default class PatientOneTimeCodeService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * generate a new account id code for the currently logged in patient.
	 * @return promise that resolves to the newly generated one time code
	 */
	public async generateAccountIdCode(): Promise<OneTimeCode>
	{
		const codeStub: OneTimeCode = OneTimeCodeFactory.generateOneTimeCodeStub(OneTimeCodeType.PatientAccountIdCode);
		const newCodeDto = (await PatientAPI().generatePatientOneTimeCode(
			(new OneTimeCodeToOneTimeCodeDtoConverter()).convert(codeStub))).data;

		return (new OneTimeCodeDtoToOneTimeCodeConverter()).convert(newCodeDto);
	}

	/**
	 * get all active codes for the currently logged in patient
	 * @return promise that resolves to a list of active one time codes
	 */
	public async getActiveCodes(): Promise<OneTimeCode[]>
	{
		return (new OneTimeCodeDtoToOneTimeCodeConverter()).convertList((await PatientAPI().getPatientOneTimeCodes()).data);
	}
}
