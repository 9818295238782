import {ChatMessageType, UserType} from "@/open_api/generated";
import {Moment} from "moment";

export default class ChatMessage
{
	private readonly _id: string;
	private readonly _message: string;
	private readonly _senderId: string;
	private readonly _messageType: ChatMessageType;
	private readonly _senderType: UserType;
	private readonly _createdAt: Moment;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(id: string, message: string, messageType: ChatMessageType, senderId: string, senderType: UserType, createdAt: Moment)
	{
		this._id = id;
		this._message = message;
		this._messageType = messageType;
		this._senderId = senderId;
		this._senderType = senderType;
		this._createdAt = createdAt;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get id(): string
	{
		return this._id;
	}

	get message(): string
	{
		return this._message;
	}

	get messageType(): ChatMessageType
	{
		return this._messageType;
	}

	get senderId(): string
	{
		return this._senderId;
	}

	get senderType(): UserType
	{
		return this._senderType;
	}

	get createdAt(): Moment
	{
		return this._createdAt;
	}
}
