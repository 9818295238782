import {NativeBridge} from "@/lib/NativeBridge";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import NativeEventListener from "@/lib/telehealth/ios/NativeEventListener";
import {IOSCallEvent} from "@/views/patient_user/telehealth/types";
import {ChatItemType, MessageOrigin} from "@/lib/telehealth/models/telehealth.types";
import moment from "moment";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import ChatMessageToTelehealthChatMessageDtoConverter
	from "@/lib/telehealth/conversion/ChatMessageToTelehealthChatMessageDtoConverter";
import TelehealthChatMessageDtoToChatMessageConverter
	from "@/lib/telehealth/conversion/TelehealthChatMessageDtoToChatMessageConverter";

export default class ChatManager
{
	protected nativeEventListener: NativeEventListener;
	protected onChatMessageCallbacks: CallbackCollection;
	protected onChatTypingCallbacks: CallbackCollection;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		nativeEventListener: NativeEventListener,
		onChatMessageCallbacks: CallbackCollection,
		onChatTypingCallbacks: CallbackCollection)
	{
		this.nativeEventListener = nativeEventListener;
		this.onChatMessageCallbacks = onChatMessageCallbacks;
		this.onChatTypingCallbacks = onChatTypingCallbacks;

		this.setupEventListeners();
	}

	/**
	 * send a chat message to every one in the call.
	 * @param msg - the message to send
	 */
	public async sendChatMessage(msg: ChatMessage): Promise<void>
	{
		await NativeBridge.sendChatMessage(JSON.stringify((new ChatMessageToTelehealthChatMessageDtoConverter()).convert(msg)));

		// iOS client does not receive its own events for some reason
		// fake an incoming chat message from our self.
		this.onChatMessageCallbacks.call(null, msg);
	}

	/**
	 * sends a typing signal to remote clients. Use this to indicate that this client is typing
	 */
	public async sendTypingSignal(): Promise<void>
	{
		await NativeBridge.sendTypingSignal();
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	protected setupEventListeners(): void
	{
		this.nativeEventListener.addNativeEventListener(IOSCallEvent.ChatMessage, this.onChatMessage, this);
		this.nativeEventListener.addNativeEventListener(IOSCallEvent.ChatTyping, this.onChatTyping, this);
	}

	protected onChatMessage(data: string): void
	{
		const chatMessage = (new TelehealthChatMessageDtoToChatMessageConverter()).convert(JSON.parse(data));
		this.onChatMessageCallbacks.call(null, chatMessage);
	}

	protected onChatTyping(): void
	{
		this.onChatTypingCallbacks.call(null);
	}
}
