
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {MediaDeviceStatus} from "@/lib/mediaDevices";
	import {Appointment} from "@/lib/models/Appointment";
	import IconTooltip from "@/components/IconTooltip.vue";
	import MicrophoneStatusIcon from "@/views/clinic_user/appointment/components/MicrophoneStatusIcon.vue";
	import VideoStatusIcon from "@/views/clinic_user/appointment/components/VideoStatusIcon.vue";

	@Options({
		components: {VideoStatusIcon, MicrophoneStatusIcon, IconTooltip},
	})
	export default class AppointmentDetails extends Vue
	{
		@Prop({type: Object}) private appointment: Appointment;
		@Prop({type: String, default: MediaDeviceStatus.UNKNOWN}) public microphoneStatus;
		@Prop({type: String, default: MediaDeviceStatus.UNKNOWN}) public videoStatus;

		private readonly APPOINTMENT_DATE_FORMAT: string = "dddd MMMM Do YYYY [at] h:mm a";

		// ==========================================================================
		// Getters
		// ==========================================================================

		get appointmentTimeHuman(): string
		{
			return this.appointment.startDateTime.format(this.APPOINTMENT_DATE_FORMAT);
		}
	}
