import {BaseLoginService} from "@/lib/services/login/BaseLogin.service";
import {getCookies} from "@/lib/utils/cookies";
import {LoginAPI} from "@/lib/services/Api";
import {ClinicUserLoginResponse} from "@/open_api/generated";
import {ClinicUser} from "@/lib/clinic/clinicUser.model";

export class ProviderLoginService extends BaseLoginService
{
	constructor()
	{
		super();
		localStorage.setItem(this.loginTokenKey, getCookies(document)[this.loginTokenKey]);
	}

	public async login(email: string, password: string): Promise<ClinicUser>
	{
		try
		{
			const response = await LoginAPI().clinicLogin({ email, password });
			return this.setLoggedInInfo(response.data);
		}
		catch (error)
		{
			console.error(error);
		}
	}

	// eslint-disable-next-line camelcase
	public setLoggedInInfo({user, login_token}: ClinicUserLoginResponse): ClinicUser
	{
		const loggedInUser = ClinicUser.fromDto(user, login_token);
		this.setLoggedInToken(loggedInUser.loginToken);
		return this.setLoggedInUser(loggedInUser) as ClinicUser;
	}
}
