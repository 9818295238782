import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5d80d90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-list-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item) => {
      return (_openBlock(), _createBlock(_component_BaseButton, {
        key: item.id,
        class: _normalizeClass({primary: $props.primary, danger: $props.danger, warning: $props.warning, info: $props.info}),
        onClick: () => $props.onClick(item)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.label), 1)
        ]),
        _: 2
      }, 1032, ["class", "onClick"]))
    }), 128))
  ]))
}