import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8adf454"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "telehealth-audio-embedded" }
const _hoisted_2 = { class: "d-flex flex-row p-16 align-items-center justify-content-space-between" }
const _hoisted_3 = { class: "d-flex flex-row align-items-center" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "d-flex flex-row align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PulsingIndicator = _resolveComponent("PulsingIndicator")!
  const _component_MicroButton = _resolveComponent("MicroButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PulsingIndicator, {
          class: _normalizeClass(["call-indicator", _ctx.callStateIndicatorClasses]),
          pulsing: _ctx.isCallingPatient
        }, null, 8, ["class", "pulsing"]),
        _createElementVNode("div", {
          class: "call-state-text text-ellipsis m-l-8 m-r-8",
          title: _ctx.callStateText
        }, _toDisplayString(_ctx.callStateText), 9, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_MicroButton, {
          class: "call-button",
          "button-l-r-padding": "8px",
          title: _ctx.callButtonTitle,
          disabled: _ctx.loading,
          onClick: _ctx.onCallButtonClick
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass(["icon", _ctx.callButtonIconClass])
            }, null, 2)
          ]),
          _: 1
        }, 8, ["title", "disabled", "onClick"]),
        _createVNode(_component_MicroButton, {
          class: "mute-button m-l-8",
          "button-l-r-padding": "8px",
          title: _ctx.muteButtonTitle,
          disabled: _ctx.loading,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleMute()))
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass(["icon", _ctx.muteButtonIconClass])
            }, null, 2)
          ]),
          _: 1
        }, 8, ["title", "disabled"])
      ])
    ])
  ]))
}