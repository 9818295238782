
	import {Options, Vue} from "vue-property-decorator";
	import TelehealthProviderBase from "@/components/telehealth/TelehealthProviderBase.vue";
	import OpenTokTelehealth from "@/lib/telehealth/opentok/OpenTokTelehealth";
	import {SessionData} from "@/lib/telehealth/models/sessionData.model";
	import {ClinicAPI} from "@/lib/services/Api";
	import PulsingIndicator from "@/components/Cosmetic/PulsingIndicator.vue";
	import MicroButton from "@/components/Buttons/MicroButton.vue";
	import {AV_ERROR} from "@/lib/telehealth/TelehealthInterface";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";

	@Options({components: {MicroButton, PulsingIndicator}})
	export default class TelehealthAudioEmbedded extends TelehealthProviderBase
	{
		private readonly CALL_START_DELAY = 2000; // 2 seconds
		private callLoading = true;
		private avError = false; // true if audio visual error has occurred.
		private avErrorMessage: string = null; // descriptive message for user about AV error.

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async onCreated(): Promise<void>
		{
			this.telehealthEngine = new OpenTokTelehealth();
			this.telehealthEngine.setClientInfo(await this.getlocalClientData());
		}

		public async onMounted(): Promise<void>
		{
			const sessionData = await this.fetchSessionData();
			// initialize the telehealth engine
			await this.telehealthEngine.initialize(sessionData, null, null);

			window.setTimeout(() =>
			{
				this.callLoading = false;
				this.telehealthEngine.disableVideo();
				this.startCall();
			}, this.CALL_START_DELAY);
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public onCallButtonClick(): void
		{
			if (this.inRoom())
			{
				this.endCall(false);
			}
			else
			{
				this.avError = false;
				this.startCall();
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get callStateText(): string
		{
			if (this.avError)
			{
				return this.avErrorMessage;
			}
			else if (this.callLoading)
			{
				return "Loading...";
			}
			else if (this.isCallingPatient)
			{
				return "Calling...";
			}
			else if (this.patientPublishing)
			{
				return "Connected.";
			}
			else
			{
				return "Disconnected.";
			}
		}

		get callButtonTitle(): string
		{
			if (this.inRoom())
			{
				return "Hangup";
			}
			else
			{
				return "Call";
			}
		}

		get callButtonIconClass(): any
		{
			return {
				"icon-tele-hangup": this.inRoom(),
				"icon-tele-call": !this.inRoom(),
			};
		}

		get muteButtonTitle(): string
		{
			if (this.telehealthEngine.isMuted)
			{
				return "Unmute";
			}
			else
			{
				return "Mute";
			}
		}

		get muteButtonIconClass(): any
		{
			return {
				"icon-tele-volume-mic-mute": this.telehealthEngine.isMuted,
				"icon-tele-volume-mic": !this.telehealthEngine.isMuted,
			};
		}

		get callStateIndicatorClasses(): any
		{
			return {
				calling: this.isCallingPatient || this.callLoading,
				connected: this.patientPublishing,
				"not-connected": !this.patientPublishing && !this.isCallingPatient && !this.callLoading,
			};
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		protected onAVDeviceError(error: AV_ERROR): void
		{
			let message = "Error getting Microphone and Camera";
			switch (error)
			{
			case AV_ERROR.PERMISSION_DENIED:
				message = "You have denied permission to your microphone and or camera. Please allow permission";
				break;
			case AV_ERROR.NO_DEVICES:
				message = "We could not find your microphone or camera. Please ensure they are plugged in";
				break;
			case AV_ERROR.HARDWARE_FAULT:
				message = "We are having trouble using your microphone and or camera. Please make sure no " +
					"other applications are using them";
				break;
			}

			this.avError = true;
			this.avErrorMessage = message;
		}

		protected async fetchSessionData(): Promise<SessionData>
		{
			return new SessionData((await ClinicAPI().getProviderTelehealthSessionData(this.appointmentId)).data);
		}
	}
