import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a8bd8ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "review-card" }
const _hoisted_2 = { class: "reviewer-name" }
const _hoisted_3 = { class: "reviewer-location" }
const _hoisted_4 = { class: "reviewer-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["reviewer-image", {large: _ctx.large}])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.reviewerName), 1),
    _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.reviewerLocation), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.reviewerMessage), 1)
  ]))
}