import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex-col-center"
}
const _hoisted_2 = { class: "w-512 m-t-96" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppointmentsHeader = _resolveComponent("AppointmentsHeader")!
  const _component_AppointmentsFeed = _resolveComponent("AppointmentsFeed")!
  const _component_AppointmentInfo = _resolveComponent("AppointmentInfo")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SplitView = _resolveComponent("SplitView")!
  const _component_FloatingActionButton = _resolveComponent("FloatingActionButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, {
    contentWidthFull: "",
    contentFullHeight: "",
    contentHeaderRemoveFalseHeaderSpace: !_ctx.shouldShowSpinner,
    contentBodyBottomMarginDisabled: "",
    contentNotchCorrectionDisabled: ""
  }, _createSlots({
    content: _withCtx(() => [
      _withDirectives(_createVNode(_component_SplitView, {
        "hide-right": _ctx.isMobile,
        noHeader: _ctx.isMobile
      }, {
        "split-left": _withCtx(() => [
          _createVNode(_component_AppointmentsFeed, {
            ref: "appointmentFeed",
            "selected-appointment-clinic-id": _ctx.clinicId,
            "selected-appointment-remote-id": _ctx.remoteAppointmentId,
            "selected-patient-id": _ctx.selectedPatientId,
            "is-mobile": _ctx.isMobile,
            isLoading: _ctx.appointmentFeedLoading,
            "onUpdate:isLoading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.appointmentFeedLoading) = $event)),
            hasAppointments: _ctx.appointmentFeedHasAppointments,
            "onUpdate:hasAppointments": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.appointmentFeedHasAppointments) = $event)),
            onSelect: _ctx.selectAppointmentFromCardInfo,
            onUserChange: _ctx.onAppointmentFeedUserChange
          }, null, 8, ["selected-appointment-clinic-id", "selected-appointment-remote-id", "selected-patient-id", "is-mobile", "isLoading", "hasAppointments", "onSelect", "onUserChange"])
        ]),
        "split-right": _withCtx(() => [
          (_ctx.clinicId && _ctx.remoteAppointmentId && _ctx.appointmentFeedHasAppointments)
            ? (_openBlock(), _createBlock(_component_AppointmentInfo, {
                key: _ctx.$route.fullPath,
                onAppointmentCancelled: _ctx.onAppointmentCancelled,
                clinicId: _ctx.clinicId,
                remoteAppointmentId: _ctx.remoteAppointmentId
              }, null, 8, ["onAppointmentCancelled", "clinicId", "remoteAppointmentId"]))
            : _createCommentVNode("", true),
          (!_ctx.appointmentFeedHasAppointments)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_EventStateSpinner, {
                    "success-icon": "icon-calendar",
                    "success-title": "No Appointments Found",
                    "success-message": _ctx.zeroStateSpinnerMessage
                  }, null, 8, ["success-message"]),
                  _createVNode(_component_BaseButton, {
                    pattern: _ctx.ButtonColorPattern.FILL,
                    color: _ctx.ButtonColor.PRIMARY,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.bookForSelected()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("AppointmentsHome.BookAnAppointment")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["hide-right", "noHeader"]), [
        [_vShow, !_ctx.shouldShowSpinner]
      ]),
      (_ctx.shouldShowSpinner)
        ? (_openBlock(), _createBlock(_component_EventStateSpinner, {
            key: 0,
            "is-loading": true,
            "loading-icon": "icon-calendar",
            "loading-title": this.$mhat('AppointmentsHome.LoadingTitle')
          }, null, 8, ["loading-title"]))
        : _createCommentVNode("", true)
    ]),
    "fixed-footer": _withCtx(() => [
      _createVNode(_component_FloatingActionButton, {
        icon: "icon-plus",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.bookForSelected()))
      })
    ]),
    _: 2
  }, [
    (!_ctx.isMobile)
      ? {
          name: "content-header",
          fn: _withCtx(() => [
            _createVNode(_component_AppointmentsHeader)
          ])
        }
      : undefined
  ]), 1032, ["contentHeaderRemoveFalseHeaderSpace"]))
}