import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_split_pane, {
        disabled: !_ctx.chatVisible,
        "content-id": _ctx.contentId,
        when: "xl",
        onIonSplitPaneVisible: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSplitPaneVisibilityChange($event))),
        ref: "splitPane"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            type: "push",
            "content-id": _ctx.contentId,
            onIonWillClose: _ctx.onMenuClose,
            "menu-id": "chat-menu",
            ref: "splitMenu"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "menu", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["content-id", "onIonWillClose"]),
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["disabled", "content-id"])
    ]),
    _: 3
  }))
}