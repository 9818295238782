import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a063abf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-text" }
const _hoisted_2 = { class: "body-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeaderBar = _resolveComponent("MobileHeaderBar")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_MobileHeaderBar, {
        "on-back-click": _ctx.dismissModal,
        "on-close-click": _ctx.dismissModal,
        "header-text": this.$mhat('CannotConnectToClinicModal.HeaderText'),
        icon: null
      }, null, 8, ["on-back-click", "on-close-click", "header-text"])
    ]),
    "content-top": _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(this.$mhat('CannotConnectToClinicModal.CannotConnectToClinicHeader', {clinicName: _ctx.clinic.name})), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(this.$mhat('CannotConnectToClinicModal.CannotConnectToClinicMessage',
				{clinicName: _ctx.clinic.name, clinicPhoneNumber: _ctx.clinic.primaryPhoneDisplay})), 1),
      _createVNode(_component_BaseButton, {
        color: _ctx.ButtonColor.GREYSCALE_LIGHT,
        onClick: _ctx.dismissModal
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("CannotConnectToClinicModal.DismissButtonText")), 1)
        ]),
        _: 1
      }, 8, ["color", "onClick"])
    ]),
    _: 1
  }))
}