import {AxiosResponse} from "axios";
import moment, {Moment} from "moment";

import {AppointmentType, ProviderTransfer, ScheduleSlotTransfer} from "@/open_api/generated";
import {AppointmentDayMap} from "@/lib/types/Appointment";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import {Appointment, DAY_PERIOD} from "@/lib/models/Appointment";
import {DATE_FORMAT} from "@/constants";
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import Services from "@/lib/services/Services";
import Provider from "@/lib/clinic/providerProfile.model";
import ClinicService from "@/lib/clinics/Clinic.service";
import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
import {Patient} from "@/lib/patient/Patient";
import DependentStore from "@/lib/vuex/modules/Dependent.store";
import LoadingQueue from "@/lib/LoadingQueue";
import {getBookingClinicService} from "@/views/patient_user/booking/BookingRoutingHelper";
import ScheduleSlotTransferToScheduleSlotConverter
	from "@/lib/scheduling/converter/ScheduleSlotTransferToScheduleSlotConverter";
import ScheduleSlot from "@/lib/scheduling/model/ScheduleSlot";
import ProviderGroup from "@/lib/clinic/providerGroup.model";
import BookingServiceFactory from "@/lib/booking/factory/BookingServiceFactory";

// Called from Vue with .call(this) for context
export function setBookingParams()
{
	if (!BookingControllerStore.bookingController)
	{
		BookingControllerStore.setBookingController(new BookingController());
	}

	const controller: BookingController = BookingControllerStore.bookingController;

	controller.clinicId = this.clinicId;
	controller.providerGroupId = this.providerGroupId;
	controller.providerOrGroupId = this.providerOrGroupId;
	controller.appointmentTypeId = this.appointmentTypeId;
	controller.appointmentDate = this.appointmentDate;
	controller.dependentId = this.dependentId;

	BookingControllerStore.setBookingController(controller);
}

export default class BookingController
{
	public static filterAppointmentsByPeriod(period: DAY_PERIOD, slots: ScheduleSlot[]): ScheduleSlot[]
	{
		return slots.filter((slot) => Appointment.createDayPeriod(slot.startDateTime) === period);
	}

	private APPOINTMENT_CACHE_DURATION = 5;
	private MAX_MONTH_ITERATIONS = 4;

	private _availableSlots: ScheduleSlot[] = [];

	private _appointmentDays: AppointmentDayMap = {};
	private _calendarMonth: Moment = moment();
	private _bookingErrors: ErrorResponse = null;

	// URL Params
	private _clinicId: string = null;
	private _providerOrGroupId: string = null;
	private _appointmentTypeId: string = null;
	private _appointmentDate: string = null;
	private _dependentId: string = null;
	private _providerGroupId: string = null;

	private _clinic: ClinicProfile = null;
	private _provider: Provider = null;
	private _appointmentType: AppointmentType = null;
	private _providerGroup: ProviderGroup = null;

	private _selectedSlot: ScheduleSlot = null;
	private _reason = "";

	private _appointment: Appointment = null;

	private _timeAppointmentsReceived: Moment = null;
	private _appointmentBooked = false;
	private _loadingQueue: LoadingQueue = new LoadingQueue();

	public async fetchAppointmentData(searchDate: Moment = moment())
	{
		this._loadingQueue.pushLoadingState();

		try
		{
			await this.getAppointmentsIfNone(searchDate);
			await this.fetchMetadata();
		}
		finally
		{
			this._loadingQueue.popLoadingState();
		}
	}

	public async fetchMetadata()
	{
		const dataPromises = [];
		const isGroup = await this.isGroupId(this.providerOrGroupId);

		if (!this.clinic && this.clinicId)
		{
			dataPromises.push(this.getClinicInfo());
		}

		if (!this.provider && !isGroup)
		{
			dataPromises.push(this.getProviderInfo());
		}

		if (!this.appointmentType && this.appointmentTypeId)
		{
			dataPromises.push(this.getAppointmentTypeInfo());
		}

		return await Promise.all(dataPromises);
	}

	public async getAppointmentsIfNone(searchDate: Moment = moment()): Promise<any>
	{
		if (!this.hasAppointments || this.shouldBustAppointmentCache())
		{
			return await this.getAppointmentsForMonth(searchDate);
		}
	}

	public selectAppointmentSlot(slot: ScheduleSlot): void
	{
		this.selectedSlot = slot;
	}

	public getAppointmentsForMonth(searchDate: Moment): Promise<any>
	{
		this.availableSlots = [];

		const startDate: string = searchDate.toString();
		const endDate: string = searchDate.endOf("month").toString();

		return this.getAppointments(startDate, endDate);
	}

	public async getAppointments(startDate: string, endDate: string, iter?: number): Promise<any>
	{
		if (this.canGetAppointments())
		{
			this._loadingQueue.pushLoadingState();
			const {clinicId, providerOrGroupId, appointmentTypeId} = this;

			try
			{
				const bookingService = await new BookingServiceFactory().getBookingService(this.clinicId, this.providerOrGroupId, this.clinicService);
				const response = await bookingService.getAvailableAppointments(clinicId, providerOrGroupId, appointmentTypeId, startDate, endDate);

				return this.handleAppointmentsResponse(response, startDate, iter);
			}
			catch (error)
			{
				this.bookingErrors = error;
				throw error;
			}
			finally
			{
				this._loadingQueue.popLoadingState();
			}
		}
	}

	public clearAppointmentState()
	{
		this.timeAppointmentsReceived = null;
		this.appointmentTypeId = null;
		this.appointment = null;
	}

	private async handleAppointmentsResponse(response: ScheduleSlotTransfer[], startDate: string, iter?: number)
	{
		if (!this.hasAppointments)
		{
			this.availableSlots = (new ScheduleSlotTransferToScheduleSlotConverter()).convertList(response);
		}
		else
		{
			this.availableSlots = [...this.availableSlots, ...(new ScheduleSlotTransferToScheduleSlotConverter()).convertList(response)];
		}

		if (this.availableSlots.length < 3 && iter !== 0)
		{
			const nextMonthStart = moment(startDate).add(1, "month").startOf("month");
			const nextMonthEnd = moment(startDate).add(1, "month").endOf("month");

			await this.getAppointments(
				nextMonthStart.toString(),
				nextMonthEnd.toString(),
				iter ? --iter : this.MAX_MONTH_ITERATIONS,
			);
		}
		else
		{
			this.timeAppointmentsReceived = moment();
		}
	}

	private shouldBustAppointmentCache(): boolean
	{
		if (this.timeAppointmentsReceived)
		{
			return this.minutesSinceAppointmentsReceived() > this.APPOINTMENT_CACHE_DURATION;
		}

		return true;
	}

	private minutesSinceAppointmentsReceived(): number
	{
		const lastReceived: Moment = this.timeAppointmentsReceived;
		const now: Moment = moment();

		return moment.duration(now.diff(lastReceived)).asMinutes();
	}

	public async clinicBookingNotes()
	{
		if (!this._clinic)
		{
			this._loadingQueue.pushLoadingState();
			this._clinic = await this.clinicService.getClinic(this.clinicId);
			this._loadingQueue.popLoadingState();
		}

		return this._clinic.bookingNotes;
	}

	private getProviderInfo()
	{
		this._loadingQueue.pushLoadingState();

		return this.clinicService.patientClinicProvider(this.clinicId, this.providerOrGroupId)
			.then((provider: Provider) =>
			{
				this.provider = provider;

				if (this.appointment)
				{
					this.appointment.providerName = this.provider.fullNameWithPrefixSuffix();
				}
			})
			.finally(() =>
			{
				this._loadingQueue.popLoadingState();
			});
	}

	private getAppointmentTypeInfo()
	{
		this._loadingQueue.pushLoadingState();

		return this.clinicService.getAppointmentType(this.clinicId, this.appointmentTypeId)
			.then((appointmentType: AppointmentType) =>
			{
				this.appointmentType = appointmentType;
			})
			.finally(() =>
			{
				this._loadingQueue.popLoadingState();
			});
	}

	private getClinicInfo(): Promise<any>
	{
		this._loadingQueue.pushLoadingState();

		return this.clinicService.getClinic(this.clinicId)
			.then((clinic) =>
			{
				this.clinic = clinic;

				if (this.appointment)
				{
					this.appointment.clinicName = clinic.name;
				}
			})
			.finally(() =>
			{
				this._loadingQueue.popLoadingState();
			});
	}

	private canGetAppointments(): boolean
	{
		return Boolean(this.clinicId && this.providerOrGroupId && this.appointmentTypeId);
	}

	private async isGroupId(providerOrGroupId: string): Promise<boolean>
	{
		const providerGroups = await this.clinicService.getClinicProviderGroups(this.clinicId);

		return providerGroups.some((providerGroup) => providerGroup.id === providerOrGroupId);
	}

	// ----------------------  Computed --------------------------

	get hasAppointments(): boolean
	{
		return this.availableSlots.length > 0;
	}

	get noAppointments(): boolean
	{
		return !this._loadingQueue.isLoading && !this.hasAppointments;
	}

	get isLoading(): boolean
	{
		return this._loadingQueue.isLoading;
	}

	get params(): any
	{
		const {clinicId, providerOrGroupId, appointmentTypeId, appointmentDate} = this;
		return {clinicId, providerOrGroupId, appointmentTypeId, appointmentDate};
	}

	get dependent(): Patient
	{
		if (this.isDependent)
		{
			return DependentStore.dependent(this.dependentId);
		}
	}

	get isDependent(): boolean
	{
		return Boolean(this.dependentId);
	}

	get clinicService(): ClinicService
	{
		return getBookingClinicService(this.dependentId);
	}

	// ----------------------  Accessors --------------------------

	get appointmentDays(): AppointmentDayMap
	{
		return this._appointmentDays;
	}

	get calendarMonth(): Moment
	{
		return this._calendarMonth;
	}

	set calendarMonth(value: Moment)
	{
		this._calendarMonth = value;
	}

	get clinicId(): string
	{
		return this._clinicId;
	}

	set clinicId(value: string)
	{
		this._clinicId = value;
	}

	get providerOrGroupId(): string
	{
		return this._providerOrGroupId;
	}

	set providerOrGroupId(value: string)
	{
		this._providerOrGroupId = value;
	}

	get appointmentTypeId(): string
	{
		return this._appointmentTypeId;
	}

	set appointmentTypeId(value: string)
	{
		this._appointmentTypeId = value;
	}

	get appointmentType(): AppointmentType
	{
		return this._appointmentType;
	}

	set appointmentType(value: AppointmentType)
	{
		this._appointmentTypeId = value.id;
		this._appointmentType = value;
	}

	get bookingErrors(): ErrorResponse
	{
		return this._bookingErrors;
	}

	set bookingErrors(value: ErrorResponse)
	{
		this._bookingErrors = value;
	}

	get clinic(): ClinicProfile
	{
		return this._clinic;
	}

	set clinic(value: ClinicProfile)
	{
		this._clinic = value;
	}

	get provider(): Provider
	{
		return this._provider;
	}

	set provider(value: Provider)
	{
		this._provider = value;
	}

	get timeAppointmentsReceived(): moment.Moment
	{
		return this._timeAppointmentsReceived;
	}

	set timeAppointmentsReceived(value: moment.Moment)
	{
		this._timeAppointmentsReceived = value;
	}

	get appointment(): Appointment
	{
		if (this._appointment)
		{
			if (this.provider)
			{
				this._appointment.providerName = this.provider.fullNameWithPrefixSuffix();
			}

			if (this.clinic)
			{
				this._appointment.clinicName = this.clinic.name;
			}
		}

		return this._appointment;
	}

	set appointment(value: Appointment)
	{
		this._appointment = value;
	}

	get appointmentDate(): string
	{
		return this._appointmentDate;
	}

	set appointmentDate(value: string)
	{
		this._appointmentDate = value;
	}

	get appointmentBooked(): boolean
	{
		return this._appointmentBooked;
	}

	set appointmentBooked(value: boolean)
	{
		this._appointmentBooked = value;
	}

	get dependentId(): string
	{
		return this._dependentId;
	}

	set dependentId(value: string)
	{
		this._dependentId = value;
	}

	get providerGroupId(): string
	{
		return this._providerGroupId;
	}

	set providerGroupId(value: string)
	{
		this._providerGroupId = value;
	}

	get availableSlots(): ScheduleSlot[]
	{
		return this._availableSlots;
	}

	set availableSlots(value: ScheduleSlot[])
	{
		this._availableSlots = value;
	}

	get selectedSlot(): ScheduleSlot
	{
		return this._selectedSlot;
	}

	set selectedSlot(value: ScheduleSlot)
	{
		this._selectedSlot = value;
	}

	get reason(): string
	{
		return this._reason;
	}

	set reason(value: string)
	{
		this._reason = value;
	}
}
