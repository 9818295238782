import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fd58bdd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBarForgot = _resolveComponent("HeaderBarForgot")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (!_ctx.token)
    ? (_openBlock(), _createBlock(_component_PageWrapper, { key: 0 }, {
        "content-header": _withCtx(() => [
          _createVNode(_component_HeaderBarForgot, {
            "header-text": this.$mhat('LoginConfirm.HeaderText')
          }, null, 8, ["header-text"])
        ]),
        "content-top": _withCtx(() => [
          (_ctx.tokenSent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("p", null, _toDisplayString(this.$mhat("LoginConfirm.SentConfirmationEmailMessage", {patientEmail: _ctx.email})), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(this.$mhat("LoginConfirm.MustConfirmEmailMessage")), 1),
                _createElementVNode("p", null, _toDisplayString(this.$mhat("LoginConfirm.ConfirmEmailInstructionMessage")), 1),
                _createElementVNode("form", {
                  onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendConfirmEmail && _ctx.sendConfirmEmail(...args)), ["prevent"]))
                }, [
                  _createVNode(_component_BaseInput, {
                    label: this.$mhat('LoginConfirm.EmailFieldLabel'),
                    id: "email",
                    value: _ctx.email,
                    "padding-left": "",
                    "icon-left": "icon-mail",
                    onInput: _ctx.updateEmail,
                    valid: _ctx.emailValid
                  }, null, 8, ["label", "value", "onInput", "valid"]),
                  _createVNode(_component_BaseButton, {
                    type: "submit",
                    primary: "",
                    disabled: !_ctx.emailValid
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("LoginConfirm.SendButtonText")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ], 32)
              ]))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}