
	import {LOGO_PROFILE} from "@/assets/AppIcons";

	export default {
		name: "HeaderBarProfile",
		data()
		{
			return {
				headerProps: {
					headerIcon: LOGO_PROFILE,
					headerText: this.$mhat("HeaderBarProfile.HeaderText"),
				},
			};
		},
	};
