

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {Moment} from "moment";

	import CalendarDays from "./CalendarDays.vue";

	@Options({components: {CalendarDays}})
	export default class Calendar extends Vue
	{
		@Prop() public readonly month: Moment;
		@Prop({type: Set}) public readonly activeDates: Set<string>;
		@Prop({type: Boolean}) public readonly disabled: boolean;

		public handleDateSelect(date: Moment)
		{
			if (!this.disabled)
			{
				this.$emit("date-select", date);
			}
		}
	}
