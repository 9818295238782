import {BaseLoginService} from "@/lib/services/login/BaseLogin.service";
import {AuthInfo, PatientLoginResponse} from "@/open_api/generated";
import {LoginAPI} from "@/lib/services/Api";
import {NativeBridge} from "@/lib/NativeBridge";
import {PatientUser} from "@/lib/patient/patientUser.model";

export class PatientLoginService extends BaseLoginService
{
	public async login(email: string, password: string): Promise<PatientUser>
	{
		const authInfo: AuthInfo = { email, password };

		const response = await LoginAPI().login(authInfo);
		return this.setLoggedInInfo(response.data);
	}

	// eslint-disable-next-line camelcase
	public setLoggedInInfo({user, login_token}: PatientLoginResponse): PatientUser
	{
		NativeBridge.onUserLogin(login_token);

		const loggedInUser = PatientUser.fromDto(user, login_token);
		this.setLoggedInToken(loggedInUser.loginToken);
		return this.setLoggedInUser(loggedInUser) as PatientUser;
	}
}
