import {Notification, NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
import Services from "@/lib/services/Services";
import {Route} from "@/router/router";
import {$mhat} from "@/i18n";

export default class NotificationService
{
	public static hasConnectedClinics()
	{
		Services.PatientClinics.connectedClinics().then((clinics) =>
		{
			if (clinics.length === 0)
			{
				this.emitNoClinics();
			}
		});
	}

	public static notify(notification: Notification): void
	{
		WebNotification.$emit(notification);
	}

	/**
	 * present a simple dismiss notification to the user.
	 * @param title - title of the notification.
	 * @param message - message for the notification.
	 * @param severity - [optional (default Reminder)] the severity level of the notification.
	 */
	public static notificationDismiss(
		title: string,
		message: string,
		severity: NotificationSeverity = NotificationSeverity.Reminder): void
	{
		this.notify({
			event: NotifyEvent.Generic,
			type: NotificationType.Dismiss,
			severity,
			title,
			message,
		});
	}

	/**
	 * show a generic error message notification to the user
	 */
	public static genericErrorNotification(): void
	{
		this.notificationDismiss(
			$mhat("NotificationService.GenericErrorTitle"),
			$mhat("NotificationService.GenericErrorMessage"),
			NotificationSeverity.Critical);
	}

	private static emitNoClinics()
	{
		this.notify({
			event: NotifyEvent.NoClinics,
			type: NotificationType.Confirm,
			severity: NotificationSeverity.Reminder,
			title: $mhat("NotificationService.NoClinicsErrorTitle"),
			message: $mhat("NotificationService.NoClinicsErrorMessage"),
			confirm: {
				message: $mhat("NotificationService.FindClinicButtonText"),
			},
			route: Route.Clinics.FindClinic,
		});
	}
}
