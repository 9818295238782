

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class AppointmentsHeader extends Vue
	{
		@Prop({type: String}) headerText: string;

		get getHeaderText()
		{
			return this.headerText ? this.headerText : this.$mhat("AppointmentsHeader.HeaderText");
		}
	}
