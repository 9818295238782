
	import MoreIcon from "@/assets/images/icon-more.svg";
	import {ChatItem, ChatItemType} from "@/views/patient_user/telehealth/types";
	import {Options, Prop, Vue} from "vue-property-decorator";
	import {DATE_FORMAT} from "@/constants";
	import DocumentPreview from "@/components/Documents/DocumentPreview.vue";
	import DocumentService from "@/lib/document/service/DocumentService";
	import MhaDocument from "@/lib/document/model/MhaDocument";

	@Options({
		components: {DocumentPreview},
		emits: ["previewLoaded", "click"],
	})
	export default class ChatBubble extends Vue
	{
		@Prop() public readonly item: ChatItem;
		@Prop(Boolean) public readonly active: boolean;
		@Prop(Boolean) public readonly isLast: boolean;

		public loading = true;
		public expanded = true;
		public document: MhaDocument;

		MoreIcon = MoreIcon;

		// ==========================================================================
		// Vue lifecycle
		// ==========================================================================

		public async created(): Promise<void>
		{
			try
			{
				this.loading = true;

				if (this.isDocumentItem)
				{
					await this.loadDocument();
				}
			}
			finally
			{
				this.loading = false;
			}
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		protected async loadDocument(): Promise<void>
		{
			const documentService = new DocumentService();
			this.document = await documentService.getDocument(this.item.message);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get chatItemTime()
		{
			if (this.item.receivedAt)
			{
				return this.item.receivedAt.format("h:mm a");
			}
		}

		get receivedAtTime(): string
		{
			return this.item.receivedAt?.format(DATE_FORMAT.H_MM_A);
		}

		get isMessageItem(): boolean
		{
			return this.item.type === ChatItemType.MESSAGE;
		}

		get isDocumentItem(): boolean
		{
			return this.item.type === ChatItemType.DOCUMENT;
		}

		get isTypingItem(): boolean
		{
			return this.item.type === ChatItemType.TYPING;
		}

		get originClass(): string
		{
			return this.item.messageOrigin?.toLowerCase();
		}

		get isEventItem()
		{
			return this.item.type === ChatItemType.EVENT;
		}
	}

