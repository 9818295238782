import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container-sm"
}
const _hoisted_2 = {
  key: 0,
  class: "mha text helper",
  style: {"margin-top":"8px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrgImage = _resolveComponent("OrgImage")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_OrgImage, {
          "asset-type": _ctx.ORG_ASSET_TYPE.LOGO
        }, null, 8, ["asset-type"]),
        (_ctx.loggedInUser)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$mhat('HomePageLinks.WelcomeBackMessage', {patientFirstName: _ctx.loggedInUser.profile.firstName})), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_BaseButton, {
          onClick: _ctx.routeToBooking,
          color: _ctx.ButtonColor.PRIMARY,
          pattern: _ctx.ButtonColorPattern.DEFAULT
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$mhat('HomePageLinks.BookAppointment')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "color", "pattern"]),
        (!_ctx.isCloudMd)
          ? (_openBlock(), _createBlock(_component_RouteButton, {
              key: 1,
              route: _ctx.Route.Clinics.FindClinic,
              color: _ctx.ButtonColor.PRIMARY,
              pattern: _ctx.ButtonColorPattern.DEFAULT
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$mhat('HomePageLinks.FindClinic')), 1)
              ]),
              _: 1
            }, 8, ["route", "color", "pattern"]))
          : _createCommentVNode("", true),
        (!_ctx.isCloudMd)
          ? (_openBlock(), _createBlock(_component_RouteButton, {
              key: 2,
              route: _ctx.Route.Clinics.Home,
              color: _ctx.ButtonColor.PRIMARY,
              pattern: _ctx.ButtonColorPattern.DEFAULT
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$mhat('HomePageLinks.MyClinics')), 1)
              ]),
              _: 1
            }, 8, ["route", "color", "pattern"]))
          : _createCommentVNode("", true),
        _createVNode(_component_RouteButton, {
          route: _ctx.Route.Inbox.Home,
          color: _ctx.ButtonColor.PRIMARY,
          pattern: _ctx.ButtonColorPattern.DEFAULT
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$mhat('HomePageLinks.MyMessages')), 1)
          ]),
          _: 1
        }, 8, ["route", "color", "pattern"]),
        _createVNode(_component_RouteButton, {
          route: _ctx.Route.Appointments.Home,
          class: "appointments-desktop",
          color: _ctx.ButtonColor.PRIMARY,
          pattern: _ctx.ButtonColorPattern.DEFAULT
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$mhat('HomePageLinks.MyAppointments')), 1)
          ]),
          _: 1
        }, 8, ["route", "color", "pattern"]),
        _createVNode(_component_BaseButton, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.routeToImdHealth())),
          color: _ctx.ButtonColor.PRIMARY,
          pattern: _ctx.ButtonColorPattern.DEFAULT
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$mhat('HomePageLinks.SearchHealthInformation')), 1)
          ]),
          _: 1
        }, 8, ["color", "pattern"])
      ]))
    : (_openBlock(), _createBlock(_component_EventStateSpinner, {
        key: 1,
        "is-loading": _ctx.isLoading,
        "loading-icon": "icon-user-md",
        "loading-title": this.$mhat('HomePageLinks.LoadingTitle')
      }, null, 8, ["is-loading", "loading-title"]))
}