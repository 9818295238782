import {OneTimeCodeType} from "@/lib/oneTimeCode/model/OneTimeCodeType";
import OneTimeCode from "@/lib/oneTimeCode/model/OneTimeCode";
import {OneTimeCodeDtoCodeTypeEnum} from "@/open_api/generated";

export default class OneTimeCodeFactory
{
	// ==========================================================================
	// Class Methods
	// ==========================================================================

	/**
	 * generate a new one time code stub. submit this stub to the server
	 * to generate a new code
	 * @param codeType - the type of code you want.
	 */
	public static generateOneTimeCodeStub(codeType: OneTimeCodeType): OneTimeCode
	{
		switch (codeType)
		{
			case OneTimeCodeDtoCodeTypeEnum.PatientAccountIdCode:
				return new OneTimeCode(codeType, null, null);
		}
	}
}
