import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, _mergeProps({
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    class: "small-button",
    small: ""
  }, _ctx.$attrs, {
    onMouseenter: $options.mouseOver,
    onMouseleave: $options.mouseOut
  }), {
    default: _withCtx(() => [
      (!$data.hoverText)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($data.hoverText), 1))
    ]),
    _: 3
  }, 16, ["onMouseenter", "onMouseleave"]))
}