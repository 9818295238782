
	import {LOGO_BOOKING} from "@/assets/AppIcons";
	import {Options, Prop, Vue} from "vue-property-decorator";
	import {alertController} from "@ionic/vue";

	@Options({})
	export default class TelehealthHeader extends Vue
	{
		@Prop(Boolean) public readonly canToggle: boolean;
		@Prop(Boolean) public readonly loading: boolean;

		headerProps = {
			headerText: "Telehealth",
			headerIcon: LOGO_BOOKING,
			hasClose: true,
		};

		public leaveAppointment()
		{
			alertController
				.create({
					header: "Exiting Appointment",
					message: "Are you sure you want to exit your Telehealth appointment?",
					buttons: [
						{
							text: "Decline",
						},
						{
							text: "Accept",
							handler: () =>
							{
								this.$emit("exit");
							},
						}],
				})
				.then((alertEl) =>
				{
					alertEl.present();
				});
		}

		get statusChip()
		{
			return {
				class: [
					this.loading ? "" : "connected",
				],
				label: this.loading ? "Waiting for Patient" : "Patient Connected",
			};
		}
	}

