import {DependentAPI, PatientAPI} from "../services/Api";
import ClinicService from "./Clinic.service";
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import {AppointmentType, ProvinceCode} from "@/open_api/generated";
import ProviderGroup from "@/lib/clinic/providerGroup.model";
import ProviderTransferToProviderConverter from "@/lib/clinic/converter/ProviderTransferToProviderConverter";

export default class DependentClinicService extends ClinicService
{
	private readonly _dependentId;

	public constructor(dependentId)
	{
		super();
		this._dependentId = dependentId;
	}

	public async getCloudMdClinic(requiredFields?: boolean, provinceCode?: ProvinceCode): Promise<ClinicProfile>
	{
		const {data} = await DependentAPI().getDependentCloudMd(this.dependentId, requiredFields);
		return new ClinicProfile(data.clinic);
	}

	public async connectToClinic(clinicId, connectionRequest = {}): Promise<ClinicProfile>
	{
		const {data} = await DependentAPI().linkDependentWithClinic(this.dependentId, clinicId);
		return new ClinicProfile(data.clinic);
	}

	public async disconnectFromClinic(clinicId): Promise<ClinicProfile>
	{
		const {data} = await DependentAPI().unlinkDependentWithClinic(this.dependentId, clinicId);
		return new ClinicProfile(data.clinic);
	}

	public async getDependentClinic(clinicId): Promise<ClinicProfile>
	{
		const {data} = await DependentAPI().getDependentClinic(this.dependentId, clinicId);
		return new ClinicProfile(data);
	}

	public async getDependentClinicInfo(clinicId, requiredFields?: boolean): Promise<ClinicProfile>
	{
		const {data} = await DependentAPI().getDependentClinicInfo(this.dependentId, clinicId, requiredFields);
		return new ClinicProfile(data);
	}

	get dependentId()
	{
		return this._dependentId;
	}

	public async patientClinicProvider(clinicId, providerId)
	{
		const response = DependentAPI().dependentClinicProvider(this.dependentId, clinicId, providerId);
		return new ProviderTransferToProviderConverter().convert(response.data);
	}

	public async clinicAvailableAppointments(clinicId, providerId, appointmentTypeId, startDate, endDate)
	{
		return DependentAPI().dependentClinicAvailableAppointments(
			this.dependentId, clinicId, providerId, appointmentTypeId, startDate, endDate);
	}

	protected async viewableClinicsInLocRange(provinceCode, city, address, postalCode)
	{
		return DependentAPI().dependentLinkableClinicsByLocation(this.dependentId, provinceCode, city, address, postalCode);
	}

	protected async viewableClinicsInCoordRange(latitude, longitude)
	{
		return DependentAPI().dependentLinkableClinicsByCoordinates(this.dependentId, latitude, longitude);
	}

	protected async patientClinics(bookableOnly, messageableOnly, availableOnly, connectedOnly)
	{
		return DependentAPI().dependentClinics(this.dependentId, bookableOnly, messageableOnly, availableOnly, connectedOnly);
	}

	protected async patientClinic(clinicId)
	{
		return DependentAPI().getDependentClinic(this.dependentId, clinicId);
	}

	protected async patientClinicProviderGroup(clinicId, providerGroupId)
	{
		return DependentAPI().dependentClinicProviderGroup(this.dependentId, clinicId, providerGroupId);
	}

	protected async patientClinicProviderGroups(clinicId)
	{
		return await DependentAPI().dependentClinicProviderGroups(this.dependentId, clinicId);
	}

	protected async patientClinicProviderGroupsByProvider(clinicId, providerId)
	{
		return await DependentAPI().dependentClinicProviderGroupsByProvider(this.dependentId, clinicId, providerId);
	}

	protected async patientClinicProviderGroupProviders(clinicId, providerGroupId)
	{
		return await DependentAPI().dependentClinicProviderGroupProviders(this.dependentId, clinicId, providerGroupId);
	}

	protected async patientClinicProviders(clinicId)
	{
		return await DependentAPI().dependentClinicProviders(this.dependentId, clinicId);
	}

	public async getAppointmentTypes(clinicId, providerId): Promise<AppointmentType[]>
	{
		const {data} = await DependentAPI().dependentClinicAppointmentTypes(this.dependentId, clinicId, providerId);
		return data;
	}

	public async getAppointmentType(clinicId, appointmentTypeId): Promise<AppointmentType>
	{
		const {data} = await DependentAPI().dependentClinicAppointmentType(this.dependentId, clinicId, appointmentTypeId);
		return data;
	}
}
