
export default class Provider
{
	private _id: string;
	private _remoteId: string;
	private _firstName: string;
	private _lastName: string;
	private _prefix: string;
	private _suffix: string;
	private _specialty: string;

	/**
	 * create a provider
	 * @param id - the id of the provider group
	 * @param remoteId - the remote id of the provider
	 * @param firstName - the first name of the provivder
	 * @param lastName - the last name of the provider
	 * @param prefix - the provider's prefix
	 * @param suffix - the provider's suffix
	 * @param specialty - the specialty of the provider
	 */
	constructor(id: string,
	            remoteId: string,
	            firstName: string,
	            lastName: string,
	            prefix: string,
	            suffix: string,
	            specialty: string)
	{
		this.id = id;
		this.remoteId = remoteId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.prefix = prefix;
		this.suffix = suffix;
		this.specialty = specialty;
	}

	// ==========================================================================
	// Setters
	// ==========================================================================

	set id(value: string)
	{
		this._id = value;
	}

	set remoteId(value: string)
	{
		this._remoteId = value;
	}

	set firstName(value: string)
	{
		this._firstName = value;
	}

	set lastName(value: string)
	{
		this._lastName = value;
	}

	set prefix(value: string)
	{
		this._prefix = value;
	}

	set suffix(value: string)
	{
		this._suffix = value;
	}

	set specialty(value: string)
	{
		this._specialty = value;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get id()
	{
		return this._id;
	}

	get remoteId()
	{
		return this._remoteId;
	}

	get firstName()
	{
		return this._firstName;
	}

	get lastName()
	{
		return this._lastName;
	}

	get prefix()
	{
		return this._prefix;
	}

	get suffix()
	{
		return this._suffix;
	}

	get specialty()
	{
		return this._specialty;
	}

	public fullName(): string
	{
		return this.firstName + " " + this.lastName;
	}

	public fullNameWithPrefixSuffix(): string
	{
		const prefix = this.prefix ? (this.prefix + " ") : "";
		const suffix = this.suffix ? (", " + this.suffix) : "";

		return prefix + this.fullName() + suffix;
	}
}
