import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-539b780e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-view" }
const _hoisted_2 = { class: "content-scroll padding" }
const _hoisted_3 = { class: "top-content" }
const _hoisted_4 = { class: "clinic-info" }
const _hoisted_5 = { class: "info-top" }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "address" }
const _hoisted_8 = { class: "phone" }
const _hoisted_9 = { class: "bottom-content" }
const _hoisted_10 = {
  key: 2,
  class: "notes"
}
const _hoisted_11 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteButton = _resolveComponent("DeleteButton")!
  const _component_AppointmentCard = _resolveComponent("AppointmentCard")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString($props.clinic.name), 1),
            ($props.appointment.canCancel)
              ? (_openBlock(), _createBlock(_component_DeleteButton, {
                  key: 0,
                  onDelete: $options.confirmCancelAppointment
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(this.$mhat("AppointmentPage.CancelAppointmentText")), 1)
                  ]),
                  _: 1
                }, 8, ["onDelete"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("span", _hoisted_7, _toDisplayString($props.clinic.addressDisplayFullLine), 1),
          _createElementVNode("p", _hoisted_8, _toDisplayString($props.clinic.primaryPhoneDisplay), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_AppointmentCard, {
          class: "info-card",
          appointment: $props.appointment
        }, null, 8, ["appointment"]),
        ($options.showTelehealthButton)
          ? (_openBlock(), _createBlock(_component_BaseButton, {
              key: 0,
              primary: "",
              class: "telehealth-button",
              onClick: $options.handleClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$mhat("AppointmentPage.StartAppointmentText")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        ($props.appointment.canCancel)
          ? (_openBlock(), _createBlock(_component_BaseButton, {
              key: 1,
              class: "reschedule-button",
              pattern: _ctx.ButtonColorPattern.COLORED_TEXT_BORDER,
              onClick: $options.rescheduleAppointment
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$mhat("AppointmentPage.RescheduleAppointmentText")), 1)
              ]),
              _: 1
            }, 8, ["pattern", "onClick"]))
          : _createCommentVNode("", true),
        ($props.appointment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("h3", null, _toDisplayString(this.$mhat("AppointmentPage.AboutAppointmentText")), 1),
              ($props.clinic)
                ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($props.clinic.bookingNotes), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}