
	import {LOGO_PROFILE} from "@/assets/AppIcons";

	export default {
		name: "HeaderBarSecurity",
		data()
		{
			return {
				headerProps: {
					headerIcon: LOGO_PROFILE,
					headerText: this.headerText ? this.headerText : this.$mhat("HeaderBarSecurity.HeaderText"),
				},
			};
		},
	};
