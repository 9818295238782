import {AxiosResponse} from "axios";

import {
	PatientAccountCredentials,
	PatientAccountStatus,
	PatientNotificationSettings,
	PatientUserDto,
} from "@/open_api/generated";
import {PatientAPI} from "./Api";
import {Patient} from "@/lib/patient/Patient";
import AuthStore from "@/lib/vuex/auth.store";
import {Validator} from "@/lib/utils/Validator";
import {LoginToken} from "@/lib/models/LoginToken";
import DependentStore from "@/lib/vuex/modules/Dependent.store";

export default class PatientService
{
	// Public Functions
	public static getLocalProfileCopy(): Patient
	{
		return Patient.copyConstructor(AuthStore.loggedInPatient?.profile);
	}

	public static async updateProfile(userProfile: Patient)
	{
		const response: AxiosResponse<PatientUserDto> = await this.persistProfileToBackEnd(userProfile);
		this.updateLocalProfile(Patient.fromDto(response.data));
		return response;
	}

	public static async updateNotifications(sms: boolean, email: boolean)
	{
		const response: AxiosResponse<PatientNotificationSettings> = await this.persistNotificationsToBackEnd(sms, email);
		this.updateLocalNotifications(response.data);
	}

	public static async updateEmail(userProfile: Patient, currentPassword: string): Promise<void>
	{
		await this.validateEmail(userProfile);
		await this.persistEmailToBackEnd(userProfile.email, currentPassword);
	}

	public static async updatePassword(currentPassword: string, password: string, passwordConfirmation: string)
	{
		await this.persistPasswordToBackEnd(currentPassword, password, passwordConfirmation);
	}

	public static async deactivateAccount()
	{
		await this.deactivateAccountOnBackEnd();
	}

	public static async getDependents(): Promise<Patient[]>
	{
		const {data} = await PatientAPI().getDependents();
		return data.map((dependent) => Patient.fromDto(dependent));
	}

	/**
	 * get the patient user object of the dependent if dependent id is provided / not null
	 * otherwise get parent user.
	 * @param dependentId - dependent id
	 */
	public static async getDependentOrParentById(dependentId?: string): Promise<Patient>
	{
		if (dependentId)
		{
			const patient = DependentStore.dependent(dependentId);
			if (patient)
			{
				return patient;
			}
			else
			{
				await DependentStore.fetchDependents();
				return DependentStore.dependent(dependentId);
			}
		}
		else
		{
			return AuthStore.loggedInPatient?.profile;
		}
	}

	/**
	 * update the logged in patient's TZ to match the TZ in the browser
	 */
	public static async updatePatientTzToCurrentBrowserTz(): Promise<void>
	{
		const browserTz = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
		if (AuthStore.loggedInPatient.profile.timeZone !== browserTz)
		{
			AuthStore.loggedInPatient.profile.timeZone = browserTz;
			await this.updateProfile(AuthStore.loggedInPatient.profile);
		}
	}

	// ==========================================================================
	// Private Methods
	// ==========================================================================

	private static async deactivateAccountOnBackEnd()
	{
		const payload: PatientAccountStatus = {
			active: false,
		};

		return PatientAPI().deactivatePatient({account: payload});
	}

	private static async persistNotificationsToBackEnd(sms: boolean, email: boolean)
	{
		const payload: PatientNotificationSettings = {
			sms_notifications: sms,
			email_notifications: email,
		};

		return PatientAPI().updatePatientNotificationSettings({notifications: payload});
	}

	private static validateEmail(userProfile: Patient)
	{
		return Validator.emailAddress(userProfile.email);
	}

	private static async persistEmailToBackEnd(email: string, password: string)
	{
		const payload: PatientAccountCredentials = {
			email,
			current_password: password,
		};

		return PatientAPI().updateAccount({user: payload});
	}

	private static async persistPasswordToBackEnd(currentPassword: string, password: string, passwordConfirmation: string)
	{
		const payload: PatientAccountCredentials = {
			current_password: currentPassword,
			password,
			password_confirmation: passwordConfirmation,
		};

		return PatientAPI().updateAccount({user: payload});
	}

	private static async persistProfileToBackEnd(userProfile: Patient)
	{
		const patientTransfer: PatientUserDto = userProfile.toProfileTransferObj();
		return PatientAPI().updatePatient({
			profile: patientTransfer,
		});
	}

	private static updateLocalProfile(userProfile: Patient)
	{
		const loggedInUser = AuthStore.loggedInPatient;
		loggedInUser.profile = userProfile;
		AuthStore.setLoggedInUser(loggedInUser);
	}

	private static updateLocalNotifications(response)
	{
		const user = AuthStore.loggedInPatient;

		user.profile.emailNotifications = response.email_notifications;
		user.profile.smsNotifications = response.sms_notifications;

		AuthStore.setLoggedInUser(user);
	}
}
