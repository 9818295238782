import MHABackendLogger from "@/lib/utils/MHABackendLogger";

/**
 * get the value of the attribute from object, denoted by attributeString
 * @param obj - the object containing the attribute you wish to access
 * @param attributeString - a string indicating the attribute like "attr" or "foo.bar"
 */
export function getObjectAttribute(obj: any, attributeString: string): any
{
	let currObj = obj;
	const splitAttributes = attributeString.split(".");
	for (const attr of splitAttributes)
	{
		if (currObj === undefined || currObj === null)
		{
			break;
		}

		currObj = currObj[attr];
	}
	return currObj;
}

export async function playSound(soundUrl: string): Promise<void>
{
	try
	{
		await (new Audio(soundUrl)).play();
	}
	catch (error)
	{
		console.warn("Unable to play sound file: " + error);
	}
}
