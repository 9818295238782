

	import {Options, Vue} from "vue-property-decorator";
	import ReviewCard from "@/views/public/whitelabel/components/ReviewCard.vue";

	import imgTeam from "@/assets/images/graphics/odb/icons/team.svg";
	import imgNeedle from "@/assets/images/graphics/odb/icons/needle.svg";
	import imgDocument from "@/assets/images/graphics/odb/icons/document.svg";
	import ClinicLogo from "@/views/patient_user/clinic/components/ClinicLogo.vue";

	@Options({components: {ClinicLogo, ReviewCard}})
	export default class HowCanWeHelpCard extends Vue
	{
		public imgTeam = imgTeam;
		public imgNeedle = imgNeedle;
		public imgDocument = imgDocument;
	}
