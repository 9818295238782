import {Patient} from "@/lib/patient/Patient";

export interface Passwords
{
	currentPassword: string;
	confirmPassword: string;
	newPassword: string;
}

export interface PatientProfileMap
{
	[key: string]: Patient;
}

// Note that these must match the profile transfer object names
export enum PatientProfileField
{
	FIRST_NAME = "firstName",
	MIDDLE_NAME = "middleName",
	LAST_NAME = "lastName",
	EMAIL = "email",
	HEALTHCARE_PROVINCE_CODE = "healthCareProvinceCode",
	HEALTH_NUMBER = "healthNumber",
	HEALTH_NUMBER_VERSION = "healthNumberVersion",
	BIRTH_DATE = "birthDate",
	SEX = "sex",
	NEWBORN = "newborn",
	CELL_PHONE = "cellPhone",
	ADDRESS_1 = "address1",
	CITY = "city",
	ADDRESS_PROVINCE_CODE = "addressProvinceCode",
	POSTAL_CODE = "postalCode",
	COUNTRY_CODE = "countryCode",
	TIME_ZONE = "timeZone",
}

export enum PasswordField
{
	PASSWORD = "password",
	PASSWORD_CONFIRM = "confirmPassword",
}

export enum PatientProfileLabel
{
	FIRST_NAME = "First Name",
	MIDDLE_NAME = "Middle Name",
	LAST_NAME = "Last Name",
	EMAIL = "Email",
	HEALTHCARE_PROVINCE_CODE = "Healthcare Province",
	HEALTH_NUMBER = "Health Number",
	HEALTH_NUMBER_VERSION = "HIN Version Code",
	BIRTH_DATE = "Birth Date",
	SEX = "Sex At Birth",
	CELL_PHONE = "Cell Phone",
	ADDRESS_1 = "Street Address",
	CITY = "City",
	ADDRESS_PROVINCE_CODE = "Province",
	POSTAL_CODE = "Postal Code",
	COUNTRY_CODE = "Country",
	TIME_ZONE = "Timezone",
}

export interface ProfileDTO
{
	[PatientProfileField.FIRST_NAME]?: string;
	[PatientProfileField.MIDDLE_NAME]?: string;
	[PatientProfileField.LAST_NAME]?: string;
	[PatientProfileField.BIRTH_DATE]?: string;
	[PatientProfileField.HEALTHCARE_PROVINCE_CODE]?: string;
	[PatientProfileField.HEALTH_NUMBER]?: string;
	[PatientProfileField.HEALTH_NUMBER_VERSION]?: string;
	[PatientProfileField.SEX]?: string;
	[PatientProfileField.CELL_PHONE]?: string;
	[PatientProfileField.ADDRESS_1]?: string;
	[PatientProfileField.CITY]?: string;
	[PatientProfileField.ADDRESS_PROVINCE_CODE]?: string;
	[PatientProfileField.POSTAL_CODE]?: string;
	[PatientProfileField.COUNTRY_CODE]?: string;
	[PatientProfileField.TIME_ZONE]?: string;
}

export function createProfileDTO(): ProfileDTO
{
	return {
		[PatientProfileField.FIRST_NAME]: "",
		[PatientProfileField.MIDDLE_NAME]: "",
		[PatientProfileField.LAST_NAME]: "",
		[PatientProfileField.BIRTH_DATE]: "",
		[PatientProfileField.HEALTHCARE_PROVINCE_CODE]: "",
		[PatientProfileField.HEALTH_NUMBER]: "",
		[PatientProfileField.HEALTH_NUMBER_VERSION]: "",
		[PatientProfileField.SEX]: "",
		[PatientProfileField.CELL_PHONE]: "",
		[PatientProfileField.ADDRESS_1]: "",
		[PatientProfileField.CITY]: "",
		[PatientProfileField.ADDRESS_PROVINCE_CODE]: "",
		[PatientProfileField.POSTAL_CODE]: "",
		[PatientProfileField.COUNTRY_CODE]: "CA",
		[PatientProfileField.TIME_ZONE]: "",
	};
}

export function isRequired(field: PatientProfileField, required: PatientProfileField[], profile: ProfileDTO): boolean
{
	const hinRequired = required.includes(PatientProfileField.HEALTH_NUMBER);

	if (field === PatientProfileField.HEALTHCARE_PROVINCE_CODE)
	{
		return hinRequired;
	}
	else if (field === PatientProfileField.HEALTH_NUMBER_VERSION)
	{
		return hinRequired && profile[PatientProfileField.HEALTHCARE_PROVINCE_CODE] === "ON";
	}

	return required.includes(field);
}

export function getProfileFieldLabel(fieldId: PatientProfileField): PatientProfileLabel
{
	switch (fieldId)
	{
		case PatientProfileField.FIRST_NAME:
			return PatientProfileLabel.FIRST_NAME;
		case PatientProfileField.MIDDLE_NAME:
			return PatientProfileLabel.MIDDLE_NAME;
		case PatientProfileField.LAST_NAME:
			return PatientProfileLabel.LAST_NAME;
		case PatientProfileField.EMAIL:
			return PatientProfileLabel.EMAIL;
		case PatientProfileField.HEALTHCARE_PROVINCE_CODE:
			return PatientProfileLabel.HEALTHCARE_PROVINCE_CODE;
		case PatientProfileField.HEALTH_NUMBER:
			return PatientProfileLabel.HEALTH_NUMBER;
		case PatientProfileField.HEALTH_NUMBER_VERSION:
			return PatientProfileLabel.HEALTH_NUMBER_VERSION;
		case PatientProfileField.BIRTH_DATE:
			return PatientProfileLabel.BIRTH_DATE;
		case PatientProfileField.SEX:
			return PatientProfileLabel.SEX;
		case PatientProfileField.CELL_PHONE:
			return PatientProfileLabel.CELL_PHONE;
		case PatientProfileField.ADDRESS_1:
			return PatientProfileLabel.ADDRESS_1;
		case PatientProfileField.CITY:
			return PatientProfileLabel.CITY;
		case PatientProfileField.ADDRESS_PROVINCE_CODE:
			return PatientProfileLabel.ADDRESS_PROVINCE_CODE;
		case PatientProfileField.POSTAL_CODE:
			return PatientProfileLabel.POSTAL_CODE;
		case PatientProfileField.COUNTRY_CODE:
			return PatientProfileLabel.COUNTRY_CODE;
		case PatientProfileField.TIME_ZONE:
			return PatientProfileLabel.TIME_ZONE;
	}
}

export const SEX_OPTIONS = [{value: "M", name: "Male"}, {value: "F", name: "Female"}];
