

	import {noNull} from "@/lib/utils/prototype/String";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import BaseSelectionCard from "@/components/Buttons/BaseSelectionCard.vue";
	import {Options, Vue, Prop, Emit} from "vue-property-decorator";

	@Options({
		emits: ["click"],
		components: {BaseSelectionCard},
	})
	export default class ClinicCard extends Vue
	{
		// ==========================================================================
		// Properties
		// ==========================================================================
		@Prop({type: Object}) clinic: ClinicProfile;
		@Prop({type: Boolean}) active: boolean;
		@Prop({type: Boolean}) deleted: boolean;
		@Prop({type: Boolean}) clickable: boolean;

		selected(clinic: ClinicProfile)
		{
			this.$emit("click", clinic);
		}

		get cityProvince()
		{
			if (this.clinic)
			{
				const {city, provinceCode} = this.clinic;
				const cityDisplay = city ? city + "," : "";

				return `${cityDisplay} ${noNull(provinceCode)}`;
			}
		}
	}
