import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {ClinicAppointmentTransfer} from "@/open_api/generated";
import ClinicAppointment from "@/lib/appointment/model/ClinicAppointment";
import moment from "moment";

export default class ClinicAppointmentTransferToClinicAppointmentConverter
	extends AbstractConverter<ClinicAppointmentTransfer, ClinicAppointment>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: ClinicAppointmentTransfer): ClinicAppointment
	{
		return new ClinicAppointment(
			from.id,
			from.appointment_no,
			from.queued_appointment_id,
			from.patient_user_id,
			from.patient_clinic_appointment_id,
			from.is_cancelled,
			from.is_virtual,
			from.is_queued_appointment,
			moment(from.start_datetime_utc),
			moment(from.end_datetime_utc),
			from.app_name,
			from.appointment_type);
	}
}
