import DeviceInfo from "@/lib/DeviceInfo";
import {GeoLocation} from "@/lib/types/FindClinic";
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import Services from "@/lib/services/Services";
import { Patient } from "@/lib/patient/Patient";
import ClinicService from "@/lib/clinics/Clinic.service";
import { Route } from "@/router/router";
import {RouteLocationRaw} from "vue-router";

export class ClinicsFindController
{
	private _clinics: ClinicProfile[] = null;
	private _isLoading = false;
	// true if the clinic list is restricted for some reason.
	private _restrictClinicList = false;
	private _restrictedClinicsMessage = "";
	private _hasLocationAccess = true;
	private _patientUser: Patient = null;
	private _clinicService: ClinicService = null;
	private _geoLocation: GeoLocation = null;

	constructor(patientUser?: Patient, geoLocation?: GeoLocation)
	{
		this.clinics = [];
		this.patientUser = patientUser;
		this.clinicService = Services.PatientClinics;
		this.geoLocation = geoLocation;
	}

	public loadClinicsList()
	{
		this.isLoading = true;
		this.geoLocation ? this.findClinicsWithGeoLocation(this.geoLocation) : this.findClinicsWithCoordinates();
	}

	protected findClinicsWithGeoLocation(geoLocation)
	{
		const {provinceCode, postalCode, city, address} = geoLocation;
		this.clinicService.getClinicsInLocRange(provinceCode, city, address, postalCode)
			.then((clinics) =>
			{
				this.isLoading = false;
				this.clinics = clinics;
			})
			.catch(() =>
			{
				this.isLoading = false;
			});
	}

	protected findClinicsWithCoordinates()
	{
		DeviceInfo.getUserLocation()
			.then((position) =>
			{
				this.hasLocationAccess = true;

				if (position)
				{
					const {latitude, longitude} = position.coords;
					this.loadClinicsByCoords(latitude, longitude);
				}
			})
			.catch((err) =>
			{
				console.log("Failed to get location access, with error: ");
				console.log(err);
				this.hasLocationAccess = false;

				// location access error. pull all clinics
				this.loadAllLinkableClinics();
			});
	}

	protected loadClinicsByCoords(latitude: number, longitude: number)
	{
		this.clinicService.getClinicsInCoordRange(latitude, longitude)
			.then((clinics) =>
			{
				this.isLoading = false;
				this.clinics = clinics;
			})
			.catch(() =>
			{
				this.isLoading = false;
			});
	}

	/**
	 * load all clinics that the user can be linked to
	 */
	protected loadAllLinkableClinics()
	{
		this.clinicService.getLinkableClinics().then((clinics) =>
		{
			this.isLoading = false;
			this.clinics = clinics;
		}).catch((error) =>
		{
			console.error(error);
			this.isLoading = false;
			this.clinics = [];
		});
	}

	public noRestrictionsRoute(): RouteLocationRaw
	{
		return {
			name: Route.Clinics.FindClinic,
		};
	}

	get noClinics(): boolean
	{
		return !this.isLoading && (!this.clinics || this.clinics.length === 0);
	}

	get clinics(): ClinicProfile[]
	{
		return this._clinics;
	}

	set clinics(value: ClinicProfile[])
	{
		this._clinics = value;
	}

	get isLoading(): boolean
	{
		return this._isLoading;
	}

	set isLoading(value: boolean)
	{
		this._isLoading = value;
	}

	get hasLocationAccess(): boolean
	{
		return this._hasLocationAccess;
	}

	set hasLocationAccess(value: boolean)
	{
		this._hasLocationAccess = value;
	}

	get patientUser(): Patient
	{
		return this._patientUser;
	}

	set patientUser(value: Patient)
	{
		this._patientUser = value;
	}

	get clinicService(): ClinicService
	{
		return this._clinicService;
	}

	set clinicService(value: ClinicService)
	{
		this._clinicService = value;
	}

	get geoLocation(): GeoLocation
	{
		return this._geoLocation;
	}

	set geoLocation(value: GeoLocation)
	{
		this._geoLocation = value;
	}

	get restrictClinicList(): boolean
	{
		return this._restrictClinicList;
	}

	set restrictClinicList(value: boolean)
	{
		this._restrictClinicList = value;
	}

	get restrictedClinicsMessage(): string
	{
		return this._restrictedClinicsMessage;
	}

	set restrictedClinicsMessage(value: string)
	{
		this._restrictedClinicsMessage = value;
	}
}
