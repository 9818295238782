

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {IOSCallData, NativeBridge} from "@/lib/NativeBridge";
	import {PatientAPI} from "@/lib/services/Api";
	import {ChatItem, PeerData, TeleCallState} from "@/lib/telehealth/models/telehealth.types";
	import {PermissionType, Plugins} from "@capacitor/core";
	import TelehealthInterface, {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
	import {SessionData} from "@/lib/telehealth/models/sessionData.model";
	import {MediaDeviceStatus} from "@/lib/mediaDevices";
	import LogEvent from "@/lib/telehealth/models/LogEvent";
	import MHABackendLogger from "@/lib/utils/MHABackendLogger";
	import TelehealthApiServiceInterface from "@/lib/telehealth/api/TelehealthApiServiceInterface";
	import ChatMessageFactory from "@/lib/telehealth/factory/ChatMessageFactory";
	import ChatMessage from "@/lib/telehealth/models/ChatMessage";
	import ChatMessageToChatItemConverter from "@/lib/telehealth/conversion/ChatMessageToChatItemConverter";

	@Options({})
	export default class TelehealthIOSBase extends Vue
	{
		@Prop({type: String}) public readonly appointmentId: string;

		// AQS mode only
		@Prop({type: String, required: true}) organizationId: string;
		@Prop({type: String, required: true}) queuedAppointmentId: string;
		@Prop({type: String, required: true}) queueId: string;

		public chatItems: ChatItem[] = [];

		protected telehealthEngine: TelehealthInterface = null;
		protected telehealthApiService: TelehealthApiServiceInterface = null;
		protected callOver = false;
		protected callAlert: HTMLIonAlertElement;
		// delay before the call alert is displayed
		protected CALL_ALERT_DISPLAY_DELAY = 5000; // 5 seconds.

		// typing indication
		protected remoteTyping = false;
		protected remoteTypingTimeoutHandle: number = null;
		protected static readonly REMOTE_TYPING_TIMEOUT = 2000; // show typing dots for 2 seconds.

		// session ping
		protected sessionPingIntervalHandle: number;
		protected static readonly SESSION_PING_INTERVAL = 30000; // 30 seconds

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async created(): Promise<void>
		{
			await this.onCreated();
		}

		public async mounted(): Promise<void>
		{
			await this.onMounted();
			this.setupEventListeners();
			await this.reloadChatFromHistory();
			this.sessionPingIntervalHandle = window.setInterval(() => this.pingSession(), TelehealthIOSBase.SESSION_PING_INTERVAL);
		}

		public async unmounted(): Promise<void>
		{
			await this.onDestroyed();
			this.telehealthEngine.destroy();
			window.clearInterval(this.sessionPingIntervalHandle);
		}

		/**
		 * override this to hook in to the created life cycle event
		 */
		public async onCreated(): Promise<void>
		{

		}

		/**
		 * override this to hook in to the mounted life cycle event
		 */
		public async onMounted(): Promise<void>
		{

		}

		/**
		 * override this to hook in to the destroyed life cycle event
		 */
		public async onDestroyed(): Promise<void>
		{

		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async enterRoom(): Promise<void>
		{
			await this.telehealthEngine.enterRoom();
		}

		public async leaveRoom(): Promise<void>
		{
			await this.telehealthEngine.leaveRoom();
		}

		/**
		 * sends a text message to the remote clients
		 * @param message - the message to send
		 */
		public async sendTextMessage(message: string): Promise<void>
		{
			const chatMessage = new ChatMessageFactory().newMessage(message);

			// record message in DB
			const newChatMessage = await this.telehealthApiService.recordChatMessage(chatMessage);

			// send message to remote
			await this.telehealthEngine.sendChatMessage(newChatMessage);
		}

		/**
		 * signal to remote clients that this client is typing
		 */
		public async signalTyping(): Promise<void>
		{
			await this.telehealthEngine.sendTypingSignal();
		}

		public async toggleChat()
		{
			NativeBridge.openVideoView();
		}

		public onExit()
		{
			this.leaveRoom();
			this.$mhaRouterPush(this.Route.Home);
		}

		public callData(sessionData: SessionData): IOSCallData
		{
			if (sessionData)
			{
				const {sessionId, token, apiKey} = sessionData;
				return {
					sessionToken: token,
					sessionId,
					appointmentId: this.appointmentId,
					apiKey: apiKey.toString(),
					handle: "",
					voip: this.isCallKit(),
					videoEnabled: !this.isVideoDisabled(),
				};
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		/**
		 * true if this page was launched as part of a callkit action.
		 */
		public isCallKit(): boolean
		{
			return Boolean(this.$route.query.voip);
		}

		/**
		 * if true call is audio only. Video will be off by default (but user can toggle on if they want).
		 * Simply based on query param "videoDisabled"
		 */
		public isVideoDisabled(): boolean
		{
			return this.$route.query.videoDisabled !== undefined && this.$route.query.videoDisabled === "true";
		}

		public callState(): TELEHEALTH_STATE
		{
			return this.telehealthEngine.callState;
		}

		public legacyCallState(): TeleCallState
		{
			return this.telehealthEngine.legacyCallState;
		}

		public inRoom(): boolean
		{
			return this.telehealthEngine.callState === TELEHEALTH_STATE.IN_ROOM;
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		protected setupEventListeners(): void
		{
			this.telehealthEngine.onCallEnd(this.onCallEnd, this);
			this.telehealthEngine.onChatMessage(this.onChatMessage, this);
			this.telehealthEngine.onChatTyping(this.onChatTyping, this);
			this.telehealthEngine.onLogEvent(this.onLogEvent, this);
			this.telehealthEngine.onCallStateChange(this.onCallStateChange, this);
		}

		protected async onCallEnd(): Promise<boolean>
		{
			this.callOver = true;
			return false;
		}

		protected onChatMessage(remoteId: string, chatMessage: ChatMessage): void
		{
			this.chatItems.push(new ChatMessageToChatItemConverter().convert(chatMessage));
		}

		protected onChatTyping(remoteId: string): void
		{
			this.remoteTyping = true;

			if (this.remoteTypingTimeoutHandle)
			{
				window.clearTimeout(this.remoteTypingTimeoutHandle);
			}

			this.remoteTypingTimeoutHandle = window.setTimeout(
				() => this.remoteTyping = false,
				TelehealthIOSBase.REMOTE_TYPING_TIMEOUT);
		}

		protected onCallStateChange(state: TELEHEALTH_STATE): void
		{
		// override to react to call state changes.
		}

		protected onLogEvent(logEvent: LogEvent): void
		{
			this.telehealthApiService.logEvent(logEvent);
		}

		protected async pingSession()
		{
			await this.telehealthApiService.pingSession(this.legacyCallState());
		}

		protected dismissCallAlert(): void
		{
			if (this.callAlert)
			{
				this.callAlert.dismiss();
			}
		}

		/**
		 * get iOS AV permission status from native code
		 * @protected
		 */
		protected async getAVPermissions(): Promise<{audio: MediaDeviceStatus, video: MediaDeviceStatus}>
		{
			const cameraPermissions = await Plugins.Permissions.query({ name: PermissionType.Camera }).catch(() => null);

			if (cameraPermissions?.state === "granted")
			{
				return {
					audio: MediaDeviceStatus.GRANTED,
					video: MediaDeviceStatus.GRANTED,
				};
			}
			else
			{
				return {
					audio: MediaDeviceStatus.NOT_ALLOWED,
					video: MediaDeviceStatus.NOT_ALLOWED,
				};
			}
		}

		/**
		 * reload chat messages from history
		 */
		protected async reloadChatFromHistory(): Promise<void>
		{
			const chatHistory: ChatMessage[] = await this.telehealthApiService.getChatHistory();

			if (chatHistory)
			{
				chatHistory.forEach((chatMessage) =>
				{
					this.chatItems.push(new ChatMessageToChatItemConverter().convert(chatMessage));
				});
			}
		}
	}
