

	import calUtils from "./CalendarUtils";
	import {Options, Vue, Prop} from "vue-property-decorator";
	import moment, {Moment} from "moment";
	import {DATE_FORMAT} from "@/constants";

	@Options({})
	export default class CalendarDays extends Vue
	{
		@Prop({type: Object}) public readonly month: Moment;
		@Prop({type: Set}) public readonly activeDates: Set<string>;

		/* Data Properties */

		public datesForMonth: Array<Moment | null> = [];

		/* Lifecycle Methods */

		public created()
		{
			this.datesForMonth = calUtils.getDatesForMonth(this.month);
		}

		/* Instance Methods */

		selectDate(selectedDate: Moment)
		{
			this.$emit("date", selectedDate);
		}

		dateHasAppointments(date: Moment)
		{
			return this.activeDates.has(date.format(DATE_FORMAT.DATE_STRING));
		}

		getDateClass(date: Moment)
		{
			return {
				today: moment().isSame(date, "day"),
				bookable: this.dateHasAppointments(date),
				"day-content": true,
			};
		}
	}
