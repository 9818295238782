import {PharmacyDto} from "@/open_api/generated";

export class Pharmacy
{
	public static fromDto(dto: PharmacyDto): Pharmacy
	{
		const pharmacy = new Pharmacy();
		pharmacy.id = dto.id;
		pharmacy.name = dto.name;
		pharmacy.address1 = dto.address_1;
		pharmacy.city = dto.city;
		pharmacy.addressProvinceCode = dto.address_province_code;
		pharmacy.postalCode = dto.postal_code;
		pharmacy.countryCode = dto.country_code;
		pharmacy.primaryPhone = dto.primary_phone;
		pharmacy.logoUrl = dto.logo_url;
		pharmacy.advertisementFooterUrl = dto.advertisement_footer_url;
		pharmacy.brandColor = dto.brand_color;
		pharmacy.urlFriendlyName = dto.url_friendly_name;
		pharmacy.loginMessage = dto.custom_login_message;

		return pharmacy;
	}

	private _id: string;
	private _name: string;
	private _address1: string;
	private _city: string;
	private _addressProvinceCode: string;
	private _postalCode: string;
	private _countryCode: string;
	private _primaryPhone: string;
	private _logoUrl: string;
	private _advertisementFooterUrl: string;
	private _brandColor: string;
	private _urlFriendlyName: string;
	private _loginMessage: string;

	get id(): string
	{
		return this._id;
	}

	set id(value: string)
	{
		this._id = value;
	}

	get name(): string
	{
		return this._name;
	}

	set name(value: string)
	{
		this._name = value;
	}

	get address1(): string
	{
		return this._address1;
	}

	set address1(value: string)
	{
		this._address1 = value;
	}

	get city(): string
	{
		return this._city;
	}

	set city(value: string)
	{
		this._city = value;
	}

	get addressProvinceCode(): string
	{
		return this._addressProvinceCode;
	}

	set addressProvinceCode(value: string)
	{
		this._addressProvinceCode = value;
	}

	get postalCode(): string
	{
		return this._postalCode;
	}

	set postalCode(value: string)
	{
		this._postalCode = value;
	}

	get countryCode(): string
	{
		return this._countryCode;
	}

	set countryCode(value: string)
	{
		this._countryCode = value;
	}

	get primaryPhone(): string
	{
		return this._primaryPhone;
	}

	set primaryPhone(value: string)
	{
		this._primaryPhone = value;
	}

	get logoUrl(): string
	{
		return this._logoUrl;
	}

	set logoUrl(value: string)
	{
		this._logoUrl = value;
	}

	get brandColor(): string
	{
		return this._brandColor;
	}

	set brandColor(value: string)
	{
		this._brandColor = value;
	}

	get advertisementFooterUrl(): string
	{
		return this._advertisementFooterUrl;
	}

	set advertisementFooterUrl(value: string)
	{
		this._advertisementFooterUrl = value;
	}

	get urlFriendlyName(): string
	{
		return this._urlFriendlyName;
	}

	set urlFriendlyName(value: string)
	{
		this._urlFriendlyName = value;
	}

	get loginMessage(): string
	{
		return this._loginMessage;
	}

	set loginMessage(value: string)
	{
		this._loginMessage = value;
	}
}
