import AuthStore from "@/lib/vuex/auth.store";
import IllegalStateError from "@/lib/error/IllegalStateError";

export default abstract class BaseService
{
	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	/**
	 * perform an action by user type.
	 * @param patient - action to perform when user is patient
	 * @param clinicUser - action to perform when user is clinic user
	 * @param kiosk - action to perform when user is kiosk user
	 * @protected
	 */
	protected doByUserType(patient: () => any = null, clinicUser: () => any = null, kiosk: () => any = null): any
	{
		if (AuthStore.isPatient)
		{
			return patient();
		}
		else if (AuthStore.isClinic)
		{
			return clinicUser();
		}
		else if (AuthStore.isKiosk)
		{
			return kiosk();
		}
		else
		{
			throw new IllegalStateError("User type is not known");
		}
	}
}
