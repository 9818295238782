import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-center": _withCtx(() => [
      _createVNode(_component_EventStateSpinner, {
        isLoading: _ctx.spinnerModalPropsCall().isLoading,
        loadingTitle: _ctx.spinnerModalPropsCall().loadingTitle,
        loadingMessage: _ctx.spinnerModalPropsCall().loadingMessage,
        loadingIcon: _ctx.spinnerModalPropsCall().loadingIcon,
        successTitle: _ctx.spinnerModalPropsCall().successTitle,
        successMessage: _ctx.spinnerModalPropsCall().successMessage,
        successIcon: _ctx.spinnerModalPropsCall().successIcon,
        showFailureState: _ctx.spinnerModalPropsCall().showFailureState,
        failureIcon: _ctx.spinnerModalPropsCall().failureIcon,
        failureTitle: _ctx.spinnerModalPropsCall().failureTitle,
        failureMessage: _ctx.spinnerModalPropsCall().failureMessage,
        "container-class": _ctx.spinnerModalPropsCall().containerClass
      }, null, 8, ["isLoading", "loadingTitle", "loadingMessage", "loadingIcon", "successTitle", "successMessage", "successIcon", "showFailureState", "failureIcon", "failureTitle", "failureMessage", "container-class"]),
      (_ctx.showSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.successButtonProps, (button) => {
              return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_component_BaseButton, {
                  pattern: button.pattern,
                  color: button.color,
                  onClick: ($event: any) => (_ctx.buttonClick(button.click))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(button.label), 1)
                  ]),
                  _: 2
                }, 1032, ["pattern", "color", "onClick"])
              ]))
            }), 256))
          ]))
        : _createCommentVNode("", true),
      (_ctx.showFailure)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.failureButtonProps, (button) => {
              return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_component_BaseButton, {
                  pattern: button.pattern,
                  color: button.color,
                  onClick: ($event: any) => (_ctx.buttonClick(button.click))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(button.label), 1)
                  ]),
                  _: 2
                }, 1032, ["pattern", "color", "onClick"])
              ]))
            }), 256))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}