

	import {Options, Vue, Prop} from "vue-property-decorator";
	import defaultBackground from "@/assets/images/graphics/common/background.jpg";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import WhitelabelContentLayout from "@/views/public/whitelabel/components/WhitelabelContentLayout.vue";
	import {ProfileController} from "@/views/patient_user/profile/controllers/ProfileController";
	import {UserProfileController} from "@/views/patient_user/profile/controllers/UserProfileController";
	import LoginForm from "@/views/patient_user/login/components/LoginForm.vue";
	import {Route} from "@/router/router";
	import WhitelabelHeaderBar from "@/views/public/whitelabel/components/WhitelabelHeaderBar.vue";
	import OrganizationStore, {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";
	import OrgImage from "@/components/Organization/OrgImage.vue";

	@Options({components: {OrgImage, LoginForm, WhitelabelContentLayout, CorporateFooter, WhitelabelHeaderBar}})
	export default class WhitelabelLogin extends Vue
	{
		@Prop({type: String, required: true}) organizationId: string;

		public backgroundImage = defaultBackground;
		public controller: ProfileController = new UserProfileController();

		protected ORG_ASSET_TYPE = ORG_ASSET_TYPE;

		public onLoginSuccess()
		{
			const pushRoute = this.$route.query.push_route;

			if (pushRoute && pushRoute !== this.$route.path)
			{
				this.$mhaRouterPush(pushRoute as string);
			}
			else
			{
				this.$mhaRouterPush(Route.odbBookingRoute.Redirect);
			}
		}

		public onSignupRequest()
		{
			this.$mhaRouterPush(this.Route.Public.Organization.Signup);
		}

		get clinicId()
		{
			return OrganizationStore.organizationProfile?.primary_clinic_id;
		}

		get forgotPasswordRoute()
		{
			return {
				name: Route.Public.Organization.Forgot,
				params: {
					organizationId: this.organizationId,
				},
			};
		}

		get titleText()
		{
			return this.$mhat("WhitelabelLogin.TitleText");
		}

		get heroImageHeight()
		{
			if (window.innerHeight < 900)
			{
				return "1080px";
			}
			return "100vh";
		}
	}
