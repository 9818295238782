import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88c68ac4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_ContactClinicRouteButton = _resolveComponent("ContactClinicRouteButton")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, {
        "header-text": this.$mhat('BookingAppointmentType.HeaderText'),
        hasClose: _ctx.hasClose
      }, null, 8, ["header-text", "hasClose"])
    ]),
    _: 2
  }, [
    (!_ctx.showSpinner)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            (_ctx.isDependentMode)
              ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                  key: 0,
                  dependent: _ctx.controller.dependent
                }, null, 8, ["dependent"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("p", null, _toDisplayString(this.$mhat("BookingAppointmentType.WhichAppointmentTypeText")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appointmentTypes, (appointmentType) => {
                return (_openBlock(), _createBlock(_component_BaseButton, {
                  key: appointmentType.id,
                  onClick: () => _ctx.setSelected(appointmentType)
                }, {
                  default: _withCtx(() => [
                    (appointmentType.is_virtual)
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: _normalizeClass(["icon appointment-type-icon d-flex flex-row align-items-center justify-content-center p-4", _ctx.iconForAppointmentType(appointmentType)])
                        }, null, 2))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(appointmentType.description), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128)),
              (_ctx.showTherapistLink)
                ? (_openBlock(), _createBlock(_component_BaseButton, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onBookTherapist()))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        class: "virtualIcon",
                        src: _ctx.ICON_VIDEO,
                        alt: "TeleHealth Appointment Icon"
                      }, null, 8, _hoisted_1),
                      _createTextVNode(" " + _toDisplayString(this.$mhat("BookingAppointmentType.ConnectWithATherapist")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ])
        }
      : {
          name: "content-center",
          fn: _withCtx(() => [
            _createVNode(_component_EventStateSpinner, {
              "is-loading": _ctx.isLoading,
              "loading-icon": "icon-calendar-search",
              "loading-title": this.$mhat('BookingAppointmentType.LoadingTitle'),
              "show-failure-state": _ctx.noAppointmentTypes,
              "failure-icon": "icon-calendar-search",
              "failure-title": this.$mhat('BookingAppointmentType.LoadingFailureTitle'),
              "failure-message": this.$mhat('BookingAppointmentType.LoadingFailureMessage')
            }, null, 8, ["is-loading", "loading-title", "show-failure-state", "failure-title", "failure-message"]),
            (_ctx.noAppointmentTypes)
              ? (_openBlock(), _createElementBlock("div", { key: 0 }, [
                  _createVNode(_component_ContactClinicRouteButton, { clinicId: _ctx.clinicId }, null, 8, ["clinicId"]),
                  _createVNode(_component_RouteButton, {
                    pattern: _ctx.ButtonColorPattern.TRANSPARENT,
                    color: _ctx.ButtonColor.PRIMARY,
                    route: _ctx.Route.Home
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("BookingAppointmentType.CloseWindow")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "route"])
                ]))
              : _createCommentVNode("", true)
          ])
        }
  ]), 1024))
}