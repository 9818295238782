import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_RouteButton = _resolveComponent("RouteButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_EventStateSpinner, {
      isLoading: _ctx.isLoading,
      loadingIcon: "icon-find-a-clinic",
      loadingTitle: this.$mhat('ClinicSearchSpinner.LoadingTitle'),
      showFailureState: !_ctx.isLoading && _ctx.showFailure,
      failureIcon: "icon-clinic",
      failureTitle: _ctx.cFailureTitle,
      failureMessage: _ctx.cFailureMessage
    }, null, 8, ["isLoading", "loadingTitle", "showFailureState", "failureTitle", "failureMessage"]),
    (!_ctx.isLoading && _ctx.showFailure)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BaseButton, {
            pattern: _ctx.ButtonColorPattern.FILL,
            color: _ctx.ButtonColor.PRIMARY,
            onClick: _ctx.primaryAction
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.primaryText), 1)
            ]),
            _: 1
          }, 8, ["pattern", "color", "onClick"]),
          _createVNode(_component_RouteButton, {
            pattern: _ctx.ButtonColorPattern.TRANSPARENT,
            color: _ctx.ButtonColor.PRIMARY,
            route: _ctx.Route.Home
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.$mhat("ClinicSearchSpinner.CloseWindow")), 1)
            ]),
            _: 1
          }, 8, ["pattern", "color", "route"])
        ]))
      : _createCommentVNode("", true)
  ]))
}