import DeviceInfo from "@/lib/DeviceInfo";
import { GoogleAnalyticsInitPromise, gtag } from "@/lib/Google/GoogleAnalyticsInit";

export default class GoogleAnalyticService
{
	protected static EVENTS =
	{
		REGISTRATION_COMPLETE: {
			NAME: "conversion",
			SEND_TO: "AW-644761063/Zn8RCLG-3c4BEOeLubMC",
		},
	};

	public static async logGoogleEvent(eventName: string, sendTo: string)
	{
		return this.googleLogWrapper(() =>
		{
			// @ts-ignore
			gtag("event", eventName, {send_to: sendTo});
		});
	}

	public static async logGoogleRegistrationComplete()
	{
		return this.logGoogleEvent(this.EVENTS.REGISTRATION_COMPLETE.NAME, this.EVENTS.REGISTRATION_COMPLETE.SEND_TO);
	}

	private static async googleLogWrapper(logFunc: () => void)
	{
		if (DeviceInfo.isCloudMd && DeviceInfo.isProduction())
		{
			try
			{
				await GoogleAnalyticsInitPromise;

				// @ts-ignore
				if (typeof gtag !== "undefined")
				{
					logFunc();
				}
			}
			catch (e)
			{
				console.error("Google event log error: " + e);
			}
		}
	}
}
