import {OrganizationFontType} from "@/open_api/generated";

/**
 * override a font
 * @param fontFace - the font face to use instead
 * @param fontToOverride - the font name to override. css variable name
 */
export function overrideFont(fontFace: FontFace, fontToOverride: string)
{
	const overrides = document.querySelector("#app .style-override") as HTMLElement;

	document.fonts.add(fontFace);
	overrides.style.setProperty(fontToOverride, fontFace.family);
}

/**
 * clears any font override for the given font
 * @param font - the font name to clear the override for. css variable name
 */
export function clearFontOverride(font: string)
{
	const overrides = document.querySelector("#app .style-override") as HTMLElement;

	overrides.style.setProperty(font, "unset");
}

/**
 * delete any fonts from the document with the given font family.
 * @param fontFamily - the font family to delete
 */
export function deleteFontFromDOM(fontFamily: string): void
{
	document.fonts.forEach((font) =>
	{
		if (font.family === fontFamily)
		{
			document.fonts.delete(font);
		}
	});
}
