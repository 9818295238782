

	export default {
		name: "DependentsEventStateSearch",
		components: {},
		props: {
			isLoading: Boolean,
		},

		data()
		{
			return {
				loadingTitle: this.$mhat("DependentsEventStateSearch.LoadingTitle"),
				loadingMessage: null,
				noResultsTitle: this.$mhat("DependentsEventStateSearch.NoResultsTitle"),
				noResultsMessage: null,
			};
		},
	};
