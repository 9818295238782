
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import DeviceInfo from "@/lib/DeviceInfo";
	import OrgImage from "@/components/Organization/OrgImage.vue";
	import OrganizationStore, {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";

	export default {
		name: "ClinicLogo",
		components: {OrgImage},
		props: {
			clinic: ClinicProfile,
			orgLog: Boolean,
			iconFontSize: {type: String, default: "88px"},
			diameter: {type: String, default: "120px"},
			borderWidth: {type: String, default: "8px"},
			borderColor: {type: String},
		},

		data: () =>
		{
			return {
				ORG_ASSET_TYPE,
			};
		},

		computed: {
			clinicHasIcon()
			{
				return this.clinic.iconPath && this.clinic.iconPath.length > 0;
			},

			fullIconPath()
			{
				return this.clinic.iconPath;
			},

			brandIcon()
			{
				return DeviceInfo.getAppFontIcon();
			},

			hasOrgAsset()
			{
				return OrganizationStore.hasAsset(ORG_ASSET_TYPE.LOGO);
			},

			outerCircleStyle()
			{
				return {
					width: this.diameter,
					height: this.diameter,
					"background-color": this.borderColor,
				};
			},
			innerCircleStyle()
			{
				const size = "calc(" + this.diameter + " - " + this.borderWidth + " * 2)";
				return {
					width: size,
					height: size,
				};
			},

			iconStyle()
			{
				return {
					"font-size": this.iconFontSize,
				};
			},
		},
	};
