
	import uuidv4 from "uuid/v4";
	import {NotifyEvent, WebNotification, WebNotificationClear} from "@/lib/types/Notifier";
	import {EventBus, LoginEvent} from "@/lib/utils/EventBus";
	import DeviceInfo from "@/lib/DeviceInfo";
	import AuthStore from "@/lib/vuex/auth.store";
	import PatientDataSpinner from "@/components/EventState/PatientDataSpinner.vue";
	import DependentStore from "@/lib/vuex/modules/Dependent.store";
	import ConfigStore from "@/lib/vuex/modules/Config.store";
	import {Route} from "@/router/router";
	import {registerWebPlugin} from "@capacitor/core";
	import {FileSharer} from "@byteowls/capacitor-filesharer";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {IonApp} from "@ionic/vue";
	import PatientService from "@/lib/services/PatientService";
	import DependentService from "@/lib/dependents/Dependent.service";

	export default {
		name: "App",
		components: {PatientDataSpinner, IonApp},
		data()
		{
			return {
				isLoading: false,
				dataInitError: null,
				routeId: null,
				isMobile: DeviceInfo.isMobile(),
				notification: null as Notification,
				notificationId: null,
				isCloudMd: DeviceInfo.isCloudMd,
			};
		},

		async created()
		{
			registerWebPlugin(FileSharer);

			if (this.checkCookies())
			{
				this.initUserData();
				this.initializeNotificationListeners();
			}
		},

		mounted()
		{
			OrganizationStore.applyOrganizationColors();
			OrganizationStore.applyOrganizationFonts();
		},

		methods: {
			async initUserData()
			{
				this.isLoading = true;

				// TODO: Refactor how patient and clinic users are detected
				try
				{
					if (await AuthStore.verifyLogin(this.$router.currentRoute) && AuthStore.isPatient)
					{
						await DependentStore.fetchDependents();
						// on first load update TZ to match browser TZ
						PatientService.updatePatientTzToCurrentBrowserTz();
						DependentService.updateAllDependentTzToCurrentBrowserTz();
					}
					await ConfigStore.fetchAll();
				}
				catch (error)
				{
					this.dataInitError = error;
					throw error;
				}

				this.isLoading = false;
			},

			initializeNotificationListeners()
			{
				EventBus.$on(LoginEvent.LoginSuccess, this.initUserData);

				WebNotification.$all((notification: Notification) =>
				{
					this.notification = notification;
					this.notificationId = uuidv4();
				});

				EventBus.$on(WebNotificationClear, (event?: NotifyEvent) =>
				{
					if (!event)
					{
						this.notification = null;
					}
					else if (this.notification && this.notification.event === event)
					{
						this.notification = null;
					}
				});
			},

			checkCookies()
			{
				if (!navigator.cookieEnabled)
				{
					this.$mhaRouterPush(Route.Public.CookiesNotEnabled);
				}
				return navigator.cookieEnabled;
			},

			dismissNotification()
			{
				this.notification = null;
			},

			confirmNotification()
			{
				if (this.notification.route)
				{
					this.$mhaRouterPush(this.notification.route);
				}

				this.notification = null;
			},
		},

		watch: {
			$route(to, from)
			{
				this.routeId = to.meta.routeId;
			},

			// update organization colors when org profile changes
			getOrganization()
			{
				OrganizationStore.applyOrganizationColors();
				OrganizationStore.applyOrganizationFonts();
			},
		},

		computed: {
			getOrganization()
			{
				return OrganizationStore.organizationProfile;
			},
		},
	};
