
	import {Options, Vue} from "vue-property-decorator";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import TelehealthWrapper from "@/components/telehealth/TelehealthWrapper.vue";
	import PatientTelehealthHeader from "@/views/patient_user/appointment/components/PatientTelehealthHeader.vue";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import PublicWaitingRoomOverlay from "@/views/public/PublicWaitingRoomOverlay.vue";
	import TelehealthBase from "@/components/telehealth/TelehealthBase.vue";
	import {Route} from "@/router/router";
	import {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
	import {IonPage} from "@ionic/vue";
	import VideoViewForIonSlides from "@/components/telehealth/VideoViewForIonSlides.vue";

	@Options({
		components: {IonPage, AppChip, TelehealthWrapper, PatientTelehealthHeader, VideoViewForIonSlides, ChatView, PublicWaitingRoomOverlay},
	})
	export default class PublicTelehealth extends TelehealthBase
	{
		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async onExit(): Promise<void>
		{
			await this.endCall();
			this.$mhaRouterPush(Route.Login.Home);
		}

		protected setCallStateQueryParam(callState: TELEHEALTH_STATE): void
		{
			this.$router.replace({query: {state: callState, token: this.$route.query.token}});
		}
	}
