

	import {Options, Vue, Prop} from "vue-property-decorator";
	import BaseSelectionCard from "@/components/Buttons/BaseSelectionCard.vue";
	import {Mail} from "@/lib/Mailbox/Mail.model";
	import {SelectionCardColorPattern} from "@/components/Buttons/types";

	@Options({
		emits: ["click"],
		components: {BaseSelectionCard},
	})
	export default class MessageCard extends Vue
	{
		@Prop({type: Object}) mail: Mail;
		@Prop({type: Boolean}) active: boolean;
		@Prop({type: [String, Object]}) route: any;
		@Prop({type: Boolean}) clickable: boolean;

		public ripple = true;
		public mousePressed = false;
		SelectionCardColorPattern = SelectionCardColorPattern;

		public handleClick()
		{
			if (!this.mousePressed)
			{
				this.$emit("click", this.mail);
			}

			this.goToRoute();
		}

		public goToRoute()
		{
			if (this.route)
			{
				this.$mhaRouterPush(this.route);
			}
		}

		get borderDate()
		{
			return {
				top: this.mail.createdAt.format("MMM"),
				bottom: this.mail.createdAt.date(),
			};
		}

		get messageHeader()
		{
			if (this.mail.isSent)
			{
				return this.$mhat("MessageCard.SentMessageHeader", {recipientNames: this.mail.recipientNames});
			}

			return this.$mhat("MessageCard.ReceivedMessageHeader", {senderName: this.mail.senderName});
		}

		get cardPattern()
		{
			return (this.mail.hasNotification) ? SelectionCardColorPattern.DEFAULT : SelectionCardColorPattern.FADED;
		}
	}
