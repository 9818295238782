import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00d7c1cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-col mha-font-body-lg h-100"
}
const _hoisted_2 = { class: "section" }
const _hoisted_3 = { class: "font-color-neutral-grey mha-font-body-sm m-b-8" }
const _hoisted_4 = { class: "section" }
const _hoisted_5 = { class: "font-color-neutral-grey mha-font-body-sm m-b-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MailHeader = _resolveComponent("MailHeader")!
  const _component_Message = _resolveComponent("Message")!
  const _component_TabBar = _resolveComponent("TabBar")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, {
    contentWidthFull: "",
    contentHeaderRemoveFalseHeaderSpace: ""
  }, _createSlots({
    "content-top": _withCtx(() => [
      (_ctx.conversation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(this.$mhat("MessageRead.ClinicSectionHeader")), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.clinicName), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(this.$mhat("MessageRead.SubjectSectionHeader")), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.conversation.lastMessage.subject), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conversation.messages, (message) => {
              return (_openBlock(), _createBlock(_component_Message, {
                key: message.id,
                message: message
              }, null, 8, ["message"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    "fixed-footer": _withCtx(() => [
      _createVNode(_component_TabBar, { "tab-bar-items": _ctx.tabBarItems }, null, 8, ["tab-bar-items"])
    ]),
    _: 2
  }, [
    (_ctx.$isMobile)
      ? {
          name: "content-header",
          fn: _withCtx(() => [
            _createVNode(_component_MailHeader, {
              "no-back": "",
              "header-text": this.$mhat('MessageRead.HeaderText'),
              "button-opts": _ctx.headerButtonOpts
            }, null, 8, ["header-text", "button-opts"])
          ])
        }
      : undefined
  ]), 1024))
}