

	import {Options, Vue} from "vue-property-decorator";

	import {PublicAPI} from "@/lib/services/Api";
	import SignupHeaderBar from "./components/SignupHeaderBar.vue";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import {UserProfileController} from "../profile/controllers/UserProfileController";
	import {ProfileController} from "../profile/controllers/ProfileController";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {Patient} from "@/lib/patient/Patient";
	import LoadingQueue from "@/lib/LoadingQueue";
	import SignupForm from "@/views/patient_user/login/components/SignupForm.vue";
	import PasswordController from "@/views/patient_user/login/controllers/PasswordController";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {OrganizationProfileDto} from "@/open_api/generated";
	import {BaseButtonOpts, ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import WhitelabelHeaderBar from "@/views/public/whitelabel/components/WhitelabelHeaderBar.vue";

	@Options({components: {SignupHeaderBar, CorporateFooter, SignupForm, WhitelabelHeaderBar}})
	export default class SignupPage extends Vue
	{
		public clinicId: string = null;
		public clinic: ClinicProfile = null;
		public organization: OrganizationProfileDto = null;
		public profileController: ProfileController = new UserProfileController();
		public passwordController: PasswordController = new PasswordController();
		public loadingQueue: LoadingQueue = new LoadingQueue();

		public async created()
		{
			this.loadingQueue.pushLoadingState();
			this.organization = OrganizationStore.organizationProfile;
			this.profileController.userProfile = Patient.fillFromQueryString(this.$route.query);
			this.clinicId = this.$route.query.clinicId as string;

			if (this.isWhitelabelMode)
			{
				this.clinicId = this.organization.primary_clinic_id;
			}

			try
			{
				if (this.clinicId && (!this.$isCloudMd || this.isWhitelabelMode))
				{
					await this.fetchClinic();
				}
			}
			finally
			{
				this.loadingQueue.popLoadingState();
			}
		}

		public async fetchClinic()
		{
			const {data} = await PublicAPI().getPublicClinicData(this.clinicId, true);
			this.clinic = new ClinicProfile(data);
		}

		public onClose(): void
		{
			const route = (this.isWhitelabelMode) ? this.Route.Public.Organization.Home : this.Route.Login.Home;
			this.$mhaRouterPush(route);
		}

		get backRouteHref(): string
		{
			return ((this.isWhitelabelMode) ? "/org/" + this.organization.url_friendly_name : "") + "/login";
		}

		get isBrandedMode()
		{
			return Boolean(this.clinicId);
		}

		get isWhitelabelMode()
		{
			return Boolean(this.organization);
		}

		get titleText()
		{
			if (this.isWhitelabelMode)
			{
				return this.$mhat("SignupPage.WhitelabelSignupText");
			}
			return this.$mhat("SignupPage.SignupText");
		}

		get successButtonProps()
		{
			if (this.isWhitelabelMode)
			{
				return [
					{
						label: this.$mhat("SignupPage.BackToLoginButtonText"),
						color: ButtonColor.PRIMARY,
						pattern: ButtonColorPattern.FILL,
						click: () =>
						{
							this.$mhaRouterPush({
								name: this.Route.Public.Organization.Login,
								params: {organizationId: this.organization.id},
							});
						},
					} as BaseButtonOpts,
				];
			}
		}

		get forgotRoute()
		{
			if (this.isWhitelabelMode)
			{
				return {
					name: this.Route.Public.Organization.Forgot,
					params: {organizationId: OrganizationStore?.organizationProfile?.id},
				};
			}
			else
			{
				return {name: this.Route.Login.Forgot};
			}
		}
	}
