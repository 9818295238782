import {ClinicsRoutes} from "@/router/patient_user/clinics.routes";
import {getRouteOrWhitelabelRoute} from "@/router/whitelableUtil";

export class ClinicsRouteCollection
{
	// ==========================================================================
	// Singleton impl
	// ==========================================================================

	private static instance: ClinicsRouteCollection;

	public static getInstance(): ClinicsRouteCollection
	{
		if (!this.instance)
		{
			this.instance = new ClinicsRouteCollection();
		}
		return this.instance;
	}

	private constructor()
	{
	}

	// ==========================================================================
	// Routes
	// ==========================================================================

	get Home(): string
	{
		return getRouteOrWhitelabelRoute(ClinicsRoutes.Home);
	}

	get FindClinic(): string
	{
		return getRouteOrWhitelabelRoute(ClinicsRoutes.FindClinic);
	}
}

export const clinicsRouteCollection = ClinicsRouteCollection.getInstance();
