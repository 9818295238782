
	import {Options, Prop, Vue} from "vue-property-decorator";
	import MhaDocument from "@/lib/document/model/MhaDocument";
	import FileUtil from "@/lib/utils/FileUtil";
	import {IonSpinner} from "@ionic/vue";

	@Options({components: {IonSpinner}, emits: ["loaded"]})
	export default class DocumentPreview extends Vue
	{
		@Prop({type: Object, required: true}) document: MhaDocument;

		public loading = true;
		public documentBase64Data: string;

		// ==========================================================================
		// Vue lifecycle
		// ==========================================================================

		public async created(): Promise<void>
		{
			try
			{
				this.loading = true;
				await this.loadDocumentData();
			}
			finally
			{
				this.$emit("loaded");
				this.loading = false;
			}
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		protected async loadDocumentData(): Promise<void>
		{
			this.documentBase64Data = await FileUtil.getAuthenticatedFileAsBase64(this.document.url);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get isFileDocumentType(): boolean
		{
			return !!this.document.type.match("^application/.*");
		}

		get isFileTextType(): boolean
		{
			return !!this.document.type.match("^text/.*");
		}

		get isImageFileType(): boolean
		{
			return !!this.document.type.match("^image/*");
		}

		get canPreviewFile(): boolean
		{
			return this.document.canPreview;
		}
	}
