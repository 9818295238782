

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class DeviceFrame extends Vue
	{
		@Prop({type: String}) deviceType: string;
		@Prop({type: Boolean}) dark: boolean;
		@Prop({type: Number}) width: number;
		@Prop({type: Number}) height: number;
		@Prop({type: Boolean, default: false}) noBorder;

		get computedDeviceStyle()
		{
			return {
				width: this.width + "px",
				height: this.height + "px",
			};
		}
	}
