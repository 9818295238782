

	import {Options, Prop, Vue} from "vue-property-decorator";

	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import BasicList from "@/components/List/BasicList.vue";
	import {BasicListType} from "@/lib/types/ListType";
	import FileChooser from "@/components/Inputs/FileChooser.vue";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import {alertController} from "@ionic/vue";
	import MHABackendLogger from "@/lib/utils/MHABackendLogger";

	@Options({components: {BasicList, FileChooser}})
	export default class AttachmentSelector extends Vue
	{
		@Prop({type: String}) accept: string;

		public attachments: File[] = [];
		public maxFiles = 6;

		public filePickerButtonProps = {
			color: ButtonColor.PRIMARY,
			pattern: ButtonColorPattern.TRANSPARENT,
		};

		public triggerFileChooser()
		{
			const chooser: FileChooser = this.$refs.fileChooser as FileChooser;
			chooser.pickNewFiles();
		}

		public async addAttachment(fieldName, fileList: File[])
		{
			if (!fileList.length)
			{
				return;
			}
			if ((fileList.length + this.attachments.length) > this.maxFiles)
			{
				WebNotification.$emit({
					event: NotifyEvent.Generic,
					type: NotificationType.Swipe,
					severity: NotificationSeverity.Critical,
					title: this.$mhat("AttachmentSelector.GenericAttachmentErrorTitle"),
					message: this.$mhat("AttachmentSelector.MaxNumberOfFilesErrorMessage", {maxNumberOfFiles: this.maxFiles}),
					timeout: 3000,
				});
			}
			else
			{
				const attachmentNames = Array.from(this.attachments, (attachment) => attachment.name);
				const noDuplicateFileArray = Array.from(fileList).filter((file) =>
				{
					return !attachmentNames.includes(file.name);
				});
				this.attachments = this.attachments.concat(noDuplicateFileArray);

				try
				{
					await this.attachmentsChanged();
				}
				catch (error)
				{
					WebNotification.$emit({
						event: NotifyEvent.Generic,
						type: NotificationType.Swipe,
						severity: NotificationSeverity.Critical,
						title: this.$mhat("AttachmentSelector.GenericAttachmentErrorTitle"),
						message: this.$mhat("AttachmentSelector.GenericAttachmentErrorMessage"),
						timeout: 3000,
					});

					throw error;
				}
			}
		}

		public removeAttachment(itemToRemove)
		{
			return alertController
				.create({
					header: this.$mhat("AttachmentSelector.RemoveAttachmentAlertHeader"),
					message: this.$mhat("AttachmentSelector.RemoveAttachmentAlertMessage"),
					buttons: [this.$mhat("AttachmentSelector.DisagreeButtonText"),
						{
							text: this.$mhat("AttachmentSelector.AgreeButtonText"),
							handler: async() =>
							{
								this.attachments = this.attachments.filter((item) =>
								{
									return item.name !== itemToRemove.id;
								});

								try
								{
									await this.attachmentsChanged();
								}
								catch (error)
								{
									WebNotification.$emit({
										event: NotifyEvent.Generic,
										type: NotificationType.Swipe,
										severity: NotificationSeverity.Critical,
										title: this.$mhat("AttachmentSelector.GenericAttachmentErrorTitle"),
										message: this.$mhat("AttachmentSelector.RemoveAttachmentErrorMessage"),
										timeout: 3000,
									});

									throw error;
								}
							},
						}],
				})
				.then((a) => a.present());
		}

		public async attachmentsChanged()
		{
			const encodedFiles = await Promise.all(this.attachments.map(async (file) =>
			{
				try
				{
					return {
						name: file.name,
						type: file.type,
						size: file.size,
						data: await this.toBase64(file),
					};
				}
				catch (error)
				{
					throw new Error(error);
				}
			}));

			this.$emit("change", encodedFiles);
		}

		private toBase64(file: File): Promise<string>
		{
			return new Promise((resolve: any, reject) =>
			{
				const reader = new FileReader();
				reader.readAsDataURL(file);

				reader.onload = () =>
				{
					// only get the base 64 data, omit the meta info
					const base64result = reader.result as string;
					const base64resultData = base64result.split(",")[1];
					resolve(base64resultData);
				};
				reader.onerror = (error) => reject(reader.error);
			});
		}

		get attachmentListLeft(): BasicListType[]
		{
			return this.attachmentList.filter((item, index) =>
			{
				return index % 2 === 0;
			});
		}

		get attachmentListRight(): BasicListType[]
		{
			return this.attachmentList.filter((item, index) =>
			{
				return index % 2 === 1;
			});
		}

		get attachmentList(): BasicListType[]
		{
			return this.attachments.map((file) =>
			{
				return {
					id: file.name,
					label: file.name,
				};
			});
		}
	}
