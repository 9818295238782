

	import {Options, Vue} from "vue-property-decorator";
	import HeaderLogo from "@/components/Header/HeaderLogo.vue";

	@Options({components: {HeaderLogo}})
	export default class KioskBranded extends Vue
	{

	}
