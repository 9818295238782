
	import { Options, Prop, Vue, Watch } from "vue-property-decorator";
	import RoundButton from "@/components/Buttons/RoundButton.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {VideoControlState} from "@/lib/telehealth/models/telehealth.types";

	@Options({components: {RoundButton}})
	export default class VideoViewControls extends Vue
	{
		@Prop(Object) public readonly state: VideoControlState;
		@Prop({type: Boolean, default: false}) showViewCycle: boolean;

		ButtonColorPattern = ButtonColorPattern;
		ButtonColor = ButtonColor;

		private callMessageDisplayTime = 10000; // 10 seconds
		private showCallMessage = false;
		// if true. call message div is set to height zero.
		private zeroHeightCallMessage = false;

		private readonly BUTTON_TEMPORARY_DISABLE_TIME = 3000; // 3 seconds
		public callButtonDisabled = false;
		public toggleVideoButtonDisabled = false;

		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		public created(): void
		{
			this.showCallMessage = this.AuthStore.isClinic;
		}

		// ==========================================================================
		// Vue Watches
		// ==========================================================================

		@Watch("state")
		private onStatusChange(newState: VideoControlState, oldState: VideoControlState): void
		{
			if (!newState.canEnd && oldState.canEnd)
			{ // we have transitioned from in call to out of call.
				this.setupCallMessageTimeout();
				this.zeroHeightCallMessage = false;
			}
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public call()
		{
			this.$emit("call");
			this.disableCallButtonForAMoment();
			this.showCallMessage = false;
		}

		public hangup()
		{
			this.$emit("end");
			this.disableCallButtonForAMoment();
		}

		public toggleVideo()
		{
			this.$emit("toggle-video");
			this.disableVideoToggleForAMoment();
		}

		public onCallMessageTransitionEnd()
		{
			this.zeroHeightCallMessage = !this.callMessageClasses.visible;
		}

		// ==========================================================================
		// Private Methods
		// ==========================================================================

		// setup delay, after which the call message is displayed.
		private setupCallMessageTimeout(): void
		{
			if (this.AuthStore.isClinic)
			{
				setTimeout(() =>
				{
					this.showCallMessage = true;
				}, this.callMessageDisplayTime);
			}
		}

		private disableCallButtonForAMoment()
		{
			this.callButtonDisabled = true;
			setTimeout(() => this.callButtonDisabled = false, this.BUTTON_TEMPORARY_DISABLE_TIME);
		}

		private disableVideoToggleForAMoment()
		{
			this.toggleVideoButtonDisabled = true;
			setTimeout(() => this.toggleVideoButtonDisabled = false, this.BUTTON_TEMPORARY_DISABLE_TIME);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get callMessageClasses(): {visible: boolean, "zero-height": boolean}
		{
			return {
				visible: !this.state.canEnd && this.showCallMessage,
				"zero-height": this.zeroHeightCallMessage,
			};
		}

		get muteStateClass()
		{
			return [this.state.muted ? "icon-tele-volume-mic-mute" : "icon-tele-volume-mic"];
		}

		get videoStateClass()
		{
			return [this.state.videoEnabled ? "icon-view" : "icon-view-off"];
		}
	}
