import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileHeaderBar = _resolveComponent("ProfileHeaderBar")!
  const _component_ProfileControlButtons = _resolveComponent("ProfileControlButtons")!
  const _component_ProfileInputFields = _resolveComponent("ProfileInputFields")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_ProfileHeaderBar)
    ]),
    "content-top": _withCtx(() => [
      _createVNode(_component_ProfileControlButtons, {
        onSaveFunction: _ctx.onSave,
        onCancelFunction: _ctx.onCancel,
        "disable-save": !_ctx.profileHasChanges || !_ctx.allFieldsValid(),
        class: "section-header"
      }, null, 8, ["onSaveFunction", "onCancelFunction", "disable-save"]),
      _createVNode(_component_ProfileInputFields, {
        controller: _ctx.controller,
        includeSectionHeaders: "",
        includeFieldLabels: "",
        onProfileChange: _ctx.onProfileChange
      }, null, 8, ["controller", "onProfileChange"])
    ]),
    "content-bottom": _withCtx(() => [
      _createVNode(_component_BaseButton, {
        pattern: _ctx.ButtonColorPattern.FILL,
        color: _ctx.ButtonColor.PRIMARY,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSave())),
        disabled: !_ctx.profileHasChanges || !_ctx.allFieldsValid()
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("AccountHome.SaveButtonText")), 1)
        ]),
        _: 1
      }, 8, ["pattern", "color", "disabled"]),
      _createVNode(_component_BaseButton, {
        pattern: _ctx.ButtonColorPattern.TRANSPARENT,
        color: _ctx.ButtonColor.PRIMARY,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCancel()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("AccountHome.CancelButtonText")), 1)
        ]),
        _: 1
      }, 8, ["pattern", "color"])
    ]),
    _: 1
  }))
}