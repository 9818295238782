import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a3e810"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "scroll-to-page-top" }
const _hoisted_2 = { class: "see-doctor-button-wrapper d-flex flex-row justify-content-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { ref: "scroll-to-page-content" }
const _hoisted_5 = { class: "care-cards-wrapper" }
const _hoisted_6 = { class: "how-can-we-help-wrapper" }
const _hoisted_7 = { class: "middle-image-wrapper" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "reviews-wrapper" }
const _hoisted_10 = { class: "app-download-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrgImage = _resolveComponent("OrgImage")!
  const _component_HomePageHeaderBar = _resolveComponent("HomePageHeaderBar")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_WhitelabelMore = _resolveComponent("WhitelabelMore")!
  const _component_WhitelabelContentLayout = _resolveComponent("WhitelabelContentLayout")!
  const _component_WhitelabelCareCards = _resolveComponent("WhitelabelCareCards")!
  const _component_HowCanWeHelpCard = _resolveComponent("HowCanWeHelpCard")!
  const _component_BackgroundImage = _resolveComponent("BackgroundImage")!
  const _component_ReviewCards = _resolveComponent("ReviewCards")!
  const _component_VirtualCareBanner = _resolveComponent("VirtualCareBanner")!
  const _component_AppDownload = _resolveComponent("AppDownload")!
  const _component_CorporateFooter = _resolveComponent("CorporateFooter")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, {
    contentWidthFull: "",
    contentBodyPaddingDisabled: "",
    contentBodyBottomMarginDisabled: "",
    backgroundImage: "",
    height: "100vh"
  }, {
    background: _withCtx(() => [
      _createVNode(_component_OrgImage, {
        "asset-type": _ctx.ORG_ASSET_TYPE.ODB_HERO_IMAGE,
        "alt-image": _ctx.backgroundImage,
        "atl-text": "Hero Image"
      }, null, 8, ["asset-type", "alt-image"])
    ]),
    "content-header": _withCtx(() => [
      (_ctx.AuthStore.isLoggedIn())
        ? (_openBlock(), _createBlock(_component_HomePageHeaderBar, {
            key: 0,
            onLogout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$logout(false))),
            "show-dependents": false
          }))
        : _createCommentVNode("", true)
    ]),
    "content-top": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, null, 512),
      _createVNode(_component_WhitelabelContentLayout, {
        title: _ctx.welcomeTitle,
        text: _ctx.welcomeText,
        "logo-light": "",
        "center-content": !_ctx.showCloudMdBranding
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_RouteButton, {
              route: _ctx.loginRoute,
              color: _ctx.ButtonColor.PRIMARY,
              pattern: _ctx.ButtonColorPattern.FILL,
              size: _ctx.ButtonSize.DEFAULT
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$mhat("WhitelabelHome.SeeADoctorButtonText")), 1)
              ]),
              _: 1
            }, 8, ["route", "color", "pattern", "size"]),
            _createVNode(_component_BaseButton, {
              class: "m-t-4",
              color: _ctx.ButtonColor.PRIMARY,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showHoursModal())),
              pattern: _ctx.ButtonColorPattern.DEFAULT
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$mhat("WhitelabelHome.OperationHoursButtonText")), 1)
              ]),
              _: 1
            }, 8, ["color", "pattern"])
          ]),
          (_ctx.showCloudMdBranding)
            ? (_openBlock(), _createBlock(_component_WhitelabelMore, {
                key: 0,
                class: "more-spacing",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scrollTo('scroll-to-page-content')))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "text", "center-content"]),
      (_ctx.showCloudMdBranding)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, null, 512),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_WhitelabelCareCards, null, {
                default: _withCtx(() => [
                  _createVNode(_component_RouteButton, {
                    route: _ctx.loginRoute,
                    color: _ctx.ButtonColor.PRIMARY,
                    pattern: _ctx.ButtonColorPattern.FILL,
                    size: _ctx.ButtonSize.DEFAULT
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("WhitelabelHome.SeeADoctorButtonText")), 1)
                    ]),
                    _: 1
                  }, 8, ["route", "color", "pattern", "size"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_HowCanWeHelpCard)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_BackgroundImage, { height: "600px" }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: _ctx.middleBackground,
                    alt: "middle image"
                  }, null, 8, _hoisted_8)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_ReviewCards)
            ]),
            _createVNode(_component_VirtualCareBanner, null, {
              default: _withCtx(() => [
                _createVNode(_component_RouteButton, {
                  route: _ctx.loginRoute,
                  color: _ctx.ButtonColor.PRIMARY,
                  pattern: _ctx.ButtonColorPattern.FILL,
                  size: _ctx.ButtonSize.DEFAULT
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(this.$mhat("WhitelabelHome.SeeADoctorButtonText")), 1)
                  ]),
                  _: 1
                }, 8, ["route", "color", "pattern", "size"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_AppDownload, {
                dark: "",
                version: _ctx.AppDownloadGraphicType.ORG
              }, null, 8, ["version"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    "content-bottom": _withCtx(() => [
      _createVNode(_component_CorporateFooter, {
        branded: "",
        "brand-white-bg": "",
        "show-support-link": false
      })
    ]),
    _: 1
  }))
}