

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {AppointmentType, ClinicAppointmentType, ErrorCode} from "@/open_api/generated";
	import BookingController, {setBookingParams} from "@/views/patient_user/booking/BookingController";
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import ContactClinicRouteButton from "./components/ContactClinicRouteButton.vue";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import {isBlank} from "@/lib/utils/prototype/String";
	import {ICON_CHAT, ICON_VIDEO} from "@/assets/AppIcons";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";
	import Services from "@/lib/services/Services";
	import ClinicService from "@/lib/clinics/Clinic.service";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import ConfigStore from "@/lib/vuex/modules/Config.store";
	import FBService from "@/lib/FB/FBService";
	import {
		bookingForward,
		dynamicBookingRoute,
		getBookingClinicService,
	} from "@/views/patient_user/booking/BookingRoutingHelper";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {dependentBookingRouteCollection} from "@/router/patient_user/DependentBookingRouteCollection";
	import {bookingRouteCollection} from "@/router/patient_user/BookingRouteCollection";
	import BookingServiceFactory from "@/lib/booking/factory/BookingServiceFactory";

	@Options({components: {BookingHeaderBar, DependentSubheader, ContactClinicRouteButton}})
	export default class BookingAppointmentType extends Vue
	{
		@Prop() public readonly clinicId;
		@Prop() public readonly providerOrGroupId;
		@Prop() public readonly dependentId;
		@Prop({type: Boolean, default: true, required: false }) hasClose: boolean;
		@Prop() public readonly rescheduleRemoteAppointmentId;

		public isLoading = true;
		public skipBookingNotes = true;
		public appointmentTypes: AppointmentType[] = [];
		public selectedType: AppointmentType = null;
		public clinicService: ClinicService = null;

		ICON_VIDEO = ICON_VIDEO;
		ICON_CHAT = ICON_CHAT;
		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;
		ClinicAppointmentType = ClinicAppointmentType;

		// ==========================================================================
		// Vue lifecycle
		// ==========================================================================

		async beforeRouteEnter(to, from, next)
		{
			const dependentId = to.params.dependentId;
			const clinicId = to.params.clinicId;
			const providerOrGroupId = to.params.providerOrGroupId;
			const clinicService = getBookingClinicService(dependentId);
			const clinicProfile = await getBookingClinicService().getClinic(clinicId);

			const bookingService = await new BookingServiceFactory().getBookingService(clinicId, providerOrGroupId, clinicService);
			const appointmentTypes = await bookingService.getAppointmentTypes(clinicId, providerOrGroupId);

			if (clinicProfile && clinicProfile.allowSkippingAppointmentTypeSelection && appointmentTypes && appointmentTypes.length === 1)
			{
				return bookingForward(dynamicBookingRoute(dependentId).NextAvailability,
					{
						dependentId: dependentId,
						clinicId: clinicId,
						providerOrGroupId: providerOrGroupId,
						appointmentTypeId: appointmentTypes[0].id,
						organizationId: OrganizationStore.organizationProfile?.url_friendly_name,
					},
					next,
					to.query);
			}

			next();
		}

		public created(): void
		{
			if (this.isDependentMode)
			{
				this.clinicService = Services.DependentClinics(this.dependentId);
			}
			else
			{
				this.clinicService = Services.PatientClinics;
			}
			setBookingParams.call(this);
			this.controller.clearAppointmentState();
			this.checkBookingNotes();
			this.fetchAppointmentTypes();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async fetchAppointmentTypes(): Promise<void>
		{
			const {clinicId, providerOrGroupId} = this;

			const bookingService = await new BookingServiceFactory().getBookingService(this.controller.clinicId, this.controller.providerOrGroupId, this.clinicService);

			bookingService.getAppointmentTypes(clinicId, providerOrGroupId).then(
				(response) =>
				{
					this.appointmentTypes = response;
				}).catch((error: ErrorResponse) =>
			{
				if (!error.isAuthError && !error.is(ErrorCode.IncompleteProfile))
				{
					WebNotification.$emit({
						event: NotifyEvent.Generic,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Critical,
						title: this.$mhat("BookingAppointmentType.GenericError"),
						message: this.$mhat("BookingAppointmentType.GenericErrorMessage"),
					});
				}
			}).finally(() =>
			{
				this.isLoading = false;
			});
		}

		public setSelected(selected: AppointmentType): void
		{
			this.controller.appointmentType = selected;

			const appointmentTypeId = selected.id;
			const params = { ...this.params, appointmentTypeId };
			const query = {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId};

			if (this.isDependentMode)
			{
				const name = this.skipBookingNotes ? Route.Booking.Dependent.NextAvailability : Route.Booking.Dependent.Notes;
				this.$mhaRouterPush({ name, params, query });
			}
			else
			{
				const name = this.skipBookingNotes ? Route.Booking.NextAvailability : Route.Booking.Notes;
				this.$mhaRouterPush({ name, params, query });
			}
		}

		public onBookTherapist(): void
		{
			// record route to SnapClarity in FB analytics.
			FBService.logRouteToSnapClarity();
			window.open(ConfigStore.configConstants.snap_clarity_url, "_blank");
		}

		public async checkBookingNotes(): Promise<void>
		{
			const controller: BookingController = this.controller;
			this.skipBookingNotes = await controller.clinicBookingNotes() === null;
		}

		public iconForAppointmentType(appointmentType: AppointmentType): string
		{
			if (appointmentType.appointment_type === ClinicAppointmentType.Chat)
			{
				return "icon-chat";
			}
			return "icon-video-2";
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get failureMessage()
		{
			if (this.controller.clinic)
			{
				const {name} = this.controller.clinic;

				return this.$mhat("BookingAppointmentType.NoAppointmentTypesFailureMessage", {clinicName: name});
			}
		}

		get showSpinner()
		{
			return this.isLoading || this.noAppointmentTypes;
		}

		get controller(): BookingController
		{
			return BookingControllerStore.bookingController;
		}

		get noAppointmentTypes()
		{
			return !this.isLoading && this.appointmentTypes.length === 0;
		}

		get params()
		{
			const {clinicId, providerOrGroupId, dependentId} = this;
			return {clinicId, providerOrGroupId, dependentId};
		}

		get isDependentMode()
		{
			return !isBlank(this.dependentId);
		}

		get showTherapistLink()
		{
			return this.$isCloudMd && ConfigStore.configConstants.snap_clarity_enabled;
		}
	}

