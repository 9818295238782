
	import {Options, Vue} from "vue-property-decorator";
	import OrganizationService from "@/lib/organization/service/OrganizationService";
	import {Route} from "@/router/router";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import EventStateSpinner from "@/components/EventState/EventStateSpinner.vue";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";

	@Options({components: {BaseButton, EventStateSpinner}})
	export default class OrgAqsRedirect extends Vue
	{
		public loading = false;
		public failed = false;
		public failureMessage = "";

		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		public async created(): Promise<void>
		{
			this.loading = true;
			this.failureMessage = this.$mhat("Common.Error.Generic");
			try
			{
				// ensure the patient is connected to primary clinic
				await (new OrganizationService()).connectToCurrentOrganizationPrimaryClinic();

				this.$mhaRouterReplace(
					{
						name: Route.AqsBooking.AppointmentType,
						params: {
							organizationId: OrganizationStore.organizationProfile.url_friendly_name,
							clinicId: OrganizationStore.organizationProfile.primary_clinic_id,
						},
						query: {
							hideHeader: "true",
						},
					});
			}
			catch (err)
			{
				this.failed = true;
				if (err instanceof ErrorResponse)
				{
					if (err.is(ErrorCode.InvalidHealthNumber))
					{
						this.failureMessage = this.$mhat("OrgAqsRedirect.InvalidHealthNumber");
					}
					// Swallow Incomplete Profile Errors
					else if (!err.is(ErrorCode.IncompleteProfile))
					{
						throw err;
					}
				}
				else
				{
					throw err;
				}
			}
			finally
			{
				this.loading = false;
			}
		}

		/**
		 * start the booking workflow over again.
		 */
		public onStartOver(): void
		{
			const crossFrameService = new CrossFrameCommunicationService();
			if (crossFrameService.hasParentFrame)
			{
				// let any parent frames know we want to go back to the beginning of booking.
				crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateBack});
			}
		}
	}
