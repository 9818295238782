import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97d3504a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-label-div" }
const _hoisted_2 = ["for", "data-required-text"]
const _hoisted_3 = {
  key: 1,
  class: "validationMessage"
}
const _hoisted_4 = { class: "input-container d-flex align-items-center" }
const _hoisted_5 = { class: "input-label-div" }
const _hoisted_6 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-layout", _ctx.labelClasses]),
    style: _normalizeStyle(_ctx.labelStyle)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.hideLabel && _ctx.labelTop)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.forInputId,
            "data-required-text": this.$mhat('InputLayout.RequiredText'),
            style: _normalizeStyle({color: _ctx.labelColor}),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onLabelClicked()))
          }, _toDisplayString(_ctx.label), 13, _hoisted_2))
        : _createCommentVNode("", true),
      (!_ctx.hideValidationMessage && !_ctx.isValid && !_ctx.pristine)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.validationMessage), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.hideLabel && _ctx.labelRight)
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              for: _ctx.forInputId,
              style: _normalizeStyle({color: _ctx.labelColor}),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onLabelClicked()))
            }, _toDisplayString(_ctx.label), 13, _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ])
  ], 6))
}