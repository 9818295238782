import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a86a51a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "clinic-provider-info"
}
const _hoisted_2 = { class: "info-header" }
const _hoisted_3 = { class: "info-body provider-swipe-container" }
const _hoisted_4 = { class: "provider-list-wrapper" }
const _hoisted_5 = { class: "provider-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.clinic)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h6", _hoisted_2, _toDisplayString(this.$mhat("ClinicProviderInfo.ProvidersHeader")), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageCount, (pageNo) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.providerList(pageNo-1), (providerName) => {
                  return (_openBlock(), _createElementBlock("li", { key: providerName }, [
                    _createElementVNode("span", null, _toDisplayString(providerName), 1)
                  ]))
                }), 128))
              ])
            ]))
          }), 256))
        ])
      ]))
    : _createCommentVNode("", true)
}