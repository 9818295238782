import MHABackendLogger from "@/lib/utils/MHABackendLogger";
import {app} from "@/main";
import {TELEHEALTH_MODE} from "@/components/telehealth/TelehealthMode";
import {RouteRecordRaw} from "vue-router";

const TermsAndConditions = () => import(/* webpackChunkName: "Public" */ "@/views/public/TermsAndConditionsPage.vue");
const PrivacyPolicy = () => import(/* webpackChunkName: "Public" */ "@/views/public/PrivacyPolicyPage.vue");
const CookiesNotEnabled = () => import(/* webpackChunkName: "Public" */ "@/views/public/CookiesNotEnabled.vue");
const ErrorPage = () => import(/* webpackChunkName: "Public" */ "@/views/public/ErrorPage.vue");

export enum PublicRoute
{
	TermsConditions = "TermsAndConditions",
	PrivacyPolicy = "PrivacyPolicy",
	CookiesNotEnabled = "CookiesNotEnabled",
	ErrorPage = "ErrorPage",
}

export const publicRoutes: RouteRecordRaw[] = [
	{
		path: "/terms",
		name: PublicRoute.TermsConditions,
		component: TermsAndConditions,
		props: true,
		meta: {
			page: 0,
		},
	},
	{
		path: "/privacy",
		name: PublicRoute.PrivacyPolicy,
		component: PrivacyPolicy,
		props: true,
		meta: {
			page: 0,
		},
	},
	{
		path: "/cookies-not-enabled",
		name: PublicRoute.CookiesNotEnabled,
		component: CookiesNotEnabled,
	},
	{
		path: "/:catchAll(.*)?",
		alias: "/error_page/:catchAll(.*)?",
		name: PublicRoute.ErrorPage,
		component: ErrorPage,
		props: (route) =>
		{
			return Object.assign({
				errorTitle: route.query.errorTitle,
				errorMessage: route.query.errorMessage,
			}, route.params);
		},

		async beforeEnter(to, from, next)
		{
			MHABackendLogger.warn("Routing to error page.\n" +
				`errorCode: ${to.params.errorCode} \n` +
				`errorTitle: ${to.query.errorTitle} \n` +
				`errorMessage: ${to.query.errorMessage}`);
			next();
		},
	},
];
