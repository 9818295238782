

	import difference from "lodash.difference";
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {AppointmentCardInfo} from "@/lib/types/Appointment";
	import {Appointment} from "@/lib/models/Appointment";
	import {SelectionCardColorPattern} from "@/components/Buttons/types";
	import {Patient} from "@/lib/patient/Patient";
	import AppointmentsHeader from "@/views/patient_user/appointment/components/AppointmentsHeader.vue";
	import AuthStore from "@/lib/vuex/auth.store";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import PatientAppointmentService from "@/lib/appointment/service/PatientAppointmentService";
	import moment from "moment";
	import DependentStore from "@/lib/vuex/modules/Dependent.store";
	import PatientProfileService from "@/lib/patient/PatientProfileService";
	import DeviceInfo from "@/lib/DeviceInfo";

	@Options({
		emits: ["select", "userChange", "loadingComplete", "update:isLoading", "update:hasAppointments"],
		components: {AppointmentsHeader},
	})
	export default class AppointmentsFeed extends Vue
	{
		@Prop({type: String, default: null}) selectedAppointmentClinicId: string;
		@Prop({type: String, default: null}) selectedAppointmentRemoteId: string;
		@Prop({type: String, default: null}) selectedPatientId: string;
		@Prop({type: Boolean}) isMobile: boolean;
		// outbound properties
		@Prop({type: Boolean, default: false, required: false}) isLoading: boolean;
		@Prop({type: Boolean, default: false, required: false}) hasAppointments: boolean;

		public appointments: Appointment[] = [];
		public selectedUser: {name: string, value: string} = null;
		SelectionCardColorPattern = SelectionCardColorPattern;

		private patientAppointmentService = new PatientAppointmentService();
		private patientProfileService = new PatientProfileService();

		// ==========================================================================
		// Vue lifecycle hooks
		// ==========================================================================

		public async mounted(): Promise<void>
		{
			const profile: Patient = this.patientProfileService.getPatientProfile(this.selectedPatientId);

			this.selectedUser = {
				name: profile.firstName + " " + profile.lastName,
				value: this.selectedPatientId,
			};

			await this.loadAppointments();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async loadAppointments(): Promise<void>
		{
			try
			{
				this.setIsLoading(true);
				const startOfYear = moment().subtract(1, "years");
				const endOfYear = moment().add(1, "years");

				this.appointments = await this.patientAppointmentService.getAllRemoteAppointmentsInRange(this.selectedUser.value, startOfYear, endOfYear, true);

				this.selectDefaultAppointment();
				this.$emit("update:hasAppointments", this.hasAppointmentsInList);
			}
			catch (error)
			{
				console.error(error);
				WebNotification.$emit({
					event: NotifyEvent.Generic,
					type: NotificationType.Dismiss,
					severity: NotificationSeverity.Critical,
					title: this.$mhat("AppointmentsFeed.GenericErrorTitle"),
					message: this.$mhat("AppointmentsFeed.GenericErrorMessage"),
				});
				throw error;
			}
			finally
			{
				this.setIsLoading(false);
			}
		}

		/**
		 * select the default appointment.
		 */
		public selectDefaultAppointment(): void
		{
			if (this.hasAppointmentsInList && !this.selectedAppointmentRemoteId && !DeviceInfo.isMobile())
			{
				this.selected(this.appointments[0]);
			}
		}

		public selected(selected: AppointmentCardInfo)
		{
			this.$emit("select", selected);
		}

		public isActive(appointment: AppointmentCardInfo)
		{
			return this.selectedAppointment?.compositeId === appointment.compositeId;
		}

		public dependentSelected(user)
		{
			this.selectedUser = user;
			this.$emit("userChange", user.value);
			this.$mhaRouterReplace(this.Route.Appointments.View, {params: {clinicId: null, remoteAppointmentId: null, selectedPatientId: user.value}});
			this.loadAppointments();
		}

		public setIsLoading(isLoading: boolean): void
		{
			this.$emit("update:isLoading", isLoading);
		}

		/**
		 * remove an appointment from the appointment list.
		 * @param remoteAppointmentId - the remote id of the appointment to remove.
		 * @param clinicId - the clinic id of the appointment to remove.
		 */
		public removeAppointmentByRemoteIdClinicId(remoteAppointmentId: string, clinicId: string): void
		{
			this.appointments = this.appointments.filter(
				(appt: Appointment) => appt.remoteId !== remoteAppointmentId || appt.clinicId !== clinicId);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get dependents(): Patient[]
		{
			return DependentStore.dependents;
		}

		get selectUserOptions()
		{
			const selfAsList = [
				{
					name: AuthStore.loggedInUser.firstName + " " + AuthStore.loggedInUser.lastName,
					value: AuthStore.loggedInUser.id,
				},
			];
			const dependentList = this.dependents.map(({firstName, lastName, id}) =>
			{
				return {
					name: `${firstName} ${lastName}`,
					value: id,
				};
			});
			return selfAsList.concat(dependentList);
		}

		get selectedAppointment(): Appointment
		{
			return this.appointments?.find((appt) => appt.remoteId === this.selectedAppointmentRemoteId && appt.clinicId === this.selectedAppointmentClinicId);
		}

		get upcomingAppointments(): Appointment[]
		{
			return this.appointments.filter((appt) =>
			{
				return appt.endDateTime.isAfter(moment());
			});
		}

		get previousAppointments(): Appointment[]
		{
			return difference(this.appointments, this.upcomingAppointments);
		}

		get hasPreviousAppointments()
		{
			return this.previousAppointments.length > 0;
		}

		get previousVisible()
		{
			return {
				visibility: this.hasPreviousAppointments ? "visible" : "hidden",
			};
		}

		/**
		 * check if the appointment list is empty or not.
		 */
		get hasAppointmentsInList(): boolean
		{
			return this.appointments && this.appointments.length > 0;
		}
	}
