
	import {Options, Vue, Prop} from "vue-property-decorator";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import PatientTelehealthHeader from "@/views/patient_user/appointment/components/PatientTelehealthHeader.vue";
	import TelehealthWrapper from "@/components/telehealth/TelehealthWrapper.vue";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import OnDemandBookingTelehealthWaitingRoomOverlay from "@/views/patient_user/odb/telehealth/OnDemandBookingTelehealthWaitingRoomOverlay.vue";
	import {AqsPatientAPI} from "@/lib/services/Api";
	import {SessionData} from "@/lib/telehealth/models/sessionData.model";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {Route} from "@/router/router";
	import TelehealthBase from "@/components/telehealth/TelehealthBase.vue";
	import OpenTokTelehealth from "@/lib/telehealth/opentok/OpenTokTelehealth";
	import LogEvent from "@/lib/telehealth/models/LogEvent";
	import {VideoDisplayMode} from "@/lib/telehealth/models/VideoDisplayMode";
	import {IonPage} from "@ionic/vue";
	import VideoViewForIonSlides from "@/components/telehealth/VideoViewForIonSlides.vue";

	@Options({
		components: {
			AppChip,
			IonPage,
			TelehealthWrapper,
			PatientTelehealthHeader,
			VideoViewForIonSlides,
			ChatView,
			OnDemandBookingTelehealthWaitingRoomOverlay,
		},
	})
	export default class OnDemandBookingTelehealth extends TelehealthBase
	{
		@Prop({type: String, required: true}) organizationId: string;

		// used to mask flicker when leaving page.
		public leavingPage = false;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async onExit(): Promise<void>
		{
			await this.endCall();
			this.leavingPage = true;
			this.$mhaRouterPush(Route.Public.Organization.Home);
		}
	}
