import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15f1d814"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "booking-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrgImage = _resolveComponent("OrgImage")!
  const _component_HomePageHeaderBar = _resolveComponent("HomePageHeaderBar")!
  const _component_SelectMenu = _resolveComponent("SelectMenu")!
  const _component_MessageInput = _resolveComponent("MessageInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_OnDemandBookingContentLayout = _resolveComponent("OnDemandBookingContentLayout")!
  const _component_CorporateFooter = _resolveComponent("CorporateFooter")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, {
    contentWidthFull: "",
    backgroundImage: "",
    contentBodyPaddingDisabled: "",
    contentBodyBottomMarginDisabled: "",
    height: _ctx.heroImageHeight
  }, {
    background: _withCtx(() => [
      _createVNode(_component_OrgImage, {
        "asset-type": _ctx.ORG_ASSET_TYPE.ODB_HERO_IMAGE,
        "alt-image": _ctx.backgroundImage,
        "atl-text": "Hero Image"
      }, null, 8, ["asset-type", "alt-image"])
    ]),
    "content-header": _withCtx(() => [
      _createVNode(_component_HomePageHeaderBar, {
        onLogout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout())),
        "show-dependents": false
      })
    ]),
    "content-top": _withCtx(() => [
      _createVNode(_component_OnDemandBookingContentLayout, {
        title: this.$mhat('OnDemandBookingReason.TitleMessage'),
        text: _ctx.messageText,
        "logo-light": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.hasMultipleCities)
              ? (_openBlock(), _createBlock(_component_SelectMenu, {
                  key: 0,
                  class: "select",
                  "hide-label": "",
                  id: "city",
                  placeholder: "Select City",
                  selected: _ctx.selectedCityAsOption,
                  options: _ctx.cityOptions,
                  onSelection: _ctx.citySelected
                }, null, 8, ["selected", "options", "onSelection"]))
              : _createCommentVNode("", true),
            (!_ctx.singlePharmacyMode || _ctx.hasMultipleCities)
              ? (_openBlock(), _createBlock(_component_SelectMenu, {
                  key: 1,
                  class: "select",
                  "hide-label": "",
                  id: "pharmacy",
                  placeholder: _ctx.pharmacySelectPlaceholderText,
                  selected: _ctx.selectedPharmacy,
                  options: _ctx.pharmacies,
                  disabled: !_ctx.selectedCity && _ctx.hasMultipleCities,
                  onSelection: _ctx.pharmacySelected
                }, null, 8, ["placeholder", "selected", "options", "disabled", "onSelection"]))
              : _createCommentVNode("", true),
            _createVNode(_component_MessageInput, {
              value: _ctx.appointmentReason,
              placeholder: this.$mhat('OnDemandBookingReason.AppointmentReasonPlaceholder'),
              onInput: _ctx.onReasonChange
            }, null, 8, ["value", "placeholder", "onInput"]),
            _createVNode(_component_BaseButton, {
              class: "book-btn",
              color: _ctx.ButtonColor.PRIMARY,
              pattern: _ctx.ButtonColorPattern.FILL,
              disabled: !_ctx.canBook,
              onClick: _ctx.bookAppointment
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.$mhat("OnDemandBookingReason.BookAppointmentButtonText")), 1)
              ]),
              _: 1
            }, 8, ["color", "pattern", "disabled", "onClick"])
          ])
        ]),
        _: 1
      }, 8, ["title", "text"])
    ]),
    "content-footer": _withCtx(() => [
      _createVNode(_component_CorporateFooter, {
        branded: "",
        "brand-white-bg": "",
        "show-support-link": false
      })
    ]),
    _: 1
  }, 8, ["height"]))
}