
import {Options, Vue} from "vue-property-decorator";
import DependentService from "@/lib/dependents/Dependent.service";
import {ProfileController} from "@/views/patient_user/profile/controllers/ProfileController";
import {DependentProfileController} from "@/views/patient_user/profile/controllers/DependentProfileController";
import {UserProfileController} from "@/views/patient_user/profile/controllers/UserProfileController";
import {Patient} from "@/lib/patient/Patient";
import AuthStore from "@/lib/vuex/auth.store";
import DeviceInfo from "@/lib/DeviceInfo";
import {Route} from "@/router/router";
import {loadingController} from "@ionic/vue";

interface CloseButtonOpts
{
	text: string;
	handler: () => void;
}

@Options({})
export default class ProfileModalController extends Vue
{
	/* Component Props */

	/* Data Properties */
	controller: ProfileController = null;
	loader: HTMLIonLoadingElement = null;
	message = "";
	profileFields: string[] = [];

	/* Lifecycle Methods */
	public async created()
	{
		await this.presentLoader();
		await this.createProfileController();
		this.onCreated();
	}

	public async beforeUnmount()
	{
		await this.dismissLoader();
	}

	// @ts-ignore
	public async onCreated()
	{
	}

	/* Component Methods */
	public async submitProfile(): Promise<void>
	{
		await this.controller.onSave();
	}

	public async presentLoader()
	{
		await this.dismissLoader();
		this.loader = await loadingController.create({});
		await this.loader.present();
	}

	public async dismissLoader()
	{
		if (this.loader)
		{
			await this.loader.dismiss();
		}
	}

	private async createProfileController()
	{
		const loggedInUser = AuthStore.loggedInUser;
		const userId = this.$route.query.userId as string;

		if (userId && userId !== loggedInUser.id)
		{
			const dependent = await DependentService.getDependent(userId);

			if (dependent)
			{
				this.controller = new DependentProfileController(dependent);
			}
		}
		else
		{
			this.controller = new UserProfileController();
		}
	}

	/* Computed Properties */
	get allFieldsValid(): boolean
	{
		return this.controller.isRequiredFieldsValid(this.profileFields);
	}

	get isDependent(): boolean
	{
		const loggedInUser = AuthStore.loggedInUser;
		const {userProfile} = this;

		return (loggedInUser && userProfile && userProfile.id !== loggedInUser.id);
	}

	get userProfile(): Patient
	{
		if (this.controller && this.controller.userProfile)
		{
			return this.controller.userProfile;
		}
	}

	get closeButtonOpts(): CloseButtonOpts
	{
		return {
			text: "Close",
			handler: () => this.$mhaRouterPush(Route.Home),
		};
	}
}
