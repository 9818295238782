

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ButtonColor, ButtonColorPattern, ButtonSize} from "@/components/Buttons/types";

	@Options({
		emits: ["click"],
	})
	export default class CloseButton extends Vue
	{
		@Prop({type: String}) text: string;

		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;
		ButtonSize = ButtonSize;

		get getText()
		{
			return this.text ? this.text : this.$mhat("CloseButton.ButtonText");
		}
	}
