
	export default {
		name: "SmallButton",
		inheritAttrs: false,
		emits: ["click"],
		props: {
			onHover: String,
		},

		data()
		{
			return {
				hoverText: undefined,
			};
		},

		methods: {
			mouseOver()
			{
				if (this.onHover)
				{
					this.hoverText = this.onHover;
				}
			},

			mouseOut()
			{
				this.hoverText = undefined;
			},
		},
	};
