import TelehealthInterfaceBase from "@/lib/telehealth/TelehealthInterfaceBase";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
import TelehealthError, {TELEHEALTH_ERROR_TYPE} from "@/lib/telehealth/error/TelehealthError";
import {IOSCallData, NativeBridge} from "@/lib/NativeBridge";
import NativeEventListener from "@/lib/telehealth/ios/NativeEventListener";
import StateTracker from "@/lib/telehealth/ios/StateTracker";
import ChatManager from "@/lib/telehealth/ios/ChatManager";
import EventLogger from "@/lib/telehealth/ios/EventLogger";
import {VideoDisplayMode} from "@/lib/telehealth/models/VideoDisplayMode";
import {reactive} from "vue";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";

/**
 * A passive telehealth engine that relays information form the native iOS telehealth session.
 * This engine does NOT directly connect to the session.
 */
export default class IOSTelehealth extends TelehealthInterfaceBase
{
	protected destroyed = false;
	protected nativeEventListener: NativeEventListener;
	protected stateTracker: StateTracker;
	protected chatManager: ChatManager;
	protected eventLogger: EventLogger;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor()
	{
		super();

		this.nativeEventListener = reactive(new NativeEventListener()) as NativeEventListener;
		this.stateTracker = reactive(new StateTracker(this.nativeEventListener, this.onCallEndCallbacks, this.onCallStateChangeCallbacks)) as StateTracker;
		this.chatManager = reactive(
			new ChatManager(this.nativeEventListener, this.onChatMessageCallbacks, this.onChatTypingCallbacks)) as ChatManager;
		this.eventLogger = reactive(new EventLogger(this.nativeEventListener, this.onLogEventCallbacks)) as EventLogger;
	}

	/**
	 * call to initialize the telehealth engine
	 * @param callData - IOS call data
	 * @param videoSelector - not used
	 * @param videoPreviewSelector - not used
	 */
	public async initialize(callData: IOSCallData, videoSelector: string, videoPreviewSelector: string): Promise<void>
	{
		if (this.destroyed)
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.LOGIC_ERROR, "This Telehealth Engine is destroyed!");
		}

		this.nativeEventListener.setupNativeEventListeners();

		// create the native video element if not VOIP call (voip call will already have one created).
		if (!callData.voip)
		{
			NativeBridge.createVideoView(callData);
		}
	}

	/**
	 * destroy the telehealth engine. YOU MUST CALL THIS or the telehealth session will
	 * continue to run in the background.
	 */
	public destroy()
	{
		this.destroyed = true;
		NativeBridge.destroyVideoView();
	}

	/**
	 * enter the video room
	 */
	public async enterRoom(): Promise<void>
	{
		this.stateTracker.setCallState(TELEHEALTH_STATE.IN_ROOM);
		NativeBridge.openVideoView();
	}

	/**
	 * leave the video room
	 */
	public async leaveRoom(): Promise<void>
	{
		this.stateTracker.setCallState(TELEHEALTH_STATE.OUT_OF_ROOM);
		NativeBridge.closeVideoView();
	}

	/**
	 * Call remote clients (Invite any clients in state, "OUT_OF_ROOM", in to the state "IN_ROOM").
	 * Returning true / false to indicate if the remote has accepted the call or not. Note with multiple remotes
	 * if one accepts this method will return true
	 * @param callDisplay - a string to be presented to the remote you are calling. Think a phone's call display.
	 */
	public async callRemote(callDisplay: string): Promise<boolean>
	{
		throw new TelehealthError(
			TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED,
			"callRemote is not implemented in IOSTelehealth");
	}

	/**
	 * just like callRemote but only calls a specific remote as indicated by remoteId
	 * @param remoteId - the remote client to call
	 */
	public async callSpecificRemote(remoteId: string): Promise<boolean>
	{
		throw new TelehealthError(
			TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED,
			"callSpecificRemote is not implemented in IOSTelehealth");
	}

	/**
	 * Cancel an outgoing call. Basically this just notifies the remote clients that the call should be canceled.
	 * Currently what this would mean is that the incoming call alert will dismiss itself.
	 */
	public async cancelCallRemote(): Promise<void>
	{
		throw new TelehealthError(
			TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED,
			"cancelCallRemote is not implemented in IOSTelehealth");
	}

	/**
	 * notify remote clients that the call has "ended". This is just an indication that the call has ended
	 * it does not effect the state of the call in any way. Different clients will act differently to this message.
	 * In the case of a patient they will transition to the call end screen and leave the Room.
	 */
	public async notifyRemotesOfCallEnd(): Promise<void>
	{
		throw new TelehealthError(
			TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED,
			"notifyRemotesOfCallEnd is not implemented in IOSTelehealth");
	}

	public changeVideoDisplayMode(mode: VideoDisplayMode): void
	{
		throw new TelehealthError(
			TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED,
			"changeVideoDisplayMode is not implemented in IOSTelehealth");
	}

	/**
	 * enable video feed.
	 */
	public enableVideo(): void
	{
		NativeBridge.publishVideo(true);
	}

	/**
	 * disable video feed.
	 */
	public disableVideo(): void
	{
		NativeBridge.publishVideo(false);
	}

	/**
	 * cycle to the next available camera on the device.
	 */
	public cycleCamera(): void
	{
		throw new TelehealthError(
			TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED,
			"cycleCamera is not implemented in IOSTelehealth");
	}

	/**
	 * mute or unmute the mic
	 * @param mute
	 */
	public muteMicrophone(mute: boolean): void
	{
		throw new TelehealthError(
			TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED,
			"muteMicrophone is not implemented in IOSTelehealth");
	}

	/**
	 * send a chat message to every one in the call.
	 * @param msg - the message to send
	 */
	public async sendChatMessage(msg: ChatMessage): Promise<void>
	{
		await this.chatManager.sendChatMessage(msg);
	}

	/**
	 * sends a typing signal to remote clients. Use this to indicate that this client is typing
	 */
	public async sendTypingSignal(): Promise<void>
	{
		await this.chatManager.sendTypingSignal();
	}

	/**
	 * kick a remote client. Not allowed for IOS Client
	 * @param remoteId - the remoteId identifying the client.
	 * @return true / false indicating success of the kick.
	 */
	public async kickRemote(remoteId: string): Promise<boolean>
	{
		throw new TelehealthError(TELEHEALTH_ERROR_TYPE.PERMISSION_ERROR, "IOS Client cannot kick");
	}

	/**
	 * set this clients telehealth info.
	 * this information is NOT currently relayed to the native code.
	 * @param clientInfo - the client info.
	 */
	public setClientInfo(clientInfo: TelehealthClient): void
	{
		this.localClientInfo = clientInfo;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get callState(): TELEHEALTH_STATE
	{
		return this.stateTracker.callState;
	}

	/**
	 * this information is not currently tracked
	 */
	get remoteClients(): TelehealthClient[]
	{
		throw new TelehealthError(TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED, "remoteClients is not implemented in IOSTelehealth");
	}

	/**
	 * indicates if video is enabled or not. This is false for example, in audio calls.
	 */
	get isVideoEnabled(): boolean
	{
		throw new TelehealthError(TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED,
			"isVideoEnabled is not implemented in IOSTelehealth");
	}

	/**
	 * iOS handles this on native side. Web side is only for chat / waiting room
	 * so technically always muted.
	 */
	get isMuted(): boolean
	{
		throw new TelehealthError(TELEHEALTH_ERROR_TYPE.NOT_IMPLEMENTED, "isMuted is not implemented in IOSTelehealth");
	}

	get videoDisplayMode(): VideoDisplayMode
	{
		return VideoDisplayMode.COVER;
	}
}
