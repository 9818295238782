import MHABackendLogger from "@/lib/utils/MHABackendLogger";

window.addEventListener("error", (event) =>
{
	const target = (event.target as HTMLElement);
	if (target && target.localName === "script" && target.getAttribute("src").match(/^.*chunk.*.js$/))
	{
		// extract query params from URL fragment
		const urlFragmentParams = getFragmentParams(location.hash);
		const urlFragmentPath = getFragmentPath(location.hash);

		// reload if we haven't already
		if (urlFragmentParams.get("chunk_error") !== "true")
		{
			console.error("Reloading page due to chunk error!");
			MHABackendLogger.warn(`Reloading page due to chunk error!\n Chunk: ${target.getAttribute("src")}`);

			urlFragmentParams.append("chunk_error", "true");
			location.replace(location.origin + location.pathname + urlFragmentPath + "?" + urlFragmentParams.toString());
			location.reload();
		}
		else
		{
			console.error("Chunk error detected but we have already reloaded! Doing nothing.");
			MHABackendLogger.warn(`Chunk error detected but we have already reloaded! Doing nothing.
			                        Chunk: ${target.getAttribute("src")}`);
		}
	}
}, {capture: true, passive: true});

function getFragmentPath(urlHash: string): string
{
	const match = urlHash.match(/([^?]*)\??(.*)$/);
	if (match && match[1])
	{
		return match[1];
	}
	return "#/";
}

function getFragmentParams(urlHash: string): URLSearchParams
{
	const match = urlHash.match(/([^?]*)\??(.*)$/);
	if (match && match[2])
	{
		return new URLSearchParams(match[2]);
	}
	return new URLSearchParams();
}
