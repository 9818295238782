export enum VALIDATION_TYPE
{
	UNKNOWN,

	// Generic
	REQUIRED_FIELD,

	// Patient user fields
	PATIENT_CELL,
	PATIENT_HEALTH_NUMBER,
	PATIENT_DOB,
}

/**
 * represents a validation that can be checked.
 * basic use:
 * create -> validate at later point -> display msg or accept depending on result
 */
export default class Validation
{
	protected failedMessage: string;
	protected validationFunction: () => boolean;
	public type: VALIDATION_TYPE;
	public name = "";

	/**
	 * build a validation object
	 * @param valFunc - the validation check function
	 * @param obj - the object to check
	 * @param property - the property of the object to check. can be a string like "fiz.bang.bar"
	 * @param msg - the message to display on validation failure
	 * @param type - validation type code, if not provided uses VALIDATION_TYPE.UNKNOWN
	 * @param name - generic identifier for the validation
	 */
	public static buildValidation(valFunc: (obj: any) => boolean, // tslint:disable-next-line
		obj: any, property: string, msg: string, type?: VALIDATION_TYPE, name?: string)
	{
		return new Validation(() =>
		{
			let prop = obj;
			for (const propSearch of property.split("."))
			{
				prop = prop[propSearch];
			}

			return valFunc(prop);
		}, msg, type, name);
	}

	constructor(validationFunc: () => boolean, failedMessage: string, type?: VALIDATION_TYPE, name?: string)
	{
		this.validationFunction = validationFunc;
		this.failedMessage = failedMessage;
		this.type = type || VALIDATION_TYPE.UNKNOWN;
		this.name = name;
	}

	get isValid()
	{
		return this.validationFunction();
	}

	get validationFailedMessage()
	{
		return this.failedMessage;
	}
}
