

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({components: {}})
	export default class CareCard extends Vue
	{
		@Prop({type: String}) titleLineOne: string;
		@Prop({type: String}) titleLineTwo: string;
		@Prop({type: String}) message: string;
	}
