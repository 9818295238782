
	import {Options, Vue, Prop} from "vue-property-decorator";
	import OrgImage from "@/components/Organization/OrgImage.vue";
	import {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";
	import {IMAGE_BRANDED} from "@/assets/AppIcons";

	@Options({components: {OrgImage}})
	export default class WhitelabelPrimaryLogo extends Vue
	{
		@Prop({type: Boolean}) logoLight: boolean;

		public IMAGE_BRANDED = IMAGE_BRANDED;

		public get logoType()
		{
			return this.logoLight ? ORG_ASSET_TYPE.LOGO_LIGHT : ORG_ASSET_TYPE.LOGO;
		}
	}
