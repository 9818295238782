import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bf77754"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "check-icon d-flex justify-content-center align-items-center m-r-8"
}
const _hoisted_3 = { class: "status-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["verification-status d-flex flex-row m-t-16", _ctx.verificationStatusClass]),
    title: _ctx.verificationTooltip,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick($event)))
  }, [
    (!_ctx.isDependent)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.verificationIcon)
          }, null, 2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.verificationStatusMessage), 1)
  ], 10, _hoisted_1))
}