import MHABackendLogger from "@/lib/utils/MHABackendLogger";
import {app} from "@/main";
import {RouteRecordRaw} from "vue-router";

const TermsAndConditions = () => import(/* webpackChunkName: "Public" */ "@/views/public/TermsAndConditionsPage.vue");
const PrivacyPolicy = () => import(/* webpackChunkName: "Public" */ "@/views/public/PrivacyPolicyPage.vue");
const CookiesNotEnabled = () => import(/* webpackChunkName: "Public" */ "@/views/public/CookiesNotEnabled.vue");
const ErrorPage = () => import(/* webpackChunkName: "Public" */ "@/views/public/ErrorPage.vue");

export enum OrgPublicRoute
{
	TermsConditions = "OrgTermsAndConditions",
	PrivacyPolicy = "OrgPrivacyPolicy",
	CookiesNotEnabled = "OrgCookiesNotEnabled",
	ErrorPage = "OrgErrorPage",
}

export const orgPublicRoutes: RouteRecordRaw[] = [
	{
		path: "/org/:organizationId/terms",
		name: OrgPublicRoute.TermsConditions,
		component: TermsAndConditions,
		props: true,
		meta: {
			page: 0,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/privacy",
		name: OrgPublicRoute.PrivacyPolicy,
		component: PrivacyPolicy,
		props: true,
		meta: {
			page: 0,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/cookies-not-enabled",
		name: OrgPublicRoute.CookiesNotEnabled,
		component: CookiesNotEnabled,
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/error_page/:catchAll(.*)?",
		alias: "/org/:organizationId/:catchAll(.*)?",
		name: OrgPublicRoute.ErrorPage,
		component: ErrorPage,
		props: true,
		meta: {
			brandedRoute: true,
		},

		async beforeEnter(to, from, next)
		{
			MHABackendLogger.warn("Routing to error page.\n" +
				`errorCode: ${to.params.errorCode} \n` +
				`errorTitle: ${to.query.errorTitle} \n` +
				`errorMessage: ${to.query.errorMessage}`);
			next();
		},
	},
];
