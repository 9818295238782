

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {IonHeader} from "@ionic/vue";

	@Options({components: {IonHeader}})
	export default class HeaderBar extends Vue
	{
		@Prop({type: Boolean}) overlay: boolean;
		@Prop({type: Boolean}) white: boolean;
		@Prop({type: Object}) headerStyleOverride: any;
	}
