
	import BookingController, {setBookingParams} from "@/views/patient_user/booking/BookingController";
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import ContactClinicRouteButton from "./components/ContactClinicRouteButton.vue";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import ClinicService from "@/lib/clinics/Clinic.service";
	import {isBlank} from "@/lib/utils/prototype/String";
	import Provider from "@/lib/clinic/providerProfile.model";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";
	import {bookingForward, dynamicBookingRoute, getBookingClinicService} from "@/views/patient_user/booking/BookingRoutingHelper";
	import DeviceInfo from "@/lib/DeviceInfo";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import {NotificationSeverity} from "@/lib/types/Notifier";
	import NotificationService from "@/components/Notification/NotificationService";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import BookingServiceFactory from "@/lib/booking/factory/BookingServiceFactory";

	export default {
		name: "BookingProvider",

		props: {
			clinicId: String,
			dependentId: String,
			providerGroupId: String,
			rescheduleRemoteAppointmentId: String,
			// if true back buttons will be shown in the top bar.
			hasBackButtons: {
				default: true,
				type: Boolean,
			},
			hasClose: {
				type: Boolean,
				required: false,
				default: true,
			},
		},

		data()
		{
			return {
				isLoading: true,
				loadingMessage: this.$mhat("BookingProvider.LoadingTitle"),
				providers: [] as Provider[],
				ButtonColor,
				ButtonColorPattern,
			};
		},

		async beforeRouteEnter(to, from, next)
		{
			const dependentId = to.params.dependentId;
			const clinicId = to.params.clinicId;
			const clinicService = getBookingClinicService(dependentId);
			const clinicProfile = await getBookingClinicService().getClinic(clinicId);

			const bookingService = await new BookingServiceFactory().getBookingService(clinicId, to.params.providerGroupId, clinicService);
			const clinicProviders = await bookingService.getProviders(clinicId, to.params.providerGroupId);

			if (DeviceInfo.isCloudMd)
			{
				// If CloudMd skip provider selection
				const cloudMdClinic: ClinicProfile =
					await getBookingClinicService(dependentId).getCloudMdClinic();
				return bookingForward(dynamicBookingRoute(dependentId).AppointmentType,
					{
						dependentId,
						clinicId: cloudMdClinic.id,
						providerOrGroupId: cloudMdClinic.primaryProvider?.id,
					},
					next);
			}

			if (clinicProfile && clinicProfile.allowSkippingProviderSelection && clinicProviders && clinicProviders.length === 1)
			{
				return bookingForward(dynamicBookingRoute(dependentId).AppointmentType,
					{
						dependentId: dependentId,
						clinicId: clinicId,
						providerOrGroupId: clinicProviders[0].id,
						organizationId: OrganizationStore.organizationProfile?.url_friendly_name,
					},
					next,
					to.query);
			}

			next();
		},

		async created()
		{
			this.clinicService = getBookingClinicService(this.dependentId);
			setBookingParams.call(this);
			this.controller.clearAppointmentState();

			try
			{
				const bookingService = await new BookingServiceFactory().getBookingService(this.clinicId, this.providerGroupId, this.clinicService);
				this.providers = await bookingService.getProviders(this.clinicId, this.providerGroupId);
			}
			catch (error)
			{
				if (error instanceof ErrorResponse && (error as ErrorResponse).is(ErrorCode.RemotePatientInactive))
				{
					NotificationService.notificationDismiss(this.$mhat("BookingProvider.ClinicConnectionProblem"), error.message, NotificationSeverity.Warning);
				}
			}
			finally
			{
				this.isLoading = false;
			}
		},

		methods: {

			setSelected(selected: Provider)
			{
				this.isLoading = true;
				this.loadingMessage = this.$mhat("BookingProvider.ProviderSelectedLoadingMessage");
				const controller: BookingController = this.controller;
				if (controller.provider !== selected)
				{
					controller.provider = selected;
					controller.providerOrGroupId = selected.id;
				}

				this.$mhaRouterPush(this.nextRoute);
			},

			seeNextAvailable()
			{
				this.isLoading = true;
				this.loadingMessage = this.$mhat("BookingProvider.ProviderSelectedLoadingMessage");
				const controller: BookingController = this.controller;

				controller.providerOrGroupId = this.providerGroupId;
				controller.provider = null;

				this.$mhaRouterPush(this.nextRoute);
			},
		},

		computed: {
			failureMessage()
			{
				let clinicName = "This clinic";
				if (this.controller.clinic)
				{
					clinicName = this.controller.clinic.name;
				}
				return this.$mhat("BookingProvider.NoProvidersFailureMessage", {clinicName: clinicName});
			},

			showSpinner()
			{
				return this.isLoading || this.noProviders;
			},

			controller()
			{
				return BookingControllerStore.bookingController;
			},

			noProviders()
			{
				return !this.isLoading && this.providers.length === 0;
			},

			nextRoute()
			{
				const controller: BookingController = this.controller;

				const {appointmentMethod, clinicId, dependentId} = this;
				const {providerOrGroupId} = controller;

				return {
					name: this.isDependentMode ? Route.Booking.Dependent.AppointmentType : Route.Booking.AppointmentType,
					params: {appointmentMethod, dependentId, clinicId, providerOrGroupId},
					query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId},
				};
			},

			isDependentMode()
			{
				return !isBlank(this.dependentId);
			},
		},

		components: { BookingHeaderBar, DependentSubheader, ContactClinicRouteButton },
	};
