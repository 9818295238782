

	import {Options, Vue, Prop} from "vue-property-decorator";
	import TelehealthNotificationItem from "./types/TelehealthNotificationItem.vue";
	import {PatientNotification} from "@/lib/models/PatientNotification";
	import {IonSlide, IonSlides} from "@ionic/vue";
	import BannerNotification from "@/lib/notification/model/BannerNotification";

	@Options({components: {TelehealthNotificationItem, IonSlide, IonSlides}})
	export default class BannerNotificationContainer extends Vue
	{
		@Prop({type: Array}) public readonly notifications: BannerNotification[];

		get notificationsList()
		{
			return this.notifications;
		}
	}
