
	import moment from "moment";
	import {DATE_FORMAT} from "@/constants";
	import {DAY_PERIOD} from "@/lib/models/Appointment";
	import BookingController, {setBookingParams} from "@/views/patient_user/booking/BookingController";
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import NoAppointmentsImage from "@/assets/images/graphics/no-appointments.svg";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import {isBlank} from "@/lib/utils/prototype/String";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";
	import ScheduleSlot from "@/lib/scheduling/model/ScheduleSlot";
	import {DateUtils} from "@/lib/utils/Date";
	import {IonButton} from "@ionic/vue";
	import {getBookingClinicService} from "@/views/patient_user/booking/BookingRoutingHelper";

	export default {
		name: "BookingAppointmentTime",

		props: {
			appointmentMethod: String,
			clinicId: String,
			providerOrGroupId: String,
			appointmentTypeId: String,
			appointmentDate: String,
			dependentId: String,
			hasClose: {
				type: Boolean,
				required: false,
				default: true,
			},
			rescheduleRemoteAppointmentId: String,
		},

		data()
		{
			return {
				DAY_PERIOD,
				selectedPeriod: null as DAY_PERIOD,
				NoAppointmentsImage,
				ButtonColor,
				ButtonColorPattern,
			};
		},

		async created()
		{
			setBookingParams.call(this);
			await this.controller.fetchAppointmentData(moment(this.appointmentDate, DATE_FORMAT.DATE_STRING));
		},

		methods: {

			async setSlot(slot: ScheduleSlot, index: number)
			{
				this.controller.selectAppointmentSlot(slot);
				this.controller.providerOrGroupId = slot.providerId;
				this.controller.provider = await getBookingClinicService().patientClinicProvider(this.clinicId, slot.providerId);
				this.$mhaRouterPush({
					name: this.isDependentMode ? Route.Booking.Dependent.Reason : Route.Booking.Reason,
					params: {...this.params, appointmentId: index.toString(), providerOrGroupId: slot.providerId},
					query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId},
				});
			},

			getDisplayTime(slot: ScheduleSlot): string
			{
				return slot.startDateTime.format(DATE_FORMAT.H_MM_A);
			},

			filterByPeriod(dayPeriod: DAY_PERIOD): ScheduleSlot[]
			{
				if (!this.noAppointmentsForDay)
				{
					return BookingController.filterAppointmentsByPeriod(dayPeriod, this.appointmentsForDay);
				}

				return [];
			},
		},

		computed: {
			failureMessage()
			{
				return this.$mhat("BookingAppointmentTime.FailureMessage", {selectedDate: this.dateDisplay});
			},

			dateDisplay()
			{
				return moment(this.appointmentDate, "YYYY-MM-DD").format("dddd, MMMM Do");
			},

			showSpinner()
			{
				return this.isLoading || this.noAppointmentsForDay;
			},

			isLoading()
			{
				return this.controller.isLoading;
			},

			controller()
			{
				return BookingControllerStore.bookingController;
			},

			noAppointmentsForDay()
			{
				return !this.isLoading && this.appointmentsForDay.length === 0;
			},

			appointmentsForDay()
			{
				return this.controller.availableSlots.filter((slot) => slot.startDateString === this.appointmentDate);
			},

			morningSlots()
			{
				return this.filterByPeriod(DAY_PERIOD.MORNING);
			},

			afternoonSlots()
			{
				return this.filterByPeriod(DAY_PERIOD.AFTERNOON);
			},

			eveningSlots()
			{
				return this.filterByPeriod(DAY_PERIOD.EVENING);
			},

			prevRoute()
			{
				const {params} = this;

				return {
					name: this.isDependentMode ? Route.Booking.Dependent.Calendar : Route.Booking.Calendar,
					params,
				};
			},

			params()
			{
				const {appointmentMethod, dependentId, clinicId, providerOrGroupId, appointmentTypeId, appointmentDate} = this;
				return {appointmentMethod, dependentId, clinicId, providerOrGroupId, appointmentTypeId, appointmentDate};
			},

			isDependentMode()
			{
				return !isBlank(this.dependentId);
			},
		},

		components: {BookingHeaderBar, DependentSubheader, IonButton},
	};
