import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13ceaa12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MailHeader = _resolveComponent("MailHeader")!
  const _component_MessageFeed = _resolveComponent("MessageFeed")!
  const _component_MessageRead = _resolveComponent("MessageRead")!
  const _component_SplitView = _resolveComponent("SplitView")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, {
    contentWidthFull: "",
    contentFullHeight: "",
    contentHeaderRemoveFalseHeaderSpace: !_ctx.showSpinner,
    contentBodyBottomMarginDisabled: "",
    contentNotchCorrectionDisabled: ""
  }, _createSlots({
    content: _withCtx(() => [
      (!_ctx.showSpinner)
        ? (_openBlock(), _createBlock(_component_SplitView, {
            key: 0,
            "hide-right": _ctx.$isMobile,
            noHeader: _ctx.$isMobile
          }, {
            "split-left": _withCtx(() => [
              _createVNode(_component_MessageFeed, {
                controller: _ctx.controller,
                onSelected: _ctx.onMailSelected,
                onCompose: _ctx.onCompose
              }, null, 8, ["controller", "onSelected", "onCompose"])
            ]),
            "split-right": _withCtx(() => [
              (_ctx.controller.activeMail)
                ? (_openBlock(), _createBlock(_component_MessageRead, {
                    key: 0,
                    mail: _ctx.controller.activeMail,
                    onArchive: _cache[0] || (_cache[0] = () => _ctx.controller.setSelectedArchived()),
                    onUnarchive: _cache[1] || (_cache[1] = () => _ctx.controller.setSelectedArchived()),
                    onReply: _cache[2] || (_cache[2] = () => _ctx.showReplyModal())
                  }, null, 8, ["mail"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["hide-right", "noHeader"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_EventStateSpinner, {
              "is-loading": _ctx.controller.isLoading,
              "loading-icon": "icon-chat",
              "loading-title": this.$mhat('MailHome.LoadingTitle'),
              "success-title": this.$mhat('MailHome.LoadingSuccessTitle'),
              "success-icon": "icon-chat"
            }, null, 8, ["is-loading", "loading-title", "success-title"]),
            (!_ctx.controller.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_RouteButton, {
                    pattern: _ctx.ButtonColorPattern.TRANSPARENT,
                    color: _ctx.ButtonColor.PRIMARY,
                    route: "/"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("MailHome.CloseWindow")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color"])
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 2
  }, [
    (!_ctx.$isMobile)
      ? {
          name: "content-header",
          fn: _withCtx(() => [
            _createVNode(_component_MailHeader, {
              "has-close": _ctx.hasBackButtons ? {name: _ctx.Route.Inbox.Home} : false,
              "no-back": !_ctx.hasBackButtons
            }, null, 8, ["has-close", "no-back"])
          ])
        }
      : undefined
  ]), 1032, ["contentHeaderRemoveFalseHeaderSpace"]))
}