

	import {Options, Vue} from "vue-property-decorator";
	import PasswordField from "@/components/Inputs/PasswordField.vue";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import {PatientLoginService} from "@/lib/services/login/PatientLogin.service";
	import SecurityHeaderBar from "./components/SecurityHeaderBar.vue";
	import {UserProfileController} from "./controllers/UserProfileController";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

	@Options({components: {PasswordField, SecurityHeaderBar}})
	export default class SecurityHome extends Vue
	{
		public controller: UserProfileController = new UserProfileController();
		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		public onChangePassword()
		{
			this.$mhaRouterPush(this.Route.Settings.SecurityPassword);
		}

		public onChangeEmail()
		{
			this.$mhaRouterPush(this.Route.Settings.SecurityEmail);
		}

		public async onDeactivate()
		{
			WebNotification.$emit({
				event: NotifyEvent.ConfirmDeactivate,
				type: NotificationType.Confirm,
				severity: NotificationSeverity.Critical,
				title: this.$mhat("SecurityHome.DeactivateAccountConfirmationAlertTitle"),
				message: this.$mhat("SecurityHome.DeactivateAccountConfirmationAlertMessage"),
				confirm: {
					message: this.$mhat("SecurityHome.Deactivate"),
					callback: () =>
					{
						this.controller.onDeactivate()
							.then(() =>
							{
								// TODO don't pass components to services
								new PatientLoginService().logout(this as any);
							});
					},
				},
				dismiss: {
					message: this.$mhat("SecurityHome.Cancel"),
				},
			});
		}
	}
