

	import {LOGO_BRAND} from "@/assets/AppIcons";
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {modalController} from "@ionic/vue";

	@Options({})
	export default class ModalHeader extends Vue
	{
		@Prop() headerText: string;
		@Prop({default: undefined}) headerIcon: string;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public handleClose(): void
		{
			if (modalController.getTop())
			{
				modalController.dismiss();
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get headerProps(): any
		{
			return {
				headerText: this.headerText,
				icon: this.headerIcon,
				hasClose: true,
				noBack: true,
				onCloseClick: this.handleClose,
			};
		}
	}
