import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b9f5aaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reason-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_MessageInput = _resolveComponent("MessageInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, {
        "header-text": _ctx.$mhat('BookingReason.AddAReason'),
        "has-close": $props.hasClose
      }, null, 8, ["header-text", "has-close"])
    ]),
    _: 2
  }, [
    ($options.controller.provider)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            ($options.isDependentMode)
              ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                  key: 0,
                  dependent: $options.controller.dependent
                }, null, 8, ["dependent"]))
              : _createCommentVNode("", true),
            _createElementVNode("p", null, _toDisplayString(_ctx.$mhat('BookingReason.ReasonInstructionText', {providerFullNameWithPrefixSuffix: $options.controller.provider.fullNameWithPrefixSuffix()})), 1),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_MessageInput, {
                "no-button": $data.isMobile,
                active: $options.validReason,
                value: $options.controller.reason,
                placeholder: _ctx.$mhat('BookingReason.ReasonPlaceholder'),
                maxlength: "80",
                onInput: $options.handleInput,
                onSend: $options.addReason
              }, null, 8, ["no-button", "active", "value", "placeholder", "onInput", "onSend"])
            ]),
            _createVNode(_component_BaseButton, {
              primary: "",
              disabled: !$options.validReason,
              onClick: $options.addReason
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$mhat('BookingReason.ContinueButtonText')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        }
      : undefined
  ]), 1024))
}