import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-top": _withCtx(() => [
      (!_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$mhat('NavigatorBookingLandingPage.NavigatorBookingInstructionText')), 1),
            _createVNode(_component_BaseButton, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToAqsBooking()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$mhat('NavigatorBookingLandingPage.TalkToNavigator')), 1)
              ]),
              _: 1
            }),
            (_ctx.patientHasNavigatorAssignment)
              ? (_openBlock(), _createBlock(_component_BaseButton, {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toBooking()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$mhat('NavigatorBookingLandingPage.BookAnAppointment')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}