/**
 * responsible for managing incoming and outgoing video / audio streams.
 */
import SessionCommunicator from "@/lib/telehealth/opentok/SessionCommunicator";
import StateTracker from "@/lib/telehealth/opentok/StateTracker";
import {SignalDispatcher} from "@/lib/telehealth/opentok/SignalDispatcher";
import {OTError, Publisher, Subscriber} from "@opentok/client";
import {
	OT_CUSTOM_SIGNAL, OT_ERROR_CODE, OT_EVENT_TYPE, OT_LOCAL_SIGNAL, OT_SUBSCRIBER_STYLE,
	PublisherProperties, SignalEvent, StreamEvent, SubscriberProperties, VIDEO_RESOLUTION,
} from "@/lib/telehealth/opentok/ot.types";
import TelehealthError, {TELEHEALTH_ERROR_TYPE} from "@/lib/telehealth/error/TelehealthError";
import {AV_ERROR, TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import SessionClient from "@/lib/telehealth/opentok/SessionClient";
import {MediaDeviceStatus} from "@/lib/mediaDevices";
import {VideoDisplayMode} from "@/lib/telehealth/models/VideoDisplayMode";
import {LOGO_BRAND} from "@/assets/AppIcons";
import DeviceInfo from "@/lib/DeviceInfo";

/**
 * responsible for sending and receiving AV streams
 */
export default class StreamManager
{
	// used to track if the publisher has to be cleaned up or not
	protected published = false;
	// used to track state transitions fromState -> toState
	protected currentState = TELEHEALTH_STATE.NOT_CONNECTED;
	protected readonly STREAM_CHECK_INTERVAL = 5000;
	protected streamCheckInterval = null;

	// RTC quality tracking
	protected readonly QUALITY_PROBLEM_ACTION_THRESHOLD = 20;
	protected qualityProblemIndicator = 0;
	protected previousNackCount = 0;
	protected previousDroppedFrames = 0;

	protected sessionCommunicator: SessionCommunicator;
	protected signalDispatcher: SignalDispatcher;
	protected stateTracker: StateTracker;
	protected onAVErrorCallbacks: CallbackCollection = null;
	protected publisher: Publisher = null;
	protected videoSelector: string = null;
	protected videoPreviewSelector: string = null;

	// initial publisher properties
	protected publisherProperties: PublisherProperties = {
		insertMode: "append",
		showControls: false,
		mirror: false,
		resolution: VIDEO_RESOLUTION.HIGH,
		// In a perfect world these (publishAudio / publishVideo) would be "false" until the call starts.
		// This is preferable because when these are true the users "camera recording light" lights up
		// (it's the light on your laptop by the camera).
		// However, this causes issues on FireFox (works perfect on Chrome). Perhaps in the future depending on browser
		// adjust these values..
		publishAudio: true,
		publishVideo: true,
		width: "100%",
		height: "100%",
	};

	// subscription properties
	protected subscriberProperties: SubscriberProperties = {
		width: "auto",
		height: "100%",
		fitMode: "contain",
		showControls: true,
		insertMode: "append",
	};

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		sessionCommunicator: SessionCommunicator,
		signalDispatcher: SignalDispatcher,
		stateTracker: StateTracker,
		onAVErrorCallbacks: CallbackCollection)
	{
		this.sessionCommunicator = sessionCommunicator;
		this.signalDispatcher = signalDispatcher;
		this.stateTracker = stateTracker;
		this.onAVErrorCallbacks = onAVErrorCallbacks;

		this.setupSessionHooks();
	}

	/**
	 * set the DOM selector used to inject the video
	 * @param selector - the selector
	 */
	public setVideoSelector(selector: string): void
	{
		this.videoSelector = selector;
	}

	/**
	 * set the DOM selector used to inject the video preview
	 * @param selector - the selector
	 */
	public setVideoPreviewSelector(selector: string): void
	{
		this.videoPreviewSelector = selector;
	}

	/**
	 * enable or disable audio streaming
	 * @param enable
	 */
	public enableAudio(enable: boolean): void
	{
		if (this.publisher)
		{
			this.publisher.publishAudio(enable);
		}

		this.publisherProperties.publishAudio = enable;
	}

	/**
	 * enable or disable video streaming
	 * @param publish - if true publish video. false don't.
	 */
	public publishVideo(publish: boolean): void
	{
		if (this.videoEnabled === publish)
		{
			return;
		}

		this.publisherProperties.publishVideo = publish;

		if (this.publisher)
		{
			this.publisher.publishVideo(publish);
		}
	}

	/**
	 * change the visual presentation of remote client video
	 * @param videoDisplayMode
	 */
	public changeVideoDisplayMode(videoDisplayMode: VideoDisplayMode): void
	{
		this.subscriberProperties.fitMode = videoDisplayMode;
		this.stateTracker.clients.forEach((client) =>
		{
			if (client.isSubscribed)
			{
				this.sessionCommunicator.unsubscribe(client.subscriber);
				client.setSubscriber(null);
				this.subscribeToClient(client);
			}
		});
	}

	/**
	 * cycle through available cameras
	 */
	public cycleCamera(): void
	{
		if (this.publisher)
		{
			this.publisher.cycleVideo();
		}
	}

	/**
	 * start streaming (publishing)
	 */
	public async startStreaming(): Promise<void>
	{
		if (!this.publisher)
		{
			await this.createPublisher();
		}

		await this.sessionCommunicator.publish(this.publisher);
		this.published = true;

		this.streamCheckInterval = window.setInterval(() => this.checkStream(), this.STREAM_CHECK_INTERVAL);
	}

	/**
	 * stop streaming (publishing)
	 */
	public stopStreaming(): void
	{
		if (this.streamCheckInterval)
		{
			window.clearInterval(this.streamCheckInterval);
			this.streamCheckInterval = null;
		}

		if (this.published)
		{
			this.destroyPublisher();
		}
	}

	/**
	 * destroy the publisher
	 */
	public destroyPublisher(): void
	{
		if (this.publisher)
		{
			if (this.published)
			{
				this.sessionCommunicator.unPublish(this.publisher);
				this.published = false;
			}
			this.publisher.destroy();
			this.publisher = null;
		}
	}

	/**
	 * subscribe to all available but currently unsubscribed streams
	 */
	public async subscribeToAllAvailableStreams(): Promise<void>
	{
		const clients = this.stateTracker.clients.filter(
			(client) => !client.isSelf && client.isSubscribable && !client.isSubscribed);

		for (const client of clients)
		{
			await this.subscribeToClient(client);
		}
	}

	/**
	 * unsubscribe from all AV streams
	 */
	public unsubscribeFromAllStreams(): void
	{
		const clients = this.stateTracker.clients.filter(
			(client) => !client.isSelf && client.isSubscribed);

		for (const client of clients)
		{
			this.sessionCommunicator.unsubscribe(client.subscriber);
			client.setSubscriber(null);
		}
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get audioEnabled(): boolean
	{
		return this.publisherProperties.publishAudio;
	}

	get videoEnabled(): boolean
	{
		return this.publisherProperties.publishVideo;
	}

	get videoDisplayMode(): VideoDisplayMode
	{
		return this.subscriberProperties.fitMode as VideoDisplayMode;
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	protected setupSessionHooks(): void
	{
		this.signalDispatcher.addSignalHandler(OT_EVENT_TYPE.SESSION_CONNECTED, this.onSessionConnected, this);
		this.signalDispatcher.addSignalHandler(OT_EVENT_TYPE.STREAM_CREATED, this.onStreamCreated, this);
		this.signalDispatcher.addSignalHandler(OT_EVENT_TYPE.STREAM_DESTROYED, this.onStreamDestroyed, this);

		this.signalDispatcher.addSignalHandler(OT_LOCAL_SIGNAL.STATE_CHANGE, this.onCallStateChange, this);
	}

	protected async onSessionConnected(): Promise<void>
	{
		if (this.publisher)
		{
			// orphan publisher.
			this.publisher.destroy();
			this.publisher = null;
		}

		await this.createPublisher();
	}

	protected async onStreamCreated(event: StreamEvent): Promise<void>
	{
		if (this.stateTracker.inRoom)
		{
			const client = this.stateTracker.getRemoteClientByConnectionId(event.stream.connection.connectionId);
			if (client && !client.isSubscribed && !client.isAttemptingToSubscribe)
			{
				client.setStream(event.stream);
				await this.subscribeToClient(client);
			}
			else if (!client)
			{
				console.error("Skipping subscribe to stream because client is unknown!");
			}
			else if (client.isSubscribed)
			{
				console.error("Skipping subscribe because we are already subscribed to client");
			}
			else if (client.isAttemptingToSubscribe)
			{
				console.error("Skipping subscribe, already attempting to subscribe");
			}
			else
			{
				console.error("Failed to subscribe");
			}
		}
	}

	protected onStreamDestroyed(event: StreamEvent): void
	{
		const client = this.stateTracker.getRemoteClientByConnectionId(event.stream.connection.connectionId);
		if (client)
		{
			if (client.subscriber)
			{
				this.sessionCommunicator.unsubscribe(client.subscriber);
				client.setSubscriber(null);
			}
		}
		else
		{
			console.error("Skipping un subscribe to stream because client is unknown!");
		}
	}

	protected async onCallStateChange(event: SignalEvent): Promise<void>
	{
		if (event.data === TELEHEALTH_STATE.IN_ROOM)
		{
			this.stopStreaming();
			await this.startStreaming();
			await this.subscribeToAllAvailableStreams();
		}
		else if (this.currentState === TELEHEALTH_STATE.IN_ROOM && event.data === TELEHEALTH_STATE.OUT_OF_ROOM)
		{ // moved from IN_ROOM -> OUT_OF_ROOM
			this.stopStreaming();
			this.unsubscribeFromAllStreams();
		}

		this.currentState = event.data as TELEHEALTH_STATE;
	}

	/**
	 * subscribe to the remote clients stream
	 * @param client - the remote client to subscribe to.
	 * @protected
	 */
	protected async subscribeToClient(client: SessionClient): Promise<void>
	{
		if (client && !client.isSubscribed && client.isSubscribable)
		{
			try
			{
				client.lockSubscriptionAttempt();

				const subscriber = await this.sessionCommunicator.subscribe(
					client.stream,
					document.querySelector(this.videoSelector) as HTMLElement,
					this.subscriberProperties);

				this.setSubscriberStyle(subscriber);
				client.setSubscriber(subscriber);
			}
			finally
			{
				client.unlockSubscriptionAttempt();
			}
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.LOGIC_ERROR, "Attempting to subscribe to unsubscribable client");
		}
	}

	/**
	 * set subscriber style parameters
	 * @param subscriber - the subscriber to style
	 * @protected
	 */
	protected setSubscriberStyle(subscriber: Subscriber): void
	{
		subscriber.setStyle(OT_SUBSCRIBER_STYLE.BACKGROUND_IMAGE, `${location.protocol}//${location.hostname}${LOGO_BRAND.toString()}`);
		subscriber.setStyle(OT_SUBSCRIBER_STYLE.AUDIO_BLOCKED_DISPLAY_MODE, "off");
		subscriber.setStyle(OT_SUBSCRIBER_STYLE.VIDEO_DISABLED_DISPLAY_MODE, "off");
		subscriber.setStyle(OT_SUBSCRIBER_STYLE.AUDIO_LEVEL_DISPLAY_MODE, "off");
		subscriber.setStyle(OT_SUBSCRIBER_STYLE.BUTTON_DISPLAY_MODE, "off");
		subscriber.setStyle(OT_SUBSCRIBER_STYLE.NAME_DISPLAY_MODE, "off");
	}

	/**
	 * create a new publisher. Does not publish!
	 * @protected
	 */
	protected createPublisher(): Promise<void>
	{
		return new Promise((resolve, reject) =>
		{
			this.publisher = OT.initPublisher(
				document.querySelector(this.videoPreviewSelector) as HTMLElement,
				this.publisherProperties,
				async (error) =>
				{
					if (error)
					{
						this.handlePublishingError(error);
					}
					else
					{
						this.stateTracker.getSelf?.setAudioStatus(
							this.publisher.getAudioSource() ? MediaDeviceStatus.GRANTED : MediaDeviceStatus.NOT_FOUND);
						// OT docs say that Publisher has a getVideoSource() method. we don't seem to have it. use getImageData() for now
						this.stateTracker.getSelf?.setVideoStatus(
							this.publisher.getImgData() ? MediaDeviceStatus.GRANTED : MediaDeviceStatus.NOT_FOUND);
					}

					if (this.stateTracker.getSelf)
					{
						await this.sessionCommunicator.broadcast(OT_CUSTOM_SIGNAL.PEER_META_DATA, this.stateTracker.getSelf.toJson);
					}
					resolve();
				});
		});
	}

	protected handlePublishingError(error: OTError | undefined): void
	{
		if (error)
		{
			this.publisher = null;

			switch (error.name)
			{
				case OT_ERROR_CODE.OT_USER_MEDIA_ACCESS_DENIED:
					this.stateTracker.getSelf?.setVideoStatus(MediaDeviceStatus.NOT_ALLOWED);
					this.stateTracker.getSelf?.setAudioStatus(MediaDeviceStatus.NOT_ALLOWED);
					this.onAVErrorCallbacks.call(AV_ERROR.PERMISSION_DENIED);
					break;
				case OT_ERROR_CODE.OT_UNABLE_TO_CAPTURE_MEDIA:
				case OT_ERROR_CODE.OT_HARDWARE_UNAVAILABLE:
					this.stateTracker.getSelf?.setVideoStatus(MediaDeviceStatus.ABORT_ERROR);
					this.stateTracker.getSelf?.setAudioStatus(MediaDeviceStatus.ABORT_ERROR);
					this.onAVErrorCallbacks.call(AV_ERROR.HARDWARE_FAULT);
					break;
				case OT_ERROR_CODE.OT_NO_DEVICES_FOUND:
					this.stateTracker.getSelf?.setVideoStatus(MediaDeviceStatus.NOT_FOUND);
					this.stateTracker.getSelf?.setAudioStatus(MediaDeviceStatus.NOT_FOUND);
					this.onAVErrorCallbacks.call(AV_ERROR.NO_DEVICES);
					break;
				default:
					this.stateTracker.getSelf?.setVideoStatus(MediaDeviceStatus.UNKNOWN);
					this.stateTracker.getSelf?.setAudioStatus(MediaDeviceStatus.UNKNOWN);
					throw new TelehealthError(
						TELEHEALTH_ERROR_TYPE.STREAMING_ERROR,
						`Failed to create publisher with error: ${error.name}. ${error.message}`);
			}
		}
	}

	/**
	 * fires stream check functions at a fixed interval
	 * @protected
	 */
	protected async checkStream(): Promise<void>
	{

	}

	/**
	 * This method is called every 5 seconds while we are publishing a stream.
	 * It monitors and adjusts the quality of the call.
	 * @protected
	 */
	protected async rtcMonitor(): Promise<void>
	{
		if (this.publisher)
		{
			this.publisher.getStats(async (error, statusArray) =>
			{
				if (statusArray)
				{
					this.qualityProblemIndicator = Math.max(this.qualityProblemIndicator - 1, 0);
					const rtcReport = await this.publisher.getRtcStatsReport();

					rtcReport.forEach((peer) =>
					{
						peer.rtcStatsReport.forEach((item) =>
						{
							if (item.type === "outbound-rtp" && item.kind === "video")
							{
								if (item.qualityLimitationReason && item.qualityLimitationReason !== "none")
								{ // Chrome only
									this.qualityProblemIndicator += 2;
								}

								if (item.droppedFrames && item.droppedFrames - this.previousDroppedFrames > 0)
								{ // FF only
									this.previousDroppedFrames = item.droppedFrames;
									this.qualityProblemIndicator += 2;
								}
								if (item.nackCount && item.nackCount - this.previousNackCount > 0)
								{
									this.previousNackCount = item.nackCount;
									this.qualityProblemIndicator += 2;
								}
							}
						});
					});
				}
			});

			// adjust quality  if necessary
			if (this.qualityProblemIndicator >= this.QUALITY_PROBLEM_ACTION_THRESHOLD && !this.stateTracker.hasLegacyClients)
			{ // decrease quality
				switch (this.publisherProperties.resolution)
				{
					case VIDEO_RESOLUTION.HIGH:
						this.publisherProperties.resolution = VIDEO_RESOLUTION.MEDIUM;
						this.stopStreaming();
						this.startStreaming();
						break;
					case VIDEO_RESOLUTION.MEDIUM:
						this.publisherProperties.resolution = VIDEO_RESOLUTION.LOW;
						this.stopStreaming();
						this.startStreaming();
						break;
				}

				this.qualityProblemIndicator = 0;
				// notify Vue code of poor connection
				await this.onAVErrorCallbacks.call(AV_ERROR.POOR_CONNECTION);
			}
		}
	}
}
