import {Vue} from "vue-property-decorator";
import { Patient } from "@/lib/patient/Patient";
import ClinicPageDependentController from "@/views/patient_user/clinic/ClinicPageDependentController";
import { ClinicPageController } from "@/views/patient_user/clinic/ClinicPageController";

export default class ClinicPageControllerFactory
{
	/**
	 * create a new ClinicPageController based on the patient user.
	 * (abstracts selection, of dependent vs regular controller version)
	 * @param vue - vue instance
	 * @param patientUser - patient
	 */
	public static createControllerByPatientUser(vue: Vue, patientUser: Patient)
	{
		if (patientUser.isDependent)
		{
			return new ClinicPageDependentController(vue, patientUser);
		}
		return new ClinicPageController(vue, patientUser);
	}
}
