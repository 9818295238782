

	import {Options, Vue, Prop, Emit} from "vue-property-decorator";
	import ValidationList from "@/lib/utils/ValidationList";
	import InputLayout from "@/components/Inputs/InputLayout.vue";
	import {IonInput, IonTextarea} from "@ionic/vue";

	@Options({
		emits: ["mounted", "input", "keydown"],
		components: {InputLayout, IonInput, IonTextarea},
	})
	export default class BaseInput extends Vue
	{
		// ==========================================================================
		// Properties
		// ==========================================================================

		@Prop({type: String}) id: string;
		@Prop({type: String}) type: string;
		@Prop({type: String}) value: string;
		@Prop({type: String}) labelColor: string;
		@Prop({type: String}) iconLeft: string;
		@Prop({type: String}) iconRight: string;
		@Prop({type: String}) label: string;
		@Prop({type: Boolean}) error: boolean;
		@Prop({type: Boolean}) compact: boolean;
		@Prop({type: Boolean}) disabled: boolean;
		@Prop({type: Boolean}) nostyle: boolean;
		@Prop({type: [Boolean, ValidationList], default: true}) valid: [boolean, ValidationList];
		@Prop({type: Boolean}) invalid: boolean;
		@Prop({type: Boolean}) required: boolean;
		@Prop({type: Boolean}) paddingLeft: boolean;
		@Prop({type: Boolean}) upper: boolean;
		@Prop({type: Boolean}) lower: boolean;
		@Prop({type: Boolean}) hideLabel: boolean;
		@Prop({type: Boolean, default: false}) hideValidationMessage: boolean;
		@Prop({type: String}) placeholder: string;
		@Prop({type: String, default: "on"}) autocapitalize: string;
		@Prop({type: String, default: "on"}) autocomplete: string;
		@Prop({type: Number, default: 1}) zIndex: number;
		@Prop({type: Boolean}) textArea: boolean;
		@Prop({type: Boolean}) autoGrow: boolean; // text-area use only
		@Prop({type: Number}) rows: number; // text-area use only
		@Prop({type: Number}) maxLength: number;
		@Prop({type: Boolean, default: false}) dirty: boolean; // force this input to be dirty

		@Prop({type: RegExp}) allowedPasteRegex: RegExp; // block paste operations that do not match this regex
		@Prop({type: RegExp}) allowedKeydownRegex: RegExp; // block keydown events who's key's do not match this regex

		private pristine = true;

		mounted()
		{
			this.$emit("mounted", this.$refs.input);
		}

		public onInput({detail})
		{
			this.pristine = false;
			this.$emit("input", {id: this.id, value: detail.value});
		}

		public onLabelClicked($event)
		{
			(this.$refs.input as Vue).$el.setFocus();
		}

		public onPaste(event)
		{
			// block paste if it includes invalid chars
			if (event.clipboardData.getData("Text") && this.allowedPasteRegex)
			{
				if (!event.clipboardData.getData("Text").match(this.allowedPasteRegex))
				{
					return event.preventDefault();
				}
			}
		}

		public onKeyDown(event)
		{
			// block keydown events if they do not match allowedCharacter regex
			if (
				this.allowedKeydownRegex &&
				event.key &&
				!event.altKey &&
				!event.ctrlKey &&
				!event.key.match(this.allowedKeydownRegex) &&
				event.code !== "Backspace" &&
				event.key !== "Backspace")
			{
				return event.preventDefault();
			}
			else
			{
				this.$emit("keydown", event);
			}
		}

		get isValid()
		{
			if (this.valid instanceof ValidationList)
			{
				return this.valid.isValid();
			}
			else
			{
				return this.valid;
			}
		}

		get getValidationMessage()
		{
			if (this.valid instanceof ValidationList)
			{
				return this.valid.getValidationResultMsg();
			}
			else
			{
				return "";
			}
		}

		get inputClasses()
		{
			const {disabled, nostyle, required, isValid, pristine} = this;
			return {
				disabled,
				nostyle,
				required,
				valid: isValid,
				invalid: !isValid,
				pristine: this.isPristine,
			};
		}

		get isPristine(): boolean
		{
			return this.pristine && !this.dirty;
		}
	}
