import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import router from "@/router/router";
import {getObjectAttribute} from "@/lib/utils/util";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

// ============== GENERATED BY vue i18n plugin =================== //

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`,
 * which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType>
{
	const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages: LocaleMessages<VueMessageType> = {};
	locales.keys().forEach((key) =>
	{
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1)
		{
			const locale = matched[1];
			messages[locale] = locales(key).default;
		}
	});
	return messages;
}

/*
	TEMPORARY. FOR TESTING. REVERT WHEN DONE.
	Read the locale from a local storage variable.
	Set it to some invalid locale and the page
	will display the keys instead of the translations.
	Or set it to 'fr' to display in French (if available).
	Not having the variable behaves as normal,
	setting the locale to the browser language
 */
const localStorageLocale = window.localStorage.locale;
let locale = navigator.language.substring(0, 2);
let fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en";

if (localStorageLocale)
{
	locale = localStorageLocale.substring(0, 2);
	fallbackLocale = localStorageLocale.substring(0, 2);
}

const i18n = createI18n({
	legacy: false,
	locale: locale,
	fallbackLocale: fallbackLocale,
	messages: loadLocaleMessages(),
	globalInjection: true,
});

export function $mhat(keypath: string, properties: any = {}, defaultString: string = null)
{
	let overrideValue = null;

	if (router)
	{
		const overrideKeyPath = `${i18n.global.locale.value}.${router.currentRoute.value.name as string}.${keypath}`;
		overrideValue = getObjectAttribute(OrganizationStore.organizationLocaleOverrides, overrideKeyPath);
	}

	return overrideValue || i18n.global.t(keypath, {default: defaultString, ...properties});
}

export default i18n;
