import Validation, { VALIDATION_TYPE } from "@/lib/utils/Validation";

export default class ValidationList extends Array<Validation>
{
	/** see https://blog.simontest.net/extend-array-with-typescript-965cc1134b3
	 * to understand why this is necessary.
	 */
	public constructor(...items: Validation[])
	{
		super(...items);
		Object.setPrototypeOf(this, Object.create(ValidationList.prototype));
	}

	static create(): ValidationList
	{
		return Object.create(ValidationList.prototype);
	}

	/**
	 * get validations found with the given type code.
	 * @param type - the code to search for
	 */
	public getByType(type: VALIDATION_TYPE): ValidationList
	{
		return new ValidationList(...this.filter((validation) => validation.type === type));
	}

	/**
	 * remove validations from this list with the given type
	 * @param type - the type of the validations to remove
	 */
	public removeByType(type: VALIDATION_TYPE)
	{
		let index = 0;
		for (const validation of this)
		{
			if (validation.type === type)
			{
				this.splice(index, 1);
				index--;
			}
			index++;
		}
	}

	/**
	 * validate all validations in this list
	 */
	public isValid(): boolean
	{
		for (const validation of this)
		{
			if (!validation.isValid)
			{
				return false;
			}
		}
		return true;
	}

	/**
	 * get user display message as a result of validation.
	 */
	public getValidationResultMsg(): string
	{
		return this.map((val) => val.isValid ? "" : val.validationFailedMessage).reduce((acc, msg) => acc + msg, "");
	}

	/**
	 * merge to validation lists to create a new one
	 * @param other - other list to merge with
	 */
	public concat(other: ValidationList): ValidationList
	{
		const newValidationList = ValidationList.create();

		for (const validation of this)
		{
			newValidationList.push(validation);
		}
		for (const validation of other)
		{
			newValidationList.push(validation);
		}

		return newValidationList;
	}

	/**
	 * like concat but can accept multiple validations list
	 * @param other - other lists to merge with.
	 */
	public concatAll(...other: ValidationList[])
	{
		let newValidationList = ValidationList.create();
		for (const validationList of other)
		{
			newValidationList = newValidationList.concat(validationList);
		}
		return newValidationList;
	}
}
