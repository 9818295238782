

	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import {Validator} from "@/lib/utils/Validator";
	import Services from "@/lib/services/Services";
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {AuthInfo, ErrorCode} from "@/open_api/generated";
	import {removeURLParameter} from "@/lib/utils/history";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {Route} from "@/router/router";
	import {RouteLocationRaw} from "vue-router";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

	@Options({})
	export default class LoginForm extends Vue
	{
		@Prop(String) urlFriendlyName: string;
		@Prop({type: String}) clinicId: string;
		@Prop({type: Object}) forgotPasswordRoute: RouteLocationRaw;

		public hasFormError = false;
		public isLoading = false;
		public hasSubmitted = false;

		public userInfo: AuthInfo = {
			email: "",
			password: "",
		};

		public mounted()
		{
			this.handleRouteErrors();
		}

		public async login()
		{
			this.hasSubmitted = true;

			if (!this.canSubmit)
			{
				return false;
			}

			this.isLoading = true;
			this.hasFormError = false;
			const {email, password} = this.userInfo;

			try
			{
				await Services.PatientLogin.login(email, password);
				this.handleLoginSuccess();
			}
			catch (e)
			{
				if (e instanceof ErrorResponse)
				{
					this.handleError(e);
				}
				else
				{
					console.error("Unhandled Exception when logging in: " + e);
					throw e;
				}
			}
			finally
			{
				this.isLoading = false;
			}
		}

		public onSignup()
		{
			this.$emit("signup");
		}

		public handleLoginSuccess()
		{
			this.$emit("success");
		}

		public onForgotPassword()
		{
			const routeLocation = this.forgotPasswordRoute ? this.forgotPasswordRoute : Route.Login.Forgot;
			this.$mhaRouterPush(routeLocation);
		}

		public handleRouteErrors()
		{
			const error = this.$route.query.error_code;
			if (error)
			{
				switch (error)
				{
				case ErrorCode.Authentication:
					this.handleValidationError();
					break;
				case ErrorCode.UnconfirmedEmail:
					this.handleUnconfirmedEmailError();
					break;
				case ErrorCode.SessionExpired:
					this.handleSessionExpiredError();
					break;
				default:
					this.handleUnknownError();
					break;
				}
			}
		}

		public handleError(error: ErrorResponse)
		{
			// remove the parameter from the url and update. this prevents NavigationDuplicated errors
			window.location = removeURLParameter(window.location.href, "error_code");

			let errorMessage = error.message;

			if (error.is(ErrorCode.Authentication) || error.is(ErrorCode.Validation))
			{
				this.hasFormError = true;
			}
			else if (error.is(ErrorCode.UnconfirmedEmail))
			{
				this.handleUnconfirmedEmailError();
			}
			else if (error.is(ErrorCode.RecordNotFound))
			{
				errorMessage = this.$mhat("LoginForm.RecordNotFoundErrorMessage");
			}

			this.notifyLoginError(errorMessage);
		}

		private handleUnconfirmedEmailError()
		{
			this.notifyLoginError(this.$mhat("LoginForm.UnconfirmedEmailErrorMessage"));

			let route: RouteLocationRaw = {
				name: this.Route.Login.Confirm,
			};

			if (OrganizationStore.hasOrganization)
			{
				route = {
					name: this.Route.Public.Organization.Confirm,
					params: {
						organizationId: OrganizationStore.organizationProfile.id,
					},
				};
			}

			this.$mhaRouterReplace(route);
		}

		private handleValidationError()
		{
			this.hasFormError = true;
			this.notifyLoginError(this.$mhat("LoginForm.ValidationErrorMessage"));
		}

		private handleUnknownError()
		{
			this.notifyLoginError(this.$mhat("LoginForm.LoginErrorMessage"));
		}

		private handleSessionExpiredError()
		{
			this.notifyLoginError(this.$mhat("LoginForm.SessionExpiredErrorMessage"), this.$mhat("LoginForm.SessionExpiredErrorTitle"));
		}

		public setEmail(payload)
		{
			const email: string = payload.value.trim() || "";
			this.userInfo.email = email;
		}

		public notifyLoginError(message: string, title = this.$mhat("LoginForm.GenericErrorTitle"))
		{
			WebNotification.$emit({
				event: NotifyEvent.InvalidLogin,
				type: NotificationType.Swipe,
				severity: NotificationSeverity.Reminder,
				title,
				message,
				timeout: 5000,
				icon: "icon-lock",
			});
		}

		/* Computed Properties */

		get canSubmit()
		{
			return !this.isLoading && this.isValidEmail && Validator.password(this.userInfo.password);
		}

		get isPasswordValid()
		{
			return this.userInfo?.password?.length >= 8;
		}

		get isValidEmail(): boolean
		{
			return this.userInfo !== null &&
				Validator.emailAddress(this.userInfo.email.trim()) &&
				Validator.isPresent(this.userInfo.email.trim());
		}
	}
