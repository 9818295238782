
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import {PublicAPI} from "@/lib/services/Api";
import {BaseApiService} from "@/lib/services/BaseApiService";
import {ProvinceCode} from "@/open_api/generated";

export default class PublicClinicService extends BaseApiService
{
	constructor()
	{
		super();
	}

	public async getClinicByName(clinicName: string): Promise<ClinicProfile>
	{
		this.isLoading = true;
		const response = await PublicAPI().getClinicByName(clinicName);
		this.isLoading = false;

		return new ClinicProfile(response.data);
	}

	public async getClinic(clinicId: string): Promise<ClinicProfile>
	{
		this.isLoading = true;
		const response = await PublicAPI().getPublicClinicData(clinicId);
		this.isLoading = false;

		return new ClinicProfile(response.data);
	}

	public async getCloudMDClinic(provinceCode?: ProvinceCode, requiredFields?: boolean): Promise<ClinicProfile>
	{
		this.isLoading = true;
		const response = await PublicAPI().getCloudMd(provinceCode, requiredFields);
		this.isLoading = false;

		return new ClinicProfile(response.data);
	}
}
