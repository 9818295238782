

	import {Options, Vue, Prop} from "vue-property-decorator";
	import AppointmentPage from "@/views/patient_user/appointment/components/AppointmentPage.vue";
	import AppointmentsHeader from "./components/AppointmentsHeader.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {modalController} from "@ionic/vue";
	import {Appointment} from "@/lib/models/Appointment";
	import PatientAppointmentService from "@/lib/appointment/service/PatientAppointmentService";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {PatientAPI} from "@/lib/services/Api";
	import PatientClinicService from "@/lib/clinics/PatientClinic.service";

	export enum AppointmentModalDismissReason
	{
		None,
		AppointmentCancelled,
		ExternalRoute,
	}

	@Options({components: {AppointmentsHeader, AppointmentPage}})
	export default class AppointmentInfo extends Vue
	{
		@Prop({type: String}) clinicId: string;
		@Prop({type: String}) remoteAppointmentId: string;
		@Prop({type: Boolean}) isMobile: boolean;
		@Prop() parentCtx: any;

		public appointment: Appointment = null;
		public clinicProfile: ClinicProfile = null;
		public isLoading = false;
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;

		private patientAppointmentService = new PatientAppointmentService();
		private patientClinicService = new PatientClinicService();

		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		public async created(): Promise<void>
		{
			try
			{
				this.isLoading = true;
				this.appointment = await this.patientAppointmentService.getRemoteAppointment(this.clinicId, this.remoteAppointmentId);
				this.clinicProfile = await this.patientClinicService.getClinic(this.clinicId);
			}
			finally
			{
				this.isLoading = false;
			}
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public onAppointmentCancelled(): void
		{
			if (this.isMobile)
			{
				modalController.dismiss(AppointmentModalDismissReason.AppointmentCancelled);
			}
			else
			{
				this.$emit("appointmentCancelled");
			}
		}

		public handleRoute(route)
		{
			this.parentCtx.$mhaRouterPush(route);
		}

		public bookForSelected()
		{
			this.parentCtx.bookForSelected();
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get headerButtonOpts(): any
		{
			return {
				icon: "icon-close",
				text: this.$mhat("AppointmentInfo.CloseButtonText"),
				action: () =>
				{
					if (this.isMobile)
					{
						modalController.dismiss(AppointmentModalDismissReason.None);
					}
				},
			};
		}
	}
