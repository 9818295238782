import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_ClinicSearchSpinner = _resolveComponent("ClinicSearchSpinner")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_ClinicCard = _resolveComponent("ClinicCard")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, {
        "header-text": this.$mhat('BookingClinic.HeaderText'),
        noBack: !$props.hasBackButtons,
        hasClose: $props.hasBackButtons
      }, null, 8, ["header-text", "noBack", "hasClose"])
    ]),
    _: 2
  }, [
    ($data.selectingClinic)
      ? {
          name: "content-center",
          fn: _withCtx(() => [
            _createVNode(_component_EventStateSpinner, {
              "is-loading": $data.selectingClinic,
              "loading-icon": "icon-user-md",
              "loading-title": this.$mhat('BookingClinic.ClinicSelectedLoadingMessage')
            }, null, 8, ["is-loading", "loading-title"])
          ])
        }
      : ($options.shouldShowLoadingSpinner)
        ? {
            name: "content-center",
            fn: _withCtx(() => [
              _createVNode(_component_ClinicSearchSpinner, {
                "search-type": $data.ClinicSearchType.CONNECTED,
                "dependent-id": $props.dependentId,
                "is-loading": $data.isLoading,
                "show-failure": $options.noClinics
              }, null, 8, ["search-type", "dependent-id", "is-loading", "show-failure"])
            ])
          }
        : {
            name: "content-top",
            fn: _withCtx(() => [
              ($options.isDependentMode)
                ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                    key: 0,
                    dependent: $options.controller.dependent
                  }, null, 8, ["dependent"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, _toDisplayString(this.$mhat("BookingClinic.WhichClinicText")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.clinics, (clinic) => {
                  return (_openBlock(), _createBlock(_component_ClinicCard, {
                    key: clinic.id,
                    clinic: clinic,
                    clickable: "",
                    onClick: () => $options.setSelected(clinic)
                  }, null, 8, ["clinic", "onClick"]))
                }), 128))
              ])
            ])
          }
  ]), 1024))
}