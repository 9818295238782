import {AppointmentType, ScheduleSlotTransfer} from "@/open_api/generated";
import BookingServiceInterface from "@/lib/booking/service/BookngServiceInterface";
import Provider from "@/lib/clinic/providerProfile.model";
import ClinicService from "@/lib/clinics/Clinic.service";

export default class ProviderBookingService implements BookingServiceInterface
{
	protected _clinicService;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(clinicService: ClinicService)
	{
		this._clinicService = clinicService;
	}

	// ==========================================================================
	// BookingServiceInterface Implementation
	// ==========================================================================

	public async getAppointmentTypes(clinicId, providerId): Promise<AppointmentType[]>
	{
		return await this._clinicService.getAppointmentTypes(clinicId, providerId);
	}

	public async getProviders(clinicId): Promise<Provider[]>
	{
		return await this._clinicService.getClinicProviders(clinicId);
	}

	public async getAvailableAppointments(clinicId, providerId, appointmentTypeId, startDate, endDate): Promise<ScheduleSlotTransfer[]>
	{
		const response = await this._clinicService.clinicAvailableAppointments(clinicId, providerId, appointmentTypeId, startDate, endDate);

		return response.data.map(appointment => ({...appointment, provider_id: providerId}));
	}
}
