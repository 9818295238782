<template>
	<div class="subheader_with_colour">
		<div class="circle" :class="{colorClass}"></div>
		<h5 class="header" :class="{headerClass}">{{headerText}}</h5>
	</div>
</template>

<script>
	export default {
		name: "SubheaderWithColour",

		props: {
			headerText: {
				type: String,
				required: false,
				default: "",
			},
			colorClass: String,
			headerClass: String,
		},
	};
</script>

<style scoped lang="scss">
	.subheader_with_colour {
		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;

		padding-bottom: 16px;

		.circle {
			flex: 0 0 16px;
			border-radius: 50%;
			width: 16px;
			height: 16px;
			margin-right: 16px;
			background-color: var(--color-primary-base);
		}
	}
</style>
