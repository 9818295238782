import {RouteAuthType} from "@/router/types";
import {SettingsRoute} from "@/router/patient_user/settings.routes";
import SecurityHome from "@/views/patient_user/profile/SecurityHome.vue";
import SecurityChangeEmail from "@/views/patient_user/profile/SecurityChangeEmail.vue";
import SecurityChangePassword from "@/views/patient_user/profile/SecurityChangePassword.vue";
import PrivacyHome from "@/views/patient_user/profile/PrivacyHome.vue";
import RequiredProfileModal from "@/views/patient_user/cloud_md/components/RequiredProfileModal.vue";
import AccountIdCodePage from "@/views/patient_user/profile/AccountIdCodePage.vue";
import {RouteRecordRaw} from "vue-router";

const ProfileEdit = () => import(/* webpackChunkName: "Profile" */ "@/views/patient_user/profile/AccountHome.vue");

export enum OrgSettingsRoute
{
	Profile = "OrgSettingsProfile",
	RequiredEdit = "OrgSettingsProfileRequired",
	Security = "OrgSettingsSecurity",
	SecurityEmail = "OrgSettingsSecurityEmail",
	SecurityPassword = "OrgSettingsSecurityPassword",
	Privacy = "OrgSettingsPrivacy",
	AccountIdCode = "OrgAccountIdCode",
}

export const orgSettingsRoutes: RouteRecordRaw[] = [
	{
		path: "/org/:organizationId/account/profile",
		name: OrgSettingsRoute.Profile,
		component: ProfileEdit,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/profile/required:dependentId?",
		name: OrgSettingsRoute.RequiredEdit,
		component: RequiredProfileModal,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/account/security",
		name: OrgSettingsRoute.Security,
		component: SecurityHome,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/account/security/email",
		name: OrgSettingsRoute.SecurityEmail,
		component: SecurityChangeEmail,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/account/security/password",
		name: OrgSettingsRoute.SecurityPassword,
		component: SecurityChangePassword,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/account/privacy",
		name: OrgSettingsRoute.Privacy,
		component: PrivacyHome,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/account/id_code",
		name: OrgSettingsRoute.AccountIdCode,
		component: AccountIdCodePage,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
