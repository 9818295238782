import {BaseLoginService} from "@/lib/services/login/BaseLogin.service";
import {AuthInfo, KioskLoginResponse} from "@/open_api/generated";
import {KioskAPI} from "@/lib/services/Api";
import {KioskUser} from "@/lib/kiosk/kioskUser.model";

export class KioskLoginService extends BaseLoginService
{
	public async login(email: string, password: string): Promise<KioskUser>
	{
		const authInfo: AuthInfo = { email, password };

		const response = await KioskAPI().kioskLogin(authInfo);
		return this.setLoggedInInfo(response.data);
	}

	// eslint-disable-next-line camelcase
	public setLoggedInInfo({user, login_token}: KioskLoginResponse): KioskUser
	{
		const loggedInUser = KioskUser.fromDto(user, login_token);
		this.setLoggedInToken(loggedInUser.loginToken);
		return this.setLoggedInUser(loggedInUser) as KioskUser;
	}
}
