

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({components: {}})
	export default class ReviewCard extends Vue
	{
		@Prop({type: String}) reviewerName: string;
		@Prop({type: String}) reviewerLocation: string;
		@Prop({type: String}) reviewerMessage: string;

		@Prop({type: Boolean, default: false}) large: boolean;
	}
