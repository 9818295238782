

	import {Options, Vue} from "vue-property-decorator";
	import DeviceInfo from "@/lib/DeviceInfo";
	import AuthStore from "@/lib/vuex/auth.store";
	import {BookingRoute} from "@/router/patient_user/booking.routes";
	import {getResources} from "@/lib/utils/Resource";
	import AnalyticsService from "@/lib/anayltics/AnalyticsService";
	import OrgImage from "@/components/Organization/OrgImage.vue";
	import {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";
	import {IonButton} from "@ionic/vue";

	@Options({
		components: {OrgImage},
	})
	export default class HomePageLinks extends Vue
	{
		public ORG_ASSET_TYPE = ORG_ASSET_TYPE;
		public isLoading = false;
		protected analyticsService = new AnalyticsService();

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public routeToBooking(): void
		{
			this.isLoading = true;
			this.$mhaRouterPush(this.Route.Booking.Home);
		}

		public routeToImdHealth(): void
		{
			this.analyticsService.logPageView(this.imdHealthLink);
			window.open(this.imdHealthLink);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get loggedInUser()
		{
			return AuthStore.loggedInUser;
		}

		get isCloudMd()
		{
			return DeviceInfo.isCloudMd;
		}

		get imdHealthLink()
		{
			return getResources().links.imd_health_link;
		}
	}
