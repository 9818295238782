import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinnerSearch = _resolveComponent("EventStateSpinnerSearch")!

  return (_openBlock(), _createBlock(_component_EventStateSpinnerSearch, {
    noResultsTitle: $data.noResultsTitle,
    noResultsMessage: $data.noResultsMessage,
    isLoading: $props.isLoading,
    loadingMessage: $data.loadingMessage,
    loadingTitle: $data.loadingTitle
  }, null, 8, ["noResultsTitle", "noResultsMessage", "isLoading", "loadingMessage", "loadingTitle"]))
}