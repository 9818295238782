import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelectionCard = _resolveComponent("BaseSelectionCard")!

  return (_openBlock(), _createBlock(_component_BaseSelectionCard, _mergeProps({
    onClick: _ctx.handleClick,
    onDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete'))),
    class: ["secondary-card", {previous: _ctx.previous}],
    header: _ctx.appointment.clinicName,
    primary: _ctx.appointment.providerName,
    footer: _ctx.appointment.startDateTime.format('h:mm a'),
    "border-text": _ctx.borderDate,
    teleHealth: _ctx.appointment.isVirtual
  }, _ctx.$attrs), null, 16, ["onClick", "header", "primary", "footer", "class", "border-text", "teleHealth"]))
}