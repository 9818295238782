import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TelehealthHeader = _resolveComponent("TelehealthHeader")!

  return (_openBlock(), _createBlock(_component_TelehealthHeader, {
    onExit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('exit')))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}