

	import {Options, Vue, Prop} from "vue-property-decorator";

	import {NotificationSeverity, NotificationType, Notification} from "@/lib/types/Notifier";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import BannerNotificationItem from "../BannerNotificationItem.vue";
	import DeviceInfo from "@/lib/DeviceInfo";

	@Options({components: {BannerNotificationItem}})
	export default class Notifier extends Vue
	{
		@Prop({type: Object}) notification: Notification;

		NotificationType = NotificationType;
		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		created()
		{
			const notification: Notification = this.notification;

			if (notification.timeout)
			{
				setTimeout(() =>
				{
					this.$emit(NotificationType.Dismiss);
				}, notification.timeout);
			}
		}

		public dismiss(notificationType?: NotificationType)
		{
			if (!notificationType || (notificationType && this.notification.type === notificationType))
			{
				if (this.dismissCallback)
				{
					this.dismissCallback();
				}

				this.$emit(NotificationType.Dismiss);
			}
		}

		public confirm()
		{
			if (this.confirmCallback)
			{
				this.confirmCallback();
			}

			this.$emit(NotificationType.Confirm);
		}

		get isConfirm()
		{
			return this.notification.type === NotificationType.Confirm;
		}

		get isDismiss()
		{
			return this.notification.type === NotificationType.Dismiss;
		}

		get isSwipe()
		{
			return this.notification.type === NotificationType.Swipe;
		}

		get confirmText()
		{
			const {confirm} = this.notification;

			if (confirm)
			{
				return confirm.message;
			}

			return this.$mhat("Notifier.ConfirmButtonText");
		}

		get dismissText()
		{
			const {dismiss} = this.notification;

			if (dismiss)
			{
				return dismiss.message;
			}

			return this.$mhat("Notifier.DismissButtonText");
		}

		get confirmCallback()
		{
			if (this.notification.confirm)
			{
				return this.notification.confirm.callback;
			}
		}

		get dismissCallback()
		{
			if (this.notification.dismiss)
			{
				return this.notification.dismiss.callback;
			}
		}

		get isReminder()
		{
			return this.notification.severity === NotificationSeverity.Reminder;
		}

		get isWarning()
		{
			return this.notification.severity === NotificationSeverity.Warning;
		}

		get isCritical()
		{
			return this.notification.severity === NotificationSeverity.Critical;
		}

		get buttonColor()
		{
			switch (this.severity)
			{
			// for now all banner notifications look the same
			case NotificationSeverity.Critical:
			case NotificationSeverity.Warning:
			case NotificationSeverity.Reminder:
			default: return ButtonColor.PRIMARY;
			}
		}

		get severity(): NotificationSeverity
		{
			return this.notification.severity;
		}

		get bannerClasses()
		{
			return `${this.severity} ${this.notification.type}`;
		}

		get iconClass()
		{
			if (this.notification.icon)
			{
				return this.notification.icon;
			}
			else if (this.isCritical)
			{
				return "icon-stop";
			}
			else if (this.isWarning)
			{
				return "icon-exclamation";
			}

			return DeviceInfo.getAppFontIcon();
		}
	}
