import {Patient} from "@/lib/patient/Patient";
import AuthStore from "@/lib/vuex/auth.store";
import DependentStore from "@/lib/vuex/modules/Dependent.store";

export default class PatientProfileService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * get the profile of the given patient.
	 * @param patientId - the patient to get the profile for. Must be the current patient or a dependent of the current patient.
	 * @return promise that resolves to a patient profile.
	 */
	public getPatientProfile(patientId: string): Patient
	{
		if (patientId === AuthStore.loggedInPatient.id)
		{
			return AuthStore.loggedInPatient.profile;
		}
		else
		{
			return DependentStore.dependents.find((dependent) => dependent.id === patientId);
		}
	}
}
