import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-487cb46c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dependent-p" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DependentsHeaderBar = _resolveComponent("DependentsHeaderBar")!
  const _component_ButtonList = _resolveComponent("ButtonList")!
  const _component_DependentsEventStateSearch = _resolveComponent("DependentsEventStateSearch")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_DependentsHeaderBar)
    ]),
    "content-top": _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(this.$mhat("DependentsHome.DependentsInstructionText")), 1),
      (!_ctx.showEventSpinner)
        ? (_openBlock(), _createBlock(_component_ButtonList, {
            key: 0,
            list: _ctx.dependentList,
            "on-click": _ctx.onDependentSelect,
            "icon-class": "icon-chevron-right"
          }, null, 8, ["list", "on-click"]))
        : _createCommentVNode("", true)
    ]),
    "content-center": _withCtx(() => [
      (_ctx.showEventSpinner)
        ? (_openBlock(), _createBlock(_component_DependentsEventStateSearch, {
            key: 0,
            isLoading: _ctx.loadingQueue.isLoading
          }, null, 8, ["isLoading"]))
        : _createCommentVNode("", true)
    ]),
    "content-bottom": _withCtx(() => [
      (!_ctx.loadingQueue.isLoading)
        ? (_openBlock(), _createBlock(_component_RouteButton, {
            key: 0,
            primary: "",
            route: _ctx.Route.Dependents.AddDependent
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.$mhat("DependentsHome.AddADependentText")), 1)
            ]),
            _: 1
          }, 8, ["route"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}