<template>
	<PageWrapper>
		<template #content-center>
			<EventStateSpinner
							:isLoading="false"
							successIcon="icon-exclamation"
							successTitle="MyHealthAccess Requires Cookies"
							successMessage="please enable cookies in your browsers settings."
			/>
			<BaseButton primary @click="reload()">Reload</BaseButton>
		</template>
	</PageWrapper>
</template>

<script>
	import {Options, Vue} from "vue-property-decorator";

	@Options({})
	export default class CookiesNotEnabled extends Vue
	{
		reload()
		{ // we cannot use vue routing. The page must reload for browser cookie settings to apply.
			window.location.href = "/";
		}
	}
</script>

<style scoped>

</style>
