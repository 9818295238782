import {Connection, Dimensions, Event, Session, Stream} from "@opentok/client";

/**
 * OpenTok does not provide type definitions. This file contains manual type definitions
 */

export const OT_SIGNAL_PREFIX = "signal:";

export enum OT_EVENT_TYPE
{
	SESSION_CONNECTED = "sessionConnected",
	SESSION_DISCONNECTED = "sessionDisconnected",
	SESSION_RECONNECTING = "sessionReconnecting",
	STREAM_CREATED = "streamCreated",
	STREAM_DESTROYED = "streamDestroyed",
	SIGNAL = "signal",
	CONNECTION_DESTROYED = "connectionDestroyed",
	CONNECTION_CREATED = "connectionCreated",
}
export const OT_EVENT_TYPES: string[] = [
	OT_EVENT_TYPE.SESSION_CONNECTED, OT_EVENT_TYPE.SESSION_DISCONNECTED, OT_EVENT_TYPE.SESSION_RECONNECTING,
	OT_EVENT_TYPE.STREAM_CREATED, OT_EVENT_TYPE.STREAM_DESTROYED, OT_EVENT_TYPE.SIGNAL,
	OT_EVENT_TYPE.CONNECTION_DESTROYED, OT_EVENT_TYPE.CONNECTION_CREATED,
];

export enum OT_CUSTOM_SIGNAL {
	PEER_META_DATA = "PeerConnected", // string name cannot be changed for legacy compat.
	IGNORE_ID = "IgnoreId",
	CALL_INBOUND = "CallInbound",
	CALL_ACCEPT = "CallAccept",
	CALL_REJECT = "CallReject",
	CALL_END = "CallEnd",
	CALL_CANCEL = "CallCancel",
	CHAT_MESSAGE = "ChatMessage",
	CHAT_TYPING = "ChatTyping",
	KICK_PEER = "KickPeer",
	KICK_ACK = "KickAcknowledge",
}

export enum OT_SUBSCRIBER_STYLE {
	BACKGROUND_IMAGE = "backgroundImageURI",
	AUDIO_BLOCKED_DISPLAY_MODE = "audioBlockedDisplayMode",
	VIDEO_DISABLED_DISPLAY_MODE = "videoDisabledDisplayMode",
	AUDIO_LEVEL_DISPLAY_MODE = "audioLevelDisplayMode",
	BUTTON_DISPLAY_MODE = "buttonDisplayMode",
	NAME_DISPLAY_MODE = "nameDisplayMode",
}

export const enum OT_SIGNAL_ERROR_TYPE {
	NOT_CONNECTED = "OT_NOT_CONNECTED",
}

// internal signals (not broadcast to other clients)
export enum OT_LOCAL_SIGNAL {
	STATE_CHANGE = "localStateChange",
}

export enum OT_ERROR_CODE {
	OT_USER_MEDIA_ACCESS_DENIED = "OT_USER_MEDIA_ACCESS_DENIED",
	OT_HARDWARE_UNAVAILABLE = "OT_HARDWARE_UNAVAILABLE",
	OT_UNABLE_TO_CAPTURE_MEDIA = "OT_UNABLE_TO_CAPTURE_MEDIA",
	OT_NO_DEVICES_FOUND = "OT_NO_DEVICES_FOUND",
}

export enum OT_SESSION_DISCONNECT_REASON {
	KICKED = "forceDisconnected",
}

export type SignalEvent = Event<OT_EVENT_TYPE.SIGNAL, Session> & {
	type?: string;
	data?: string;
	from: Connection;
};

export type StreamEvent = Event<OT_EVENT_TYPE.SESSION_DISCONNECTED, Session> & {
	stream: Stream;
};

export type ConnectionEvent = Event<OT_EVENT_TYPE.SESSION_DISCONNECTED, Session> & {
	connection: Connection;
};

export type SessionDisconnectedEvent = Event<OT_EVENT_TYPE.SESSION_DISCONNECTED, Session> & {
	reason: string;
};

export enum VIDEO_RESOLUTION
{
	HIGH = "1280x720",
	MEDIUM = "640x480",
	LOW = "320x240",
}

// see https://tokbox.com/developer/sdks/js/reference/OT.html#initPublisher
export interface PublisherProperties
{
	audioBitrate?: number;
	audioFallbackEnabled?: boolean;
	audioSource?: string | MediaStreamTrack | boolean;
	videoSource?: string | MediaStreamTrack | boolean;
	disableAudioProcessing?: boolean;
	enableStereo?: boolean;
	facingMode?: "user" | "environment" | "left" | "right";
	fitMode?: "cover" | "contain";
	frameRate?: 30 | 15 | 7 | 1;
	height?: number | string;
	width?: number | string;
	insertDefaultUI?: boolean;
	insertMode?: "append" | "replace" | "before" | "after";
	maxResolution?: Dimensions;
	mirror?: boolean;
	name?: string;
	publishAudio?: boolean;
	publishVideo?: boolean;
	resolution?: VIDEO_RESOLUTION.HIGH | VIDEO_RESOLUTION.MEDIUM | VIDEO_RESOLUTION.LOW;
	showControls?: boolean;
	style?: any;
}

export interface SubscriberProperties
{
	audioVolume?: number;
	fitMode?: "cover" | "contain";
	height?: number | string;
	width?: number | string;
	insertDefaultUI?: boolean;
	preferredFrameRate?: 30 | 15 | 7 | 1;
	preferredResolution?: Dimensions;
	insertMode?: "append" | "replace" | "before" | "after";
	showControls?: boolean;
	style?: any;
	subscribeToAudio?: boolean;
	subscribeToVideo?: boolean;
	testNetwork?: boolean;
}
