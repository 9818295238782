import ProviderGroupBookingService from "@/lib/booking/service/ProviderGroupBookingService";
import ProviderBookingService from "@/lib/booking/service/ProviderBookingService";
import ClinicService from "@/lib/clinics/Clinic.service";

export default class BookingServiceFactory
{
	/**
	 * get a booking service based on whether the provided id is a provider group id or a provider id
	 * @param clinicId - the clinic id
	 * @param providerOrGroupId - the provider id or the provider group id
	 * @param clinicService - the clinic service
	 */
	public async getBookingService(clinicId: string, providerOrGroupId: string, clinicService: ClinicService)
	{
		try
		{
			if (providerOrGroupId)
			{
				const providerGroups = await clinicService.getClinicProviderGroups(clinicId);
				const providerGroupExists = providerGroups.some((providerGroup) => providerGroup.id === providerOrGroupId);

				if (providerGroupExists)
				{
					return new ProviderGroupBookingService(clinicService);
				}

				return new ProviderBookingService(clinicService);
			}
			else
			{
				return new ProviderBookingService(clinicService);
			}
		}
		catch (error)
		{
			return new ProviderBookingService(clinicService);
		}
	}
}
