import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerNotificationContainer = _resolveComponent("BannerNotificationContainer")!
  const _component_HomePageHeaderBar = _resolveComponent("HomePageHeaderBar")!
  const _component_HomePageLinks = _resolveComponent("HomePageLinks")!
  const _component_TabBar = _resolveComponent("TabBar")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, {
    contentHeaderPaddingDisabled: "",
    contentHeaderRemoveFalseHeaderSpace: _ctx.hideExtraHeaderSpace
  }, {
    "fixed-header": _withCtx(() => [
      _createVNode(_component_BannerNotificationContainer, { notifications: _ctx.notifications }, null, 8, ["notifications"])
    ]),
    "content-header": _withCtx(() => [
      _createVNode(_component_HomePageHeaderBar, {
        class: "display-desktop",
        "padding-horizontal": "",
        onLogout: _ctx.logout
      }, null, 8, ["onLogout"])
    ]),
    "content-center": _withCtx(() => [
      _createVNode(_component_HomePageLinks)
    ]),
    "fixed-footer": _withCtx(() => [
      _createVNode(_component_TabBar, {
        "custom-class": "home-mobile-footer",
        "tab-bar-items": _ctx.tabBarItems
      }, null, 8, ["tab-bar-items"])
    ]),
    _: 1
  }, 8, ["contentHeaderRemoveFalseHeaderSpace"]))
}