

	import {Options, Prop, Vue} from "vue-property-decorator";
	import {ChatItem, VideoControlState} from "@/lib/telehealth/models/telehealth.types";
	import TelehealthInterface, {AV_ERROR, TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
	import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
	import {createTimer, Timer} from "@/lib/utils/timer";
	import {ErrorCode, UserType} from "@/open_api/generated";
	import LogEvent from "@/lib/telehealth/models/LogEvent";
	import {NativeBridge} from "@/lib/NativeBridge";
	import DeviceInfo from "@/lib/DeviceInfo";
	import {VideoDisplayMode} from "@/lib/telehealth/models/VideoDisplayMode";
	import TelehealthError, {TELEHEALTH_ERROR_TYPE} from "@/lib/telehealth/error/TelehealthError";
	import OpenTokTelehealth from "@/lib/telehealth/opentok/OpenTokTelehealth";
	import {modalController} from "@ionic/core";
	import {SessionData} from "@/lib/telehealth/models/sessionData.model";
	import {TELEHEALTH_MODE} from "@/components/telehealth/TelehealthMode";
	import TelehealthApiServiceInterface from "@/lib/telehealth/api/TelehealthApiServiceInterface";
	import RegularTelehealthApiService from "@/lib/telehealth/api/RegularTelehealthApiService";
	import AqsTelehealthApiService from "@/lib/telehealth/api/AqsTelehealthApiService";
	import PublicTelehealthApiService from "@/lib/telehealth/api/PublicTelehealthApiService";
	import ChatMessageFactory from "@/lib/telehealth/factory/ChatMessageFactory";
	import AuthStore from "@/lib/vuex/auth.store";
	import ClinicUserAqsTelehealthApiService from "@/lib/telehealth/api/ClinicUserAqsTelehealthApiService";
	import ClinicUserRegularTelehealthApiService from "@/lib/telehealth/api/ClinicUserRegularTelehealthApiService";
	import ChatMessage from "@/lib/telehealth/models/ChatMessage";
	import ChatMessageToChatItemConverter from "@/lib/telehealth/conversion/ChatMessageToChatItemConverter";
	import {alertController} from "@ionic/vue";
	import {reactive} from "vue";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {playSound} from "@/lib/utils/util";
	import soundFile from "@/assets/audio/tadah_19.wav";
	import KioskTelehealthApiService from "@/lib/telehealth/api/KioskTelehealthApiService";
	import DocumentService from "@/lib/document/service/DocumentService";

	@Options({})
	export default class TelehealthBase extends Vue
	{
		// the mode of telehealth
		@Prop({type: String, default: TELEHEALTH_MODE.REGULAR}) telehealthMode: string;

		// used in regular mode
		@Prop({type: String}) public readonly appointmentId: string;

		// used in AQS mode
		@Prop({type: String}) queueId: string;
		@Prop({type: String}) queuedAppointmentId: string;
		@Prop({type: String}) clinicId: string;

		public chatItems: ChatItem[] = [];

		protected telehealthApiService: TelehealthApiServiceInterface = null;
		protected loading = true;
		protected readonly EXTRA_LOADING_DELAY = 1000; // 1 second

		protected telehealthEngine: TelehealthInterface = null;
		// initial state of the telehealth call as dictated by the query parameter 'state'.
		protected initialState: TELEHEALTH_STATE = TELEHEALTH_STATE.NOT_CONNECTED;

		protected chatTypingTimeout: number = null;
		protected isRemoteTyping = false;
		protected showChat = true;

		protected telehealthTimer: Timer;
		protected callTime = "00:00";
		protected callOver = false;

		protected sessionPingIntervalHandle: number;
		protected SESSION_PING_INTERVAL = 30000;

		protected showVideoUI = true;
		protected mouseMoveTimeoutHandle: number = null;
		protected MOVE_MOUSE_UI_HIDE_TIME = 10000;

		protected incomingCallAlert: HTMLIonAlertElement = null;

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async created(): Promise<void>
		{
			// build different API service depending on telehealth type.
			if (AuthStore.isClinic)
			{
				if (this.isRegularTelehealth)
				{
					this.telehealthApiService = new ClinicUserRegularTelehealthApiService(this.appointmentId);
				}
				else if (this.isAqsTelehealth)
				{
					this.telehealthApiService =
						new ClinicUserAqsTelehealthApiService(this.clinicId, this.queueId, this.queuedAppointmentId);
				}
				else if (this.isPublicTelehealth)
				{
					// This should never legitimately happen. Most uses of this will for testing.
					// When testing you join a public telehealth appointment while also logged in to the provider side.
					this.telehealthApiService = new PublicTelehealthApiService(
						this.appointmentId,
						this.$route.query.token as string);
				}
			}
			else if (AuthStore.isKiosk)
			{
				this.telehealthApiService = new KioskTelehealthApiService(this.appointmentId);
			}
			else
			{
				if (this.isRegularTelehealth)
				{
					this.telehealthApiService = new RegularTelehealthApiService(this.appointmentId);
				}
				else if (this.isAqsTelehealth)
				{
					this.telehealthApiService =
						new AqsTelehealthApiService(this.clinicId, this.queueId, this.queuedAppointmentId);
				}
				else if (this.isPublicTelehealth)
				{
					this.telehealthApiService = new PublicTelehealthApiService(
						this.appointmentId,
						this.$route.query.token as string);
				}
			}

			this.telehealthTimer = createTimer((time) => this.callTime = time, 1000);
			this.initialState =
				this.$route.query.state ? this.$route.query.state as TELEHEALTH_STATE : TELEHEALTH_STATE.OUT_OF_ROOM;

			// trigger the video UI hiding timeout (so that UI will hide even if mouse never enters the window).
			this.resetUIHideTimeout();

			await this.onCreated();
		}

		public async mounted(): Promise<void>
		{
			try
			{
				await this.onMounted();
			}
			catch (error)
			{
				if (error instanceof ErrorResponse && error.is(ErrorCode.Access))
				{
					console.warn(error.message);
					this.showAppointmentAccessAlert();
				}
				else
				{
					this.showConnectionFailedAlert();

					if (error instanceof TelehealthError && error.type === TELEHEALTH_ERROR_TYPE.ENGINE_DESTROYED_ERROR)
					{
						// Due to the async nature of telehealth initialization, on a slow connection a user
						// can leave telehealth (thus destroying the engine) before it finishes initialization causing this error.
						console.error("Attempted to initialize telehealth engine after destruction");
					}
					else
					{
						throw error;
					}
				}
			}

			if (this.telehealthEngine.isAVEnabled)
			{
				// if audio only default to not publishing video (more like audio off / on by default)
				if (this.audioOnlyCall)
				{
					this.telehealthEngine.disableVideo();
				}
				else
				{
					this.telehealthEngine.enableVideo();
				}
			}

			this.setupTelehealthCallbacks();
			await this.configureInitialState();
			await this.reloadChatFromHistory();
			this.sessionPingIntervalHandle = window.setInterval(() => this.pingSession(), this.SESSION_PING_INTERVAL);

			// Give async events in the telehealth engine a second to complete
			// It is completely safe to interact with the engine at this point, this
			// extra delay is simply to reduce visual flicker as the state changes.
			window.setTimeout(
				() => this.loading = false,
				this.EXTRA_LOADING_DELAY);
		}

		public async unmounted(): Promise<void>
		{
			if (this.inRoom())
			{
				await this.leaveRoom();
			}
			this.telehealthEngine.destroy();
			await this.onDestroyed();
			window.clearInterval(this.sessionPingIntervalHandle);
		}

		/**
		 * override this to hook in to the created life cycle event
		 */
		public async onCreated(): Promise<void>
		{
			this.telehealthEngine = reactive(new OpenTokTelehealth()) as OpenTokTelehealth;
			this.telehealthEngine.changeVideoDisplayMode(VideoDisplayMode.COVER);
			this.telehealthEngine.setClientInfo(await this.getlocalClientData());
		}

		/**
		 * override this to hook in to the mounted life cycle event
		 */
		public async onMounted(): Promise<void>
		{
			// initialize the telehealth engine
			await this.telehealthEngine.initialize(
				await this.fetchSessionData(),
				"#subscriber",
				"#publisher");

			if (this.isVoipCall)
			{
				await this.dismissOpenModals();
			}
		}

		/**
		 * override this to hook in to the destroyed life cycle event
		 */
		public async onDestroyed(): Promise<void>
		{

		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		/**
		 * enter the video conference "room"
		 */
		public async enterRoom(): Promise<void>
		{
			await this.telehealthEngine.enterRoom();

			this.callOver = false;
			this.setCallStateQueryParam(TELEHEALTH_STATE.IN_ROOM);
			this.telehealthTimer.stop();
			this.telehealthTimer.start();
		}

		/**
		 * leave the video conference "room"
		 */
		public async leaveRoom(): Promise<void>
		{
			this.callOver = true;
			this.telehealthTimer.pause();
			this.setCallStateQueryParam(TELEHEALTH_STATE.OUT_OF_ROOM);
			await this.telehealthEngine.leaveRoom();
		}

		/**
		 * Let remote clients know that you have "ended" the call.
		 * This does not force them to leave the call. It is only and indication
		 * that you are leaving and consider the call over.
		 */
		public async notifyRemotesOfCallEnd(): Promise<void>
		{
			await this.telehealthEngine.notifyRemotesOfCallEnd();
		}

		/**
		 * cycle threw the two supported video display modes
		 */
		public cycleVideoDisplayMode(): void
		{
			if (this.telehealthEngine.videoDisplayMode === VideoDisplayMode.COVER)
			{
				this.telehealthEngine.changeVideoDisplayMode(VideoDisplayMode.FIT);
			}
			else
			{
				this.telehealthEngine.changeVideoDisplayMode(VideoDisplayMode.COVER);
			}
		}

		/**
		 * cycle through available cameras
		 */
		public async cycleCamera(): Promise<void>
		{
			this.telehealthEngine.cycleCamera();
		}

		/**
		 * toggle video on and off
		 */
		public async toggleVideo(): Promise<void>
		{
			if (this.telehealthEngine.isVideoEnabled)
			{
				this.telehealthEngine.disableVideo();
			}
			else
			{
				this.telehealthEngine.enableVideo();
			}
		}

		/**
		 * toggle call audio
		 */
		public async toggleMute(): Promise<void>
		{
			this.telehealthEngine.muteMicrophone(!this.telehealthEngine.isMuted);
		}

		/**
		 * sends a text message to the remote clients
		 * @param message - the message to send
		 */
		public async sendTextMessage(message: string): Promise<void>
		{
			const chatMessage = (new ChatMessageFactory()).newMessage(message);

			// record message in DB
			const newChatMessage = await this.telehealthApiService.recordChatMessage(chatMessage);

			// send to remote
			await this.telehealthEngine.sendChatMessage(newChatMessage);
		}

		/**
		 * send a file message to the remote clients (in chat)
		 * @param file - the file to send
		 */
		public async sendDocumentMessage(file: File): Promise<void>
		{
			try
			{
				// upload document to MHA server
				const documentService = new DocumentService();
				const newDocument = await documentService.createDocument(file, (await this.fetchSessionData()).clinicId);
				const chatMessage = (new ChatMessageFactory()).newDocumentMessage(newDocument);

				// record message in DB
				const newChatMessage = await this.telehealthApiService.recordChatMessage(chatMessage);
				// send to remote
				await this.telehealthEngine.sendChatMessage(newChatMessage);
			}
			catch (err)
			{
				if (err instanceof ErrorResponse && err.is(ErrorCode.UserFriendly))
				{
					NotificationService.notificationDismiss(
						this.$mhat("TelehealthBase.FailedToSendDocument"),
						err.message,
						NotificationSeverity.Warning);
				}
				else
				{
					NotificationService.notificationDismiss(
						this.$mhat("TelehealthBase.FailedToSendDocument"),
						this.$mhat("Common.Error.Generic"),
						NotificationSeverity.Critical,
					);
				}
			}
		}

		/**
		 * signal to remote clients that this client is typing
		 */
		public async singleTyping(): Promise<void>
		{
			await this.telehealthEngine.sendTypingSignal();
		}

		/**
		 * toggle the visibility of the chat area
		 */
		public toggleChat(): void
		{
			this.showChat = !this.showChat;
		}

		public async getlocalClientData(): Promise<TelehealthClient>
		{
			return await this.telehealthApiService.getLocalClientData(this.$browser);
		}

		public async endCall(): Promise<void>
		{
			if (this.telehealthEngine.isConnected)
			{
				await this.leaveRoom();
				await this.notifyRemotesOfCallEnd();
			}
		}

		// ==========================================================================
		// Getters
		// Some getters in the getters section are not TS (type script) getters on purpose. vue-class-component converts
		// all TS getters to Vue computed properties. Vue caching of computed properties causes issues.
		// ==========================================================================

		/**
		 * true if the current telehealth mode is REGULAR
		 */
		get isRegularTelehealth(): boolean
		{
			return this.telehealthMode === TELEHEALTH_MODE.REGULAR;
		}

		/**
		 * true if current the telehealth mode is AQS
		 */
		get isAqsTelehealth(): boolean
		{
			return this.telehealthMode === TELEHEALTH_MODE.AQS;
		}

		/**
		 * true if the current telehealth mode is public
		 */
		get isPublicTelehealth(): boolean
		{
			return this.telehealthMode === TELEHEALTH_MODE.PUBLIC;
		}

		/**
		 * @return true / false indicating if there is at least one remote.
		 */
		public remoteConnected(): boolean
		{
			return this.telehealthEngine?.remoteClients.length > 0;
		}

		/**
		 * @return true / false indicating if there is a doctor in the session or not
		 */
		public doctorConnected(): boolean
		{
			return this.telehealthEngine?.remoteClients.filter((client) => client.userType === UserType.ClinicUser).length > 0;
		}

		public inRoom(): boolean
		{
			return this.telehealthEngine?.callState === TELEHEALTH_STATE.IN_ROOM;
		}

		public get chatVisible(): boolean
		{
			return this.showChat;
		}

		public get showTypingIndication(): boolean
		{
			return this.isRemoteTyping;
		}

		public get videoControlState(): VideoControlState
		{
			return {
				visible: this.shouldShowVideoControls,
				canEnd: this.inRoom(),
				videoEnabled: this.telehealthEngine.isVideoEnabled,
				muted: this.telehealthEngine.isMuted,
				callTime: this.callTime,
				disabled: false,
				handleMouseMove: () => this.resetUIHideTimeout(),
				handleMouseUp: () => this.resetUIHideTimeout(),
			};
		}

		// true if the video controls should be displayed
		get shouldShowVideoControls(): boolean
		{
			return this.showVideoUI && !this.isLoading;
		}

		// if true the user should not be prompted with a call join alert
		// and the call should be automatically joined
		get shouldAutoAcceptCall(): boolean
		{
			// When an Android device reaches the telehealth page via VOIP notification
			// the user has already indicated that they want to accept the call.
			return DeviceInfo.isAndroid() && this.isVoipCall;
		}

		/**
		 * true if this page was launched as part of a callkit action.
		 */
		get isVoipCall(): boolean
		{
			return this.$route.query.voip !== undefined && this.$route.query.voip === "true";
		}

		/**
		 * if true call is audio only. Video will be off by default (but user can toggle on if they want).
		 * Simply based on query param "audioOnly"
		 */
		get audioOnlyCall(): boolean
		{
			return this.$route.query.audioOnly !== undefined && this.$route.query.audioOnly === "true";
		}

		get isLoading(): boolean
		{
			return this.loading;
		}

		// ==========================================================================
		// Setters
		// ==========================================================================

		public set chatVisible(visible: boolean)
		{
			this.showChat = visible;
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		/**
		 * starts / resets the video UI hide timeout. When it fires
		 * the video controls will be come hidden.
		 */
		protected resetUIHideTimeout(): void
		{
			this.showVideoUI = true;
			if (this.mouseMoveTimeoutHandle)
			{
				window.clearTimeout(this.mouseMoveTimeoutHandle);
			}

			// hide video UI if the mouse doesn't move for some period of time.
			this.mouseMoveTimeoutHandle = window.setTimeout(() =>
			{
				this.showVideoUI = false;
			}, this.MOVE_MOUSE_UI_HIDE_TIME);
		}

		/**
		 * reload chat messages from history
		 */
		protected async reloadChatFromHistory(): Promise<void>
		{
			const chatHistory: ChatMessage[] = await this.telehealthApiService.getChatHistory();

			if (chatHistory)
			{
				chatHistory.forEach((chatMessage) =>
				{
					this.chatItems.push(new ChatMessageToChatItemConverter().convert(chatMessage));
				});
			}
		}

		/**
		 * This function is called every 30 seconds to ping the session. Provide your ping implementation as an override.
		 */
		protected async pingSession()
		{
			await this.telehealthApiService.pingSession(this.telehealthEngine.legacyCallState);
		}

		/**
		 * dismiss any open modal.
		 */
		protected async dismissOpenModals(): Promise<void>
		{
			const modal: HTMLIonModalElement = await modalController.getTop();
			if (modal)
			{
				await modalController.dismiss(modal);
			}
		}

		/**
		 * fetch session data, that is required to join the session.
		 * @return the session data
		 * @protected
		 */
		protected async fetchSessionData(): Promise<SessionData>
		{
			try
			{
				return await this.telehealthApiService.fetchSessionData();
			}
			catch (error)
			{
				NotificationService.notify(
					{
						event: NotifyEvent.Generic,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Critical,
						title: this.$mhat("TelehealthBase.ConnectionErrorTitle"),
						message: this.$mhat("TelehealthBase.ConnectionErrorMessage"),
					},
				);
				throw error;
			}
		}

		/**
		 * Configure the calls initial state. State can be set via query param "state".
		 * If no state is provided, TELEHEALTH_STATE.INITIAL_STATE is assumed.
		 */
		protected async configureInitialState(): Promise<void>
		{
			// if query parameter provided transition to state.
			if (this.$route.query.state)
			{
				switch (this.initialState)
				{
				case TELEHEALTH_STATE.IN_ROOM:
					await this.enterRoom();
					break;
				case TELEHEALTH_STATE.OUT_OF_ROOM:
					await this.leaveRoom();
					break;
				}
			}
			else if (this.shouldAutoAcceptCall)
			{
				await this.enterRoom();
			}
		}

		/**
		 * sets up the the default telehealth callbacks
		 */
		public setupTelehealthCallbacks(): void
		{
			if (this.telehealthEngine)
			{
				this.telehealthEngine.onInboundCall(this.onInboundCall, this);
				this.telehealthEngine.onChatMessage(this.addChatItem, this);
				this.telehealthEngine.onChatTyping(this.signalChatTyping, this);
				this.telehealthEngine.onAVDeviceError(this.onAVDeviceError, this);
				this.telehealthEngine.onCallEnd(this.onCallEnd, this);
				this.telehealthEngine.onInboundCallCancel(this.onCallCancel, this);
				this.telehealthEngine.onLogEvent(this.onLogEvent, this);
				this.telehealthEngine.onRemoteEnterRoom(this.playNotificationSound, this);
			}
			else
			{
				console.warn(
					"Telehealth engine not setup in, \"created\" or \"mounted\"" +
						" life cycle hooks. Default callbacks will not be registered");
			}
		}

		protected async onCallEnd(remoteId: string): Promise<boolean>
		{
			this.callOver = true;
			this.setCallStateQueryParam(TELEHEALTH_STATE.OUT_OF_ROOM);
			return true;
		}

		/**
		 * called when a remote client calls us.
		 */
		protected async onInboundCall(remoteId: string, callDisplay: string): Promise<boolean>
		{
			let accept = false;

			if (this.shouldAutoAcceptCall || !this.callOver)
			{
				accept = true;
			}
			else
			{
				await this.dismissCurrentAlert();
				this.incomingCallAlert = await alertController.create({
					header: this.$mhat("TelehealthBase.IncomingCallAlertHeader"),
					message: this.$mhat("TelehealthBase.IncomingCallAlertMessage", {callDisplayName: callDisplay}),
					backdropDismiss: false,
					buttons: [
						{
							text: this.$mhat("TelehealthBase.DeclineButtonText"),
							handler: () =>
							{
								accept = false;
							},
						},
						{
							text: this.$mhat("TelehealthBase.AcceptButtonText"),
							handler: () =>
							{
								accept = true;
							},
						}],
				});
				await this.incomingCallAlert.present();
				await this.incomingCallAlert.onDidDismiss();
			}

			// tell phone to stop ringing if applicable
			await NativeBridge.stopRinging();

			if (accept)
			{
				this.setCallStateQueryParam(TELEHEALTH_STATE.IN_ROOM);
				this.telehealthTimer.stop();
				this.telehealthTimer.start();
			}

			return accept;
		}

		protected async dismissCurrentAlert(): Promise<void>
		{
			if (this.incomingCallAlert)
			{
				await this.incomingCallAlert.dismiss();
			}
		}

		protected async onCallCancel(remoteId: string): Promise<void>
		{
			if (this.incomingCallAlert)
			{
				await this.incomingCallAlert.dismiss();
			}
		}

		protected async onLogEvent(logEvent: LogEvent): Promise<void>
		{
			await this.telehealthApiService.logEvent(logEvent);
		}

		protected setCallStateQueryParam(callState: TELEHEALTH_STATE): void
		{
			this.$router.replace({
				query: {
					state: callState,
					voip: this.isVoipCall.toString(),
					audioOnly: this.audioOnlyCall.toString(),
				},
			});
		}

		protected clearCallStateQueryParam(): void
		{
			this.$router.replace({});
		}

		/**
		 * called when a new chat message is received
		 * @param remoteId
		 * @param chatMessage
		 * @protected
		 */
		protected addChatItem(remoteId: string, chatMessage: ChatMessage): void
		{
			this.chatItems.push(new ChatMessageToChatItemConverter().convert(chatMessage));
		}

		/**
		 * called when a remote client is typing
		 * @param remoteId - the remote id of the typing client
		 * @protected
		 */
		protected signalChatTyping(remoteId: string): void
		{
			this.isRemoteTyping = true;

			// clear existing timeouts
			if (this.chatTypingTimeout)
			{
				window.clearTimeout(this.chatTypingTimeout);
			}

			// remove typing indication after a second
			this.chatTypingTimeout = window.setTimeout(() =>
			{
				this.isRemoteTyping = false;
			}, 1000);
		}

		protected onAVDeviceError(error: AV_ERROR): void
		{
			if (error === AV_ERROR.POOR_CONNECTION)
			{
				NotificationService.notify(
					{
						event: NotifyEvent.Generic,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Warning,
						timeout: 10000,
						title: this.$mhat("TelehealthBase.PoorConnectionAlertTitle"),
						message: this.$mhat("TelehealthBase.PoorConnectionAlertMessage"),
					});
			}
			else
			{
				let message = this.$mhat("TelehealthBase.MicAndCameraErrorMessage");
				switch (error)
				{
				case AV_ERROR.PERMISSION_DENIED:
					message = this.$mhat("TelehealthBase.AVPermissionDeniedErrorMessage");
					break;
				case AV_ERROR.NO_DEVICES:
					message = this.$mhat("TelehealthBase.AVNoDevicesErrorMessage");
					break;
				case AV_ERROR.HARDWARE_FAULT:
					message = this.$mhat("TelehealthBase.AVHardwareFaultErrorMessage");
					break;
				}

				NotificationService.notify(
					{
						event: NotifyEvent.Generic,
						type: NotificationType.Confirm,
						severity: NotificationSeverity.Critical,
						title: this.$mhat("TelehealthBase.GenericAVErrorTitle"),
						message,
						confirm: {
							message: this.$mhat("TelehealthBase.ReloadButtonText"),
							callback: () =>
							{
								window.location.reload();
							},
						},
					},
				);
			}
		}

		/**
		 * show an alert to the user informing them that they don't have access to this appointment
		 */
		protected showAppointmentAccessAlert(): void
		{
			NotificationService.notify(
				{
					event: NotifyEvent.Generic,
					type: NotificationType.Confirm,
					severity: NotificationSeverity.Critical,
					title: this.$mhat("TelehealthBase.AppointmentAccessErrorTitle"),
					message: this.$mhat("TelehealthBase.AppointmentAccessErrorMessage", {loggedInUserEmail: this.AuthStore.loggedInUser.email}),
					confirm: {
						message: this.$mhat("TelehealthBase.LogoutButtonText"),
						callback: () =>
						{
							this.AuthStore.logout();
						},
					},
				},
			);
		}

		/**
		 * display connection failed alert to user.
		 */
		protected showConnectionFailedAlert(): void
		{
			NotificationService.notify(
				{
					event: NotifyEvent.Generic,
					type: NotificationType.Confirm,
					severity: NotificationSeverity.Critical,
					title: this.$mhat("TelehealthBase.ConnectionErrorTitle"),
					message: this.$mhat("TelehealthBase.ConnectionFailedErrorMessage"),
					confirm: {
						message: this.$mhat("TelehealthBase.ReloadButtonText"),
						callback: () =>
						{
							window.location.reload();
						},
					},
				},
			);
		}

		protected playNotificationSound(): void
		{
			playSound(soundFile.toString());
		}
	}
