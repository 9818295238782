import {Moment} from "moment";

const CALENDAR_GRID_SQUARES = 64;

function getDatesForMonth(currentMonth: Moment): Array<Moment | null>
{
	const daysInMonth = currentMonth.daysInMonth();
	const firstDay = currentMonth.date(1);

	const monthWeeks = [];

	let dateIter = 1;

	for (let i = 0; i < CALENDAR_GRID_SQUARES; i++)
	{
		if (i < firstDay.weekday() || dateIter > daysInMonth)
		{
			monthWeeks.push(null);
		}
		else
		{
			monthWeeks.push(firstDay.clone().date(dateIter));
			dateIter++;
		}
	}

	return monthWeeks;
}

export default { getDatesForMonth };
