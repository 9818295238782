

	import {Options, Vue} from "vue-property-decorator";
	import PasswordField from "@/components/Inputs/PasswordField.vue";
	import SecurityHeaderBar from "./components/SecurityHeaderBar.vue";
	import {UserProfileController} from "./controllers/UserProfileController";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import AuthStore from "@/lib/vuex/auth.store";

	@Options({components: {PasswordField, SecurityHeaderBar}})
	export default class SecurityChangeEmail extends Vue
	{
		public controller: UserProfileController = new UserProfileController();
		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		public updatePassword(element)
		{
			this.controller.passwords[element.id] = element.value;
		}

		public updateProfile(element)
		{
			this.controller.userProfile[element.id] = element.value;
		}

		public onSave()
		{
			this.controller.updateEmail().then(
				async (response) =>
				{
					this.$router.back();
				},
				(errors) =>
				{
					// TODO
				},
			);
		}

		public onCancel()
		{
			this.$mhaRouterPush(this.Route.Settings.Security);
		}
	}
