

	import {Options, Vue} from "vue-property-decorator";

	import {Validator} from "@/lib/utils/Validator";
	import HeaderBarForgot from "./components/ForgotHeaderBar.vue";
	import {AccountAPI} from "@/lib/services/Api";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import {getResources} from "@/lib/utils/Resource";
	import LoadingQueue from "../../../lib/LoadingQueue";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode, OrganizationProfileDto} from "@/open_api/generated";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import defaultBackground from "@/assets/images/graphics/common/background.jpg";
	import {RouteType} from "@/router/types";
	import {RouteLocationRaw} from "vue-router";
	import WhitelabelHeaderBar from "@/views/public/whitelabel/components/WhitelabelHeaderBar.vue";
	import Services from "@/lib/services/Services";

	@Options({components: {CorporateFooter, HeaderBarForgot, WhitelabelHeaderBar}})
	export default class LoginReset extends Vue
	{
		public reset = false;
		public loadingQueue: LoadingQueue = new LoadingQueue();
		public isError = false;
		public token: any = null;
		public email = "";
		public password = "";
		public confirmPassword = "";
		public backgroundImage = defaultBackground;

		public tokenExpired = false;
		public organization: OrganizationProfileDto = null;

		private HOME_ROUTING_DELAY = 5000; // 5 seconds

		mounted()
		{
			this.token = this.$router.currentRoute.value.query.token;
			this.organization = OrganizationStore.organizationProfile;

			if (!this.token)
			{
				this.$mhaRouterPush(this.Route.Login.Home);
			}
		}

		public inputPassword(event)
		{
			this.password = event.value;
		}

		public inputConfirmPassword(event)
		{
			this.confirmPassword = event.value;
		}

		public resetPassword()
		{
			this.reset = true;
			this.loadingQueue.pushLoadingState();

			const payload = {
				reset_password_token: this.token,
				password: this.password,
				password_confirmation: this.confirmPassword,
			};

			AccountAPI().resetPassword({reset: payload}).then(
				(result) =>
				{
					Services.PatientLogin.setLoggedInInfo(result.data);
					window.setTimeout(() => this.$mhaRouterPush(this.routeHome), this.HOME_ROUTING_DELAY);
				},
			).catch(
				(error: ErrorResponse) =>
				{
					this.isError = true;
					if (error.is(ErrorCode.InvalidToken))
					{
						this.tokenExpired = true;
					}
				},
			).finally(() =>
			{
				this.loadingQueue.popLoadingState();
			});
		}

		get validPassword()
		{
			return Validator.password(this.password);
		}

		get validConfirmPassword()
		{
			return Validator.matches(this.password, this.confirmPassword, Validator.password);
		}

		get canSubmit()
		{
			return this.validPassword &&
				this.validConfirmPassword &&
				Validator.isPresent(this.token);
		}

		get resetSuccessMessage()
		{
			return this.$mhat("LoginReset.PasswordResetSuccessMessage", {appName: getResources().app_name});
		}

		get resetErrorMessage()
		{
			let message = this.$mhat("LoginReset.GenericPasswordResetFailureMessage");
			if (this.tokenExpired)
			{
				message = this.$mhat("LoginReset.TokenExpiredFailureMessage");
			}
			return message;
		}

		get isWhiteLabelMode()
		{
			return Boolean(this.organization);
		}

		get routeHome(): RouteLocationRaw
		{
			if (this.isWhiteLabelMode)
			{
				return {
					name: this.Route.Public.Organization.Home,
					params: {organizationId: this.organization.url_friendly_name},
				};
			}
			return {name: this.Route.Home as RouteType};
		}

		get routeForgot(): RouteLocationRaw
		{
			if (this.isWhiteLabelMode)
			{
				return {
					name: this.Route.Public.Organization.Forgot,
					params: {organizationId: this.organization.url_friendly_name},
				};
			}
			return {name: this.Route.Login.Forgot};
		}
	}
