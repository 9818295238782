
	import {Options, Prop, Vue} from "vue-property-decorator";
	import OrganizationService from "@/lib/organization/service/OrganizationService";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {BookingRoute} from "@/router/patient_user/booking.routes";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import PatientAppointmentService from "@/lib/appointment/service/PatientAppointmentService";

	@Options({components: {}})
	export default class OrgRescheduleBookingRedirect extends Vue
	{
		@Prop({type: String}) rescheduleRemoteAppointmentId: string;

		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		public async created(): Promise<void>
		{
			try
			{
				// ensure the patient is connected to primary clinic
				await (new OrganizationService()).connectToCurrentOrganizationPrimaryClinic();

				const patientAppointmentService = new PatientAppointmentService();
				const appointment = await patientAppointmentService.getRemoteAppointment(
					OrganizationStore.organizationProfile.primary_clinic_id,
					this.rescheduleRemoteAppointmentId);

				this.$mhaRouterReplace(
					{
						name: BookingRoute.AppointmentType,
						params: {
							organizationId: OrganizationStore.organizationProfile.url_friendly_name,
							clinicId: OrganizationStore.organizationProfile.primary_clinic_id,
							providerOrGroupId: appointment.providerId,
						},
						query: {
							hasClose: "false",
							rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId,
						},
					});
			}
			catch (err)
			{
				// ignore profile incomplete error
				if (!(err instanceof ErrorResponse) || !err.is(ErrorCode.IncompleteProfile))
				{
					throw err;
				}
			}
		}
	}
