import { RouteAuthType } from "@/router/types";
import PublicTelehealth from "@/views/public/PublicTelehealth.vue";
import DeviceInfo from "@/lib/DeviceInfo";
import PublicTelehealthIos from "@/views/public/PublicTelehealthIos.vue";
import AppointmentConfirmation from "@/views/patient_user/appointment/AppointmentConfirmation.vue";
import {TELEHEALTH_MODE} from "@/components/telehealth/TelehealthMode";
import {RouteRecordRaw} from "vue-router";

export enum PublicAppointmentRoutes
{
	OneTimeTelehealth = "oneTimeTelehealth",
	Confirm = "AppointmentConfirm",
}

export const publicAppointmentRoutes: RouteRecordRaw[] = [
	{
		path: "/public/appointments/:appointmentId/telehealth/session",
		name: PublicAppointmentRoutes.OneTimeTelehealth,
		component: DeviceInfo.isIOS() ? PublicTelehealthIos : PublicTelehealth,
		props: (route) =>
		{
			return Object.assign({telehealthMode: TELEHEALTH_MODE.PUBLIC}, route.params);
		},
	},

	{
		path: "/appointments/:clinicId/:appointmentNo/confirm",
		name: PublicAppointmentRoutes.Confirm,
		props: true,
		component: AppointmentConfirmation,
		meta: {
			brandedRoute: true,
		},
	},
];
