
	import desktopBackground from "@/assets/images/graphics/telehealth/Tele-Background-D.png";
	import mobileBackground from "@/assets/images/graphics/telehealth/Tele-Background-M.png";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import { Options, Prop, Vue } from "vue-property-decorator";
	import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";
	import {Route} from "@/router/router";

	@Options({})
	export default class WaitingRoomOverlay extends Vue
	{
		@Prop({type: Object})
		public failure: any;

		@Prop({type: Boolean})
		public mobile: boolean;

		@Prop({type: Boolean, default: false})
		public showFailureState: boolean;

		@Prop({type: Boolean, default: true})
		public isLoading: boolean;

		// [optional] AQS properties. defining properties in inheriting component doesn't seem to work.
		@Prop({type: String, required: false}) public clinicId: string;
		@Prop({type: String, required: false}) public queuedAppointmentId: string;
		@Prop({type: String, required: false}) public queueId: string;

		public preLoading = true;
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;

		mounted()
		{
			setTimeout(() =>
			{
				this.preLoading = false;
			}, 40);
		}

		/**
		 * Go back to the home page
		 */
		public onBackToHome(): void
		{
			const crossFrameService = new CrossFrameCommunicationService();
			if (crossFrameService.hasParentFrame)
			{
				// let any parent frames know we want to go back to the home page
				crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateToHome});
			}
			this.$mhaRouterPush(Route.Home);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		public get failureOrDefault()
		{
			const failureDefault = {
				title: this.$mhat("WaitingRoomOverlay.ConnectionErrorTitle"),
				message: this.$mhat("WaitingRoomOverlay.ConnectionErrorMessage"),
			};

			return this.failure ? this.failure : failureDefault;
		}

		public get showFailure(): boolean
		{
			return this.showFailureState || Boolean(this.failure);
		}

		public get getBackgroundImage()
		{
			let background = desktopBackground;
			if (this.mobile)
			{
				background = mobileBackground;
			}
			return {
				backgroundImage: `url(${background})`,
			};
		}

		public get getIsLoading()
		{
			if (this.preLoading)
			{
				return false;
			}

			return this.isLoading;
		}

		public get getLoadingMessages()
		{
			return [
				this.$mhat("WaitingRoomOverlay.LoadingMessagePart1"),
				this.$mhat("WaitingRoomOverlay.LoadingMessagePart2"),
			];
		}

		get splashScreenMode(): boolean
		{
			return OrganizationConfigurationStore.telehealthShowSplashScreenOnCallEnd;
		}
	}
