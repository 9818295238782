
	import {ICON_BUTTON_ADD} from "@/assets/AppIcons";

	export default {
		name: "ClinicsActionBar",

		data()
		{
			return { ICON_ADD: ICON_BUTTON_ADD };
		},
	};
