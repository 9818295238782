

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {MediaDeviceStatus} from "@/lib/mediaDevices";
	import IconTooltip from "@/components/IconTooltip.vue";

	@Options({
		components: {IconTooltip},
	})
	export default class MicrophoneStatusIcon extends Vue
	{
		@Prop({type: String, default: MediaDeviceStatus.UNKNOWN}) public microphoneStatus;

		// ==========================================================================
		// Getters
		// ==========================================================================

		get micIconClass(): string
		{
			if (this.hasMicAccess)
			{
				return "icon-tele-volume-mic";
			}
			else
			{
				return "icon-tele-volume-mic-mute";
			}
		}

		get micStatusClass(): string[]
		{
			const classes = ["mha-font-body-xl"];

			if (this.microphoneStatus)
			{
				classes.push(this.hasMicAccess ? "font-color-line-base" : "font-color-red-base");
			}

			return classes;
		}

		get micTooltip(): string
		{
			if (this.microphoneStatus)
			{
				return this.hasMicAccess ? "Microphone detected" : "No authorized microphones found";
			}
		}

		get hasMicAccess(): boolean
		{
			return this.microphoneStatus === MediaDeviceStatus.GRANTED;
		}
	}
