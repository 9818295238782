import {BaseUser} from "@/lib/baseUser.model";
import {PatientUserDto} from "@/open_api/generated";
import {Patient} from "@/lib/patient/Patient";
import {LoginToken} from "@/lib/models/LoginToken";
import {Route} from "@/router/router";

export class PatientUser extends BaseUser
{
	public route = {
		Login: Route.Login.Home,
		Home: Route.Home,
	};

	private _profile: Patient;

	public static fromDto(dto: PatientUserDto, jwt: string): PatientUser
	{
		const patientUser = new PatientUser();

		patientUser.loginToken = new LoginToken(jwt);
		patientUser.profile = Patient.fromDto(dto);

		return patientUser;
	}

	get id(): string
	{
		return this.profile.id;
	}

	get email(): string
	{
		return this.profile.email;
	}

	get firstName(): string
	{
		return this.profile.firstName;
	}

	get lastName(): string
	{
		return this.profile.lastName;
	}

	get profile(): Patient
	{
		return this._profile;
	}

	set profile(value: Patient)
	{
		this._profile = value;
	}
}
