export function noNull(value: any)
{
	if (value === null || value === undefined)
	{
		return "";
	}

	return value;
}

export function isDefined(value: any)
{
	return typeof value !== "undefined";
}

export function exists(object: any)
{
	return isDefined(object) && object !== null;
}

export function isBlank(object: any)
{
	return !exists(object) || object === "";
}

export function concatIfExists(delimiter, ...values)
{
	return values.filter(Boolean).join(delimiter);
}
