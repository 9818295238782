import {MailRoute} from "@/router/patient_user/inbox.routes";
import {getRouteOrWhitelabelRoute} from "@/router/whitelableUtil";

export default class InboxRouteCollection
{
	// ==========================================================================
	// Singleton impl
	// ==========================================================================

	private static instance: InboxRouteCollection;

	public static getInstance(): InboxRouteCollection
	{
		if (!this.instance)
		{
			this.instance = new InboxRouteCollection();
		}
		return this.instance;
	}

	private constructor()
	{
	}

	// ==========================================================================
	// Routes
	// ==========================================================================

	get Home(): string
	{
		return getRouteOrWhitelabelRoute(MailRoute.Home);
	}
}

export const inboxRouteCollection = InboxRouteCollection.getInstance();
