import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e943d70e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "org-image" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.assetUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "branded-logo",
          src: _ctx.assetUrl,
          alt: _ctx.atlText,
          style: _normalizeStyle(_ctx.imageStyle)
        }, null, 12, _hoisted_2))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "branded-logo",
          src: _ctx.altImage,
          alt: _ctx.atlText,
          style: _normalizeStyle(_ctx.imageStyle)
        }, null, 12, _hoisted_3))
  ]))
}