

	import {Options, Vue, Prop} from "vue-property-decorator";
	import appStoreImage from "@/assets/images/external/app-store.png";
	import appStoreBlueImage from "@/assets/images/external/app-store-blue.png";
	import playStoreImage from "@/assets/images/external/google-play.png";
	import playStoreBlueImage from "@/assets/images/external/google-play-blue.png";

	@Options({})
	export default class AppBadges extends Vue
	{
		@Prop({type: Boolean, default: false}) blueBadges;

		public playStoreImage = playStoreImage;
		public appStoreImage = appStoreImage;

		public mounted()
		{
			if (this.blueBadges)
			{
				this.playStoreImage = playStoreBlueImage;
				this.appStoreImage = appStoreBlueImage;
			}
		}

		get appStoreLink()
		{
			if (this.$isCloudMd)
			{
				return "https://apps.apple.com/ca/app/cloudmd/id1494213164";
			}
			return "https://apps.apple.com/ca/app/myhealthaccess/id1475689291";
		}

		get googlePlayLink()
		{
			if (this.$isCloudMd)
			{
				return "https://play.google.com/store/apps/details?id=ca.cloudpractice.cloudmd";
			}
			return "https://play.google.com/store/apps/details?id=ca.cloudpractice.myhealthaccess";
		}
	}

