import {Module, VuexModule, Mutation, Action, getModule} from "vuex-module-decorators";

import store from "../store";
import BookingController from "@/views/patient_user/booking/BookingController";

@Module({dynamic: true, store, name: "bookingStore", namespaced: true})
class BookingControllerStore extends VuexModule
{
	private _bookingController: BookingController = null;

	@Mutation
	public setBookingController(value: BookingController)
	{
		this._bookingController = value;
	}

	get bookingController(): BookingController
	{
		return this._bookingController;
	}
}

export default getModule(BookingControllerStore);
