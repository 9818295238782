

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import SignupEULAModal from "../SignupEULAModal.vue";
	import SignupPolicyModal from "@/views/patient_user/login/SignupPolicyModal.vue";
	import ProfileInputFields from "@/views/patient_user/profile/components/ProfileInputFields.vue";
	import {ProfileController} from "@/views/patient_user/profile/controllers/ProfileController";
	import {PatientProfileField} from "@/lib/types/Profile";
	import ClinicLogo from "@/views/patient_user/clinic/components/ClinicLogo.vue";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import PasswordController from "@/views/patient_user/login/controllers/PasswordController";
	import {Route} from "@/router/router";
	import SignupService from "@/lib/Signup/Signup.service";
	import {AxiosResponse} from "axios";
	import {ErrorCode, PatientAccountStatus} from "@/open_api/generated";
	import FBService from "@/lib/FB/FBService";
	import GoogleAnalyticService from "@/lib/Google/GoogleAnalyticService";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import NotificationService from "@/components/Notification/NotificationService";
	import LoadingQueue from "@/lib/LoadingQueue";
	import {RouteLocationRaw} from "vue-router";
	import {SIGNUP_STATUS} from "@/views/patient_user/login/components/types";
	import SignupSpinner from "@/views/patient_user/login/components/SignupSpinner.vue";
	import {EventStateSpinnerModalController} from "@/components/EventState/EventStateSpinnerModalController";
	import {getResources} from "@/lib/utils/Resource";
	import {BaseButtonOpts, ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {EventStateSpinnerModalProps} from "@/components/EventState/types";
	import WhitelabelPrimaryLogo from "@/views/public/whitelabel/components/WhitelabelPrimaryLogo.vue";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {modalController} from "@ionic/vue";
	import {Patient} from "@/lib/patient/Patient";
	import moment from "moment";

	@Options({components: {WhitelabelPrimaryLogo, SignupSpinner, ClinicLogo, ProfileInputFields, SignupEULAModal}})
	export default class SignupForm extends Vue
	{
		@Prop() parentCtx: Vue;
		@Prop({type: Object}) clinic: ClinicProfile;
		@Prop({type: Object}) profileController: ProfileController;
		@Prop({type: Object}) passwordController: PasswordController;
		@Prop({type: String}) titleText: string;
		@Prop({type: Boolean, default: false}) darkStyle: boolean;
		@Prop({type: Object}) forgotPasswordRoute: RouteLocationRaw;
		@Prop({type: Boolean, default: false}) useInlineSpinner: boolean;
		@Prop({type: Array}) successButtonProps: BaseButtonOpts[];
		@Prop({type: Boolean, default: false}) useOrgLogo: boolean;
		@Prop({type: Array}) successMessages: string[];
		@Prop({type: Boolean, default: false}) subscribeCloudmdMarketing: boolean;

		protected status: SIGNUP_STATUS = SIGNUP_STATUS.NOT_SUBMITTED;
		protected policyAccepted = false;
		protected emailTaken = false;
		protected modal = null;
		protected loadingModal = null;
		protected loadingQueue: LoadingQueue = new LoadingQueue();

		beforeUnmount()
		{
			this.clearEmailTakenState();
		}

		public onProfileChange(input)
		{
			if (input.id === PatientProfileField.EMAIL)
			{
				this.clearEmailTakenState();
			}
		}

		public agreeToTerms()
		{
			this.policyAccepted = !this.policyAccepted;
		}

		public termsClick(event)
		{
			event.preventDefault();
			this.showModal(SignupEULAModal);
		}

		public policyClick(event)
		{
			event.preventDefault();
			this.showModal(SignupPolicyModal);
		}

		public clearEmailTakenState()
		{
			WebNotification.$clear(NotifyEvent.EmailTaken);
			this.emailTaken = false;
		}

		public async submitSignupRequest()
		{
			this.loadingQueue.pushLoadingState();
			this.status = SIGNUP_STATUS.PENDING;
			this.$emit("submit", this.status);
			await this.showLoadingModal();

			this.setUserProfileDefaults(this.profileController.userProfile);
			const createAccountInfo = this.profileController.userProfile.toCreateAccountTransferObj(
				this.passwordController.password,
				this.passwordController.confirmPassword);

			SignupService.signup(createAccountInfo, this.clinic?.id, this.subscribeCloudmdMarketing).then(
				(response: AxiosResponse<PatientAccountStatus>) =>
				{
					if (response.data.active)
					{
						if (this.isBrandedMode)
						{
							this.status = (response.data.clinic_connected) ? SIGNUP_STATUS.SUCCESS : SIGNUP_STATUS.SUCCESS_NO_AUTOCONNECT;
						}
						else
						{
							this.status = SIGNUP_STATUS.SUCCESS;
						}

						FBService.logRegistrationComplete();
						GoogleAnalyticService.logGoogleRegistrationComplete();

						this.$emit("success", this.status, response.data);
					}
					else
					{
						this.status = SIGNUP_STATUS.ERROR;
						this.$emit("error", this.status);
					}
				}).catch((error: ErrorResponse) =>
			{
				this.status = SIGNUP_STATUS.NOT_SUBMITTED;

				if (error.is(ErrorCode.EligibilityCheckFailed))
				{
					this.dismissLoadingModal();
					NotificationService.notify({
						event: NotifyEvent.Generic,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Reminder,
						title: this.$mhat("SignupForm.IneligibleHealthCardErrorTitle"),
						message: `${error.message}`,
					});
				}
				else if (error.is(ErrorCode.InvalidEmail))
				{
					this.emailTaken = true;
					this.dismissLoadingModal();

					WebNotification.$emit({
						event: NotifyEvent.EmailTaken,
						type: NotificationType.Confirm,
						severity: NotificationSeverity.Reminder,
						title: this.$mhat("SignupForm.AccountExistsErrorTitle"),
						message: this.$mhat("SignupForm.AccountExistsErrorMessage"),
						confirm: {
							message: this.$mhat("SignupForm.ForgotPasswordButtonText"),
							callback: this.onForgotPassword,
						},
					});
				}
				else if (error.is(ErrorCode.Validation))
				{
					this.dismissLoadingModal();
					NotificationService.notify({
						event: NotifyEvent.Generic,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Reminder,
						title: this.$mhat("SignupForm.ValidationErrorMessageTitle"),
						message: `${error.message}`,
					});
				}
				else
				{
					this.status = SIGNUP_STATUS.ERROR;
					this.$emit("error", this.status, error);
				}
			}).finally(() =>
			{
				this.passwordController.clear();
				this.loadingQueue.popLoadingState();
			},
			);
		}

		public resetForm()
		{
			this.status = SIGNUP_STATUS.NOT_SUBMITTED;
			this.passwordController.clear();
			this.clearEmailTakenState();
			this.$emit("reset", this.status);
		}

		public dismissModal()
		{
			if (this.modal)
			{
				this.modal.dismiss();
			}
		}

		public dismissLoadingModal()
		{
			if (this.loadingModal)
			{
				this.loadingModal.dismiss();
			}
		}

		public async showModal(component)
		{
			await this.dismissModal();
			const context = (this.parentCtx) ? this.parentCtx : this;

			this.modal = await modalController
				.create({
					component,
					showBackdrop: true,
					cssClass: "modal-size-large",
				});
			await this.modal.present();
		}

		public async showLoadingModal()
		{
			await this.dismissModal();
			await this.dismissLoadingModal();

			const props = {
				successButtonProps: this.loadingModalSuccessButtonProps,
				failureButtonProps: [
					{
						label: this.$mhat("SignupForm.BackToSignupButtonText"),
						color: ButtonColor.PRIMARY,
						pattern: ButtonColorPattern.DEFAULT,
						click: this.loadingModalFailureCallback,
					} as BaseButtonOpts,
				],

				spinnerModalPropsCall: (): EventStateSpinnerModalProps =>
				{
					return {
						isLoading: this.loadingQueue.isLoading || this.isStatusPending,
						loadingMessage: this.loadingMessage,
						successTitle: this.successTitle,
						successMessage: this.successMessage,
						successIcon: this.successIcon,
						showFailureState: this.isStatusError,
						failureTitle: this.$mhat("SignupForm.AccountCreationFailureTitle"),
						failureMessage: this.$mhat("SignupForm.AccountCreationFailureMessage"),
						defaultCancelAction: this.loadingModalFailureCallback,
					} as EventStateSpinnerModalProps;
				},
			};

			this.loadingModal = await EventStateSpinnerModalController.presentLoader(props);
			return this.loadingModal;
		}

		public isStatus(status: SIGNUP_STATUS)
		{
			return this.status === status;
		}

		public onForgotPassword()
		{
			const context = (this.parentCtx) ? this.parentCtx : this;
			const routeLocation = this.forgotPasswordRoute ? this.forgotPasswordRoute : Route.Login.Forgot;
			context.$mhaRouterPush(routeLocation);
			this.$emit("forgot");
		}

		get loadingModalSuccessButtonProps()
		{
			if (this.successButtonProps)
			{
				return this.successButtonProps;
			}
			else
			{
				return [
					{
						label: this.$mhat("SignupForm.ToLoginButtonText"),
						color: ButtonColor.PRIMARY,
						pattern: ButtonColorPattern.FILL,
						click: () =>
						{
							const context = (this.parentCtx) ? this.parentCtx : this;
							context.$mhaRouterPush(Route.Login.Home);
						},
					} as BaseButtonOpts,
				];
			}
		}

		get loadingModalFailureCallback()
		{
			return () =>
			{
				this.resetForm();
			};
		}

		get canSubmit()
		{
			return (this.isStatusNotSubmitted || this.isStatusError) &&
				this.policyAccepted &&
				this.profileController.isRequiredFieldsValid(this.requiredFields) &&
				this.profileController.isFieldsValid() &&
				this.passwordController.isPasswordValidAndConfirmed();
		}

		get signupFields()
		{
			const signupFiledIds = [
				PatientProfileField.FIRST_NAME,
				PatientProfileField.LAST_NAME,
				PatientProfileField.EMAIL,
			];
			return signupFiledIds.concat(this.clinicRequiredFields);
		}

		get requiredFields()
		{
			const signupFiledIds = [
				PatientProfileField.FIRST_NAME,
				PatientProfileField.LAST_NAME,
				PatientProfileField.EMAIL,
			];
			const requiredFields = signupFiledIds.concat(this.clinicRequiredFields);

			// if health number required also require healthcare province code.
			if (requiredFields.includes(PatientProfileField.HEALTH_NUMBER))
			{
				requiredFields.push(PatientProfileField.HEALTHCARE_PROVINCE_CODE);
			}

			return requiredFields;
		}

		get clinicRequiredFields(): PatientProfileField[]
		{
			return this.clinic?.requiredFields || [];
		}

		get emailError()
		{
			return this.emailTaken;
		}

		get isStatusNotSubmitted()
		{
			return this.isStatus(SIGNUP_STATUS.NOT_SUBMITTED);
		}

		get isStatusPending()
		{
			return this.isStatus(SIGNUP_STATUS.PENDING);
		}

		get isStatusError()
		{
			return this.isStatus(SIGNUP_STATUS.ERROR);
		}

		get isStatusSuccess()
		{
			return this.isStatus(SIGNUP_STATUS.SUCCESS);
		}

		get isStatusSuccessPartial()
		{
			return this.isStatus(SIGNUP_STATUS.SUCCESS_NO_AUTOCONNECT);
		}

		get isBrandedMode()
		{
			return Boolean(this.clinic);
		}

		get clinicName(): string
		{
			return this.$mhat("SignupForm.clinicName", {clinicName: this.clinic?.name});
		}

		get loadingTitle(): string
		{
			return this.$mhat("SignupForm.LoadingTitle", {patientFirstName: this.profileController.userProfile.firstName});
		}

		get successTitle(): string
		{
			if (this.isStatusSuccessPartial)
			{
				return this.$mhat("SignupForm.PartialAccountCreationSuccessMessage");
			}
			else
			{
				return this.$mhat("SignupForm.AccountCreationSuccessMessage");
			}
		}

		get successMessage(): string[]
		{
			let messages = null;
			const defaultSuccessMessage = this.$mhat("SignupForm.SentEmailConfirmationMessage", {patientEmail: this.profileController.userProfile.email});

			if (this.isStatusSuccessPartial)
			{
				messages = [this.$mhat("SignupForm.PartialAccountCreatedInstructionMessage",
					{patientEmail: this.profileController.userProfile.email, clinicName: this.clinicName})];
			}
			else
			{
				if (this.successMessages && this.successMessages.length > 0)
				{
					messages = this.successMessages;
				}
				else
				{
					messages = [defaultSuccessMessage];
				}
			}

			return messages;
		}

		get successIcon(): string
		{
			return (this.isStatusSuccessPartial) ? "icon-question" : "icon-check";
		}

		get brandName()
		{
			return getResources().app_name;
		}

		get loadingMessage()
		{
			return this.$mhat("SignupForm.LoadingMessage");
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		/**
		 * set default profile parameters for the user profile
		 * @param profile - the profile to set defaults on
		 * @protected
		 */
		protected setUserProfileDefaults(profile: Patient): void
		{
			profile.organizationId = OrganizationStore.organizationProfile?.id;
			profile.timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
		}
	}
