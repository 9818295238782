import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, _mergeProps({
    onClick: _ctx.goRoute,
    primary: _ctx.primary,
    disabled: _ctx.disabled
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon"),
      _renderSlot(_ctx.$slots, "default", { class: "button-text" })
    ]),
    _: 3
  }, 16, ["onClick", "primary", "disabled"]))
}