import NativeEventListener from "@/lib/telehealth/ios/NativeEventListener";
import {IOSCallEvent} from "@/views/patient_user/telehealth/types";
import {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import {ref, Ref, unref, UnwrapRef} from "vue";

export default class StateTracker
{
	protected _callState: Ref<UnwrapRef<TELEHEALTH_STATE>> = ref(TELEHEALTH_STATE.OUT_OF_ROOM);
	protected nativeEventListener: NativeEventListener;

	protected onCallEndCallbacks: CallbackCollection;
	protected _onCallStateChangeCallbacks: CallbackCollection;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		nativeEventListener: NativeEventListener,
		onCallEndCallbacks: CallbackCollection,
		onCallStateChangeCallbacks: CallbackCollection)
	{
		this.nativeEventListener = nativeEventListener;
		this.onCallEndCallbacks = onCallEndCallbacks;
		this._onCallStateChangeCallbacks = onCallStateChangeCallbacks;

		this.setupEventListeners();
	}

	public setCallState(state: TELEHEALTH_STATE): void
	{
		this._callState = ref(state);

		// notify listeners of call state change.
		this._onCallStateChangeCallbacks.call(this._callState);
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get callState(): TELEHEALTH_STATE
	{
		return unref(this._callState);
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	protected setupEventListeners(): void
	{
		this.nativeEventListener.addNativeEventListener(IOSCallEvent.ClientAccept, this.onCallAccepted, this);
		this.nativeEventListener.addNativeEventListener(IOSCallEvent.CallEnd, this.onCallEnd, this);
	}

	protected onCallAccepted(): void
	{
		this.setCallState(TELEHEALTH_STATE.IN_ROOM);
	}

	protected onCallEnd(): void
	{
		this.setCallState(TELEHEALTH_STATE.OUT_OF_ROOM);
		this.onCallEndCallbacks.call(null);
	}
}
