import {CreateAccountInfo, PatientUserDto, Sex} from "@/open_api/generated";
import moment, {Duration, Moment} from "moment";
import { stringToBoolean } from "@/lib/utils/prototype/Boolean";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

export class Patient
{
	public static fromDto(dto: PatientUserDto): Patient
	{
		if (!dto.id)
		{
			throw new Error("Transfer model incomplete");
		}

		const patient = new Patient();

		patient.id = dto.id;
		patient.email = dto.email;
		patient.emailNotifications = dto.email_notifications;
		patient.smsNotifications = dto.sms_notifications;
		patient.firstName = dto.first_name;
		patient.middleName = dto.middle_name;
		patient.lastName = dto.last_name;
		patient.birthDate = dto.birth_date;
		patient.sex = dto.sex;
		patient.newborn = dto.newborn;
		patient.healthNumber = dto.health_number;
		patient.healthNumberVersion = dto.health_number_version;
		patient.healthCareProvinceCode = dto.health_care_province_code;
		patient.address1 = dto.address_1;
		patient.address2 = dto.address_2;
		patient.city = dto.city;
		patient.addressProvinceCode = dto.address_province_code;
		patient.postalCode = dto.postal_code;
		patient.countryCode = dto.country_code;
		patient.homePhone = dto.home_phone;
		patient.cellPhone = dto.cell_phone;
		patient.workPhone = dto.work_phone;
		patient.primaryFax = dto.primary_fax;
		patient.timeZone = dto.time_zone;
		patient.hasCompleteProfile = dto.has_complete_profile;
		patient.isDependent = dto.is_dependent;
		patient.locale = dto.locale;

		return patient;
	}

	public static toDTO(values: any): PatientUserDto
	{
		const tmpPatient = new Patient();

		for (const _property of Object.keys(tmpPatient))
		{
			const property = _property.substring(1, _property.length);
			if (values.hasOwnProperty(property))
			{
				tmpPatient[_property] = values[property];
			}
		}

		return tmpPatient.toProfileTransferObj();
	}

	public static copyConstructor(other: Patient): Patient
	{
		const copy = new Patient();
		copy.countryCode = "CA";

		if (other)
		{
			copy.id = other.id;
			copy.email = other.email;
			copy.emailNotifications = other.emailNotifications;
			copy.smsNotifications = other.smsNotifications;
			copy.firstName = other.firstName;
			copy.middleName = other.middleName;
			copy.lastName = other.lastName;
			copy.birthDateAsMoment = moment(other.birthDateAsMoment);
			copy.sex = other.sex;
			copy.newborn = other.newborn;
			copy.healthNumber = other.healthNumber;
			copy.healthNumberVersion = other.healthNumberVersion;
			copy.healthCareProvinceCode = other.healthCareProvinceCode;
			copy.address1 = other.address1;
			copy.address2 = other.address2;
			copy.city = other.city;
			copy.addressProvinceCode = other.addressProvinceCode;
			copy.postalCode = other.postalCode;
			copy.countryCode = other.countryCode;
			copy.homePhone = other.homePhone;
			copy.cellPhone = other.cellPhone;
			copy.workPhone = other.workPhone;
			copy.primaryFax = other.primaryFax;
			copy.timeZone = other.timeZone;
			copy.locale = other.locale;
			copy.isDependent = other.isDependent;
		}

		return copy;
	}

	public static fillFromQueryString(routerQueryObj: any): Patient
	{
		const patientObj = new Patient();

		const sex = this.fromQueryOrEmptyString(routerQueryObj, "sex");

		patientObj.firstName = this.fromQueryOrEmptyString(routerQueryObj, "firstName");
		patientObj.middleName = this.fromQueryOrEmptyString(routerQueryObj, "middleName");
		patientObj.lastName = this.fromQueryOrEmptyString(routerQueryObj, "lastName");
		patientObj.email = this.fromQueryOrEmptyString(routerQueryObj, "email");
		patientObj.birthDate = this.fromQueryOrEmptyString(routerQueryObj, "birthDate");
		patientObj.sex = sex.length > 0 ? Sex[sex] : null;
		patientObj.healthCareProvinceCode = this.fromQueryOrEmptyString(routerQueryObj, "healthCareProvinceCode");
		patientObj.healthNumber = this.fromQueryOrEmptyString(routerQueryObj, "healthNumber");
		patientObj.healthNumberVersion = this.fromQueryOrEmptyString(routerQueryObj, "healthNumberVersion");
		patientObj.cellPhone = this.fromQueryOrEmptyString(routerQueryObj, "cellPhone");
		patientObj.homePhone = this.fromQueryOrEmptyString(routerQueryObj, "homePhone");
		patientObj.workPhone = this.fromQueryOrEmptyString(routerQueryObj, "workPhone");
		patientObj.primaryFax = this.fromQueryOrEmptyString(routerQueryObj, "primaryFax");
		patientObj.address1 = this.fromQueryOrEmptyString(routerQueryObj, "address1");
		patientObj.address2 = this.fromQueryOrEmptyString(routerQueryObj, "address2");
		patientObj.city = this.fromQueryOrEmptyString(routerQueryObj, "city");
		patientObj.addressProvinceCode = this.fromQueryOrEmptyString(routerQueryObj, "addressProvinceCode");
		patientObj.countryCode = this.fromQueryOrEmptyString(routerQueryObj, "countryCode");
		patientObj.postalCode = this.fromQueryOrEmptyString(routerQueryObj, "postalCode");
		patientObj.timeZone = this.fromQueryOrEmptyString(routerQueryObj, "timeZone");
		patientObj.isDependent = stringToBoolean(this.fromQueryOrEmptyString(routerQueryObj, "isDependent"));

		return patientObj;
	}

	private static fromQueryOrEmptyString(routerQueryObj: any, fieldName: string): string
	{
		const routeValue = routerQueryObj[fieldName];
		if (routeValue)
		{
			return routeValue;
		}

		return "";
	}

	// Patient User
	private _id: string = null;
	private _email: string = null;
	private _emailNotifications: boolean = null;
	private _smsNotifications: boolean = null;
	private _isDependent = false;

	// Patient Profile
	private _firstName: string = null;
	private _middleName: string = null;
	private _lastName: string = null;
	private _birthDate: string = null;
	private _sex: Sex = null;
	private _newborn = false;
	private _healthNumber: string = null;
	private _healthNumberVersion: string = null;
	private _healthCareProvinceCode: string = null;
	private _address1: string = null;
	private _address2: string = null;
	private _city: string = null;
	private _addressProvinceCode: string = null;
	private _postalCode: string = null;
	private _countryCode: string = null;
	private _homePhone: string = null;
	private _cellPhone: string = null;
	private _workPhone: string = null;
	private _primaryFax: string = null;
	private _timeZone: string = null;
	private _locale: string = null;
	private _hasCompleteProfile = false;

	// Other
	private _organizationId: string = null;

	private constructor()
	{
	}

	public toProfileTransferObj(): PatientUserDto
	{
		return {
			first_name: this.firstName,
			middle_name: this.middleName,
			last_name: this.lastName,
			birth_date: this.birthDate,
			sex: this.sex,
			newborn: this.newborn,
			health_number: this.healthNumber,
			health_number_version: this.healthNumberVersion,
			health_care_province_code: this.healthCareProvinceCode,
			address_1: this.address1,
			address_2: this.address2,
			city: this.city,
			address_province_code: this.addressProvinceCode,
			postal_code: this.postalCode,
			country_code: this.countryCode,
			home_phone: this.homePhone,
			cell_phone: this.cellPhone,
			work_phone: this.workPhone,
			primary_fax: this.primaryFax,
			time_zone: this.timeZone,
			locale: this.locale,
			is_dependent: this.isDependent,
		};
	}

	public toQueryParams()
	{
		return {
			firstName: this.firstName,
			middleName: this.middleName,
			lastName: this.lastName,
			email: this.email,
			birthDate: this.birthDate,
			sex: this.sex,
			healthNumber: this.healthNumber,
			healthNumberVersion: this.healthNumberVersion,
			healthCareProvinceCode: this.healthCareProvinceCode,
			address1: this.address1,
			address2: this.address2,
			city: this.city,
			addressProvinceCode: this.addressProvinceCode,
			postalCode: this.postalCode,
			countryCode: this.countryCode,
			homePhone: this.homePhone,
			cellPhone: this.cellPhone,
			workPhone: this.workPhone,
			primaryFax: this.primaryFax,
			timeZone: this.timeZone,
			isDependent: this.isDependent.toString(),
		};
	}

	public toCreateAccountTransferObj(password: string, passwordConfirmation: string): CreateAccountInfo
	{
		return {
			email: this.email,
			password,
			password_confirmation: passwordConfirmation,
			signup_organization_id: this.organizationId,
			first_name: this.firstName,
			middle_name: this.middleName,
			last_name: this.lastName,
			birth_date: this.birthDate,
			sex: this.sex,
			health_care_province_code: this.healthCareProvinceCode,
			health_number: this.healthNumber,
			health_number_version: this.healthNumberVersion,
			cell_phone: this.cellPhone,
			home_phone: this.homePhone,
			work_phone: this.workPhone,
			address_1: this.address1,
			address_2: this.address2,
			city: this.city,
			address_province_code: this.addressProvinceCode,
			country_code: this.countryCode,
			postal_code: this.postalCode,
			primary_fax: this.primaryFax,
			time_zone: this.timeZone,
		};
	}

	public healthNumberValidation(): Promise<null>
	{
		// TODO: health number validation (async?)
		return Promise.resolve(null);
	}

	get fullName(): string
	{
		return `${this.firstName} ${this.lastName}`;
	}

	get id(): string
	{
		return this._id;
	}

	set id(value: string)
	{
		this._id = value;
	}

	get email(): string
	{
		return this._email;
	}

	set email(value: string)
	{
		this._email = value;
	}

	get emailNotifications(): boolean
	{
		return this._emailNotifications;
	}

	set emailNotifications(value: boolean)
	{
		this._emailNotifications = value;
	}

	get smsNotifications(): boolean
	{
		return this._smsNotifications;
	}

	set smsNotifications(value: boolean)
	{
		this._smsNotifications = value;
	}

	get firstName(): string
	{
		return this._firstName;
	}

	set firstName(value: string)
	{
		this._firstName = value;
	}

	get middleName(): string
	{
		return this._middleName;
	}

	set middleName(value: string)
	{
		this._middleName = value;
	}

	get lastName(): string
	{
		return this._lastName;
	}

	set lastName(value: string)
	{
		this._lastName = value;
	}

	get age(): Duration
	{
		return moment.duration(moment().diff(this.birthDateAsMoment));
	}

	get birthDateAsMoment(): moment.Moment
	{
		return moment(this._birthDate);
	}

	set birthDateAsMoment(value: moment.Moment)
	{
		this._birthDate = value.format("YYYY-MM-DD");
	}

	get birthDate(): string
	{
		return this._birthDate;
	}

	set birthDate(value: string)
	{
		this._birthDate = value;
	}

	get sex(): Sex
	{
		return this._sex;
	}

	set sex(value: Sex)
	{
		this._sex = value;
	}

	get healthNumber(): string
	{
		return this._healthNumber;
	}

	set healthNumber(value: string)
	{
		this._healthNumber = value ? value.replace(/\s/g, "") : value;
	}

	get healthNumberVersion(): string
	{
		return this._healthNumberVersion;
	}

	set healthNumberVersion(value: string)
	{
		this._healthNumberVersion = value;
	}

	get healthCareProvinceCode(): string
	{
		return this._healthCareProvinceCode;
	}

	set healthCareProvinceCode(value: string)
	{
		this._healthCareProvinceCode = value;
	}

	get address1(): string
	{
		return this._address1;
	}

	set address1(value: string)
	{
		this._address1 = value;
	}

	get address2(): string
	{
		return this._address2;
	}

	set address2(value: string)
	{
		this._address2 = value;
	}

	get city(): string
	{
		return this._city;
	}

	set city(value: string)
	{
		this._city = value;
	}

	get addressProvinceCode(): string
	{
		return this._addressProvinceCode;
	}

	set addressProvinceCode(value: string)
	{
		this._addressProvinceCode = value;
	}

	get postalCode(): string
	{
		return this._postalCode;
	}

	set postalCode(value: string)
	{
		this._postalCode = value;
	}

	get countryCode(): string
	{
		return this._countryCode;
	}

	set countryCode(value: string)
	{
		this._countryCode = value;
	}

	get homePhone(): string
	{
		return this._homePhone;
	}

	set homePhone(value: string)
	{
		this._homePhone = value;
	}

	get cellPhone(): string
	{
		return this._cellPhone;
	}

	set cellPhone(value: string)
	{
		this._cellPhone = value;
	}

	get workPhone(): string
	{
		return this._workPhone;
	}

	set workPhone(value: string)
	{
		this._workPhone = value;
	}

	get primaryFax(): string
	{
		return this._primaryFax;
	}

	set primaryFax(value: string)
	{
		this._primaryFax = value;
	}

	get timeZone(): string
	{
		return this._timeZone;
	}

	set timeZone(value: string)
	{
		this._timeZone = value;
	}

	get locale(): string
	{
		return this._locale;
	}

	set locale(value: string)
	{
		this._locale = value;
	}

	get hasCompleteProfile(): boolean
	{
		return this._hasCompleteProfile;
	}

	set hasCompleteProfile(value: boolean)
	{
		this._hasCompleteProfile = value;
	}

	get isDependent(): boolean
	{
		return this._isDependent;
	}

	set isDependent(value: boolean)
	{
		this._isDependent = value;
	}

	get organizationId(): string
	{
		return this._organizationId;
	}

	set organizationId(value: string)
	{
		this._organizationId = value;
	}

	get newborn(): boolean
	{
		return this._newborn;
	}

	set newborn(value: boolean)
	{
		this._newborn = value;
	}

	get isNewborn(): boolean
	{
		return this._newborn;
	}
}
