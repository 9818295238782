

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {FilterDropdownOpts} from "@/components/Inputs/types";
	import {SelectOption} from "@/lib/types/Components";

	@Options({
		emits: ["input", "select"],
	})
	export default class ListFilterDropdown extends Vue
	{
		@Prop({default: () => []}) filters: FilterDropdownOpts[];
		@Prop() inputValue: string;
		@Prop() activeFilter: FilterDropdownOpts;

		toFilterOpt(option: SelectOption): FilterDropdownOpts
		{
			return this.filters.find((filter) => filter.id === option.value);
		}

		toSelectOpt(filter: FilterDropdownOpts)
		{
			const {name, icon, id} = filter;
			return {name, icon, value: id};
		}

		onInput(payload)
		{
			const windowSelector = document.querySelector("ion-content");

			if (windowSelector.onclick)
			{
				windowSelector.onclick(null);
			}

			this.$emit("input", payload);
		}

		updateFilter(selectedOption)
		{
			this.$emit("select", this.toFilterOpt(selectedOption));
		}

		get filtersAsOptions()
		{
			return this.filters.map((filter) => this.toSelectOpt(filter));
		}
	}
