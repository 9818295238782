import {SignalDispatcher} from "@/lib/telehealth/opentok/SignalDispatcher";
import StateTracker from "@/lib/telehealth/opentok/StateTracker";
import SessionCommunicator from "@/lib/telehealth/opentok/SessionCommunicator";
import {
	ConnectionEvent,
	OT_CUSTOM_SIGNAL,
	OT_EVENT_TYPE,
	OT_LOCAL_SIGNAL,
	OT_SESSION_DISCONNECT_REASON,
	SessionDisconnectedEvent,
	SignalEvent,
} from "@/lib/telehealth/opentok/ot.types";
import {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
import StreamManager from "@/lib/telehealth/opentok/StreamManager";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import {UserType} from "@/open_api/generated";
import TelehealthError, {TELEHEALTH_ERROR_TYPE} from "@/lib/telehealth/error/TelehealthError";
import SessionClient from "@/lib/telehealth/opentok/SessionClient";

/**
 * responsible for "driving" the call. This class encompass call management.
 */
export default class CallManager
{
	protected signalDispatcher: SignalDispatcher;
	protected stateTracker: StateTracker;
	protected sessionCommunicator: SessionCommunicator;
	protected streamManager: StreamManager;
	protected onInboundCallCallbacks: CallbackCollection = null;
	protected onInboundCallCancelCallbacks: CallbackCollection = null;
	protected onCallEndCallbacks: CallbackCollection = null;
	protected onKickedCallbacks: CallbackCollection = null;

	// promise used in the handshaking process of calling a remote
	protected callRemoteResolve = null;
	protected callRejectionCount = 0;
	protected CALL_NO_RESPONSE_GIVE_UP_TIME = 90000; // 90 seconds

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		signalDispatcher: SignalDispatcher,
		stateTracker: StateTracker,
		sessionCommunicator: SessionCommunicator,
		streamManager: StreamManager,
		onInboundCallCallbacks: CallbackCollection,
		onCallEndCallbacks: CallbackCollection,
		onInboundCallCancelCallbacks: CallbackCollection,
		onKickedCallbacks: CallbackCollection)
	{
		this.signalDispatcher = signalDispatcher;
		this.stateTracker = stateTracker;
		this.sessionCommunicator = sessionCommunicator;
		this.streamManager = streamManager;
		this.onInboundCallCallbacks = onInboundCallCallbacks;
		this.onCallEndCallbacks = onCallEndCallbacks;
		this.onInboundCallCancelCallbacks = onInboundCallCancelCallbacks;
		this.onKickedCallbacks = onKickedCallbacks;

		this.setupEventHooks();
	}

	/**
	 * transition the telehealth call to the in room state.
	 */
	public async transitionToRoom(): Promise<void>
	{
		if (this.stateTracker.state !== TELEHEALTH_STATE.IN_ROOM)
		{
			await this.sessionCommunicator.localSignal(OT_LOCAL_SIGNAL.STATE_CHANGE, TELEHEALTH_STATE.IN_ROOM);
		}
	}

	/**
	 * transition the telehealth call to the out of room state.
	 */
	public async transitionToOutOfRoom(): Promise<void>
	{
		if (this.stateTracker.state !== TELEHEALTH_STATE.OUT_OF_ROOM)
		{
			await this.sessionCommunicator.localSignal(OT_LOCAL_SIGNAL.STATE_CHANGE, TELEHEALTH_STATE.OUT_OF_ROOM);
		}
	}

	/**
	 * call remote clients in the session.
	 * @param callDisplay - a string to be presented to the remote you are calling. Think a phone's call display.
	 * @return a promise that will resolve true / false. It will be true if any client accepts the call
	 * or false if a timeout of 60 seconds elapses and or the call is rejected and there is only one remote.
	 */
	public callRemote(callDisplay: string): Promise<boolean>
	{
		this.callRejectionCount = 0;

		return Promise.race(
			[
				new Promise<boolean>((resolve, reject) =>
				{
					this.setCallRemoteResolve(resolve);
					this.sessionCommunicator.broadcast(OT_CUSTOM_SIGNAL.CALL_INBOUND, callDisplay);
				}),
				new Promise<boolean>((resolve, reject) =>
				{
					window.setTimeout(() => resolve(false), this.CALL_NO_RESPONSE_GIVE_UP_TIME);
				}),
			]);
	}

	/**
	 * like callRemote but only calls a specific remote
	 * @param remoteId - the remote client to call
	 */
	public callSpecificRemote(remoteId: string): Promise<boolean>
	{
		return Promise.race(
			[
				new Promise<boolean>((resolve, reject) =>
				{
					this.setCallRemoteResolve(resolve);
					this.sessionCommunicator.signalByConnectionId(OT_CUSTOM_SIGNAL.CALL_INBOUND, "", remoteId);
				}),
				new Promise<boolean>((resolve, reject) =>
				{
					window.setTimeout(() => resolve(false), this.CALL_NO_RESPONSE_GIVE_UP_TIME);
				}),
			]);
	}

	/**
	 * kick a remote client from the video call
	 * @param remoteId
	 */
	public kickRemote(remoteId: string): Promise<boolean>
	{
		if (this.stateTracker.getSelf.userType !== UserType.ClinicUser)
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.PERMISSION_ERROR, "The current user is not allowed to kick");
		}

		return this.sessionCommunicator.forceDisconnect(remoteId);
	}

	/**
	 * cancel an outgoing call
	 */
	public async cancelCallRemote(): Promise<void>
	{
		await this.sessionCommunicator.broadcast(OT_CUSTOM_SIGNAL.CALL_CANCEL, "");

		// resolve any pending call requests to false (call rejected).
		if (this.callRemoteResolve)
		{
			this.callRemoteResolve(false);
			this.callRemoteResolve = null;
		}
	}

	public async signalEndCall(): Promise<void>
	{
		await this.sessionCommunicator.broadcast(OT_CUSTOM_SIGNAL.CALL_END, "");
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	protected setupEventHooks(): void
	{
		this.signalDispatcher.addSignalHandler(OT_EVENT_TYPE.CONNECTION_CREATED, this.onClientConnect, this);
		this.signalDispatcher.addSignalHandler(OT_EVENT_TYPE.SESSION_DISCONNECTED, this.onSessionDisconnect, this);

		this.signalDispatcher.addSignalHandler(OT_CUSTOM_SIGNAL.CALL_INBOUND, this.onInboundCall, this);
		this.signalDispatcher.addSignalHandler(OT_CUSTOM_SIGNAL.CALL_CANCEL, this.onCallCancel, this);
		this.signalDispatcher.addSignalHandler(OT_CUSTOM_SIGNAL.CALL_ACCEPT, this.onCallAccept, this);
		this.signalDispatcher.addSignalHandler(OT_CUSTOM_SIGNAL.CALL_REJECT, this.onCallReject, this);
		this.signalDispatcher.addSignalHandler(OT_CUSTOM_SIGNAL.CALL_END, this.onCallEnd, this);
	}

	/**
	 * emit peerData when a new client connects
	 * @protected
	 */
	protected async onClientConnect(event: ConnectionEvent): Promise<void>
	{
		const self = this.stateTracker.getSelf;

		if (self && !this.stateTracker.isSelfConnection(event.connection))
		{ // some one else has connected to the session
			// send our current status when a new client connects
			await this.sessionCommunicator.broadcast(OT_CUSTOM_SIGNAL.PEER_META_DATA, self.toJson);
		}
	}

	protected async onSessionDisconnect(event: SessionDisconnectedEvent): Promise<void>
	{
		if (event.reason === OT_SESSION_DISCONNECT_REASON.KICKED)
		{
			// we where kicked notify listeners
			await this.onKickedCallbacks.call();
		}
	}

	protected async onInboundCall(event: SignalEvent): Promise<void>
	{
		if (!this.stateTracker.isMyEvent(event) && !this.stateTracker.inRoom)
		{
			const shouldAcceptCall = await this.onInboundCallCallbacks.callTruthy(event.from, event.data);
			// Remote client may have gone away while we are waiting for user response. Check that it is still there.
			const remoteClient: SessionClient = this.stateTracker.getRemoteClientByConnectionId(event.from.connectionId);

			if (shouldAcceptCall)
			{
				if (remoteClient)
				{
					await this.sessionCommunicator.signal(OT_CUSTOM_SIGNAL.CALL_ACCEPT, "", remoteClient.connection);
				}
				await this.transitionToRoom();
			}
			else if (remoteClient)
			{
				await this.sessionCommunicator.signal(OT_CUSTOM_SIGNAL.CALL_REJECT, "", remoteClient.connection);
			}
		}
		else
		{
			// Already in room. Accept call.
			await this.sessionCommunicator.signal(OT_CUSTOM_SIGNAL.CALL_ACCEPT, "", event.from);
		}
	}

	protected async onCallCancel(event: SignalEvent): Promise<void>
	{
		// notify listeners of call cancel
		await this.onInboundCallCancelCallbacks.call(event.from.connectionId);
	}

	protected async onCallAccept(event: SignalEvent): Promise<void>
	{
		if (!this.stateTracker.isMyEvent(event) && this.callRemoteResolve)
		{
			this.callRemoteResolve(true);
			this.callRemoteResolve = null;
		}
	}

	protected async onCallReject(event: SignalEvent): Promise<void>
	{
		if (!this.stateTracker.isSelfConnection(event.from) && this.callRemoteResolve)
		{
			this.callRejectionCount++;
			if (this.callRejectionCount >= (this.stateTracker.clients.length - 1))
			{
				this.callRemoteResolve(false);
				this.callRemoteResolve = null;
			}
		}
	}

	protected async onCallEnd(event: SignalEvent): Promise<void>
	{
		if (this.stateTracker.inRoom)
		{
			const shouldEndCall = await this.onCallEndCallbacks.callTruthy(event.from);

			if (shouldEndCall)
			{
				await this.transitionToOutOfRoom();
			}
		}
	}

	/**
	 * set the call remote resolve function. If call remote is already set to a resolve
	 * the methods will be chained, creating a sort of resolve collection.
	 * @param resolve
	 */
	protected setCallRemoteResolve(resolve: (res: boolean) => void): void
	{
		if (this.callRemoteResolve)
		{
			const callRemoteResolveCopy = this.callRemoteResolve;
			this.callRemoteResolve = (result: boolean) =>
			{
				callRemoteResolveCopy(result);
				resolve(result);
			};
		}
		else
		{
			this.callRemoteResolve = resolve;
		}
	}
}
