import {AppointmentType, ScheduleSlotTransfer} from "@/open_api/generated";
import BookingServiceInterface from "@/lib/booking/service/BookngServiceInterface";
import Provider from "@/lib/clinic/providerProfile.model";
import moment from "moment";
import ClinicService from "@/lib/clinics/Clinic.service";

export default class ProviderGroupBookingService implements BookingServiceInterface
{
	protected _clinicService;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(clinicService: ClinicService)
	{
		this._clinicService = clinicService;
	}

	// ==========================================================================
	// BookingServiceInterface Implementation
	// ==========================================================================

	public async getAppointmentTypes(clinicId, providerGroupId): Promise<AppointmentType[]>
	{
		let appointmentTypes = [];

		const providerGroupProviders = await this._clinicService.getClinicProviderGroupProviders(clinicId, providerGroupId);

		for (let i = 0; i < providerGroupProviders.length; i++)
		{
			const response = await this._clinicService.getAppointmentTypes(clinicId, providerGroupProviders[i].id);

			appointmentTypes = appointmentTypes.concat(response);
		}

		// Remove duplicates
		appointmentTypes = appointmentTypes.filter((appointmentType1, index, self) =>
			index === self.findIndex((appointmentType2) => (
				appointmentType2.id === appointmentType1.id
			)),
		);

		return appointmentTypes;
	}

	public async getProviders(clinicId, providerGroupId): Promise<Provider[]>
	{
		return await this._clinicService.getClinicProviderGroupProviders(clinicId, providerGroupId);
	}

	public async getAvailableAppointments(clinicId, providerGroupId, appointmentTypeId, startDate, endDate): Promise<ScheduleSlotTransfer[]>
	{
		const appointments = [];
		const appointmentPromises = [];
		const providerGroupProviders = await this._clinicService.getClinicProviderGroupProviders(clinicId, providerGroupId);

		for (const provider of providerGroupProviders)
		{
			appointmentPromises.push(this._clinicService.clinicAvailableAppointments(clinicId, provider.id, appointmentTypeId, startDate, endDate));
		}

		const appointmentResponses = await Promise.all(appointmentPromises);

		for (const [index, response] of appointmentResponses.entries())
		{
			response.data.map(function(newAppointment)
			{
				// See if an appointment slot already exists with the same start date time.
				// If it already does, randomly decide to keep the existing one or remove the existing one and add the new one
				const existingAppointmentIndex = appointments.findIndex(existingAppointment =>
				{
					return existingAppointment.start_date_time === newAppointment.start_date_time;
				});

				if (existingAppointmentIndex !== -1)
				{
					if (Math.round(Math.random()) === 1)
					{
						appointments.splice(existingAppointmentIndex, 1);
						newAppointment.provider_id = providerGroupProviders[index].id;
						appointments.push(newAppointment);
					}
				}
				else
				{
					newAppointment.provider_id = providerGroupProviders[index].id;
					appointments.push(newAppointment);
				}
			});
		}

		// Sort all the appointments by start date time
		appointments.sort(function(appointment1, appointment2)
		{
			return moment(appointment1.start_date_time).unix() - moment(appointment2.start_date_time).unix();
		});

		return appointments;
	}
}
