import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de81b74a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "operating-hours"
}
const _hoisted_2 = {
  key: 0,
  class: "operating-title"
}
const _hoisted_3 = { class: "days-operation d-flex flex-row justify-content-space-between" }
const _hoisted_4 = { class: "d-flex flex-col" }
const _hoisted_5 = { class: "day" }
const _hoisted_6 = { class: "d-flex flex-col" }
const _hoisted_7 = {
  key: 0,
  class: "hours"
}
const _hoisted_8 = {
  key: 1,
  class: "hours"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.operatingHoursList)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showHeader)
          ? (_openBlock(), _createElementBlock("h6", _hoisted_2, _toDisplayString(this.$mhat("ClinicOperatingInfo.OperatingHoursHeader")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operatingHours, (hour) => {
              return (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.getDayOfWeekTranslation(hour.day)), 1))
            }), 256))
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operatingHours, (hour) => {
              return (_openBlock(), _createElementBlock("div", null, [
                (hour.start && hour.end)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(hour.start) + " - " + _toDisplayString(hour.end), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(this.$mhat("ClinicOperatingInfo.Closed")), 1))
              ]))
            }), 256))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}