
	import {Options, Vue} from "vue-property-decorator";
	import PrivacyPolicyText from "@/components/Text/PrivacyPolicyText.vue";
	import CloudMDPrivacyPolicyText from "@/components/Text/CloudMDPrivacyPolicyText.vue";
	import DeviceInfo from "@/lib/DeviceInfo";
	import modalBaseController from "@/lib/modal/modalBaseController";

	@Options({components: {CloudMDPrivacyPolicyText, PrivacyPolicyText}})
	export default class SignupPolicyModal extends modalBaseController
	{
		get isCloudMD()
		{
			return DeviceInfo.isCloudMd;
		}
	}
