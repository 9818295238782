import {SignupAPI} from "../services/Api";
import {CreateAccountInfo} from "@/open_api/generated";

export default class SignupService
{
	public static async signup(
		accountPayload: CreateAccountInfo,
		requestedClinicId: string,
		subscribeCloudMDMarketing = false)
	{
		let clinicPayload = null;

		if (requestedClinicId)
		{
			clinicPayload = {
				clinic_id: requestedClinicId,
				patient_registered: true,
				patient_connect_message: "",
			};
		}

		return await SignupAPI().signup({
			account_info: accountPayload,
			clinic_request: clinicPayload,
			signup_options: {
				subscribe_to_cloudmd_marketing: subscribeCloudMDMarketing,
			},
		});
	}
}
