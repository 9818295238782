import uuidv4 from "uuid";
import {RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";

const DependentsHome = () => import(
	/* webpackChunkName: "Dependents" */ "@/views/patient_user/dependent/DependentsHome.vue");
const DependentsProfile = () => import(
	/* webpackChunkName: "Dependents" */ "@/views/patient_user/dependent/DependentsProfile.vue");

export enum DependentsRoute
{
	Home = "DependentsHome",
	AddDependent = "AddDependent",
	ViewDependent = "ViewDependent",
	FindClinic = "DependentsFindClinic",
}

export const dependentRoutes: RouteRecordRaw[] = [
	{
		path: "/dependents",
		name: DependentsRoute.Home,
		component: DependentsHome,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
		},
	},
	{
		path: "/dependents/add",
		name: DependentsRoute.AddDependent,
		component: DependentsProfile,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			routeId: uuidv4(), // Forces Vue to rerender routes that load the same component
		},
	},
	{
		path: "/dependents/profile/:dependentId",
		name: DependentsRoute.ViewDependent,
		component: DependentsProfile,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			routeId: uuidv4(),
		},
	},
	{
		path: "/dependents/:dependentId/clinics/find",
		name: DependentsRoute.FindClinic,
		component: DependentsProfile,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
		},
	},
];
