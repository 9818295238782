import {HostType, RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";
import {ProvinceCode} from "@/open_api/generated";

const KioskHome = () => import(/* webpackChunkName: "kiosk" */ "@/views/kiosk_user/Kiosk.vue");

export enum KioskHomeRoute
{
	Home = "KioskHome",
}

export const homeRoutes: RouteRecordRaw[] = [
	{
		path: "/kiosk/:kioskLocation?/",
		name: KioskHomeRoute.Home,
		props: (route) =>
		{
			// Recent changes to vue-router have broken default property bindings.
			// When an optional parameter is not provided a blank string will be passed instead of null / undefined.
			if (!route.params.kioskLocation)
			{
				route.params.kioskLocation = ProvinceCode.BC;
			}

			return route;
		},
		component: KioskHome,
	},
];
