import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a0feba6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "care-cards" }
const _hoisted_2 = { class: "page-column column-right" }
const _hoisted_3 = { class: "care-cards-why" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "slot-wrapper" }
const _hoisted_6 = { class: "page-column column-left" }
const _hoisted_7 = { class: "care-cards-graphic" }
const _hoisted_8 = { class: "background" }
const _hoisted_9 = { class: "foreground" }
const _hoisted_10 = { class: "card-column" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "card-column offset" }
const _hoisted_14 = ["src"]
const _hoisted_15 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CirclesGraphic = _resolveComponent("CirclesGraphic")!
  const _component_CareCard = _resolveComponent("CareCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.titleText), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.messageText), 1),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_CirclesGraphic)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_CareCard, {
              "title-line-one": this.$mhat('WhitelabelCareCards.SeeADoctorCardTitleOne'),
              "title-line-two": this.$mhat('WhitelabelCareCards.SeeADoctorCardTitleTwo'),
              message: this.$mhat('WhitelabelCareCards.SeeADoctorCardMessage')
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.imgWallet,
                  alt: "img wallet"
                }, null, 8, _hoisted_11)
              ]),
              _: 1
            }, 8, ["title-line-one", "title-line-two", "message"]),
            _createVNode(_component_CareCard, {
              "title-line-one": this.$mhat('WhitelabelCareCards.TimeOffCardTitleOne'),
              "title-line-two": this.$mhat('WhitelabelCareCards.TimeOffCardTitleTwo'),
              message: this.$mhat('WhitelabelCareCards.TimeOffCardMessage')
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.imgClock,
                  alt: "img clock"
                }, null, 8, _hoisted_12)
              ]),
              _: 1
            }, 8, ["title-line-one", "title-line-two", "message"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_CareCard, {
              "title-line-one": this.$mhat('WhitelabelCareCards.QualifiedDoctorsCardTitleOne'),
              "title-line-two": this.$mhat('WhitelabelCareCards.QualifiedDoctorsCardTitleTwo'),
              message: this.$mhat('WhitelabelCareCards.QualifiedDoctorsCardMessage')
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.imgAward,
                  alt: "img award"
                }, null, 8, _hoisted_14)
              ]),
              _: 1
            }, 8, ["title-line-one", "title-line-two", "message"]),
            _createVNode(_component_CareCard, {
              "title-line-one": this.$mhat('WhitelabelCareCards.ConnectFromCardTitleOne'),
              "title-line-two": this.$mhat('WhitelabelCareCards.ConnectFromCardTitleTwo'),
              message: this.$mhat('WhitelabelCareCards.ConnectFromCardMessage')
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.imgComputer,
                  alt: "img computer"
                }, null, 8, _hoisted_15)
              ]),
              _: 1
            }, 8, ["title-line-one", "title-line-two", "message"])
          ])
        ])
      ])
    ])
  ]))
}