
	import {Options, Prop, Vue} from "vue-property-decorator";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import defaultBackground from "@/assets/images/graphics/common/background.jpg";
	import middleBackground from "@/assets/images/graphics/odb/image-middle@2x.png";
	import WhitelabelContentLayout from "@/views/public/whitelabel/components/WhitelabelContentLayout.vue";
	import WhitelabelMore from "@/views/public/whitelabel/components/WhitelabelMore.vue";
	import WhitelabelCareCards from "@/views/public/whitelabel/components/WhitelabelCareCards.vue";
	import AppDownload from "@/views/patient_user/login/components/AppDownload.vue";
	import BackgroundImage from "@/components/Cosmetic/BackgroundImage.vue";
	import {AppDownloadGraphicType} from "@/views/patient_user/login/components/types";
	import HowCanWeHelpCard from "@/views/public/whitelabel/components/HowCanWeHelpCard.vue";
	import ReviewCards from "@/views/public/whitelabel/components/ReviewCards.vue";
	import VirtualCareBanner from "@/views/public/whitelabel/components/VirtualCareBanner.vue";
	import {Route} from "@/router/router";
	import HomePageHeaderBar from "@/views/patient_user/home/components/HomePageHeaderBar.vue";
	import OrganizationStore, {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";
	import AnalyticsService from "@/lib/anayltics/AnalyticsService";
	import OrgImage from "@/components/Organization/OrgImage.vue";
	import OnDemandBookingHoursModal from "@/views/patient_user/odb/booking/OnDemandBookingHoursModal.vue";
	import {AqsPatientAPI, AqsPublicAPI} from "@/lib/services/Api";
	import {modalController} from "@ionic/vue";

	@Options({
		components: {
			OrgImage,
			HomePageHeaderBar,
			HowCanWeHelpCard,
			ReviewCards,
			AppDownload,
			BackgroundImage,
			WhitelabelCareCards,
			WhitelabelContentLayout,
			WhitelabelMore,
			CorporateFooter,
			VirtualCareBanner,
		},
	})
	export default class WhitelabelHome extends Vue
	{
		@Prop({type: String, required: true}) organizationId: string;

		public backgroundImage = defaultBackground;
		public middleBackground = middleBackground;
		public signupModal = null;
		public AppDownloadGraphicType = AppDownloadGraphicType;

		protected ORG_ASSET_TYPE = ORG_ASSET_TYPE;
		protected analyticsService = new AnalyticsService();

		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		public created(): void
		{
			this.analyticsService.logPageView();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public scrollTo(elementId: string)
		{
			const element = this.$refs[elementId] as HTMLElement;
			element.scrollIntoView({ behavior: "smooth" });
		}

		public async showHoursModal(): Promise<void>
		{
			// get odb queue
			const queue = (await AqsPublicAPI().getOdbQueuePublic(OrganizationStore.organizationProfile.primary_clinic_id)).data;

			const modal = await modalController.create({
				component: OnDemandBookingHoursModal,
				cssClass: "small-modal",
				componentProps: {
					queue,
				},
			});

			await modal.present();
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get welcomeTitle()
		{
			return this.$mhat("WhitelabelHome.WelcomeTitle");
		}

		get welcomeText()
		{
			return this.$mhat("WhitelabelHome.WelcomeText");
		}

		get loginRoute()
		{
			return {
				name: Route.Public.Organization.Login,
				params: {
					organizationId: this.organizationId,
				},
			};
		}

		get showCloudMdBranding()
		{
			return OrganizationStore.organizationProfile?.show_cloudmd_branding;
		}
	}
