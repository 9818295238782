

	import {Options, Vue} from "vue-property-decorator";

	import DependentsHeaderBar from "./components/DependentsHeaderBar.vue";
	import DependentsEventStateSearch from "./components/DependentsEventStateSearch.vue";
	import ButtonList from "@/components/List/ButtonList.vue";
	import {BasicListType} from "@/lib/types/ListType";
	import DependentStore from "@/lib/vuex/modules/Dependent.store";
	import LoadingQueue from "@/lib/LoadingQueue";

	@Options({components: {DependentsHeaderBar, ButtonList, DependentsEventStateSearch}})
	export default class DependentsHome extends Vue
	{
		public loadingQueue: LoadingQueue = new LoadingQueue();

		async created()
		{
			this.loadingQueue.pushLoadingState();
			await DependentStore.fetchDependents();
			this.loadingQueue.popLoadingState();
		}

		public onDependentSelect(dependent)
		{
			this.$mhaRouterPush(
				{
					name: this.Route.Dependents.ViewDependent,
					params: {
						dependentId: dependent.id,
					},
				});
		}

		get dependentList(): BasicListType[]
		{
			return DependentStore.dependents.map((dependent) =>
			{
				return {
					id: dependent.id,
					label: `${dependent.firstName} ${dependent.lastName}`,
				};
			});
		}

		get showEventSpinner()
		{
			return this.loadingQueue.isLoading || this.dependentList.length < 1;
		}
	}
