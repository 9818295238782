import {HomeRoute, homeRoutes} from "@/router/patient_user/home.routes";
import {LoginRoute, loginRoutes} from "@/router/patient_user/login.routes";
import {SignupRoute, signupRoutes} from "@/router/patient_user/signup.routes";
import {SettingsRoute, settingsRoutes} from "@/router/patient_user/settings.routes";
import {clinicsRoute, ClinicsRoutes} from "@/router/patient_user/clinics.routes";
import {dependentRoutes, DependentsRoute} from "@/router/patient_user/dependents.routes";
import {appointmentRoutes, AppointmentsRoute} from "@/router/patient_user/appointment.routes";
import {inboxRoutes, MailRoute} from "@/router/patient_user/inbox.routes";
import {BookingRoute, bookingRoutes, DependentBookingRoute} from "@/router/patient_user/booking.routes";
import {dependentClinicsRoute, DependentClinicsRoute} from "@/router/patient_user/dependentClinics.routes";
import {ODBBookingRoute, odbBookingRoutes} from "@/router/patient_user/odb.routes";
import {OrgSettingsRoute, orgSettingsRoutes} from "@/router/patient_user/org.settings.routes";
import {PatientRouteCollection} from "@/router/patient_user/PatientRouteCollection";
import {orgDependentRoutes, OrgDependentsRoute} from "@/router/patient_user/org.dependents.routes";
import {fusionRoutes} from "@/router/patient_user/fusion.routes";
import {aqsBookingRoutes} from "@/router/patient_user/aqs.booking.routes";
import {RouteRecordRaw} from "vue-router";

export const PatientUserRoute = new PatientRouteCollection();

export type PatientUserRouteType = LoginRoute | SignupRoute | BookingRoute | DependentBookingRoute | HomeRoute |
		SettingsRoute | ClinicsRoutes | DependentsRoute | OrgDependentsRoute | AppointmentsRoute | MailRoute |
		DependentClinicsRoute | ODBBookingRoute | OrgSettingsRoute;

export const patientUserRoutes: RouteRecordRaw[] = [
	...homeRoutes,
	...loginRoutes,
	...signupRoutes,
	...settingsRoutes,
	...orgSettingsRoutes,
	...dependentRoutes,
	...orgDependentRoutes,
	...dependentClinicsRoute,
	...bookingRoutes,
	...inboxRoutes,
	...appointmentRoutes,
	...clinicsRoute,
	...odbBookingRoutes,
	...fusionRoutes,
	...aqsBookingRoutes,
];
