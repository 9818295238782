
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {Route} from "@/router/router";
	import ClinicConnectionRedirectBaseController from "@/lib/patient/clinicConnectionRedirectBaseController";

	/*
	The branded booking page (on Rails) has no idea who the user is, therefore, it can't know who if the user is
	connected to the requested clinic.  Since this route requires the user to be logged in,
	we can determine if the user is connected to the requested clinic and forward them to the appropriate page.
	*/

	@Options({})
	export default class BookingBrandedRedirect extends ClinicConnectionRedirectBaseController
	{
		@Prop({type: String}) clinicId: string;

		created()
		{
			this.initialize(this.clinicId);
		}

		protected redirectConnected()
		{
			const {clinicId} = this;
			this.$mhaRouterReplace({
				name: Route.Booking.Provider,
				params: { clinicId },
			});
		}
	}
