import moment from "moment";
import {ArchiveType, ComposedMail, Mailboxes, MailboxType} from "@/lib/Mailbox/Mailbox.types";
import {Mailbox} from "@/lib/Mailbox/Mailbox.model";
import {Mail} from "@/lib/Mailbox/Mail.model";
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
import Services from "@/lib/services/Services";
import DeviceInfo from "@/lib/DeviceInfo";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import LoadingQueue from "@/lib/LoadingQueue";
import {reactive, ref, unref} from "vue";
import {Vue} from "vue-class-component";
import {$mhat} from "@/i18n";

const QUERY_LIMIT = 50;
const MAIL_RECEIVED_TIMEOUT_MINUTES = 1;
const DELETE_ANIMATION_TIMEOUT_MS = 400;

export class MailboxController
{
	private _messageableClinics: ClinicProfile[] = [];

	private _mailboxes: Mailboxes = reactive({
		[MailboxType.Inbox]: new Mailbox(ArchiveType.Archive),
		[MailboxType.Sent]: new Mailbox(ArchiveType.Archive),
		[MailboxType.Archive]: new Mailbox(ArchiveType.Unarchive),
	}) as Mailboxes;

	private _mailboxType: MailboxType = null;
	private _activeMail: Mail = null;

	private _selectModeActive = false;
	private _mailSelections: Mail[] = [];

	private _isComposing = false;
	private _isReplying = false;
	private loadingQueue = ref(new LoadingQueue());

	constructor(mailboxType: MailboxType)
	{
		this.mailboxType = mailboxType;
		this.getMessageableClinics();

		this.getMail();
	}

	public getMail()
	{
		if (this.canGetMail)
		{
			unref(this.loadingQueue).pushLoadingState();
			this.activeMailbox.allMailReceivedAt = null;

			const queryOffset: number = this.activeMailbox.queryOffset;
			Services.MailBox.getPatientMail(this.mailboxType, queryOffset, QUERY_LIMIT)
				.then((patientMail) =>
				{
					if (patientMail.length < QUERY_LIMIT)
					{
						this.activeMailbox.allMailReceivedAt = moment();
					}

					this.activeMailbox.queryOffset += patientMail.length;
					this.activeMailbox.mail.push(...patientMail);

					if (!DeviceInfo.isMobile())
					{
						this.selectMail(this.activeMailbox.mail[0]);
					}
				}).catch((error: ErrorResponse) =>
				{
					// auth errors cause a login redirect
					if (!error.isAuthError)
					{
						WebNotification.$emit({
							event: NotifyEvent.Generic,
							type: NotificationType.Swipe,
							severity: NotificationSeverity.Critical,
							title: $mhat("MailboxController.LoadingErrorTitle"),
							message: $mhat("MailboxController.LoadingErrorMessage"),
							timeout: 3000,
						});
					}
				}).finally(() =>
				{
					unref(this.loadingQueue).popLoadingState();
				});
		}
	}

	public selectMail(mail: Mail)
	{
		if (mail)
		{
			if (this.selectModeActive)
			{
				const mailSelectionIndex = this.mailSelections.indexOf(mail);

				if (mailSelectionIndex > -1)
				{
					this.mailSelections.splice(mailSelectionIndex, 1);
				}
				else
				{
					this.mailSelections.push(mail);
				}
			}
			else
			{
				Services.MailBox.readMail(this.mailboxType, mail.id)
					.then(() =>
					{
						mail.isRead = true;
					})
					.catch((err) =>
					{
						console.error(err);
					});

				this.activeMail = mail;
			}
		}
	}

	public setArchive(mail: Mail)
	{
		if (this.activeMailbox.archiveType === ArchiveType.Archive)
		{
			return Services.MailBox.archiveMail(mail.id);
		}
		else if (this.activeMailbox.archiveType === ArchiveType.Unarchive)
		{
			return Services.MailBox.unarchiveMail(mail.id);
		}
	}

	public setSelectedArchived()
	{
		if (this.selectModeActive)
		{
			this.selectModeActive = false;

			const requestArr = this.mailSelections.map((mail: Mail) => this.setArchive(mail));
			Promise.all(requestArr)
				.then(() =>
				{
					this.mailSelections.forEach((mail: Mail) => this.removeMail(mail));
					this.mailSelections = [];
					this.notifyArchiveSuccess(this.activeMailbox.archiveType);
				});
		}
		else
		{
			this.setArchive(this.activeMail)
				.then(() =>
				{
					this.removeMail(this.activeMail);
					this.notifyArchiveSuccess(this.activeMailbox.archiveType);
				});
		}
	}

	public enterSelectMode(mail?: Mail)
	{
		if (!this.selectModeActive)
		{
			this.selectModeActive = true;

			if (mail)
			{
				this.mailSelections.push(mail);
			}
		}
	}

	public leaveSelectMode()
	{
		this.selectModeActive = false;
		this.mailSelections = [];
	}

	public isSelected(mail: Mail, showActive?: boolean)
	{
		if (this.selectModeActive)
		{
			return this.mailSelections.includes(mail);
		}
		else if (this.activeMail && showActive)
		{
			return this.activeMail.id === mail.id;
		}
	}

	public changeMailbox(mailboxType: MailboxType, refresh = true)
	{
		this.leaveSelectMode();
		this.mailboxType = mailboxType;

		if (refresh || this.activeMailbox.mail.length === 0)
		{
			this.activeMailbox.resetMailbox();
			this.getMail();
		}
	}

	public replyToActive()
	{
		this.isReplying = true;
		this.isComposing = true;
	}

	public composeMail()
	{
		this.isComposing = true;
	}

	public cancelComposing()
	{
		this.isComposing = false;
		this.isReplying = false;
	}

	public sendMail(composedMail: ComposedMail)
	{
		unref(this.loadingQueue).pushLoadingState();
		return Services.MailBox.sendMail(composedMail).then((response) =>
		{
			this.cancelComposing();
			this.onSentMailSuccess(composedMail);
			return response;
		}).catch((error: ErrorResponse) =>
		{
			this.onSentMailFailure(composedMail, error);
			throw error;
		}).finally(() =>
		{
			unref(this.loadingQueue).popLoadingState();
		});
	}

	/**
	 * Refresh the currently selected message. This will cause
	 * Vue change hooks to fire.
	 * @param ctx - the vue context
	 */
	// don't pass components to controllers
	public refreshSelectedMessage(ctx: Vue): void
	{
		const selectedMail = this.activeMail;
		this.activeMail = null;
		ctx.$nextTick(() => this.activeMail = selectedMail);
	}

	public async getMessageableClinics(): Promise<void>
	{
		this.messageableClinics = await Services.PatientClinics.messageableClinics();
	}

	private onSentMailSuccess(composedMail: ComposedMail)
	{
		this.bannerNotifySuccess($mhat("MailboxController.MessageSentSuccessMessage", {clinicName: composedMail.clinic.name}));
	}

	private onSentMailFailure(composedMail: ComposedMail, error: ErrorResponse)
	{
		WebNotification.$emit({
			event: NotifyEvent.Generic,
			type: NotificationType.Dismiss,
			severity: NotificationSeverity.Critical,
			title: $mhat("MailboxController.MessageSentFailureMessage", {clinicName: composedMail.clinic.name}),
			message: error.message,
		});
	}

	private notifyArchiveSuccess(archiveType: ArchiveType)
	{
		if (archiveType === ArchiveType.Archive)
		{
			this.bannerNotifySuccess($mhat("MailboxController.ArchiveMessageSuccessMessage"));
		}
		else if (archiveType === ArchiveType.Unarchive)
		{
			this.bannerNotifySuccess($mhat("MailboxController.UnarchiveMessageSuccessMessage"));
		}
	}

	private bannerNotifySuccess(message: string)
	{
		WebNotification.$emit({
			event: NotifyEvent.Generic,
			type: NotificationType.Swipe,
			severity: NotificationSeverity.Reminder,
			title: $mhat("MailboxController.SuccessTitle"),
			message,
			timeout: 2000,
			icon: "icon-check",
		});
	}

	/**
	 * Private methods
	 */

	private removeMail(mail: Mail)
	{
		mail.isDeleting = true;
		mail.isSelected = false;

		setTimeout(() =>
		{
			this.activeMail = null;
			this.activeMailbox.resetMailbox();
			this.getMail();
		}, DELETE_ANIMATION_TIMEOUT_MS);
	}

	/**
	 * Active Mailbox computed properties
	 */
	get activeMailbox(): Mailbox
	{
		return this.mailboxes[this.mailboxType];
	}

	get isLoading()
	{
		return unref(this.loadingQueue).isLoading;
	}

	get canGetMail()
	{
		return (this.timeSinceAllMailReceived > MAIL_RECEIVED_TIMEOUT_MINUTES ||
			isNaN(this.timeSinceAllMailReceived)) && !this.isLoading;
	}

	get timeSinceAllMailReceived()
	{
		const allMailReceivedAt = this.activeMailbox.allMailReceivedAt;

		if (allMailReceivedAt)
		{
			return moment().diff(allMailReceivedAt, "minutes");
		}
	}

	get noMail()
	{
		return this.activeMailbox.mail.length === 0;
	}

	/**
	 * Instance getters & setters
	 */

	get mailboxes(): Mailboxes
	{
		return this._mailboxes;
	}

	set mailboxes(value: Mailboxes)
	{
		this._mailboxes = value;
	}

	get mailboxType(): MailboxType
	{
		return this._mailboxType;
	}

	set mailboxType(value: MailboxType)
	{
		this._mailboxType = value;
	}

	get activeMail(): Mail
	{
		return this._activeMail;
	}

	set activeMail(value: Mail)
	{
		this._activeMail = value;
	}

	get selectModeActive(): boolean
	{
		return this._selectModeActive;
	}

	set selectModeActive(value: boolean)
	{
		this._selectModeActive = value;
	}

	get mailSelections(): Mail[]
	{
		return this._mailSelections;
	}

	set mailSelections(value: Mail[])
	{
		this._mailSelections = value;
	}

	get isComposing(): boolean
	{
		return this._isComposing;
	}

	set isComposing(value: boolean)
	{
		this._isComposing = value;
	}

	get isReplying(): boolean
	{
		return this._isReplying;
	}

	set isReplying(value: boolean)
	{
		this._isReplying = value;
	}

	get messageableClinics(): ClinicProfile[]
	{
		return this._messageableClinics;
	}

	set messageableClinics(value: ClinicProfile[])
	{
		this._messageableClinics = value;
	}
}
