
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import DeleteButton from "@/components/Buttons/DeleteButton.vue";
	import {Appointment} from "@/lib/models/Appointment";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ClinicAppointmentType, ErrorCode, OneTimeTelehealthTokenDto} from "@/open_api/generated";
	import router, {Route} from "@/router/router";
	import {PatientAPI} from "@/lib/services/Api";
	import {modalController, alertController, loadingController} from "@ionic/vue";
	import PatientAppointmentService from "@/lib/appointment/service/PatientAppointmentService";
	import {AppointmentModalDismissReason} from "@/views/patient_user/appointment/AppointmentInfo.vue";
	import AuthStore from "@/lib/vuex/auth.store";

	export default {
		name: "AppointmentPage",

		emits: ["appointmentCancelled"],

		props: {
			appointment: {
				type: Object as () => Appointment,
				required: true,
			},
			clinic: {
				type: [Object as () => ClinicProfile, String],
				required: true,
			},
		},

		methods: {
			async cancelAppointment()
			{
				const loader = await loadingController.create({});
				const patientAppointmentService = new PatientAppointmentService();
				loader.present();

				patientAppointmentService.cancelAppointment(this.appointment)
					.then((results) =>
						{
							this.$emit("appointmentCancelled", {});
						},
						(errorResponse: ErrorResponse) =>
						{
							if (errorResponse.is(ErrorCode.BookingRuleViolation))
							{
								this.showCancelErrors(this.$mhat("AppointmentPage.UnableToCancelAppointmentErrorMessage"));
							}
							else
							{
								this.showCancelErrors(this.$mhat("AppointmentPage.FailedToCancelAppointmentErrorMessage"));
							}
						})
					.finally(() =>
					{
						loader.dismiss();
					},
					);
			},

			confirmCancelAppointment()
			{
				return alertController
					.create({
						header: this.$mhat("AppointmentPage.CancelAppointmentConfirmationDialogHeader"),
						message: this.$mhat("AppointmentPage.CancelAppointmentConfirmationMessage"),
						buttons: [this.$mhat("AppointmentPage.CancelAppointmentDisagree"),
							{
								text: this.$mhat("AppointmentPage.CancelAppointmentAgree"),
								handler: () =>
								{
									this.cancelAppointment();
								},
							}],
					})
					.then((a) => a.present());
			},

			showCancelErrors(message)
			{
				WebNotification.$emit({
					event: NotifyEvent.Generic,
					type: NotificationType.Swipe,
					severity: NotificationSeverity.Critical,
					title: this.$mhat("AppointmentPage.GenericErrorTitle"),
					message,
					timeout: 2500,
				});
			},

			async handleClick(): Promise<void>
			{
				if (this.appointment.isVirtual)
				{
					try
					{
						if (this.appointment.appointmentType === ClinicAppointmentType.OneTimeLink)
						{
							this.routeToOneTimeTelehealth();
						}
						else
						{
							this.$mhaRouterPush(await this.getVirtualRoute());
						}

						// if we are open in modal close it.
						if (await modalController.getTop())
						{
							modalController.dismiss(AppointmentModalDismissReason.ExternalRoute);
						}
					}
					catch (e)
					{
						this.$emit("route", await this.getVirtualRoute());
					}
				}
			},

			async rescheduleAppointment(): Promise<void>
			{
				let dependentId = null;
				const clinicId = this.clinic.id;
				const providerId = this.appointment.providerId;

				if (this.isDependentAppointment)
				{
					dependentId = this.appointment.patientId;
				}

				this.$mhaRouterPush({
					name: this.isDependentAppointment ? Route.Booking.Dependent.AppointmentType : Route.Booking.AppointmentType,
					params: {dependentId: dependentId, clinicId: clinicId, providerOrGroupId: providerId},
					query: {rescheduleRemoteAppointmentId: this.appointment.remoteId},
				});

				// if we are open in modal close it.
				if (await modalController.getTop())
				{
					modalController.dismiss(AppointmentModalDismissReason.ExternalRoute);
				}
			},

			async getVirtualRoute()
			{
				return {
					name: await Route.Appointments.Telehealth(this.appointment.id),
					params: { appointmentId: this.appointment.id },
				};
			},

			// Generate a new one time token and route the user in to a one time session.
			async routeToOneTimeTelehealth(): Promise<void>
			{
				const oneTimeToken: OneTimeTelehealthTokenDto = (await PatientAPI().getRemoteAppointmentOneTimeToken(
					this.appointment.clinicId,
					this.appointment.remoteId)).data;

				router.push({
					name: this.Route.Public.OneTimeTelehealth,
					params: {
						appointmentId: oneTimeToken.appointment_id,
					},
					query: {token: oneTimeToken.token.toString()},
				});
			},
		},

		computed: {

			showTelehealthButton()
			{
				// show button for several hours after the session is scheduled to end
				const extendedEndTime = this.appointment.endDateTime.clone().add(12, "hours");

				return this.appointment &&
					this.appointment.isVirtual &&
					this.appointment.currentClinicTime.isBefore(extendedEndTime);
			},

			isDependentAppointment(): boolean
			{
				return this.appointment.patientId !== AuthStore.loggedInUser.id;
			},
		},

		components: {DeleteButton},
	};
