import {homeRoutes, KioskHomeRoute} from "@/router/kiosk_user/home.routes";
import {KioskTelehealthRoute, telehealthRoutes} from "@/router/kiosk_user/telehealth.routes";
import {RouteRecordRaw} from "vue-router";

export const KioskUserRoute = {
	Home: KioskHomeRoute.Home,
	Telehealth: KioskTelehealthRoute.Telehealth,
};

export type KioskUserRouteType = KioskHomeRoute | KioskTelehealthRoute;

export const kioskUserRoutes: RouteRecordRaw[] = [
	...homeRoutes,
	...telehealthRoutes,
];
