import Services from "@/lib/services/Services";
import {BookingRoute, DependentBookingRoute} from "@/router/patient_user/booking.routes";
import ClinicService from "@/lib/clinics/Clinic.service";
import BookingDynamicRoute from "@/views/patient_user/booking/BookingDynamicRoute";
import {bookingRouteCollection} from "@/router/patient_user/BookingRouteCollection";

// ==============================================================
// Booking Routing Helpers Methods
// ==============================================================

// forward to the specified route
export function bookingForward(route, params, next, query = {}): any
{
	return next(
		{
			name: route,
			params: {
				...params,
			},
			query,
		});
}

// get booking route object based on if you have a dependent or not.
export function dynamicBookingRoute(dependentId): BookingDynamicRoute
{
	return new BookingDynamicRoute(dependentId ? DependentBookingRoute : bookingRouteCollection);
}

// get clinic service
export function getBookingClinicService(dependentId = null): ClinicService
{
	return dependentId ? Services.DependentClinics(dependentId) : Services.PatientClinics;
}
