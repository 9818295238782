
	import {Options, Vue} from "vue-property-decorator";
	import OrganizationService from "@/lib/organization/service/OrganizationService";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import {Route} from "@/router/router";

	@Options({components: {}})
	export default class OrgBookingRedirect extends Vue
	{
		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		public async created(): Promise<void>
		{
			try
			{
				// ensure the patient is connected to primary clinic
				await (new OrganizationService()).connectToCurrentOrganizationPrimaryClinic();

				this.$mhaRouterReplace(
					{
						name: Route.Booking.Provider,
						params: {
							organizationId: OrganizationStore.organizationProfile.url_friendly_name,
							clinicId: OrganizationStore.organizationProfile.primary_clinic_id,
						},
						query: {
							hasClose: "false",
						},
					});
			}
			catch (err)
			{
				// ignore profile incomplete error
				if (!(err instanceof ErrorResponse) || !err.is(ErrorCode.IncompleteProfile))
				{
					throw err;
				}
			}
		}
	}
