import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-314d3c4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-col align-items-center justify-content-center w-fit-content"
}
const _hoisted_2 = ["src", "type"]
const _hoisted_3 = {
  key: 1,
  class: "no-preview d-flex flex-col justify-content-center text-center"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.canPreviewFile && !_ctx.isFileTextType)
          ? (_openBlock(), _createElementBlock("embed", {
              key: 0,
              class: "embedded-content w-max-100 h-max-100",
              src: `data:${_ctx.document.type};utf-8;base64,${_ctx.documentBase64Data}`,
              alt: "Attachment preview",
              type: _ctx.document.type
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$mhat("DocumentPreview.NotAvailable")), 1))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_ion_spinner, { color: "primary" })
      ]))
}