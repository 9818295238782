import JWT from "jsonwebtoken";
import {UserType} from "@/open_api/generated";

export class LoginToken
{
	private readonly _tokenStr: string;
	private readonly _email: string;
	private readonly _userType: UserType;
	private readonly _expiresAt: Date;

	constructor(token: string)
	{
		// eslint-disable-next-line camelcase
		const {email, user_type, exp}: any = JWT.decode(token);

		// Throw an error for old and invalid JWTs
		// eslint-disable-next-line camelcase
		if (email)
		{
			this._tokenStr = token;
			this._email = email;
			// eslint-disable-next-line camelcase
			this._userType = user_type || UserType.PatientUser;

			// Kiosk users don't have an expiration
			if (exp)
			{
				this._expiresAt = new Date(exp * 1000);
			}
		}
		else
		{
			throw new Error("Invalid JWT");
		}
	}

	get tokenStr(): string
	{
		return this._tokenStr;
	}

	get isExpired(): boolean
	{
		if (this.expiresAt)
		{
			return Date.now() >= this.expiresAt.valueOf();
		}
		else
		{
			// If expiration is null, isExpired should only return false for Kiosk users
			return this._userType !== UserType.KioskUser;
		}
	}

	get email(): string
	{
		return this._email;
	}

	get userType(): UserType
	{
		return this._userType;
	}

	get expiresAt(): Date
	{
		return this._expiresAt;
	}
}
