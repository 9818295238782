import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10c9d074"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-title" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: $props.icon,
      alt: $props.title
    }, null, 8, _hoisted_2),
    _createElementVNode("span", null, _toDisplayString($props.title), 1)
  ]))
}