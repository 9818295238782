
	import EventStateSpinner from "@/components/EventState/EventStateSpinner.vue";

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({components: {EventStateSpinner}})
	export default class ErrorPage extends Vue
	{
		@Prop({type: String, default: null}) errorTitle;
		@Prop({type: String, default: null}) errorMessage;
		@Prop({type: Number, default: 404}) errorCode;
	}
