
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import ContactClinicRouteButton from "./components/ContactClinicRouteButton.vue";
	import AuthStore from "@/lib/vuex/auth.store";
	import BookingController, {setBookingParams} from "./BookingController";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import {isBlank} from "@/lib/utils/prototype/String";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";
	import {PatientUser} from "@/lib/patient/patientUser.model";
	import {ErrorCode} from "@/open_api/generated";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity} from "@/lib/types/Notifier";
	import ScheduleSlot from "@/lib/scheduling/model/ScheduleSlot";
	import {getBookingClinicService} from "@/views/patient_user/booking/BookingRoutingHelper";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";

	export default {
		name: "BookingNextAvailability",

		props: {
			appointmentMethod: String,
			clinicId: String,
			providerOrGroupId: String,
			appointmentTypeId: String,
			dependentId: String,
			hasClose: {
				type: Boolean,
				required: false,
				default: true,
			},
			noBack: {
				type: Boolean,
				required: false,
				default: false,
			},
			rescheduleRemoteAppointmentId: String,
		},

		data()
		{
			return {
				showFindProviderSpinner: false,
				selectedButton: null,
				skipBookingNotes: true,
				initialized: false,
				error: null as ErrorResponse,
				ButtonColor,
				ButtonColorPattern,
			};
		},

		async created()
		{
			setBookingParams.call(this);

			this.controller.fetchAppointmentData()
				.then(() => this.checkBookingNotes())
				.catch((error: ErrorResponse) =>
				{
					this.initialized = true;
					// TODO: Need a better system for checking if a caught error is an ErrorResponse or a runtime error
					if (error instanceof ErrorResponse &&
						(error.is(ErrorCode.RecordNotFound) || error.is(ErrorCode.IncompleteProfile)))
					{
						this.error = error;

						if (this.$isCloudMd)
						{
							this.$router.go(-1);
						}
					}
					else if (error instanceof ErrorResponse && error.is(ErrorCode.RemotePatientInactive))
					{
						NotificationService.notificationDismiss(
							this.$mhat("BookingNextAvailability.ClinicConnectionProblem"),
							error.message,
							NotificationSeverity.Warning);
					}
					else
					{
						// If not an ErrorResponse, it's a runtime error, throw for error logger
						throw error;
					}
				},
				);
		},

		methods: {
			friendlyTime(slot: ScheduleSlot)
			{
				return slot.startDateTime.format("LLL");
			},

			async selectSlot(slot: ScheduleSlot, index: number)
			{
				this.controller.providerOrGroupId = slot.providerId;
				this.controller.provider = await getBookingClinicService().patientClinicProvider(this.clinicId, slot.providerId);

				this.controller.selectAppointmentSlot(slot);

				const appointmentDate = slot.startDateString;

				this.$mhaRouterPush({
					name: this.isDependentMode ? Route.Booking.Dependent.Reason : Route.Booking.Reason,
					params: {...this.params, appointmentDate, appointmentId: index.toString(), providerOrGroupId: slot.providerId},
					query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId},
				});
			},

			checkBookingNotes()
			{
				const controller: BookingController = this.controller;

				controller.clinicBookingNotes()
					.then((bookingNotes) =>
					{
						this.skipBookingNotes = bookingNotes === null;
						this.initialized = true;
					});
			},

			closeBooking(): void
			{
				const crossFrameService = new CrossFrameCommunicationService();
				if (crossFrameService.hasParentFrame)
				{
					crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateBack});
				}

				this.$mhaRouterPush(Route.Home);
			},

			providerGroupRoute(): void
			{
				this.showFindProviderSpinner = true;

				this.$mhaRouterPush(
					{
						name: this.isDependentMode ? Route.Booking.Dependent.ProviderGroup : Route.Booking.ProviderGroup,
						params: {...this.params},
						query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId},
					},
				);
			},
		},

		computed: {
			failure()
			{
				const genericFailure = {
					title: this.$mhat("BookingNextAvailability.NoAppointmentsAvailable"),
					message: this.$mhat("BookingNextAvailability.NoAppointmentsAvailableMessage"),
				};

				if (this.error)
				{
					const {message, code} = this.error;

					if (this.error.is(ErrorCode.RemoteClinicConnection))
					{
						return {title: this.$mhat("BookingNextAvailability.UnableToContactClinic"), message};
					}
					else
					{
						genericFailure.message = message;
						return genericFailure;
					}
				}
				else
				{
					return genericFailure;
				}
			},

			showFindAppointmentsSpinner()
			{
				return (this.isLoading || this.noAppointments) && !this.showFindProviderSpinner;
			},

			isLoading()
			{
				return !this.initialized || this.controller.isLoading;
			},

			noAppointments()
			{
				return this.controller.noAppointments;
			},

			controller()
			{
				return BookingControllerStore.bookingController;
			},

			availableSlots(): ScheduleSlot[]
			{
				return this.controller?.availableSlots.slice(0, 3) || [];
			},

			helperText()
			{
				const {firstName} = (AuthStore.loggedInUser as PatientUser).profile;

				if (this.isLoading)
				{
					return this.$mhat("BookingNextAvailability.FindingAppointmentHelperText", {firstName: firstName});
				}

				return this.$mhat("BookingNextAvailability.NextAvailableAppointmentsHelperText", {firstName: firstName, numberOfAppointmentSlots: this.availableSlots.length});
			},

			calendarRoute()
			{
				return {
					name: this.isDependentMode ? Route.Booking.Dependent.Calendar : Route.Booking.Calendar,
					params: {...this.params},
					query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId},
				};
			},

			params()
			{
				const {
					appointmentMethod,
					dependentId,
					clinicId,
					providerOrGroupId,
					appointmentTypeId,
				} = this;

				return {appointmentMethod, dependentId, clinicId, providerOrGroupId, appointmentTypeId};
			},
			isDependentMode()
			{
				return !isBlank(this.dependentId);
			},
		},

		components: {BaseButton, BookingHeaderBar, DependentSubheader, ContactClinicRouteButton},
	};
