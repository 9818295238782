
	import moment, {Moment} from "moment";
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import ContactClinicRouteButton from "./components/ContactClinicRouteButton.vue";
	import {setBookingParams} from "./BookingController";
	import {DATE_FORMAT} from "@/constants";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import {isBlank} from "@/lib/utils/prototype/String";
	import {SelectOption} from "@/lib/types/Components";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";

	const MAX_MONTH_SEARCH = 6;

	export default {
		name: "BookingCalendar",

		props: {
			clinicId: String,
			providerOrGroupId: String,
			appointmentTypeId: String,
			dependentId: String,
			hasClose: {
				type: Boolean,
				required: false,
				default: true,
			},
			rescheduleRemoteAppointmentId: String,
		},

		data()
		{
			return {
				currentMonth: moment(),
				bookableMonths: this.createBookableMonths(),
				ButtonColor,
				ButtonColorPattern,
			};
		},

		created()
		{
			setBookingParams.call(this);

			this.controller.fetchAppointmentData();
			this.setMonthIfAppointmentsExists();
		},

		methods: {

			changeMonth(option: SelectOption)
			{
				this.currentMonth = option.value;
				this.controller.getAppointmentsForMonth(this.currentMonth);
			},

			selectDate(selectedDate: Moment)
			{
				const appointmentDate = selectedDate.format(DATE_FORMAT.DATE_STRING);

				this.$mhaRouterPush({
					name: this.isDependentMode ? Route.Booking.Dependent.AppointmentTime : Route.Booking.AppointmentTime,
					params: {...this.params, appointmentDate},
					query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId},
				});
			},

			setMonthIfAppointmentsExists()
			{
				if (this.controller.hasAppointments)
				{
					const monthString = this.controller.availableSlots[0].startDateString;
					this.currentMonth = moment(monthString);
				}
			},

			createBookableMonths(): Moment[]
			{
				const months = [];

				for (let i = 0; i < MAX_MONTH_SEARCH; i++)
				{
					months.push(moment().add(i, "months").startOf("month"));
				}

				return months;
			},
		},

		computed: {
			availableDates()
			{
				return new Set(this.controller.availableSlots.map((slot) => slot.startDateString));
			},

			failureMessage()
			{
				return this.$mhat("BookingCalendar.FailureMessage");
			},

			showSpinner()
			{
				return this.isLoading || this.noAppointments;
			},

			isLoading()
			{
				return this.controller.isLoading;
			},

			noAppointments()
			{
				return this.controller.noAppointments;
			},

			controller()
			{
				return BookingControllerStore.bookingController;
			},

			params()
			{
				const {dependentId, clinicId, providerOrGroupId, appointmentTypeId} = this;
				return {dependentId, clinicId, providerOrGroupId, appointmentTypeId};
			},
			isDependentMode()
			{
				return !isBlank(this.dependentId);
			},

			monthOptions(): {[key: string]: SelectOption[]}
			{
				return this.bookableMonths.reduce((acc, month: Moment) =>
				{
					const monthFormat = month.format("MMMM YYYY");
					acc[monthFormat] = {
						name: monthFormat,
						value: month,
					};
					return acc;
				}, {});
			},
		},

		components: {BookingHeaderBar, DependentSubheader, ContactClinicRouteButton},
	};
