import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28c8b506"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "appointment-times" }
const _hoisted_2 = {
  key: 0,
  class: "column"
}
const _hoisted_3 = { class: "column-header" }
const _hoisted_4 = { class: "column-items" }
const _hoisted_5 = {
  key: 1,
  class: "column"
}
const _hoisted_6 = { class: "column-header" }
const _hoisted_7 = { class: "column-items" }
const _hoisted_8 = {
  key: 2,
  class: "column"
}
const _hoisted_9 = { class: "column-header" }
const _hoisted_10 = { class: "column-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, {
        "header-text": this.$mhat('BookingAppointmentTime.HeaderText'),
        "has-close": $props.hasClose
      }, null, 8, ["header-text", "has-close"])
    ]),
    _: 2
  }, [
    (!$options.showSpinner)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            ($options.isDependentMode)
              ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                  key: 0,
                  dependent: $options.controller.dependent
                }, null, 8, ["dependent"]))
              : _createCommentVNode("", true),
            _createElementVNode("p", null, _toDisplayString(this.$mhat("BookingAppointmentTime.SelectTimeInstructionText", {selectedDate: $options.dateDisplay})), 1),
            _createElementVNode("div", _hoisted_1, [
              ($options.morningSlots.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(this.$mhat("BookingAppointmentTime.Morning")), 1),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.morningSlots, (slot, index) => {
                        return (_openBlock(), _createBlock(_component_ion_button, {
                          key: slot.startDateTime,
                          class: "time-slot",
                          fill: "clear",
                          onClick: ($event: any) => ($options.setSlot(slot, index))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString($options.getDisplayTime(slot)), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              ($options.afternoonSlots.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(this.$mhat("BookingAppointmentTime.Afternoon")), 1),
                    _createElementVNode("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.afternoonSlots, (slot, index) => {
                        return (_openBlock(), _createBlock(_component_ion_button, {
                          key: slot.startDateTime,
                          class: "time-slot",
                          fill: "clear",
                          onClick: ($event: any) => ($options.setSlot(slot, index))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString($options.getDisplayTime(slot)), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              ($options.eveningSlots.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(this.$mhat("BookingAppointmentTime.Evening")), 1),
                    _createElementVNode("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.eveningSlots, (slot, index) => {
                        return (_openBlock(), _createBlock(_component_ion_button, {
                          key: slot.startDateTime,
                          class: "time-slot",
                          fill: "clear",
                          onClick: ($event: any) => ($options.setSlot(slot, index))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString($options.getDisplayTime(slot)), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        }
      : {
          name: "content-center",
          fn: _withCtx(() => [
            _createVNode(_component_EventStateSpinner, {
              "is-loading": $options.isLoading,
              "loading-icon": "icon-calendar-add",
              "loading-title": this.$mhat('BookingAppointmentTime.LoadingTitle'),
              "show-failure-state": $options.noAppointmentsForDay,
              "failure-icon": "icon-calendar-add",
              "failure-title": this.$mhat('BookingAppointmentTime.LoadingFailureTitle'),
              "failure-message": $options.failureMessage
            }, null, 8, ["is-loading", "loading-title", "show-failure-state", "failure-title", "failure-message"]),
            ($options.noAppointmentsForDay)
              ? (_openBlock(), _createElementBlock("div", { key: 0 }, [
                  _createVNode(_component_RouteButton, {
                    pattern: $data.ButtonColorPattern.FILL,
                    color: $data.ButtonColor.PRIMARY,
                    route: $options.prevRoute
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("BookingAppointmentTime.ChangeDateButtonText")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "route"]),
                  _createVNode(_component_RouteButton, {
                    pattern: $data.ButtonColorPattern.TRANSPARENT,
                    color: $data.ButtonColor.PRIMARY,
                    route: _ctx.Route.Home
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("BookingAppointmentTime.CloseWindow")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "route"])
                ]))
              : _createCommentVNode("", true)
          ])
        }
  ]), 1024))
}