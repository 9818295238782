import {BookingRoute, DependentBookingRoute} from "@/router/patient_user/booking.routes";
import {getRouteOrWhitelabelRoute} from "@/router/whitelableUtil";
import DependentBookingRouteCollection, {dependentBookingRouteCollection} from "@/router/patient_user/DependentBookingRouteCollection";

export default class BookingRouteCollection
{
	// ==========================================================================
	// Singleton impl
	// ==========================================================================

	private static instance: BookingRouteCollection;

	public static getInstance(): BookingRouteCollection
	{
		if (!this.instance)
		{
			this.instance = new BookingRouteCollection();
		}
		return this.instance;
	}

	private constructor()
	{
	}

	// ==========================================================================
	// Routes
	// ==========================================================================

	get BrandedRedirect(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.BrandedRedirect);
	}

	get Home(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.Home);
	}

	get Clinic(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.Clinic);
	}

	get ProviderGroup(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.ProviderGroup);
	}

	get Provider(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.Provider);
	}

	get ProviderGroupProvider(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.ProviderGroupProvider);
	}

	get AppointmentType(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.AppointmentType);
	}

	get Notes(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.Notes);
	}

	get NextAvailability(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.NextAvailability);
	}

	get Calendar(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.Calendar);
	}

	get AppointmentTime(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.AppointmentTime);
	}

	get Reason(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.Reason);
	}

	get Confirm(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.Confirm);
	}

	get BookAppointment(): string
	{
		return getRouteOrWhitelabelRoute(BookingRoute.BookAppointment);
	}

	get Dependent(): DependentBookingRouteCollection
	{
		return dependentBookingRouteCollection;
	}
}

export const bookingRouteCollection = BookingRouteCollection.getInstance();
