import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53216834"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "clinic-contact-info"
}
const _hoisted_2 = { class: "clinic-title" }
const _hoisted_3 = { class: "clinic-address" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { class: "clinic-phone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.clinic)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.clinic.name), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", null, [
            (_ctx.clinic.address1)
              ? (_openBlock(), _createElementBlock("li", _hoisted_4, _toDisplayString(_ctx.clinic.address1), 1))
              : _createCommentVNode("", true),
            (_ctx.clinic.address2)
              ? (_openBlock(), _createElementBlock("li", _hoisted_5, _toDisplayString(_ctx.clinic.address2), 1))
              : _createCommentVNode("", true),
            (_ctx.clinic.addressCityDisplay)
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, _toDisplayString(_ctx.clinic.addressCityDisplay), 1))
              : _createCommentVNode("", true),
            (_ctx.clinic.postalCodeDisplay)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, _toDisplayString(_ctx.clinic.postalCodeDisplay), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.clinic.primaryPhoneDisplay), 1)
      ]))
    : _createCommentVNode("", true)
}