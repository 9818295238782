import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3577a080"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-item" }
const _hoisted_3 = { class: "header-item center" }
const _hoisted_4 = { class: "header-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, {
    class: "ion-header-class",
    style: _normalizeStyle(_ctx.headerStyleOverride)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["header-container", { overlay: _ctx.overlay, white: _ctx.white }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "left", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "center", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "right", {}, undefined, true)
          ])
        ])
      ], 2)
    ]),
    _: 3
  }, 8, ["style"]))
}