import axios, {AxiosError} from "axios";
import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import {ErrorCode, ErrorResponseInterface} from "@/open_api/generated";
import router, {getUserLoginRoute, Route} from "@/router/router";
import DeviceInfo from "@/lib/DeviceInfo";
import Services from "@/lib/services/Services";
import {RouteLocationRaw} from "vue-router";
import PatientModalService from "@/lib/modal/service/PatientModalService";
import {DependentClinicsRoute} from "@/router/patient_user/dependentClinics.routes";
import {$mhat} from "@/i18n";
import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";

function handleNetworkError(): void
{
	WebNotification.$emit({
		event: NotifyEvent.NetworkError,
		type: NotificationType.Dismiss,
		severity: NotificationSeverity.Critical,
		title: $mhat("AxiosResponseInterceptor.NetworkErrorTitle"),
		message: $mhat("AxiosResponseInterceptor.NetworkErrorMessage"),
		dismiss: {
			message: $mhat("AxiosResponseInterceptor.DismissButtonText"),
		},
	});
}

function handleAuthenticationError(errorResponse: ErrorResponse): void
{
	const crossFrameService = new CrossFrameCommunicationService();
	const userLoginRouteName = getUserLoginRoute(errorResponse.data.user_type);
	const route: RouteLocationRaw = {
		name: userLoginRouteName || Route.LoginPage,
		query: {
			error_code: errorResponse.code,
			push_route: undefined,
		},
	};

	const currentLocation = router.currentRoute.value.name;
	if (currentLocation !== Route.LoginPage)
	{
		route.query.push_route = router.currentRoute.value.path;
	}

	Services.PatientLogin.logout();
	Services.ProviderLogin.logout();
	Services.KioskLogin.logout();

	if (crossFrameService.hasParentFrame)
	{
		// let any parent frames know we want to go back to the login page.
		crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateToLogin});
	}

	router.push(route);
}

function handleIncompleteProfileError(errorResponse: ErrorResponse): void
{
	const noRedirectList: string[] = [
		Route.Settings.RequiredEdit,
		Route.Booking.BrandedRedirect,
		Route.odbBookingRoute.Redirect,
		Route.Clinics.Home,
		Route.Clinics.FindClinic,
		DependentClinicsRoute.FindParentClinics,
		DependentClinicsRoute.FindClinic,
	];

	const currentLocation = router.currentRoute.value.name;
	if (!noRedirectList.includes(currentLocation as string))
	{
		if (isIncompleteProfileCorrectable(errorResponse.data))
		{ // patient has no remote id and clinic does not allow remote creation. show error page
			PatientModalService.displayCannotConnectModal(errorResponse.data.clinic_id).then(() => router.back());
		}
		else
		{ // show incomplete profile modal
			PatientModalService.displayIncompleteProfileModal(
				errorResponse.data.clinic_id,
				errorResponse.data.patient_user_id,
				errorResponse.data.missing_fields);
		}
	}
}

/**
 * determine if the user can correct the incomplete profile or not based on error data.
 * @param incompleteProfileData - the data returned by the incomplete profile error
 */
function isIncompleteProfileCorrectable(incompleteProfileData: any): boolean
{
	return (incompleteProfileData.patient_remote_id === null && !incompleteProfileData.clinic_allows_remote_create) ||
		incompleteProfileData.inactive_profile;
}

function handleInvalidProvinceError(errorResponse: ErrorResponse): void
{
	if (DeviceInfo.isCloudMd)
	{
		const currentLocation = router.currentRoute.value.name;
		if (currentLocation !== Route.Settings.RequiredEdit)
		{
			router.push({
				name: Route.Settings.RequiredEdit,
				query: {
					userId: errorResponse.data.patient_user_id,
					clinicId: errorResponse.data.clinic_id,
					invalidProvinceCode: "true",
				},
			});
		}
	}
}

function handleRemoteConnectionError(errorResponse: ErrorResponse): void
{
	WebNotification.$emit({
		event: NotifyEvent.Generic,
		type: NotificationType.Swipe,
		severity: NotificationSeverity.Critical,
		title: $mhat("AxiosResponseInterceptor.RemoteConnectionErrorTitle"),
		message: errorResponse.message,
		timeout: 4000,
	});
}

axios.interceptors.response.use((response) =>
{
	return response;
},
(error: AxiosError) =>
{
	// Network Error
	if (!error.response)
	{
		handleNetworkError();
	}
	else
	{
		const errorResponse = new ErrorResponse(error.response.data as ErrorResponseInterface);

		if (errorResponse.is(ErrorCode.Authentication) || errorResponse.is(ErrorCode.SessionExpired))
		{
			handleAuthenticationError(errorResponse);
		}
		else if (errorResponse.is(ErrorCode.IncompleteProfile))
		{
			handleIncompleteProfileError(errorResponse);
		}
		else if (errorResponse.is(ErrorCode.InvalidProvince))
		{
			handleInvalidProvinceError(errorResponse);
		}
		else if (errorResponse.is(ErrorCode.RemoteClinicConnection))
		{
			handleRemoteConnectionError(errorResponse);
		}

		return Promise.reject(errorResponse);
	}
});
