
	import {Options, Vue, Prop} from "vue-property-decorator";

	import { ButtonColor, ButtonColorPattern } from "@/components/Buttons/types";

	@Options({})
	export default class PatientDataSpinner extends Vue
	{
		@Prop({type: Boolean})
		public isLoading: boolean;

		@Prop({type: Boolean})
		public isError: boolean;

		public ButtonColorPattern = ButtonColorPattern;
		public ButtonColor = ButtonColor;

		public reload()
		{
			window.location.reload();
		}

		get isLoadingState()
		{
			return this.isLoading && !this.isError;
		}
	}
