

	import ChatView from "@/components/telehealth/ChatView.vue";
	import {Options, Vue} from "vue-property-decorator";
	import ChatBubble from "@/components/telehealth/ChatBubble.vue";
	import TelehealthHeader from "@/views/patient_user/telehealth/components/TelehealthHeader.vue";

	@Options({components: {ChatBubble, TelehealthHeader}})
	export default class ChatViewClinicUser extends ChatView
	{

	}

