import CallbackCollection from "@/lib/utils/CallbackCollection";
import NativeEventListener from "@/lib/telehealth/ios/NativeEventListener";
import {IOSCallEvent} from "@/views/patient_user/telehealth/types";
import LogEvent from "@/lib/telehealth/models/LogEvent";

export default class EventLogger
{
	protected onLogEventCallbacks: CallbackCollection;
	protected nativeEventListener: NativeEventListener;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		nativeEventListener: NativeEventListener,
		onLogEventCallbacks: CallbackCollection)
	{
		this.nativeEventListener = nativeEventListener;
		this.onLogEventCallbacks = onLogEventCallbacks;

		this.setupEventListeners();
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	protected setupEventListeners(): void
	{
		this.nativeEventListener.addNativeEventListener(IOSCallEvent.LogEvent, this.onLogEvent, this);
	}

	protected onLogEvent(data: string): void
	{
		const nativeLogData = JSON.parse(data);

		const logEvent = new LogEvent(
			nativeLogData.signalType,
			nativeLogData.logLevel,
			nativeLogData.message,
			nativeLogData.fromUserId,
			nativeLogData.fromUserType,
			nativeLogData.toUserId,
			nativeLogData.toUserType,
		);

		this.onLogEventCallbacks.call(logEvent);
	}
}
