import {Clinic as ClinicTransfer, ClinicConnectionStatus, OperatingHours, ProviderTransfer, ClinicBookingStatus} from "../../open_api/generated/api";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {RouteLocation, RouteLocationRaw} from "vue-router";
import {concatIfExists} from "@/lib/utils/prototype/String";
import {Route} from "@/router/router";
import {PatientProfileField} from "@/lib/types/Profile";

export class ClinicProfile
{
	private _id: string;
	private _name: string;
	private _provinceCode: string;
	private _city: string;
	private _postalCode: string;
	private _address1: string;
	private _address2: string;
	private _primaryPhone: string;
	private _about: string;

	private _operatingHours: OperatingHours;
	private _bookingNotes: string;

	private _primaryProvider: ProviderTransfer;
	private _providerNames: string[];
	private _requiredFields: PatientProfileField[];

	private _allowsUnconfirmedBooking: boolean;
	private _allowsRemoteProfileCreation: boolean;
	private _connectionStatus: ClinicConnectionStatus;
	private _bookingStatus: ClinicBookingStatus;
	private _allowsDependentBooking: boolean;

	private _patientCanMessage: boolean;
	private readonly _iconPath: string;
	private readonly _telehealthEnabled: boolean;
	private readonly _ebookingEnabled: boolean;

	private _allowSkippingProviderSelection: boolean;
	private _allowSkippingAppointmentTypeSelection: boolean;

	constructor(clinicTransfer: ClinicTransfer)
	{
		this.id = clinicTransfer.id;
		this.name = clinicTransfer.name;
		this.provinceCode = clinicTransfer.province_code;
		this.city = clinicTransfer.city;
		this._iconPath = clinicTransfer.icon_path;
		this.postalCode = clinicTransfer.postal_code;
		this.address1 = clinicTransfer.address_1;
		this.address2 = clinicTransfer.address_2;
		this.primaryPhone = clinicTransfer.primary_phone;
		this.about = clinicTransfer.about;
		this.operatingHours = clinicTransfer.operating_hours;
		this.allowsUnconfirmedBooking = clinicTransfer.allow_unconfirmed_bookings;
		this.allowsRemoteProfileCreation = clinicTransfer.allow_remote_profile_creation;
		this.connectionStatus = clinicTransfer.connection_status;
		this.primaryProvider = clinicTransfer.primary_provider;
		this.providerNames = clinicTransfer.provider_names;
		this.bookingNotes = clinicTransfer.patient_booking_notes;
		this.patientCanMessage = clinicTransfer.patient_can_message;
		this.allowsDependentBooking = clinicTransfer.allow_dependent_bookings;
		this.requiredFields = clinicTransfer.required_fields as PatientProfileField[];
		this._telehealthEnabled = clinicTransfer.is_virtual;
		this.bookingStatus = clinicTransfer.booking_status;
		this._ebookingEnabled = clinicTransfer.ebooking_enabled;
		this.allowSkippingProviderSelection = clinicTransfer.allow_skipping_provider_selection;
		this.allowSkippingAppointmentTypeSelection = clinicTransfer.allow_skipping_appointment_type_selection;
	}

	dependentBookingRoute(dependentId: string): RouteLocationRaw
	{
		return {
			name: Route.Booking.Dependent.Provider,
			params: {
				appointmentMethod: "clinic",
				clinicId: this.id,
				dependentId,
			},
		};
	}

	get primaryPhoneDisplay()
	{
		if (this.primaryPhone)
		{
			const phoneNumber = parsePhoneNumberFromString(this.primaryPhone, "CA");
			if (phoneNumber)
			{
				return phoneNumber.formatNational();
			}
			return "";
		}
	}

	get operatingHoursArr()
	{
		const week = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
		return week.map((day) =>
		{
			return {
				day,
				start: this.operatingHours[day].start,
				end: this.operatingHours[day].stop,
			};
		});
	}

	get patientCanBook()
	{
		return this.bookingStatus === ClinicBookingStatus.Bookable;
	}

	get patientBookingPending()
	{
		return this.bookingStatus === ClinicBookingStatus.Pending;
	}

	get patientBookingNoConnection()
	{
		return this.bookingStatus === ClinicBookingStatus.NotConnected;
	}

	get patientBookingUnavailable()
	{
		return this.bookingStatus === ClinicBookingStatus.Unavailable;
	}

	get hasRejectedPatient()
	{
		return this.connectionStatus === ClinicConnectionStatus.ClinicRejected;
	}

	get patientRejectedClinic()
	{
		return this.connectionStatus === ClinicConnectionStatus.PatientRejected;
	}

	get pendingPatientConfirm()
	{
		return this.connectionStatus === ClinicConnectionStatus.PendingPatientConfirm;
	}

	get pendingClinicConfirm()
	{
		return this.connectionStatus === ClinicConnectionStatus.PendingClinicConfirm;
	}

	get isConnected()
	{
		return this.connectionStatus === ClinicConnectionStatus.Verified ||
			this.connectionStatus === ClinicConnectionStatus.Confirmed;
	}

	get noConnection()
	{
		return this.connectionStatus === ClinicConnectionStatus.NoLink;
	}

	get bookingRoute(): RouteLocationRaw
	{
		return {
			name: Route.Booking.Provider,
			params: {
				appointmentMethod: "clinic",
				clinicId: this.id,
			},
		};
	}

	get providerGroupBookingRoute(): RouteLocationRaw
	{
		return {
			name: Route.Booking.ProviderGroup,
			params: {
				appointmentMethod: "clinic",
				clinicId: this.id,
			},
		};
	}

	get addressDisplayFullLine()
	{
		const address = concatIfExists(" ", this.address1, this.address2);
		return concatIfExists(", ", address, this.addressCityDisplay);
	}

	get addressCityDisplay()
	{
		return concatIfExists(", ", this.city, this.provinceCode);
	}

	get postalCodeDisplay()
	{
		let postalCode = "";

		if (this.postalCode)
		{
			postalCode = this.postalCode.toUpperCase();
			postalCode = postalCode.replace("-", "");
			postalCode = postalCode.replace(" ", "");
			postalCode = postalCode.slice(0, 3) + " " + postalCode.slice(3);
		}

		return postalCode;
	}

	/* Instance property getters & setters */

	get id(): string
	{
		return this._id;
	}

	set id(value: string)
	{
		this._id = value;
	}

	get name(): string
	{
		return this._name;
	}

	set name(value: string)
	{
		this._name = value;
	}

	get iconPath(): string
	{
		return this._iconPath;
	}

	get provinceCode(): string
	{
		return this._provinceCode;
	}

	set provinceCode(value: string)
	{
		this._provinceCode = value;
	}

	get city(): string
	{
		return this._city;
	}

	set city(value: string)
	{
		this._city = value;
	}

	get postalCode(): string
	{
		return this._postalCode;
	}

	set postalCode(value: string)
	{
		this._postalCode = value;
	}

	get address1(): string
	{
		return this._address1;
	}

	set address1(value: string)
	{
		this._address1 = value;
	}

	get address2(): string
	{
		return this._address2;
	}

	set address2(value: string)
	{
		this._address2 = value;
	}

	get primaryPhone(): string
	{
		return this._primaryPhone;
	}

	set primaryPhone(value: string)
	{
		this._primaryPhone = value;
	}

	get about(): string
	{
		return this._about;
	}

	set about(value: string)
	{
		this._about = value;
	}

	get operatingHours(): OperatingHours
	{
		return this._operatingHours;
	}

	set operatingHours(value: OperatingHours)
	{
		this._operatingHours = value;
	}

	get connectionStatus(): ClinicConnectionStatus
	{
		return this._connectionStatus;
	}

	set connectionStatus(value: ClinicConnectionStatus)
	{
		this._connectionStatus = value;
	}

	get allowsUnconfirmedBooking(): boolean
	{
		return this._allowsUnconfirmedBooking;
	}

	set allowsUnconfirmedBooking(value: boolean)
	{
		this._allowsUnconfirmedBooking = value;
	}

	get bookingStatus(): ClinicBookingStatus
	{
		return this._bookingStatus;
	}

	set bookingStatus(value: ClinicBookingStatus)
	{
		this._bookingStatus = value;
	}

	get allowsDependentBooking(): boolean
	{
		return this._allowsDependentBooking;
	}

	set allowsDependentBooking(value: boolean)
	{
		this._allowsDependentBooking = value;
	}

	get allowsRemoteProfileCreation(): boolean
	{
		return this._allowsRemoteProfileCreation;
	}

	set allowsRemoteProfileCreation(value: boolean)
	{
		this._allowsRemoteProfileCreation = value;
	}

	get providerNames(): string[]
	{
		return this._providerNames;
	}

	set providerNames(value: string[])
	{
		this._providerNames = value;
	}

	get bookingNotes(): string
	{
		return this._bookingNotes;
	}

	set bookingNotes(value: string)
	{
		this._bookingNotes = value;
	}

	get primaryProvider(): ProviderTransfer
	{
		return this._primaryProvider;
	}

	set primaryProvider(value: ProviderTransfer)
	{
		this._primaryProvider = value;
	}

	get requiredFields(): PatientProfileField[]
	{
		return this._requiredFields;
	}

	set requiredFields(value: PatientProfileField[])
	{
		this._requiredFields = value;
	}

	get telehealthEnabled(): boolean
	{
		return this._telehealthEnabled;
	}

	get ebookingEnabled(): boolean
	{
		return this._ebookingEnabled;
	}

	get patientCanMessage(): boolean
	{
		return this._patientCanMessage;
	}

	set patientCanMessage(value: boolean)
	{
		this._patientCanMessage = value;
	}

	get allowSkippingProviderSelection(): boolean
	{
		return this._allowSkippingProviderSelection;
	}

	set allowSkippingProviderSelection(value: boolean)
	{
		this._allowSkippingProviderSelection = value;
	}

	get allowSkippingAppointmentTypeSelection(): boolean
	{
		return this._allowSkippingAppointmentTypeSelection;
	}

	set allowSkippingAppointmentTypeSelection(value: boolean)
	{
		this._allowSkippingAppointmentTypeSelection = value;
	}
}
