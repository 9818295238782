
	import {LOGO_DEPENDENTS} from "@/assets/AppIcons";

	export default {
		name: "DependentsHeaderBar",

		props: {
			headerText: {
				type: String,
				required: false,
			},
			ionicPadding: Boolean,
			hidden: Boolean,
		},

		data()
		{
			return {
				headerProps: {
					headerIcon: LOGO_DEPENDENTS,
					headerText: this.headerText ? this.headerText : this.$mhat("DependentsHeaderBar.HeaderText"),
				},
			};
		},
	};
