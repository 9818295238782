import AbstractConverter from "@/lib/conversion/AbstractConverter";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import {ChatItem, ChatItemType, MessageOrigin} from "@/lib/telehealth/models/telehealth.types";
import AuthStore from "@/lib/vuex/auth.store";
import {ChatMessageType} from "@/open_api/generated";

export default class ChatMessageToChatItemConverter extends AbstractConverter<ChatMessage, ChatItem>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: ChatMessage): ChatItem
	{
		return {
			id: from.id,
			type: from.messageType === ChatMessageType.Document ? ChatItemType.DOCUMENT : ChatItemType.MESSAGE,
			message: from.message,
			receivedAt: from.createdAt,
			messageOrigin: this.getMessageOrigin(from),
		};
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	// get the origin of a chat message
	protected getMessageOrigin(from: ChatMessage): MessageOrigin
	{
		if (!AuthStore.loggedInUser)
		{
			return from.senderId === null ? MessageOrigin.Sent : MessageOrigin.Received;
		}
		else
		{
			return from.senderId === AuthStore.loggedInUser.id ? MessageOrigin.Sent : MessageOrigin.Received;
		}
	}
}
