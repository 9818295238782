
	import {Options, Vue, Prop, Watch} from "vue-property-decorator";

	import {IMAGE_BRANDED} from "@/assets/AppIcons";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

	@Options({components: {}})
	export default class OrgImage extends Vue
	{
		@Prop({type: String}) public assetType: string;
		@Prop({type: String, default: "image"}) public atlText: string;
		@Prop({type: String, default: IMAGE_BRANDED}) public altImage: string;

		@Prop({type: String, default: "auto"}) public imageWidth: string;
		@Prop({type: String, default: "auto"}) public imageHeight: string;

		public created()
		{
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get assetUrl()
		{
			if (this.assetType)
			{
				const orgAsset = OrganizationStore.organizationAssets?.find((asset) => asset.asset_type === this.assetType);
				if (orgAsset)
				{
					return orgAsset.url;
				}
				return null;
			}
		}

		get imageStyle(): any
		{
			return {
				width: this.imageWidth,
				height: this.imageHeight,
			};
		}
	}
