import {RouteLocation as VueRoute, Router as VueRouter} from "vue-router";
import {HostType, RouteAuthType} from "@/router/types";
import DeviceInfo from "@/lib/DeviceInfo";
import {PublicAPI} from "@/lib/services/Api";
import {getUserLoginRoute, Route, userHomeRoute, allRoutes} from "@/router/router";
import AuthStore from "@/lib/vuex/auth.store";
import {ErrorCode, UserType} from "@/open_api/generated";
import {LoginRoute} from "@/router/patient_user/login.routes";
import MHABackendLogger from "@/lib/utils/MHABackendLogger";
import ConfigStore from "@/lib/vuex/modules/Config.store";
import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";
import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";

async function handlePatientRoute(next, to: VueRoute, from: VueRoute)
{
	if (AuthStore.isLoggedIn() && AuthStore.isPatient)
	{
		return next();
	}
	else
	{
		return handleAuthError(next, to, from);
	}
}

async function handleClinicUserRoute(next, to: VueRoute, from: VueRoute)
{
	if (AuthStore.isLoggedIn() && AuthStore.isClinic)
	{
		return next();
	}
	else
	{
		return handleAuthError(next, to, from);
	}
}

async function handleAuthRoute(next, to: VueRoute, from: VueRoute)
{
	if (await AuthStore.verifyLogin(to))
	{
		return next();
	}
	else
	{
		return handleAuthError(next, to, from);
	}
}

function handleAuthError(next, to: VueRoute, from: VueRoute)
{
	const {PatientUser, KioskUser, ClinicUser} = UserType;
	const crossFrameService = new CrossFrameCommunicationService();
	let name = Route.Login.Home;

	if ([PatientUser, ClinicUser, KioskUser].includes(to.meta.routeAuth as UserType))
	{
		name = getUserLoginRoute(to.meta.routeAuth as UserType);
	}

	if (crossFrameService.hasParentFrame)
	{
		// let any parent frames know we want to go back to the login page.
		crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateToLogin});

		next(false);
	}
	else
	{
		const route = Object.assign({}, to);
		route.name = name;

		route.query = {
			push_route: (to.name !== userHomeRoute[to.meta.routeAuth as UserType]) ? encodeURI(to.fullPath) : undefined,
			error_code: (AuthStore.isLoggedIn() || !from.name) ? undefined : ErrorCode.SessionExpired,
		};

		return next(route);
	}
}

function getAuthRoute(route: VueRoute): RouteAuthType
{
	let routeAuth: RouteAuthType = null;
	const authRecord = route.matched.find((record) => record.meta.routeAuth !== undefined);

	if (authRecord)
	{
		routeAuth = authRecord.meta.routeAuth as RouteAuthType;
	}

	return routeAuth;
}

function isRestrictedRoute(route: VueRoute)
{
	let isRestricted = false;
	const restrictedRoute = route.matched.find(({meta}) => meta.restrictHosts !== undefined);

	if (restrictedRoute)
	{
		const restrictedHosts = restrictedRoute.meta.restrictHosts as string[];
		if (DeviceInfo.isCloudMd)
		{
			isRestricted = restrictedHosts.includes(HostType.CLOUDMD);
		}
		else
		{
			isRestricted = restrictedHosts.includes(HostType.MYHEALTHACCESS);
		}
	}

	return isRestricted;
}

function defaultRouterOnComplete(response: any): void
{
}

function defaultRouterOnAbort(error?: any): void
{
	if (error)
	{
		// not real error, occurs when routing to current location
		if (error.name === "NavigationDuplicated")
		{
			console.info(error.message);
			MHABackendLogger.info(error);
		}
		else
		{
			console.warn(error.message);
			MHABackendLogger.warn(error);
		}
	}
	else // assume a router push error and ignore
	{
		const message = "undefined error - assumed router redirect rejection";
		console.warn(message);
		MHABackendLogger.warn(message);
	}
}

export const authenticate = (router: VueRouter) =>
{
	// while logged in, redirect on these pages
	const loggedInRedirects = new Map<string, string>();
	loggedInRedirects.set(Route.Login.Home, Route.Home);
	loggedInRedirects.set(Route.Login.Confirm, Route.Home);
	loggedInRedirects.set(Route.Login.Forgot, Route.Home);
	loggedInRedirects.set(Route.Login.Reset, Route.Home);
	loggedInRedirects.set(Route.Signup.Form, Route.Home);
	loggedInRedirects.set(Route.Signup.Final, Route.Home);

	loggedInRedirects.set(Route.Public.Organization.Login, Route.odbBookingRoute.Redirect);
	loggedInRedirects.set(Route.Public.Organization.Signup, Route.odbBookingRoute.Redirect);
	loggedInRedirects.set(Route.Public.Organization.Forgot, Route.odbBookingRoute.Redirect);
	loggedInRedirects.set(Route.Public.Organization.Confirm, Route.odbBookingRoute.Redirect);
	loggedInRedirects.set(Route.Public.Organization.Reset, Route.odbBookingRoute.Redirect);

	/* due to many ionic components calling router.push without handing errors that occur from redirection,
	 we wrap the original router push function in some custom error handling. */
	const originalPush = router.push;
	const originalReplace = router.replace;

	router.push = async function push(
		location: VueRoute,
		onResolve: (response?) => void = defaultRouterOnComplete,
		onReject: (error?) => void = defaultRouterOnAbort): Promise<any>
	{
		return await originalPush.call(this, location, onResolve, onReject);
	};
	router.replace = async function replace(
		location: VueRoute,
		onResolve: (response?) => void = defaultRouterOnComplete,
		onReject: (error?) => void = defaultRouterOnAbort): Promise<any>
	{
		return await originalReplace.call(this, location, onResolve, onReject);
	};

	router.beforeEach(async (to, from, next) =>
	{
		await AuthStore.verifyLogin(to);
		const authRoute: RouteAuthType = getAuthRoute(to);

		// TODO: isRestrictedRoute should check if the route user type matches the logged in user. Navigate to 403 error page
		if (isRestrictedRoute(to))
		{
			if (AuthStore.loggedInUser)
			{
				return next({name: AuthStore.loggedInUser.route.Home});
			}
			else
			{
				return next({name: LoginRoute.Home});
			}
		}

		// check if cookies are enabled. If not go to error page.
		if (!navigator.cookieEnabled)
		{
			if (to.name !== Route.Public.CookiesNotEnabled)
			{
				return next({name: Route.Public.CookiesNotEnabled});
			}
			else
			{
				return next();
			}
		}

		if (AuthStore.isLoggedIn())
		{
			const pushRoute: any = to.query.push_route;

			// If you're coming from outside MHA, go to the push route
			if (pushRoute && !from.name && to.name === AuthStore.loggedInUser.route.Home)
			{
				return next({path: pushRoute});
			}

			// if you hit one of the specified pages while logged in, redirect
			if (to.meta.routeAuth === RouteAuthType.NoAuth && loggedInRedirects.has(to.name as string) && AuthStore.isPatient)
			{
				return next({
					name: loggedInRedirects.get(to.name as string),
					params: to.params,
					query: to.query,
				});
			}
		}

		switch (authRoute)
		{
			case RouteAuthType.Patient:
				return await handlePatientRoute(next, to, from);
			case RouteAuthType.ClinicUser:
				return await handleClinicUserRoute(next, to, from);
			case RouteAuthType.Authenticated:
				return await handleAuthRoute(next, to, from);
			default:
				return next();
		}
	});

	// check api version when changing routes. If different, reload.
	if (process.env.NODE_ENV !== "development")
	{
		router.beforeEach((to, from, next) =>
		{
			PublicAPI().getVersion().then(
				(result) =>
				{
					if (result && DeviceInfo.appVersion !== result.data.version)
					{
						location.reload();
					}
				},
				(error) =>
				{

				},
			);
			next();
		});
	}

	return router;
};
