
	import {BasicListType} from "../../lib/types/ListType";

	export default {
		name: "ButtonList",

		props: {
			list: Array as () => BasicListType[],
			onClick: Function,
			primary: Boolean,
			danger: Boolean,
			warning: Boolean,
			info: Boolean,
		},
	};
