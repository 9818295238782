import DeviceInfo from "@/lib/DeviceInfo";
import { fbInitPromise } from "@/lib/FB/FBInitialization";

export enum FB_CUSTOM_EVENT {
	CLOUDMD_LANDING_PAGE_SIGNUP_COMPLETE = "cloudmd_landing_page_signup_complete",
	CLOUDMD_LANDING_PAGE_VIEW = "cloudmd_landing_page_view",
	ROUTE_TO_SNAP_CLARITY = "route_to_snap_clarity",
}

export enum FB_CONTENT_TYPES {
	WEB_PAGE,
}

export default class FBService
{
	public static async logFBEvent(eventName: string, params: any)
	{
		this.fbLogWrapper(() =>
		{
			// @ts-ignore
			FB.AppEvents.logEvent(eventName, null, params);
		});
	}

	public static logRouteToSnapClarity()
	{
		this.logFBEvent(FB_CUSTOM_EVENT.ROUTE_TO_SNAP_CLARITY, {});
	}

	// log an event indicating that a user has viewed the cloudmd landing page
	public static logCloudMdLandingPageView(): void
	{
		this.logFBEvent(FB_CUSTOM_EVENT.CLOUDMD_LANDING_PAGE_VIEW, {});
	}

	// log an event indicating that a user has completed signup via the cloudmd landing page.
	public static logCloudMdLandingPageSignupComplete(): void
	{
		this.logFBEvent(FB_CUSTOM_EVENT.CLOUDMD_LANDING_PAGE_SIGNUP_COMPLETE, {});
	}

	public static async logViewContent()
	{
		this.fbLogWrapper(() =>
		{
			const params = {};
			// @ts-ignore
			params[FB.AppEvents.ParameterNames.CONTENT_TYPE] = window.location.href;
			// @ts-ignore
			params[FB.AppEvents.ParameterNames.CONTENT_ID] = FB_CONTENT_TYPES.WEB_PAGE;

			// @ts-ignore
			this.logFBEvent(FB.AppEvents.EventNames.VIEWED_CONTENT, params);
		});
	}

	public static async logRegistrationComplete()
	{
		this.fbLogWrapper(() =>
		{
			const params = {};
			// @ts-ignore
			params[FB.AppEvents.ParameterNames.REGISTRATION_METHOD] = "signup";
			// @ts-ignore
			this.logFBEvent(FB.AppEvents.EventNames.COMPLETED_REGISTRATION, params);
		});
	}

	private static async fbLogWrapper(logFunc: () => void)
	{
		if (DeviceInfo.isCloudMd && DeviceInfo.isProduction())
		{
			try
			{
				await fbInitPromise;

				// @ts-ignore
				if (typeof FB !== "undefined")
				{
					logFunc();
				}
			}
			catch (e)
			{
				console.error("FB event log error: " + e);
			}
		}
	}
}
