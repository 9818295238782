
	export default {
		name: "FooterBar",

		props: {
			single: Boolean,
			full: Boolean,
			two: Boolean,
		},
	};
