import {ClinicsRoutes} from "@/router/patient_user/clinics.routes";
import {DependentClinicsRoute} from "@/router/patient_user/dependentClinics.routes";
import {getRouteOrWhitelabelRoute} from "@/router/whitelableUtil";
import {ClinicsRouteCollection} from "@/router/patient_user/ClinicsRouteCollection";

export class DependentClinicsRouteCollection
{
	// ==========================================================================
	// Singleton impl
	// ==========================================================================

	private static instance: DependentClinicsRouteCollection;

	public static getInstance(): DependentClinicsRouteCollection
	{
		if (!this.instance)
		{
			this.instance = new DependentClinicsRouteCollection();
		}
		return this.instance;
	}

	private constructor()
	{
	}

	// ==========================================================================
	// Routes
	// ==========================================================================

	get FindClinic(): string
	{
		return getRouteOrWhitelabelRoute(DependentClinicsRoute.FindClinic);
	}

	get FindParentClinics(): string
	{
		return getRouteOrWhitelabelRoute(DependentClinicsRoute.FindParentClinics);
	}
}

export const dependentClinicsRouteCollection = DependentClinicsRouteCollection.getInstance();
