import {RouteLocationNormalized, Router} from "vue-router";
import Vue from "vue";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

export default function setOrganizationGuards(router: Router): Router
{
	// if we route to a branded route load organization.
	router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) =>
	{
		if (to.params.organizationId && to.meta.brandedRoute)
		{
			await OrganizationStore.loadOrganization(to.params.organizationId as string);
		}

		next();
	});

	// if we are leaving a branded route clear branding
	router.afterEach((to, from) =>
	{
		if (!to.meta.brandedRoute)
		{
			OrganizationStore.clearOrganization();
		}
	});

	return router;
}
