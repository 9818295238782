

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {BasicListType} from "@/lib/types/ListType";
	import {IonList, IonItem, IonLabel} from "@ionic/vue";

	@Options({components: {IonList, IonItem, IonLabel}})
	export default class BasicList extends Vue
	{
		@Prop({type: Array}) list: BasicListType[];
		@Prop({type: Function}) onClick: () => void;
		@Prop({type: String}) iconClassLeft: string;
		@Prop({type: String}) iconClassRight: string;
		@Prop({type: Boolean}) iconSizeLarge: boolean;
		@Prop({type: Boolean}) primary: boolean;
		@Prop({type: Boolean}) danger: boolean;
		@Prop({type: Boolean}) warning: boolean;
		@Prop({type: Boolean}) info: boolean;
		@Prop({type: Boolean}) transparent: boolean;
		@Prop({type: Boolean}) borderless: boolean;
	}
