import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6178ae46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mha toggle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_InputLayout = _resolveComponent("InputLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputLayout, _mergeProps(_ctx.$attrs, {
      disabled: $props.disabled,
      compact: ""
    }), {
      default: _withCtx(() => [
        _createVNode(_component_ion_toggle, {
          checked: $props.status,
          onIonChange: $options.onClick
        }, null, 8, ["checked", "onIonChange"])
      ]),
      _: 1
    }, 16, ["disabled"])
  ]))
}