import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "booking-content" }
const _hoisted_2 = { class: "mha text italic-font small separator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, {
        "header-text": this.$mhat('BookingProviderGroup.HeaderText'),
        noBack: !_ctx.hasBackButtons,
        hasClose: _ctx.hasClose
      }, null, 8, ["header-text", "noBack", "hasClose"])
    ]),
    _: 2
  }, [
    (!_ctx.showSpinner)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            (_ctx.isDependentMode)
              ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                  key: 0,
                  dependent: _ctx.controller.dependent
                }, null, 8, ["dependent"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", null, _toDisplayString(this.$mhat("BookingProviderGroup.WhichCareGroupText")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.providerGroups, (providerGroup) => {
                return (_openBlock(), _createBlock(_component_BaseButton, {
                  key: providerGroup.id,
                  onClick: () => _ctx.setSelected(providerGroup)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(providerGroup.groupName), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128)),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$mhat("BookingProviderGroup.Or")), 1),
              _createVNode(_component_RouteButton, {
                route: _ctx.providerRoute()
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$mhat("BookingProviderGroup.SearchByProviderText")), 1)
                ]),
                _: 1
              }, 8, ["route"])
            ])
          ])
        }
      : {
          name: "content-center",
          fn: _withCtx(() => [
            _createVNode(_component_EventStateSpinner, {
              "is-loading": _ctx.isLoading,
              "loading-icon": "icon-user-md",
              "loading-title": _ctx.loadingMessage
            }, null, 8, ["is-loading", "loading-title"])
          ])
        }
  ]), 1024))
}