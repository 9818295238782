import {HostType, RouteAuthType} from "@/router/types";
import uuidv4 from "uuid/v4";
import {RouteRecordRaw} from "vue-router";

const SignupPage = () => import(
	/* webpackChunkName: "SignupPatient" */ "@/views/patient_user/login/SignupPage.vue");
const SignupPageBranded = () => import(
	/* webpackChunkName: "SignupPatient" */ "@/views/patient_user/login/SignupPageBranded.vue");

export enum SignupRoute
{
	Form = "SignupPage",
	BrandedForm = "BrandedSignupPage",
	Final = "SignupConfirmed",
}

export const signupRoutes: RouteRecordRaw[] = [
	{
		path: "/login/signup",
		name: SignupRoute.Form,
		component: SignupPage,
		meta: {
			routeId: uuidv4(),
			routeAuth: RouteAuthType.NoAuth,
		},
	},
	{
		path: "/branded/:clinicName/signup",
		name: SignupRoute.BrandedForm,
		component: SignupPageBranded,
		props: true,
		meta: {
			routeId: uuidv4(),
			routeAuth: RouteAuthType.NoAuth,
			restrictHosts: [HostType.MYHEALTHACCESS], // TODO - remove when we want to allow branded signup in MHA
		},
	},
];
