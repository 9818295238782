import {RouteAuthType} from "@/router/types";
import uuidv4 from "uuid/v4";
import {RouteRecordRaw} from "vue-router";

const LoginPage = () => import(/* webpackChunkName: "PatientLogin" */ "@/views/patient_user/login/LoginPage.vue");
const LoginForgot = () => import(/* webpackChunkName: "PatientLogin" */ "@/views/patient_user/login/LoginForgot.vue");
const LoginReset = () => import(/* webpackChunkName: "PatientLogin" */ "@/views/patient_user/login/LoginReset.vue");
const LoginConfirm = () => import(/* webpackChunkName: "PatientLogin" */ "@/views/patient_user/login/LoginConfirm.vue");

export enum LoginRoute
{
	Home = "LoginPage",
	Forgot = "LoginForgot",
	Reset = "LoginReset",
	Confirm = "LoginConfirm",
	PharmacyLogin = "PharmacyLogin",
}

export const loginRoutes: RouteRecordRaw[] = [
	{
		path: "/pharmacy/:urlFriendlyName/login",
		name: LoginRoute.PharmacyLogin,
		props: true,
		component: LoginPage,
		meta: {
			routeId: uuidv4(),
			routeAuth: RouteAuthType.NoAuth,
		},
	},
	{
		path: "/login",
		name: LoginRoute.Home,
		component: LoginPage,
		meta: {
			routeId: uuidv4(),
			routeAuth: RouteAuthType.NoAuth,
			props: true,
			brandedRoute: true,
		},
	},
	{
		path: "/login/forgot",
		name: LoginRoute.Forgot,
		component: LoginForgot,
		meta: {
			routeId: uuidv4(),
			routeAuth: RouteAuthType.NoAuth,
			props: true,
			brandedRoute: true,
		},
	},
	{
		path: "/login/reset",
		name: LoginRoute.Reset,
		component: LoginReset,
		meta: {
			routeId: uuidv4(),
			props: true,
			brandedRoute: true,
		},
	},
	{
		path: "/login/confirm",
		name: LoginRoute.Confirm,
		component: LoginConfirm,
		meta: {
			routeId: uuidv4(),
			props: true,
			brandedRoute: true,
		},
	},
];
