import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {LoginToken} from "@/lib/models/LoginToken";
import {PatientUser} from "@/lib/patient/patientUser.model";
import {KioskUser} from "@/lib/kiosk/kioskUser.model";
import {ClinicUser} from "@/lib/clinic/clinicUser.model";
import {ClinicAPI, KioskAPI, PatientAPI} from "@/lib/services/Api";
import {UserType} from "@/open_api/generated";
import store from "@/lib/vuex/store";
import router, {Route} from "@/router/router";
import {deleteCookie, getCookies} from "@/lib/utils/cookies";
import {RouteLocation as VueRoute} from "vue-router";
import jwtDecode, {JwtPayload} from "jwt-decode";
import ConfigStore from "@/lib/vuex/modules/Config.store";
import KeycloakStore from "@/lib/vuex/modules/KeycloakStore";

export type UserModel = PatientUser | ClinicUser | KioskUser;
export const LOGIN_TOKEN_KEY = "login_token";
export const REFRESH_TOKEN_KEY = "refresh_token";
export const keycloakStore = KeycloakStore;

@Module({dynamic: true, store, name: "authStore", namespaced: true})
class AuthStore extends VuexModule
{
	protected _loginToken: LoginToken = null;
	protected _loggedInUser: UserModel = null;

	@Action({rawError: true})
	public async fetchLoggedInUser(): Promise<UserModel>
	{
		try
		{
			const loggedInUser = await fetchUser(this.loginToken.userType);
			this.setLoggedInUser(loggedInUser);
			return loggedInUser;
		}
		catch (error)
		{
			console.log(error);
		}
	}

	/** Verify login token. Login token will be pulled from 3 locations in the following order.
	 * 1. query param (if you provide the currentRoute argument)
	 * 2. local storage
	 * 3. cookie
	 * @param currentRoute - if provided and there is not login token in cookies or local storage
	 * then the query params will be searched for a login token.
	 * @return promise that resolves to true / false indicating if a valid login token has been found.
	 */
	@Action({rawError: true})
	public async verifyLogin(currentRoute: VueRoute = null): Promise<boolean>
	{
		if (Object.keys(ConfigStore.configConstants).length === 0)
		{
			await ConfigStore.fetchAll();
		}

		if (this.loginToken && looksLikeFusionToken(this.loginToken.tokenStr))
		{
			const keycloakAccessToken = await getKeycloakAccessToken(this.loginToken.tokenStr);
			localStorage.setItem(LOGIN_TOKEN_KEY, keycloakAccessToken);
			this.setLoginToken(new LoginToken(keycloakAccessToken));
		}

		if (!this.loginToken)
		{
			try
			{
				let token: string = null;
				let refreshToken: string = null;

				// check for login token query param
				if (currentRoute)
				{
					token = currentRoute.query.login_token as string;
					refreshToken = currentRoute.query.refresh_token as string;

					// store in local storage for subsequent uses
					if (token)
					{
						if (looksLikeFusionToken(token))
						{
							localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
							token = await getKeycloakAccessToken(token);
						}

						localStorage.setItem(LOGIN_TOKEN_KEY, token);
					}
				}

				// if no query param token check local storage
				if (!token)
				{
					token = localStorage.getItem(LOGIN_TOKEN_KEY);

					// if not local storage token check cookie
					if (!token)
					{
						token = getCookies(document)[LOGIN_TOKEN_KEY];
					}
				}

				if (looksLikeFusionToken(token))
				{
					token = await getKeycloakAccessToken(token);
				}

				this.setLoginToken(new LoginToken(token));
			}
			catch (error)
			{
				return false;
			}
		}

		if (this.isLoggedIn() && !this.loggedInUser)
		{
			await this.fetchLoggedInUser();
		}

		return this.isLoggedIn() && Boolean(this.loggedInUser);
	}

	@Action({rawError: true})
	public async logout(): Promise<void>
	{
		const name = Route.loginPageByUserType(this.loggedInUser.userType);

		try
		{
			deleteCookie(LOGIN_TOKEN_KEY);
			localStorage.removeItem(LOGIN_TOKEN_KEY);
			this.clearLoggedInUser();
			this.clearLoginToken();
		}
		catch (error)
		{
			console.error(error);
		}

		await router.push({ name });
	}

	@Mutation
	public setLoggedInUser(user: UserModel)
	{
		this._loggedInUser = user;
	}

	@Mutation
	public setLoginToken(loginToken: LoginToken)
	{
		this._loginToken = loginToken;
	}

	@Mutation
	public clearLoginToken()
	{
		this._loginToken = null;
	}

	@Mutation
	public clearLoggedInUser()
	{
		this._loggedInUser = null;
	}

	@Action({rawError: true})
	public async getLoggedInStatus(): Promise<boolean>
	{
		return Boolean(this.loginToken && !this.loginToken.isExpired);
	}

	public get loggedInUser(): UserModel
	{
		return this._loggedInUser;
	}

	public get loggedInPatient(): PatientUser
	{
		return this.loggedInUser as PatientUser;
	}

	public get loggedInClinic(): ClinicUser
	{
		return this.loggedInUser as ClinicUser;
	}

	public get loggedInKiosk(): KioskUser
	{
		return this.loggedInUser as KioskUser;
	}

	public get loginToken(): LoginToken
	{
		return this._loginToken;
	}

	public get isLoggedIn(): () => boolean
	{
		return () =>
		{
			return Boolean(this.loginToken && !this.loginToken.isExpired);
		};
	}

	public get isPatient(): boolean
	{
		return this.loggedInUser?.isPatient;
	}

	public get isClinic(): boolean
	{
		return this.loggedInUser?.isClinic;
	}

	public get isKiosk(): boolean
	{
		return this.loggedInUser?.isKiosk;
	}
}

export default getModule(AuthStore);

async function fetchUser(userType: UserType): Promise<UserModel>
{
	switch (userType)
	{
		case UserType.PatientUser:
			const pResponse = await PatientAPI().loggedInPatient();
			return PatientUser.fromDto(pResponse.data.user, pResponse.data.login_token);
		case UserType.ClinicUser:
			const cResponse = await ClinicAPI().loggedInClinicUser();
			return ClinicUser.fromDto(cResponse.data.user, cResponse.data.login_token);
		case UserType.KioskUser:
			const kResponse = await KioskAPI().loggedInKiosk();
			return KioskUser.fromDto(kResponse.data.user, kResponse.data.login_token);
	}
}

async function getKeycloakAccessToken(accessToken: string): Promise<string>
{
	try
	{
		if (!keycloakStore.keycloak)
		{
			await keycloakStore.loadKeycloak();
			await keycloakStore.initializeKeycloak({accessToken: accessToken, refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY)});
		}

		const tokenRefreshed = await keycloakStore.keycloak.updateToken(60);

		if (tokenRefreshed)
		{
			accessToken = keycloakStore.keycloak.token;
		}
	}
	catch (error)
	{
		console.log("Failed to refresh the token, or the session has expired", error);
	}

	return accessToken;
}

function looksLikeFusionToken(token: string): boolean
{
	const decodedToken = jwtDecode<JwtPayload>(token);

	return decodedToken && decodedToken.iss && decodedToken.iss === ConfigStore.configConstants.fusion_keycloak_iss;
}
