import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {UserType} from "@/open_api/generated";
import {authenticate} from "@/router/middleware/authenticate";
// Route Modules
import {publicRoutes, PublicRouteType} from "@/router/public";
import {patientUserRoutes, PatientUserRouteType} from "@/router/patient_user";
import {kioskUserRoutes, KioskUserRouteType} from "@/router/kiosk_user";
import {clinicUserRoutes, ClinicUserRouteType} from "@/router/clinic_user";
import {AppRouteCollection} from "@/router/AppRouteCollection";
import {generateWhitelabelRoutes} from "@/router/whitelableUtil";
import setOrganizationGuards from "@/router/guards/global/organizationGuards";
import {sync} from "vuex-router-sync";
import store from "@/lib/vuex/store";

// base path of the router
export const ROUTER_BASE_PATH = "/patient/#/";

export type RouteType = PublicRouteType | PatientUserRouteType | KioskUserRouteType | ClinicUserRouteType;

// primary app route list
export const Route = new AppRouteCollection();

// get the login route appropriate for the type of user.
export function getUserLoginRoute(userType: UserType): string
{
	switch (userType)
	{
		case UserType.ClinicUser:
			return Route.ClinicUser.Login;
		case UserType.KioskUser:
			return Route.Kiosk.Home;
		default:
			return Route.Login.Home;
	}
}

export const userHomeRoute = {
	[UserType.PatientUser]: Route.Home,
	[UserType.ClinicUser]: Route.ClinicUser.Home,
	[UserType.KioskUser]: Route.Kiosk.Home,
};

const routes: RouteRecordRaw[] = [
	...patientUserRoutes,
	...publicRoutes,
	...clinicUserRoutes,
	...kioskUserRoutes,
];

// generate white label routes
const orgRoutes: RouteRecordRaw[] = generateWhitelabelRoutes(routes);

// combine all routes
const fullRouteList: RouteRecordRaw[] = routes.concat(orgRoutes);

export const allRoutes = fullRouteList.map((route) => route.name);

const router = authenticate(setOrganizationGuards(createRouter({
	routes: fullRouteList,
	history: createWebHistory(ROUTER_BASE_PATH),
})));

// track router state in vuex store
sync(store, router);

export default router;
