import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import PatientClinicService from "@/lib/clinics/PatientClinic.service";
import NotInWhitelabelError from "@/lib/organization/error/NotInWhitelabelError";
import NoPrimaryClinicError from "@/lib/organization/error/NoPrimaryClinicError";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import {ErrorCode} from "@/open_api/generated";

export default class OrganizationService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * connect the logged in patient to the currently applied whitelabel primary clinic.
	 * @throws NotInWhitelabelError - if this method is called when no whitelabel is applied.
	 * @throws NoPrimaryClinicError - if the organization currently applied at the time of call has no primary clinic set.
	 */
	public async connectToCurrentOrganizationPrimaryClinic(): Promise<void>
	{
		const patientClinicService = new PatientClinicService();
		const org = OrganizationStore.organizationProfile;

		if (org?.primary_clinic_id)
		{
			if (!(await patientClinicService.isConnectedToClinic(org.primary_clinic_id)))
			{
				await patientClinicService.connectToClinic(org.primary_clinic_id);
			}
		}
		else
		{
			if (!org)
			{
				throw new NotInWhitelabelError("Cannot connect patient to whitelabel clinic. Not currently in whitelabel");
			}
			else
			{
				throw new NoPrimaryClinicError(`The current whitelabel [${org.id}] has no primary clinic configured`);
			}
		}
	}
}
