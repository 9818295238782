

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class WhitelabelHeaderBar extends Vue
	{
		@Prop({type: String, required: true}) organizationId: string;
		@Prop({type: String, default: null}) headerText: string;
		@Prop({type: String, default: null}) icon: string;
		@Prop({type: Boolean}) noBack: boolean;
		@Prop({type: [String, Object, Boolean]}) hasClose: string | boolean | any;
		@Prop({type: Object}) buttonOpts: any;

		// ==========================================================================
		// Getters
		// ==========================================================================

		get backRoute(): string
		{
			return "/org/" + this.organizationId;
		}
	}
