import {PermissionType, Plugins} from "@capacitor/core";
import DeviceInfo from "@/lib/DeviceInfo";

export enum MediaStreamErrorName
{
	NOT_FOUND_ERROR = "NotFoundError",
	NOT_ALLOWED_ERROR = "NotAllowedError",
	ABORT_ERROR = "AbortError",
}

export enum MediaDeviceStatus
{
	GRANTED = "Granted",
	NOT_FOUND = "NotFoundError",
	NOT_ALLOWED = "NotAllowedError",
	NOT_SUPPORTED = "NotSupportedError",
	ABORT_ERROR = "AbortError",
	UNKNOWN = "UnknownError",
}

export enum MediaDeviceType
{
	AUDIO = "audioinput",
	VIDEO = "videoinput",
}

export interface MediaDevicePermissions
{
	audio: MediaDeviceStatus;
	video: MediaDeviceStatus;
}

/**
 * Whether or not microphone/camera usage is supported
 */
export function getMediaSupport(): boolean
{
	return Boolean(navigator.mediaDevices);
}

/**
 * Return an array of available audio/video input devices. Omit deviceType to return all devices
 * @param deviceType
 */
export async function getMediaDevices(deviceType?: MediaDeviceType): Promise<MediaDeviceInfo[]>
{
	const devices = await navigator.mediaDevices.enumerateDevices();
	return deviceType ? devices.filter((device) => device.kind === deviceType) : devices;
}

async function getDeviceStatus(deviceType?: MediaDeviceType): Promise<MediaDeviceStatus>
{
	const constraints = {audio: true, video: true};

	if (deviceType === MediaDeviceType.AUDIO)
	{
		delete constraints.video;
	}
	else if (deviceType === MediaDeviceType.VIDEO)
	{
		delete constraints.audio;
	}

	try
	{
		const stream = await navigator.mediaDevices.getUserMedia(constraints);
		stream.getTracks().forEach((track) => track.stop());
		return MediaDeviceStatus.GRANTED;
	}
	catch (err)
	{
		switch (err.name)
		{
			case MediaStreamErrorName.NOT_ALLOWED_ERROR:
				return MediaDeviceStatus.NOT_ALLOWED;
			case MediaStreamErrorName.NOT_FOUND_ERROR:
				return MediaDeviceStatus.NOT_FOUND;
			case MediaStreamErrorName.ABORT_ERROR:
				return MediaDeviceStatus.ABORT_ERROR;
			default:
				return MediaDeviceStatus.UNKNOWN;
		}
	}
}

/**
 *
 */
export async function getAudioDeviceStatus(): Promise<MediaDeviceStatus>
{
	return await getDeviceStatus(MediaDeviceType.AUDIO);
}

export async function getVideoDeviceStatus(): Promise<MediaDeviceStatus>
{
	return await getDeviceStatus(MediaDeviceType.VIDEO);
}

export async function getMediaDevicePermissions(): Promise<MediaDevicePermissions>
{
	let audio = MediaDeviceStatus.NOT_ALLOWED;
	let video = MediaDeviceStatus.NOT_ALLOWED;

	if (DeviceInfo.isIOS())
	{
		// TODO Figure out why old builds don't have access to Permissions plugin.. catch as temp fix
		const cameraPermissions = await Plugins.Permissions.query({ name: PermissionType.Camera }).catch(() => null);

		if (cameraPermissions?.state === "granted")
		{
			audio = MediaDeviceStatus.GRANTED;
			video = MediaDeviceStatus.GRANTED;
		}
	}
	else
	{
		audio = await getAudioDeviceStatus();
		video = await getVideoDeviceStatus();
	}

	return { audio, video };
}
