

	import {Options, Vue, Prop} from "vue-property-decorator";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

	@Options({})
	export default class BannerNotificationItem extends Vue
	{
		@Prop({type: String, default: "icon-check"}) iconClass: string;
		@Prop({type: String}) title: string;
		@Prop({type: String}) message: string;
		@Prop({type: Boolean, default: false}) hasButtons: boolean;

		@Prop({type: Boolean, default: false}) primary: boolean;
		@Prop({type: Boolean, default: false}) warning: boolean;
		@Prop({type: Boolean, default: false}) danger: boolean;

		public whitelabel: boolean = OrganizationStore.hasOrganization;
	}
