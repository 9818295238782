/**
 * lighten or darken a hex color
 * @param color - color to be darkened / lightened
 * @param amount - the amount
 * @return - the lightened / darkened color
 */
export function lightenDarkenColor(color, amount)
{
	let usePound = false;

	if (color[0] === "#")
	{
		color = color.slice(1);
		usePound = true;
	}

	const num = parseInt(color, 16);

	const r = Math.max(Math.min((num >> 16) + amount, 255), 0);
	const b = Math.max(Math.min(((num >> 8) & 0x00FF) + amount, 255), 0);
	const g = Math.max(Math.min((num & 0x0000FF) + amount, 255), 0);

	return (usePound ? "#" : "") + ("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);
}

/**
 * override a specific color variable
 * @param color - the color to apply
 * @param colorVariable - the variable to override
 */
export function overrideColor(color: string, colorVariable: string): void
{
	const root = document.querySelector("#app .style-override") as HTMLElement;
	root.style.setProperty(colorVariable, `#${color}`);
}

/** override a standard color range (lightest - darkest)
 * @param color - the color to use
 * @param colorVariablePrefix - the color variable prefix Ex "--color-primary"
 * @protected
 */
export function setColorRange(color: string, colorVariablePrefix: string): void
{
	const root = document.querySelector("#app .style-override") as HTMLElement;
	root.style.setProperty(`${colorVariablePrefix}-darkest`,
		`#${lightenDarkenColor(color, -75)}`);
	root.style.setProperty(`${colorVariablePrefix}-darker`,
		`#${lightenDarkenColor(color, -50)}`);
	root.style.setProperty(`${colorVariablePrefix}-dark`,
		`#${lightenDarkenColor(color, -25)}`);
	root.style.setProperty(`${colorVariablePrefix}-base`,
		`#${color}`);
	root.style.setProperty(`${colorVariablePrefix}-light`,
		`#${lightenDarkenColor(color, 25)}`);
	root.style.setProperty(`${colorVariablePrefix}-lighter`,
		`#${lightenDarkenColor(color, 50)}`);
	root.style.setProperty(`${colorVariablePrefix}-lightest`,
		`#${lightenDarkenColor(color, 75)}`);
}

/**
 * clear a color variable override.
 * @param colorVariable - the variable to clear the override for
 */
export function clearColorOverride(colorVariable: string): void
{
	const root = document.querySelector("#app .style-override") as HTMLElement;
	root.style.setProperty(colorVariable, "unset");
}

/**
 * clear any color overrides set on a color range
 * @param colorVariablePrefix - color variable prefix to clear. Ex: "--color-primary"
 */
export function clearColorRangeOverride(colorVariablePrefix: string): void
{
	const root = document.querySelector("#app .style-override") as HTMLElement;
	root.style.setProperty(`${colorVariablePrefix}-darkest`, "unset");
	root.style.setProperty(`${colorVariablePrefix}-darker`, "unset");
	root.style.setProperty(`${colorVariablePrefix}-dark`, "unset");
	root.style.setProperty(`${colorVariablePrefix}-base`, "unset");
	root.style.setProperty(`${colorVariablePrefix}-light`, "unset");
	root.style.setProperty(`${colorVariablePrefix}-lighter`, "unset");
	root.style.setProperty(`${colorVariablePrefix}-lightest`, "unset");
}
