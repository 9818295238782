import {RouteRecordRaw} from "vue-router";
import KioskTelehealth from "@/views/kiosk_user/KioskTelehealth.vue";

export enum KioskTelehealthRoute
{
	Telehealth = "Telehealth",
}

export const telehealthRoutes: RouteRecordRaw[] = [
	{
		path: "/kiosk/:kioskLocation?/patient/:patientId/appointment/:appointmentId/telehealth",
		name: KioskTelehealthRoute.Telehealth,
		props: true,
		component: KioskTelehealth,
	},
];
