

	import {Options, Vue, Prop} from "vue-property-decorator";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {alertController} from "@ionic/vue";

	@Options({
		components: {AppChip},
	})
	export default class VideoViewTopControls extends Vue
	{
		@Prop({type: String, default: "00:00"}) callTime: string;
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public onExit()
		{
			alertController
				.create({
					header: "Leave Appointment",
					message: "Are you sure you want to end this appointment?",
					buttons: [
						{
							text: "Decline",
						},
						{
							text: "Accept",
							handler: () => this.$emit("exit"),
						},
					],
				})
				.then((alertEl) =>
				{
					alertEl.present();
				});
		}
	}
