

	import {Options, Vue, Prop} from "vue-property-decorator";

	import {Mail} from "@/lib/Mailbox/Mail.model";
	import {BasicListType} from "@/lib/types/ListType";
	import DeviceInfo from "@/lib/DeviceInfo";
	import {Capacitor, Plugins} from "@capacitor/core";
	import {MailAttachment} from "@/open_api/generated";
	import {FileSharerPlugin} from "@byteowls/capacitor-filesharer";
	import FileUtil from "@/lib/utils/FileUtil";

	@Options({})
	export default class AttachmentViewer extends Vue
	{
		@Prop({type: Object}) mail: Mail;

		public onAttachmentLinkClick(attachment: MailAttachment)
		{
			const downloadFile = async (plugin: FileSharerPlugin) =>
			{
				plugin.share({
					filename: attachment.name,
					base64Data: await FileUtil.getAuthenticatedFileAsBase64(attachment.url),
					contentType: attachment.type,
				});
			};

			const downloadUnavailable = () =>
			{
				alert("File downloads seem to be unavailable. Please update or use a different platform.");
			};

			if (DeviceInfo.isIOS())
			{
				if (Capacitor.isPluginAvailable("FileSharerPlugin"))
				{
					downloadFile(Plugins.FileSharerPlugin as FileSharerPlugin);
				}
				else
				{
					downloadUnavailable();
				}
			}
			else
			{
				if (Capacitor.isPluginAvailable("FileSharer"))
				{
					downloadFile(Plugins.FileSharer as FileSharerPlugin);
				}
				else
				{
					downloadUnavailable();
				}
			}
		}

		get attachmentList(): BasicListType[]
		{
			if (this.mail.hasAttachments)
			{
				return this.mail.attachments.map((attachment) =>
				{
					return {
						id: attachment.id,
						label: attachment.name,
						name: attachment.name,
						type: attachment.type,
						url: attachment.url,
					};
				});
			}
			return [];
		}
	}
