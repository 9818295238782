import {popoverController} from "@ionic/vue";

export default class PopoverService
{
	// ==========================================================================
	// Class Methods
	// ==========================================================================

	/**
	 * present the specified component as a popover
	 * @param componentClass - the popover to present
	 * @param props - props hash to pass to the popover component
	 * @param event - the mouse event that triggered the popover (used by ionic to position the popover)
	 * @return whatever the popover returns when dismissed or nil.
	 */
	public static async showPopover(
		componentClass: any,
		props: any,
		event: MouseEvent): Promise<any>
	{
		const popover = await popoverController.create({
			component: componentClass,
			componentProps: props,
			cssClass: "default-popover-container",
			event,
			showBackdrop: false,
		});

		await popover.present();
		return (await popover.onDidDismiss()).data;
	}
}
