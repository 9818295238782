import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentWrapper = _resolveComponent("ContentWrapper")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "fixed-header", {}, undefined, true),
      _createVNode(_component_ContentWrapper, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
        background: _withCtx(() => [
          _renderSlot(_ctx.$slots, "background", {}, undefined, true)
        ]),
        "content-header": _withCtx(() => [
          _renderSlot(_ctx.$slots, "content-header", {}, undefined, true)
        ]),
        content: _withCtx(() => [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ]),
        "content-top": _withCtx(() => [
          _renderSlot(_ctx.$slots, "content-top", {}, undefined, true)
        ]),
        "content-center": _withCtx(() => [
          _renderSlot(_ctx.$slots, "content-center", {}, undefined, true)
        ]),
        "content-bottom": _withCtx(() => [
          _renderSlot(_ctx.$slots, "content-bottom", {}, undefined, true)
        ]),
        "content-footer": _withCtx(() => [
          _renderSlot(_ctx.$slots, "content-footer", {}, undefined, true)
        ]),
        _: 3
      }, 16),
      _createVNode(_component_ion_footer, {
        style: _normalizeStyle(_ctx.footerStyleOverride)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "fixed-footer", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["style"])
    ]),
    _: 3
  }))
}