import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {AppointmentCancelSuccess, ClinicAppointmentTransfer} from "@/open_api/generated";
import AppointmentCancelResponse from "@/lib/appointment/model/AppointmentCancelResponse";

export default class AppointmentCancelSuccessToAppointmentCancelResponseConverter extends AbstractConverter<AppointmentCancelSuccess, AppointmentCancelResponse>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: AppointmentCancelSuccess): AppointmentCancelResponse
	{
		return new AppointmentCancelResponse(from.remote_id, from.success);
	}
}
