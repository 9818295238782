import CallbackCollection from "@/lib/utils/CallbackCollection";
import {SignalDispatcher} from "@/lib/telehealth/opentok/SignalDispatcher";
import SessionCommunicator from "@/lib/telehealth/opentok/SessionCommunicator";
import {OT_CUSTOM_SIGNAL, SignalEvent} from "@/lib/telehealth/opentok/ot.types";
import uuid4 from "uuid/v4";
import {MessageOrigin} from "@/views/patient_user/telehealth/types";
import moment from "moment";
import {ChatItemType} from "@/lib/telehealth/models/telehealth.types";
import StateTracker from "@/lib/telehealth/opentok/StateTracker";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import TelehealthChatMessageDtoToChatMessageConverter
	from "@/lib/telehealth/conversion/TelehealthChatMessageDtoToChatMessageConverter";
import ChatMessageToTelehealthChatMessageDtoConverter
	from "@/lib/telehealth/conversion/ChatMessageToTelehealthChatMessageDtoConverter";

/**
 * responsible for managing the telehealth chat
 */
export default class ChatManager
{
	protected chatMessageCallbacks: CallbackCollection = new CallbackCollection();
	protected chatTypingCallbacks: CallbackCollection = new CallbackCollection();

	protected stateTracker: StateTracker;
	protected signalDispatcher: SignalDispatcher;
	protected sessionCommunicator: SessionCommunicator;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		stateTracker: StateTracker,
		signalDispatcher: SignalDispatcher,
		sessionCommunicator: SessionCommunicator,
		chatMessageCallbacks: CallbackCollection,
		chatTypingCallbacks: CallbackCollection,
	)
	{
		this.stateTracker = stateTracker;
		this.signalDispatcher = signalDispatcher;
		this.sessionCommunicator = sessionCommunicator;
		this.chatMessageCallbacks = chatMessageCallbacks;
		this.chatTypingCallbacks = chatTypingCallbacks;

		this.setupEventHooks();
	}

	/**
	 * send a chat message to remote clients
	 * @param message - the message to send
	 */
	public async sendChatMessage(message: ChatMessage): Promise<void>
	{
		await this.sessionCommunicator.broadcast(OT_CUSTOM_SIGNAL.CHAT_MESSAGE,
		                                         JSON.stringify((new ChatMessageToTelehealthChatMessageDtoConverter()).convert(message)));
	}

	/**
	 * send a typing signal to remote clients
	 */
	public async sendTypingSignal(): Promise<void>
	{
		await this.sessionCommunicator.broadcast(OT_CUSTOM_SIGNAL.CHAT_TYPING, "");
	}

	/**
	 * call notification callbacks to notify listeners of a new message.
	 * @param senderId - the sender of the message (connectionId)
	 * @param message - the message
	 * @param origin - the origin of the message
	 * @protected
	 */
	public notifyNewMessage(senderId: string, message: ChatMessage, origin: MessageOrigin): void
	{
		this.chatMessageCallbacks.call(
			senderId,
			message,
		);
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	protected setupEventHooks(): void
	{
		this.signalDispatcher.addSignalHandler(OT_CUSTOM_SIGNAL.CHAT_MESSAGE, this.onChatMessage, this);
		this.signalDispatcher.addSignalHandler(OT_CUSTOM_SIGNAL.CHAT_TYPING, this.onChatTyping, this);
	}

	protected onChatMessage(event: SignalEvent): void
	{
		let messageOrigin = MessageOrigin.Received;
		if (this.stateTracker.isSelfConnection(event.from))
		{
			messageOrigin = MessageOrigin.Sent;
		}

		this.notifyNewMessage(event.from.connectionId,
		                      (new TelehealthChatMessageDtoToChatMessageConverter()).convert(JSON.parse(event.data)),
		                      messageOrigin);
	}

	protected onChatTyping(event: SignalEvent): void
	{
		if (!this.stateTracker.isSelfConnection(event.from))
		{
			this.chatTypingCallbacks.call(event.from);
		}
	}
}
