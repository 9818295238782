export function removeURLParameter(url, parameter)
{
	const urlparts = url.split("?");
	if (urlparts.length >= 2)
	{
		const prefix = encodeURIComponent(parameter) + "=";
		const pars = urlparts[1].split(/[&;]/g);

		for (let i = pars.length; i-- > 0;)
		{
			if (pars[i].lastIndexOf(prefix, 0) !== -1)
			{
				pars.splice(i, 1);
			}
		}

		url = urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
		return url;
	}
	else
	{
		return url;
	}
}

export function insertParam(key, value)
{
	if (history.pushState)
	{
		// const newurl = window.location.protocol + "//" + window.location.host + search.pathname + '?myNewUrlQuery=1';
		const currentUrl = removeURLParameter(window.location.href, key);

		let queryStart;
		if (currentUrl.indexOf("?") !== -1)
		{
			queryStart = "&";
		}
		else
		{
			queryStart = "?";
		}

		const newurl = currentUrl + queryStart + key + "=" + value;
		window.history.pushState({path: newurl}, "", newurl);
	}
}
