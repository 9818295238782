import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ec92736"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "full-width"
}
const _hoisted_2 = {
  key: 0,
  class: "full-width"
}
const _hoisted_3 = { class: "booking-errors" }
const _hoisted_4 = {
  key: 1,
  class: "full-width"
}
const _hoisted_5 = {
  key: 2,
  class: "full-width"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-center": _withCtx(() => [
      _createVNode(_component_EventStateSpinner, {
        showFailureState: $options.hasErrors,
        isLoading: $data.isLoading,
        loadingTitle: this.$mhat('BookingFinal.LoadingText'),
        successTitle: this.$mhat('BookingFinal.SuccessText'),
        successMessage: $options.successMessage,
        failureTitle: $data.errorMessage,
        failureMessage: ""
      }, null, 8, ["showFailureState", "isLoading", "loadingTitle", "successTitle", "successMessage", "failureTitle"]),
      (!$data.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            ($options.hasErrors)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("ol", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.errors, (error) => {
                      return (_openBlock(), _createElementBlock("li", null, _toDisplayString(error.message), 1))
                    }), 256))
                  ]),
                  _createVNode(_component_RouteButton, {
                    pattern: $data.ButtonColorPattern.FILL,
                    color: $data.ButtonColor.PRIMARY,
                    route: $options.rescheduleRoute
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat('BookingFinal.RescheduleAppointment')), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "route"]),
                  _createVNode(_component_BaseButton, {
                    pattern: $data.ButtonColorPattern.TRANSPARENT,
                    color: $data.ButtonColor.PRIMARY,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onBackToHome()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$mhat('BookingFinal.BackToHome')), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color"])
                ]))
              : ($options.splashScreenMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_BaseButton, {
                      pattern: $data.ButtonColorPattern.FILL,
                      color: $data.ButtonColor.PRIMARY,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.onBackToHome()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$mhat('BookingFinal.BackToHome')), 1)
                      ]),
                      _: 1
                    }, 8, ["pattern", "color"])
                  ]))
                : (!$options.splashScreenMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_RouteButton, {
                        pattern: $data.ButtonColorPattern.FILL,
                        color: $data.ButtonColor.PRIMARY,
                        route: _ctx.Route.Appointments.Home
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(this.$mhat('BookingFinal.ViewAppointments')), 1)
                        ]),
                        _: 1
                      }, 8, ["pattern", "color", "route"]),
                      _createVNode(_component_RouteButton, {
                        route: _ctx.Route.Booking.Home
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(this.$mhat('BookingFinal.BookAnotherAppointment')), 1)
                        ]),
                        _: 1
                      }, 8, ["route"]),
                      _createVNode(_component_BaseButton, {
                        pattern: $data.ButtonColorPattern.TRANSPARENT,
                        color: $data.ButtonColor.PRIMARY,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.onBackToHome()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(this.$mhat('BookingFinal.CloseWindow')), 1)
                        ]),
                        _: 1
                      }, 8, ["pattern", "color"])
                    ]))
                  : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}