import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderTitle = _resolveComponent("HeaderTitle")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_HeaderBar = _resolveComponent("HeaderBar")!

  return (_openBlock(), _createBlock(_component_HeaderBar, null, {
    left: _withCtx(() => [
      _createVNode(_component_HeaderTitle, { title: $props.title }, null, 8, ["title"])
    ]),
    right: _withCtx(() => [
      _createVNode(_component_CloseButton, {
        text: $props.closeText,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, null, 8, ["text"])
    ]),
    _: 1
  }))
}