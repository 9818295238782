

	import {Options, Vue, Prop} from "vue-property-decorator";
	import DefaultPopover from "@/components/Popover/DefaultPopover.vue";
	import {PatientUserDto} from "@/open_api/generated";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import {ButtonColor, ButtonColorPattern, ButtonSize} from "@/components/Buttons/types";
	import {ClinicAPI} from "@/lib/services/Api";
	import {popoverController} from "@ionic/vue";

	@Options({
		components: {DefaultPopover, BaseButton},
	})
	export default class VerificationPopover extends Vue
	{
		@Prop({type: Object}) public patient: PatientUserDto;
		@Prop({type: String}) public remoteProfileId: string;

		public ButtonColorPattern = ButtonColorPattern;
		public ButtonColor = ButtonColor;
		public ButtonSize = ButtonSize;
		public popoverController = popoverController;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async verifyPatient(): Promise<void>
		{
			await ClinicAPI().verifyPatientClinicConnection(this.patient.id);
			await ClinicAPI().linkPatientToClinicEmrRecord(this.patient.id, {remote_id: this.remoteProfileId});
			await popoverController.dismiss(true);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get patientDisplayName(): string
		{
			return `${this.patient.first_name} ${this.patient.last_name}`;
		}
	}
