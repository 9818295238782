export default {
  "Common": {
    "Error": {
      "Generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were unable to process your request. Please contact support if the problem persists."])}
    }
  },
  "LoginPageHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "ClinicLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic Login"])}
  },
  "CorporateFooter": {
    "PoweredByText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Powered By ", _interpolate(_named("brandName"))])},
    "CustomerSupportButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Support"])},
    "TermsButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
    "PrivacyPolicyButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
  },
  "ContactSupportModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Support"])}
  },
  "LoginForm": {
    "EmailFieldDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address"])},
    "PasswordFieldDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "LoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "SignUpButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "ForgotPasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Don't Know My Password"])},
    "UnconfirmedEmailErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has not been confirmed"])},
    "ValidationErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem validating your email or password"])},
    "LoginErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were unable to process your request. Please contact support if the problem persists."])},
    "SessionExpiredErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired Session"])},
    "SessionExpiredErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session has expired, please log in again"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Error"])},
    "RecordNotFoundErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No patient account was found."])}
  },
  "LoginPage": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching Pharmacy Data..."])},
    "LoginPageGreetingCmd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to CloudMD. Please login or create an account to book an appointment and see a doctor online."])},
    "LoginPageGreetingMha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to MyHealthAccess. Please login or create an account to connect with your healthcare providers and manage your appointments"])}
  },
  "LoginConfirm": {
    "appName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("appName"))])},
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Login"])},
    "SentConfirmationEmailMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have sent email confirmation instructions to the following address: ", _interpolate(_named("patientEmail")), "."])},
    "MustConfirmEmailMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must confirm your email address before you can log in."])},
    "ConfirmEmailInstructionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email address that you used to log in and we'll send you instructions on how to confirm your email address"])},
    "EmailFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "SendButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "NotificationErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "FailedToSendConfirmationEmailMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were unable to send your confirmation email. Please try again or contact support for assistance"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
    "NotificationSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "AccountConfirmedSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your ", _interpolate(_named("appName")), " account has been confirmed"])},
    "ProblemConfirmingEmailErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are having problems confirming your email address. Please try again or contact support for assistance"])},
    "EmailAlreadyConfirmedErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email was already confirmed. Please sign in."])}
  },
  "LoginReset": {
    "appName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("appName"))])},
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "ResetPasswordInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a new password for your account"])},
    "NewPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password (min. 8 characters)"])},
    "ConfirmPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "ResetPasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset My Password"])},
    "PasswordResetSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Have Successfully Reset Your Password"])},
    "PasswordResetFailureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Could Not Reset Your Password"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working..."])},
    "TryAgainButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "PasswordResetSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You will be logged in to your ", _interpolate(_named("appName")), " account momentarily..."])},
    "GenericPasswordResetFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again or return to the login screen for more help."])},
    "TokenExpiredFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password reset token has expired. Click below to request another."])}
  },
  "LoginForgot": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "ForgotPasswordInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To reset your password, please enter your email address below."])},
    "EmailFieldText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address"])},
    "ContinueButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "ReturnToLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Login"])},
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Has Been Sent"])},
    "FailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something Went Wrong"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We had a problem resetting your password. Please contact customer support for assistance"])},
    "EmailSentSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email with instructions on how to reset your password has been sent. If you don't receive the email, check your junk mail or try again."])}
  },
  "HeaderBarForgot": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])}
  },
  "WhitelabelMore": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More About CloudMD"])}
  },
  "WhitelabelLogin": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please login or create an account to continue."])},
    "TitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in to See A Doctor"])}
  },
  "WhitelabelHome": {
    "SeeADoctorButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See A Doctor Now"])},
    "OperationHoursButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours of Operation"])},
    "WelcomeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Doctor will see you now."])},
    "WelcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get virtual medical care on your schedule and from any device including your phone, desktop & tablet. Instantly connect with a licensed Canadian doctor!"])}
  },
  "WhitelabelCareCards": {
    "TitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why patients love virtual care"])},
    "MessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See a doctor when it’s convenient for you. Any time, anywhere. No longer do you need to take a half day off work to wait an hour in the doctor’s office for a 10-minute appointment. The doctor will see you now."])},
    "SeeADoctorCardTitleOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See A Doctor"])},
    "SeeADoctorCardTitleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Free"])},
    "SeeADoctorCardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available at no charge to BC and Ontario residents."])},
    "TimeOffCardTitleOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Need to Take"])},
    "TimeOffCardTitleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Off Work"])},
    "TimeOffCardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to doctors' evenings and weekends."])},
    "QualifiedDoctorsCardTitleOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualified"])},
    "QualifiedDoctorsCardTitleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canadian Doctors."])},
    "QualifiedDoctorsCardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prescriptions sent directly from your doctor to your local pharmacy."])},
    "ConnectFromCardTitleOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect From"])},
    "ConnectFromCardTitleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any Device"])},
    "ConnectFromCardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple and easy to use interface."])}
  },
  "HowCanWeHelpCard": {
    "TitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How Can CloudMD Help?"])},
    "MessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our doctors can complete the following visits via telemedicine:"])},
    "GeneralMedicalAdvice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General medical advice"])},
    "Referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialist referrals"])},
    "SinusInfections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinus infections"])},
    "BirthControlPrescriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth control pill prescription"])},
    "Prescriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New and renewed prescriptions"])},
    "LabResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review of test/lab results"])},
    "SkinInflammations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rashes and skin inflammation"])},
    "Utis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urinary tract infection (UTI)"])},
    "SickNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sick notes"])},
    "Colds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cough or cold"])},
    "MentalHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mental health appointments"])},
    "NutritionConcerns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diet & nutrition concerns"])}
  },
  "VirtualCareBanner": {
    "TitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Doctor will see you now."])},
    "MessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get virtual medical care on your schedule and from any device including your phone, desktop & tablet. Instantly connect with a licensed Canadian doctor!"])}
  },
  "AppDownload": {
    "OrgHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download The App Today"])},
    "DefaultHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the App!"])},
    "MessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay connected at home or from your mobile device. Available now on the AppStore and Google Play. Access to care when you need it. CloudMD is always accepting new patients."])}
  },
  "OnDemandBookingReason": {
    "reasonPromptSinglePharmacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before we can book your appointment we need to let the doctor know what you would like to discuss. Please enter your appointment reason in the text field below."])},
    "reasonPromptMultiPharmacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before we can book your appointment we need to let the doctor know what you would like to discuss. Please enter your appointment reason in the text field below and also select your preferred pharmacy."])},
    "bookingLocationSelectLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select A Pharmacy"])},
    "TitleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do you want to see the doctor today?"])},
    "AppointmentReasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter why you would like to see the doctor here"])},
    "BookAppointmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Your Appointment"])},
    "BookingErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Error"])},
    "DuplicateBookingErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Booking"])},
    "DuplicateBookingErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an appointment in the queue. Rejoin it?"])},
    "RejoinButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoin"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "FullQueueErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queue Availability"])},
    "TryAgainButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "ClosedClinicErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry"])},
    "ClosedClinicErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The clinic is closed."])},
    "SeeClinicHoursButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Clinic Hours"])},
    "BookNewAppointmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book New"])},
    "AppointmentAlreadyBookedErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have a recently booked appointment on, ", _interpolate(_named("appointmentDate")), " Do you wish to rejoin it?"])}
  },
  "OnDemandBookingHoursModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours of Operation"])}
  },
  "OnDemandBookingRedirect": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting..."])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait while we load the page"])},
    "NoOrgErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The organization was not found"])},
    "HomePageButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Homepage"])}
  },
  "OrgAqsRedirect": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting..."])},
    "InvalidHealthNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile is using an invalid health number. Please correct this before continuing."])},
    "GoBackText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])}
  },
  "OrgProviderGroupBookingRedirect": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirecting..."])},
    "InvalidHealthNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile is using an invalid health number. Please correct this before continuing."])},
    "GoBackText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])}
  },
  "SignupPage": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "BackToLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Login"])},
    "SignupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you book your first appointment, complete the form below."])},
    "WhitelabelSignupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you book your first appointment, the doctor will need you to complete the form below."])}
  },
  "SignupHeaderBar": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Signup"])}
  },
  "SignupForm": {
    "clinicName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName"))])},
    "UseOfWebsiteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of the service and website is subject to "])},
    "Our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our"])},
    "And": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "TermsOfUseLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
    "PrivacyPolicyLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "CreateAccountButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create My Account"])},
    "IneligibleHealthCardErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Card Not Eligible"])},
    "AccountExistsErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Exists"])},
    "AccountExistsErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is in use. Did you forget your password?"])},
    "ForgotPasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "ValidationErrorMessageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation Error"])},
    "BackToSignupButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Signup"])},
    "AccountCreationFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something Went Wrong"])},
    "AccountCreationFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We had a problem creating your account. Press the back button below to try again, or contact support for assistance."])},
    "ToLoginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Login"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hold on ", _interpolate(_named("patientFirstName"))])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be patient while we create your account."])},
    "PartialAccountCreationSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Account Has Been Created But..."])},
    "AccountCreationSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Complete"])},
    "SentEmailConfirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An email has been sent to ", _interpolate(_named("patientEmail")), " with instructions on how to confirm your account."])},
    "PartialAccountCreatedInstructionMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We were able to create your account and a confirmation email has been sent to ", _interpolate(_named("patientEmail")), ", but your connection to ", _interpolate(_named("clinicName")), " could not be completed at this time. Once you confirm your account please check your account information or contact the clinic for more details."])}
  },
  "Geodata": {
    "Countries": {
      "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
      "US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States"])}
    },
    "Provinces": {
      "AB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alberta"])},
      "BC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["British Columbia"])},
      "MB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manitoba"])},
      "NB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Brunswick"])},
      "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newfoundland and Labrador"])},
      "NS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Scotia"])},
      "NT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Northwest Territories"])},
      "NU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nunavut"])},
      "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontario"])},
      "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prince Edward Island"])},
      "QC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quebec"])},
      "SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saskatchewan"])},
      "YT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yukon Territory"])}
    },
    "States": {
      "AL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alabama"])},
      "AK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alaska"])},
      "AZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arizona"])},
      "AR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkansas"])},
      "CA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["California"])},
      "CO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colorado"])},
      "CT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecticut"])},
      "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delaware"])},
      "DC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District of Columbia"])},
      "FL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Florida"])},
      "GA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
      "HI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hawaii"])},
      "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idaho"])},
      "IL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illinois"])},
      "IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiana"])},
      "IA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iowa"])},
      "KS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kansas"])},
      "KY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kentucky"])},
      "LA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Louisiana"])},
      "ME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maine"])},
      "MD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maryland"])},
      "MA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massachusetts"])},
      "MI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Michigan"])},
      "MN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minnesota"])},
      "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mississippi"])},
      "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missouri"])},
      "MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montana"])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebraska"])},
      "NV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevada"])},
      "NH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Hampshire"])},
      "NJ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Jersey"])},
      "NM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Mexico"])},
      "NY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New York"])},
      "NC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Carolina"])},
      "ND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["North Dakota"])},
      "OH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohio"])},
      "OK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oklahoma"])},
      "OR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oregon"])},
      "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pennsylvania"])},
      "RI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhode Island"])},
      "SC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Carolina"])},
      "SD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Dakota"])},
      "TN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tennessee"])},
      "TX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texas"])},
      "UT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utah"])},
      "VT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermont"])},
      "VA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virginia"])},
      "WA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Washington"])},
      "WV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["West Virginia"])},
      "WI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wisconsin"])},
      "WY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyoming"])},
      "AA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armed Forces Americas (except Canada)"])},
      "AE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Africa, Canada, Europe, Middle East"])},
      "AP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armed Forces Pacific"])},
      "AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["American Samoa"])},
      "GU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guam"])},
      "MP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Northern Mariana Islands"])},
      "PR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerto Rico"])},
      "UM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States Minor Outlying Islands"])},
      "VI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["US Virgin Islands"])}
    }
  },
  "InputLayout": {
    "RequiredText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])}
  },
  "DateSelector": {
    "DayPlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "MonthPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "YearPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])}
  },
  "SignupSpinner": {
    "FailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something Went Wrong"])},
    "FailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We had a problem creating your account. Press the back button below to try again, or contact support for assistance."])},
    "BackToSignUpButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Signup"])},
    "GenericClinicName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the clinic"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hold on ", _interpolate(_named("patientName"))])},
    "PartialAccountCreationSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Account Has Been Created But..."])},
    "AccountCreationSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Complete"])},
    "PartialAccountCreationSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We were able to create your account and a confirmation email has been sent to ", _interpolate(_named("patientEmail")), ", but your connection to ", _interpolate(_named("clinicName")), " could not be completed at this time. Once you confirm your account please check your account information or contact the clinic for more details."])},
    "AccountCreationSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An email has been sent to ", _interpolate(_named("patientEmail")), " with instructions on how to confirm your account."])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We're creating your ", _interpolate(_named("appName")), " account now"])}
  },
  "NavigatorBookingLandingPage": {
    "NavigatorBookingInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When would you like to see your navigator?"])},
    "TalkToNavigator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk to Navigator Now"])},
    "BookAnAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book an Appointment With a Navigator"])},
    "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something Went Wrong"])}
  },
  "AqsAppointmentType": {
    "instructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay, great. What kind of appointment do you need?"])},
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Type"])},
    "LiveChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Chat"])},
    "VideoCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Call"])},
    "ClinicClosedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry"])},
    "ClinicClosedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The clinic is closed."])},
    "ClinicClosedConfirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Clinic Hours"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some thing went wrong"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to book your on demand appointment"])}
  },
  "OnDemandBookingWaitingRoom": {
    "AppointmentBookedHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Appointment Has Been Booked"])},
    "AppointmentBookedSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doctor will be with you shortly."])},
    "QueuePositionTextPart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are"])},
    "QueuePositionTextPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in the queue"])},
    "NextInLineText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are next in line"])},
    "CancelAppointmentInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By cancelling your appointment you will lose your spot in line."])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "CancelAppointmentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceling Appointment"])},
    "CancelAppointmentConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to cancel your appointment?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "ConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are having trouble connecting to the server. Please refresh the page"])},
    "ConnectionErrorAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "AppointmentCancelledMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This appointment is no longer available"])},
    "Exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
    "GoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Home"])}
  },
  "OnDemandExamRoom": {
    "YourAppointmentStartsSoonHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doctor will be right with you"])},
    "YourAppointmentStartsSoonSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thank you for your patience."])},
    "CancelAppointmentMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By cancelling your appointment you will lose your spot in line."])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "AppointmentEndedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Telehealth appointment has ended. Have a nice day!"])},
    "GoToHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Homepage"])},
    "CancelAppointmentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceling Appointment"])},
    "CancelAppointmentConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to cancel your appointment?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "BookingNextAvailability": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding Appointments..."])},
    "NextAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Availability"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "ShowMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Me"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Window"])},
    "ClinicConnectionProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic Connection Problem"])},
    "NoAppointmentsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Appointments Available"])},
    "NoAppointmentsAvailableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I couldn't find any available appointments for you to book. The clinic may have changed their schedule, or you have met the appointment count for their booking rules."])},
    "UnableToContactClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable To Contact Clinic"])},
    "FindingAppointmentHelperText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Just a minute, ", _interpolate(_named("firstName")), ". I'm finding available appointments for you."])},
    "NextAvailableAppointmentsHelperText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstName")), ", here are the next ", _interpolate(_named("numberOfAppointmentSlots")), " available appointments:"])},
    "ShowProvidersAvailabilityInstructionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click below to see all of ", _interpolate(_named("providerFullNameWithPrefixSuffix")), "'s availability, and choose one that works for you."])},
    "GenericProvidersAvailabilityInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click below to see all availability, and choose one that works for you."])},
    "SelectAnotherProviderButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Another Provider"])},
    "FindingProvidersLoadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding Healthcare Providers..."])}
  },
  "BookingAppointmentTime": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select A Time"])},
    "SelectTimeInstructionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please select from the list of available appointment times for ", _interpolate(_named("selectedDate")), "."])},
    "Morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morning"])},
    "Afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afternoon"])},
    "Evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evening"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding Appointments..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Appointments Available"])},
    "ChangeDateButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Appointment Date"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Window"])},
    "FailureMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I couldn't find any availability for ", _interpolate(_named("selectedDate")), ". Click below to select another day."])}
  },
  "BookingCalendar": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which Day?"])},
    "SelectDateInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a month and day."])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding Appointments..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Appointments Available"])},
    "FailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I couldn't find any available appointments for you to book. The clinic may have changed their schedule, or you have met the appointment count for their booking rules."])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Window"])}
  },
  "ContactClinicRouteButton": {
    "ButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Clinic"])}
  },
  "CalendarDays": {
    "SundayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
    "MondayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
    "TuesdayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
    "WednesdayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
    "ThursdayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
    "FridayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
    "SaturdayAbbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])}
  },
  "DocumentPreview": {
    "NotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Preview Available"])}
  },
  "BookingReason": {
    "AddAReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add A Reason"])},
    "ReasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for your appointment..."])},
    "ReasonInstructionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Okay, we are almost done. We just need to give ", _interpolate(_named("providerFullNameWithPrefixSuffix")), " a reason for your appointment in 80 characters or less."])},
    "ContinueButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "BookingConfirm": {
    "ConfirmDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Details"])},
    "BookingConfirmationDetailsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's confirm the details of your appointment:"])},
    "BookingConfirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to book this appointment?"])},
    "ThatIsCorrectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, that is correct"])},
    "StartOverText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'd like to start over"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clearing appointment information..."])}
  },
  "BookingFinal": {
    "LoadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking your appointment"])},
    "SuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A message should arrive shortly confirming your appointment..."])},
    "FailureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to book your appointment due to the following errors:"])},
    "RescheduleAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule Appointment"])},
    "BackToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back To Home"])},
    "ViewAppointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View My Appointments"])},
    "BookAnotherAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Another Appointment"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Window"])},
    "RegularSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there anything else you would like to do today?"])},
    "SplashScreenSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a nice day."])},
    "RescheduleFailureText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to reschedule your appointment due to the following errors:"])}
  },
  "TelehealthHeader": {
    "ExitButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
    "ExitAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave Appointment"])},
    "ExitAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to end this appointment?"])},
    "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "Decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])}
  },
  "TelehealthBase": {
    "ConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telehealth Connection Error"])},
    "ConnectionErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred preventing the Telehealth connection."])},
    "IncomingCallAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming Call"])},
    "IncomingCallAlertMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("callDisplayName")), " is calling!"])},
    "DeclineButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "AcceptButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "PoorConnectionAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poor Connection Detected"])},
    "PoorConnectionAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are reducing call quality due to a poor connection"])},
    "MicAndCameraErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting Microphone and Camera"])},
    "AVPermissionDeniedErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have denied permission to your microphone and or camera. Please allow permission and reload this page"])},
    "AVNoDevicesErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not find your microphone or camera. Please ensure they are plugged in and reload this page"])},
    "AVHardwareFaultErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are having trouble using your microphone and or camera. Please make sure no other applications are using them and reload this page"])},
    "GenericAVErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microphone & Camera Error"])},
    "ReloadButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload Page"])},
    "AppointmentAccessErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Access Error"])},
    "AppointmentAccessErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("loggedInUserEmail")), " doesn't have access to this appointment. Please double-check your reminder and try again."])},
    "LogoutButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "ConnectionFailedErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred when trying to connect to the telehealth session. Please try again."])},
    "FailedToSendDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send document."])}
  },
  "TelehealthPatient": {
    "DoctorHasLeftTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor has left"])},
    "DoctorHasLeftMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doctor has left the call. If you don't expect them to come back, you can hangup at any time"])}
  },
  "VideoView": {
    "ChatButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])}
  },
  "TelehealthTextChat": {
    "Exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
    "ChattingWith": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chatting with: ", _interpolate(_named("patientRemoteName"))])},
    "RoomEmptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chat room is empty"])},
    "LeaveChatTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave Chat?"])},
    "LeaveChatConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave?"])},
    "Stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay"])},
    "Leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave"])}
  },
  "ChatView": {
    "MessagePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a message"])},
    "SendMessageTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])},
    "SendFileTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a file"])},
    "CannotSendFileTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient must have an account to send files"])}
  },
  "AxiosResponseInterceptor": {
    "NetworkErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network Error"])},
    "NetworkErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem connecting to the servers"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
    "RemoteConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed To Contact Clinic Server"])}
  },
  "AlertService": {
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])}
  },
  "Notifier": {
    "ConfirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])}
  },
  "HomePageLinks": {
    "WelcomeBackMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Welcome back, ", _interpolate(_named("patientFirstName")), ". How can we help?"])},
    "BookAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book An Appointment"])},
    "FindClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find A Clinic"])},
    "MyClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Clinics"])},
    "MyMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Messages"])},
    "MyAppointments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Appointments"])},
    "SearchHealthInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search For Health Information"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])}
  },
  "HomePageHeaderBar": {
    "AccountHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "MyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "Dependents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependents"])},
    "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "VerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
    "LogOutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
    "BackToAppText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Back to ", _interpolate(_named("appName"))])}
  },
  "HomePage": {
    "TabBarAppointmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointments"])},
    "TabBarAccountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "TabBarMessagesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])}
  },
  "HomeActionSheetButtons": {
    "AccountButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "DependentsButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Dependents"])},
    "SecurityButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "PrivacyButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "VerificationCodeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
    "LogOutButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "NotificationService": {
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something Went Wrong"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact support if the problem persists."])},
    "NoClinicsErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Connected Clinics"])},
    "NoClinicsErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not connected to any clinics"])},
    "FindClinicButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a Clinic"])}
  },
  "BannerNotificationFactory": {
    "PatientAppointmentNotificationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have a telehealth appointment at ", _interpolate(_named("appointmentStartDateTime"))])},
    "DependentAppointmentNotificationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dependentFirstName")), " has a telehealth appointment at ", _interpolate(_named("appointmentStartDateTime"))])},
    "JoinButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Now"])},
    "NotificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Reminder"])}
  },
  "BookingHome": {
    "BookingInstructionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Okay, ", _interpolate(_named("patientFirstName")), "! Would you like to book an appointment for yourself or someone else?"])},
    "Me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
    "AddADependent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add A Dependent"])}
  },
  "BookingHeaderBar": {
    "DefaultHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book An Appointment"])}
  },
  "DependentSubheader": {
    "DefaultTextPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking For"])},
    "UnknownDependent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
  },
  "BookingClinic": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Clinic"])},
    "WhichClinicText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which clinic would you like to book an appointment with?"])},
    "GenericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to fetch bookable clinic data"])},
    "ClinicSelectedLoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecting clinic..."])}
  },
  "BookingProviderGroup": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Care Group"])},
    "WhichCareGroupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What Care Group are you needing?"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding Care Groups..."])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Window"])},
    "ClinicConnectionProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic Connection Problem"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "SearchByProviderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by Provider"])},
    "ProviderGroupSelectedLoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecting Care Group..."])}
  },
  "BookingProvider": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Provider"])},
    "WhichProviderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who would you like to book an appointment with?"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding Healthcare Providers..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Active Providers Found"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Window"])},
    "ClinicConnectionProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic Connection Problem"])},
    "NoProvidersFailureMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName")), " doesn't appear to have any active providers, or your primary healthcare provider has not been enabled."])},
    "SeeNextAvailableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Next Available"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "ProviderSelectedLoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecting provider..."])}
  },
  "BookingAppointmentType": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Type"])},
    "WhichAppointmentTypeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay, great. What kind of appointment do you need?"])},
    "ConnectWithATherapist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with a Therapist"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding Appointment Types..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Appointment Types Found"])},
    "LoadingFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This clinic doesn't appear to have any appointment types enabled."])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Window"])},
    "GenericError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load appointment data"])},
    "NoAppointmentTypesFailureMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName")), " doesn't appear to have any appointment types enabled."])}
  },
  "BookingDisclaimer": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "UnderstandButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Understand"])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])}
  },
  "WaitingRoomOverlay": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the waiting room for your virtual appointment."])},
    "AppointmentEndedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Appointment Has Ended"])},
    "CloseButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "HomePageButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Homepage"])},
    "ConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are having connection issues with your virtual appointment."])},
    "ConnectionErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure your internet connection is working and your appointment will resume automatically."])},
    "LoadingMessagePart1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your appointment will start shortly."])},
    "LoadingMessagePart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait here for your provider to join and make sure your device volume is on and camera is functioning properly."])}
  },
  "PublicTelehealth": {
    "DoctorHasLeftTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor has left"])},
    "DoctorHasLeftMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["he doctor has left the call. If you don't expect them to come back, you can hangup at any time"])}
  },
  "PublicWaitingRoomOverlay": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the waiting room for your virtual appointment."])},
    "AppointmentEndedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Appointment Has Ended"])},
    "AppointmentEndedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your telehealth appointment has ended. Have a nice day!"])},
    "HomeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Home"])}
  },
  "ClinicsHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Clinics"])}
  },
  "ClinicAboutInfo": {
    "AboutUsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])}
  },
  "ClinicProviderInfo": {
    "ProvidersHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providers"])}
  },
  "ClinicOperatingInfo": {
    "OperatingHoursHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Hours of Operation"])},
    "Closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "Sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "Saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])}
  },
  "ClinicPageController": {
    "RejectConnectionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want reject this clinic's connection request?"])},
    "RemoveConnectionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this clinic connection?"])},
    "ConnectionRequestSentMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection Request Sent!"])},
    "HealthCardIneligibleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Card Not Eligible"])},
    "ConnectionProblemErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic Connection Problem"])},
    "GenericConnectionErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic Connection Error"])},
    "GenericConnectionErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are currently unable to connect you with this clinic right now"])},
    "DisconnectedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnected from clinic"])},
    "RemoveClinicAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Connection?"])},
    "RemoveClinicAlertMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to remove your connection to ", _interpolate(_named("clinicName")), "?"])},
    "BackButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "ConfirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
  },
  "ClinicsFindDependentController": {
    "CurrentClinicsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available clinics currently connected to Parent"])}
  },
  "ClinicSearchSpinner": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Clinics..."])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Window"])},
    "FindAClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find A Clinic"])},
    "TryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "NoClinicsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Available Clinics"])},
    "NoClinicsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Clinics Found"])},
    "NoClinicsAvailableForDependent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There doesn't appear to be any clinics available for ", _interpolate(_named("dependentFirstName")), " to connect to. Please try again later."])},
    "NoClinicsFoundForDependent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dependentFirstName")), " doesn't have any clinics. Click below to connect them to a clinic, or back to select someone else."])},
    "NoClinicsAvailableForPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There doesn't appear to be any clinics available for you to connect to. Please try again later."])},
    "NoClinicsFoundForPatient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't appear to have any clinics. Click below to find a clinic."])}
  },
  "ClinicsFindHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find A Clinic"])}
  },
  "ClinicsFind": {
    "DependentHeaderTextPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching Clinics As, "])},
    "SelectClinicText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the clinic you would like to make a connection with."])},
    "LocationUnavailableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location access not available."])},
    "ShowAllClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all available clinics"])},
    "NoClinicsFoundTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Clinics"])},
    "NoClinicsFoundMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't find any clinics."])},
    "SearchForClinicsPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search For Clinics..."])},
    "SearchByProvidersPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search By Provider..."])}
  },
  "ClinicViewHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic Info"])}
  },
  "ClinicView": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Clinic..."])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Loading Clinic"])},
    "LoadingFailureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem occurred while loading this clinic. Please verify you have the correct clinic, or try again."])},
    "Allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow"])},
    "Unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
    "Connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "BookAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book An Appointment"])},
    "Deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny"])},
    "MessageUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Us"])},
    "DisconnectFromClinic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect From Clinic"])}
  },
  "ClinicsMobile": {
    "PendingClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Clinics"])},
    "YourClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Clinics"])},
    "NoClinics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No clinic connections"])},
    "NoClinicsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To make a connection with your healthcare provider, please click the find a clinic button."])}
  },
  "CannotConnectToClinicModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "CannotConnectToClinicHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We cannot connect you to ", _interpolate(_named("clinicName")), "."])},
    "CannotConnectToClinicMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName")), " does not allow remote profile creation, and we couldn't locate your profile. The most likely cause of this issue is that you have updated your health card number or the clinic has set your patient record to inactive. Please contact, ", _interpolate(_named("clinicName")), " at ", _interpolate(_named("clinicPhoneNumber")), " to resolve this issue."])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])}
  },
  "ChartUploadDocumentReviewModal": {
    "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review documents to upload to chart"])},
    "UploadErrorTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Failed to upload document to chart ", _interpolate(_named("documentName"))])},
    "InstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload selected documents to patient eChart."])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading documents..."])}
  },
  "MailHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Messages"])}
  },
  "MailHome": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Messages..."])},
    "LoadingSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Inbox Is Empty!"])},
    "CloseWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Window"])}
  },
  "MailboxController": {
    "LoadingErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed To Load Mailbox"])},
    "LoadingErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error occurred while loading the mailbox"])},
    "MessageSentSuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your message has been sent to ", _interpolate(_named("clinicName")), "!"])},
    "MessageSentFailureMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your message could not be sent to ", _interpolate(_named("clinicName"))])},
    "ArchiveMessageSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been archived."])},
    "UnarchiveMessageSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been unarchived."])},
    "SuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])}
  },
  "MessageFeed": {
    "NoMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No messages"])},
    "InboxMailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])},
    "SentMailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
    "ArchiveMailbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])}
  },
  "MessageCard": {
    "SentMessageHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To: ", _interpolate(_named("recipientNames"))])},
    "ReceivedMessageHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["From: ", _interpolate(_named("senderName"))])}
  },
  "MessageRead": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Message"])},
    "ClinicSectionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic"])},
    "SubjectSectionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "UnarchiveButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unarchive"])},
    "ArchiveButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
    "ReplyButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "CloseButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "Message": {
    "FromMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From: "])},
    "MessageReceivedOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["on ", _interpolate(_named("messageDate"))])}
  },
  "MessageCompose": {
    "NoClinicsAvailableMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No clinics available for messaging"])},
    "SubjectPlaceholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Subject Here"])},
    "MessagePlaceholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Message Here"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working..."])},
    "CloseButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "ClinicPlaceholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Select A Clinic"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ConfirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "MessageHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "ReplyHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply to Message"])},
    "NewMessageHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Message"])},
    "AddAttachmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Attachment"])},
    "SendButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "MessageLayoutWrapper": {
    "ClinicHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clinic"])},
    "SubjectHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "BodyHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "AttachmentsHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])}
  },
  "AttachmentSelector": {
    "AddAttachmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Attachment"])},
    "GenericAttachmentErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment Error"])},
    "MaxNumberOfFilesErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Exceeds limit of ", _interpolate(_named("maxNumberOfFiles")), " attached files per message"])},
    "GenericAttachmentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error attaching file to message"])},
    "RemoveAttachmentAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Attachment"])},
    "RemoveAttachmentAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this attachment?"])},
    "DisagreeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disagree"])},
    "AgreeButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree"])},
    "RemoveAttachmentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error removing file from message"])}
  },
  "FileChooser": {
    "GenericAttachmentErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment Error"])},
    "MaxFileSizeErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fileName")), " exceeds max size of ", _interpolate(_named("maxFileSize")), "MB."])}
  },
  "AppointmentsHeader": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Appointments"])}
  },
  "AppointmentsHome": {
    "BookAnAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book An Appointment"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding Your Appointments..."])},
    "NoAppointmentsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't appear to have any appointments. Click below to book an appointment."])},
    "NoDependentAppointmentsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dependentFirstName")), " doesn't seem to have any appointments. Click below to book an appointment for them"])}
  },
  "AppointmentsFeed": {
    "PreviousAppointmentsTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Previous Appointments (", _interpolate(_named("numberOfPreviousAppointments")), ")"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error retrieving appointments list"])}
  },
  "AppointmentInfo": {
    "MobileHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment With"])},
    "CloseButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "AppointmentPage": {
    "CancelAppointmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Appointment"])},
    "StartAppointmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Your Telehealth Appointment"])},
    "AboutAppointmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Your Appointment"])},
    "UnableToCancelAppointmentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This appointment can no longer be cancelled online. Please contact your clinic"])},
    "FailedToCancelAppointmentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to cancel appointment. Please contact your clinic if the issue persists."])},
    "CancelAppointmentConfirmationDialogHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Appointment"])},
    "CancelAppointmentConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel this appointment?"])},
    "CancelAppointmentDisagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disagree"])},
    "CancelAppointmentAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "RescheduleAppointmentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Appointment"])}
  },
  "HeaderBarProfile": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])}
  },
  "AccountHome": {
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "SaveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
  },
  "ProfileControlButtons": {
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "SaveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])}
  },
  "ProfileInputFields": {
    "FirstNameField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "MiddleNameField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle Name"])},
    "LastNameField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "EmailField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "HealthCareProvinceCodeField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthcare Province"])},
    "HealthNumberField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Number"])},
    "VersionCodeField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "BirthDateField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
    "SexField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex At Birth"])},
    "CellPhoneField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell Phone"])},
    "AddressField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street Address"])},
    "CityField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "CountryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "TimezoneField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timezone"])},
    "PasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "PasswordFieldPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password here"])},
    "ConfirmPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "ConfirmPasswordFieldPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-type your password here"])},
    "HealthInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Health Information"])},
    "NewbornDependentIndicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please indicate if this dependent does not have their own health insurance number because they are a newborn"])},
    "NewbornToggleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is this dependent a newborn?"])},
    "AgeAndSexInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Age and Sex Information"])},
    "ContactInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Contact Information"])},
    "PrimaryPhoneNumberDescriptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a phone number which the Doctor may use to contact you regarding your appointment if required."])},
    "PasswordRequirementsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must consist of at least 8 characters"])},
    "GenericFieldPlaceHolderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your info here"])},
    "FieldPlaceHolderText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter your ", _interpolate(_named("fieldName")), " here"])}
  },
  "ProfileController": {
    "StateRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "ProvinceRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
    "USPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP Code"])},
    "CanadaPostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
    "SexMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "SexFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "GenericNotificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "TurnOffEmailNotificationWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turning off email will affect your ability to receive updates and appointment reminders"])},
    "ConfirmationButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Understand"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
    "SomethingWentWrongMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, something went wrong. Please try again or contact support"])},
    "PasswordText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "EmailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])}
  },
  "PatientProfileModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "InstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you book your first appointment, the doctor will need you to complete the form below."])},
    "ContinueButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "ConfirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm this information is correct."])},
    "NotRegisteredMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your patient profile is not currently registered with ", _interpolate(_named("clinicName")), "."])}
  },
  "ProfileModal": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Info"])},
    "CloseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
    "SubmitButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "RequiredProfileModal": {
    "UpdateInfoButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Information"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "UnsupportedProvinceErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported Province"])},
    "UnsupportedProvinceErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your health care province is not currently supported."])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "IneligibleHealthCardErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Card Not Eligible"])},
    "ConnectedToClinicMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected To Clinic"])},
    "NoRecordHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Record Found"])},
    "NoRecordMessageText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("clinicName")), " is not accepting new patients at this time."])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
    "EditProfileButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "BookingUnavailableHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Unavailable"])},
    "BookingUnavailableMessageText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Booking with ", _interpolate(_named("clinicName")), " is currently unavailable. Contact your clinic if this persists"])},
    "CreatePatientRecordMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You need to create a ", _interpolate(_named("clinicName")), " patient record to sign in"])},
    "CreateDependentPatientRecordMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You need to create a ", _interpolate(_named("clinicName")), " patient record for ", _interpolate(_named("dependentFirstName"))])},
    "ConnectToClinicHeaderText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Connect to ", _interpolate(_named("clinicName"))])},
    "ViewProfileButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Profile"])},
    "ConnectButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "IncompleteProfileHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete Profile"])},
    "IncompleteProfileMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You must complete your profile to be added to ", _interpolate(_named("clinicName"))])},
    "DependentIncompleteProfileMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You must complete ", _interpolate(_named("dependentFirstName")), "'s profile to add them to $", _interpolate(_named("clinicName"))])},
    "ConnectionMessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'd like to connect to your clinic"])},
    "DependentConnectionMessageText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I'd like to connect my dependent ", _interpolate(_named("dependentFirstName")), " $", _interpolate(_named("dependentLastName")), " to your clinic"])},
    "PatientHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patient Info"])},
    "DependentHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependent Info"])}
  },
  "DependentsHome": {
    "DependentsInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click below to view and update an existing dependent, or to add a new dependent."])},
    "AddADependentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add A Dependent"])}
  },
  "DependentsHeaderBar": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Dependents"])}
  },
  "DependentsEventStateSearch": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching for Dependents"])},
    "NoResultsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Dependents Found"])}
  },
  "DependentsProfile": {
    "ConnectedClinicTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected Clinics"])},
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working"])},
    "LoadingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please wait while the action is completed"])},
    "SaveAndContinueButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & Continue"])},
    "AddAConnectionButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add A New Connection"])},
    "DeleteDependentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Dependent"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "LoadingErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load dependent profile"])},
    "NewbornDependentAlertMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This dependent is less than ", _interpolate(_named("numberOfMonths")), " months old, and may not be registered with a personal health number. If this is the case the dependent must be linked using the guardians health number. Please confirm if this is the case.\n Does this dependent have a personal health number?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "SavingErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save dependent profile information"])},
    "DeleteDependentAlertHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Dependent"])},
    "DeleteDependentAlertMessageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this dependent? All future appointments will be cancelled!"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "DeleteDependentErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not delete dependent record"])},
    "UnCancellableAppointmentsAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un-cancellable future appointments!"])},
    "UnCancellableAppointmentsAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you still want to continue deleting dependent? You may have to contact the clinic(s) to manually cancel"])},
    "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "DeleteClinicConnectionAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Clinic Connection"])},
    "DeleteClinicConnectionAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this clinic connection?"])},
    "Disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disagree"])},
    "Agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree"])},
    "DeleteClinicErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not remove clinic connection"])},
    "EditDependentHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a Dependent"])},
    "AddDependentHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Dependent"])}
  },
  "HeaderBarSecurity": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])}
  },
  "SecurityHome": {
    "UpdateEmailOrPasswordInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change your email or password you will need to unlock the fields with you current email and password."])},
    "HowToProceedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To proceed click one of the buttons below."])},
    "EmailFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "PasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "ChangePasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change My Password"])},
    "ChangeEmailButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change My Email"])},
    "DeactivateAccountButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate My Account"])},
    "DeactivateAccountConfirmationAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Action"])},
    "DeactivateAccountConfirmationAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to deactivate your account?"])},
    "Deactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "SecurityChangePassword": {
    "ChangePasswordInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For safety, we need your current password before we can update your account."])},
    "CurrentPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
    "NewPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "ConfirmNewPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
    "ChangePasswordButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change My Password"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "SecurityChangeEmail": {
    "ChangeEmailInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're going to need your password if you want to change the email address that you use to log in"])},
    "CurrentPasswordFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
    "EmailFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "ChangeEmailButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change My Email"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "HeaderBarPrivacy": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])}
  },
  "PrivacyHome": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "NotificationSettingsHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your notification settings"])},
    "NotificationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "AllowTextMessagesFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Text Messages"])},
    "AllowEmailsFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Emails"])},
    "ViewPrivacyPolicyButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Privacy Policy"])},
    "ViewTermsButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Terms of Use"])}
  },
  "UserProfileController": {
    "GenericNotificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "TurnOffEmailNotificationWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turning off email will affect your ability to receive updates like appointment reminders from MyHealthAccess"])},
    "ConfirmationButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Understand"])},
    "TurnOffTextNotificationWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turning off text messages will affect your ability to receive updates like appointment reminders from MyHealthAccess"])},
    "MissingRequiredFieldsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some required fields of your profile aren't correct or are missing. Please update them before saving"])},
    "OkButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "CheckEmailAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your new email address"])},
    "CheckEmailAlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To finish the email change please check your new email address for confirmation instructions."])}
  },
  "AccountIdCodePage": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
    "VerificationCodeInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide this code to your doctor for identity verification."])}
  },
  "Kiosk": {
    "LoadingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Kiosk Data"])},
    "LoadingFailureTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Loading Kiosk Data"])},
    "TimeoutAlertHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you still there?"])},
    "TimeoutAlertMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To protect your privacy, you will be timed out in ", _interpolate(_named("timeoutSeconds")), " seconds if the Kiosk is left inactive"])}
  },
  "KioskHome": {
    "SeeADoctorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See A Doctor Now"])},
    "SeeADoctorDescriptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like to see a doctor have your health card ready and touch the button below."])},
    "TryAgainButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])},
    "HealthCardReaderScanningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanning health card..."])}
  },
  "KioskReason": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Reason"])},
    "AppointmentReasonInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch the white text box below to enter why you would like to see the doctor today."])},
    "AppointmentReasonPlaceholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch here to open the keyboard."])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "NextButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
  },
  "KioskProfileForm": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swipe Your Health Card Now"])},
    "InstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please swipe your health card now or manually enter your health card information in the text fields below."])},
    "RequiredFieldsInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the following required fields:"])},
    "UseOfSiteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of the service and website is subject to our"])},
    "And": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "TermsButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
    "PrivacyPolicyButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "BookAppointmentButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Appointment"])},
    "FieldErrorsHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Errors"])},
    "FieldErrorsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some fields are incorrect. Please double check the highlighted fields"])},
    "DismissButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
    "GenericErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Are Sorry"])},
    "GenericErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cannot complete your request at this moment. Please try again later."])}
  },
  "KioskFinished": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Appointment Is Over"])},
    "InformationDeletedConfirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your information has been deleted from the kiosk to protect your privacy."])},
    "PickUpPrescriptionInstructionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pick up your prescription please see the pharmacist."])},
    "ExitButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goodbye"])}
  },
  "KioskWaitingRoomOverlay": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Appointment Has Been Booked"])},
    "WaitingRoomText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doctor will be with you in a moment."])},
    "CancelAppointmentInformationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling your appointment will immediately delete your information from the kiosk to protect your privacy and you will lose your spot in line."])},
    "CancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ExitButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Homepage"])},
    "CancelAppointmentNotificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceling Appointment"])},
    "CancelAppointmentNotificationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to cancel your appointment?"])},
    "YesButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "NoButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "KioskTelehealth": {
    "DoctorHasLeftTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor has left"])},
    "DoctorHasLeftMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doctor has left the call. If you don't expect them to come back, you can hangup at any time"])},
    "TimeoutErrorHeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Waiting?"])},
    "TimeoutErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To protect your privacy, you will be timed out in ", _interpolate(_named("timeoutSeconds")), " seconds."])},
    "RejoinCallMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this was in error you can rejoin."])},
    "RejoinCallAfterNetworkErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This may have been caused by a network error. Do you want to rejoin?"])},
    "SessionEndedNotificationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Has Ended"])},
    "StayButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay"])},
    "LeaveButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave"])}
  },
  "TermsAndConditionsPage": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms Of Use"])}
  },
  "PrivacyPolicyPage": {
    "HeaderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
  },
  "CloseButton": {
    "ButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "ErrorPage": {
    "403Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Authorized"])},
    "403Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to access this resource"])},
    "404Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry"])},
    "404Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not find what you are looking for"])},
    "ErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Error Has Occurred!"])},
    "ErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["HTTP Status Code: ", _interpolate(_named("code"))])},
    "GoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Homepage"])}
  }
}