import {InboxMessage, MailAttachment} from "@/open_api/generated";
import moment, {Moment} from "moment";
import {MailboxType} from "@/lib/Mailbox/Mailbox.types";
import Messageable from "@/lib/Mailbox/Messageable.model";
import AuthStore from "@/lib/vuex/auth.store";

export class Mail
{
	private _id: string = null;
	private _conversationId: string = null;
	private _mailbox: string = null;
	private _createdAt: Moment = null;

	private _subject: string = null;
	private _body: string = null;
	private _sender: Messageable = null;
	private _recipients: Messageable[] = [];

	private _isArchived = false;
	private _isRead = false;

	private _isSelected = false;
	private _isDeleting = false;

	private _canReply = true;

	private _attachments: MailAttachment[] = null;

	constructor(mail: InboxMessage)
	{
		this.id = mail.id;
		this.conversationId = mail.conversation_id;
		this.mailbox = mail.group;
		this.createdAt = moment(mail.created_at);
		this.subject = mail.subject;
		this.body = mail.message;
		this.sender = new Messageable(mail.sender_id, mail.sender_name, mail.sender_type);
		this.isArchived = mail.group === MailboxType.Archive;
		this.isRead = mail.is_read;
		this.attachments = mail.attachments;
		this.recipients = mail.recipients.map((recipient) => new Messageable(recipient.id, recipient.name, recipient.type));
	}

	get hasNotification(): boolean
	{
		return this.mailbox === MailboxType.Inbox && !this.isRead && !this.isArchived;
	}

	get hasAttachments(): boolean
	{
		return this.attachments && this.attachments.length > 0;
	}

	set isArchived(isArchived: boolean)
	{
		this._isArchived = isArchived;
	}

	get isArchived(): boolean
	{
		return this._isArchived;
	}

	get isInbox(): boolean
	{
		return this.mailbox === MailboxType.Inbox;
	}

	get isSent(): boolean
	{
		return this.mailbox === MailboxType.Sent;
	}

	get id(): string
	{
		return this._id;
	}

	set id(value: string)
	{
		this._id = value;
	}

	get conversationId(): string
	{
		return this._conversationId;
	}

	set conversationId(value: string)
	{
		this._conversationId = value;
	}

	get mailbox(): string
	{
		return this._mailbox;
	}

	set mailbox(value: string)
	{
		this._mailbox = value;
	}

	get createdAt(): moment.Moment
	{
		return this._createdAt;
	}

	set createdAt(value: moment.Moment)
	{
		this._createdAt = value;
	}

	get subject(): string
	{
		return this._subject;
	}

	set subject(value: string)
	{
		this._subject = value;
	}

	get body(): string
	{
		return this._body;
	}

	set body(value: string)
	{
		this._body = value;
	}

	// check if sender is the current user.
	get isSenderMe(): boolean
	{
		return this.sender?.id === AuthStore.loggedInUser.id;
	}

	get sender(): Messageable
	{
		return this._sender;
	}

	set sender(sender: Messageable)
	{
		this._sender = sender;
	}

	get senderName(): string
	{
		return this._sender?.name;
	}

	get isRead(): boolean
	{
		return this._isRead;
	}

	set isRead(value: boolean)
	{
		this._isRead = value;
	}

	get isSelected(): boolean
	{
		return this._isSelected;
	}

	set isSelected(value: boolean)
	{
		this._isSelected = value;
	}

	get isDeleting(): boolean
	{
		return this._isDeleting;
	}

	set isDeleting(value: boolean)
	{
		this._isDeleting = value;
	}

	get senderId(): string
	{
		return this._sender?.id;
	}

	get canReply(): boolean
	{
		return this._canReply;
	}

	set canReply(value: boolean)
	{
		this._canReply = value;
	}

	get attachments(): MailAttachment[]
	{
		return this._attachments;
	}

	set attachments(value: MailAttachment[])
	{
		this._attachments = value;
	}

	get recipients(): Messageable[]
	{
		return this._recipients;
	}

	set recipients(value: Messageable[])
	{
		this._recipients = value;
	}

	// get list of all recipient names.
	get recipientNames(): string
	{
		return this.recipients.map((recipient) => recipient.name).join(", ");
	}
}
