

	import {Options, Vue} from "vue-property-decorator";

	import {Validator} from "@/lib/utils/Validator";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import {AccountAPI} from "@/lib/services/Api";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {PatientLoginService} from "@/lib/services/login/PatientLogin.service";
	import {EmailConfirmationResponse, ErrorCode, OrganizationProfileDto} from "@/open_api/generated";
	import HeaderBarForgot from "@/views/patient_user/login/components/ForgotHeaderBar.vue";
	import {Route} from "@/router/router";
	import {RouteLocationRaw} from "vue-router";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {getResources} from "@/lib/utils/Resource";

	@Options({components: {HeaderBarForgot}})
	export default class LoginConfirm extends Vue
	{
		public token: string = null;
		public tokenSent = false;
		public email: string = null;
		public organization: OrganizationProfileDto = null;

		mounted()
		{
			this.token = this.$route.query.token as string;

			if (OrganizationStore.hasOrganization)
			{
				this.organization = OrganizationStore.organizationProfile;
			}
			if (this.token)
			{
				this.confirmEmail();
			}
		}

		public confirmEmail()
		{
			AccountAPI().confirmEmail({confirm_email_token: this.token, email: this.email})
				.then((response) =>
					{
						const results: EmailConfirmationResponse = response.data;
						this.alertAccountConfirmed();

						if (results.login_token && results.user)
						{
							new PatientLoginService().setLoggedInInfo(results);
						}

						this.doRedirect(results);
					},
					(error: ErrorResponse) =>
					{
						if (error && error.is(ErrorCode.DuplicateConfirmation))
						{
							this.alertAlreadyConfirmedError(error);
						}
						else if (error && error.is(ErrorCode.InvalidToken))
						{
							this.alertInvalidTokenError(error);
						}
						else
						{
							this.alertProblemConfirming();
						}
						this.doRedirect({});
					});
		}

		public updateEmail(event)
		{
			this.email = event.value;
		}

		public sendConfirmEmail()
		{
			AccountAPI().sendConfirmEmail({email: this.email}).then(
				(success) =>
				{
					this.tokenSent = true;
				},
				(error) =>
				{
					WebNotification.$emit({
						event: NotifyEvent.PasswordResetError,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Reminder,
						title: this.$mhat("LoginConfirm.NotificationErrorTitle"),
						message: this.$mhat("LoginConfirm.FailedToSendConfirmationEmailMessage"),
						dismiss: {
							message: this.$mhat("LoginConfirm.DismissButtonText"),
						},
					});
				},
			);
		}

		public alertAccountConfirmed()
		{
			WebNotification.$emit({
				event: NotifyEvent.AccountConfirmed,
				type: NotificationType.Swipe,
				severity: NotificationSeverity.Reminder,
				title: this.$mhat("LoginConfirm.NotificationSuccessTitle"),
				message: this.$mhat("LoginConfirm.AccountConfirmedSuccessMessage", {appName: this.appName}),
				timeout: 5000,
			});
		}

		public alertProblemConfirming()
		{
			WebNotification.$emit({
				event: NotifyEvent.PasswordResetError,
				type: NotificationType.Dismiss,
				severity: NotificationSeverity.Reminder,
				title: this.$mhat("LoginConfirm.NotificationErrorTitle"),
				message: this.$mhat("LoginConfirm.ProblemConfirmingEmailErrorMessage"),
				dismiss: {
					message: this.$mhat("LoginConfirm.DismissButtonText"),
				},
			});
		}

		public alertAlreadyConfirmedError(errors: ErrorResponse)
		{
			WebNotification.$emit({
				event: NotifyEvent.PasswordResetError,
				type: NotificationType.Dismiss,
				severity: NotificationSeverity.Reminder,
				title: this.$mhat("LoginConfirm.NotificationErrorTitle"),
				message: this.$mhat("LoginConfirm.EmailAlreadyConfirmedErrorMessage"),
				dismiss: {
					message: this.$mhat("LoginConfirm.DismissButtonText"),
				},
			});
		}

		public alertInvalidTokenError(error: ErrorResponse)
		{
			WebNotification.$emit({
				event: NotifyEvent.PasswordResetError,
				type: NotificationType.Dismiss,
				severity: NotificationSeverity.Warning,
				title: this.$mhat("LoginConfirm.NotificationErrorTitle"),
				message: error.message,
				dismiss: {
					message: this.$mhat("LoginConfirm.DismissButtonText"),
				},
			});
		}

		public doRedirect(results)
		{
			let route: string | RouteLocationRaw = Route.Login.Home;

			// whitelabel redirects
			if (this.organization)
			{
				if (this.AuthStore.isLoggedIn())
				{
					route = {
						name: Route.odbBookingRoute.Redirect,
						params: {
							organizationId: this.organization.id,
						},
					};
				}
				else
				{
					route = {
						name: Route.Public.Organization.Login,
						params: {
							organizationId: this.organization.id,
						},
					};
				}
			}
			else if (this.AuthStore.isLoggedIn())
			{
				route = Route.Home;
				if (results.clinic)
				{
					const clinic = new ClinicProfile(results.clinic);
					if (clinic.patientCanBook)
					{
						route = {
							name: Route.Booking.Provider,
							params: {
								appointmentMethod: "clinic",
								clinicId: clinic.id,
							},
						};
					}
				}
			}
			this.$mhaRouterPush(route);
		}

		get emailValid()
		{
			return Validator.emailAddress(this.email);
		}

		get appName()
		{
			return this.$mhat("LoginConfirm.appName", {appName: getResources().app_name});
		}
	}
