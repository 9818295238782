import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba40855a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "toggle-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrivacyHeaderBar = _resolveComponent("PrivacyHeaderBar")!
  const _component_Toggle = _resolveComponent("Toggle")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_PrivacyHeaderBar, {
        headerText: this.$mhat('PrivacyHome.HeaderText')
      }, null, 8, ["headerText"])
    ]),
    "content-top": _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(this.$mhat("PrivacyHome.NotificationSettingsHeaderText")), 1),
      (_ctx.controller.userProfile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h6", null, _toDisplayString(this.$mhat("PrivacyHome.NotificationsHeader")), 1),
              _createVNode(_component_Toggle, {
                status: _ctx.controller.userProfile.smsNotifications,
                labelPosition: _ctx.LabelPosition.RIGHT,
                label: this.$mhat('PrivacyHome.AllowTextMessagesFieldLabel'),
                labelColor: "var(--color-neutral-darker)",
                onToggled: _cache[0] || (_cache[0] = ($event: any) => (_ctx.controller.onToggleSMS()))
              }, null, 8, ["status", "labelPosition", "label"]),
              _createVNode(_component_Toggle, {
                status: _ctx.controller.userProfile.emailNotifications,
                labelPosition: _ctx.LabelPosition.RIGHT,
                label: this.$mhat('PrivacyHome.AllowEmailsFieldLabel'),
                labelColor: "var(--color-neutral-darker)",
                onToggled: _cache[1] || (_cache[1] = ($event: any) => (_ctx.controller.onToggleEmail()))
              }, null, 8, ["status", "labelPosition", "label"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    "content-bottom": _withCtx(() => [
      _createVNode(_component_RouteButton, {
        route: _ctx.Route.Public.PrivacyPolicy
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("PrivacyHome.ViewPrivacyPolicyButtonText")), 1)
        ]),
        _: 1
      }, 8, ["route"]),
      _createVNode(_component_RouteButton, {
        pattern: _ctx.ButtonColorPattern.TRANSPARENT,
        color: _ctx.ButtonColor.PRIMARY,
        route: _ctx.Route.Public.TermsConditions
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("PrivacyHome.ViewTermsButtonText")), 1)
        ]),
        _: 1
      }, 8, ["pattern", "color", "route"])
    ]),
    _: 1
  }))
}