import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13442843"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-badge-container" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", { href: _ctx.appStoreLink }, [
      _createElementVNode("img", {
        src: _ctx.appStoreImage,
        alt: "Link to App Store"
      }, null, 8, _hoisted_3)
    ], 8, _hoisted_2),
    _createElementVNode("a", { href: _ctx.googlePlayLink }, [
      _createElementVNode("img", {
        src: _ctx.playStoreImage,
        alt: "Link to Google Play Store"
      }, null, 8, _hoisted_5)
    ], 8, _hoisted_4)
  ]))
}