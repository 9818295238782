import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelectionCard = _resolveComponent("BaseSelectionCard")!

  return (_openBlock(), _createBlock(_component_BaseSelectionCard, {
    onClick: _ctx.handleClick,
    class: _normalizeClass(["secondary-card min-height", {previous: !_ctx.mail.hasNotification}]),
    pattern: _ctx.cardPattern,
    active: _ctx.active,
    attachment: _ctx.mail.hasAttachments,
    header: _ctx.messageHeader,
    primary: _ctx.mail.subject,
    footer: _ctx.mail.body,
    "border-text": _ctx.borderDate,
    regular: !_ctx.mail.hasNotification,
    deleted: _ctx.mail.isDeleting,
    clickable: _ctx.clickable
  }, null, 8, ["onClick", "pattern", "class", "active", "attachment", "header", "primary", "footer", "border-text", "regular", "deleted", "clickable"]))
}