import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ecb007b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "whitelabel-layout-container" }
const _hoisted_2 = { class: "header-text" }
const _hoisted_3 = { class: "label-text" }
const _hoisted_4 = { class: "whitelabel-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhitelabelPrimaryLogo = _resolveComponent("WhitelabelPrimaryLogo")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["whitelabel-layout-root", _ctx.layoutRootClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_WhitelabelPrimaryLogo, {
        "logo-light": _ctx.logoLight,
        class: "logo-constraints"
      }, null, 8, ["logo-light"]),
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text), 1),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ], 2))
}