

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class RouteButton extends Vue
	{
		@Prop({type: [String, Object]}) route: any;
		@Prop({type: String}) icon: string;
		@Prop({type: Boolean}) primary: boolean;
		@Prop({type: Boolean}) disabled: boolean;

		public goRoute()
		{
			if (!this.disabled)
			{
				this.$mhaRouterPush(this.route);
			}
		}
	}
