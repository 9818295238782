import {KioskDto} from "@/open_api/generated";
import {BaseUser} from "@/lib/baseUser.model";
import {LoginToken} from "../models/LoginToken";
import {Pharmacy} from "@/lib/kiosk/pharmacy.model";
import {Route} from "@/router/router";
import KioskStore from "@/lib/vuex/modules/Kiosk.store";
import {PatientProfileField} from "@/lib/types/Profile";

export class KioskUser extends BaseUser
{
	public route = {
		Login: Route.Kiosk.Home,
		Home: Route.Kiosk.Home,
	};

	private _pharmacy: Pharmacy;

	public static fromDto(dto: KioskDto, jwt: string): KioskUser
	{
		const kioskUser = new KioskUser();

		kioskUser.id = dto.id;
		kioskUser._email = dto.email;
		kioskUser.loginToken = new LoginToken(jwt);
		kioskUser.pharmacy = Pharmacy.fromDto(dto.pharmacy);

		return kioskUser;
	}

	get pharmacy(): Pharmacy
	{
		return this._pharmacy;
	}

	set pharmacy(value: Pharmacy)
	{
		this._pharmacy = value;
	}

	get firstName(): string
	{
		return KioskStore.patientProfile[PatientProfileField.FIRST_NAME];
	}

	get lastName(): string
	{
		return KioskStore.patientProfile[PatientProfileField.LAST_NAME];
	}
}
