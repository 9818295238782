import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5eb35ea8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-modal" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = { class: "modal-form" }
const _hoisted_4 = { class: "modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, { contentWidthFull: $props.contentWidthFull }, {
    "content-header": _withCtx(() => [
      ($props.headerTitle)
        ? (_openBlock(), _createBlock(_component_AppHeader, {
            key: 0,
            "close-text": $props.closeText,
            title: $props.headerTitle,
            onClose: $options.closeModal
          }, null, 8, ["close-text", "title", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    "content-top": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["modal-title", { error: $props.error }])
          }, [
            _createElementVNode("p", null, _toDisplayString($props.title), 1)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "modal-form", {}, undefined, true)
        ])
      ])
    ]),
    "content-bottom": _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "modal-buttons", {}, undefined, true)
      ])
    ]),
    "fixed-footer": _withCtx(() => [
      ($props.footer)
        ? (_openBlock(), _createBlock(_component_ion_footer, {
            key: 0,
            class: "modal-footer"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "modal-footer", {}, undefined, true)
            ]),
            _: 3
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["contentWidthFull"]))
}