import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {ScheduleSlotTransfer} from "@/open_api/generated";
import ScheduleSlot from "@/lib/scheduling/model/ScheduleSlot";
import moment from "moment";

export default class ScheduleSlotTransferToScheduleSlotConverter extends AbstractConverter<ScheduleSlotTransfer, ScheduleSlot>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: ScheduleSlotTransfer): ScheduleSlot
	{
		if (!from)
		{
			return null;
		}

		return new ScheduleSlot(moment(from.start_date_time), from.duration, from.provider_id);
	}
}
