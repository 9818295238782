
	import {Options, Prop, Vue} from "vue-property-decorator";
	import VideoViewControls from "@/components/telehealth/VideoViewControls.vue";
	import {VideoControlState} from "@/lib/telehealth/models/telehealth.types";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import VideoFilter from "@/components/telehealth/VideoFilter.vue";
	import DeviceInfo from "@/lib/DeviceInfo";

	@Options({components: {VideoFilter, AppChip, VideoViewControls}})
	export default class VideoView extends Vue
	{
		@Prop(Object) public readonly state: VideoControlState;
		@Prop({type: Boolean, default: false}) allowViewCycle: boolean;
		@Prop({type: Boolean, default: false}) overrideTopControls: boolean;

		public uiTimeout: NodeJS.Timeout = null;

		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		public mounted()
		{
			this.onViewportSizeChange();
			window.addEventListener("resize", this.onViewportSizeChange);
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public handlePublisherClick()
		{
			if (this.state.muted)
			{
				this.$emit("mute");
			}
		}

		public async openChat()
		{
			this.$emit("chat");
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		/**
		 * FireFox has terrible filter performance. Only enable for
		 * Chrome browsers right now.
		 */
		get videoFilterEnabled(): boolean
		{
			return DeviceInfo.isChrome();
		}

		// ==========================================================================
		// Private Methods
		// ==========================================================================

		private onViewportSizeChange()
		{
			// Account for iOS address bar. Basically height 100vh == 105% on iOS.
			if (this.$refs["video-view"])
			{
				const viewPortHeight = window.innerHeight;
				(this.$refs["video-view"] as HTMLElement).style.setProperty("--video-view-height", `${viewPortHeight}px`);
			}
		}
	}
