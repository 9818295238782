import TelehealthApiServiceInterface from "@/lib/telehealth/api/TelehealthApiServiceInterface";
import {SessionData} from "@/lib/telehealth/models/sessionData.model";
import {BrowserInfo} from "detect-browser";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import LogEvent from "@/lib/telehealth/models/LogEvent";
import {TeleCallState} from "@/lib/telehealth/models/telehealth.types";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import {ClinicAPI, PatientAPI} from "@/lib/services/Api";
import AuthStore from "@/lib/vuex/auth.store";
import ChatMessageToTelehealthChatMessageDtoConverter from "@/lib/telehealth/conversion/ChatMessageToTelehealthChatMessageDtoConverter";
import {TelehealthChatMessageDto} from "@/open_api/generated";
import TelehealthChatMessageDtoToChatMessageConverter from "@/lib/telehealth/conversion/TelehealthChatMessageDtoToChatMessageConverter";

export default class ClinicUserRegularTelehealthApiService implements TelehealthApiServiceInterface
{
	protected _appointmentId: string;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(appointmentId)
	{
		this._appointmentId = appointmentId;
	}

	// ==========================================================================
	// TelehealthApiServiceInterface Implementation
	// ==========================================================================

	public async fetchSessionData(): Promise<SessionData>
	{
		return new SessionData((await ClinicAPI().getProviderTelehealthSessionData(this._appointmentId)).data);
	}

	public async getLocalClientData(browserInfo: BrowserInfo): Promise<TelehealthClient>
	{
		const user = AuthStore.loggedInUser;

		return new TelehealthClient(
			browserInfo,
			user.email,
			user.firstName,
			user.lastName,
			user.userType,
		);
	}

	public async logEvent(event: LogEvent): Promise<void>
	{
		ClinicAPI().logEventToProviderTelehealthSession(
			this._appointmentId,
			event.asTelehealthLogEventDto);
	}

	public async pingSession(callState: TeleCallState): Promise<void>
	{
		// clinic users do not ping sessions.
	}

	public async recordChatMessage(message: ChatMessage): Promise<ChatMessage>
	{
		const chatMessageDto: TelehealthChatMessageDto = (await ClinicAPI().addClinicUserChatMessageToAppointment(
			this._appointmentId,
			new ChatMessageToTelehealthChatMessageDtoConverter().convert(message))).data;
		return new TelehealthChatMessageDtoToChatMessageConverter().convert(chatMessageDto);
	}

	/**
	 * load chat history from DB.
	 * @return array of chat messages for the telehealth appointment.
	 */
	public async getChatHistory(): Promise<ChatMessage[]>
	{
		return new TelehealthChatMessageDtoToChatMessageConverter()
			.convertList((await ClinicAPI().getClinicUserAppointmentChatMessages(this._appointmentId)).data);
	}
}
