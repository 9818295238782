
	import ClinicsFindHeader from "@/views/patient_user/clinic/components/ClinicsFindHeader.vue";
	import {ClinicsFindController} from "@/views/patient_user/clinic/ClinicsFindController";
	import {Clinic} from "@/open_api/generated";
	import ClinicCard from "@/components/Buttons/ClinicCard.vue";
	import { Options, Vue, Prop, Watch } from "vue-property-decorator";
	import ClinicSearchSpinner from "@/components/EventState/ClinicSearchSpinner.vue";
	import {ClinicSearchType} from "@/components/EventState/types";
	import {ClinicSearchFilter, FilterDropdownOpts} from "@/components/Inputs/types";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {ClinicEvent, EventBus} from "@/lib/utils/EventBus";
	import ClinicModalController from "@/views/patient_user/clinic/ClinicModalController";
	import {RouteType} from "@/router/types";
	import ClinicsFindControllerFactory from "@/views/patient_user/clinic/ClinicsFindControllerFactory";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import { ButtonColor, ButtonColorPattern } from "@/components/Buttons/types";

	@Options({
		components:
			{
				ClinicSearchSpinner,
				ClinicCard,
				ClinicsFindHeader,
				DependentSubheader,
			},
	})
	export default class ClinicsFind extends ClinicModalController
	{
		@Prop({type: Boolean, default: false}) restrictClinics: boolean;

		controller: ClinicsFindController = new ClinicsFindController();
		filters: FilterDropdownOpts[];
		activeFilter: FilterDropdownOpts;
		filterText = null;
		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		afterCreated()
		{
			this.controller = ClinicsFindControllerFactory
				.createClinicsFindControllerByPatient(this.patientUser, this.restrictClinics);
			this.controller.loadClinicsList();
			EventBus.$on(ClinicEvent.CONNECT, this.findClinics.bind(this));

			this.filters = this.clinicSearchFilters;
			this.activeFilter = this.filters[0];
		}

		public onInput(payload)
		{
			this.filterText = payload.value;
		}

		public onSelect(filter: FilterDropdownOpts)
		{
			this.activeFilter = filter;
			this.$forceUpdate();
		}

		public findClinics()
		{
			this.controller.loadClinicsList();
		}

		@Watch("restrictClinics")
		public onRestrictClinicsChange()
		{
			this.controller.restrictClinicList = this.restrictClinics;
			this.findClinics();
		}

		public async goToClinic(clinic: ClinicProfile)
		{
			this.$mhaRouterReplace(
				{
					name: this.$route.name as RouteType,
					params: {clinicId: clinic.id},
				});
		}

		public isActiveFilter(filterType: ClinicSearchFilter)
		{
			return this.activeFilter.id === filterType;
		}

		get spinnerFailure()
		{
			return {
				title: this.$mhat("ClinicsFind.NoClinicsFoundTitle"),
				message: this.$mhat("ClinicsFind.NoClinicsFoundMessage"),
			};
		}

		get searchType()
		{
			return ClinicSearchType.UNCONNECTED;
		}

		get filteredClinics(): Clinic[]
		{
			if (this.filterText)
			{
				const lowerFilterText = this.filterText.toLowerCase();

				if (this.isActiveFilter(ClinicSearchFilter.Clinic))
				{
					return this.clinics.filter((clinic) =>
					{
						return clinic.name.toLowerCase().includes(lowerFilterText);
					});
				}
				else if (this.isActiveFilter(ClinicSearchFilter.Provider))
				{
					return this.clinics.filter((clinic: ClinicProfile) =>
					{
						return clinic.providerNames
							.map((name) => name.toLowerCase())
							.some((name) => name.includes(lowerFilterText));
					});
				}
			}

			return this.clinics;
		}

		get clinics(): ClinicProfile[]
		{
			if (this.controller && this.controller.clinics)
			{
				return this.controller.clinics;
			}

			return [];
		}

		get shouldShowSpinner()
		{
			return this.controller.isLoading || this.controller.noClinics;
		}

		get isDependentMode()
		{
			return this.patientUser.isDependent;
		}

		get clinicSearchFilters()
		{
			return [
				{
					name: "Clinic Name",
					id: ClinicSearchFilter.Clinic,
					icon: "icon-clinic",
					placeholder: this.$mhat("ClinicsFind.SearchForClinicsPlaceholder"),
				},
				{
					name: "Provider Name",
					id: ClinicSearchFilter.Provider,
					icon: "icon-user-md",
					placeholder: this.$mhat("ClinicsFind.SearchByProvidersPlaceholder"),
				},
			];
		}
	}
