import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd16b8a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "review-cards" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "card center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "card" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReviewCard = _resolveComponent("ReviewCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ReviewCard, {
        "reviewer-name": "Jelena D",
        "reviewer-location": "Surrey BC",
        "reviewer-message": _ctx.reviewTextA
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.imgReviewerA,
            alt: "reviewer"
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["reviewer-message"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ReviewCard, {
        large: "",
        "reviewer-name": "Jacqueline M",
        "reviewer-location": "Guelph ON",
        "reviewer-message": _ctx.reviewTextB
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.imgReviewerB,
            alt: "reviewer"
          }, null, 8, _hoisted_5)
        ]),
        _: 1
      }, 8, ["reviewer-message"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_ReviewCard, {
        "reviewer-name": "Giovanna C",
        "reviewer-location": "Vancouver BC",
        "reviewer-message": _ctx.reviewTextC
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.imgReviewerC,
            alt: "reviewer"
          }, null, 8, _hoisted_7)
        ]),
        _: 1
      }, 8, ["reviewer-message"])
    ])
  ]))
}