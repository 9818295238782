import {Mailbox} from "@/lib/Mailbox/Mailbox.model";
import {MailAttachment} from "@/open_api/generated";
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";

export enum MailboxType
{
	Inbox = "received",
	Sent = "sent",
	Archive = "archived",
}

export enum ArchiveType
{
	Archive,
	Unarchive,
}

export interface Mailboxes
{
	[MailboxType.Inbox]: Mailbox;
	[MailboxType.Sent]: Mailbox;
	[MailboxType.Archive]: Mailbox;
}

interface SelectStatus
{
	selected: boolean;
	archived: boolean;
}

export interface MailSelectStatus
{
	[key: string]: SelectStatus;
}

export interface SelectedMail
{
	messageId: string;
	mailboxType: MailboxType;
}

export interface ComposedMessage
{
	subject: string;
	body: string;
}

export interface ComposedMail
{
	clinic: ClinicProfile;
	message: ComposedMessage;
	conversationId: string;
	attachments: MailAttachment[];
}
