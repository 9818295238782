

	import {Options, Vue} from "vue-property-decorator";
	import {modalController} from "@ionic/vue";

	@Options({})
	export default class ContactSupportModal extends Vue
	{
		public dismiss()
		{
			modalController.dismiss();
		}
	}
