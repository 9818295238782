import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "login-form",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
  }, [
    _createVNode(_component_BaseInput, {
      compact: "",
      "padding-left": "",
      type: "text",
      "icon-left": "icon-mail",
      autocapitalize: "off",
      placeholder: this.$mhat('LoginForm.EmailFieldDescription'),
      label: this.$mhat('LoginForm.EmailFieldDescription'),
      hideLabel: true,
      id: "email",
      onInput: _ctx.setEmail,
      valid: _ctx.isValidEmail,
      required: true,
      dirty: this.hasSubmitted
    }, null, 8, ["placeholder", "label", "onInput", "valid", "dirty"]),
    _createVNode(_component_PasswordField, {
      value: _ctx.userInfo.password,
      valid: _ctx.isPasswordValid,
      required: true,
      dirty: this.hasSubmitted,
      hideLabel: true,
      placeholder: this.$mhat('LoginForm.PasswordFieldDescription'),
      label: this.$mhat('LoginForm.PasswordFieldDescription'),
      onInput: _cache[0] || (_cache[0] = (payload) => {_ctx.userInfo.password = payload.value})
    }, null, 8, ["value", "valid", "dirty", "placeholder", "label"]),
    _createVNode(_component_BaseButton, {
      type: "submit",
      color: _ctx.ButtonColor.PRIMARY,
      pattern: _ctx.ButtonColorPattern.FILL
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.$mhat("LoginForm.LoginButtonText")), 1)
      ]),
      _: 1
    }, 8, ["color", "pattern"]),
    _createVNode(_component_BaseButton, {
      color: _ctx.ButtonColor.PRIMARY,
      pattern: _ctx.ButtonColorPattern.FILL,
      onClick: _ctx.onSignup
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.$mhat("LoginForm.SignUpButtonText")), 1)
      ]),
      _: 1
    }, 8, ["color", "pattern", "onClick"]),
    _createVNode(_component_BaseButton, {
      color: _ctx.ButtonColor.PRIMARY,
      pattern: _ctx.ButtonColorPattern.TRANSPARENT,
      noTextTransform: "",
      onClick: _ctx.onForgotPassword
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.$mhat("LoginForm.ForgotPasswordButtonText")), 1)
      ]),
      _: 1
    }, 8, ["color", "pattern", "onClick"])
  ], 32))
}