import PatientClinicService from "@/lib/clinics/PatientClinic.service";
import {MailboxService} from "@/lib/Mailbox/Mailbox.service";
import PublicClinicService from "@/lib/clinics/PublicClinic.service";
import DependentClinicService from "@/lib/clinics/DependentClinic.service";
import {ProviderLoginService} from "@/lib/services/login/ProviderLogin.service";
import {PatientLoginService} from "@/lib/services/login/PatientLogin.service";
import SignupService from "@/lib/Signup/Signup.service";
import PatientService from "@/lib/services/PatientService";
import {KioskLoginService} from "@/lib/services/login/KioskLogin.service";

/**
 * @deprecated - Directly construct service instances or
 *               if you want to make a singleton service use "private" class with const export.
 */
export default class Services
{
	private static readonly patientClinicService = new PatientClinicService();
	private static readonly publicClinicService = new PublicClinicService();
	private static readonly mailBoxService = new MailboxService();
	private static readonly signupService = new SignupService();

	public static get PatientLogin(): PatientLoginService
	{
		return new PatientLoginService();
	}

	public static get ProviderLogin(): ProviderLoginService
	{
		return new ProviderLoginService();
	}

	public static get KioskLogin(): KioskLoginService
	{
		return new KioskLoginService();
	}

	public static get PatientClinics(): PatientClinicService
	{
		return this.patientClinicService;
	}

	public static DependentClinics(dependentId): DependentClinicService
	{
		return new DependentClinicService(dependentId);
	}

	public static get PublicClinics(): PublicClinicService
	{
		return this.publicClinicService;
	}

	public static get MailBox(): MailboxService
	{
		return this.mailBoxService;
	}

	public static get Signup(): SignupService
	{
		return this.signupService;
	}

	public static get PatientService(): PatientService
	{
		return PatientService;
	}
}
