

	import {Options, Vue} from "vue-property-decorator";

	import HomePageHeaderBar from "./components/HomePageHeaderBar.vue";
	import NotificationService from "@/components/Notification/NotificationService";
	import DeviceInfo from "@/lib/DeviceInfo";
	import BannerNotificationContainer from "@/components/Notification/BannerNotificationContainer.vue";
	import {Patient} from "@/lib/patient/Patient";
	import {HomeActionSheetButtons} from "@/views/patient_user/home/types";
	import DependentStore from "@/lib/vuex/modules/Dependent.store";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import HomePageLinks from "@/views/patient_user/home/HomePageLinks.vue";
	import {PatientAPI} from "@/lib/services/Api";
	import FBService from "@/lib/FB/FBService";
	import {TabBarItem} from "@/components/Menu/types";
	import {PatientNotification} from "@/lib/models/PatientNotification";
	import {NativeBridge} from "@/lib/NativeBridge";
	import {actionSheetController} from "@ionic/vue";
	import BannerNotification from "@/lib/notification/model/BannerNotification";
	import PatientNotificationService from "@/lib/notification/service/PatientNotificationService";
	import PatientService from "@/lib/services/PatientService";
	import DependentService from "@/lib/dependents/Dependent.service";

	@Options({components: {HomePageLinks, HomePageHeaderBar, BannerNotificationContainer}})
	export default class HomePage extends Vue
	{
		dependents: Patient[] = [];
		isMobile: boolean = DeviceInfo.isMobile();
		isLoading = true;
		notifications: BannerNotification[] = null;

		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		async created()
		{
			if (!this.$isCloudMd)
			{
				NotificationService.hasConnectedClinics();
			}

			await DependentStore.fetchDependents();
			this.fetchNotifications();
			BookingControllerStore.setBookingController(null);

			this.setNativePushTokens();
			PatientService.updatePatientTzToCurrentBrowserTz();
			DependentService.updateAllDependentTzToCurrentBrowserTz();
			FBService.logViewContent();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public routeToMessages()
		{
			this.$mhaRouterPush(this.Route.Inbox.Home);
		}

		public routeToAppointments()
		{
			this.$mhaRouterPush(this.Route.Appointments.Home);
		}

		public async fetchNotifications()
		{
			this.notifications = await (new PatientNotificationService()).getAllPatientNotifications();
		}

		public async presentActionSheet()
		{
			const popover = await actionSheetController.create({
				animated: true,
				backdropDismiss: true,
				buttons: HomeActionSheetButtons.call(this),
			});

			return await popover.present();
		}

		public logout()
		{
			this.$logout(this.Route.Login.Home);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get tabBarItems(): Array<any>
		{
			return [
				{
					icon: "icon-calendar",
					label: this.$mhat("HomePage.TabBarAppointmentLabel"),
					click: () => this.routeToAppointments(),
				} as TabBarItem,
				{
					icon: "icon-user",
					label: this.$mhat("HomePage.TabBarAccountLabel"),
					click: () => this.presentActionSheet(),
				} as TabBarItem,
				{
					icon: "icon-chat",
					label: this.$mhat("HomePage.TabBarMessagesLabel"),
					click: () => this.routeToMessages(),
				} as TabBarItem,
			];
		}

		get hideExtraHeaderSpace()
		{
			return (this.notifications && this.notifications.length > 0);
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		/**
		 * set native push tokens if applicable
		 */
		protected setNativePushTokens(): void
		{
			NativeBridge.setPushTokens();
		}
	}
