

	import {Options, Prop, Vue} from "vue-property-decorator";

	import {getResources} from "@/lib/utils/Resource";
	import DeviceInfo from "@/lib/DeviceInfo";
	import OrgImage from "@/components/Organization/OrgImage.vue";
	import {IMAGE_BRANDED} from "@/assets/AppIcons";
	import {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";
	import ContactSupportModal from "@/views/public/ContactSupportModal.vue";
	import {modalController} from "@ionic/vue";

	@Options({components: {OrgImage}})
	export default class CorporateFooter extends Vue
	{
		@Prop({type: Boolean}) stick: boolean;
		@Prop({type: Boolean}) branded: boolean;
		@Prop({type: Boolean}) brandWhiteBg: boolean;
		@Prop({type: Boolean}) brandGreyBg: boolean;
		@Prop({type: Boolean}) orgLogo: boolean;

		@Prop({type: Boolean, default: true}) showSupportLink: boolean;
		@Prop({type: Boolean, default: true}) showTermsLink: boolean;
		@Prop({type: Boolean, default: true}) showPrivacyLink: boolean;

		public text = {
			name: getResources().app_name,
		};

		public modal = null;
		public IMAGE_BRANDED = IMAGE_BRANDED;
		public ORG_ASSET_TYPE = ORG_ASSET_TYPE;

		public async openSupportModal()
		{
			this.modal = await modalController
				.create({
					component: ContactSupportModal,
					showBackdrop: true,
					cssClass: "fullscreen",
				});
			await this.modal.present();
		}

		// navigate to terms and conditions
		public toTerms(): void
		{
			this.$mhaRouterPush(this.Route.Public.TermsConditions);
		}

		// navigate to privacy policy
		public toPrivacyPolicy(): void
		{
			this.$mhaRouterPush(this.Route.Public.PrivacyPolicy);
		}

		get brandName()
		{
			return getResources().app_name;
		}

		get brandIcon()
		{
			return DeviceInfo.getAppFontIcon();
		}

		get showFirstSeparator()
		{
			return (this.showSupportLink && (this.showTermsLink || this.showPrivacyLink));
		}

		get showSecondSeparator()
		{
			return (this.showTermsLink && this.showPrivacyLink);
		}
	}
