import {Vue} from "vue-property-decorator";
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import {PendingPatientResponse} from "@/lib/types/Clinic";
import {ClinicBookingStatus, ClinicConnectionRequest, ErrorCode} from "@/open_api/generated";
import Services from "@/lib/services/Services";
import PatientProfileModal from "@/views/patient_user/clinic/components/PatientProfileModal.vue";
import Provider from "@/lib/clinic/providerProfile.model";
import {defaultToastOpts} from "@/lib/utils/helpers/ionic";
import DependentStore from "@/lib/vuex/modules/Dependent.store";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import {ClinicEvent, EventBus} from "@/lib/utils/EventBus";
import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
import {Route} from "@/router/router";
import {Patient} from "@/lib/patient/Patient";
import ClinicService from "@/lib/clinics/Clinic.service";
import {alertController, loadingController, modalController, toastController} from "@ionic/vue";
import NotificationService from "@/components/Notification/NotificationService";
import {$mhat} from "@/i18n";

export class ClinicPageController
{
	private _clinic: ClinicProfile = null;
	private _providers: Provider[] = [];
	private _instance: Vue = null;
	private _patientUser: Patient = null;

	private _isLoading = false;
	private _gettingClinic = false;
	protected _clinicService: ClinicService = null;

	// TODO: Implement connection requests, also in signup service, also in branded signup modal
	private _connectionRequest: ClinicConnectionRequest = {
		patient_request: {
			patient_connect_message: "",
		},
	};

	// TODO remove passing of vue components to services
	constructor(instance: Vue, patientUser: Patient)
	{
		this.instance = instance;
		this.patientUser = patientUser;
		this._clinicService = Services.PatientClinics;
	}

	public getClinic(clinicId)
	{
		return this.clinicService.getClinicNoCache(clinicId)
			.then((clinic) =>
			{
				this.clinic = clinic;
			})
			.finally(() =>
			{
				this.gettingClinic = false;
			});
	}

	public handlePatientConfirmationResponse(response: PendingPatientResponse)
	{
		if (response === PendingPatientResponse.Confirm)
		{
			this.connectToClinic();
		}
		else
		{
			this.promptCancelConfirmation($mhat("ClinicPageController.RejectConnectionMessage"));
		}
	}

	public async routeToBookAppointment()
	{
		// @ts-ignore
		const {parentCtx} = this.instance;

		let route = this.clinic.bookingRoute;

		const clinicProviderGroups = await this.clinicService.getClinicProviderGroups(this.clinic.id);

		if (clinicProviderGroups.length > 0)
		{
			route = this.clinic.providerGroupBookingRoute;
		}

		if (DependentStore.dependents.length > 0)
		{
			route = Route.Booking.Home;
		}

		parentCtx.$mhaRouterPush(route);
	}

	public cancelClinicConnection()
	{
		this.isLoading = true;
		this.promptCancelConfirmation($mhat("ClinicPageController.RemoveConnectionMessage"));
	}

	public async connectToClinic()
	{
		const updateLoader = await this.showLoadingSpinner();
		const clinicId = this.clinic.id;

		return this.clinicService.connectToClinic(clinicId, this.connectionRequest)
			.then((clinic: ClinicProfile) =>
			{
				EventBus.$emit(ClinicEvent.CONNECT);

				this.clinic = clinic;
				this.notifyConnection($mhat("ClinicPageController.ConnectionRequestSentMessage"));
			},
			(errorResponse: ErrorResponse) =>
			{
				this.handleClinicConnectionError(errorResponse);
			})
			.finally(() =>
			{
				this.isLoading = false;
				updateLoader.dismiss();
				this.instance.$forceUpdate();
			});
	}

	/*
	============= Protected Methods =================
	*/

	/**
	 * Handle a clinic connection error. prompting the user for
	 * more input if required.
	 * @param errorResponse - clinic connection error
	 */
	protected handleClinicConnectionError(errorResponse: any)
	{
		if (errorResponse && errorResponse instanceof ErrorResponse)
		{
			if (errorResponse.is(ErrorCode.IncompleteProfile))
			{
				this.openProfileModal(errorResponse.data.required_fields);
			}
			else if (errorResponse.is(ErrorCode.EligibilityCheckFailed))
			{
				WebNotification.$emit({
					event: NotifyEvent.Generic,
					type: NotificationType.Dismiss,
					severity: NotificationSeverity.Reminder,
					title: $mhat("ClinicPageController.HealthCardIneligibleMessage"),
					message: errorResponse.message,
				});

				this.openProfileModal(errorResponse.data.required_fields);
			}
			else if (errorResponse.is(ErrorCode.RemotePatientInactive))
			{
				WebNotification.$emit({
					event: NotifyEvent.Generic,
					type: NotificationType.Dismiss,
					severity: NotificationSeverity.Warning,
					title: $mhat("ClinicPageController.ConnectionProblemErrorMessage"),
					message: errorResponse.message,
				});
			}
			else if (errorResponse.is(ErrorCode.UserFriendly))
			{
				NotificationService.notificationDismiss($mhat("ClinicPageController.GenericConnectionErrorTitle"), errorResponse.message, NotificationSeverity.Critical);
			}
		}
		else
		{
			WebNotification.$emit({
				event: NotifyEvent.Generic,
				type: NotificationType.Dismiss,
				severity: NotificationSeverity.Critical,
				title: $mhat("ClinicPageController.GenericConnectionErrorTitle"),
				message: $mhat("ClinicPageController.GenericConnectionErrorMessage"),
			});
		}
	}

	/**
	 * display loading spinner to user
	 */
	protected async showLoadingSpinner(): Promise<HTMLIonLoadingElement>
	{
		this.isLoading = true;
		let updateLoader: HTMLIonLoadingElement = null;

		updateLoader = await loadingController.create({});
		updateLoader.present();
		return updateLoader;
	}

	protected openProfileModal(requiredFields = null)
	{
		const props = {
			active: true,
			clinic: this.clinic,
			loading: this.isLoading,
			handleSubmit: this.connectToClinic.bind(this),
			visibleProfileFields: requiredFields,
		};

		modalController
			.create({
				// @ts-ignore
				component: PatientProfileModal,
				showBackdrop: true,
				componentProps: props,
			})
			.then((modal) =>
			{
				modal.present();
			});
	}

	protected async disconnectFromClinic()
	{
		this.isLoading = true;
		let updateLoader: HTMLIonLoadingElement = null;
		const clinicId = this.clinic.id;

		updateLoader = await loadingController.create({});
		updateLoader.present();

		return this.clinicService.disconnectFromClinic(clinicId)
			.then((clinic: ClinicProfile) =>
			{
				this.clinic = clinic;
				this.notifyConnection($mhat("ClinicPageController.DisconnectedMessage"));
				modalController.dismiss();
			})
			.finally(() =>
			{
				this.isLoading = false;
				updateLoader.dismiss();
				this.instance.$forceUpdate();
			});
	}

	protected promptCancelConfirmation(message: string)
	{
		return alertController
			.create({
				header: $mhat("ClinicPageController.RemoveClinicAlertTitle"),
				message: $mhat("ClinicPageController.RemoveClinicAlertMessage", {clinicName: this.clinic.name}),
				buttons: [$mhat("ClinicPageController.BackButtonText"),
					{
						text: $mhat("ClinicPageController.ConfirmButtonText"),
						handler: () =>
						{
							this.disconnectFromClinic();
						},
					}],
			})
			.then((a) => a.present())
			.finally(() => this.isLoading = false);
	}

	protected async notifyConnection(message: string)
	{
		const currentToast = await toastController.getTop();

		if (currentToast)
		{
			await currentToast.dismiss();
		}

		const toast = await toastController.create(Object.assign(defaultToastOpts, {message}) as any);
		await toast.present();
	}

	get clinicService(): ClinicService
	{
		return this._clinicService;
	}

	get isConnectionPendingPatientConfirm()
	{
		return this.clinic.pendingPatientConfirm;
	}

	get bookingStatus(): ClinicBookingStatus
	{
		if (this.clinic)
		{
			return this.clinic.bookingStatus;
		}
		return ClinicBookingStatus.Unavailable;
	}

	/* Instance property getters & setters */

	get clinic(): ClinicProfile
	{
		return this._clinic;
	}

	set clinic(value: ClinicProfile)
	{
		this._clinic = value;
	}

	get connectionRequest(): ClinicConnectionRequest
	{
		return this._connectionRequest;
	}

	set connectionRequest(value: ClinicConnectionRequest)
	{
		this._connectionRequest = value;
	}

	get instance(): Vue
	{
		return this._instance;
	}

	set instance(value: Vue)
	{
		this._instance = value;
	}

	get isLoading(): boolean
	{
		return this._isLoading;
	}

	set isLoading(value: boolean)
	{
		this._isLoading = value;
	}

	get gettingClinic(): boolean
	{
		return this._gettingClinic;
	}

	set gettingClinic(value: boolean)
	{
		this._gettingClinic = value;
	}

	get providers(): Provider[]
	{
		return this._providers;
	}

	set providers(value: Provider[])
	{
		this._providers = value;
	}

	get patientUser(): Patient
	{
		return this._patientUser;
	}

	set patientUser(value: Patient)
	{
		this._patientUser = value;
	}
}
