import {AppointmentType, ProvinceCode} from "../../open_api/generated";
import {PatientAPI} from "../services/Api";
import ClinicService from "@/lib/clinics/Clinic.service";
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import ProviderGroup from "@/lib/clinic/providerGroup.model";
import ProviderTransferToProviderConverter from "@/lib/clinic/converter/ProviderTransferToProviderConverter";

export default class PatientClinicService extends ClinicService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor()
	{
		super();
	}

	public async getClinic(clinicId: string, requiredFields?: boolean): Promise<ClinicProfile>
	{
		const response = await this.patientClinic(clinicId, requiredFields);
		return new ClinicProfile(response.data);
	}

	public async getCloudMdClinic(requiredFields?: boolean, provinceCode?: ProvinceCode): Promise<ClinicProfile>
	{
		const response = await PatientAPI().getCloudMdClinic(provinceCode, requiredFields);
		return new ClinicProfile(response.data.clinic);
	}

	public async patientClinicProvider(clinicId, providerId)
	{
		const response = await PatientAPI().patientClinicProvider(clinicId, providerId);
		return new ProviderTransferToProviderConverter().convert(response.data);
	}

	public async clinicAvailableAppointments(clinicId, providerId, appointmentTypeId, startDate, endDate)
	{
		return PatientAPI().patientClinicAvailableAppointments(clinicId, providerId, appointmentTypeId, startDate, endDate);
	}

	public async connectToClinic(clinicId, connectionRequest?): Promise<ClinicProfile>
	{
		if (!connectionRequest)
		{
			connectionRequest = {
				patient_request: {
					patient_registered: true,
					patient_connect_message: "",
				},
			};
		}

		const {data} = await PatientAPI().linkWithClinic(clinicId, connectionRequest);
		return new ClinicProfile(data.clinic);
	}

	public async disconnectFromClinic(clinicId): Promise<ClinicProfile>
	{
		const {data} = await PatientAPI().unlinkWithClinic(clinicId);
		return new ClinicProfile(data.clinic);
	}

	public async getAppointmentTypes(clinicId, providerId): Promise<AppointmentType[]>
	{
		const {data} = await PatientAPI().patientClinicAppointmentTypes(clinicId, providerId);
		return data;
	}

	public async getAppointmentType(clinicId, appointmentTypeId): Promise<AppointmentType>
	{
		const {data} = await PatientAPI().patientClinicAppointmentType(clinicId, appointmentTypeId);
		return data;
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	protected async viewableClinicsInLocRange(provinceCode, city, address, postalCode)
	{
		return PatientAPI().viewableClinicsInLocRange(provinceCode, city, address, postalCode);
	}

	protected async viewableClinicsInCoordRange(latitude, longitude)
	{
		return PatientAPI().viewableClinicsInCoordRange(latitude, longitude);
	}

	protected async patientClinics(bookableOnly, messageableOnly, availableOnly, connectedOnly)
	{
		return PatientAPI().patientClinics(bookableOnly, messageableOnly, availableOnly, connectedOnly);
	}

	protected async patientClinic(clinicId, requiredFields?: boolean)
	{
		return PatientAPI().getClinic(clinicId, requiredFields);
	}

	protected async patientClinicProviderGroup(clinicId, providerGroupId)
	{
		return PatientAPI().patientClinicProviderGroup(clinicId, providerGroupId);
	}

	protected async patientClinicProviderGroups(clinicId)
	{
		return await PatientAPI().patientClinicProviderGroups(clinicId);
	}

	protected async patientClinicProviderGroupsByProvider(clinicId, providerId)
	{
		return await PatientAPI().patientClinicProviderGroupsByProvider(clinicId, providerId);
	}

	protected async patientClinicProviderGroupProviders(clinicId, providerGroupId)
	{
		return await PatientAPI().patientClinicProviderGroupProviders(clinicId, providerGroupId);
	}

	protected async patientClinicProviders(clinicId)
	{
		return await PatientAPI().patientClinicProviders(clinicId);
	}
}
