export interface Timer
{
	start: () => string;
	stop: () => string;
	pause: () => string;
}

export function numPadLeft(timeStr: number, pad: string, len: number): string
{
	return (new Array(len + 1).join(pad) + timeStr).slice(-len);
}

export function formatSecondsDuration(seconds: number): string
{
	const mins = Math.floor(seconds / 60);
	const secs = seconds % 60;

	return `${numPadLeft(mins, "0", 2)}:${numPadLeft(secs, "0", 2)}`;
}

export function createTimer(cb: (time: string) => void, interval: number): Timer
{
	let seconds = 0;
	let timer: NodeJS.Timeout = null;

	function start(): string
	{
		timer = setInterval(() =>
		{
			seconds++;
			cb(formatSecondsDuration(seconds));
		}, interval);

		return formatSecondsDuration(seconds);
	}

	function stop(): string
	{
		clearInterval(timer);
		timer = null;
		seconds = 0;

		return formatSecondsDuration(seconds);
	}

	function pause(): string
	{
		clearInterval(timer);
		return formatSecondsDuration(seconds);
	}

	cb(formatSecondsDuration(seconds));

	return { start, stop, pause };
}
