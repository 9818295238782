

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {IonSplitPane} from "@ionic/vue";

	@Options({components: {IonSplitPane}})
	export default class SplitView extends Vue
	{
		@Prop({type: Boolean}) hideLeft: boolean;
		@Prop({type: Boolean}) hideRight: boolean;
		@Prop({type: Boolean}) noHeader: boolean;
	}
