

	import {Options, Prop, Vue} from "vue-property-decorator";
	import MailHeader from "./components/MailHeader.vue";
	import MessageView from "./MessageViewModal.vue";
	import MessageFeed from "./MessageFeed.vue";
	import {MailboxController} from "@/lib/Mailbox/Mailbox.controller";
	import {MailboxType} from "@/lib/Mailbox/Mailbox.types";
	import {ModalOptions} from "@ionic/core";
	import MessageRead from "@/views/patient_user/mail/components/MessageRead.vue";
	import {modalController} from "@ionic/vue";
	import {ref, Ref, unref, UnwrapRef} from "vue";

	@Options({components: {MessageFeed, MailHeader, MessageView, MessageRead}})
	export default class MailHome extends Vue
	{
		// if true back buttons will be shown in the top bar.
		@Prop({type: Boolean, default: false}) hasBackButtons: boolean;

		// TODO remove usage of controller.
		public readonly controller = ref(new MailboxController(MailboxType.Inbox));
		// eslint-disable-next-line no-undef
		public modal: HTMLIonModalElement = null;

		public async created(): Promise<void>
		{
			await unref(this.controller).getMessageableClinics();

			if (this.compose)
			{
				this.onCompose();
			}
		}

		public onMailSelected()
		{
			if (this.$isMobile && !unref(this.controller).selectModeActive)
			{
				this.showReadModal();
			}
		}

		public onCompose()
		{
			if (!unref(this.controller).selectModeActive)
			{
				this.showComposeModal();
			}
		}

		public showReadModal()
		{
			this.showModal(false);
		}

		public showComposeModal()
		{
			this.showModal(true, false);
		}

		public showReplyModal()
		{
			this.showModal(true, true);
		}

		public showModal(composeMode: boolean, isReply = false)
		{
			this.dismissModal();
			unref(this.controller).isComposing = composeMode;
			if (composeMode && isReply)
			{
				unref(this.controller).replyToActive();
			}
			this.createModal(MessageView, {
				controller: unref(this.controller),
				composeOnly: !this.$isMobile,
			})
				.then(async (modal) =>
				{
					this.modal = modal;
					this.modal.present();

					await this.modal.onDidDismiss();
					this.modal = null;
				});
		}

		public dismissModal()
		{
			unref(this.controller).cancelComposing();

			if (this.modal)
			{
				this.modal.dismiss();
				this.modal = null;
			}
		}

		public createModal(component: typeof Vue, propsData)
		{
			return modalController
				.create({
					component,
					showBackdrop: true,
					componentProps: propsData,
					cssClass: "modal-size-med",
				} as ModalOptions);
		}

		public archiveMail()
		{
			unref(this.controller).setSelectedArchived();
			this.dismissModal();
		}

		get showSpinner()
		{
			return unref(this.controller).isLoading && !this.modal;
		}

		get allMail()
		{
			return unref(this.controller).activeMailbox.mail;
		}

		get compose()
		{
			return this.$route.query.compose;
		}

		get clinicId()
		{
			return this.$route.query.clinicId;
		}
	}
