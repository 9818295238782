
	export default {
		name: "HeaderBarBooking",

		props: {
			headerText: {
				type: String,
				required: false,
			},
			hasClose: {
				type: Boolean,
				default: true,
			},
			noBack: {
				type: Boolean,
				default: false,
			},
			hidden: Boolean,
		},

		data()
		{
			return {
				headerProps: {
					icon: "icon-calendar-add",
					hasClose: this.hasClose,
					noBack: this.noBack,
				},
			};
		},

		computed:
			{
				getHeaderText()
				{
					return this.headerText ? this.headerText : this.$mhat("BookingHeaderBar.DefaultHeaderText");
				},
			},
	};
