import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrgImage = _resolveComponent("OrgImage")!

  return (_openBlock(), _createBlock(_component_OrgImage, {
    "asset-type": _ctx.logoType,
    "alt-image": _ctx.IMAGE_BRANDED,
    "image-width": "368px"
  }, null, 8, ["asset-type", "alt-image"]))
}