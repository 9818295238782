import {ProvinceCode} from "@/open_api/generated";
import {$mhat} from "@/i18n";

export const TimeZones = [
	{name: "Pacific Time", value: "Pacific Time (US & Canada)"},
	{name: "Mountain Time", value: "Mountain Time (US & Canada)"},
	{name: "Central Time", value: "Central Time (US & Canada)"},
	{name: "Eastern Time", value: "Eastern Time (US & Canada)"},
	{name: "Atlantic Time", value: "Atlantic Time (Canada)"},
	{name: "Newfoundland Time", value: "Newfoundland"}, // value matches the MHA ruby backend
];

export const Countries = [
	{value: "CA", name: $mhat("Geodata.Countries.CA")},
	{value: "US", name: $mhat("Geodata.Countries.US")}, // name matches the MHA ruby backend
];

export const Provinces = [
	{value: ProvinceCode.AB, name: $mhat("Geodata.Provinces.AB")},
	{value: ProvinceCode.BC, name: $mhat("Geodata.Provinces.BC")},
	{value: ProvinceCode.MB, name: $mhat("Geodata.Provinces.MB")},
	{value: ProvinceCode.NB, name: $mhat("Geodata.Provinces.NB")},
	{value: ProvinceCode.NL, name: $mhat("Geodata.Provinces.NL")},
	{value: ProvinceCode.NS, name: $mhat("Geodata.Provinces.NS")},
	{value: ProvinceCode.NT, name: $mhat("Geodata.Provinces.NT")},
	{value: ProvinceCode.NU, name: $mhat("Geodata.Provinces.NU")},
	{value: ProvinceCode.ON, name: $mhat("Geodata.Provinces.ON")},
	{value: ProvinceCode.PE, name: $mhat("Geodata.Provinces.PE")},
	{value: ProvinceCode.QC, name: $mhat("Geodata.Provinces.QC")},
	{value: ProvinceCode.SK, name: $mhat("Geodata.Provinces.SK")},
	{value: ProvinceCode.YT, name: $mhat("Geodata.Provinces.YT")},
];

export const SubRegions = {
	CA: Provinces,
	US: [
		{value: "AL", name: $mhat("Geodata.States.AL")},
		{value: "AK", name: $mhat("Geodata.States.AK")},
		{value: "AZ", name: $mhat("Geodata.States.AZ")},
		{value: "AR", name: $mhat("Geodata.States.AR")},
		{value: "CA", name: $mhat("Geodata.States.CA")},
		{value: "CO", name: $mhat("Geodata.States.CO")},
		{value: "CT", name: $mhat("Geodata.States.CT")},
		{value: "DE", name: $mhat("Geodata.States.DE")},
		{value: "DC", name: $mhat("Geodata.States.DC")},
		{value: "FL", name: $mhat("Geodata.States.FL")},
		{value: "GA", name: $mhat("Geodata.States.GA")},
		{value: "HI", name: $mhat("Geodata.States.HI")},
		{value: "ID", name: $mhat("Geodata.States.ID")},
		{value: "IL", name: $mhat("Geodata.States.IL")},
		{value: "IN", name: $mhat("Geodata.States.IN")},
		{value: "IA", name: $mhat("Geodata.States.IA")},
		{value: "KS", name: $mhat("Geodata.States.KS")},
		{value: "KY", name: $mhat("Geodata.States.KY")},
		{value: "LA", name: $mhat("Geodata.States.LA")},
		{value: "ME", name: $mhat("Geodata.States.ME")},
		{value: "MD", name: $mhat("Geodata.States.MD")},
		{value: "MA", name: $mhat("Geodata.States.MA")},
		{value: "MI", name: $mhat("Geodata.States.MI")},
		{value: "MN", name: $mhat("Geodata.States.MN")},
		{value: "MS", name: $mhat("Geodata.States.MS")},
		{value: "MO", name: $mhat("Geodata.States.MO")},
		{value: "MT", name: $mhat("Geodata.States.MT")},
		{value: "NE", name: $mhat("Geodata.States.NE")},
		{value: "NV", name: $mhat("Geodata.States.NV")},
		{value: "NH", name: $mhat("Geodata.States.NH")},
		{value: "NJ", name: $mhat("Geodata.States.NJ")},
		{value: "NM", name: $mhat("Geodata.States.NM")},
		{value: "NY", name: $mhat("Geodata.States.NY")},
		{value: "NC", name: $mhat("Geodata.States.NC")},
		{value: "ND", name: $mhat("Geodata.States.ND")},
		{value: "OH", name: $mhat("Geodata.States.OH")},
		{value: "OK", name: $mhat("Geodata.States.OK")},
		{value: "OR", name: $mhat("Geodata.States.OR")},
		{value: "PA", name: $mhat("Geodata.States.PA")},
		{value: "RI", name: $mhat("Geodata.States.RI")},
		{value: "SC", name: $mhat("Geodata.States.SC")},
		{value: "SD", name: $mhat("Geodata.States.SD")},
		{value: "TN", name: $mhat("Geodata.States.TN")},
		{value: "TX", name: $mhat("Geodata.States.TX")},
		{value: "UT", name: $mhat("Geodata.States.UT")},
		{value: "VT", name: $mhat("Geodata.States.VT")},
		{value: "VA", name: $mhat("Geodata.States.VA")},
		{value: "WA", name: $mhat("Geodata.States.WA")},
		{value: "WV", name: $mhat("Geodata.States.WV")},
		{value: "WI", name: $mhat("Geodata.States.WI")},
		{value: "WY", name: $mhat("Geodata.States.WY")},
		// The following are added to mach the ruby backend (values and names)
		{value: "AA", name: $mhat("Geodata.States.AA")},
		{value: "AE", name: $mhat("Geodata.States.AE")},
		{value: "AP", name: $mhat("Geodata.States.AP")},
		{value: "AS", name: $mhat("Geodata.States.AS")},
		{value: "GU", name: $mhat("Geodata.States.GU")},
		{value: "MP", name: $mhat("Geodata.States.MP")},
		{value: "PR", name: $mhat("Geodata.States.PR")},
		{value: "UM", name: $mhat("Geodata.States.UM")},
		{value: "VI", name: $mhat("Geodata.States.VI")},
	],
};
