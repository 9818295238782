import {OrganizationFontType} from "@/open_api/generated";
import FileUtil from "@/lib/utils/FileUtil";

export default class OrganizationFont
{
	private readonly _id: string;
	private readonly _type: OrganizationFontType;
	private readonly _url: URL;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * create new organization font
	 */
	constructor(id: string, type: OrganizationFontType, url: URL)
	{
		this._id = id;
		this._type = type;
		this._url = url;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get id(): string
	{
		return this._id;
	}

	get type(): OrganizationFontType
	{
		return this._type;
	}

	get url(): URL
	{
		return this._url;
	}
}
