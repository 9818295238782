import {DocumentCreateTransfer} from "@/open_api/generated";

export default class DocumentCreateTransferFactory
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * create a new document create transfer
	 * @param shareWithId - depending on the type of user uploading the document this will be a patient id or a clinic id.
	 * @param name - name of the document
	 * @param contentType - file content type (mime type)
	 * @param size - size of the file in bytes
	 * @param base64Data - base64 encoded file data
	 * @return a new document create transfer
	 */
	public create(shareWithId: string, name: string, contentType: string, size: number, base64Data: string): DocumentCreateTransfer
	{
		return {
			share_with_id: shareWithId,
			name,
			content_type: contentType,
			size,
			base64_data: base64Data,
		};
	}
}
