import {RouteAuthType} from "@/router/types";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import WhiteLabelHome from "@/views/public/whitelabel/WhitelabelHome.vue";
import WhiteLabelLogin from "@/views/public/whitelabel/WhitelabelLogin.vue";
import WhiteLabelSignup from "@/views/patient_user/login/SignupPage.vue";
import WhiteLabelLoginForgot from "@/views/patient_user/login/LoginForgot.vue";
import WhiteLabelLoginConfirm from "@/views/patient_user/login/LoginConfirm.vue";
import WhiteLabelLoginReset from "@/views/patient_user/login/LoginReset.vue";

import {Route} from "@/router/router";
import MHABackendLogger from "@/lib/utils/MHABackendLogger";
import {RouteRecordRaw} from "vue-router";

export enum OrganizationRoute
{
	Home = "OrgHome",
	Login = "OrgLogin",
	Signup = "OrgSignup",
	Reset = "OrgReset",
	Forgot = "OrgForgot",
	Confirm = "OrgConfirm",
}

export const orgRoutes: RouteRecordRaw[] = [
	{
		path: "/org/:organizationId/odb",
		name: OrganizationRoute.Home,
		component: WhiteLabelHome,
		props: true,
		meta: {
			routeAuth: RouteAuthType.NoAuth,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/odb/login",
		name: OrganizationRoute.Login,
		component: WhiteLabelLogin,
		props: true,
		meta: {
			routeAuth: RouteAuthType.NoAuth,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/odb/signup",
		name: OrganizationRoute.Signup,
		component: WhiteLabelSignup,
		props: true,
		meta: {
			routeAuth: RouteAuthType.NoAuth,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/odb/login/forgot",
		name: OrganizationRoute.Forgot,
		component: WhiteLabelLoginForgot,
		props: true,
		meta: {
			routeAuth: RouteAuthType.NoAuth,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/login/confirm",
		name: OrganizationRoute.Confirm,
		component: WhiteLabelLoginConfirm,
		props: true,
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/odb/login/reset",
		name: OrganizationRoute.Reset,
		component: WhiteLabelLoginReset,
		props: true,
		meta: {
			routeAuth: RouteAuthType.NoAuth,
			brandedRoute: true,
		},
	},
];

for (const route of orgRoutes)
{
	route.beforeEnter = (to, from, next) =>
	{
		OrganizationStore.loadOrganization(to.params.organizationId as string).then(
			() =>
			{
				next();
			}).catch(
			(error) =>
			{
				console.warn(error);
				MHABackendLogger.warn(error);
				next({name: Route.Public.ErrorPage});
			});
	};
}
