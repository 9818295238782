import {LoginToken} from "@/lib/models/LoginToken";
import {BaseUserDto, UserType} from "@/open_api/generated";
import {RouteType} from "@/router/types";

export class BaseUser
{
	public route = {
		Login: null,
		Home: null,
	};

	public static fromDto(baseUserDto: BaseUserDto, jwt: string): BaseUser
	{
		return null;
	}

	private _id: string;
	protected _email: string;
	protected _firstName: string;
	protected _lastName: string;

	private _loginToken: LoginToken;

	get userType(): UserType
	{
		return this.loginToken.userType;
	}

	get isPatient(): boolean
	{
		return this.userType === UserType.PatientUser;
	}

	get isClinic(): boolean
	{
		return this.userType === UserType.ClinicUser;
	}

	get isKiosk(): boolean
	{
		return this.userType === UserType.KioskUser;
	}

	get id(): string
	{
		return this._id;
	}

	set id(value: string)
	{
		this._id = value;
	}

	get email(): string
	{
		return this._email;
	}

	set email(value: string)
	{
		this._email = value;
	}

	get firstName(): string
	{
		return this._firstName;
	}

	get lastName(): string
	{
		return this._lastName;
	}

	get loginToken(): LoginToken
	{
		return this._loginToken;
	}

	set loginToken(value: LoginToken)
	{
		this._loginToken = value;
	}
}
