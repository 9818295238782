import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, { lines: "full" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          data: item,
          key: item.id,
          class: _normalizeClass({borderless: _ctx.borderless, transparent: _ctx.transparent}),
          onClick: ($event: any) => (_ctx.onClick(item))
        }, {
          default: _withCtx(() => [
            (_ctx.iconClassLeft)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass({primary: _ctx.primary, danger: _ctx.danger, warning: _ctx.warning, info: _ctx.info, [_ctx.iconClassLeft]:_ctx.iconClassLeft, 'icon-lg': _ctx.iconSizeLarge})
                }, null, 2))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_label, { class: "item-label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1024),
            (_ctx.iconClassRight)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 1,
                  class: _normalizeClass({primary: _ctx.primary, danger: _ctx.danger, warning: _ctx.warning, info: _ctx.info, [_ctx.iconClassRight]:_ctx.iconClassRight,  'icon-lg': _ctx.iconSizeLarge})
                }, null, 2))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["data", "class", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}