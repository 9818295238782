
// get the global property hash
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

// returns global properties to be passed with every router request.
export function getGlobalPropertiesHash()
{
	return {
		organizationId: OrganizationStore?.organizationProfile?.url_friendly_name,
	};
}
