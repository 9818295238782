

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {IonChip, IonLabel} from "@ionic/vue";

	@Options({components: {IonChip, IonLabel}})
	export default class ProfileControlButtons extends Vue
	{
		@Prop() headerText: string;
		@Prop() onSaveFunction: () => void;
		@Prop() onCancelFunction: () => void;
		@Prop() disableSave: boolean;

		get getHeaderText()
		{
			return this.headerText ? this.headerText : this.$mhat("ProfileControlButtons.HeaderText");
		}

		private onSave()
		{
			if (!this.disableSave)
			{
				this.onSaveFunction();
			}
		}
	}
