
	import {Options, Prop, Vue, Watch} from "vue-property-decorator";
	import SmallButton from "@/components/Buttons/SmallButton.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {ChatItem, ChatItemType} from "@/views/patient_user/telehealth/types";
	import DocumentPreview from "@/components/Documents/DocumentPreview.vue";
	import MhaDocument from "@/lib/document/model/MhaDocument";
	import DocumentService from "@/lib/document/service/DocumentService";
	import Checkbox from "@/components/Inputs/Checkbox.vue";
	import RemoteDocumentService from "@/lib/document/service/RemoteDocumentService";
	import NotificationService from "@/components/Notification/NotificationService";
	import ModalBaseController from "@/lib/modal/modalBaseController";
	import EventStateSpinner from "@/components/EventState/EventStateSpinner.vue";
	import {IonSpinner} from "@ionic/vue";

	@Options({components: {EventStateSpinner, Checkbox, DocumentPreview, SmallButton, IonSpinner}})
	export default class ChartUploadDocumentReviewModal extends ModalBaseController
	{
		@Prop({type: String, required: true}) patientId: string;
		@Prop({type: Object, required: true}) chatItems: ChatItem[];

		public documentUploadList: {loading: boolean, upload: boolean, document: MhaDocument}[] = [];
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;

		// ==========================================================================
		// Vue hooks
		// ==========================================================================

		@Watch("chatItems", {immediate: true})
		public async onChatItemsChange(chatItems: ChatItem[]): Promise<void>
		{
			const documentService = new DocumentService();

			if (chatItems)
			{
				const documentChatItems = chatItems.filter((item) => item.type === ChatItemType.DOCUMENT);
				this.documentUploadList = (await Promise.all(documentChatItems.map((item) => documentService.getDocument(item.message))))
					.map((document) =>
					{
						return {upload: true, loading: false, document};
					});
			}
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async uploadDocuments(): Promise<void>
		{
			const remoteDocumentService = new RemoteDocumentService();

			await Promise.all(this.documentUploadList.map((documentUploadItem) =>
			{
				if (documentUploadItem.upload)
				{
					// eslint-disable-next-line no-async-promise-executor
					return new Promise(async (resolve, reject) =>
					{
						try
						{
							documentUploadItem.loading = true;
							resolve(await remoteDocumentService.saveDocumentToPatientChart(this.patientId, documentUploadItem.document));
						}
						catch (err)
						{
							NotificationService.notificationDismiss(
								this.$mhat("ChartUploadDocumentReviewModal.UploadErrorTitle", documentUploadItem.document.name),
								this.$mhat("Common.Error.Generic"));
							reject(err);
						}
						finally
						{
							documentUploadItem.loading = false;
						}
					});
				}
				return Promise.resolve();
			}));

			this.dismissModal(true);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get hasDocuments(): boolean
		{
			return !!this.documentUploadList && this.documentUploadList.length > 0;
		}

		get isLoading(): boolean
		{
			return !!this.documentUploadList && this.documentUploadList.reduce((loading, uploadItem) => uploadItem.loading || loading, false);
		}
	}
