import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeaderBar = _resolveComponent("MobileHeaderBar")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      (!_ctx.hideHeader)
        ? (_openBlock(), _createBlock(_component_MobileHeaderBar, {
            key: 0,
            "header-text": _ctx.$mhat('AqsAppointmentType.HeaderText'),
            useIonicBack: false,
            backRoute: _ctx.backRoute
          }, null, 8, ["header-text", "backRoute"]))
        : _createCommentVNode("", true)
    ]),
    "content-center": _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$mhat("AqsAppointmentType.instructionText")), 1),
      _createVNode(_component_BaseButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onTypeSelect(_ctx.QueuedAppointmentType.Chat)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$mhat("AqsAppointmentType.LiveChat")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BaseButton, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onTypeSelect(_ctx.QueuedAppointmentType.Video)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$mhat("AqsAppointmentType.VideoCall")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}