import {getRouteOrWhitelabelRoute} from "@/router/whitelableUtil";
import {LoginRoute} from "@/router/patient_user/login.routes";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

export class LoginRouteCollection
{
	// ==========================================================================
	// Singleton impl
	// ==========================================================================

	private static instance: LoginRouteCollection;

	public static getInstance(): LoginRouteCollection
	{
		if (!this.instance)
		{
			this.instance = new LoginRouteCollection();
		}
		return this.instance;
	}

	private constructor()
	{
	}

	// ==========================================================================
	// Routes
	// ==========================================================================

	get Home(): string
	{
		if (OrganizationStore.isWhiteLabel)
		{
			return OrganizationStore.organizationConfiguration.routes.login;
		}
		return LoginRoute.Home;
	}

	get Forgot(): string
	{
		return getRouteOrWhitelabelRoute(LoginRoute.Forgot);
	}

	get Reset(): string
	{
		return getRouteOrWhitelabelRoute(LoginRoute.Reset);
	}

	get Confirm(): string
	{
		return getRouteOrWhitelabelRoute(LoginRoute.Confirm);
	}

	get PharmacyLogin(): string
	{
		return LoginRoute.PharmacyLogin;
	}
}

export const loginRouteCollection = LoginRouteCollection.getInstance();
