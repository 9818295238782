import {LogLevel} from "@/lib/telehealth/models/LogLevel";
import {TelehealthLogEventDto, TelehealthLogEventDtoLogLevelEnum, UserType} from "@/open_api/generated";

export default class LogEvent
{
	protected _signalType: string;
	protected _logLevel: LogLevel;
	protected _message: string;
	protected _toUser: string;
	protected _toUserType: UserType;
	protected _fromUser: string;
	protected _fromUserType: UserType;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		signalType: string,
		logLevel: LogLevel,
		message: string,
		fromUser: string,
		fromUserType: UserType,
		toUser: string,
		toUserType: UserType)
	{
		this._signalType = signalType;
		this._logLevel = logLevel;
		this._message = message;
		this._toUser = toUser;
		this._fromUser = fromUser;
		this._fromUserType = fromUserType;
		this._toUserType = toUserType;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get signalType(): string
	{
		return this._signalType;
	}

	get logLevel(): LogLevel
	{
		return this._logLevel;
	}

	get message(): string
	{
		return this._message;
	}

	get toUser(): string
	{
		return this._toUser;
	}

	get toUserType(): UserType
	{
		return this._toUserType;
	}

	get fromUser(): string
	{
		 return this._fromUser;
	}

	get fromUserType(): UserType
	{
		return this._fromUserType;
	}

	get asTelehealthLogEventDto(): TelehealthLogEventDto
	{
		return {
			event_type: this.signalType,
			log_level: this.logLevel.toString() as TelehealthLogEventDtoLogLevelEnum,
			message: this.message,
			from_user_id: this.fromUser,
			from_user_type: this.fromUserType,
			to_user_id: this.toUser,
			to_user_type: this.toUserType,
		};
	}
}
