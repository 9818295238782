import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toClinicMessaging())),
    disabled: _ctx.isDisabled,
    primary: ""
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(this.$mhat("ContactClinicRouteButton.ButtonText")), 1)
    ]),
    _: 1
  }, 8, ["disabled"]))
}