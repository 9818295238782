<template>
	<div class="privacy-policy-container">
		<section id="main" class="container">
			<header>
				<h5>CloudPractice Inc.</h5>
				<h1>PRIVACY POLICY</h1>
				<p>Effective January 29, 2020</p>
			</header>
			<div class="box">
				<p>
					This Privacy Policy explains how Cloud Practice Inc. collects, uses, keeps safe and discloses Personal Information and the choices you have with respect to the information collected via the CloudMD platform.
					Our privacy practices are based on British Columbia’s Personal Information Protection Act (PIPA), and Canada’s Personal Information Protection and Electronic Documents Act (PIPEDA),
					and ten internationally recognized privacy principles.
				</p>
				<p>
					By checking “I agree” to the Privacy Policy when registering for an account to use any of the Services, as defined below, either through the CloudMD website or downloaded app (jointly referred to herein as “Application”),
					you acknowledge that you have read, understood and agree to comply with and be bound by the terms and conditions of this Policy for as long as you continue to use the Services.
					If you do not agree with the Policy, do not use this Application.
				</p>
				<p>
					In this Policy, when we say “we”, “us” and “our”, we are referring to Cloud Practice Inc. (herein referred to as Cloud Practice) who provides services via CloudMD.
					When we say “you”, “member”, “client” or “user” we are referring to the person using the Application. When we say “Healthcare Provider”,
					we are referring to authorized healthcare professionals who have access to a connected provider account on the Application that has entered into a contract to utilize CloudMD.
				</p>
				<p>
					CloudMD is a platform accessed via web browser or a downloaded app that (1) allows patients to book appointments with their Healthcare Provider and manage their schedule;
					(2) enables patients to receive appointment reminders via email and/or text message; (3) provides the opportunity for Healthcare Providers to conduct virtual medical appointment with patients
					via video feed, audio and chat functionalities; (4) offers patients health monitoring tools such as triage and symptom checkers and permits upload of a user’s own health information;
					and (5) collects demographic and health information for use in primary healthcare and business analytics (herein collectively referred to as the “Services”).
					Cloud Practice may offer additional services or revise any of the Services at its discretion, and this Policy will apply to all services or revised Services.
					Cloud Practice reserves the right to discontinue offering any of the Services.
				</p>
				<p>
					“Personal Information” means information about an identifiable individual such as name, email address, residential address and telephone number.
					Personal Information also encompasses more sensitive information such as gender, age, health status, family information, and any other data assigned to an individual.
				</p>
				<p>
					The Application is intended for persons thirteen (13) years or older. Persons under the age of thirteen (13) should not access, use and/or browse the Application or the Services.
				</p>
				<h5>1. ACCOUNTABILITY</h5>
				<p>
					Cloud Practice’s Privacy Officer is responsible for the creation, oversight and implementation of our privacy management program and procedures to protect Personal Information, for receiving your privacy-related questions and for providing you with information about our privacy practices.
					You can contact our Privacy Officer at:
					<br>Attn: Cloud Practice Privacy Officer
					<br>#302 - 31 Bastion Square
					<br>Victoria BC V8W1J1
					<br>Email: <a href="mailto:privacy@cloudmd.ca">privacy@cloudmd.ca</a>
				</p>
				<h5>2. COLLECTION AND USE OF INFORMATION</h5>
				<p>
					Cloud Practice collects, uses, and discloses Personal Information only for the purposes of providing you with our Services and for other purposes you consent to as a user.
					We will collect the minimum amount of information required to facilitate the Services; however, we provide you with the option of sharing additional information
					(e.g. upload health tracking data) to enhance your use of the Services. More specifically, Cloud Practice collects and uses the following information:
				</p>
				<strong>Email Address:</strong>
				<p>
					When you register for an account to use our Services, we collect your email address. Your email will be used for account generation.
					It will also be used to contact you for the purposes of product information, service updates, and notifications relating to the Services.
					If you opt-in to receive email appointment reminders, your email address will be used to send you appointment reminders.
					In accordance with Canada’s Anti-Spam Legislation (S.C. 2010, c.23) (CASL), with your consent,
					your email address will also be used to provide you with information for promotional purposes. At any time, you can opt-out of most email
					communication from us by clicking on the unsubscribe link at the bottom of our emails. We may still contact you for administrative purposes,
					for example to notify you of a change to the Services, change to this Privacy Policy, or if there is a service interruption.
				</p>
				<strong>Information About You:</strong>
				<p>
					When you register for an account to use our Services, we collect information about you including your first and last name, email address, phone number and a unique password.
					In order to book an appointment with a clinic, we may also collect your birthdate and Personal Health Number. Within the portal, you can provide additional information in your
					“Patient Profile”, however, additional details are up to you to decide to provide. As the Application will be securely connected to your Healthcare Provider’s EMR, we may collect
					information from the EMR or send information you provide to the EMR. We will use this information to tailor your experience with the Services provided by the Application, connect
					you to your Healthcare Provider and their clinic, and allow for you to book appointments and upload health information to the platform.
				</p>
				<p>
					The information you provide may also be integrated into your electronic patient record that is overseen by your Healthcare Provider and the clinic to which you attend.
					Should you engage in virtual appointments, your Healthcare Provider may transcribe any information you provide over the video chat directly into your electronic patient record.
				</p>
				<p>
					As noted above (see section Email Address), if you decide to receive appointment reminders and indicate your preferred contact method (email or text),
					we will use your email or phone number to contact you to remind you of your appointment. You may opt out of these notifications at any time.
				</p>
				<strong>Appointment Booking:</strong>
				<p>
					When you book an appointment via CloudMD, we collect information related to the Healthcare Provider and clinic you booked with, the date and time of your appointment,
					the type of appointment and the reason for your appointment. This information will be used to support the clinic’s administrators and your Healthcare
					Provider when preparing for your appointment (whether in person or virtual).
				</p>
				<strong>Messages:</strong>
				<p>
					Messages between you and your Healthcare Provider, and/or your Healthcare Provider’s clinic may be sent to each other via your account and/or email address.
					You may receive a notification to inform you that a message has arrived. Should you prefer to stop receiving direct messages between you and your Healthcare Provider,
					you will need to contact your Healthcare Provider to request this change in communication preferences.
				</p>
				<strong>Business Analytics and Services:</strong>
				<p>
					The information you provide when using the Services may be used for business development and analytics purposes.
					Prior to use, due caution will be taken to ensure that the information is stripped of any information that could identify you and aggregated to ensure any analysis
					is done on a group of information. This information will be used for internal purposes only and not further disclosed.
				</p>
				<strong>Mobile Device:</strong>
				<p>
					If you use a mobile device to access the CloudMD website or download the CloudMD app, we may collect device information (such as your mobile device ID, model and manufacturer),
					operating system and version information, IP address and geolocational information.
				</p>
				<strong>Cookies:</strong>
				<p>
					We use cookies to improve your experience, to save you time, and to make it possible to navigate our Application.
					We do not link the information we store in cookies to any Personal Information you submit while using our Services or visiting our websites.
					You can remove cookies manually by following directions provided in your Internet browser’s “help” file. If you refuse cookies,
					you will not be able to use some or all of our Services, since some cookies are essential to navigation.
				</p>
				<strong>Tracking and Analytics:</strong>
				<p>
					Cloud Practice may from time to time implement analytics services that also use cookies to monitor system usage,
					server and software performance, to improve system design, to create benchmarks and to conduct trending analyses.
					We will ensure that no Personal Information is included in those cookies. If the use of cookies by any service provider differs materially from the practices already listed,
					we will revise this document accordingly and notify you of the change(s).
				</p>
				<strong>Cached Data:</strong>
				<p>
					When you use our Services, some data is cached in the memory of the device. Cached data is encrypted and kept on the device as long as you are logged in,
					even if the Application is put in the background. The data is deleted from the device when you sign out of the Application and does not persist.
				</p>
				<h5>3. WHO CAN SEE MY INFORMATION?</h5>
				<strong>User Account:</strong>
				<p>
					The information you provide in your user account and any additional information you decide to upload to your account, such as health monitoring information,
					will be visible to you and your Healthcare Provider.  Your Personal Information and information related to your use of the Services may be become a part of your
					electronic patient record at your Healthcare Provider’s clinic.
				</p>
				<strong>Appointment Booking:</strong>
				<p>
					Your appointment booking details, as noted in s. 3, will be available to you and your Healthcare Provider.
				</p>
				<strong>Virtual Appointments:</strong>
				<p>
					The video and audio feed from your virtual appointment will only be visible and available to you and your Healthcare Provider.
					Should you decide to engage in the Application’s Chat Function, which allows you and your Healthcare Provider to send instant messages during your virtual appointment,
					the chat will only be visible to you and your Healthcare Provider. As soon as your appointment session is closed, the video,
					audio and chat will no longer be available and no recordings or files from the appointment will be maintained or stored.
				</p>
				<h5>4. DISCLOSURE AND RETENTION OF INFORMATION</h5>
				<p>
					Cloud Practice will not use or disclose Personal Information for purposes other than the purposes identified in this Privacy Policy. We do not sell,
					rent or disclose your Personal Information to any third party without your consent.
				</p>
				<strong>Under certain circumstances, Cloud Practice will disclose your Personal Information to:</strong>
				<p>
					Legal authorities: when we are required or authorized by law, at the request of a third party, or if we believe disclosure is reasonable to comply with court orders,
					protect individual’s health and safety, or protect Cloud Practice’s rights and property;
				</p>
				<p>
					Your Healthcare Provider and their clinic: as indicated above, your Personal Information will be disclosed to your Healthcare Provider and their clinic to provide you with the Services;
				</p>
				<p>
					Trusted third parties by us: when the Services we are providing to you require us to provide your information to a third party who performs functions on our behalf.
					Your information will be treated with the same level of privacy and security as we are committed to providing as a company, and will not be used for other purposes than that which we authorize.
				</p>
				<p>
					Other third parties upon merger or sale: we may transfer any information we have about you in connection with a merger or sale involving all or part of our business or as part of a corporate
					reorganization or stock sale or other change in corporate control.
				</p>
				<p>
					Where it is necessary to establish or collect amounts owed to us.
				</p>
				<br>
				<p>
					At any time and without penalty, you can withdraw your consent and close your CloudMD account through the “Deactivate Account” tab located within your account profile.
					If you choose to close your account and withdraw from the Services, we will conduct the following:
				</p>
				<p>
					Where Personal Information has been de-identified and aggregated, resulting datasets will be retained for business analytics purposes for the period of time needed for Cloud Practice to
					pursue legitimate business interests, conduct audits, comply with legal obligations, resolve disputes and enforce our agreements.
				</p>
				<p>
					Where Personal Information exists, you will be required to email <a href="mailto:privacy@cloudmd.ca">privacy@cloudmd.ca</a> requesting destruction of your information.
					To which, we will securely destroy your Personal Information in our possession and control within 30 days unless we are required to retain such Personal Information longer to fulfill our legal obligations,
					resolve disputes and enforce our agreements.
				</p>
				<br>
				<p>
					Where Personal Information is integrated into your electronic patient record at your Healthcare Provider’s medical clinic:
				</p>
				<p>
					Data retention and destruction requirements will be performed in accordance with the clinic’s privacy policies and procedures and legal obligations,
					and Cloud Practice does not retain any control or custody over the information that has been integrated into your electronic patient record.
				</p>
				<h5>5. CONSENT</h5>
				<p>
					Cloud Practice takes a consent-based approach to the collection, use and disclosure of Personal Information.
					Before receiving an invitation to the Services, we collect your explicit consent and provide you with information about the Services and how we collect,
					use and disclose your Personal Information. Your consent is for your information being collected, used, disclosed and stored by us,
					only as described in this Privacy Policy. Before we contact you with general commercial information, we will ask for your consent.
				</p>
				<h5>6. LOCATION OF SERVICES</h5>
				<p>
					In general, Cloud Practice stores, accesses, and uses Personal Information from inside Canada. Unless there is a legal or regulatory requirement to store,
					access and/or use Personal Information in Canada, we may use service providers located outside of Canada to process and/or store Personal Information for us.
					Please note that Personal Information in the custody of these service providers may be subject to access by the law enforcement authorities of those jurisdictions in which the service providers are located.
				</p>
				<p>
					Our Privacy Officer can provide further information about our policies and practices regarding service providers located outside of Canada and further
					information about how these service providers, collect, use, disclose or store personal information on Cloud Practice’s behalf.
				</p>
				<h5>7. ACCURACY</h5>
				<p>
					Cloud Practice relies on you to ensure that the Personal Information you provide to us while using the Services is as accurate,
					complete and up-to-date as necessary for the purposes for which it is to be used. You are welcome to make changes,
					request deletion or correction to Personal Information at any time by updating your profile and account settings or by contacting us at <a href="mailto:privacy@cloudmd.ca">privacy@cloudmd.ca</a>.
				</p>
				<h5>8. SAFEGUARDS</h5>
				<p>
					Cloud Practice uses administrative, technical and physical safeguards to protect your Personal Information against loss, theft,
					and unauthorized access, use, disclosure, copying, modification, disposal, or destruction in accordance with applicable legal requirements and industry best practices.
					For example, we implement encryption and security controls to protect against unauthorized access to Personal Information during electronic transmission and require third
					parties acting on our behalf to maintain reasonable and appropriate safeguards. We also ensure that only those employees responsible for the Services’ operations have physical
					or technical access to Personal Information and only where such access is required to perform work authorized by their supervisors.
				</p>
				<p>
					We will review and update our security policies and controls as technology changes to ensure ongoing security of your information, however please bear in mind that no
					internet or email transmission is ever fully secure or error free and no security system is impenetrable. We cannot fully guarantee the confidentiality of any information that you share with us.
				</p>
				<h5>9. OPENNESS</h5>
				<p>
					Cloud Practice makes clear and transparent notice of our privacy practices publicly available via this Privacy Policy.
					We will advise you at the first reasonable opportunity upon discovering or being advised of an incident where your Personal Information is lost, stolen,
					accessed, used, disclosed, copied, modified, or disposed of by unauthorized persons or in an unauthorized manner.
				</p>
				<h5>10. INDIVIDUAL ACCESS</h5>
				<p>
					At any time, you can review, edit or request removal of information you have provided to Cloud Practice within the requirements as outlined in Disclosure
					and Retention of Information above. Upon written request, Cloud Practice will also provide you with a list of entities to whom we have disclosed your Personal information.
				</p>
				<h5>11. ADDITIONAL FEATURES AND INFORMATION</h5>
				<p>
					It is important to guard your privacy when you are online. If our Services contain links to other websites, those websites are not governed by this Privacy Policy.
					Whether we have posted those links or other organizations or individuals have, you should read their privacy policies and make an informed decision about whether you want to use those websites or their services.
				</p>
				<p>
					Where Cloud Practice has enabled you to connect with other third-party service providers in order to utilize their services in conjunction with our Services,
					you will be subject to their collection practices and the terms of use and privacy policies of these third-party service providers.
					You are responsible for reviewing and agreeing to them. If you do not agree with their terms of use or privacy policies you should not use the services offered by such third-party service providers.
				</p>
				<p>
					Cloud Practice does not knowingly or intentionally collect Personal Information from users who are under the age of thirteen (13),
					to align with requirements set forth by the Office of the Privacy Commissioner of Canada, which requires consent to be obtained from parents or guardians for the collection,
					use and disclosure of the personal information of children under the age of 13.
				</p>
				<h5>12. CHANGES TO THIS PRIVACY POLICY</h5>
				<p>
					We may update this Policy to reflect changes to our information practices but we will not reduce your rights under this Privacy Policy without your explicit consent.
					We’ll post any changes to this page and, if the changes are significant, we will provide a more prominent notice (including email notification of Privacy Policy changes for certain Services).
					We will also keep prior versions of this Privacy Policy in an archive for your review.
				</p>
				<p>
					We encourage you to periodically review our Privacy Policy for the latest information on our privacy practices and to contact us if you have any questions or concerns.
				</p>
				<h5>13. CHALLENGING COMPLIANCE</h5>
				<p>
					Cloud Practice’s Privacy Officer is responsible for receiving your privacy-related questions. You may send us your privacy-related questions or challenge our compliance with our Privacy Policy by submitting your concern to:
					<br>Privacy Officer
					<br>Attn: Cloud Practice Privacy Officer
					<br>#302 - 31 Bastion Square
					<br>Victoria BC V8W1J1
					<br>Email: <a href="mailto:privacy@cloudmd.ca">privacy@cloudmd.ca</a>
					<br>
					<br>Office of the Information and Privacy Commissioner of British Columbia
					<br>947 Fort Street
					<br>Victoria, BC V8V 3K3
					<br>(250) 387-5629
				</p>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		name: "CloudMDPrivacyPolicyText",
	};
</script>

<style scoped lang="scss">
	.privacy-policy-container {
		a {
			color: var(--color-primary-darker);
			font-style: italic;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
				color: var(--color-primary-base);
			}
		}

		header {
			text-align: center;
		}
	}
</style>
