import {
	AccountApiFactory,
	AqsClinicUserApiFactory,
	AqsPatientApiFactory,
	AqsPublicApiFactory,
	ClinicApiFactory,
	DependentApiFactory,
	KioskApiFactory, LoggingApiFactory,
	LoginApiFactory,
	PatientApiFactory,
	PublicApiFactory,
	SignupApiFactory,
} from "@/open_api/generated/api";
import { Configuration } from "@/open_api/generated/configuration";
import AuthStore from "../vuex/auth.store";
import DeviceInfo from "@/lib/DeviceInfo";

import "./interceptors";
import {BaseLoginService} from "@/lib/services/login/BaseLogin.service";
import router, {Route} from "@/router/router";

export const BASE_API_URL = `${location.protocol}//${location.hostname}/api/rest/v1/${DeviceInfo.getHostUri()}`;

export const PublicAPI = () =>
{
	return PublicApiFactory(new Configuration(), BASE_API_URL);
};

export const LoggingAPI = () =>
{
	const apiKey = AuthStore.loginToken?.tokenStr || null;
	return LoggingApiFactory(new Configuration({apiKey}), BASE_API_URL);
};

export const SignupAPI = () =>
{
	return SignupApiFactory(new Configuration(), BASE_API_URL);
};

export const LoginAPI = () =>
{
	return LoginApiFactory(new Configuration(), BASE_API_URL);
};

export const AccountAPI = () =>
{
	return AccountApiFactory(new Configuration(), BASE_API_URL);
};

export const PatientAPI = () =>
{
	return ApiLoginWrapper((loginToken, apiKey) =>
	{
		return PatientApiFactory(new Configuration({apiKey}), BASE_API_URL);
	});
};
export const DependentAPI = () =>
{
	return ApiLoginWrapper((loginToken, apiKey) =>
	{
		return DependentApiFactory(new Configuration({apiKey}), BASE_API_URL);
	});
};

export const ClinicAPI = () =>
{
	return ApiLoginWrapper((loginToken, apiKey) =>
	{
		return ClinicApiFactory(new Configuration({apiKey}), BASE_API_URL);
	});
};

export const KioskAPI = () =>
{
	const apiKey = AuthStore.loginToken?.tokenStr;
	return KioskApiFactory(new Configuration({apiKey}), BASE_API_URL);
};

export const AqsPatientAPI = () =>
{
	return ApiLoginWrapper((loginToken, apiKey) =>
	{
		return AqsPatientApiFactory(new Configuration({apiKey}), BASE_API_URL);
	});
};

export const AqsClinicUserAPI = () =>
{
	return ApiLoginWrapper((providerToken, apiKey) =>
	{
		return AqsClinicUserApiFactory(new Configuration({apiKey}), BASE_API_URL);
	});
};

export const AqsPublicAPI = () =>
{
	return AqsPublicApiFactory(new Configuration(), BASE_API_URL);
};

function ApiLoginWrapper(bodyFunc: (loginToken, apiKey) => void): any
{
	if (AuthStore.loginToken)
	{
		const loginToken = AuthStore.loginToken;
		const apiKey = loginToken.tokenStr;

		return bodyFunc(loginToken, apiKey);
	}
	else
	{
		router.push(Route.Login.Home);
	}
	return null;
}
