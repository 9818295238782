
	import {Options, Vue} from "vue-property-decorator";
	import WaitingRoomOverlay from "@/views/patient_user/telehealth/components/WaitingRoomOverlay.vue";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import PatientTelehealthHeader from "@/views/patient_user/appointment/components/PatientTelehealthHeader.vue";
	import TelehealthWrapper from "@/components/telehealth/TelehealthWrapper.vue";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import {PatientAPI} from "@/lib/services/Api";
	import {SessionData} from "@/lib/telehealth/models/sessionData.model";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
	import TelehealthIOSBase from "@/components/telehealth/TelehealthIOSBase.vue";
	import IOSTelehealth from "@/lib/telehealth/ios/IOSTelehealth";
	import {IonPage} from "@ionic/vue";
	import {reactive, toRaw} from "vue";
	import TelehealthInterface, {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
	import RegularTelehealthApiService from "@/lib/telehealth/api/RegularTelehealthApiService";

	// TODO: Base patient controller to avoid reimplementing logic in patient, ios-web and kiosk telehealth controllers
	@Options({
		components: {AppChip, TelehealthWrapper, PatientTelehealthHeader, ChatView, WaitingRoomOverlay, IonPage},
	})
	export default class TelehealthPatientIos extends TelehealthIOSBase
	{
		// ==========================================================================
		// Vue Life Cycle Hooks
		// ==========================================================================

		public async onCreated(): Promise<void>
		{
			this.telehealthEngine = reactive(new IOSTelehealth()) as TelehealthInterface;
			this.telehealthApiService = new RegularTelehealthApiService(this.appointmentId);
		}

		public async onMounted(): Promise<void>
		{
			await this.telehealthEngine.initialize(this.callData(await this.fetchSessionData(this.appointmentId)), null, null);
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		protected onCallStateChange(state: TELEHEALTH_STATE): void
		{
			// Vue has a really hard time tracking changes caused by the native iOS code.
			// force update
			if (this.$refs.ionPage)
			{
				(this.$refs.ionPage as typeof IonPage).$forceUpdate();
			}
		}

		/**
		 * fetch session data, that is required to join the session.
		 * @param appointmentId - the appointment for whom the session is for.
		 * @return the session data
		 * @protected
		 */
		protected async fetchSessionData(appointmentId: string): Promise<SessionData>
		{
			try
			{
				return new SessionData((await PatientAPI().getPatientTelehealthSessionData(appointmentId)).data);
			}
			catch (error)
			{
				NotificationService.notify(
					{
						event: NotifyEvent.Generic,
						type: NotificationType.Dismiss,
						severity: NotificationSeverity.Critical,
						title: "Telehealth Connection Error",
						message: "An error has occurred preventing the Telehealth connection.",
					},
				);
				throw error;
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================
	}
