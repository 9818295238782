import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelectionCard = _resolveComponent("BaseSelectionCard")!

  return (_openBlock(), _createBlock(_component_BaseSelectionCard, {
    key: _ctx.clinic.id,
    header: _ctx.clinic.name,
    primary: _ctx.clinic.address1,
    footer: _ctx.cityProvince,
    active: _ctx.active,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', _ctx.clinic))),
    deleted: _ctx.deleted,
    "tele-health": _ctx.clinic.telehealthEnabled,
    clickable: _ctx.clickable
  }, null, 8, ["header", "primary", "footer", "active", "deleted", "tele-health", "clickable"]))
}