import DeviceInfo from "@/lib/DeviceInfo";

export function gtag()
{
	dataLayer.push(arguments);
}

export const GoogleAnalyticsInitPromise = Promise.race([new Promise((resolve, reject) =>
{
	if (DeviceInfo.isCloudMd && DeviceInfo.isProduction())
	{
		window.dataLayer = window.dataLayer || [];

		gtag("js", new Date());
		gtag("config", "AW-644761063");
	}

	resolve();
}), new Promise((resolve, reject) =>
{
	window.setTimeout(() => reject("Google Analytics load timeout"), 5000);
})]);

GoogleAnalyticsInitPromise.catch((err) =>
{ /* suppress */ });
