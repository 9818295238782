
	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class BackgroundImage extends Vue
	{
		@Prop({type: String}) backgroundVerticalOffset: string;
		@Prop({type: String}) backgroundVerticalOverlap: string;
		@Prop({type: Boolean, default: false}) centerBackground: string;
		@Prop({type: String, default: "1920px"}) width: string;
		@Prop({type: String, default: "1080px"}) height: string;

		get containerStyle()
		{
			return {
				"padding-top": this.backgroundVerticalOffset,
				width: this.width,
				height: this.height,
			};
		}

		get containerClasses()
		{
			return {
				"center-background": this.centerBackground,
			};
		}
	}

