

	import {Options, Vue} from "vue-property-decorator";
	import AuthStore from "@/lib/vuex/auth.store";

	@Options({})
	export default class Home extends Vue
	{
		get firstName(): string
		{
			return AuthStore.loggedInClinic?.firstName;
		}
	}
