

	import {Options, Prop, Watch, Vue} from "vue-property-decorator";
	import {ProfileController} from "../controllers/ProfileController";
	import {Countries, SubRegions, TimeZones} from "@/lib/utils/Geodata";
	import {getProfileFieldLabel, PasswordField, PatientProfileField} from "@/lib/types/Profile";
	import PasswordController from "@/views/patient_user/login/controllers/PasswordController";
	import PhoneField from "@/components/Inputs/PhoneField.vue";
	import InputLayout from "@/components/Inputs/InputLayout.vue";
	import AuthStore from "@/lib/vuex/auth.store";
	import {ProvinceCode} from "@/open_api/generated";

	@Options({components: {InputLayout, PhoneField}})
	export default class ProfileInputFields extends Vue
	{
		@Prop({type: Object}) controller: ProfileController;
		@Prop({type: Object}) passwordController: PasswordController;
		@Prop({type: Array}) inputIds: string[];
		@Prop({type: Array}) requiredInputIds: string[];
		@Prop({type: Object}) fieldLabelOverrides: any;
		@Prop({type: Object}) fieldPlaceholderOverrides: any;
		@Prop({type: Boolean}) includeSectionHeaders: boolean;
		@Prop({type: Boolean}) includeSignupFields: boolean;
		@Prop({type: Boolean}) includeFieldLabels: boolean;
		@Prop({type: Boolean}) emailError: boolean;
		@Prop({type: Boolean}) darkStyle: boolean;
		@Prop({type: Boolean, default: false}) includeDependentFields: boolean;

		private hinChanged = false;

		Countries = Countries;
		TimeZones = TimeZones;
		SubRegions = SubRegions;
		USER_PROFILE_FIELDS = PatientProfileField;
		PASSWORD_FIELDS = PasswordField;

		@Watch("inputIds", {immediate: true, deep: true})
		public onInputIdsChange(newVal)
		{
			if (newVal)
			{
				// set DOB required if included
				this.controller.setDateOfBirthRequired(newVal.includes(this.USER_PROFILE_FIELDS.BIRTH_DATE));
			}
			else
			{
				// if all ids required DOB is required
				this.controller.setDateOfBirthRequired(true);
			}
		}

		public updateProfile(input)
		{
			this.controller.userProfile[input.id] = input.value;
			this.$emit("profileChange", input);
		}

		public updateProfileHin(input)
		{
			this.hinChanged = true;
			this.updateProfile(input);
		}

		public passwordUpdate(password)
		{
			this.passwordController.password = password.value;
		}

		public confirmPasswordUpdate(confirmPassword)
		{
			this.passwordController.confirmPassword = confirmPassword.value;
		}

		public includeThisField(id)
		{
			if (this.inputIds)
			{
				return this.inputIds.includes(id);
			}
			return true; // all fields included by default
		}

		public isFieldRequired(id)
		{
			if (this.requiredInputIds)
			{
				return this.requiredInputIds.includes(id);
			}
			return false;
		}

		public isFieldValid(id)
		{
			if (this.isFieldRequired(id))
			{
				return this.controller.isRequiredFieldValid(id);
			}
			return true;
		}

		public isFieldValidAlternateRequire(id, requireId)
		{
			if (this.isFieldRequired(requireId))
			{
				return this.controller.isRequiredFieldValid(id);
			}
			return true;
		}

		public fieldPlaceholder(label): string
		{
			if (label)
			{
				return this.$mhat("ProfileInputFields.FieldPlaceHolderText", {fieldName: label.toLowerCase()});
			}
			return this.$mhat("ProfileInputFields.GenericFieldPlaceHolderText");
		}

		public onNewbornToggle(): void
		{
			this.controller.userProfile.newborn = !this.controller.userProfile.newborn;

			if (this.controller.userProfile.newborn)
			{
				// assign guardian hc info to dependent
				this.controller.userProfile.healthNumber = AuthStore.loggedInPatient.profile.healthNumber;
				this.controller.userProfile.healthCareProvinceCode = AuthStore.loggedInPatient.profile.healthCareProvinceCode;
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get hasContactInfoFields()
		{
			return this.includeThisField(PatientProfileField.CELL_PHONE) ||
				this.includeThisField(PatientProfileField.ADDRESS_1) ||
				this.includeThisField(PatientProfileField.CITY) ||
				this.includeThisField(PatientProfileField.ADDRESS_PROVINCE_CODE) ||
				this.includeThisField(PatientProfileField.POSTAL_CODE) ||
				this.includeThisField(PatientProfileField.COUNTRY_CODE) ||
				this.includeThisField(PatientProfileField.TIME_ZONE);
		}

		get showPasswordFields()
		{
			return this.includeSignupFields && this.passwordController;
		}

		get showNewbornToggle(): boolean
		{
			return AuthStore.loggedInPatient.profile.healthCareProvinceCode === ProvinceCode.BC;
		}
	}
