

	import {Options, Vue, Prop} from "vue-property-decorator";
	import DependentStore from "@/lib/vuex/modules/Dependent.store";
	import {Patient} from "@/lib/patient/Patient";
	import {ClinicSearchType} from "@/components/EventState/types";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";

	@Options({})
	export default class ClinicSearchSpinner extends Vue
	{
		@Prop() searchType: ClinicSearchType;
		@Prop() dependentId: string;
		@Prop() isLoading: boolean;
		@Prop() showFailure: boolean;
		@Prop() failureTitle: string;
		@Prop() failureMessage: string;

		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		primaryAction()
		{
			if (this.searchType === ClinicSearchType.CONNECTED)
			{
				const {dependentId} = this;

				if (this.dependentId)
				{
					this.$mhaRouterPush(Route.DependentClinics.FindParentClinics, {params: { dependentId }});
				}
				else
				{
					this.$mhaRouterPush(Route.Clinics.FindClinic);
				}
			}
			else
			{
				this.$emit("refresh");
			}
		}

		get primaryText()
		{
			return this.searchType === ClinicSearchType.CONNECTED ? this.$mhat("ClinicSearchSpinner.FindAClinic") : this.$mhat("ClinicSearchSpinner.TryAgain");
		}

		get cFailureTitle(): string
		{
			if (this.failureTitle)
			{
				return this.failureTitle;
			}

			if (this.searchType === ClinicSearchType.UNCONNECTED)
			{
				return this.$mhat("ClinicSearchSpinner.NoClinicsAvailable");
			}
			else
			{
				return this.$mhat("ClinicSearchSpinner.NoClinicsFound");
			}
		}

		get cFailureMessage(): string
		{
			if (this.failureMessage)
			{
				return this.failureMessage;
			}

			if (this.dependent)
			{
				const {firstName} = this.dependent;

				if (this.searchType === ClinicSearchType.UNCONNECTED)
				{
					return this.$mhat("ClinicSearchSpinner.NoClinicsAvailableForDependent", {dependentFirstName: firstName});
				}
				else
				{
					return this.$mhat("ClinicSearchSpinner.NoClinicsFoundForDependent", {dependentFirstName: firstName});
				}
			}
			else
			{
				if (this.searchType === ClinicSearchType.UNCONNECTED)
				{
					return this.$mhat("ClinicSearchSpinner.NoClinicsAvailableForPatient");
				}
				else
				{
					return this.$mhat("ClinicSearchSpinner.NoClinicsFoundForPatient");
				}
			}
		}

		get dependent(): Patient
		{
			if (this.dependentId)
			{
				return DependentStore.dependentMap[this.dependentId];
			}
		}
	}
