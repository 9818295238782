import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f195c8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "account-edit-container"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = {
  key: 1,
  class: "m-b-16"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { key: 4 }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_toggle = _resolveComponent("toggle")!
  const _component_SelectMenu = _resolveComponent("SelectMenu")!
  const _component_input_layout = _resolveComponent("input-layout")!
  const _component_DateSelector = _resolveComponent("DateSelector")!
  const _component_PhoneField = _resolveComponent("PhoneField")!
  const _component_PasswordField = _resolveComponent("PasswordField")!

  return (_ctx.controller && _ctx.controller.userProfile)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.FIRST_NAME))
            ? (_openBlock(), _createBlock(_component_TextField, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.FirstNameField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.FirstNameField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.FIRST_NAME,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.FIRST_NAME),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.FIRST_NAME),
                value: _ctx.controller.userProfile.firstName,
                onInput: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "value", "onInput"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.MIDDLE_NAME))
            ? (_openBlock(), _createBlock(_component_TextField, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.MiddleNameField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.MiddleNameField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.MIDDLE_NAME,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.MIDDLE_NAME),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.MIDDLE_NAME),
                value: _ctx.controller.userProfile.middleName,
                onInput: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "value", "onInput"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.LAST_NAME))
            ? (_openBlock(), _createBlock(_component_TextField, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.LastNameField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.LastNameField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.LAST_NAME,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.LAST_NAME),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.LAST_NAME),
                value: _ctx.controller.userProfile.lastName,
                onInput: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "value", "onInput"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.includeSignupFields && _ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.EMAIL))
            ? (_openBlock(), _createBlock(_component_TextField, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.EmailField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.EmailField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.EMAIL,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.EMAIL),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.EMAIL),
                value: _ctx.controller.userProfile.email,
                "icon-left": "icon-mail",
                error: _ctx.emailError,
                onInput: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "value", "error", "onInput"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER) && !_ctx.includeFieldLabels)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["sub-header", {'dark-mode': _ctx.darkStyle}])
            }, _toDisplayString(this.$mhat("ProfileInputFields.HealthInformationTitle")), 3))
          : _createCommentVNode("", true),
        (_ctx.includeDependentFields && _ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.NEWBORN) && _ctx.showNewbornToggle)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["sub-header", {'dark-mode': _ctx.darkStyle}])
                  }, _toDisplayString(this.$mhat("ProfileInputFields.NewbornDependentIndicationText")), 3)
                ])
              ]),
              _createVNode(_component_toggle, {
                status: _ctx.controller.userProfile.newborn,
                label: this.$mhat('ProfileInputFields.NewbornToggleLabel'),
                onToggled: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onNewbornToggle()))
              }, null, 8, ["status", "label"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER))
            ? (_openBlock(), _createBlock(_component_SelectMenu, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.HealthCareProvinceCodeField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.HealthCareProvinceCodeField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.HEALTHCARE_PROVINCE_CODE,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER),
                valid: _ctx.isFieldValidAlternateRequire(_ctx.USER_PROFILE_FIELDS.HEALTHCARE_PROVINCE_CODE, _ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER),
                options: _ctx.controller.hinSubregionOptions(),
                selected: _ctx.controller.currentHealthCareProvince(),
                disabled: _ctx.controller.userProfile.newborn,
                onSelection: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "options", "selected", "disabled", "onSelection"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER))
            ? (_openBlock(), _createBlock(_component_input_layout, {
                key: 0,
                compact: !_ctx.includeFieldLabels,
                "hide-label": "",
                "hide-validation-message": _ctx.includeFieldLabels,
                "is-valid": _ctx.controller.getHealthNumberValidation(_ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER))
			                && _ctx.isFieldValidAlternateRequire(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER_VERSION, _ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER),
                pristine: !_ctx.hinChanged
              }, {
                default: _withCtx(() => [
                  (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER))
                    ? (_openBlock(), _createBlock(_component_TextField, {
                        key: 0,
                        class: _normalizeClass(["form-group", {'col-80' : _ctx.controller.showHinVersion()}]),
                        label: this.$mhat('ProfileInputFields.HealthNumberField'),
                        placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.HealthNumberField')),
                        hideLabel: !_ctx.includeFieldLabels,
                        "hide-validation-message": !_ctx.includeFieldLabels,
                        "no-padding": "",
                        id: _ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER,
                        required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER),
                        valid: _ctx.controller.getHealthNumberValidation(_ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER)),
                        value: _ctx.controller.userProfile.healthNumber,
                        disabled: _ctx.controller.userProfile.newborn,
                        onInput: _ctx.updateProfileHin
                      }, null, 8, ["label", "placeholder", "hideLabel", "hide-validation-message", "id", "class", "required", "valid", "value", "disabled", "onInput"]))
                    : _createCommentVNode("", true),
                  (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER) && _ctx.controller.showHinVersion())
                    ? (_openBlock(), _createBlock(_component_TextField, {
                        key: 1,
                        class: "form-group col-20",
                        label: this.$mhat('ProfileInputFields.VersionCodeField'),
                        placeholder: "##",
                        hideLabel: !_ctx.includeFieldLabels,
                        "hide-validation-message": !_ctx.includeFieldLabels,
                        "no-padding": "",
                        id: _ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER_VERSION,
                        required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER_VERSION) && _ctx.controller.showHinVersion(),
                        valid: _ctx.isFieldValidAlternateRequire(_ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER_VERSION, _ctx.USER_PROFILE_FIELDS.HEALTH_NUMBER),
                        value: _ctx.controller.userProfile.healthNumberVersion,
                        disabled: _ctx.controller.userProfile.newborn,
                        onInput: _ctx.updateProfileHin
                      }, null, 8, ["label", "hideLabel", "hide-validation-message", "id", "required", "valid", "value", "disabled", "onInput"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["compact", "hide-validation-message", "is-valid", "pristine"]))
            : _createCommentVNode("", true)
        ]),
        ((_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.BIRTH_DATE) || _ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.SEX)) && !_ctx.includeFieldLabels)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              class: _normalizeClass(["sub-header", {'dark-mode': _ctx.darkStyle}])
            }, _toDisplayString(this.$mhat("ProfileInputFields.AgeAndSexInformationTitle")), 3))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.BIRTH_DATE))
            ? (_openBlock(), _createBlock(_component_DateSelector, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.BirthDateField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.BirthDateField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                "z-index": 590,
                id: _ctx.USER_PROFILE_FIELDS.BIRTH_DATE,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.BIRTH_DATE),
                valid: _ctx.controller.getDateOfBirthValidation(_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.BIRTH_DATE)),
                value: _ctx.controller.userProfile.birthDate,
                "allow-future": false,
                onInput: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "value", "onInput"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.SEX))
            ? (_openBlock(), _createBlock(_component_SelectMenu, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.SexField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.SexField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.SEX,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.SEX),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.SEX),
                options: _ctx.controller.sexOptions(),
                selected: _ctx.controller.currentSex(),
                onSelection: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "options", "selected", "onSelection"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.includeSectionHeaders && _ctx.hasContactInfoFields)
          ? (_openBlock(), _createElementBlock("h5", {
              key: 3,
              class: _normalizeClass(["section-header", {'dark-mode': _ctx.darkStyle}])
            }, _toDisplayString(this.$mhat("ProfileInputFields.ContactInformationTitle")), 3))
          : _createCommentVNode("", true),
        (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.CELL_PHONE))
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["sub-header", {'dark-mode': _ctx.darkStyle}])
                  }, _toDisplayString(this.$mhat("ProfileInputFields.PrimaryPhoneNumberDescriptionText")), 3)
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_PhoneField, {
                  class: "form-group",
                  label: this.$mhat('ProfileInputFields.CellPhoneField'),
                  placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.CellPhoneField')),
                  hideLabel: !_ctx.includeFieldLabels,
                  compact: !_ctx.includeFieldLabels,
                  id: _ctx.USER_PROFILE_FIELDS.CELL_PHONE,
                  required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.CELL_PHONE),
                  valid: _ctx.controller.getPhoneValidation(_ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.CELL_PHONE)),
                  value: _ctx.controller.userProfile.cellPhone,
                  onInput: _ctx.updateProfile
                }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "value", "onInput"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_17, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.ADDRESS_1))
            ? (_openBlock(), _createBlock(_component_TextField, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.AddressField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.AddressField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.ADDRESS_1,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.ADDRESS_1),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.ADDRESS_1),
                value: _ctx.controller.userProfile.address1,
                onInput: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "value", "onInput"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_18, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.CITY))
            ? (_openBlock(), _createBlock(_component_TextField, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.CityField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.CityField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.CITY,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.CITY),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.CITY),
                value: _ctx.controller.userProfile.city,
                onInput: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "value", "onInput"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_19, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.ADDRESS_PROVINCE_CODE))
            ? (_openBlock(), _createBlock(_component_SelectMenu, {
                key: 0,
                class: "form-group",
                label: _ctx.controller.subregionLabel(),
                placeholder: _ctx.controller.subregionLabel(),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.ADDRESS_PROVINCE_CODE,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.ADDRESS_PROVINCE_CODE),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.ADDRESS_PROVINCE_CODE),
                options: _ctx.controller.subregionOptions(),
                selected: _ctx.controller.currentSubregion(),
                onSelection: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "options", "selected", "onSelection"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_20, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.POSTAL_CODE))
            ? (_openBlock(), _createBlock(_component_TextField, {
                key: 0,
                class: "form-group",
                label: _ctx.controller.postalLabel(),
                placeholder: _ctx.controller.postalLabel(),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.POSTAL_CODE,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.POSTAL_CODE),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.POSTAL_CODE),
                value: _ctx.controller.userProfile.postalCode,
                onInput: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "value", "onInput"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_21, [
          (_ctx.includeThisField(_ctx.USER_PROFILE_FIELDS.COUNTRY_CODE))
            ? (_openBlock(), _createBlock(_component_SelectMenu, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.CountryField'),
                placeholder: _ctx.fieldPlaceholder(this.$mhat('ProfileInputFields.CountryField')),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.USER_PROFILE_FIELDS.COUNTRY_CODE,
                required: _ctx.isFieldRequired(_ctx.USER_PROFILE_FIELDS.COUNTRY_CODE),
                valid: _ctx.isFieldValid(_ctx.USER_PROFILE_FIELDS.COUNTRY_CODE),
                options: _ctx.Countries,
                selected: _ctx.controller.currentCountry(),
                onSelection: _ctx.updateProfile
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "required", "valid", "options", "selected", "onSelection"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.showPasswordFields)
          ? (_openBlock(), _createElementBlock("span", {
              key: 5,
              class: _normalizeClass(["sub-header", {'dark-mode': _ctx.darkStyle}])
            }, _toDisplayString(this.$mhat("ProfileInputFields.PasswordRequirementsText")), 3))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_22, [
          (_ctx.showPasswordFields)
            ? (_openBlock(), _createBlock(_component_PasswordField, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.PasswordFieldLabel'),
                placeholder: this.$mhat('ProfileInputFields.PasswordFieldPlaceholder'),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.PASSWORD_FIELDS.PASSWORD,
                required: "",
                valid: _ctx.passwordController.isPasswordValid,
                onInput: _ctx.passwordUpdate
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "valid", "onInput"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_23, [
          (_ctx.showPasswordFields)
            ? (_openBlock(), _createBlock(_component_PasswordField, {
                key: 0,
                class: "form-group",
                label: this.$mhat('ProfileInputFields.ConfirmPasswordFieldLabel'),
                placeholder: this.$mhat('ProfileInputFields.ConfirmPasswordFieldPlaceholder'),
                hideLabel: !_ctx.includeFieldLabels,
                compact: !_ctx.includeFieldLabels,
                id: _ctx.PASSWORD_FIELDS.PASSWORD_CONFIRM,
                required: "",
                valid: _ctx.passwordController.isConfirmPasswordValid,
                onInput: _ctx.confirmPasswordUpdate
              }, null, 8, ["label", "placeholder", "hideLabel", "compact", "id", "valid", "onInput"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}