
	import {Options, Vue} from "vue-property-decorator";
	import WaitingRoomOverlay from "@/views/patient_user/telehealth/components/WaitingRoomOverlay.vue";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import PatientTelehealthHeader from "@/views/patient_user/appointment/components/PatientTelehealthHeader.vue";
	import TelehealthWrapper from "@/components/telehealth/TelehealthWrapper.vue";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import TelehealthBase from "@/components/telehealth/TelehealthBase.vue";
	import OnDemandBookingTelehealthWaitingRoomOverlay from "@/views/patient_user/odb/telehealth/OnDemandBookingTelehealthWaitingRoomOverlay.vue";
	import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";
	import {IonPage} from "@ionic/vue";
	import EventStateSpinner from "@/components/EventState/EventStateSpinner.vue";
	import VideoViewForIonSlides from "@/components/telehealth/VideoViewForIonSlides.vue";

	@Options({
		components: {
			VideoViewForIonSlides,
			OnDemandBookingTelehealthWaitingRoomOverlay,
			IonPage,
			EventStateSpinner,
			AppChip,
			TelehealthWrapper,
			PatientTelehealthHeader,
			ChatView,
			WaitingRoomOverlay,
		},
	})
	export default class TelehealthPatient extends TelehealthBase
	{
		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async onExit()
		{
			await this.endCall();

			if (!OrganizationConfigurationStore.telehealthGoToOverlayOnCallExit)
			{
				this.$router.back();
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get shouldShowOverlay(): boolean
		{
			return !this.inRoom() && !(this.isVoipCall && this.isLoading);
		}
	}
