

	import {Options, Vue, Prop} from "vue-property-decorator";

	import InboxRead from "./components/MessageRead.vue";
	import MessageCard from "@/views/patient_user/mail/components/MessageCard.vue";
	import {Mail} from "@/lib/Mailbox/Mail.model";
	import InboxCompose from "@/views/patient_user/mail/components/MessageCompose.vue";
	import {MailboxController} from "@/lib/Mailbox/Mailbox.controller";
	import {MailboxType} from "@/lib/Mailbox/Mailbox.types";
	import MailHeader from "@/views/patient_user/mail/components/MailHeader.vue";

	@Options({components: {InboxCompose, InboxRead, MailHeader, MessageCard}})
	export default class MessageView extends Vue
	{
		@Prop() controller: MailboxController;

		public mail: Mail = null;

		public selectMail(mail: Mail)
		{
			this.controller.selectMail(mail);
			this.$emit("selected");
		}

		public composeNew()
		{
			this.$emit("compose");
		}

		public onSelectMailbox(option)
		{
			this.controller.changeMailbox(option.value);
		}

		get selectedMailboxOption()
		{
			return this.mailboxOptions.find((option) => option.value === this.controller.mailboxType);
		}

		get mailboxOptions(): any[]
		{
			return [
				{
					name: this.$mhat("MessageFeed.InboxMailbox"),
					value: MailboxType.Inbox,
				},
				{
					name: this.$mhat("MessageFeed.SentMailbox"),
					value: MailboxType.Sent,
				},
				{
					name: this.$mhat("MessageFeed.ArchiveMailbox"),
					value: MailboxType.Archive,
				}];
		}

		get allMail(): Mail[]
		{
			return this.controller.activeMailbox.mail;
		}
	}
