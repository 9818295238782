import mhaResources from "@/res/myhealthaccess_resource.json";
import cmdResources from "@/res/cloudmd_resource.json";
import DeviceInfo from "@/lib/DeviceInfo";
import {ResourceString} from "@/res/app_resource";

const resources: ResourceString = DeviceInfo.isCloudMd ? cmdResources : mhaResources;

export function getResources(): ResourceString
{
	return resources;
}
