import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f181e0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "waiting-room-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnDemandBookingWaitingRoom = _resolveComponent("OnDemandBookingWaitingRoom")!
  const _component_OnDemandExamRoom = _resolveComponent("OnDemandExamRoom")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.inExamRoom && (_ctx.isLoading || _ctx.failure))
      ? (_openBlock(), _createBlock(_component_OnDemandBookingWaitingRoom, {
          key: 0,
          "clinic-id": _ctx.clinicId,
          "queued-appointment-id": _ctx.queuedAppointmentId,
          "queue-id": _ctx.queueId,
          onExit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onExit())),
          onToExamRoom: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inExamRoom = true))
        }, null, 8, ["clinic-id", "queued-appointment-id", "queue-id"]))
      : (_openBlock(), _createBlock(_component_OnDemandExamRoom, {
          key: 1,
          "is-loading": _ctx.isLoading,
          failure: _ctx.failure,
          "clinic-id": _ctx.clinicId,
          "queue-id": _ctx.queueId,
          "queued-appointment-id": _ctx.queuedAppointmentId,
          onExit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onExit()))
        }, null, 8, ["is-loading", "failure", "clinic-id", "queue-id", "queued-appointment-id"]))
  ]))
}