

	import {Options, Vue, Prop} from "vue-property-decorator";
	import AppChipBase from "@/components/Buttons/AppChip/AppChipBase.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";

	@Options({
		emits: ["click"],
		components: {AppChipBase},
	})
	export default class AppChip extends Vue
	{
		@Prop({type: String, default: ButtonColor.PRIMARY}) color: ButtonColor;
		@Prop({type: String, default: ButtonColorPattern.COLORED}) pattern: ButtonColorPattern;

		// ==========================================================================
		// Getters
		// ==========================================================================

		get fillPatternClass(): string
		{
			if (this.pattern === "")
			{
				return "default";
			}
			return this.pattern;
		}
	}
