import {Validator} from "@/lib/utils/Validator";

export default class PasswordController
{
	private _password: string;
	private _confirmPassword: string;

	constructor()
	{
		this.clear();
	}

	public isPasswordValidAndConfirmed()
	{
		return this.isPasswordValid && this.isConfirmPasswordValid;
	}

	public clear()
	{
		this.password = "";
		this.confirmPassword = "";
	}

	get password(): string
	{
		return this._password;
	}

	set password(value: string)
	{
		this._password = value;
	}

	get confirmPassword(): string
	{
		return this._confirmPassword;
	}

	set confirmPassword(value: string)
	{
		this._confirmPassword = value;
	}

	get isPasswordValid()
	{
		return Validator.password(this.password);
	}

	get isConfirmPasswordValid()
	{
		return Validator.matches(this.password,
			this.confirmPassword,
			Validator.password);
	}
}
