import MhaDocument from "@/lib/document/model/MhaDocument";
import DocumentCreateTransferFactory from "@/lib/document/factory/DocumentCreateTransferFactory";
import FileUtil from "@/lib/utils/FileUtil";
import {ClinicAPI, PatientAPI} from "@/lib/services/Api";
import DocumentTransferToMhaDocumentConverter from "@/lib/document/conversion/DocumentTransferToMhaDocumentConverter";
import BaseService from "@/lib/services/BaseService";

export default class DocumentService extends BaseService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * create a new document
	 * @param file - the file the new document will comprise
	 * @param shareWithId - patient id who you want to share the document with if uploader
	 *                   is a clinic user or clinic id if the uploader is a patient user.
	 * @return - the newly uploaded Mha document.
	 */
	public async createDocument(file: File, shareWithId: string): Promise<MhaDocument>
	{
		const documentTransfer = (new DocumentCreateTransferFactory()).create(shareWithId, file.name, file.type, file.size, await FileUtil.blobToBase64(file));

		return (new DocumentTransferToMhaDocumentConverter())
			.convert((await this.doByUserType(() => PatientAPI().patientUploadDocument(documentTransfer),
			                                 () => ClinicAPI().clinicUploadDocument(documentTransfer))).data);
	}

	/**
	 * get a document
	 * @param documentId - the id of the document to get
	 * @return - promise that resolves to the document
	 */
	public async getDocument(documentId: string): Promise<MhaDocument>
	{
		return (new DocumentTransferToMhaDocumentConverter()).convert((await this.doByUserType(() => PatientAPI().patientGetDocument(documentId),
		                                                                                      () => ClinicAPI().clinicGetDocument(documentId))).data);
	}
}
