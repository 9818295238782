import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35dcb783"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "video-view",
  ref: "video-view"
}
const _hoisted_2 = { class: "absolute-overlay" }
const _hoisted_3 = { class: "header-container" }
const _hoisted_4 = {
  key: 0,
  class: "w-100"
}
const _hoisted_5 = {
  key: 0,
  class: "top-controls"
}
const _hoisted_6 = { class: "call-duration" }
const _hoisted_7 = {
  key: 0,
  class: "muted-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_chip = _resolveComponent("app-chip")!
  const _component_VideoFilter = _resolveComponent("VideoFilter")!
  const _component_VideoViewControls = _resolveComponent("VideoViewControls")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "subscriber",
      class: _normalizeClass({'video-filter': _ctx.videoFilterEnabled})
    }, null, 2),
    _createElementVNode("div", {
      class: "ui-overlay",
      onMousemove: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.state.handleMouseMove && _ctx.state.handleMouseMove(...args))),
      onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.state.handleMouseUp && _ctx.state.handleMouseUp(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_Transition, {
          name: "slide-up",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.state.visible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "top-controls", {}, undefined, true),
                  (!_ctx.overrideTopControls)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_app_chip, {
                          class: "chat-toggle",
                          onClick: _ctx.openChat
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(this.$mhat("VideoView.ChatButtonText")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.state.callTime), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 3
        })
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["bottom-controls", {active: _ctx.state.visible}])
      }, [
        _createElementVNode("div", {
          id: "publisher",
          class: _normalizeClass({publishing: _ctx.state.canEnd}),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePublisherClick && _ctx.handlePublisherClick(...args)))
        }, [
          (_ctx.state.muted)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("i", {
                  class: _normalizeClass(_ctx.state.muted ? 'icon-tele-volume-mic-mute' : 'icon-tele-volume-mic')
                }, null, 2)
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode(_component_VideoFilter),
        _createVNode(_component_VideoViewControls, {
          onClick: _withModifiers(_ctx.state.handleMouseMove, ["stop"]),
          onCall: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('call'))),
          onEnd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('end'))),
          onFlip: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('flip'))),
          onToggleVideo: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('toggle-video'))),
          onMute: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('mute'))),
          onCycleView: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('cycleView'))),
          state: _ctx.state,
          "show-view-cycle": _ctx.allowViewCycle
        }, null, 8, ["onClick", "state", "show-view-cycle"])
      ], 2)
    ], 32)
  ], 512))
}