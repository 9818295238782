import {RouteAuthType} from "@/router/types";
import BookingReason from "@/views/patient_user/odb/booking/OnDemandBookingReason.vue";
import BookingRedirect from "@/views/patient_user/odb/booking/OnDemandBookingRedirect.vue";
import OnDemandBookingTelehealth from "@/views/patient_user/odb/telehealth/OnDemandBookingTelehealth.vue";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import {Route} from "@/router/router";
import DeviceInfo from "@/lib/DeviceInfo";
import OnDemandBookingTelehealthIos from "@/views/patient_user/odb/telehealth/OnDemandBookingTelehealthIos.vue";
import MHABackendLogger from "@/lib/utils/MHABackendLogger";
import {TELEHEALTH_MODE} from "@/components/telehealth/TelehealthMode";
import {RouteRecordRaw} from "vue-router";

export enum ODBBookingRoute
{
	Redirect = "ODBBookingRedirect",
	BookingReason = "ODBBookingReason",
	Telehealth = "ODBTelehealth",
}

export const odbBookingRoutes: RouteRecordRaw[] = [
	{
		path: "/org/:organizationId/odb/redirect",
		name: ODBBookingRoute.Redirect,
		component: BookingRedirect,
		props: true,
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/odb/reason",
		name: ODBBookingRoute.BookingReason,
		component: BookingReason,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/odb/queue/:queueId/queued_appointment/:queuedAppointmentId/session",
		name: ODBBookingRoute.Telehealth,
		component: DeviceInfo.isIOS() ? OnDemandBookingTelehealthIos : OnDemandBookingTelehealth,
		props: (route) =>
		{
			return Object.assign(
				{
					telehealthMode: TELEHEALTH_MODE.AQS,
					clinicId: OrganizationStore.organizationProfile.primary_clinic_id,
				},
				route.params);
		},
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
