import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8cefe6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-col w-100"
}
const _hoisted_2 = { class: "m-b-32" }
const _hoisted_3 = { class: "font-color-neutral-grey d-flex flex-row" }
const _hoisted_4 = { class: "p-relative" }
const _hoisted_5 = {
  key: 0,
  class: "loading-overlay"
}
const _hoisted_6 = { class: "d-flex flex-row justify-content-center" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalHeader = _resolveComponent("ModalHeader")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_DocumentPreview = _resolveComponent("DocumentPreview")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_SmallButton = _resolveComponent("SmallButton")!
  const _component_page_wrapper = _resolveComponent("page-wrapper")!

  return (_openBlock(), _createBlock(_component_page_wrapper, { contentWidthFull: "" }, {
    "fixed-header": _withCtx(() => [
      _createVNode(_component_ModalHeader, {
        "header-text": _ctx.$mhat('ChartUploadDocumentReviewModal.Header'),
        headerIcon: "icon-attachments"
      }, null, 8, ["header-text"])
    ]),
    "content-header": _withCtx(() => [
      (_ctx.hasDocuments)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$mhat("ChartUploadDocumentReviewModal.InstructionText")), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documentUploadList, (documentUploadItem) => {
              return (_openBlock(), _createElementBlock("div", {
                key: documentUploadItem.document.id,
                class: "d-flex flex-col align-items-start m-b-24"
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_Checkbox, {
                    checked: documentUploadItem.upload,
                    onClick: ($event: any) => (documentUploadItem.upload = !documentUploadItem.upload)
                  }, null, 8, ["checked", "onClick"]),
                  _createElementVNode("span", null, _toDisplayString(documentUploadItem.document.name), 1)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_DocumentPreview, {
                    class: _normalizeClass(["document-preview", {'loading': documentUploadItem.loading}]),
                    document: documentUploadItem.document
                  }, null, 8, ["class", "document"]),
                  (documentUploadItem.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_ion_spinner, { color: "light" })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createBlock(_component_EventStateSpinner, {
            key: 1,
            isLoading: true,
            loadingTitle: _ctx.$mhat('ChartUploadDocumentReviewModal.LoadingTitle'),
            loadingMessage: _ctx.$mhat('ChartUploadDocumentReviewModal.LoadingMessage')
          }, null, 8, ["loadingTitle", "loadingMessage"]))
    ]),
    "fixed-footer": _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_SmallButton, {
          onClick: _ctx.uploadDocuments,
          disabled: _ctx.isLoading,
          color: _ctx.ButtonColor.PRIMARY,
          pattern: _ctx.ButtonColorPattern.FILL
        }, {
          default: _withCtx(() => [
            (!_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Continue"))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, "Uploading..."))
          ]),
          _: 1
        }, 8, ["onClick", "disabled", "color", "pattern"])
      ])
    ]),
    _: 1
  }))
}