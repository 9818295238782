import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-361c483a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-control-button-container" }
const _hoisted_2 = { class: "btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(_ctx.getHeaderText), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_chip, {
        class: "btn primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCancelFunction()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.$mhat("ProfileControlButtons.CancelButtonText")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_chip, {
        class: "btn fill primary",
        disabled: _ctx.disableSave,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSave()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.$mhat("ProfileControlButtons.SaveButtonText")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}