import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a41f3a8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "split-left" }
const _hoisted_2 = {
  id: "main",
  class: "split-right"
}
const _hoisted_3 = {
  key: 1,
  class: "split-pane-main"
}
const _hoisted_4 = {
  key: 0,
  class: "split-left"
}
const _hoisted_5 = {
  key: 1,
  class: "split-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!

  return (!_ctx.hideLeft && !_ctx.hideRight)
    ? (_openBlock(), _createBlock(_component_ion_split_pane, {
        key: 0,
        class: "split-pane split-pane-main",
        "content-id": "main",
        when: "xs"
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            name: "hide-left",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "split-left", {}, undefined, true)
              ])
            ]),
            _: 3
          }),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "split-right", {}, undefined, true)
          ])
        ]),
        _: 3
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        (!_ctx.hideLeft)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "split-left", {}, undefined, true)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.hideRight)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "split-right", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ]))
}