import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43a85679"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 2,
  class: "mha text separator"
}
const _hoisted_3 = { class: "show-all-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicsFindHeader = _resolveComponent("ClinicsFindHeader")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_ListFilterDropdown = _resolveComponent("ListFilterDropdown")!
  const _component_ClinicCard = _resolveComponent("ClinicCard")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_ClinicSearchSpinner = _resolveComponent("ClinicSearchSpinner")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_ClinicsFindHeader)
    ]),
    _: 2
  }, [
    (!_ctx.shouldShowSpinner)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            (_ctx.isDependentMode)
              ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                  key: 0,
                  dependent: _ctx.patientUser,
                  "text-prefix": this.$mhat('ClinicsFind.DependentHeaderTextPrefix')
                }, null, 8, ["dependent", "text-prefix"]))
              : _createCommentVNode("", true),
            _createElementVNode("p", null, _toDisplayString(this.$mhat("ClinicsFind.SelectClinicText")), 1),
            (!_ctx.controller.hasLocationAccess)
              ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                  _createElementVNode("i", null, _toDisplayString(this.$mhat("ClinicsFind.LocationUnavailableMessage")), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_ListFilterDropdown, {
              filters: _ctx.filters,
              "active-filter": _ctx.activeFilter,
              "input-value": _ctx.filterText,
              onInput: _ctx.onInput,
              onSelect: _ctx.onSelect
            }, null, 8, ["filters", "active-filter", "input-value", "onInput", "onSelect"]),
            (_ctx.controller.restrictedClinicsMessage && _ctx.controller.restrictClinicList)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.controller.restrictedClinicsMessage), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredClinics, (clinic) => {
              return (_openBlock(), _createBlock(_component_ClinicCard, {
                key: clinic.id,
                clinic: clinic,
                clickable: "",
                onClick: ($event: any) => (_ctx.goToClinic(clinic))
              }, null, 8, ["clinic", "onClick"]))
            }), 128)),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.controller.restrictClinicList)
                ? (_openBlock(), _createBlock(_component_RouteButton, {
                    key: 0,
                    pattern: _ctx.ButtonColorPattern.FILL,
                    color: _ctx.ButtonColor.PRIMARY,
                    route: _ctx.controller.noRestrictionsRoute()
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("ClinicsFind.ShowAllClinics")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "route"]))
                : _createCommentVNode("", true)
            ])
          ])
        }
      : {
          name: "content-center",
          fn: _withCtx(() => [
            _createVNode(_component_ClinicSearchSpinner, {
              "search-type": _ctx.searchType,
              "failure-title": _ctx.spinnerFailure.title,
              "failure-message": _ctx.spinnerFailure.message,
              "is-loading": _ctx.controller.isLoading,
              "show-failure": _ctx.controller.noClinics,
              onRefresh: _ctx.findClinics
            }, null, 8, ["search-type", "failure-title", "failure-message", "is-loading", "show-failure", "onRefresh"])
          ])
        }
  ]), 1024))
}