import {RouteAuthType} from "@/router/types";
import {RouteLocationRaw, RouteRecordRaw} from "vue-router";
import AqsAppointmentType from "@/views/patient_user/appointment/aqs/AqsAppointmentType.vue";
import {TELEHEALTH_MODE} from "@/components/telehealth/TelehealthMode";
import {Route} from "@/router/router";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import OrganizationService from "@/lib/organization/service/OrganizationService";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import {ErrorCode} from "@/open_api/generated";
import OrgAqsRedirect from "@/views/patient_user/appointment/aqs/OrgAqsRedirect.vue";

export enum AqsBookingRoutes
{
	AppointmentType = "AqsAppointmentType",
	OrganizationRedirect = "OrganizationRedirect",
}

export const aqsBookingRoutes: RouteRecordRaw[] = [
	{
		path: "/aqs/book/clinic/:clinicId/appointmentType",
		name: AqsBookingRoutes.AppointmentType,
		props: (route) =>
		{
			return Object.assign(
				{
					backRoute: route.query.backRoute,
					forwardRoute: route.query.forwardRoute,
					bookAppointment: !route.query.bookAppointment || route.query.bookAppointment === "true",
					hideHeader: route.query.hideHeader === "true",
				},
				route.params);
		},
		component: AqsAppointmentType,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},

	{ // dummy redirect route. This route redirects the user to the aqs booking for an organization's primary clinic
		path: "/aqs/book/org_aqs_redirect",
		name: AqsBookingRoutes.OrganizationRedirect,
		component: OrgAqsRedirect,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
