

	import {ClinicPageController} from "@/views/patient_user/clinic/ClinicPageController";
	import {ClinicBookingStatus, ClinicConnectionStatus} from "@/open_api/generated";
	import {PendingPatientResponse} from "@/lib/types/Clinic";
	import ClinicPage from "@/views/patient_user/clinic/BaseClinicPage.vue";
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";
	import { Patient } from "@/lib/patient/Patient";
	import ClinicPageControllerFactory from "@/views/patient_user/clinic/ClinicPageControllerFactory";
	import {modalController, alertController} from "@ionic/vue";
	import ModalBaseController from "@/lib/modal/modalBaseController";

	@Options({components: {ClinicPage}})
	export default class ClinicView extends ModalBaseController
	{
		@Prop() clinicId: string;
		@Prop() parentCtx: Vue;
		@Prop({type: Object, default: null}) public readonly patientUser: Patient;

		controller: ClinicPageController = null;
		PendingPatientResponse = PendingPatientResponse;
		ClinicBookingStatus = ClinicBookingStatus;
		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		// ==========================================================================
		// Vue lifecycle
		// ==========================================================================

		public onCreated()
		{
			// TODO don't pass components to controllers
			this.controller = ClinicPageControllerFactory.createControllerByPatientUser(this as any, this.patientUser);
			this.loadClinic();
		}

		public beforeUnmount()
		{
			alertController.getTop()
				.then((a) =>
				{
					if (a)
					{
						a.dismiss();
					}
				});
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		private loadClinic()
		{
			if (this.clinicId)
			{
				this.controller.gettingClinic = true;
				this.controller.getClinic(this.clinicId);
			}
		}

		public routeToComposeMessage(): void
		{
			this.dismissModal(true);
			this.parentCtx.$mhaRouterPush(
				Route.Inbox.Home,
				{
					query: {
						compose: "true",
						clinicId: this.controller.clinic.id,
					},
				},
			);
		}

		public routeToBooking(): void
		{
			this.dismissModal(true);
			this.controller.routeToBookAppointment();
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get isLoading()
		{
			return this.controller.isLoading;
		}

		get canMessage()
		{
			return this.controller.clinic && this.controller.clinic.patientCanMessage;
		}

		get canRemove()
		{
			const clinic = this.controller.clinic;
			return clinic.pendingClinicConfirm ||
				clinic.pendingPatientConfirm ||
				clinic.isConnected;
		}

		get showSpinner()
		{
			return this.controller.gettingClinic || this.showFailure;
		}

		get showFailure()
		{
			return !this.controller.gettingClinic && !this.controller.clinic;
		}
	}
