import {RouteType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";
import {ClinicUserLoginRoute, clinicUserLoginRoutes} from "@/router/clinic_user/login.routes";
import {ClinicUserHomeRoute, clinicUserHomeRoutes} from "@/router/clinic_user/home.routes";
import {ClinicUserAppointmentRoute, clinicUserAppointmentRoutes} from "@/router/clinic_user/appointment.routes";

export const ClinicUserRoute = {
	Login: ClinicUserLoginRoute.Home,
	Home: ClinicUserHomeRoute.Home,
	Error: ClinicUserHomeRoute.Error,
};

export type ClinicUserRouteType = ClinicUserLoginRoute | ClinicUserHomeRoute | ClinicUserAppointmentRoute;

export const clinicUserRoutes: RouteRecordRaw[] = [
	...clinicUserLoginRoutes,
	...clinicUserHomeRoutes,
	...clinicUserAppointmentRoutes,
];
