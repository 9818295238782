
	import InputLayout from "@/components/Inputs/InputLayout.vue";
	import {IonToggle} from "@ionic/vue";

	export default {
		name: "Toggle",
		components: {InputLayout, IonToggle},
		props: {
			status: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},

		methods: {
			onClick()
			{
				if (!this.disabled)
				{
					this.$emit("toggled");
				}
			},
		},

		computed: {
			activeClass()
			{
				return this.status ? "active" : "inactive";
			},

			disabledClass()
			{
				return this.disabled ? "disabled" : "";
			},
		},
	};
