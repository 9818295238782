import {Patient} from "@/lib/patient/Patient";
import {Passwords} from "@/lib/types/Profile";
import PatientService from "@/lib/services/PatientService";
import {Validator} from "@/lib/utils/Validator";
import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
import {ProfileController} from "@/views/patient_user/profile/controllers/ProfileController";
import {PatientLoginService} from "@/lib/services/login/PatientLogin.service";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import AuthStore from "@/lib/vuex/auth.store";
import {ref} from "vue";
import {$mhat} from "@/i18n";

export class UserProfileController extends ProfileController
{
	protected _passwordFields: Passwords;

	public constructor()
	{
		super();
		this.reset();
	}

	public onSave()
	{
		return this.onProfileSave();
	}

	/// // Validation /////

	public isValidPassword(): boolean
	{
		return Validator.password(this.passwords.currentPassword);
	}

	public isValidNewPassword(): boolean
	{
		return Validator.password(this.passwords.newPassword);
	}

	public isValidConfirmPassword(): boolean
	{
		return Validator.matches(this.passwords.newPassword, this.passwords.confirmPassword, Validator.password);
	}

	public canSavePassword(): boolean
	{
		return this.isValidPassword() && this.isValidConfirmPassword();
	}

	/// / Input Handlers /////

	public passwordInput(password)
	{
		this.passwords[password.id] = password.value;
	}

	/// / Getters Setters ////

	public get passwords(): any
	{
		return this._passwordFields;
	}

	public set passwords(passwords: any)
	{
		this._passwordFields = passwords;
	}

	// Button Handlers //

	public onToggleEmail()
	{
		const newEmailValue = !this.userProfile.emailNotifications;
		this.updateNotifications(this.userProfile.smsNotifications, newEmailValue);

		if (!newEmailValue) // email turned off
		{
			WebNotification.$emit({
				event: NotifyEvent.InvalidLogin,
				type: NotificationType.Dismiss,
				severity: NotificationSeverity.Reminder,
				title: $mhat("UserProfileController.GenericNotificationTitle"),
				message: $mhat("UserProfileController.TurnOffEmailNotificationWarning"),
				dismiss: {
					message: $mhat("UserProfileController.ConfirmationButtonText"),
				},
			});
		}
	}

	public onToggleSMS()
	{
		const newSmsValue = !this.userProfile.smsNotifications;
		this.updateNotifications(newSmsValue, this.userProfile.emailNotifications);

		if (!newSmsValue) // sms turned off
		{
			WebNotification.$emit({
				event: NotifyEvent.InvalidLogin,
				type: NotificationType.Dismiss,
				severity: NotificationSeverity.Reminder,
				title: $mhat("UserProfileController.GenericNotificationTitle"),
				message: $mhat("UserProfileController.TurnOffTextNotificationWarning"),
				dismiss: {
					message: $mhat("UserProfileController.ConfirmationButtonText"),
				},
			});
		}
	}

	public onCancel()
	{
		this.reset();
	}

	public onLogOut()
	{
		new PatientLoginService().logout();
	}

	public onDeactivate()
	{
		return PatientService.deactivateAccount().then(
			(success) =>
			{
				this.onLogOut();
				return true;
			},
			(errors) =>
			{
				ProfileController.emitProfileError(errors);
			},
		);
	}

	public onPasswordEdit()
	{
		this.reset();
	}

	public updateEmail()
	{
		return PatientService.updateEmail(this.userProfile, this.passwords.currentPassword).then(
			(success) =>
			{
				this.reset();
				this.alertConfirmEmailChange();
			},
			(errors: ErrorResponse) =>
			{
				ProfileController.emitProfileError(errors);
			},
		);
	}

	public updatePassword()
	{
		const {currentPassword, newPassword, confirmPassword} = this.passwords;
		return PatientService.updatePassword(currentPassword, newPassword, confirmPassword)
			.then(
				(success) =>
				{
					this.reset();
				},
				(errors) =>
				{
					ProfileController.emitProfileError(errors);
				},
			);
	}

	protected getProfile()
	{
		return PatientService.getLocalProfileCopy();
	}

	// View State Handlers

	protected reset()
	{
		this.resetProfile();

		this._passwordFields = {
			currentPassword: "",
			confirmPassword: "",
			newPassword: "",
		};
	}

	protected alertSaveDisabled()
	{
		WebNotification.$emit({
			event: NotifyEvent.Generic,
			type: NotificationType.Dismiss,
			severity: NotificationSeverity.Warning,
			title: $mhat("UserProfileController.GenericNotificationTitle"),
			message: $mhat("UserProfileController.MissingRequiredFieldsMessage"),
			dismiss: {
				message: $mhat("UserProfileController.OkButtonText"),
			},
		});
	}

	protected alertConfirmEmailChange()
	{
		WebNotification.$emit({
			event: NotifyEvent.Generic,
			type: NotificationType.Dismiss,
			severity: NotificationSeverity.Reminder,
			title: $mhat("UserProfileController.CheckEmailAlertTitle"),
			message: $mhat("UserProfileController.CheckEmailAlertMessage"),
			dismiss: {
				message: $mhat("UserProfileController.OkButtonText"),
			},
		});
	}

	protected onProfileSave()
	{
		return PatientService.updateProfile(this.userProfile).then(
			(success) =>
			{
				this.reset();
			},
			(errors) =>
			{
				ProfileController.emitProfileError(errors);
			});
	}

	private updateNotifications(sms: boolean, email: boolean)
	{
		PatientService.updateNotifications(sms, email).then(
			(success) =>
			{
				// We don't want to call reset here (or otherwise update the local copy of the userProfile),
				// otherwise it will revert other fields in progress if it's in edit mode;
				const profileCopy = this.getProfile();
				this.userProfile.smsNotifications = profileCopy.smsNotifications;
				this.userProfile.emailNotifications = profileCopy.emailNotifications;
			},
			(errors) =>
			{
				// Likewise, if there's an error, we'll only revert the property which we attempted to change
				// to avoid clobbering the other form elements.
				ProfileController.emitProfileError(errors);
			});
	}

	private resetProfile()
	{
		this._userProfile = ref(PatientService.getLocalProfileCopy() as Patient);
	}
}

export default {ProfileController};
