import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-center": _withCtx(() => [
      _createVNode(_component_EventStateSpinner, {
        isLoading: _ctx.isLoading,
        loadingTitle: this.$mhat('OnDemandBookingRedirect.LoadingTitle'),
        loadingMessage: this.$mhat('OnDemandBookingRedirect.LoadingMessage'),
        showFailureState: _ctx.hasError,
        failureTitle: "Error",
        failureMessage: _ctx.errorString
      }, null, 8, ["isLoading", "loadingTitle", "loadingMessage", "showFailureState", "failureMessage"]),
      (_ctx.hasError)
        ? (_openBlock(), _createBlock(_component_RouteButton, {
            key: 0,
            primary: "",
            route: _ctx.Route.Home
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.$mhat("OnDemandBookingRedirect.HomePageButtonText")), 1)
            ]),
            _: 1
          }, 8, ["route"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}