

	// this is a simplified version of the event state spinner,
	// for cases when there is no failure state

	import {Options, Vue, Prop} from "vue-property-decorator";
	import EventStateSpinner from "@/components/EventState/EventStateSpinner.vue";

	@Options({components: {EventStateSpinner}})
	export default class EventStateSpinnerSearch extends Vue
	{
		@Prop() isLoading: boolean;

		@Prop({ default: "icon-search" }) loadingIcon: string;
		@Prop({ default: "Searching..." }) loadingTitle: string;
		@Prop() loadingMessage: string | string[];

		@Prop({ default: "icon-question" }) noResultsIcon: string;
		@Prop({ default: "No Results Found" }) noResultsTitle: string;
		@Prop() noResultsMessage: string | string[];
	}
