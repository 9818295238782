

	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import {Validator} from "@/lib/utils/Validator";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import Services from "@/lib/services/Services";
	import LoginPageHeader from "@/views/patient_user/login/components/LoginPageHeader.vue";
	import {Options, Vue} from "vue-property-decorator";
	import {removeURLParameter} from "@/lib/utils/history";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";

	@Options({components: {LoginPageHeader, CorporateFooter}})
	export default class Login extends Vue
	{
		public isValidEmail = true;
		public hasFormError = false;
		public isLoading = false;

		public email = "";
		public password = "";

		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		public async login()
		{
			if (!this.canSubmit)
			{
				return false;
			}

			this.isLoading = true;
			this.hasFormError = false;

			try
			{
				await Services.ProviderLogin.login(this.email, this.password);
				this.handleLoginSuccess();
			}
			catch (e)
			{
				this.handleError(e);
			}

			this.isLoading = false;
		}

		public handleLoginSuccess()
		{
			const pushRoute = this.$route.query.push_route;

			if (pushRoute && pushRoute !== "/login")
			{
				this.$mhaRouterPush(pushRoute as string);
			}
			else
			{
				// TODO: Provider home
				this.$mhaRouterPush(Route.ClinicUser.Home);
			}
		}

		public handleError(error: ErrorResponse)
		{
			// remove the parameter from the url and update. this prevents NavigationDuplicated errors
			window.location = removeURLParameter(window.location.href, "error_code");

			if (error.is(ErrorCode.Authentication) || error.is(ErrorCode.Validation))
			{
				this.hasFormError = true;
			}

			this.notifyLoginError(error.message);
		}

		public setPassword(payload)
		{
			this.password = payload.value || "";
		}

		public setEmail(payload)
		{
			const email: string = payload.value.trim() || "";

			// TODO: This needs to be a computed property
			this.isValidEmail = Validator.emailAddress(email) && Validator.isPresent(email);
			this.email = email;
		}

		public notifyLoginError(message: string)
		{
			WebNotification.$emit({
				event: NotifyEvent.InvalidLogin,
				type: NotificationType.Swipe,
				severity: NotificationSeverity.Reminder,
				title: "Login Error",
				message,
				timeout: 5000,
				icon: "icon-lock",
			});
		}

		get canSubmit()
		{
			return !this.isLoading && this.isValidEmail && Validator.password(this.password);
		}
	}
