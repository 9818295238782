

	import {Options, Prop, Vue, Watch} from "vue-property-decorator";
	import AppointmentInfo, {AppointmentModalDismissReason} from "./AppointmentInfo.vue";
	import AppointmentsFeed from "./AppointmentsFeed.vue";
	import AppointmentsHeader from "./components/AppointmentsHeader.vue";
	import {AppointmentCardInfo} from "@/lib/types/Appointment";
	import {Appointment} from "@/lib/models/Appointment";
	import {ModalOptions} from "@ionic/core";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import DeviceInfo from "@/lib/DeviceInfo";
	import {modalController} from "@ionic/vue";
	import AuthStore from "@/lib/vuex/auth.store";
	import DependentService from "@/lib/dependents/Dependent.service";
	import {Route} from "@/router/router";

	@Options({components: {AppointmentsHeader, AppointmentsFeed, AppointmentInfo}})
	export default class AppointmentsHome extends Vue
	{
		@Prop({type: String, default: null, required: false}) remoteAppointmentId: string;
		@Prop({type: String, default: null, required: false}) clinicId: string;
		@Prop({type: String, default: null, required: false}) selectedPatientId: string;

		public remoteAppointments: Appointment[];
		public appointmentFeedLoading = false;
		public appointmentFeedHasAppointments= true;
		public modal: HTMLIonModalElement = null;

		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		// ==========================================================================
		// Vue Lifecycle
		// ==========================================================================

		// ==========================================================================
		// Vue Hooks
		// ==========================================================================

		@Watch("remoteAppointmentId", {immediate: true})
		public onAppointmentIdChange(newAppointmentId: string): void
		{
			if (this.isMobile && this.clinicId && newAppointmentId)
			{
				this.showModal();
			}
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		/**
		 * called when the appointment feed user changes
		 * @param userId - the newly selected user id
		 */
		public onAppointmentFeedUserChange(userId: string): void
		{
			this.$mhaRouterReplace(this.Route.Appointments.View, {params: {clinicId: null, remoteAppointmentId: null, selectedPatientId: userId}});
		}

		/**
		 * called when an appointment is cancelled
		 */
		public onAppointmentCancelled(): void
		{
			// remove appointment from feed.
			const appointmentFeed = (this.$refs.appointmentFeed) as AppointmentsFeed;
			if (appointmentFeed)
			{
				appointmentFeed.removeAppointmentByRemoteIdClinicId(this.remoteAppointmentId, this.clinicId);
			}

			// go back to base appointment page.
			this.$mhaRouterReplace(this.Route.Appointments.View, {params: {clinicId: null, remoteAppointmentId: null}});
		}

		/**
		 * called when an appointment is selected from the feed.
		 * @param cardInfo - appointment info
		 */
		public selectAppointmentFromCardInfo(cardInfo: AppointmentCardInfo)
		{
			this.$mhaRouterReplace({
				name: this.Route.Appointments.View,
				params: {clinicId: cardInfo.clinicId, remoteAppointmentId: cardInfo.remoteId, selectedPatientId: this.selectedPatientId},
			});
		}

		/**
		 * direct the user to the booking workflow. Dependent / normal.
		 */
		public bookForSelected()
		{
			if (this.selectedPatientId !== AuthStore.loggedInPatient.id)
			{
				this.$mhaRouterPush({
					name: Route.Booking.Dependent.Clinic,
					params: {
						dependentId: this.selectedPatientId,
					},
				});
			}
			else
			{
				this.$mhaRouterPush(Route.Booking.Clinic);
			}
		}

		/**
		 * show the currently selected appointment details in a modal.
		 */
		public async showModal()
		{
			if (this.clinicId && this.remoteAppointmentId)
			{
				this.modal = await this.createModal(AppointmentInfo, {
					clinicId: this.clinicId,
					remoteAppointmentId: this.remoteAppointmentId,
					dismissMethod: this.dismissModal,
					isMobile: true,
				});

				this.modal.present();
				const dismissReason: AppointmentModalDismissReason = (await this.modal.onDidDismiss()).data;

				if (dismissReason === AppointmentModalDismissReason.AppointmentCancelled)
				{
					this.onAppointmentCancelled();
				}
				else if (dismissReason === AppointmentModalDismissReason.ExternalRoute)
				{
					// nop
				}
				else
				{
					this.$mhaRouterReplace(this.Route.Appointments.View, {params: {clinicId: null, remoteAppointmentId: null, selectedPatientId: this.selectedPatientId}});
				}
			}
		}

		public dismissModal(cancelledAppointment = false)
		{
			if (this.modal)
			{
				this.modal.dismiss();
			}

			if (cancelledAppointment)
			{
			}
		}

		public createModal(component: typeof Vue, propsData)
		{
			return modalController
				.create({
					component,
					showBackdrop: true,
					componentProps: propsData,
				} as ModalOptions);
		}

		get shouldShowSpinner()
		{
			return this.appointmentFeedLoading;
		}

		/**
		 * get user facing message for zero state. different depending on if a dependent is selected or not.
		 * @return the message
		 */
		get zeroStateSpinnerMessage(): string
		{
			if (DependentService.isCachedDependent(this.selectedPatientId))
			{
				const dependent = DependentService.getCachedDependent(this.selectedPatientId);
				return this.$mhat("AppointmentsHome.NoDependentAppointmentsMessage", {dependentFirstName: dependent.firstName});
			}
			else
			{
				return this.$mhat("AppointmentsHome.NoAppointmentsMessage");
			}
		}

		get isMobile()
		{
			return DeviceInfo.isMobile();
		}
	}
