
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import {isBlank} from "@/lib/utils/prototype/String";
	import {setBookingParams} from "@/views/patient_user/booking/BookingController";
	import {Route} from "@/router/router";

	export default {
		name: "BookingDisclaimer",

		props: {
			appointmentMethod: String,
			clinicId: String,
			providerOrGroupId: String,
			appointmentTypeId: String,
			dependentId: String,
			hasClose: {
				type: Boolean,
				required: false,
				default: true,
			},
			rescheduleRemoteAppointmentId: String,
		},

		created()
		{
			setBookingParams.call(this);
		},

		computed: {
			controller()
			{
				return BookingControllerStore.bookingController;
			},

			bookingNotes()
			{
				if (this.clinic)
				{
					return this.clinic.bookingNotes;
				}

				return this.$mhat("BookingDisclaimer.LoadingMessage");
			},

			clinic()
			{
				if (this.controller)
				{
					return this.controller.clinic;
				}

				return null;
			},

			nextRoute()
			{
				const {params} = this;
				const name = this.isDependentMode ? Route.Booking.Dependent.NextAvailability : Route.Booking.NextAvailability;
				const query = {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId};
				return { name, params, query };
			},

			params()
			{
				const {appointmentMethod, clinicId, providerOrGroupId, appointmentTypeId} = this;
				return {appointmentMethod, clinicId, providerOrGroupId, appointmentTypeId};
			},

			isDependentMode()
			{
				return !isBlank(this.dependentId);
			},
		},

		components: {BookingHeaderBar, DependentSubheader},
	};
