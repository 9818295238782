import {alertController, AlertButton} from "@ionic/core";
import {$mhat} from "@/i18n";

export default class AlertService
{
	// ==========================================================================
	// Class Methods
	// ==========================================================================

	/**
	 * present a dismiss alert that automatically dismisses after the timeout period has elapsed.
	 * @param header - the alert header
	 * @param message - the alert message
	 * @param timeoutMs - the length of time before the alert times out
	 * @return a boolean indicating if a timeout occurred true == timeout.
	 */
	public static async dismissAlertWithTimeout(
		header: string,
		message: string,
		timeoutMs: number): Promise<boolean>
	{
		let didTimeout = false;
		const alertTimeout = window.setTimeout(() =>
		{
			didTimeout = true;
			alertController.getTop().then((alert) => alert?.dismiss());
		}, timeoutMs);

		await this.alert(
			header,
			message,
			[{text: $mhat("AlertService.DismissButtonText")}]);

		window.clearTimeout(alertTimeout);
		return didTimeout;
	}

	/**
	 * present and alert to the user with the given header, message and buttons.
	 * @param header - header (title) of alert
	 * @param message - message content of alert
	 * @param buttons - a list of buttons to show at the bottom of the alert.
	 */
	public static async alert(
		header: string,
		message: string,
		buttons: AlertButton[]): Promise<void>
	{
		const alert = await alertController.create({
			header,
			message,
			buttons,
		});

		alert.present();
		await alert.onDidDismiss();
	}
}
