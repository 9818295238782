

	import { Options, Vue } from "vue-property-decorator";
	import PopoverBase from "@/components/Popover/PopoverBase.vue";
	import RoundButton from "@/components/Buttons/RoundButton.vue";
	import {ButtonColor, ButtonColorPattern, ButtonSize} from "@/components/Buttons/types";
	import {popoverController} from "@ionic/vue";

	@Options({
		components: {RoundButton, PopoverBase},
	})
	export default class DefaultPopover extends Vue
	{
		public ButtonColorPattern = ButtonColorPattern;
		public ButtonColor = ButtonColor;
		public ButtonSize = ButtonSize;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public closePopover(): void
		{
			popoverController.dismiss();
		}
	}
