import {BaseUser} from "@/lib/baseUser.model";
import {ClinicUserDto} from "@/open_api/generated";
import Provider from "@/lib/clinic/providerProfile.model";
import {LoginToken} from "@/lib/models/LoginToken";
import {Route} from "@/router/router";

export class ClinicUser extends BaseUser
{
	public route = {
		Login: Route.ClinicUser.Login,
		Home: Route.ClinicUser.Home,
	};

	private _profile: Provider;

	public static fromDto(dto: ClinicUserDto, jwt: string): ClinicUser
	{
		const user = new ClinicUser();

		user.id = dto.id;
		user.email = dto.email;
		user._firstName = dto.first_name;
		user._lastName = dto.last_name;
		user.loginToken = new LoginToken(jwt);

		// TODO: Set PatientUser properties
		return user;
	}

	get profile(): Provider
	{
		return this._profile;
	}

	set profile(value: Provider)
	{
		this._profile = value;
	}
}
