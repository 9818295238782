import {BookingRoute, DependentBookingRoute} from "@/router/patient_user/booking.routes";

// Decorator class around a booking route, allowing abstracted access to dependent
// or patient booking routes.
export default class BookingDynamicRoute
{
	private bookingRoute: any;

	constructor(bookingRoute: any)
	{
		this.bookingRoute = bookingRoute;
	}

	get Home(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.Home;
	}

	get Clinic(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.Clinic;
	}

	get Provider(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.Provider;
	}

	get ProviderGroupProvider(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.ProviderGroupProvider;
	}

	get ProviderGroup(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.ProviderGroup;
	}

	get AppointmentType(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.AppointmentType;
	}

	get Notes(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.Notes;
	}

	get NextAvailability(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.NextAvailability;
	}

	get Calendar(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.Calendar;
	}

	get AppointmentTime(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.AppointmentTime;
	}

	get Reason(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.Reason;
	}

	get Confirm(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.Confirm;
	}

	get BookAppointment(): DependentBookingRoute | BookingRoute
	{
		return this.bookingRoute.BookAppointment;
	}
}
