import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07c6bddc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "care-card" }
const _hoisted_2 = { class: "logo-image" }
const _hoisted_3 = { class: "card-title line-one" }
const _hoisted_4 = { class: "card-title line-two" }
const _hoisted_5 = { class: "card-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.titleLineOne), 1),
    _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.titleLineTwo), 1),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.message), 1)
  ]))
}