

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class ClinicViewHeader extends Vue
	{
		@Prop({type: String}) headerText: string;

		get headerProps(): any
		{
			return {
				icon: "icon-clinic",
				headerText: this.$mhat("ClinicViewHeader.HeaderText"),
				hasClose: true,
				noBack: true,
			};
		}
	}
