

	import {Options, Vue, Prop} from "vue-property-decorator";

	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import {setBookingParams} from "@/views/patient_user/booking/BookingController";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import {isBlank} from "@/lib/utils/prototype/String";
	import {Route} from "@/router/router";
	import {DateUtils} from "@/lib/utils/Date";
	import {Appointment} from "@/lib/models/Appointment";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import BaseButton from "@/components/Buttons/BaseButton.vue";

	@Options({components: {BaseButton, BookingHeaderBar, DependentSubheader}})
	export default class BookingConfirm extends Vue
	{
		@Prop() public readonly appointmentId: string;
		@Prop() public readonly clinicId: string;
		@Prop() public readonly providerOrGroupId: string;
		@Prop() public readonly appointmentTypeId: string;
		@Prop() public readonly appointmentDate: string;
		@Prop() public readonly dependentId: string;
		@Prop({type: Boolean, default: true, required: false }) hasClose: boolean;
		@Prop() public readonly rescheduleRemoteAppointmentId: string;

		public isLoading = true;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public created()
		{
			setBookingParams.call(this);
			const {params} = this;

			if (!this.appointmentInfo)
			{
				const query = {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId};
				this.$mhaRouterReplace({
					name: this.isDependentMode ? Route.Booking.Dependent.AppointmentTime : Route.Booking.AppointmentTime,
					params,
					query,
				});
			}

			this.isLoading = false;
		}

		/**
		 * start the booking workflow over again.
		 */
		public onStartOver(): void
		{
			this.isLoading = true;

			const crossFrameService = new CrossFrameCommunicationService();
			if (crossFrameService.hasParentFrame)
			{
				// let any parent frames know we want to go back to the beginning of booking.
				crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateBack});
			}
			this.$mhaRouterPush(this.startOverRoute);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get controller()
		{
			return BookingControllerStore.bookingController;
		}

		get nextRoute()
		{
			const {params} = this;
			const query = {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId};
			const name = this.isDependentMode ? Route.Booking.Dependent.BookAppointment : Route.Booking.BookAppointment;
			return { name, params, query };
		}

		get startOverRoute()
		{
			if (this.rescheduleRemoteAppointmentId)
			{
				return {
					name: !isBlank(this.dependentId) ? Route.Booking.Dependent.AppointmentType : Route.Booking.AppointmentType,
					params: {dependentId: this.dependentId, clinicId: this.clinicId, providerOrGroupId: this.providerOrGroupId},
					query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId},
				};
			}

			return Route.Booking.Home;
		}

		get appointmentInfo(): Appointment
		{
			if (this.controller.provider && this.controller.selectedSlot)
			{
				const appointment = new Appointment({
					clinic_name: this.controller.clinic.name,
					provider_name: this.controller.provider.fullNameWithPrefixSuffix(),
					is_virtual: this.controller.appointmentType.is_virtual,
				});

				appointment.startDateTime = this.controller.selectedSlot.startDateTime;

				return appointment;
			}
		}

		get params()
		{
			const {dependentId, clinicId, providerOrGroupId, appointmentTypeId, appointmentDate, appointmentId} = this;
			return {dependentId, clinicId, providerOrGroupId, appointmentTypeId, appointmentDate, appointmentId};
		}

		get isDependentMode()
		{
			return !isBlank(this.dependentId);
		}
	}
