import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14c7c520"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notes" }
const _hoisted_2 = { class: "mha text body" }
const _hoisted_3 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, { contentWidthMid: "" }, {
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, {
        "header-text": this.$mhat('BookingDisclaimer.HeaderText'),
        "has-close": $props.hasClose
      }, null, 8, ["header-text", "has-close"])
    ]),
    "content-top": _withCtx(() => [
      ($options.isDependentMode)
        ? (_openBlock(), _createBlock(_component_DependentSubheader, {
            key: 0,
            dependent: $options.controller.dependent
          }, null, 8, ["dependent"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString($options.bookingNotes), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouteButton, {
          class: "understand-button",
          primary: "",
          route: $options.nextRoute
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(this.$mhat("BookingDisclaimer.UnderstandButtonText")), 1)
          ]),
          _: 1
        }, 8, ["route"])
      ])
    ]),
    _: 1
  }))
}