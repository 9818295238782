import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c74182e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-container" }
const _hoisted_2 = { class: "banner-icon" }
const _hoisted_3 = { class: "banner-text" }
const _hoisted_4 = {
  key: 0,
  class: "buttons-container"
}
const _hoisted_5 = { class: "button-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["banner-notification-item", {primary: _ctx.primary, warning: _ctx.warning, danger: _ctx.danger, whitelabel: _ctx.whitelabel}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["notification-header", {'has-buttons': _ctx.hasButtons}])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.iconClass)
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
        ])
      ], 2)
    ]),
    (_ctx.hasButtons)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "button-slot", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}