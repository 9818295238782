
	import ClinicOperatingInfo from "@/views/patient_user/clinic/components/ClinicOperatingInfo.vue";
	import {Options, Prop} from "vue-property-decorator";
	import {AqsQueueDto} from "@/open_api/generated";
	import {DateUtils} from "@/lib/utils/Date";
	import moment from "moment";
	import {DATE_FORMAT} from "@/constants";
	import modalBaseController from "@/lib/modal/modalBaseController";

	@Options({components: {ClinicOperatingInfo}})
	export default class OnDemandBookingHoursModal extends modalBaseController
	{
		@Prop({type: Object}) queue: AqsQueueDto;

		get operatingHoursList()
		{
			return DateUtils.daysOfWeek().map((day) =>
			{
				const availableDay = this.queue.availability[day.toLocaleLowerCase()];
				if (availableDay)
				{
					return {
						day,
						start: DateUtils.humanTimeString(moment(availableDay.start, [DATE_FORMAT.TIME])),
						end: DateUtils.humanTimeString(moment(availableDay.stop, [DATE_FORMAT.TIME])),
					};
				}
				else
				{
					return {
						day,
					};
				}
			});
		}
	}
