

	import {Options, Vue, Prop} from "vue-property-decorator";
	import DOMUtils from "@/lib/utils/DOMUtils";

	// TODO: Refactor to standalone tooltip component
	@Options({})
	export default class IconTooltip extends Vue
	{
		@Prop({type: String, default: "icon-account"}) readonly icon: string;
		@Prop({type: String}) public readonly iconSize: string;
		// The number of pixels to offset the icon tooltip from the icon itself (gap between icon and tooltip)
		@Prop({type: Number, default: 25}) public readonly tooltipOffsetPx;

		@Prop({type: Array, default: []}) readonly extraClass: string[];

		@Prop(String) readonly tooltip: string;

		@Prop(Boolean) readonly disabled: string;

		@Prop({type: Number, default: 3000}) readonly duration: number;

		tooltipShown = false;

		tooltipTimeout: NodeJS.Timeout;

		mounted()
		{
			this.$nextTick(() =>
			{
				this.presentTooltip();

				this.$emit("mounted", this.toggleTooltip);
			});
		}

		public toggleTooltip(isShown: boolean)
		{
			if (isShown)
			{
				this.presentTooltip(true);
			}
			else
			{
				this.hideTooltip();
			}
		}

		public hideTooltip()
		{
			clearTimeout(this.tooltipTimeout);
			this.tooltipShown = false;
		}

		// Pretty hacky way to get around ion-split-pane nesting. All elements in the chat view are inside an
		// ion-menu. No positioning will allow overlaying the video view.
		public presentTooltip(forcePresent = false)
		{
			if (this.tooltip && (!this.disabled || forcePresent))
			{
				clearTimeout(this.tooltipTimeout);

				this.tooltipShown = true;

				this.tooltipTimeout = setTimeout(() =>
				{
					this.tooltipShown = false;
				}, this.duration);

				const icon = this.$refs.icon as HTMLElement;
				const tooltip = this.$refs.tooltip as HTMLElement;

				const iconPos = DOMUtils.offset(icon);

				document.body.appendChild(tooltip);

				tooltip.style.left = (iconPos.left + this.tooltipOffsetPx) + "px";
				tooltip.style.top = iconPos.top + "px";
			}
		}

		get iconClass(): string[]
		{
			return ["icon-tooltip", this.icon, ...this.extraClass];
		}

		get iconStyles(): any
		{
			const style = {};

			if (this.iconSize)
			{
				style["font-size"] = this.iconSize;
			}

			return style;
		}

		get tooltipClass(): string[]
		{
			return ["tooltip", ...this.extraClass];
		}
	}

