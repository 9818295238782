

	import {Options, Vue} from "vue-property-decorator";
	import CareCard from "@/views/public/whitelabel/components/CareCard.vue";
	import CirclesGraphic from "@/views/public/whitelabel/components/CirclesGraphic.vue";

	import imgWallet from "@/assets/images/graphics/odb/icons/wallet.svg";
	import imgClock from "@/assets/images/graphics/odb/icons/time.svg";
	import imgComputer from "@/assets/images/graphics/odb/icons/monitor.svg";
	import imgAward from "@/assets/images/graphics/odb/icons/award.svg";

	@Options({components: {CirclesGraphic, CareCard}})
	export default class WhitelabelCareCards extends Vue
	{
		public imgWallet = imgWallet;
		public imgClock = imgClock;
		public imgComputer = imgComputer;
		public imgAward = imgAward;

		get titleText()
		{
			return this.$mhat("WhitelabelCareCards.TitleText");
		}

		get messageText()
		{
			return this.$mhat("WhitelabelCareCards.MessageText");
		}
	}
