import AbstractConverter from "@/lib/conversion/AbstractConverter";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import {TelehealthChatMessageDto} from "@/open_api/generated";

export default class ChatMessageToTelehealthChatMessageDtoConverter
	extends AbstractConverter<ChatMessage, TelehealthChatMessageDto>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	public convert(from: ChatMessage): TelehealthChatMessageDto
	{
		return {
			id: from.id,
			message: from.message,
			message_type: from.messageType,
			sender_id: from.senderId,
			sender_type: from.senderType,
		};
	}
}
