import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ec31260"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "horizontal-container"
}
const _hoisted_2 = {
  key: 1,
  class: "corporate corporate-colours d-flex flex-col align-items-center"
}
const _hoisted_3 = { class: "mha-font-body-sm" }
const _hoisted_4 = { class: "horizontal-container m-t-8" }
const _hoisted_5 = {
  key: 0,
  class: "link"
}
const _hoisted_6 = {
  key: 1,
  class: "separator mha-font-body-xs"
}
const _hoisted_7 = {
  key: 2,
  class: "link"
}
const _hoisted_8 = {
  key: 3,
  class: "separator mha-font-body-xs"
}
const _hoisted_9 = {
  key: 4,
  class: "link"
}
const _hoisted_10 = { class: "horizontal-container m-t-24" }
const _hoisted_11 = { class: "mha-font-body-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrgImage = _resolveComponent("OrgImage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["footer", {stick: _ctx.stick, branded: _ctx.branded, 'brand-white-bg': _ctx.brandWhiteBg, 'brand-grey-bg': _ctx.brandGreyBg}])
  }, [
    (_ctx.branded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.orgLogo)
            ? (_openBlock(), _createBlock(_component_OrgImage, {
                key: 0,
                "asset-type": _ctx.ORG_ASSET_TYPE.LOGO,
                "alt-image": _ctx.IMAGE_BRANDED,
                "image-height": "32px"
              }, null, 8, ["asset-type", "alt-image"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("i", {
                  class: _normalizeClass(["logo", _ctx.brandIcon])
                }, null, 2),
                _createElementVNode("span", _hoisted_3, _toDisplayString(this.$mhat("CorporateFooter.PoweredByText", {brandName: _ctx.brandName})), 1)
              ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.showSupportLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSupportModal()))
            }, _toDisplayString(this.$mhat("CorporateFooter.CustomerSupportButtonText")), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showFirstSeparator)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, "|"))
        : _createCommentVNode("", true),
      (_ctx.showTermsLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toTerms && _ctx.toTerms(...args)))
            }, _toDisplayString(this.$mhat("CorporateFooter.TermsButtonText")), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showSecondSeparator)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "|"))
        : _createCommentVNode("", true),
      (_ctx.showPrivacyLink)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("a", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toPrivacyPolicy && _ctx.toPrivacyPolicy(...args)))
            }, _toDisplayString(this.$mhat("CorporateFooter.PrivacyPolicyButtonText")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("span", _hoisted_11, "Copyright © " + _toDisplayString(new Date().getFullYear()) + " " + _toDisplayString(_ctx.text.name), 1)
    ])
  ], 2))
}