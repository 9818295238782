import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23cfe145"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_SelectMenu = _resolveComponent("SelectMenu")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_ContactClinicRouteButton = _resolveComponent("ContactClinicRouteButton")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, {
        "header-text": this.$mhat('BookingCalendar.HeaderText'),
        "has-close": $props.hasClose
      }, null, 8, ["header-text", "has-close"])
    ]),
    "content-top": _withCtx(() => [
      ($options.isDependentMode)
        ? (_openBlock(), _createBlock(_component_DependentSubheader, {
            key: 0,
            dependent: $options.controller.dependent
          }, null, 8, ["dependent"]))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString(this.$mhat("BookingCalendar.SelectDateInstructionText")), 1),
      _createVNode(_component_SelectMenu, {
        "hide-label": "",
        options: Object.values($options.monthOptions),
        selected: $options.monthOptions[$data.currentMonth.format('MMMM YYYY')],
        onSelection: $options.changeMonth
      }, null, 8, ["options", "selected", "onSelection"]),
      ($options.showSpinner)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_EventStateSpinner, {
              "is-loading": $options.isLoading,
              "loading-icon": "icon-calendar-add",
              "loading-title": this.$mhat('BookingCalendar.LoadingTitle'),
              "show-failure-state": $options.noAppointments,
              "failure-icon": "icon-calendar-add",
              "failure-title": this.$mhat('BookingCalendar.LoadingFailureTitle'),
              "failure-message": $options.failureMessage
            }, null, 8, ["is-loading", "loading-title", "show-failure-state", "failure-title", "failure-message"]),
            ($options.noAppointments)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_ContactClinicRouteButton, { clinicId: $props.clinicId }, null, 8, ["clinicId"]),
                  _createVNode(_component_RouteButton, {
                    pattern: $data.ButtonColorPattern.TRANSPARENT,
                    color: $data.ButtonColor.PRIMARY,
                    route: _ctx.Route.Home
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("BookingCalendar.CloseWindow")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "route"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createBlock(_component_Calendar, {
            key: 2,
            onDateSelect: $options.selectDate,
            month: $data.currentMonth,
            "active-dates": $options.availableDates,
            disabled: $options.controller.isLoading
          }, null, 8, ["onDateSelect", "month", "active-dates", "disabled"]))
    ]),
    _: 1
  }))
}