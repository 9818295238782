

	import {Options, Prop, Watch, Vue} from "vue-property-decorator";

	import moment from "moment";
	import {Mail} from "@/lib/Mailbox/Mail.model";
	import {SelectOption} from "@/lib/types/Components";
	import {ComposedMail} from "@/lib/Mailbox/Mailbox.types";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import MailHeader from "./MailHeader.vue";
	import AttachmentSelector from "./AttachmentSelector.vue";
	import {MailAttachment} from "@/open_api/generated";
	import {TabBarItem} from "@/components/Menu/types";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import MessageLayoutWrapper from "@/views/patient_user/mail/components/MessageLayoutWrapper.vue";
	import {modalController, pickerController} from "@ionic/vue";
	import TextArea from "@/components/Inputs/TextArea.vue";
	import {AppointmentModalDismissReason} from "@/views/patient_user/appointment/AppointmentInfo.vue";
	import {AllowedFileTypes} from "@/lib/document/model/AllowedFileTypes";

	@Options({components: {MessageLayoutWrapper, AttachmentSelector, MailHeader, TextArea}})
	export default class MessageCompose extends Vue
	{
		@Prop({type: Array}) clinics: ClinicProfile[];
		@Prop({type: String}) clinicId: string;
		@Prop({type: Object}) mailReply: Mail;
		@Prop({type: Boolean}) replying: boolean;
		@Prop({type: Boolean}) isLoading: boolean;

		@Watch("message")
		watchMessage(newVal)
		{
			this.$emit("update", newVal);
		}

		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		public selectedClinic = null;
		public currentTime = moment();
		public timeInterval = null;
		public attachments: MailAttachment[] = null;

		public message = {
			subject: "",
			body: "",
		};

		created()
		{
			if (this.replying && this.mailReply)
			{
				this.selectedClinic = this.clinics.find((clinic: ClinicProfile) =>
				{
					return clinic.id === this.mailReply.senderId;
				});

				this.message.subject = `${this.mailReply.subject}`;
			}
			else if (this.clinicId)
			{
				this.selectedClinic = this.clinics.find((clinic: ClinicProfile) =>
				{
					return clinic.id === this.clinicId;
				});
			}

			// only auto-select the clinic if there is only one.
			if (!this.selectedClinic && this.clinics.length === 1)
			{
				this.selectedClinic = this.clinics[0];
			}

			this.timeInterval = setInterval(() =>
			{
				this.currentTime = moment();
			}, 1000);
		}

		beforeRouteLeave(to, from, next)
		{
			clearInterval(this.timeInterval);
			next();
		}

		public cancelComposing(): void
		{
			this.$emit("cancel");
		}

		public cancelAndClose(): void
		{
			this.cancelComposing();
			this.dismissModal();
		}

		public cancelOnBack(): void
		{
			if (this.replying)
			{
				this.cancelComposing();
			}
			else
			{
				this.cancelAndClose();
			}
		}

		public dismissModal()
		{
			if (modalController)
			{
				modalController.dismiss();
			}
		}

		public triggerAttachmentSelection()
		{
			const attachmentSelector: AttachmentSelector = this.$refs.attachmentSelector as AttachmentSelector;
			attachmentSelector.triggerFileChooser();
		}

		public onAttachmentsChanged(updatedAttachments: MailAttachment[])
		{
			this.attachments = updatedAttachments;
		}

		public sendMessage()
		{
			if (this.canSendMessage)
			{
				const composedMail: ComposedMail = {
					clinic: this.selectedClinic,
					message: this.message,
					conversationId: this.replying ? this.mailReply.conversationId : null,
					attachments: this.attachments,
				};

				this.$emit("send", composedMail);
			}
		}

		public selectRecipient(option: SelectOption)
		{
			this.selectedClinic = option.value;
		}

		public async presentClinicPicker()
		{
			const clinicOptions = this.clinics.map((clinic: ClinicProfile) =>
			{
				return {
					text: clinic.name,
					value: clinic,
				};
			});
			// set the current selection
			const selectedIndex = clinicOptions.findIndex((option) => (option.value === this.selectedClinic));

			const picker = await pickerController.create({
				animated: true,
				columns: [
					{
						name: "clinicColumn", // used as an accessor when finding value
						options: clinicOptions,
						selectedIndex,
					},
				],
				buttons: [
					{
						text: this.$mhat("MessageCompose.CancelButtonText"),
						role: "cancel",
					},
					{
						text: this.$mhat("MessageCompose.ConfirmButtonText"),
						handler: (value) =>
						{
							this.selectedClinic = value.clinicColumn.value;
						},
					},
				],
			});
			return await picker.present();
		}

		get selectClinicText()
		{
			if (this.selectedClinic)
			{
				return this.selectedClinic.name;
			}
			return this.$mhat("MessageCompose.ClinicPlaceholderText");
		}

		get currentTimeDisplay()
		{
			return this.currentTime.format("LLLL");
		}

		get messageHeaderText()
		{
			if (this.replying)
			{
				return this.currentTimeDisplay;
			}
			return this.$mhat("MessageCompose.MessageHeaderText");
		}

		get canSendMessage()
		{
			return (this.selectedClinic && this.fieldsValid && !this.isLoading);
		}

		get fieldsValid()
		{
			return this.message.body.length > 0 && this.message.subject.length > 0;
		}

		get clinicOptions(): SelectOption[]
		{
			return this.clinics.map((clinic: ClinicProfile) =>
			{
				return {name: clinic.name, value: clinic};
			});
		}

		get headerIcon()
		{
			return this.replying ? "icon-reply" : "icon-chat";
		}

		get headerText()
		{
			return this.replying ? this.$mhat("MessageCompose.ReplyHeaderText") : this.$mhat("MessageCompose.NewMessageHeaderText");
		}

		get clinicSelectColour()
		{
			return (this.selectedClinic) ? ButtonColor.PRIMARY : ButtonColor.GREYSCALE_DARK;
		}

		get allowedAttachmentFileExtensions()
		{
			return AllowedFileTypes.join(",");
		}

		get hasAttachments()
		{
			return this.attachments && this.attachments.length > 0;
		}

		get tabBarItems()
		{
			return [
				{
					icon: "icon-chevron-left",
					label: this.$mhat("MessageCompose.CancelButtonText"),
					click: () => this.cancelOnBack(),
					disabled: this.isLoading,
				} as TabBarItem,
				{
					icon: "icon-attachments",
					label: this.$mhat("MessageCompose.AddAttachmentButtonText"),
					click: () => this.triggerAttachmentSelection(),
					disabled: this.isLoading,
				} as TabBarItem,
				{
					icon: "icon-send",
					label: this.$mhat("MessageCompose.SendButtonText"),
					click: () => this.sendMessage(),
					disabled: !this.canSendMessage,
				} as TabBarItem,
			];
		}

		get headerButtonOpts(): any
		{
			return {
				icon: "icon-close",
				text: this.$mhat("MessageCompose.CloseButtonText"),
				action: () =>
				{
					this.cancelAndClose();
				},
			};
		}
	}
