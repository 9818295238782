import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { style: {"margin-top":"16px"} }
const _hoisted_2 = { class: "confirmation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_AppointmentCard = _resolveComponent("AppointmentCard")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_ctx.appointmentInfo)
    ? (_openBlock(), _createBlock(_component_PageWrapper, { key: 0 }, _createSlots({
        "content-header": _withCtx(() => [
          _createVNode(_component_BookingHeaderBar, {
            "header-text": this.$mhat('BookingConfirm.ConfirmDetails'),
            "has-close": _ctx.hasClose
          }, null, 8, ["header-text", "has-close"])
        ]),
        _: 2
      }, [
        (!_ctx.isLoading)
          ? {
              name: "content-top",
              fn: _withCtx(() => [
                (_ctx.isDependentMode)
                  ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                      key: 0,
                      dependent: _ctx.controller.dependent
                    }, null, 8, ["dependent"]))
                  : _createCommentVNode("", true),
                _createElementVNode("p", null, _toDisplayString(_ctx.$mhat('BookingConfirm.BookingConfirmationDetailsText')), 1),
                _createVNode(_component_AppointmentCard, { appointment: _ctx.appointmentInfo }, null, 8, ["appointment"]),
                _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$mhat('BookingConfirm.BookingConfirmationText')), 1),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_RouteButton, {
                    primary: "",
                    route: _ctx.nextRoute
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$mhat('BookingConfirm.ThatIsCorrectText')), 1)
                    ]),
                    _: 1
                  }, 8, ["route"]),
                  _createVNode(_component_BaseButton, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onStartOver()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$mhat('BookingConfirm.StartOverText')), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            }
          : {
              name: "content-center",
              fn: _withCtx(() => [
                _createVNode(_component_EventStateSpinner, {
                  "is-loading": _ctx.isLoading,
                  "loading-icon": "icon-user-md",
                  "loading-title": this.$mhat('BookingConfirm.LoadingTitle')
                }, null, 8, ["is-loading", "loading-title"])
              ])
            }
      ]), 1024))
    : _createCommentVNode("", true)
}