import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createBlock(_component_BaseInput, _mergeProps(_ctx.$attrs, {
    value: _ctx.value,
    id: _ctx.id,
    allowedKeydownRegex: /[\d\s]/,
    allowedPasteRegex: /^[()-\s\d]+$/,
    "max-length": _ctx.FIELD_MAX_LENGTH,
    onInput: _cache[0] || (_cache[0] = (payload) => _ctx.onPhoneChange(payload))
  }), null, 16, ["value", "id", "allowedPasteRegex", "max-length"]))
}