import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {ProviderGroupTransfer} from "@/open_api/generated";
import ProviderGroup from "@/lib/clinic/providerGroup.model";

export default class ProviderGroupTransferToProviderGroupConverter extends AbstractConverter<ProviderGroupTransfer, ProviderGroup>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: ProviderGroupTransfer): ProviderGroup
	{
		if (!from)
		{
			return null;
		}

		return new ProviderGroup(from.id, from.group_name);
	}
}
