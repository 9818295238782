import {BrowserInfo, detect} from "detect-browser";
import {mobileCutoffWidth} from "@/constants";
import {HostType} from "@/router/types";
import {Capacitor} from "@capacitor/core";
import semver from "semver";

export default {

	// The current production version of the iOS app.
	IOS_CURRENT_VERSION: "1.5.3",
	// the current production version of the android app.
	ANDROID_CURRENT_VERSION: "1.2.5",

	getDeviceOS()
	{
		const userAgent = navigator.userAgent || navigator.vendor;

		if (/android/i.test(userAgent))
		{
			return "android";
		}

		if (/iPad|iPhone|iPod/.test(userAgent) ||
			(/Macintosh/.test(userAgent) && navigator.maxTouchPoints > 0)) // https://developer.apple.com/forums/thread/119186
		{
			return "ios";
		}

		return "unknown";
	},

	isIOS()
	{
		// @ts-ignore
		const hasNativeApi = (Boolean(window.webkit?.messageHandlers?.jsHandler) || Capacitor.isPluginAvailable("NativeBridgePlugin"));
		return this.getDeviceOS() === "ios" && hasNativeApi;
	},

	async nativeAppVersion(): Promise<string>
	{
		if (this.isIOS() || this.isAndroid())
		{
			// dynamic import of native bridge to resolve circular dependency between DeviceInfo and NativeBridge
			const nativeBridgeImport = await import("@/lib/NativeBridge");
			return await nativeBridgeImport.NativeBridge.getAppVersion();
		}
		else
		{
			throw new Error("Local device is not a phone app");
		}
	},

	async isNativeAppUpToDate(): Promise<boolean>
	{
		if (this.isIOS())
		{
			const version = await this.nativeAppVersion();
			if (version)
			{
				return semver.gte(version, this.IOS_CURRENT_VERSION);
			}
			else
			{
				// native iOS apps before version 1.5.2 will not supply version information assume out of date.
				return false;
			}
		}
		else if (this.isAndroid())
		{
			const version = await this.nativeAppVersion();
			if (version)
			{
				return semver.gte(version, this.ANDROID_CURRENT_VERSION);
			}
			else
			{
				// old android devices don't have version check code. because of the ordering of releases
				// web, then android we must consider them up to date,
				// else users would be notified of an update they cannot download.
				return true;
			}
		}
		else
		{
			// no native app. return uptodate.
			return true;
		}
	},

	isNativeApp(): boolean
	{
		return this.isAndroid() || this.isIOS();
	},

	isChrome()
	{
		return this.getBrowser().name === "chrome";
	},

	isAndroid()
	{
		// @ts-ignore
		return Boolean(window.androidBridge);
	},

	webRTCSupport()
	{
		return navigator.mediaDevices;
	},

	async hasCamera(): Promise<boolean>
	{
		const cameras = await this.getCameras();
		return cameras?.length > 0;
	},

	async getCameras()
	{
		const mediaDevices = await navigator.mediaDevices.enumerateDevices();
		return mediaDevices.filter((device) => device.kind === "videoinput");
	},

	async hasMic(): Promise<boolean>
	{
		const mediaDevices = await navigator.mediaDevices.enumerateDevices();
		return mediaDevices.some((device) => device.kind === "audioinput");
	},

	getBrowser(): BrowserInfo
	{
		return detect() as BrowserInfo;
	},

	getUserLocation(): Promise<any>
	{
		if (navigator.geolocation)
		{
			return new Promise((resolve, reject) =>
			{
				navigator.geolocation.getCurrentPosition(
					(position: any) =>
					{
						resolve(position);
					},
					(err) =>
					{
						reject(err);
					},
					{enableHighAccuracy: true});
			});
		}
	},

	isMobile(): boolean
	{
		return screen.width <= mobileCutoffWidth || window.innerHeight > window.innerWidth;
	},

	isPortraitMode(): boolean
	{
		return window.innerHeight > window.innerWidth;
	},

	isCloudMd: window.location.hostname.includes(HostType.CLOUDMD),

	isProduction(): boolean
	{
		const hostname = window.location.hostname;
		return !(
			hostname.includes("mhadev.ca") ||
				hostname.includes("cloudmd1.myhealthaccess.ca") ||
				hostname.includes("staging.myhealthaccess.ca") ||
				hostname.includes("robert.myhealthaccess.ca") ||
				hostname.includes("levi.myhealthaccess.ca") ||
				hostname.includes("ben.myhealthaccess.ca")
		);
	},

	appVersion: process.env.VUE_APP_VERSION,

	getHostUri(): string
	{
		return this.isCloudMd ? "cloud_md" : "my_health_access";
	},

	// TODO where should this live?
	getAppFontIcon()
	{
		return this.isCloudMd ? "icon-cmd" : "icon-mha";
	},
};
