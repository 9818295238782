

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class ScrollContainer extends Vue
	{
		@Prop({default: false, type: Boolean}) scrollX: boolean;
		@Prop({default: false, type: Boolean}) scrollY: boolean;

		get scrollClass()
		{
			const {scrollX, scrollY} = this;
			const classList = [];

			if (scrollX)
			{
				classList.push("overflow-x");
			}
			if (scrollY)
			{
				classList.push("overflow-y");
			}
			return classList.join(" ");
		}
	}
