import {Moment} from "moment";
import OT, {
	Stream,
	Connection,
	Publisher as OTPublisher,
	Subscriber as OTSubscriber,
} from "@opentok/client";

export enum TeleViewType
{
	CHAT = 0,
	VIDEO = 1,
}

export enum TeleCallState
{
	PENDING = 0,
	CALLING = 1,
	IN_CALL = 2,
	CALL_ENDED = 3,
}

export enum SignalType
{
	OT_PREFIX = "signal:",
	CALL_INCOMING = "call-CALLING",
	CALL_ACCEPTED = "call-ACCEPTED",
	CALL_ENDED = "call-ENDED",
	CHAT_MESSAGE = "chat-MESSAGE",
	CHAT_TYPING = "chat-TYPING",
}

export interface Signal
{
	type?: string;
	data?: string;
	to?: Connection;
}

export enum MessageOrigin
{
	Received = "RECEIVED",
	Sent = "SENT",
}

export enum ChatItemType
{
	MESSAGE, TYPING, EVENT, DOCUMENT
}

export interface ChatItem
{
	type: ChatItemType;
	message?: string; // for chat messages
	url?: string; // for file messages
	receivedAt?: Moment;
	messageOrigin?: MessageOrigin;
}

export enum EventName
{
	SESSION_CONNECTED = "sessionConnected",
	SESSION_DISCONNECTED = "sessionDisconnected",
	SESSION_RECONNECTING = "sessionReconnecting",
	STREAM_CREATED = "streamCreated",
	STREAM_DESTROYED = "streamDestroyed",
	SIGNAL = "signal",
	CONNECTION_DESTROYED = "connectionDestroyed",
	CONNECTION_CREATED = "connectionCreated",
}

export enum StreamName
{
	Provider = "providerStream",
	Patient = "patientStream",
}

export interface Publisher
{
	stream?: Stream;
	publisher?: OTPublisher;
}

export interface Subscriber
{
	stream?: Stream;
	subscriber?: OTSubscriber;
}

export interface ConnectionEvent
{
	connection: Connection;
}

export type ConnectionCreatedEvent = OT.Event<EventName.CONNECTION_CREATED, OT.Session> & ConnectionEvent;

export interface StreamEvent
{
	stream: Stream;
}

export interface SignalEvent
{
	type?: string;
	data?: string;
	from: Connection;
}

export const typingMessage = {
	type: ChatItemType.TYPING,
	origin: MessageOrigin.Received,
	message: "",
};

// all events have a type
// e: CustomEvent e.details
export enum IOSCallEvent {
	IncomingCall = "IncomingCall",
	ClientAccept = "ClientAccept",
	ClientReject = "ClientReject",
	CallEnd = "CallEnd", // from: local/remote
	SessionDidDisconnect = "SessionDidDisconnect",
	SessionDidBeginReconnecting = "SessionDidBeginReconnecting",
	SessionDidReconnect = "SessionDidReconnect",
	ChatMessage = "ChatMessage",
	ChatTyping = "ChatTyping",
	LogEvent = "LogEvent",
}
export enum EventLocation {
	LOCAL = "local",
	REMOTE = "remote",
}
