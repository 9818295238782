

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {DateUtils} from "@/lib/utils/Date";

	@Options({})
	export default class ClinicOperatingInfo extends Vue
	{
		@Prop({type: Array}) operatingHoursList: Array<{ day: string, start: string, end: string}>;
		@Prop({type: Boolean, default: true}) showHeader: boolean;

		public getDayOfWeekTranslation(day)
		{
			const dayOfWeekTranslations =
				{
					sunday: this.$mhat("ClinicOperatingInfo.Sunday"),
					monday: this.$mhat("ClinicOperatingInfo.Monday"),
					tuesday: this.$mhat("ClinicOperatingInfo.Tuesday"),
					wednesday: this.$mhat("ClinicOperatingInfo.Wednesday"),
					thursday: this.$mhat("ClinicOperatingInfo.Thursday"),
					friday: this.$mhat("ClinicOperatingInfo.Friday"),
					saturday: this.$mhat("ClinicOperatingInfo.Saturday"),
				};

			return dayOfWeekTranslations[day];
		}

		get operatingHours()
		{
			if (this.operatingHoursList)
			{
				return this.operatingHoursList;
			}

			return DateUtils.daysOfWeek().map((day) =>
			{
				return {day};
			});
		}
	}
