export function getCookies(targetDocument)
{
	return targetDocument.cookie.split(";").reduce((cookieObject, cookieString) =>
	{
		const splitCookie = cookieString.split("=");
		try
		{
			cookieObject[splitCookie[0].trim()] = decodeURIComponent(splitCookie[1]);
		}
		catch (error)
		{
			cookieObject[splitCookie[0].trim()] = splitCookie[1];
		}
		return cookieObject;
	}, []);
}

export function deleteCookie(name: string)
{
	document.cookie = `${name}=; domain=.${location.hostname}; Max-Age=0; path=/`;
}
