
	import PrivacyPolicyText from "@/components/Text/PrivacyPolicyText.vue";
	import CloudMDPrivacyPolicyText from "@/components/Text/CloudMDPrivacyPolicyText.vue";
	import HeaderBarPrivacy from "@/views/patient_user/profile/components/PrivacyHeaderBar.vue";
	import DeviceInfo from "@/lib/DeviceInfo";

	export default {
		name: "PrivacyPolicyPage",
		components: {CloudMDPrivacyPolicyText, HeaderBarPrivacy, PrivacyPolicyText},
		methods: {
			isCloudMD: () =>
			{
				return DeviceInfo.isCloudMd;
			},
		},
	};
