import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppChipBase = _resolveComponent("AppChipBase")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-chip", _ctx.color])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["app-chip-pattern-layer", _ctx.fillPatternClass])
    }, [
      _createVNode(_component_AppChipBase, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      })
    ], 2)
  ], 2))
}