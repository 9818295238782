import {Moment} from "moment";
import {Mail} from "@/lib/Mailbox/Mail.model";
import {ArchiveType} from "@/lib/Mailbox/Mailbox.types";

export class Mailbox
{
	private _mail: Mail[] = [];
	private _queryOffset = 0;
	private _allMailReceivedAt: Moment = null;
	private _archiveType: ArchiveType = null;

	constructor(archiveType?: ArchiveType)
	{
		this.archiveType = archiveType;
	}

	public resetMailbox()
	{
		this.mail = [];
		this.queryOffset = 0;
		this.allMailReceivedAt = null;
	}

	get mail(): Mail[]
	{
		return this._mail;
	}

	set mail(value: Mail[])
	{
		this._mail = value;
	}

	get queryOffset(): number
	{
		return this._queryOffset;
	}

	set queryOffset(value: number)
	{
		this._queryOffset = value;
	}

	get allMailReceivedAt(): Moment
	{
		return this._allMailReceivedAt;
	}

	set allMailReceivedAt(value: Moment)
	{
		this._allMailReceivedAt = value;
	}

	get archiveType(): ArchiveType
	{
		return this._archiveType;
	}

	set archiveType(value: ArchiveType)
	{
		this._archiveType = value;
	}
}
