import BannerNotification from "@/lib/notification/model/BannerNotification";
import {DependentAPI, PatientAPI} from "@/lib/services/Api";
import moment, {Moment} from "moment";
import ClinicAppointmentTransferToClinicAppointmentConverter
	from "@/lib/appointment/converter/ClinicAppointmentTransferToClinicAppointmentConverter";
import BannerNotificationFactory from "@/lib/notification/factory/BannerNotificationFactory";
import DependentStore from "@/lib/vuex/modules/Dependent.store";
import {Patient} from "@/lib/patient/Patient";

export default class PatientNotificationService
{
	public static readonly NOTIFICATION_LOOKBACK_TIME = [60, "minutes"];
	public static readonly NOTIFICATION_LOOKAHEAD_TIME = [12, "hours"];

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * get all notifications for the currently logged in patient
	 * @return promise that resolves to a list of banner notifications
	 */
	public async getAllPatientNotifications(): Promise<BannerNotification[]>
	{
		const notifications = await this.getVirtualAppointmentNotifications(moment().subtract(...PatientNotificationService.NOTIFICATION_LOOKBACK_TIME),
		                                                                    moment().add(...PatientNotificationService.NOTIFICATION_LOOKAHEAD_TIME));

		return notifications
			.sort((notification1, notification2) => notification1.diff(notification2));
	}

	/**
	 * get upcoming virtual appointment notifications for the logged in patient and their dependents.
	 * @param after - [optional] if provided only consider appointments after the specified time
	 * @param before - [optional]  if provided only consider appointments before the specified time
	 * @return promise that resolves to a BannerNotification[]
	 */
	public async getVirtualAppointmentNotifications(after: Moment, before: Moment): Promise<BannerNotification[]>
	{
		const clinicAppointmentResponses = await Promise.all(
			[
				PatientAPI().patientMhaClinicAppointments(after.toISOString(), before.toISOString()),
				...DependentStore.dependents.map((dependent) =>
					                                 DependentAPI().dependentMhaClinicAppointments(dependent.id, after.toISOString(), before.toISOString())),
			]);

		const clinicAppointments = (new ClinicAppointmentTransferToClinicAppointmentConverter())
			.convertList(clinicAppointmentResponses.map((resp) => resp.data).flat());

		const virtualClinicAppointments = clinicAppointments.filter((appt) => appt.isVirtual && !appt.isCancelled);
		return virtualClinicAppointments.map((appt) => BannerNotificationFactory.notificationForAppointment(appt));
	}
}
