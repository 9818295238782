import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-782513f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clinic-logo-container" }
const _hoisted_2 = { class: "button-container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "button-wrapper" }
const _hoisted_5 = { class: "button-wrapper" }
const _hoisted_6 = { class: "content-footer-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicViewHeader = _resolveComponent("ClinicViewHeader")!
  const _component_ClinicLogo = _resolveComponent("ClinicLogo")!
  const _component_ClinicInfoContainer = _resolveComponent("ClinicInfoContainer")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_ClinicContactInfo = _resolveComponent("ClinicContactInfo")!
  const _component_ClinicAboutInfo = _resolveComponent("ClinicAboutInfo")!
  const _component_ScrollContainer = _resolveComponent("ScrollContainer")!
  const _component_ClinicProviderInfo = _resolveComponent("ClinicProviderInfo")!
  const _component_ClinicOperatingInfo = _resolveComponent("ClinicOperatingInfo")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, {
    contentWidthMid: "",
    contentHeaderPaddingDisabled: ""
  }, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_ClinicViewHeader, { "padding-horizontal": "" }),
      _createElementVNode("div", {
        class: "clinic-header",
        style: _normalizeStyle(_ctx.clinicHeaderStyles)
      }, null, 4)
    ]),
    "content-footer": _withCtx(() => [
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "content-footer", {}, undefined, true)
      ])
    ]),
    "fixed-footer": _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]),
    _: 2
  }, [
    (_ctx.clinic)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ClinicInfoContainer, { contentAlignLeft: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ClinicLogo, { clinic: _ctx.clinic }, null, 8, ["clinic"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              (_ctx.patientUser.isDependent)
                ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                    key: 0,
                    class: "dependent-subheader",
                    dependent: _ctx.patientUser,
                    "text-prefix": "Viewing As, "
                  }, null, 8, ["dependent"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_ClinicInfoContainer, null, {
              default: _withCtx(() => [
                _createVNode(_component_ClinicContactInfo, { clinic: _ctx.clinic }, null, 8, ["clinic"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "button-primary", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "button-secondary", {}, undefined, true)
                ])
              ])
            ]),
            _createVNode(_component_ScrollContainer, { scrollX: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_ClinicInfoContainer, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ClinicAboutInfo, { clinic: _ctx.clinic }, null, 8, ["clinic"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ScrollContainer, { scrollX: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_ClinicInfoContainer, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ClinicProviderInfo, { clinic: _ctx.clinic }, null, 8, ["clinic"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ClinicInfoContainer, {
              constrainWidth: !_ctx.isMobile
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ClinicOperatingInfo, {
                  "operating-hours-list": _ctx.clinic.operatingHoursArr
                }, null, 8, ["operating-hours-list"])
              ]),
              _: 1
            }, 8, ["constrainWidth"])
          ])
        }
      : undefined
  ]), 1024))
}