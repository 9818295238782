

	import {Appointment} from "@/lib/models/Appointment";
	import BaseSelectionCard from "@/components/Buttons/BaseSelectionCard.vue";
	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({
		emits: ["click", "delete"],
		components: {BaseSelectionCard},
	})
	export default class AppointmentCard extends Vue
	{
		@Prop({type: Object}) public readonly appointment: Appointment;
		@Prop({type: Boolean}) public readonly previous: boolean;

		public handleClick(): void
		{
			this.$emit("click", this.appointment);
		}

		get borderDate()
		{
			return {
				top: this.appointment.startDateTime.format("MMM"),
				bottom: this.appointment.startDateTime.date(),
			};
		}
	}
