import {getRouteOrWhitelabelRoute} from "@/router/whitelableUtil";
import {BookingRoute, DependentBookingRoute} from "@/router/patient_user/booking.routes";
import BookingRouteCollection from "@/router/patient_user/BookingRouteCollection";

export default class DependentBookingRouteCollection
{
	// ==========================================================================
	// Singleton impl
	// ==========================================================================

	private static instance: DependentBookingRouteCollection;

	public static getInstance(): DependentBookingRouteCollection
	{
		if (!this.instance)
		{
			this.instance = new DependentBookingRouteCollection();
		}
		return this.instance;
	}

	private constructor()
	{
	}

	// ==========================================================================
	// Routes
	// ==========================================================================

	get Home(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.Home);
	}

	get Clinic(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.Clinic);
	}

	get ProviderGroup(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.ProviderGroup);
	}

	get Provider(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.Provider);
	}

	get ProviderGroupProvider(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.ProviderGroupProvider);
	}

	get AppointmentType(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.AppointmentType);
	}

	get Notes(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.Notes);
	}

	get NextAvailability(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.NextAvailability);
	}

	get Calendar(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.Calendar);
	}

	get AppointmentTime(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.AppointmentTime);
	}

	get Reason(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.Reason);
	}

	get Confirm(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.Confirm);
	}

	get BookAppointment(): string
	{
		return getRouteOrWhitelabelRoute(DependentBookingRoute.BookAppointment);
	}
}

export const dependentBookingRouteCollection = DependentBookingRouteCollection.getInstance();
