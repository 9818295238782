import {HostType, RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";

const ClinicsMobile = () => import(/* webpackChunkName: "Clinics" */ "@/views/patient_user/clinic/ClinicsMobile.vue");
const ClinicsFind = () => import(/* webpackChunkName: "Clinics" */ "@/views/patient_user/clinic/ClinicsFind.vue");

export enum ClinicsRoutes
{
	Home = "ClinicHome",
	FindClinic = "ClinicFindClinic",
	BrandedRedirect = "ClinicBrandedRedirect1",
}

export const clinicsRoute: RouteRecordRaw[] = [
	{
		path: "/patient/clinics/:clinicId?",
		alias: "/clinics/:clinicId?/view",
		name: ClinicsRoutes.Home,
		component: ClinicsMobile,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			restrictHosts: [HostType.CLOUDMD],
			brandedRoute: true,
		},
	},
	{
		path: "/clinics/find/:clinicId?",
		name: ClinicsRoutes.FindClinic,
		component: ClinicsFind,
		props: true,
		meta: {
			restrictHosts: [HostType.CLOUDMD],
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		// Redirect the old Vue branded booking page to the new Rails branded booking page.
		path: "/branded/clinic/name/:clinicName",
		alias: "/branded/clinic/:clinicName",
		name: ClinicsRoutes.BrandedRedirect,
		redirect: (to): string =>
		{
			window.location.href = "/branded/" + to.params.clinicName;
			return "/";
		},
	},
];
