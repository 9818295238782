import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "microphone-status-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconTooltip = _resolveComponent("IconTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconTooltip, {
      icon: _ctx.micIconClass,
      "icon-size": "24px",
      "extra-class": _ctx.micStatusClass,
      tooltip: _ctx.micTooltip,
      disabled: !this.microphoneStatus,
      duration: 3000
    }, null, 8, ["icon", "extra-class", "tooltip", "disabled"])
  ]))
}