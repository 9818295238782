export enum ClinicSearchFilter
{
	Clinic = "clinicName",
	Provider = "providerName",
}

export interface FilterDropdownOpts
{
	name: string;
	id: string;
	icon?: string;
	placeholder?: string;
}

export enum LabelPosition
{
	TOP = "label-top",
	RIGHT = "label-right",
}
