
	import {LOGO_BRAND} from "@/assets/AppIcons";

	export default {
		name: "HeaderTitle",

		props: {
			icon: {
				type: String,
				default: LOGO_BRAND,
			},
			title: String,
		},
	};
