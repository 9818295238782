import $router, {Route} from "@/router/router";
import {$mhat} from "@/i18n";

export interface ActionSheetButton {
	text?: string;
	role?: "cancel" | "destructive" | "selected" | string;
	icon?: string;
	cssClass?: string | string[];
	handler?: () => boolean | void | Promise<boolean>;
}

export const HomeActionSheetButtons = function(): ActionSheetButton[]
{
	return [
		{
			text: $mhat("HomeActionSheetButtons.AccountButtonText"),
			cssClass: "separator",
			handler: () =>
			{
				this.$mhaRouterPush(Route.Settings.Profile);
			},
		},
		{
			text: $mhat("HomeActionSheetButtons.DependentsButtonText"),
			cssClass: "separator",
			handler: () =>
			{
				this.$mhaRouterPush(Route.Dependents.Home);
			},
		},
		{
			text: $mhat("HomeActionSheetButtons.SecurityButtonText"),
			cssClass: "separator",
			handler: () =>
			{
				this.$mhaRouterPush(Route.Settings.Security);
			},
		},
		{
			text: $mhat("HomeActionSheetButtons.PrivacyButtonText"),
			cssClass: "separator",
			handler: () =>
			{
				this.$mhaRouterPush(Route.Settings.Privacy);
			},
		},
		{
			text: $mhat("HomeActionSheetButtons.VerificationCodeButtonText"),
			cssClass: "separator",
			handler: () =>
			{
				this.$mhaRouterPush(Route.Settings.AccountIdCode);
			},
		},
		{
			text: $mhat("HomeActionSheetButtons.LogOutButtonText"),
			cssClass: "danger",
			handler: () =>
			{
				this.$logout(this.Route.Login.Home);
			},
		},
		{
			text: $mhat("HomeActionSheetButtons.CancelButtonText"),
			cssClass: "primary",
			role: "cancel",
		},
	];
};
