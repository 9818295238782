import {getRouteOrWhitelabelRoute} from "@/router/whitelableUtil";
import {AqsBookingRoutes} from "@/router/patient_user/aqs.booking.routes";

export default class AqsBookingRouteCollection
{
	// ==========================================================================
	// Routes
	// ==========================================================================

	get AppointmentType(): string
	{
		return getRouteOrWhitelabelRoute(AqsBookingRoutes.AppointmentType);
	}

	get OrganizationRedirect(): string
	{
		return getRouteOrWhitelabelRoute(AqsBookingRoutes.OrganizationRedirect);
	}
}
