

	import {Options, Vue, Prop} from "vue-property-decorator";

	import DependentsHeaderBar from "./components/DependentsHeaderBar.vue";
	import BasicList from "@/components/List/BasicList.vue";
	import {SubRegions} from "@/lib/utils/Geodata";
	import {DependentProfileController} from "../profile/controllers/DependentProfileController";
	import {DependentAPI} from "@/lib/services/Api";
	import {Patient} from "@/lib/patient/Patient";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import {MyClinicsController} from "../clinic/MyClinicsController";
	import Services from "@/lib/services/Services";
	import {BasicListType} from "@/lib/types/ListType";
	import ProfileControlButtons from "../profile/components/ProfileControlButtons.vue";
	import ProfileInputFields from "../profile/components/ProfileInputFields.vue";
	import {PatientProfileField} from "@/lib/types/Profile";
	import DeviceInfo from "@/lib/DeviceInfo";
	import {presentIonLoader} from "@/lib/utils/helpers/ionic";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode, ProvinceCode} from "@/open_api/generated";
	import {Route} from "@/router/router";
	import DependentStore from "@/lib/vuex/modules/Dependent.store";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import LoadingQueue from "@/lib/LoadingQueue";
	import DependentService from "@/lib/dependents/Dependent.service";
	import AuthStore from "@/lib/vuex/auth.store";
	import {alertController} from "@ionic/vue";

	@Options({components: {ProfileInputFields, ProfileControlButtons, DependentsHeaderBar, BasicList}})
	export default class DependentsProfile extends Vue
	{
		@Prop({type: String}) dependentId: string;

		public profileController: DependentProfileController = null;
		public clinicsController: MyClinicsController = new MyClinicsController();
		public connectedClinics: ClinicProfile[] = [];
		public isCloudMd: boolean = DeviceInfo.isCloudMd;
		public loadingQueue: LoadingQueue = new LoadingQueue();
		public requiredProfileFields: PatientProfileField[] = null;

		public SubRegions = SubRegions;

		protected readonly NEWBORN_CUTOFF_MONTHS = 3;

		public async created()
		{
			// if there is a profile id, the profile controller must be constructed with the profile
			if (this.dependentId)
			{
				this.loadingQueue.pushLoadingState();

				// fetch the dependent user profile
				DependentService.getDependent(this.dependentId).then(
					(dependentProfile: Patient) =>
					{
						// initialize a profile controller with returned patient data
						this.profileController = new DependentProfileController(dependentProfile);
					},
					(error) =>
					{
						WebNotification.$emit({
							event: NotifyEvent.Generic,
							type: NotificationType.Swipe,
							severity: NotificationSeverity.Critical,
							title: this.$mhat("DependentsProfile.GenericErrorTitle"),
							message: this.$mhat("DependentsProfile.LoadingErrorMessage"),
						});
					},
				).finally(() =>
				{
					this.loadingQueue.popLoadingState();
				});

				this.loadingQueue.pushLoadingState();
				this.connectedClinics = await Services.DependentClinics(this.dependentId).connectedClinics().finally(
					() =>
					{
						this.loadingQueue.popLoadingState();
					});
			}
			else
			{
				// initialize a profile controller with empty patient data
				this.profileController = new DependentProfileController(null);
			}

			// since cloudMD immediately connects new dependents to a cloudMD clinic,
			// make sure all the cloudMD required fields are required during creation.
			if (this.isCloudMd)
			{
				this.loadingQueue.pushLoadingState();
				await this.loadClinicProfileRequirements(ProvinceCode.BC);
				this.loadingQueue.popLoadingState();
			}
		}

		public onProfileChange(input)
		{
			if (input.id === PatientProfileField.HEALTHCARE_PROVINCE_CODE)
			{
				this.loadClinicProfileRequirements(input.value);
			}
		}

		public async loadClinicProfileRequirements(provinceCode)
		{
			// use the public api since dependent may not exist yet
			await Services.PublicClinics.getCloudMDClinic(provinceCode, true).then(
				(clinicProfile) =>
				{
					this.requiredProfileFields = clinicProfile.requiredFields;
				});
		}

		public allFieldsValid()
		{
			return this.profileController.isRequiredFieldsValid(this.userProfileIdList) &&
				this.profileController.isFieldsValid();
		}

		/**
		 * Present an alert to the user informing them that this dependent looks like a newborn & giving them the option
		 * to mark the dependent as such.
		 */
		public async presentPossibleNewbornAlert(): Promise<void>
		{
			const alert: HTMLIonAlertElement = await alertController.create({
				message: this.$mhat("DependentsProfile.NewbornDependentAlertMessage", {numberOfMonths: this.NEWBORN_CUTOFF_MONTHS}),
				backdropDismiss: false,
				buttons: [
					{
						text: this.$mhat("DependentsProfile.No"),
						handler: () =>
						{
							this.profileController.userProfile.newborn = true;
							this.profileController.userProfile.healthNumber = AuthStore.loggedInPatient.profile.healthNumber;
							this.profileController.userProfile.healthCareProvinceCode =
								AuthStore.loggedInPatient.profile.healthCareProvinceCode;
						},
					},
					{
						text: this.$mhat("DependentsProfile.Yes"),
					},
				],
			});

			alert.present();
			await alert.onDidDismiss();
		}

		public cancel()
		{
			this.$router.go(-1);
		}

		public async save()
		{
			if (this.looksLikeNewborn)
			{
				await this.presentPossibleNewbornAlert();
			}

			this.loadingQueue.pushLoadingState();
			try
			{
				const response = await this.profileController.onSave();
				this.loadingQueue.popLoadingState();
				return response;
			}
			catch (err)
			{
				const error: ErrorResponse = err;
				this.loadingQueue.popLoadingState();
				if (!error.is || !error.is(ErrorCode.IncompleteProfile))
				{
					WebNotification.$emit({
						event: NotifyEvent.Generic,
						type: NotificationType.Swipe,
						severity: NotificationSeverity.Critical,
						title: this.$mhat("DependentsProfile.GenericErrorTitle"),
						message: this.$mhat("DependentsProfile.SavingErrorMessage"),
					});
				}
			}
		}

		public async onSave()
		{
			this.loadingQueue.pushLoadingState();
			const response = await this.save();
			if (response && response.data.id)
			{
				await DependentStore.fetchDependents();
			}
			this.loadingQueue.popLoadingState();
		}

		public async saveAndContinue()
		{
			this.loadingQueue.pushLoadingState();
			const response = await this.save();
			if (response && response.data.id)
			{
				await DependentStore.fetchDependents();
				this.onDependentSaved(response.data.id);
			}
			this.loadingQueue.popLoadingState();
		}

		public onDependentSaved(dependentId)
		{
			if (DeviceInfo.isCloudMd)
			{
				this.$mhaRouterPush(Route.Home);
			}
			else
			{
				this.$mhaRouterReplace(Route.DependentClinics.FindParentClinics, {params: { dependentId } });
			}
		}

		public addNewRoute()
		{
			this.$mhaRouterPush(Route.DependentClinics.FindParentClinics, {params: { dependentId: this.dependentId } });
		}

		public deleteDependent()
		{
			return alertController
				.create({
					header: this.$mhat("DependentsProfile.DeleteDependentAlertHeaderText"),
					message: this.$mhat("DependentsProfile.DeleteDependentAlertMessageText"),
					buttons: [this.$mhat("DependentsProfile.Cancel"),
						{
							text: this.$mhat("DependentsProfile.Delete"),
							handler: async () =>
							{
								const canCancel = await DependentAPI().dependentFutureAppointmentsCancellable(this.dependentId);
								if (!canCancel.data.can_cancel_all)
								{
									this.displayNoCancelConfirm();
								}
								else
								{
									await this._deleteDependent();
								}
							},
						}],
				})
				.then((a) => a.present());
		}

		public async _deleteDependent()
		{
			try
			{
				await presentIonLoader.call(this, async () =>
				{
					await DependentAPI().deleteDependent(this.dependentId);
					this.$mhaRouterPush(Route.Dependents.Home);
				});
			}
			catch (e)
			{
				WebNotification.$emit({
					event: NotifyEvent.Generic,
					type: NotificationType.Swipe,
					severity: NotificationSeverity.Critical,
					title: this.$mhat("DependentsProfile.GenericErrorTitle"),
					message: this.$mhat("DependentsProfile.DeleteDependentErrorMessage"),
				});
			}
		}

		public displayNoCancelConfirm()
		{
			return alertController
				.create({
					header: this.$mhat("DependentsProfile.UnCancellableAppointmentsAlertHeader"),
					message: this.$mhat("DependentsProfile.UnCancellableAppointmentsAlertMessage"),
					buttons: [this.$mhat("DependentsProfile.Cancel"),
						{
							text: this.$mhat("DependentsProfile.Continue"),
							handler: async () =>
							{
								await this._deleteDependent();
							},
						}],
				})
				.then((a) => a.present());
		}

		public deleteClinicConnection(clinic)
		{
			return alertController
				.create({
					header: this.$mhat("DependentsProfile.DeleteClinicConnectionAlertHeader"),
					message: this.$mhat("DependentsProfile.DeleteClinicConnectionAlertMessage"),
					buttons: [this.$mhat("DependentsProfile.Disagree"),
						{
							text: this.$mhat("DependentsProfile.Agree"),
							handler: () =>
							{
								DependentAPI().unlinkDependentWithClinic(this.dependentId, clinic.id).then(
									(response) =>
									{
										// remove deleted clinic from list
										this.connectedClinics = this.connectedClinics.filter((clin) => clin.id !== response.data.clinic.id);
									},
									(error) =>
									{
										WebNotification.$emit({
											event: NotifyEvent.Generic,
											type: NotificationType.Swipe,
											severity: NotificationSeverity.Warning,
											title: this.$mhat("DependentsProfile.GenericErrorTitle"),
											message: this.$mhat("DependentsProfile.DeleteClinicErrorMessage"),
										});
									},
								);
							},
						}],
				})
				.then((a) => a.present());
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get getHeaderText()
		{
			return (this.profileController.isEditMode()
				? this.$mhat("DependentsProfile.EditDependentHeaderText")
				: this.$mhat("DependentsProfile.AddDependentHeaderText"));
		}

		get clinicList(): BasicListType[]
		{
			return this.connectedClinics.map((clinic) =>
			{
				return {
					id: clinic.id,
					label: clinic.name,
				};
			});
		}

		get userProfileIdList(): PatientProfileField[]
		{
			if (this.requiredProfileFields)
			{
				return this.requiredProfileFields;
			}
			else
			{
				// minimum profile requirements for unconfirmed connections
				return [
					PatientProfileField.FIRST_NAME,
					PatientProfileField.LAST_NAME,
					PatientProfileField.HEALTH_NUMBER,
					PatientProfileField.BIRTH_DATE,
					PatientProfileField.SEX,
				];
			}
		}

		/**
		 * @return true if dependent is not marked as newborn but is possibly a newborn.
		 */
		get looksLikeNewborn(): boolean
		{
			return !this.profileController.userProfile.isNewborn &&
				AuthStore.loggedInPatient.profile.healthCareProvinceCode === ProvinceCode.BC &&
				this.profileController.userProfile.age.asMonths() < this.NEWBORN_CUTOFF_MONTHS;
		}
	}
