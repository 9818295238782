

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {MediaDeviceStatus} from "@/lib/mediaDevices";
	import IconTooltip from "@/components/IconTooltip.vue";

	@Options({
		components: {IconTooltip},
	})
	export default class VideoStatusIcon extends Vue
	{
		@Prop({type: String, default: MediaDeviceStatus.UNKNOWN}) public videoStatus;

		// ==========================================================================
		// Getters
		// ==========================================================================

		get cameraIconClass(): string[]
		{
			const classes = ["mha-font-body-xl"];

			if (this.videoStatus)
			{
				classes.push(this.hasCamAccess ? "font-color-line-base" : "font-color-red-base");
			}

			return classes;
		}

		get cameraTooltip(): string
		{
			if (this.videoStatus)
			{
				return this.hasCamAccess ? "Camera detected" : "No authorized cameras found";
			}
		}

		get hasCamAccess(): boolean
		{
			return this.videoStatus === MediaDeviceStatus.GRANTED;
		}
	}
