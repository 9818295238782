
import {Options, Vue} from "vue-property-decorator";
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import Services from "@/lib/services/Services";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import {ClinicBookingStatus, ClinicConnectionStatus, ErrorCode} from "@/open_api/generated";
import {Route} from "@/router/router";
import PatientProfileModal from "@/views/patient_user/clinic/components/PatientProfileModal.vue";
import LoadingQueue from "@/lib/LoadingQueue";
import {modalController} from "@ionic/vue";

@Options({})
export default class ClinicConnectionRedirectBaseController extends Vue
{
	public clinic: ClinicProfile = null;
	public hasError = false;
	public errorString = "An unknown error has occurred";
	public loadingQueue: LoadingQueue = new LoadingQueue();

	/* should be called by sub-classes on creation */
	protected initialize(clinicId: string)
	{
		this.loadingQueue.pushLoadingState();
		Services.PatientClinics.getClinic(clinicId).then(
			(clinic) =>
			{
				this.clinic = clinic;
				this.attemptRedirect();
			}).catch(
			(error: ErrorResponse) =>
			{
				console.error(error);
				this.showErrorState();
			}).finally(
			() =>
			{
				this.loadingQueue.popLoadingState();
			});
	}

	protected attemptRedirect()
	{
		const {clinic} = this;
		switch (clinic.bookingStatus)
		{
			case ClinicBookingStatus.Bookable:
			{
				this.redirectConnected();
				break;
			}
			case ClinicBookingStatus.NotConnected:
			{
				if (clinic.allowsUnconfirmedBooking)
				{
					this.redirectAutoConnect();
					break;
				}
				else
				{
					this.redirectNoConnection();
					break;
				}
			}
			default:
			{
				this.redirectNoConnection();
				break;
			}
		}
	}

	protected redirectConnected()
	{
	}

	protected redirectNoConnection()
	{
		const {clinic} = this;

		const goToFindClinic = (clinic.connectionStatus === ClinicConnectionStatus.NoLink ||
			clinic.connectionStatus === ClinicConnectionStatus.PatientRejected ||
			clinic.connectionStatus === ClinicConnectionStatus.ClinicRejected);

		this.$mhaRouterReplace({
			name: goToFindClinic ? Route.Clinics.FindClinic : Route.Clinics.Home,
			params: { clinicId: clinic.id },
		});
	}

	protected redirectAutoConnect()
	{
		this.loadingQueue.pushLoadingState();
		// attempt to link with a clinic
		Services.PatientClinics.connectToClinic(this.clinic.id).then(
			(clinic: ClinicProfile) =>
			{
				this.clinic = clinic;
				this.attemptRedirect();
			}).catch(
			(errorResponse: ErrorResponse) =>
			{
				if (errorResponse.is(ErrorCode.IncompleteProfile))
				{
					this.openProfileModal(errorResponse.data.missing_fields);
				}
				else
				{
					this.showErrorState(errorResponse.message);
				}
			}).finally(
			() =>
			{
				this.loadingQueue.popLoadingState();
			});
	}

	protected showErrorState(specificErrors = null)
	{
		this.hasError = true;
		if (specificErrors)
		{
			this.errorString = specificErrors;
		}
	}

	protected openProfileModal(missingFields = null)
	{
		const props = {
			active: true,
			clinic: this.clinic,
			loading: this.isLoading,
			handleSubmit: this.attemptRedirect.bind(this),
			handleCancel: this.redirectNoConnection.bind(this),
			visibleProfileFields: missingFields,
			showInstructionText: true,
		};

		modalController.create(
			{
				// @ts-ignore
				component: PatientProfileModal,
				showBackdrop: true,
				cssClass: "fullscreen",
				componentProps: props,

			}).then(
			(modal) =>
			{
				modal.present();
			});
	}

	get isLoading()
	{
		return this.loadingQueue.isLoading;
	}
}
