import BaseService from "@/lib/services/BaseService";
import {ClinicAPI} from "@/lib/services/Api";
import {RemoteFeatureFlags} from "@/lib/integration/remote/model/RemoteFeatureFlags";

export default class RemoteFeatureSetService extends BaseService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * does the backing EMR support writing encounter notes or not.
	 */
	public async supportsWriteEncounterNote(): Promise<boolean>
	{
		return (await ClinicAPI().checkRemoteEndpoint(RemoteFeatureFlags.WriteEncounterNote)).data.exists;
	}

	/**
	 * does the backing EMR support saving documents to patient charts
	 */
	public async supportsSaveDocumentToChart(): Promise<boolean>
	{
		return (await ClinicAPI().checkRemoteEndpoint(RemoteFeatureFlags.SaveDocumentToChart)).data.exists;
	}
}
