import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecurityHeaderBar = _resolveComponent("SecurityHeaderBar")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_SecurityHeaderBar)
    ]),
    "content-top": _withCtx(() => [
      (_ctx.controller.userProfile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", null, _toDisplayString(this.$mhat("SecurityChangeEmail.ChangeEmailInstructionText")), 1),
            _createVNode(_component_PasswordField, {
              label: this.$mhat('SecurityChangeEmail.CurrentPasswordFieldLabel'),
              id: "currentPassword",
              value: _ctx.controller.passwords.currentPassword,
              onInput: _ctx.updatePassword
            }, null, 8, ["label", "value", "onInput"]),
            _createVNode(_component_TextField, {
              label: this.$mhat('SecurityChangeEmail.EmailFieldLabel'),
              id: "email",
              value: _ctx.controller.userProfile.email,
              valid: _ctx.controller.isValidEmail(),
              onInput: _ctx.updateProfile
            }, null, 8, ["label", "value", "valid", "onInput"])
          ]))
        : _createCommentVNode("", true)
    ]),
    "content-bottom": _withCtx(() => [
      _createVNode(_component_BaseButton, {
        pattern: _ctx.ButtonColorPattern.FILL,
        color: _ctx.ButtonColor.PRIMARY,
        disabled: !_ctx.controller.isValidEmail(),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSave()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("SecurityChangeEmail.ChangeEmailButtonText")), 1)
        ]),
        _: 1
      }, 8, ["pattern", "color", "disabled"]),
      _createVNode(_component_BaseButton, {
        pattern: _ctx.ButtonColorPattern.TRANSPARENT,
        color: _ctx.ButtonColor.DANGER,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCancel()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("SecurityChangeEmail.CancelButtonText")), 1)
        ]),
        _: 1
      }, 8, ["pattern", "color"])
    ]),
    _: 1
  }))
}