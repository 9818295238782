import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBarPrivacy = _resolveComponent("HeaderBarPrivacy")!
  const _component_CloudMDPrivacyPolicyText = _resolveComponent("CloudMDPrivacyPolicyText")!
  const _component_PrivacyPolicyText = _resolveComponent("PrivacyPolicyText")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, { contentWidthFull: "" }, {
    "content-header": _withCtx(() => [
      _createVNode(_component_HeaderBarPrivacy, {
        "header-text": this.$mhat('PrivacyPolicyPage.HeaderText')
      }, null, 8, ["header-text"])
    ]),
    "content-top": _withCtx(() => [
      ($options.isCloudMD())
        ? (_openBlock(), _createBlock(_component_CloudMDPrivacyPolicyText, { key: 0 }))
        : (_openBlock(), _createBlock(_component_PrivacyPolicyText, { key: 1 }))
    ]),
    _: 1
  }))
}