import {RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";

const MailHome = () => import(/* webpackChunkName: "Inbox" */ "@/views/patient_user/mail/MailHome.vue");

export enum MailRoute
{
	Home = "MailHome",
	HomeDeprecated = "MailHomeDeprecated",
}

export const inboxRoutes: RouteRecordRaw[] = [
	{
		path: "/mail",
		name: MailRoute.Home,
		props: (route) =>
		{
			return Object.assign({hasBackButtons: !(route.query.has_back === "false")}, route.params);
		},
		component: MailHome,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		// deprecated mailbox route, remove after release
		path: "/inbox",
		name: MailRoute.HomeDeprecated,
		props: true,
		component: MailHome,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
