import TelehealthApiServiceInterface from "@/lib/telehealth/api/TelehealthApiServiceInterface";
import {SessionData} from "@/lib/telehealth/models/sessionData.model";
import {PatientAPI, PublicAPI} from "@/lib/services/Api";
import LogEvent from "@/lib/telehealth/models/LogEvent";
import {TeleCallState} from "@/lib/telehealth/models/telehealth.types";
import {BrowserInfo} from "detect-browser";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import AuthStore from "@/lib/vuex/auth.store";
import {UserType} from "@/open_api/generated";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";

export default class PublicTelehealthApiService implements TelehealthApiServiceInterface
{
	protected _appointmentId: string;
	protected _oneTimeToken: string;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(appointmentId: string, oneTimeToken: string)
	{
		this._appointmentId = appointmentId;
		this._oneTimeToken = oneTimeToken;
	}

	// ==========================================================================
	// TelehealthApiServiceInterface Implementation
	// ==========================================================================

	/**
	 * fetch telehealth session data.
	 */
	public async fetchSessionData(): Promise<SessionData>
	{
		const oneTimeToken = (await PublicAPI().getAppointmentTelehealthSession(
			this._appointmentId,
			this._oneTimeToken)).data;
		return new SessionData(oneTimeToken.session_token);
	}

	/**
	 * log a telehealth event to the server.
	 * @param event - the event to log.
	 */
	public async logEvent(event: LogEvent): Promise<void>
	{
		await PublicAPI().logEventToPatientPublicTelehealthSession(
			this._appointmentId,
			this._oneTimeToken,
			event.asTelehealthLogEventDto);
	}

	/**
	 * Ping the telehealth session. Providing the server with the current state of the telehealth call.
	 * @param callState - the current state of the call.
	 */
	public async pingSession(callState: TeleCallState): Promise<void>
	{
		await PublicAPI().pingTelehealthSession(this._appointmentId, this._oneTimeToken, {status: callState});
	}

	/**
	 * get information about the local telehealth client.
	 * @param browserInfo - information about the users browser
	 */
	public async getLocalClientData(browserInfo: BrowserInfo): Promise<TelehealthClient>
	{
		const oneTimeToken = (await PublicAPI().getAppointmentTelehealthSession(
			this._appointmentId,
			this._oneTimeToken)).data;

		return new TelehealthClient(
			browserInfo,
			oneTimeToken.user_info.email,
			oneTimeToken.user_info.first_name,
			oneTimeToken.user_info.last_name,
			UserType.MyHealthAccessPublicOneTimeTelehealthToken);
	}

	/**
	 * record a chat message for this telehealth session.
	 * @param message - the message to record
	 * @return the newly recorded message
	 */
	public async recordChatMessage(message: ChatMessage): Promise<ChatMessage>
	{
		// public telehealth does not record chat.
		return message;
	}

	/**
	 * load chat history from DB.
	 * @return array of chat messages for the telehealth appointment.
	 */
	public async getChatHistory(): Promise<ChatMessage[]>
	{
		// public telehealth doesn't have chat history
		return [];
	}
}
