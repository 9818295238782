

	export default {
		name: "MessageInput",
		components: {},
		emits: ["input", "focus"],
		props: {
			placeholder: String,
			maxlength: String,
			value: String,
		},
	};
