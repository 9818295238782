

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {TabBarItem} from "@/components/Menu/types";
	import {AddedListener, addKeyboardListener} from "@/lib/plugins/Keyboard";

	@Options({components: {}})
	export default class TabBar extends Vue
	{
		@Prop({type: String}) customClass: string;
		@Prop({type: Array}) tabBarItems: TabBarItem[];

		keyboardListener: AddedListener = null;

		mounted()
		{
			const tabBar = this.$refs.tabBar as HTMLElement;
			this.keyboardListener = addKeyboardListener(
				() => tabBar.style.setProperty("--margin-bottom", "0"),
				() => tabBar.style.setProperty("--margin-bottom", "env(safe-area-inset-bottom)"),
			);
		}

		beforeUnmount()
		{
			this.keyboardListener?.remove();
		}

		public onClick(item: TabBarItem)
		{
			if (!item.disabled)
			{
				item.click.call(this);
			}
		}
	}
