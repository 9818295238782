
	export default {
		name: "ClinicsFindHeader",

		props: {
			headerText: {
				type: String,
				required: false,
			},
		},

		data()
		{
			return {
				headerProps: {
					icon: "icon-find-a-clinic",
					headerText: this.headerText ? this.headerText : this.$mhat("ClinicsFindHeader.HeaderText"),
					hasClose: true,
				},
			};
		},
	};
