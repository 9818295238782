
	import {Options, Vue} from "vue-property-decorator";

	import OneTimeCode from "@/lib/oneTimeCode/model/OneTimeCode";
	import PatientOneTimeCodeService from "@/lib/oneTimeCode/service/PatientOneTimeCodeService";
	import {OneTimeCodeDtoCodeTypeEnum} from "@/open_api/generated";

	@Options({})
	export default class AccountIdCodePage extends Vue
	{
		protected readonly CODE_CHECK_INTERVAL_MS = 30000;

		protected oneTimeCodeService: PatientOneTimeCodeService;
		protected oneTimeCode: OneTimeCode = null;
		protected codeRefreshTimer: number = null;

		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		public created(): void
		{
			this.oneTimeCodeService = new PatientOneTimeCodeService();
		}

		public async mounted(): Promise<void>
		{
			const existingCodes = await this.getExistingAccountIdCodes();

			if (existingCodes.length > 0)
			{
				this.oneTimeCode = existingCodes[0];
			}
			else
			{
				this.oneTimeCode = await (new PatientOneTimeCodeService()).generateAccountIdCode();
			}

			this.setupCodeRefreshTimer();
		}

		public unmounted(): void
		{
			if (this.codeRefreshTimer)
			{
				window.clearInterval(this.codeRefreshTimer);
			}
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		/**
		 * setup timer that checks & refreshes code periodically
		 */
		public setupCodeRefreshTimer(): void
		{
			this.codeRefreshTimer = window.setInterval(async () =>
			{
				const existingCodes = await this.getExistingAccountIdCodes();

				if (existingCodes.length === 0)
				{
					// generate new code
					this.oneTimeCode = await (new PatientOneTimeCodeService()).generateAccountIdCode();
				}
			}, this.CODE_CHECK_INTERVAL_MS);
		}

		/**
		 * get any existing account id codes for the patient
		 * @return promise that resolves to array of one time codes.
		 */
		public async getExistingAccountIdCodes(): Promise<OneTimeCode[]>
		{
			return (await this.oneTimeCodeService.getActiveCodes())
				.filter((code) => code.type === OneTimeCodeDtoCodeTypeEnum.PatientAccountIdCode);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get formattedCode(): string
		{
			if (this.oneTimeCode)
			{
				return `${this.oneTimeCode.code.substr(0, 3)}-${this.oneTimeCode.code.substr(3, 3)}`;
			}
			return "...";
		}
	}
