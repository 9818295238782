import {FusionRoutes} from "@/router/patient_user/fusion.routes";
import {getRouteOrWhitelabelRoute} from "@/router/whitelableUtil";

export default class FusionRouteCollection
{
	// ==========================================================================
	// Routes
	// ==========================================================================

	get NavigatorBookingLandingPage(): string
	{
		return getRouteOrWhitelabelRoute(FusionRoutes.NavigatorBookingLandingPage);
	}
}
