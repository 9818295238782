
	import router from "@/router/router";
	import {modalController} from "@ionic/vue";

	export default {
		name: "TermsAndConditionsText",

		props: {
			inModalMode: Boolean,
		},

		data()
		{
			return {
				router,
			};
		},

		methods: {
			routeToPrivacyPolicy()
			{
				this.router.push({name: this.Route.Public.PrivacyPolicy});
				if (this.inModalMode)
				{
					modalController.dismiss();
				}
			},
		},
	};
