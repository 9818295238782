import {PublicRoute as _PublicRoute, publicRoutes as _publicRoutes} from "@/router/public/public.routes";
import {publicAppointmentRoutes, PublicAppointmentRoutes} from "@/router/public/appointment.routes";
import {orgRoutes, OrganizationRoute} from "@/router/public/org.routes";
import {OrgPublicRoute, orgPublicRoutes} from "@/router/public/org.public.routes";
import {PublicRouteCollection} from "@/router/public/PublicRouteCollection";
import {RouteRecordRaw} from "vue-router";

export const PublicRoute = new PublicRouteCollection();

export type PublicRouteType = _PublicRoute | PublicAppointmentRoutes | OrganizationRoute | OrgPublicRoute;

export const publicRoutes: RouteRecordRaw[] = [
	..._publicRoutes,
	...publicAppointmentRoutes,
	...orgRoutes,
	...orgPublicRoutes,
];
