import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-069a64a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["button-container", _ctx.containerClasses])
  }, [
    _createElementVNode("input", {
      type: "submit",
      disabled: _ctx.disabled,
      hidden: ""
    }, null, 8, _hoisted_1),
    _createVNode(_component_ion_button, {
      key: _ctx.newKey,
      fill: "clear",
      type: _ctx.type,
      onClick: _ctx.onClick,
      class: _normalizeClass(["mha pill base-button", _ctx.buttonClasses]),
      disabled: _ctx.disabled,
      style: _normalizeStyle(_ctx.buttonStyles)
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["type", "onClick", "disabled", "class", "style"])
  ], 2))
}