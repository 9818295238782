import ClinicAppointment from "@/lib/appointment/model/ClinicAppointment";
import {DependentAPI, PatientAPI} from "@/lib/services/Api";
import ClinicAppointmentTransferToClinicAppointmentConverter from "@/lib/appointment/converter/ClinicAppointmentTransferToClinicAppointmentConverter";
import AppointmentCancelResponse from "@/lib/appointment/model/AppointmentCancelResponse";
import AppointmentCancelSuccessToAppointmentCancelResponseConverter from "@/lib/appointment/converter/AppointmentCancelSuccessToAppointmentCancelResponseConverter";
import {Appointment} from "@/lib/models/Appointment";
import {app} from "@/main";
import AuthStore from "@/lib/vuex/auth.store";
import {Moment} from "moment";
import {AppointmentTransfer, BookSlotData} from "@/open_api/generated";
import AppointmentBookSuccessToAppointmentBookResponseConverter from "@/lib/appointment/converter/AppointmentBookSuccessToAppointmentBookResponseConverter";
import AppointmentBookResponse from "@/lib/appointment/model/AppointmentBookResponse";

export default class PatientAppointmentService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * get a clinic appointment from patient clinic appointment id
	 * @param patientClinicAppointmentId - patient_clinic_appointment_id to get the clinic_appointment for
	 * @return promise that resolves to a clinic appointment
	 */
	public async getClinicAppointment(patientClinicAppointmentId: string): Promise<ClinicAppointment>
	{
		return (new ClinicAppointmentTransferToClinicAppointmentConverter())
			.convert((await PatientAPI().getPatientClinicAppointment(patientClinicAppointmentId)).data);
	}

	/**
	 * get a appointment for a patient by clinid & remote id.
	 * @param clinicId - the clinic to get the remote appointment for
	 * @param remoteId - the remote id of the appointment
	 */
	public async getRemoteAppointment(clinicId: string, remoteId: string): Promise<Appointment>
	{
		return new Appointment((await PatientAPI().getAppointment(clinicId, remoteId)).data);
	}

	/**
	 * get all remote appointments for a patient in a given time range.
	 * @param patientId - the patient to get the appointments for
	 * @param startDate - the start time of the range.
	 * @param endDate - the end time of the range.
	 * @param sorted - [optional. default true] true / false. If true appointments will be sorted newest -> oldest. Base on the appointment date.
	 * @return promise that resolves to a list of appointments.
	 */
	public async getAllRemoteAppointmentsInRange(patientId: string, startDate: Moment, endDate: Moment, sorted = true)
	{
		let rawAppointmentList: AppointmentTransfer[];

		if (patientId === AuthStore.loggedInUser.id)
		{
			rawAppointmentList = (await PatientAPI().patientAppointments(startDate, endDate)).data;
		}
		else
		{
			rawAppointmentList = (await DependentAPI().dependentAppointments(patientId, startDate, endDate)).data;
		}

		const appointments = rawAppointmentList.map((appt) => new Appointment(appt));

		if (sorted)
		{
			appointments.sort((appointment1, appointment2) =>
			{
				return appointment2.startDateTime.unix() - appointment1.startDateTime.unix();
			});
		}

		return appointments;
	}

	/**
	 * cancel an appointment
	 * @param appointment - the appointment to cancel
	 * @return promise that resolves to appointment cancel response object
	 */
	public async cancelAppointment(appointment: Appointment): Promise<AppointmentCancelResponse>
	{
		if (appointment.patientId === AuthStore.loggedInPatient?.id)
		{
			return (new AppointmentCancelSuccessToAppointmentCancelResponseConverter()).convert(
				(await PatientAPI().patientClinicAppointmentCancel(appointment.clinicId, appointment.remoteId)).data);
		}
		else
		{
			return (new AppointmentCancelSuccessToAppointmentCancelResponseConverter()).convert(
				(await DependentAPI().dependentClinicAppointmentCancel(appointment.patientId, appointment.clinicId, appointment.remoteId)).data);
		}
	}

	/**
	 * Reschedule an appointment
	 * @param dependentId - the id of the dependent to book the new appointment with
	 * @param appointmentRemoteId - the remote id of the appointment to cancel
	 * @param clinicId - the id of the clinic to book the new appointment with
	 * @param providerId - the provider id to book the new appointment with
	 * @param appointmentTypeId - the appointment type id of the new appointment
	 * @param slotData - the slot data of the new appointment
	 * @return promise that resolves to appointment book response object
	 */
	public async rescheduleAppointment(dependentId: string,
	                                   appointmentRemoteId: string,
	                                   clinicId: string,
	                                   providerId: string,
	                                   appointmentTypeId: string,
	                                   slotData: BookSlotData): Promise<AppointmentBookResponse>
	{
		if (dependentId)
		{
			return (new AppointmentBookSuccessToAppointmentBookResponseConverter()).convert(
				(await DependentAPI().dependentClinicAppointmentReschedule(dependentId,
					clinicId,
					appointmentRemoteId,
					{provider_id: providerId, appointment_type_id: appointmentTypeId, slot_data: slotData},
				)).data,
			);
		}
		else
		{
			return (new AppointmentBookSuccessToAppointmentBookResponseConverter()).convert(
				(await PatientAPI().patientClinicAppointmentReschedule(clinicId,
					appointmentRemoteId,
					{provider_id: providerId, appointment_type_id: appointmentTypeId, slot_data: slotData},
				)).data,
			);
		}
	}
}
