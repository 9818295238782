import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54d6ad7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kiosk-content" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  class: "body",
  ref: "bodyContent"
}
const _hoisted_4 = {
  class: "inner-body",
  ref: "bodyInnerContent"
}
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KioskBranded = _resolveComponent("KioskBranded")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "kiosk-wrapper" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_KioskBranded, { class: "logo ion-no-margin" }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.pharmacyLogo.url,
                  alt: _ctx.pharmacyLogo.alt,
                  width: "544"
                }, null, 8, _hoisted_5)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "body", {}, undefined, true)
            ])
          ], 512)
        ], 512),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ])
      ])
    ]),
    _: 3
  }))
}