import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!

  return (_openBlock(), _createBlock(_component_EventStateSpinner, {
    successIcon: _ctx.noResultsIcon,
    successTitle: _ctx.noResultsTitle,
    successMessage: _ctx.noResultsMessage,
    loadingIcon: _ctx.loadingIcon,
    isLoading: _ctx.isLoading,
    loadingTitle: _ctx.loadingTitle,
    loadingMessage: _ctx.loadingMessage
  }, null, 8, ["successIcon", "successTitle", "successMessage", "loadingIcon", "isLoading", "loadingTitle", "loadingMessage"]))
}