import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_ClinicSearchSpinner = _resolveComponent("ClinicSearchSpinner")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, { "has-close": false })
    ]),
    _: 2
  }, [
    (!$options.shouldShowSpinner)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$mhat('BookingHome.BookingInstructionText', {patientFirstName: $options.loggedInUser.firstName})), 1),
            _createVNode(_component_BaseButton, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.routeClinic()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$mhat('BookingHome.Me')), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.dependents, (dependent) => {
              return (_openBlock(), _createBlock(_component_BaseButton, {
                key: dependent.id,
                onClick: ($event: any) => ($options.routeClinic(dependent.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(dependent.fullName), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128)),
            _createVNode(_component_RouteButton, {
              pattern: $data.ButtonColorPattern.TRANSPARENT,
              color: $data.ButtonColor.PRIMARY,
              route: $data.Route.Dependents.AddDependent
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$mhat('BookingHome.AddADependent')), 1)
              ]),
              _: 1
            }, 8, ["pattern", "color", "route"])
          ])
        }
      : {
          name: "content-center",
          fn: _withCtx(() => [
            _createVNode(_component_ClinicSearchSpinner, {
              "search-type": $data.ClinicSearchType.CONNECTED,
              "dependent-id": $data.dependentId,
              "is-loading": $data.isLoading
            }, null, 8, ["search-type", "dependent-id", "is-loading"])
          ])
        }
  ]), 1024))
}