

	import {Options, Vue, Prop, Emit} from "vue-property-decorator";
	import {SelectionCardColorPattern} from "./types";

	@Options({
		emits: ["click", "delete", "mouseup", "mousedown"],
	})
	export default class BaseSelectionCard extends Vue
	{
		// ==========================================================================
		// Properties
		// ==========================================================================

		@Prop({type: Boolean}) hasDelete: boolean;
		@Prop({type: Boolean}) clickable: boolean;
		@Prop({type: Boolean}) teleHealth: boolean;
		@Prop({type: Boolean}) attachment: boolean;
		@Prop({type: Boolean}) deleted: boolean;
		@Prop({type: [String, Object]}) value: any;
		@Prop({type: String}) header: string;
		@Prop({type: String}) primary: string;
		@Prop({type: String}) footer: string;
		@Prop({type: Object}) borderText: any;

		/* style props */
		@Prop({type: Boolean}) active: boolean;
		@Prop({type: Boolean}) regular: boolean;
		@Prop({type: String, default: SelectionCardColorPattern.DEFAULT}) pattern: SelectionCardColorPattern;

		public handleClick(): void
		{
			if (this.clickable)
			{
				this.$emit("click", this.value);
			}
		}

		get cardClasses()
		{
			const {active, deleted, clickable, pattern} = this;
			return {
				active,
				deleted,
				clickable,
				faded: pattern === SelectionCardColorPattern.FADED,
			};
		}
	}
