

	import {Options, Vue} from "vue-property-decorator";
	import PrivacyHeaderBar from "./components/PrivacyHeaderBar.vue";
	import {UserProfileController} from "./controllers/UserProfileController";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {LabelPosition} from "@/components/Inputs/types";

	@Options({components: {PrivacyHeaderBar}})
	export default class PrivacyHome extends Vue
	{
		public controller: UserProfileController = new UserProfileController();
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;
		public LabelPosition = LabelPosition;
	}
