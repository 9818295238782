import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video-status-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconTooltip = _resolveComponent("IconTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconTooltip, {
      icon: "icon-video-2",
      "icon-size": "24px",
      "extra-class": _ctx.cameraIconClass,
      tooltip: _ctx.cameraTooltip,
      disabled: !this.videoStatus,
      duration: 3000
    }, null, 8, ["extra-class", "tooltip", "disabled"])
  ]))
}