import DeviceInfo from "@/lib/DeviceInfo";

import BRAND_LOGO_MHA from "@/assets/images/module-brand-logo.svg";
import DEPENDENTS_LOGO_MHA from "@/assets/images/module-dependents.svg";
import PROFILE_LOGO_MHA from "@/assets/images/module-profile.svg";
import APPOINTMENTS_LOGO_MHA from "@/assets/images/module-appointments.svg";
import INBOX_LOGO_MHA from "@/assets/images/module-messages.svg";
import CLINICS_LOGO_MHA from "@/assets/images/module-find-clinic.svg";
import BOOKING_LOGO_MHA from "@/assets/images/module-booking.svg";
import TELEHEALTH_LOGO_MHA from "@/assets/images/module-telehealth.svg";

import BRAND_LOGO_CMD from "@/assets/images/cloud_md/module-brand-logo.svg";
import DEPENDENTS_LOGO_CMD from "@/assets/images/cloud_md/module-dependents.svg";
import PROFILE_LOGO_CMD from "@/assets/images/cloud_md/module-profile.svg";
import APPOINTMENTS_LOGO_CMD from "@/assets/images/cloud_md/module-appointments.svg";
import INBOX_LOGO_CMD from "@/assets/images/cloud_md/module-messages.svg";
import CLINICS_LOGO_CMD from "@/assets/images/cloud_md/module-find-clinic.svg";
import BOOKING_LOGO_CMD from "@/assets/images/cloud_md/module-booking.svg";
import TELEHEALTH_LOGO_CMD from "@/assets/images/cloud_md/module-telehealth.svg";

import ICON_MHA_VIDEO from "@/assets/images/icon-video-chat.svg";
import ICON_MHA_ADD from "@/assets/images/icon-button-add.svg";
import ICON_MHA_FIND from "@/assets/images/icon-button-find.svg";
import ICON_MHA_WRITE from "@/assets/images/icon-button-write.svg";

import ICON_CMD_VIDEO from "@/assets/images/cloud_md/icon-video-chat.svg";
import ICON_CMD_ADD from "@/assets/images/cloud_md/icon-button-add.svg";
import ICON_CMD_FIND from "@/assets/images/cloud_md/icon-button-find.svg";
import ICON_CMD_WRITE from "@/assets/images/cloud_md/icon-button-write.svg";

import IMAGE_BRANDED_MHA from "@/assets/images/branding-logo.svg";
import IMAGE_BRANDED_MHA_LIGHT from "@/assets/images/branding-logo-white.svg";
import IMAGE_BRANDED_CMD from "@/assets/images/cloud_md/branding-logo.svg";
import IMAGE_BRANDED_CMD_LIGHT from "@/assets/images/cloud_md/branding-logo-white.svg";

const isCloudMd = DeviceInfo.isCloudMd;

export const IMAGE_BRANDED = isCloudMd ? IMAGE_BRANDED_CMD : IMAGE_BRANDED_MHA;
export const IMAGE_BRANDED_LIGHT = isCloudMd ? IMAGE_BRANDED_CMD_LIGHT : IMAGE_BRANDED_MHA_LIGHT;

export const LOGO_BRAND = isCloudMd ? BRAND_LOGO_CMD : BRAND_LOGO_MHA;
export const LOGO_DEPENDENTS = isCloudMd ? DEPENDENTS_LOGO_CMD : DEPENDENTS_LOGO_MHA;
export const LOGO_PROFILE = isCloudMd ? PROFILE_LOGO_CMD : PROFILE_LOGO_MHA;
export const LOGO_APPOINTMENTS = isCloudMd ? APPOINTMENTS_LOGO_CMD : APPOINTMENTS_LOGO_MHA;
export const LOGO_INBOX = isCloudMd ? INBOX_LOGO_CMD : INBOX_LOGO_MHA;
export const ICON_CHAT = isCloudMd ? INBOX_LOGO_CMD : INBOX_LOGO_MHA;
export const LOGO_CLINICS = isCloudMd ? CLINICS_LOGO_CMD : CLINICS_LOGO_MHA;
export const LOGO_BOOKING = isCloudMd ? BOOKING_LOGO_CMD : BOOKING_LOGO_MHA;
export const LOGO_TELEHEALTH = isCloudMd ? TELEHEALTH_LOGO_CMD : TELEHEALTH_LOGO_MHA;

export const ICON_VIDEO = isCloudMd ? ICON_CMD_VIDEO : ICON_MHA_VIDEO;
export const ICON_BUTTON_ADD = isCloudMd ? ICON_CMD_ADD : ICON_MHA_ADD;
export const ICON_BUTTON_FIND = isCloudMd ? ICON_CMD_FIND : ICON_MHA_FIND;
export const ICON_BUTTON_WRITE = isCloudMd ? ICON_CMD_WRITE : ICON_MHA_WRITE;
