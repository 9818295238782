import {AqsPatientAPI} from "@/lib/services/Api";
import {AqsQueueDto} from "@/open_api/generated";

export default class AppointmentQueueService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * get the ODB queue for the specified clinic
	 * @param clinicId - the clinic to get the ODB queue for.
	 * @return - the ODB queue
	 */
	public async getODBQueueForClinic(clinicId: string): Promise<AqsQueueDto>
	{
		return (await AqsPatientAPI().getOdbQueue(clinicId)).data;
	}
}
