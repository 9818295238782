

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class AppButton extends Vue
	{
		@Prop({type: String}) fill: string;

		inheritAttrs: false;
	}
