
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import {getResources} from "@/lib/utils/Resource";
	import LoginPageHeader from "@/views/patient_user/login/components/LoginPageHeader.vue";
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {Route} from "@/router/router";
	import {Pharmacy} from "@/lib/kiosk/pharmacy.model";
	import {PublicAPI} from "@/lib/services/Api";
	import LoginForm from "@/views/patient_user/login/components/LoginForm.vue";
	import OrgImage from "@/components/Organization/OrgImage.vue";
	import {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";

	@Options({components: {OrgImage, LoginForm, LoginPageHeader, CorporateFooter}})
	export default class LoginPage extends Vue
	{
		@Prop(String) urlFriendlyName: string;

		public ORG_ASSET_TYPE = ORG_ASSET_TYPE;
		public isLoading = false;

		public pharmacy: Pharmacy = null;
		initialHostColor = null;

		public created()
		{
			this.initialHostColor = getComputedStyle(document.documentElement)
				.getPropertyValue("--color-primary-base");

			this.fetchBrandedPharmacy();
		}

		public beforeUnmount()
		{
			const root = document.querySelector("#app") as HTMLElement;
			root.style.removeProperty("--color-primary-base");
		}

		public fetchBrandedPharmacy()
		{
			if (this.urlFriendlyName)
			{
				this.isLoading = true;
				PublicAPI().getCustomLoginPharmacy(this.urlFriendlyName)
					.then((response) =>
					{
						this.pharmacy = Pharmacy.fromDto(response.data);
						if (this.pharmacy.brandColor)
						{
							const root = document.querySelector("#app") as HTMLElement;
							root.style.setProperty("--color-primary-base", `#${this.pharmacy.brandColor}`);
						}
					})
					.finally(() => this.isLoading = false);
			}
		}

		public handleLoginSuccess()
		{
			const pushRoute = this.$route.query.push_route;

			if (pushRoute && pushRoute !== "/login")
			{
				this.$mhaRouterPush(pushRoute as string);
			}
			else
			{
				this.$mhaRouterPush(Route.Home);
			}
		}

		public handleSignupRequest()
		{
			this.$mhaRouterPush({
				name: Route.Signup.Form,
				query: {
					clinicId: this.clinicId,
				},
			});
		}
		/* Computed Properties */

		get brandedLogo(): {src: string, alt: string}
		{
			const {pharmacy} = this;
			return pharmacy ? {src: pharmacy.logoUrl, alt: `${pharmacy.name} Logo`} : {src: null, alt: null};
		}

		get clinicId()
		{
			return this.$route?.query?.clinicId;
		}

		get loginMessage(): string
		{
			if (this.urlFriendlyName)
			{
				return this.pharmacy?.loginMessage;
			}
			else
			{
				if (this.$isCloudMd)
				{
					return this.$mhat("LoginPage.LoginPageGreetingCmd");
				}

				return this.$mhat("LoginPage.LoginPageGreetingMha");
			}
		}
	}
