
	import BookingController, {setBookingParams} from "@/views/patient_user/booking/BookingController";
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import ClinicCard from "@/components/Buttons/ClinicCard.vue";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import Services from "@/lib/services/Services";
	import ClinicService from "@/lib/clinics/Clinic.service";
	import {isBlank} from "@/lib/utils/prototype/String";
	import ClinicSearchSpinner from "@/components/EventState/ClinicSearchSpinner.vue";
	import {ClinicSearchType} from "@/components/EventState/types";
	import {Route} from "@/router/router";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import DeviceInfo from "@/lib/DeviceInfo";
	import {bookingForward, dynamicBookingRoute, getBookingClinicService} from "@/views/patient_user/booking/BookingRoutingHelper";
	import {BookingRoute} from "@/router/patient_user/booking.routes";

	export default {
		name: "BookingClinic",

		props: {
			dependentId: String,
			// if true back buttons will be shown in the top bar.
			hasBackButtons: {
				default: true,
				type: Boolean,
			},
		},

		data()
		{
			return {
				clinics: [] as ClinicProfile[],
				isLoading: true,
				selectingClinic: false,
				clinicService: this.$route.params.clinicService as ClinicService,
				ClinicSearchType,
			};
		},

		async beforeRouteEnter(to, from, next)
		{
			const dependentId = to.params.dependentId;
			const clinicService = getBookingClinicService(dependentId);
			const bookableClinics = await getBookingClinicService(dependentId).bookableClinics();
			to.params.clinicService = clinicService;

			if (DeviceInfo.isCloudMd)
			{
				// if CloudMd skip past provider and clinic selection.
				const cloudMdClinic: ClinicProfile =
					await getBookingClinicService(dependentId).getCloudMdClinic();

				return bookingForward(dynamicBookingRoute(dependentId).AppointmentType,
					{
						dependentId,
						clinicId: cloudMdClinic.id,
						providerOrGroupId: cloudMdClinic.primaryProvider?.id,
					},
					next,
					to.query);
			}
			else if (bookableClinics.length === 1)
			{
				const clinicProviderGroups = await clinicService.getClinicProviderGroups(bookableClinics[0].id);

				if (clinicProviderGroups.length > 0)
				{
					return bookingForward(dynamicBookingRoute(dependentId).ProviderGroup,
						{
							dependentId,
							clinicId: bookableClinics[0].id,
						},
						next,
						to.query);
				}
				else
				{
					return bookingForward(dynamicBookingRoute(dependentId).Provider,
						{
							dependentId,
							clinicId: bookableClinics[0].id,
						},
						next,
						to.query);
				}
			}

			return next();
		},

		created()
		{
			setBookingParams.call(this);
			this.clinicService.bookableClinics()
				.then((clinics) =>
				{
					this.clinics = clinics;
				})
				.catch((error: ErrorResponse) =>
				{
					if (!error.isAuthError && !error.is(ErrorCode.IncompleteProfile))
					{
						WebNotification.$emit({
							event: NotifyEvent.Generic,
							type: NotificationType.Dismiss,
							severity: NotificationSeverity.Critical,
							title: this.$mhat("BookingClinic.GenericError"),
							message: this.$mhat("BookingClinic.GenericErrorMessage"),
						});
					}
				})
				.finally(() =>
				{
					this.isLoading = false;
				});
		},

		methods: {
			async setSelected(selected: ClinicProfile)
			{
				this.selectingClinic = true;
				this.updateControllerClinic(selected);

				const clinicProviderGroups = await this.clinicService.getClinicProviderGroups(selected.id);

				if (clinicProviderGroups.length > 0)
				{
					this.toBookingProviderGroup();
				}
				else
				{
					this.toBookingProvider();
				}
			},

			updateControllerClinic(clinic: ClinicProfile)
			{
				const controller: BookingController = this.controller;
				controller.clinic = clinic;
				controller.clinicId = clinic.id;
			},

			toBookingProvider()
			{
				const {appointmentMethod, dependentId} = this;
				const clinicId = this.controller.clinicId;
				this.$mhaRouterPush({
					name: this.isDependentMode ? Route.Booking.Dependent.Provider : Route.Booking.Provider,
					params: {appointmentMethod, dependentId, clinicId},
				});
			},

			toBookingProviderGroup()
			{
				const {appointmentMethod, dependentId} = this;
				const clinicId = this.controller.clinicId;
				this.$mhaRouterPush({
					name: this.isDependentMode ? Route.Booking.Dependent.ProviderGroup : Route.Booking.ProviderGroup,
					params: {appointmentMethod, dependentId, clinicId},
				});
			},
		},

		computed: {
			controller()
			{
				return BookingControllerStore.bookingController;
			},

			noClinics()
			{
				return !this.isLoading && this.clinics.length === 0;
			},

			isDependentMode()
			{
				return !isBlank(this.dependentId);
			},

			shouldShowLoadingSpinner()
			{
				return (this.isLoading || this.noClinics) && !this.selectingClinic;
			},
		},

		components: {
			ClinicSearchSpinner,
			DependentSubheader,
			ClinicCard,
			BookingHeaderBar,
		},
	};
