import {Event, Session} from "@opentok/client";
import {
	ConnectionEvent,
	OT_CUSTOM_SIGNAL,
	OT_EVENT_TYPE,
	OT_EVENT_TYPES,
	OT_LOCAL_SIGNAL,
	OT_SIGNAL_PREFIX,
	SessionDisconnectedEvent,
	SignalEvent,
} from "@/lib/telehealth/opentok/ot.types";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import EventLogger from "@/lib/telehealth/opentok/EventLogger";
import SessionClient from "@/lib/telehealth/opentok/SessionClient";

/**
 * responsible for dispatching session signals to the other video call components
 */
export class SignalDispatcher
{
	protected dispatchMap: Map<string, CallbackCollection> = new Map<string, CallbackCollection>();
	protected onLogEventCallbacks: CallbackCollection = new CallbackCollection();
	protected selfClient: SessionClient;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(onLogEventCallbacks)
	{
		this.onLogEventCallbacks = onLogEventCallbacks;
	}

	/**
	 * register signal listener on the OT session
	 * @param otSession - the ot session to listen for signals on
	 */
	public listenForEvents(otSession: Session): void
	{
		otSession.on(OT_EVENT_TYPES.join(" "), this.onSignal, this);
	}

	/**
	 * remove the signal listener for the OT session
	 * @param otSession - the ot session to remove the listener from
	 */
	public removeEventListener(otSession: Session): void
	{
		otSession.off(OT_EVENT_TYPES.join(" "), this.onSignal);
	}

	/**
	 * add a single handler
	 * @param singleType - the type of signal this handler should handle
	 * @param callback - the single handler callback
	 * @param context - [optional] the object that will be bound to "this" in the callback.
	 */
	public addSignalHandler(
		singleType: OT_CUSTOM_SIGNAL | OT_EVENT_TYPE | OT_LOCAL_SIGNAL,
		callback: (event: SignalEvent | ConnectionEvent | SessionDisconnectedEvent | Event<any, any>) => void,
		context?: any)
	{
		if (context)
		{
			callback = callback.bind(context);
		}

		if (this.dispatchMap.has(singleType))
		{
			this.dispatchMap.get(singleType).addCallback(callback);
		}
		else
		{
			this.dispatchMap.set(singleType, new CallbackCollection([callback]));
		}
	}

	public setSelf(client: SessionClient): void
	{
		this.selfClient = client;
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	protected onSignal(event: Event<string, any>): void
	{
		const signalType = event.type.toString().replace(OT_SIGNAL_PREFIX, "");

		if (this.dispatchMap.has(signalType))
		{
			this.dispatchMap.get(signalType).call(event);
		}

		// log session events
		if (this.selfClient)
		{
			EventLogger.logInboundSignal(signalType, event, this.selfClient, this.onLogEventCallbacks);
		}
	}
}
