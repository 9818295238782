

	import {Options, Vue, Prop} from "vue-property-decorator";
	import moment, {Moment} from "moment";
	import {SelectOption} from "@/lib/types/Components";
	import {DATE_FORMAT} from "@/constants";
	import ValidationList from "@/lib/utils/ValidationList";
	import InputLayout from "@/components/Inputs/InputLayout.vue";

	@Options({components: {InputLayout}})
	export default class DateSelector extends Vue
	{
		@Prop({type: String}) id: string;
		@Prop({type: String}) label: string;
		@Prop({type: Boolean}) hideLabel: boolean;
		@Prop({type: Boolean}) required: boolean;
		@Prop({type: [Boolean, ValidationList], default: true}) valid: [boolean, ValidationList];
		@Prop({type: Number, default: 1}) zIndex: number;
		@Prop({type: Boolean}) compact: boolean;
		@Prop({type: Boolean}) disabled: boolean;

		@Prop({type: Boolean, default: false}) defaultToday: boolean;
		@Prop({type: Boolean, default: true}) allowFuture: boolean;
		@Prop({type: [String, Object]}) value: [string, Moment];
		@Prop({type: [String, Object], default: "1900-01-01"}) dateRangeStart: [string, Moment];
		@Prop({type: [String, Object]}) dateRangeEnd: [string, Moment];

		pristine = true;

		private dateValues = {
			day: null,
			month: null,
			year: null,
		};

		created()
		{
			const defaultDate = this.defaultDate;
			if (defaultDate)
			{
				this.setDateValues(defaultDate.year(), defaultDate.month() + 1, defaultDate.date());
			}
			// must emit an initial change event of the model may still be empty.
			this.onDateChange();
		}

		public setDateValues(year, month, day)
		{
			this.dateValues.year = year;
			this.dateValues.month = month;
			this.dateValues.day = day;
		}

		public dateValuesAsMoment()
		{
			if (this.dateValues.year && this.dateValues.month && this.dateValues.day)
			{
				const momentVal = moment(
					{
						years: this.dateValues.year,
						months: this.dateValues.month - 1,
						date: this.dateValues.day,
					});
				if (momentVal.isValid())
				{
					return momentVal;
				}
			}
			return null;
		}

		public onDateChange()
		{
			const momentVal = this.dateValuesAsMoment();
			const dateValue = (momentVal) ? momentVal.format(DATE_FORMAT.DATE_STRING) : null;
			const emitVal = {
				id: this.id,
				value: dateValue,
			};
			this.$emit("input", emitVal);
		}

		public onDayChange(option)
		{
			this.pristine = false;
			this.setDateValues(this.dateValues.year, this.dateValues.month, option.value);
			this.onDateChange();
		}

		public onMonthChange(option)
		{
			this.pristine = false;
			this.setDateValues(this.dateValues.year, option.value, this.dateValues.day);
			this.onDateChange();
		}

		public onYearChange(option)
		{
			this.pristine = false;
			this.setDateValues(option.value, this.dateValues.month, this.dateValues.day);
			this.onDateChange();
		}

		public getAsMoment(dateObj: any): Moment
		{
			if (!dateObj)
			{
				return null;
			}
			else if (moment.isMoment(dateObj))
			{
				return dateObj as Moment;
			}
			else
			{
				return moment(dateObj, DATE_FORMAT.DATE_STRING);
			}
		}

		get selectedDay(): SelectOption
		{
			if (this.dateValues.day)
			{
				return this.dayOptions.find((day) => day.value === this.dateValues.day);
			}
		}

		get selectedMonth(): SelectOption
		{
			if (this.dateValues.month)
			{
				return this.monthOptions.find((month) => month.value === this.dateValues.month);
			}
		}

		get selectedYear(): SelectOption
		{
			if (this.dateValues.year)
			{
				return this.yearOptions.find((year) => year.value === this.dateValues.year);
			}
		}

		get dayOptions(): SelectOption[]
		{
			let daysInMonth = 31;

			// if year and month selected limit to number of days in month
			if (this.dateValues.year && this.dateValues.month)
			{
				daysInMonth = moment().year(this.dateValues.year).month(this.dateValues.month - 1).daysInMonth();

				// if current year, month, and limit future, don't show days in the future
				const now = moment();
				if (this.dateValues.year === now.year() && (this.dateValues.month - 1) === now.month() && !this.allowFuture)
				{
					daysInMonth = now.date();

					// invalidate the day if it is out of range
					if (this.dateValues.day > daysInMonth)
					{
						this.dateValues.day = 0;
						this.onDateChange();
					}
				}
			}

			const days = [];
			for (let i = 1; i <= daysInMonth; i++)
			{
				days.push({
					name: i.toString(),
					value: i,
				});
			}
			return days;
		}

		get monthOptions(): SelectOption[]
		{
			const now = moment();
			let monthOptions = moment.months().map((name, index) =>
			{
				// use 1 indexing for months (1-12), 0 is counted as 'no selection' in the vue dropdown
				return {
					name,
					value: index + 1,
				};
			});

			// if current year is selected do not allow future months to be selected
			if (this.dateValues.year === now.year() && !this.allowFuture)
			{
				monthOptions = monthOptions.filter((option) => option.value <= (now.month() + 1));

				// ensure that currently selected month is in options list
				if (!monthOptions.find((monthOpt) => monthOpt.value === this.dateValues.month))
				{
					this.dateValues.month = null;
					this.onDateChange();
				}
			}

			return monthOptions;
		}

		get yearOptions(): SelectOption[]
		{
			const years = [];
			for (let i = this.endDate.year(); i >= this.startDate.year(); i--)
			{
				years.push({
					name: i.toString(),
					value: i,
				});
			}
			return years;
		}

		get startDate(): Moment
		{
			return this.getAsMoment(this.dateRangeStart);
		}

		get endDate(): Moment
		{
			if (this.dateRangeEnd)
			{
				return this.getAsMoment(this.dateRangeEnd);
			}
			else if (!this.allowFuture)
			{
				return moment();
			}
			else
			{
				return moment().add(2, "years");
			}
		}

		get defaultDate(): Moment
		{
			return (this.value) ? this.getAsMoment(this.value) : ((this.defaultToday) ? moment() : null);
		}

		get isValid(): boolean
		{
			if (this.pristine)
			{
				return true;
			}
			else if (typeof this.valid === "boolean")
			{
				return this.valid;
			}
			else if (this.valid instanceof ValidationList)
			{
				return this.valid.isValid();
			}

			return true;
		}

		get invalidText(): string
		{
			if (this.valid instanceof ValidationList)
			{
				return this.valid.getValidationResultMsg();
			}
			return "";
		}
	}
