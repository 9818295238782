import {Vue} from "vue-property-decorator";
import { ClinicPageController } from "./ClinicPageController";
import { Patient } from "@/lib/patient/Patient";
import Services from "@/lib/services/Services";
import PatientProfileModal from "@/views/patient_user/clinic/components/PatientProfileModal.vue";
import { DependentProfileController } from "@/views/patient_user/profile/controllers/DependentProfileController";
import {modalController} from "@ionic/vue";

export default class ClinicPageDependentController extends ClinicPageController
{
	constructor(vue: Vue, patientUser: Patient)
	{
		super(vue, patientUser);
		this._clinicService = Services.DependentClinics(this.patientUser.id);
	}

	public async routeToBookAppointment()
	{
		// @ts-ignore
		const {parentCtx} = this.instance;
		parentCtx.$mhaRouterPush(this.clinic.dependentBookingRoute(this.patientUser.id));
	}

	protected openProfileModal(requiredFields = null)
	{
		// @ts-ignore
		const {parentCtx} = this.instance;

		const props = {
			active: true,
			clinic: this.clinic,
			loading: this.isLoading,
			handleSubmit: this.connectToClinic.bind(this),
			visibleProfileFields: requiredFields,
			profileController: new DependentProfileController(this.patientUser),
		};

		modalController
			.create({
				// @ts-ignore
				component: PatientProfileModal,
				showBackdrop: true,
				componentProps: props,
			})
			.then((modal) =>
			{
				modal.present();
			});
	}
}
