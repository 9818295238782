

	import {Options, Prop, Vue} from "vue-property-decorator";
	import OnDemandBookingContentLayout from "@/views/public/whitelabel/components/WhitelabelContentLayout.vue";
	import defaultBackground from "@/assets/images/graphics/common/background.jpg";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import OrganizationStore, {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";
	import {
		AqsAppointmentDto,
		AqsQueuedAppointmentBookDto,
		AqsQueueDto, ClinicAppointmentTransfer,
		ErrorCode,
		PharmacyDto,
	} from "@/open_api/generated";
	import HomePageHeaderBar from "@/views/patient_user/home/components/HomePageHeaderBar.vue";
	import {AqsPatientAPI, PatientAPI} from "@/lib/services/Api";
	import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import LoadingQueue from "@/lib/LoadingQueue";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import OnDemandBookingHoursModal from "@/views/patient_user/odb/booking/OnDemandBookingHoursModal.vue";
	import {DateUtils} from "@/lib/utils/Date";
	import moment from "moment";
	import OrgImage from "@/components/Organization/OrgImage.vue";
	import {modalController} from "@ionic/vue";
	import {useI18n} from "vue-i18n";

	@Options({components: {OrgImage, OnDemandBookingContentLayout, CorporateFooter, HomePageHeaderBar}})
	export default class OnDemandBookingReason extends Vue
	{
		@Prop({type: String, required: true}) organizationId: string;

		public backgroundImage = defaultBackground;
		public loadingQueue: LoadingQueue = new LoadingQueue();

		public selectedPharmacy: PharmacyDto = null;
		public selectedCity: string = null;
		public appointmentReason = "";

		private SCHEDULED_APPOINTMENT_SEARCH_RANGE = 1; // hours
		private ORG_ASSET_TYPE = ORG_ASSET_TYPE;

		public async created()
		{
			this.loadingQueue.pushLoadingState();
			await OrganizationStore.loadOrganizationPharmacies(OrganizationStore.organizationProfile.id);

			// if there is only one pharmacy option, auto-select it
			if (this.singlePharmacyMode && this.pharmacies.length > 0)
			{
				this.pharmacySelected(this.pharmacies[0]);
			}
			this.loadingQueue.popLoadingState();
		}

		public pharmacySelected(pharmacy: any): void
		{
			this.selectedPharmacy = pharmacy.value;
		}

		public citySelected(city: any): void
		{
			this.selectedCity = city.value;

			// clear pharmacy selection
			this.selectedPharmacy = null;

			// if there is only one pharmacy option, auto-select it
			if (this.singlePharmacyMode && this.pharmacies.length > 0)
			{
				this.pharmacySelected(this.pharmacies[0]);
			}
		}

		public onReasonChange(value)
		{
			this.appointmentReason = value;
		}

		public async bookAppointment()
		{
			this.loadingQueue.pushLoadingState();
			let queue = null;
			try
			{
				// get odb queue
				queue = (await AqsPatientAPI().getOdbQueue(OrganizationStore.organizationProfile.primary_clinic_id)).data;

				// check for scheduled appointments that the user might want to rejoin
				const activeScheduledAppointment = (await this.getMostRecentScheduledQueuedAppointment());
				if (activeScheduledAppointment != null)
				{
					const rejoin = await this.showExistingScheduledAppointmentAlert(activeScheduledAppointment, queue);
					if (rejoin)
					{
						// user has rejoined the existing session
						return;
					}
				}

				// book a new AQS queued appointment
				const bookingDto =
					{
						reason: this.appointmentReason,
					} as AqsQueuedAppointmentBookDto;

				if (this.selectedPharmacy)
				{
					bookingDto.notes = `Pharmacy: ${this.selectedPharmacy.name || ""} \n` +
						`Address: ${this.selectedPharmacy.address_1 || ""} ${this.selectedPharmacy.city || ""} ` +
						`${this.selectedPharmacy.address_province_code || ""}, ` +
						`${this.selectedPharmacy.postal_code || ""} \n` +
						`Fax: ${this.selectedPharmacy.primary_fax || ""} \n` +
						`Phone: ${this.selectedPharmacy.primary_phone || ""}`;
				}

				const queuedAppointment = (await AqsPatientAPI().createAppointment(
					OrganizationStore.organizationProfile.primary_clinic_id, queue.id, bookingDto)).data;

				this.$mhaRouterPush({
					name: this.Route.odbBookingRoute.Telehealth,
					params: {
						organizationId: this.organizationId,
						queuedAppointmentId: queuedAppointment.id,
						queueId: queue.id,
					},
				});
			}
			catch (err)
			{
				const error: ErrorResponse = err;

				if (!error.isAuthError && !error.is(ErrorCode.SessionExpired))
				{
					if (error.code === ErrorCode.AqsQueueCapacity)
					{
						this.handleFullQueue(error.message);
					}
					else if (error.code === ErrorCode.AqsQueueAvailability)
					{
						this.handleQueueNotAvailable(queue);
					}
					else if (error.code === ErrorCode.AqsDuplicateBooking)
					{
						if (queue)
						{
							const existingQueuedAppointments = (await AqsPatientAPI().getQueuedAppointments(
								OrganizationStore.organizationProfile.primary_clinic_id, queue.id)).data;

							if (existingQueuedAppointments && existingQueuedAppointments.length > 0)
							{
								this.handleDuplicateBooking(existingQueuedAppointments[0], queue);
							}
						}
					}
					else
					{
						this.handleBookingError(error.message);
					}
				}
			}
			finally
			{
				this.loadingQueue.popLoadingState();
			}
		}

		public logout()
		{
			const {organizationId} = this;
			this.$logout({
				name: this.Route.Public.Organization.Home,
				params: {organizationId},
			});
		}

		public handleBookingError(message, severity = NotificationSeverity.Critical, title = this.$mhat("OnDemandBookingReason.BookingErrorTitle"))
		{
			WebNotification.$emit({
				event: NotifyEvent.Generic,
				type: NotificationType.Swipe,
				severity,
				title,
				message,
			});
		}

		public handleDuplicateBooking(existingQueuedAppointment: AqsAppointmentDto, queue: AqsQueueDto): void
		{
			WebNotification.$emit({
				event: NotifyEvent.Generic,
				type: NotificationType.Confirm,
				severity: NotificationSeverity.Reminder,
				title: this.$mhat("OnDemandBookingReason.DuplicateBookingErrorTitle"),
				message: this.$mhat("OnDemandBookingReason.DuplicateBookingErrorMessage"),
				confirm: {
					message: this.$mhat("OnDemandBookingReason.RejoinButtonText"),
					callback: () =>
					{
						this.$mhaRouterPush(
							{
								name: this.Route.odbBookingRoute.Telehealth,
								params: {
									organizationId: this.organizationId,
									queuedAppointmentId: existingQueuedAppointment.id,
									queueId: queue.id,
								},
							});
					},
				},
				dismiss: {
					message: this.$mhat("OnDemandBookingReason.CancelButtonText"),
					callback: async () =>
					{
						await AqsPatientAPI().dequeueQueuedAppointment(OrganizationStore.organizationProfile.primary_clinic_id,
							queue.id,
							existingQueuedAppointment.id);
						await this.bookAppointment();
					},
				},
			});
		}

		public handleFullQueue(message): void
		{
			WebNotification.$emit({
				event: NotifyEvent.Generic,
				type: NotificationType.Confirm,
				severity: NotificationSeverity.Warning,
				title: this.$mhat("OnDemandBookingReason.FullQueueErrorTitle"),
				message,
				confirm: {
					message: this.$mhat("OnDemandBookingReason.TryAgainButtonText"),
					callback: () =>
					{
						this.bookAppointment();
					},
				},
			});
		}

		public handleQueueNotAvailable(queue): void
		{
			WebNotification.$emit({
				event: NotifyEvent.Generic,
				type: NotificationType.Confirm,
				severity: NotificationSeverity.Warning,
				title: this.$mhat("OnDemandBookingReason.ClosedClinicErrorTitle"),
				message: this.$mhat("OnDemandBookingReason.ClosedClinicErrorMessage"),
				confirm: {
					message: this.$mhat("OnDemandBookingReason.SeeClinicHoursButtonText"),
					callback: async () =>
					{
						const modal = await modalController.create(
							{
								component: OnDemandBookingHoursModal,
								cssClass: "medium-modal",
								componentProps: {
									queue,
								},
							});

						await modal.present();
					},
				},
			});
		}

		// get most recent (SCHEDULED_APPOINTMENT_SEARCH_RANGE hour range) queued appointment for the user.
		private async getMostRecentScheduledQueuedAppointment(): Promise<ClinicAppointmentTransfer>
		{
			const clinicAppointments =
				(await PatientAPI().patientMhaClinicAppointments(moment().utc().subtract(this.SCHEDULED_APPOINTMENT_SEARCH_RANGE, "hours").toISOString(),
					moment().utc().add(this.SCHEDULED_APPOINTMENT_SEARCH_RANGE, "hours").toISOString())).data;

			const queuedClinicAppointments = [];
			for (const clinicAppointment of clinicAppointments)
			{
				if (clinicAppointment.is_queued_appointment)
				{
					queuedClinicAppointments.push(clinicAppointment);
				}
			}

			if (queuedClinicAppointments.length > 0)
			{
				queuedClinicAppointments.sort((first, second) =>
				{
					return moment(second.created_at).diff(moment.utc()) - moment(first.created_at).diff(moment().utc());
				});
				return queuedClinicAppointments[0];
			}

			return null;
		}

		// Alert the user of a duplicate booking. resolving true if they decided to rejoin false if they decided not to.
		private showExistingScheduledAppointmentAlert(queuedClinicAppointment, queue): Promise<boolean>
		{
			return new Promise((resolve, reject) =>
			{
				const appointmentDateTime = moment(queuedClinicAppointment.start_datetime_utc).local();

				WebNotification.$emit({
					event: NotifyEvent.Generic,
					type: NotificationType.Confirm,
					severity: NotificationSeverity.Reminder,
					title: this.$mhat("OnDemandBookingReason.DuplicateBookingErrorTitle"),
					message: this.$mhat("OnDemandBookingReason.AppointmentAlreadyBookedErrorMessage",
						{appointmentDate: DateUtils.humanDateTimeString(appointmentDateTime)}),
					confirm: {
						message: this.$mhat("OnDemandBookingReason.RejoinButtonText"),
						callback: () =>
						{
							this.$mhaRouterPush(
								{
									name: this.Route.odbBookingRoute.Telehealth,
									params: {
										organizationId: this.organizationId,
										queuedAppointmentId: queuedClinicAppointment.queued_appointment_id,
										queueId: queue.id,
									},
								});
							resolve(true);
						},
					},
					dismiss: {
						message: this.$mhat("OnDemandBookingReason.BookNewAppointmentButtonText"),
						callback: () =>
						{
							resolve(false);
						},
					},
				});
			});
		}

		get pharmacySelectDisabled()
		{
			return (this.selectedPharmacy && this.singlePharmacyMode);
		}

		get canBook(): boolean
		{
			return !this.loadingQueue.isLoading &&
				(this.appointmentReason.length > 0) &&
				(this.selectedPharmacy !== null || OrganizationStore.organizationPharmacies.length === 0);
		}

		get pharmacies()
		{
			if (OrganizationStore.organizationPharmacies)
			{
				let pharmacies = OrganizationStore.organizationPharmacies.map((pharmacy) =>
				{
					return {
						name: pharmacy.name,
						value: pharmacy,
					};
				});

				// filter out pharmacies not in the selected city
				if (this.hasMultipleCities)
				{
					pharmacies = pharmacies.filter((pharmacy) => pharmacy.value.city === this.selectedCity);
				}

				return pharmacies.sort((first, second) => first.name.localeCompare(second.name));
			}
			return [];
		}

		get cities()
		{
			if (OrganizationStore.organizationPharmacies)
			{
				return Array.from(new Set(OrganizationStore.organizationPharmacies.map((pharmacy) => pharmacy.city))).sort();
			}
		}

		// get selected city as option input. dropdowns expect this for some reason?
		get selectedCityAsOption()
		{
			if (this.selectedCity)
			{
				return {
					name: this.selectedCity,
					value: this.selectedCity,
				};
			}
			else
			{
				return null;
			}
		}

		get cityOptions()
		{
			return this.cities.map((city) =>
			{
				return {value: city, name: city};
			});
		}

		get hasMultipleCities()
		{
			return this.cities.length > 1;
		}

		get singlePharmacyMode()
		{
			return (this.pharmacies.length <= 1);
		}

		get messageText()
		{
			let messageText;
			if (this.singlePharmacyMode && !this.hasMultipleCities)
			{
				messageText = this.$mhat("OnDemandBookingReason.reasonPromptSinglePharmacy");
			}
			else
			{
				messageText = this.$mhat("OnDemandBookingReason.reasonPromptMultiPharmacy");
			}
			return messageText;
		}

		get heroImageHeight()
		{
			if (window.innerHeight < 900)
			{
				return "1080px";
			}
			return "100vh";
		}

		get pharmacySelectPlaceholderText(): string
		{
			return this.$mhat("OnDemandBookingReason.bookingLocationSelectLabel");
		}
	}
