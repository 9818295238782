

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";

	@Options({})
	export default class ClinicInfoContainer extends Vue
	{
		@Prop({type: Boolean}) constrainWidth: boolean;
		@Prop({type: Boolean}) contentAlignLeft: boolean;

		get conditionalClasses()
		{
			const {constrainWidth, contentAlignLeft} = this;
			const classList = [];

			if (constrainWidth)
			{
				classList.push("constrain-width");
			}
			if (contentAlignLeft)
			{
				classList.push("content-align-left");
			}
			return classList.join(" ");
		}
	}
