import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrgImage = _resolveComponent("OrgImage")!
  const _component_WhitelabelHeaderBar = _resolveComponent("WhitelabelHeaderBar")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_WhitelabelContentLayout = _resolveComponent("WhitelabelContentLayout")!
  const _component_CorporateFooter = _resolveComponent("CorporateFooter")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, {
    contentWidthFull: "",
    contentBodyPaddingDisabled: "",
    contentBodyBottomMarginDisabled: "",
    backgroundImage: "",
    height: _ctx.heroImageHeight
  }, {
    background: _withCtx(() => [
      _createVNode(_component_OrgImage, {
        "asset-type": _ctx.ORG_ASSET_TYPE.ODB_HERO_IMAGE,
        "alt-image": _ctx.backgroundImage,
        "atl-text": "Hero Image"
      }, null, 8, ["asset-type", "alt-image"])
    ]),
    "content-header": _withCtx(() => [
      _createVNode(_component_WhitelabelHeaderBar, { "organization-id": _ctx.organizationId }, null, 8, ["organization-id"])
    ]),
    "content-top": _withCtx(() => [
      _createVNode(_component_WhitelabelContentLayout, {
        title: _ctx.titleText,
        text: this.$mhat('WhitelabelLogin.HeaderText'),
        "logo-light": "",
        "center-content": true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LoginForm, {
            "clinic-id": _ctx.clinicId,
            "forgot-password-route": _ctx.forgotPasswordRoute,
            onSuccess: _ctx.onLoginSuccess,
            onSignup: _ctx.onSignupRequest
          }, null, 8, ["clinic-id", "forgot-password-route", "onSuccess", "onSignup"])
        ]),
        _: 1
      }, 8, ["title", "text"])
    ]),
    "content-bottom": _withCtx(() => [
      _createVNode(_component_CorporateFooter, {
        branded: "",
        "brand-white-bg": "",
        "show-support-link": false
      })
    ]),
    _: 1
  }, 8, ["height"]))
}