

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {LOGO_BRAND} from "@/assets/AppIcons";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

	@Options({})
	export default class HeaderBarForgot extends Vue
	{
		@Prop({type: String}) headerText: string;

		// ==========================================================================
		// Getters
		// ==========================================================================

		get headerProps(): any
		{
			return {
				headerIcon: LOGO_BRAND,
				headerText: this.headerText ? this.headerText : this.$mhat("HeaderBarForgot.HeaderText"),
				hasClose: false,
				backRoute: this.backRouteHref,
			};
		}

		get backRouteHref(): string
		{
			return ((OrganizationStore.hasOrganization)
				? "/org/" + OrganizationStore.organizationProfile.url_friendly_name : "") + "/login";
		}
	}
