import {RouteAuthType} from "@/router/types";
import uuidv4 from "uuid";
import {RouteRecordRaw} from "vue-router";

const DependentsHome = () => import(
	/* webpackChunkName: "Dependents" */ "@/views/patient_user/dependent/DependentsHome.vue");
const DependentsProfile = () => import(
	/* webpackChunkName: "Dependents" */ "@/views/patient_user/dependent/DependentsProfile.vue");

export enum OrgDependentsRoute
{
	Home = "OrgDependentsHome",
	AddDependent = "OrgAddDependent",
	ViewDependent = "OrgViewDependent",
	FindClinic = "OrgDependentsFindClinic",
}

export const orgDependentRoutes: RouteRecordRaw[] = [
	{
		path: "/org/:organizationId/dependents",
		name: OrgDependentsRoute.Home,
		component: DependentsHome,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/dependents/add",
		name: OrgDependentsRoute.AddDependent,
		component: DependentsProfile,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			routeId: uuidv4(), // Forces Vue to rerender routes that load the same component
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/dependents/profile/:dependentId",
		name: OrgDependentsRoute.ViewDependent,
		component: DependentsProfile,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			routeId: uuidv4(),
			brandedRoute: true,
		},
	},
	{
		path: "/org/:organizationId/dependents/:dependentId/clinics/find",
		name: OrgDependentsRoute.FindClinic,
		component: DependentsProfile,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
