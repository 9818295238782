import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/lib/vuex/store";
import {
	OrganizationAssetDto,
	OrganizationConfiguration,
	OrganizationFontType,
	OrganizationProfileDto,
	OrganizationTextDto,
	PharmacyDto,
} from "@/open_api/generated";
import {PublicAPI} from "@/lib/services/Api";
import {clearColorOverride, clearColorRangeOverride, overrideColor, setColorRange} from "@/lib/utils/Color";
import OrganizationFont from "@/lib/organization/model/OrganizationFont";
import OrganizationFontDtoToOrganizationFontConverter
	from "@/lib/organization/conversion/OrganizationFontDtoToOrganizationFontConverter";
import {clearFontOverride, deleteFontFromDOM, overrideFont} from "@/lib/utils/Fonts";

export enum ORG_ASSET_TYPE
{
	LOGO = "logo",
	LOGO_LIGHT = "logo_light",
	ODB_HERO_IMAGE = "odb_hero_image",
}

@Module({dynamic: true, store, name: "organizationStore", namespaced: true})
class OrganizationStore extends VuexModule
{
	_organizationProfile: OrganizationProfileDto = null;
	_organizationAssets: OrganizationAssetDto[] = [];
	_organizationPharmacies: PharmacyDto[] = [];
	_organizationTexts: OrganizationTextDto[] = [];
	_organizationFonts: OrganizationFont[] = [];

	// ==========================================================================
	// Actions
	// ==========================================================================

	@Action({rawError: true})
	public clearOrganization()
	{
		this.setOrganizationProfile(null);
		this.setOrganizationAssets([]);
		this.setOrganizationPharmacies([]);
		this.setOrganizationFonts([]);
	}

	@Action({rawError: true})
	public applyOrganizationColors(): void
	{
		if (this.organizationProfile)
		{
			// primary color
			if (this.organizationProfile.primary_color)
			{
				setColorRange(this.organizationProfile.primary_color, "--color-primary");
				overrideColor(this.organizationProfile.primary_color, "--ion-color-primary");
				// legacy
				setColorRange(this.organizationProfile.primary_color, "--color-whitelabel-primary");
			}
			else
			{
				clearColorRangeOverride("--color-primary");
				clearColorOverride("--ion-color-primary");
				// legacy
				clearColorRangeOverride("--color-whitelabel-primary");
			}

			// secondary color
			if (this.organizationProfile.secondary_color)
			{
				setColorRange(this.organizationProfile.secondary_color, "--color-secondary");
				// legacy
				setColorRange(this.organizationProfile.secondary_color, "--color-whitelabel-secondary");
			}
			else
			{
				clearColorRangeOverride("--color-secondary");
				// legacy
				clearColorRangeOverride("--color-whitelabel-secondary");
			}

			// background color
			if (this.organizationProfile.background_color)
			{
				overrideColor(this.organizationProfile.background_color, "--app-bg");
			}
			else
			{
				clearColorOverride("--app-bg");
			}
		}
	}

	@Action({rawError: true})
	public async applyOrganizationFonts(): Promise<void>
	{
		if (this.organizationProfile)
		{
			// regular font
			if (await this.hasFont(OrganizationFontType.Regular))
			{
				overrideFont(await this.getFontByType(OrganizationFontType.Regular), "--app-font-regular");
			}
			else
			{
				clearFontOverride("--app-font-regular");
				deleteFontFromDOM(OrganizationFontType.Regular as string);
			}

			// bold font
			if (await this.hasFont(OrganizationFontType.Bold))
			{
				overrideFont(await this.getFontByType(OrganizationFontType.Bold), "--app-font-bold");
			}
			else
			{
				clearFontOverride("--app-font-bold");
				deleteFontFromDOM(OrganizationFontType.Bold as string);
			}

			// italic font
			if (await this.hasFont(OrganizationFontType.Italic))
			{
				overrideFont(await this.getFontByType(OrganizationFontType.Italic), "--app-font-italic");
			}
			else
			{
				clearFontOverride("--app-font-italic");
				deleteFontFromDOM(OrganizationFontType.Italic as string);
			}
		}
	}

	@Action({rawError: true})
	public async loadOrganization(organizationId: string)
	{
		return Promise.all(
			[
				this.loadOrganizationProfile(organizationId),
				this.loadOrganizationAssets(organizationId),
				this.loadOrganizationFonts(organizationId),
			]);
	}

	@Action({rawError: true})
	public async loadOrganizationProfile(organizationId: string): Promise<void>
	{
		const orgProfile: OrganizationProfileDto = (await PublicAPI().getOrganizationProfile(organizationId)).data;
		orgProfile.locale_overrides = JSON.parse(orgProfile.locale_overrides);
		this.setOrganizationProfile(orgProfile);
	}

	@Action({rawError: true})
	public async loadOrganizationAssets(organizationId: string): Promise<void>
	{
		this.setOrganizationAssets((await PublicAPI().getOrganizationAssets(organizationId)).data);
	}

	@Action({rawError: true})
	public async loadOrganizationPharmacies(organizationId: string): Promise<void>
	{
		this.setOrganizationPharmacies((await PublicAPI().getOrganizationPharmacies(organizationId)).data);
	}

	@Action({rawError: true})
	public async loadOrganizationFonts(organizationId: string): Promise<void>
	{
		this.setOrganizationFonts((new OrganizationFontDtoToOrganizationFontConverter())
			                          .convertList((await PublicAPI().getOrganizationFonts(organizationId)).data));
	}

	@Action({rawError: true})
	public hasAsset(assetType: string): boolean
	{
		const orgAsset = this._organizationAssets?.find((asset) => asset.asset_type === assetType);
		return !!orgAsset;
	}

	@Action({rawError: true})
	public async hasFont(fontType: OrganizationFontType): Promise<boolean>
	{
		return !!(this._organizationFonts.find((font) => font.type === fontType));
	}

	@Action({rawError: true})
	public async getFontByType(fontType: OrganizationFontType): Promise<FontFace>
	{
		const orgFont = this._organizationFonts.find((font) => font.type === fontType);
		if (orgFont)
		{
			return new FontFace(orgFont.type, `url(${orgFont.url.toString()})`);
		}
		return null;
	}

	// ==========================================================================
	// Mutations
	// ==========================================================================

	@Mutation
	public setOrganizationProfile(organizationProfile: OrganizationProfileDto): void
	{
		this._organizationProfile = organizationProfile;
	}

	@Mutation
	public setOrganizationAssets(organizationAssetDto: OrganizationAssetDto[]): void
	{
		this._organizationAssets = organizationAssetDto;
	}

	@Mutation
	public setOrganizationPharmacies(organizationPharmacies: PharmacyDto[]): void
	{
		this._organizationPharmacies = organizationPharmacies;
	}

	@Mutation
	public setOrganizationFonts(organizationFonts: OrganizationFont[]): void
	{
		this._organizationFonts = organizationFonts;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get organizationProfile(): OrganizationProfileDto
	{
		return this._organizationProfile;
	}

	get hasOrganization(): boolean
	{
		return Boolean(this._organizationProfile);
	}

	get isWhiteLabel(): boolean
	{
		return this.hasOrganization;
	}

	get organizationAssets(): OrganizationAssetDto[]
	{
		return this._organizationAssets;
	}

	get organizationPharmacies(): PharmacyDto[]
	{
		return this._organizationPharmacies;
	}

	get organizationConfiguration(): OrganizationConfiguration
	{
		return this.organizationProfile?.configuration;
	}

	get organizationLocaleOverrides(): any
	{
		return this.organizationProfile?.locale_overrides;
	}

	get organizationFonts(): OrganizationFont[]
	{
		return this._organizationFonts;
	}
}

export default getModule(OrganizationStore);
