import {ProfileController} from "@/views/patient_user/profile/controllers/ProfileController";
import {Patient} from "@/lib/patient/Patient";
import {DependentAPI, PatientAPI} from "@/lib/services/Api";
import {AxiosPromise} from "axios";
import {PatientUserDto} from "@/open_api/generated";

export class DependentProfileController extends ProfileController
{
	constructor(patient: Patient)
	{
		super();
		this.userProfile = Patient.copyConstructor(patient);
	}

	public onSave(): AxiosPromise<PatientUserDto>
	{
		const transferObj = {
			profile: this.userProfile.toProfileTransferObj(),
		};

		if (this.isEditMode())
		{
			return DependentAPI().updateDependent(this.profileId, transferObj);
		}
		else
		{
			return PatientAPI().addDependent(transferObj);
		}
	}

	public isEditMode(): boolean
	{
		return Boolean(this.profileId);
	}

	get profileId()
	{
		if (this.userProfile)
		{
			return this.userProfile.id;
		}
	}

	set profileId(value: any)
	{
		if (this.userProfile)
		{
			this.userProfile.id = value;
		}
	}

	protected getProfile(): Patient
	{
		return this.userProfile;
	}
}
