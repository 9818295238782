import TelehealthApiServiceInterface from "@/lib/telehealth/api/TelehealthApiServiceInterface";
import {SessionData} from "@/lib/telehealth/models/sessionData.model";
import {BrowserInfo} from "detect-browser";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import LogEvent from "@/lib/telehealth/models/LogEvent";
import {TeleCallState} from "@/lib/telehealth/models/telehealth.types";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import {AqsClinicUserAPI, AqsPatientAPI, ClinicAPI} from "@/lib/services/Api";
import AuthStore from "@/lib/vuex/auth.store";
import ChatMessageToTelehealthChatMessageDtoConverter from "@/lib/telehealth/conversion/ChatMessageToTelehealthChatMessageDtoConverter";
import {TelehealthChatMessageDto} from "@/open_api/generated";
import TelehealthChatMessageDtoToChatMessageConverter from "@/lib/telehealth/conversion/TelehealthChatMessageDtoToChatMessageConverter";

export default class ClinicUserAqsTelehealthApiService implements TelehealthApiServiceInterface
{
	protected _clinicId: string;
	protected _queueId: string;
	protected _queuedAppointmentId: string;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * build aqs telehealth api
	 * @param clinicId - the clinic the telehealth appt is for
	 * @param queueId - the queue the AQS appointment is in
	 * @param queuedAppointmentId - the queued appointment to connect to.
	 */
	public constructor(clinicId: string, queueId: string, queuedAppointmentId: string)
	{
		this._clinicId = clinicId;
		this._queueId = queueId;
		this._queuedAppointmentId = queuedAppointmentId;
	}

	// ==========================================================================
	// TelehealthApiServiceInterface Implementation
	// ==========================================================================

	public async fetchSessionData(): Promise<SessionData>
	{
		return new SessionData((await AqsClinicUserAPI().getSession(this._queueId, this._queuedAppointmentId)).data);
	}

	public async getLocalClientData(browserInfo: BrowserInfo): Promise<TelehealthClient>
	{
		const user = AuthStore.loggedInUser;

		return new TelehealthClient(
			browserInfo,
			user.email,
			user.firstName,
			user.lastName,
			user.userType,
		);
	}

	public async logEvent(event: LogEvent): Promise<void>
	{
		AqsClinicUserAPI().logEventToProviderAqsTelehealthSession(
			this._queueId,
			this._queuedAppointmentId,
			event.asTelehealthLogEventDto);
	}

	public async pingSession(callState: TeleCallState): Promise<void>
	{
		// clinic users do not ping sessions.
	}

	public async recordChatMessage(message: ChatMessage): Promise<ChatMessage>
	{
		const chatMessageDto: TelehealthChatMessageDto =
			(await AqsClinicUserAPI().addClinicUserChatMessageToQueuedAppointment(
				this._queueId,
				this._queuedAppointmentId,
				new ChatMessageToTelehealthChatMessageDtoConverter().convert(message))).data;
		return new TelehealthChatMessageDtoToChatMessageConverter().convert(chatMessageDto);
	}

	/**
	 * load chat history from DB.
	 * @return array of chat messages for the telehealth appointment.
	 */
	public async getChatHistory(): Promise<ChatMessage[]>
	{
		return new TelehealthChatMessageDtoToChatMessageConverter()
			.convertList((await AqsClinicUserAPI().getClinicUserQueuedAppointmentChatMessages(
				this._queueId,
				this._queuedAppointmentId)).data);
	}
}
