import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PatientDataSpinner = _resolveComponent("PatientDataSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Notifier = _resolveComponent("Notifier")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: _normalizeClass({'cloud-md': $data.isCloudMd})
  }, [
    _createVNode(_component_ion_app, { class: "style-override" }, {
      default: _withCtx(() => [
        ($data.isLoading || $data.dataInitError)
          ? (_openBlock(), _createBlock(_component_PatientDataSpinner, {
              key: 0,
              class: "patient-data-spinner",
              "is-loading": $data.isLoading,
              "is-error": $data.dataInitError !== null
            }, null, 8, ["is-loading", "is-error"]))
          : (_openBlock(), _createBlock(_component_router_view, {
              key: $data.routeId,
              animated: false
            })),
        _createVNode(_Transition, {
          name: "slide",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            ($data.notification)
              ? (_openBlock(), _createBlock(_component_Notifier, {
                  notification: $data.notification,
                  key: $data.notificationId,
                  onDismiss: $options.dismissNotification,
                  onConfirm: $options.confirmNotification
                }, null, 8, ["notification", "onDismiss", "onConfirm"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}