import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_ContactClinicRouteButton = _resolveComponent("ContactClinicRouteButton")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, {
        "header-text": _ctx.$mhat('BookingNextAvailability.NextAvailability'),
        "has-close": $props.hasClose,
        "no-back": $props.noBack
      }, null, 8, ["header-text", "has-close", "no-back"])
    ]),
    _: 2
  }, [
    ($data.showFindProviderSpinner)
      ? {
          name: "content-center",
          fn: _withCtx(() => [
            _createVNode(_component_EventStateSpinner, {
              "is-loading": $data.showFindProviderSpinner,
              "loading-icon": "icon-user-md",
              "loading-title": _ctx.$mhat('BookingNextAvailability.FindingProvidersLoadingText')
            }, null, 8, ["is-loading", "loading-title"])
          ])
        }
      : ($options.showFindAppointmentsSpinner)
        ? {
            name: "content-center",
            fn: _withCtx(() => [
              _createVNode(_component_EventStateSpinner, {
                "is-loading": $options.isLoading,
                "loading-icon": "icon-calendar-add",
                "loading-title": _ctx.$mhat('BookingNextAvailability.LoadingTitle'),
                "show-failure-state": $options.noAppointments,
                "failure-icon": "icon-calendar-add",
                "failure-title": $options.failure.title,
                "failure-message": $options.failure.message
              }, null, 8, ["is-loading", "loading-title", "show-failure-state", "failure-title", "failure-message"]),
              ($options.noAppointments)
                ? (_openBlock(), _createElementBlock("div", { key: 0 }, [
                    _createVNode(_component_ContactClinicRouteButton, { clinicId: $props.clinicId }, null, 8, ["clinicId"]),
                    _createVNode(_component_BaseButton, {
                      pattern: $data.ButtonColorPattern.TRANSPARENT,
                      color: $data.ButtonColor.PRIMARY,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.closeBooking()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$mhat("BookingNextAvailability.CloseWindow")), 1)
                      ]),
                      _: 1
                    }, 8, ["pattern", "color"])
                  ]))
                : _createCommentVNode("", true)
            ])
          }
        : {
            name: "content-top",
            fn: _withCtx(() => [
              ($options.isDependentMode)
                ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                    key: 0,
                    dependent: $options.controller.dependent
                  }, null, 8, ["dependent"]))
                : _createCommentVNode("", true),
              (!$options.controller.noAppointments && !$options.isLoading)
                ? (_openBlock(), _createElementBlock("div", { key: 1 }, [
                    _createElementVNode("p", null, _toDisplayString($options.helperText), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.availableSlots, (slot, i) => {
                      return (_openBlock(), _createBlock(_component_BaseButton, {
                        "no-text-transform": "",
                        onClick: () => $options.selectSlot(slot, i),
                        key: i + slot.startDateTime.toString()
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($options.friendlyTime(slot)), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128)),
                    _createElementVNode("div", { class: "mha text italic-font small separator" }, _toDisplayString(_ctx.$mhat("BookingNextAvailability.Or")), 1),
                    ($options.controller.provider)
                      ? (_openBlock(), _createElementBlock("div", { key: 0 }, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$mhat("BookingNextAvailability.ShowProvidersAvailabilityInstructionText",
						{providerFullNameWithPrefixSuffix: $options.controller.provider.fullNameWithPrefixSuffix()})), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("div", { key: 1 }, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$mhat("BookingNextAvailability.GenericProvidersAvailabilityInstructionText")), 1)
                        ])),
                    _createVNode(_component_RouteButton, { route: $options.calendarRoute }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$mhat("BookingNextAvailability.ShowMe")), 1)
                      ]),
                      _: 1
                    }, 8, ["route"]),
                    (this.rescheduleRemoteAppointmentId)
                      ? (_openBlock(), _createBlock(_component_BaseButton, {
                          key: 2,
                          onClick: _cache[1] || (_cache[1] = () => $options.providerGroupRoute()),
                          pattern: $data.ButtonColorPattern.TRANSPARENT
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$mhat("BookingNextAvailability.SelectAnotherProviderButtonText")), 1)
                          ]),
                          _: 1
                        }, 8, ["pattern"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          }
  ]), 1024))
}