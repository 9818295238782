import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ba2b108"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mha-date-selector" }
const _hoisted_2 = { class: "col day" }
const _hoisted_3 = { class: "col month" }
const _hoisted_4 = { class: "col year" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectMenu = _resolveComponent("SelectMenu")!
  const _component_input_layout = _resolveComponent("input-layout")!

  return (_openBlock(), _createBlock(_component_input_layout, {
    label: _ctx.label,
    "for-input-id": _ctx.label,
    "hide-label": _ctx.hideLabel,
    "label-style": {zIndex: _ctx.zIndex},
    disabled: _ctx.disabled,
    required: _ctx.required,
    "is-valid": _ctx.isValid,
    "validation-message": _ctx.invalidText,
    pristine: _ctx.pristine,
    "no-padding": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SelectMenu, {
            "hide-label": "",
            "hide-validation-message": "",
            id: _ctx.id + '-day',
            compact: _ctx.compact,
            placeholder: this.$mhat('DateSelector.DayPlaceHolder'),
            valid: _ctx.isValid,
            required: _ctx.required,
            selected: _ctx.selectedDay,
            options: _ctx.dayOptions,
            externalPristine: _ctx.pristine,
            disabled: _ctx.disabled,
            onSelection: _ctx.onDayChange
          }, null, 8, ["id", "compact", "placeholder", "valid", "required", "selected", "options", "externalPristine", "disabled", "onSelection"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SelectMenu, {
            "hide-label": "",
            "hide-validation-message": "",
            id: _ctx.id + '-month',
            compact: _ctx.compact,
            placeholder: this.$mhat('DateSelector.MonthPlaceholder'),
            valid: _ctx.isValid,
            required: _ctx.required,
            selected: _ctx.selectedMonth,
            options: _ctx.monthOptions,
            externalPristine: _ctx.pristine,
            disabled: _ctx.disabled,
            onSelection: _ctx.onMonthChange
          }, null, 8, ["id", "compact", "placeholder", "valid", "required", "selected", "options", "externalPristine", "disabled", "onSelection"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SelectMenu, {
            "hide-label": "",
            "hide-validation-message": "",
            id: _ctx.id + '-year',
            compact: _ctx.compact,
            placeholder: this.$mhat('DateSelector.YearPlaceholder'),
            valid: _ctx.isValid,
            required: _ctx.required,
            selected: _ctx.selectedYear,
            options: _ctx.yearOptions,
            externalPristine: _ctx.pristine,
            disabled: _ctx.disabled,
            "default-autoscroll-value": _ctx.endDate.year(),
            onSelection: _ctx.onYearChange
          }, null, 8, ["id", "compact", "placeholder", "valid", "required", "selected", "options", "externalPristine", "disabled", "default-autoscroll-value", "onSelection"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["label", "for-input-id", "hide-label", "label-style", "disabled", "required", "is-valid", "validation-message", "pristine"]))
}