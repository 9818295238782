
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import AppointmentService from "@/lib/services/Appointment";
	import BookingController from "@/views/patient_user/booking/BookingController";
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import {isBlank} from "@/lib/utils/prototype/String";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {BookSlotData, ErrorCode} from "@/open_api/generated";
	import {Route} from "@/router/router";
	import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";
	import PatientAppointmentService from "@/lib/appointment/service/PatientAppointmentService";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";
	import BaseButton from "@/components/Buttons/BaseButton.vue";

	export default {
		name: "BookingFinal",

		props: {
			appointmentId: String,
			clinicId: String,
			providerOrGroupId: String,
			appointmentTypeId: String,
			appointmentDate: String,
			dependentId: String,
			rescheduleRemoteAppointmentId: String,
		},

		data()
		{
			return {
				isLoading: true,
				errors: [],
				ButtonColor,
				ButtonColorPattern,
				errorMessage: this.$mhat("BookingFinal.FailureText"),
			};
		},

		mounted()
		{
			const controller: BookingController = this.controller;

			if (!controller || !controller.selectedSlot || controller.appointmentBooked)
			{
				this.$mhaRouterPush(Route.Home);
			}
			else
			{
				this.beginBooking();
			}
		},

		methods: {

			async beginBooking()
			{
				const {clinicId, providerOrGroupId, appointmentTypeId} = this.controller;
				const reason = this.controller.reason;
				const slotData: BookSlotData = { datetime: this.controller.selectedSlot.startDateTime, reason };

				// Reschedule the appointment
				if (this.rescheduleRemoteAppointmentId)
				{
					try
					{
						const patientAppointmentService = new PatientAppointmentService();

						await patientAppointmentService.rescheduleAppointment(this.dependentId,
							this.rescheduleRemoteAppointmentId,
							this.clinicId,
							providerOrGroupId,
							appointmentTypeId,
							slotData);

						this.bookingSuccess();
					}
					catch (errorResponse)
					{
						this.errorMessage = this.$mhat("BookingFinal.RescheduleFailureText");
						this.bookingFailure(errorResponse);
					}
					finally
					{
						this.clearAppointmentStore();
					}
				}
				else
				{
					try
					{
						if (this.isDependentMode)
						{
							await AppointmentService.bookDependentAppointment(this.dependentId, clinicId, providerOrGroupId, appointmentTypeId, slotData);
						}
						else
						{
							await AppointmentService.bookAppointment(clinicId, providerOrGroupId, appointmentTypeId, slotData);
						}

						this.bookingSuccess();
					}
					catch (errorResponse)
					{
						this.errorMessage = this.$mhat("BookingFinal.FailureText");
						this.bookingFailure(errorResponse);
					}
					finally
					{
						this.clearAppointmentStore();
					}
				}
			},

			bookingSuccess()
			{
				this.controller.appointmentBooked = true;
				this.isLoading = false;
			},

			bookingFailure(errorResponse: ErrorResponse)
			{
				this.isLoading = false;
				if (errorResponse.is(ErrorCode.BookingRuleViolation))
				{
					this.errors = errorResponse.data.violations;
				}
				else
				{
					this.errors = [{message: errorResponse.message}];
				}
			},

			clearAppointmentStore()
			{
				BookingControllerStore.setBookingController(null);
			},

			/**
			 * Go back to the home page
			 */
			onBackToHome(): void
			{
				this.isLoading = true;

				const crossFrameService = new CrossFrameCommunicationService();
				if (crossFrameService.hasParentFrame)
				{
					// let any parent frames know we want to go back to the home page
					crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateToHome});
				}
				this.$mhaRouterPush(Route.Home);
			},
		},

		computed: {
			controller()
			{
				return BookingControllerStore.bookingController;
			},

			params()
			{
				const {dependentId, clinicId, providerOrGroupId, appointmentTypeId, appointmentDate, appointmentId} = this;
				return {dependentId, clinicId, providerOrGroupId, appointmentTypeId, appointmentDate, appointmentId};
			},

			isDependentMode()
			{
				return !isBlank(this.dependentId);
			},

			hasErrors()
			{
				return this.errors.length > 0;
			},

			rescheduleRoute()
			{
				const {params} = this;
				const query = {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId};
				const name = this.isDependentMode ? Route.Booking.Dependent.AppointmentTime : Route.Booking.AppointmentTime;
				return { name, params, query };
			},

			successMessage()
			{
				return this.splashScreenMode ? this.$mhat("BookingFinal.SplashScreenSuccessMessage") : this.$mhat("BookingFinal.RegularSuccessMessage");
			},

			splashScreenMode()
			{
				return OrganizationConfigurationStore.bookingCompleteShowSplashScreen;
			},
		},

		components: {BaseButton},
	};
