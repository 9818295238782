

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {LOGO_BRAND} from "@/assets/AppIcons";
	import DeviceInfo from "@/lib/DeviceInfo";

	@Options({components: {}})
	export default class EventStateSpinner extends Vue
	{
		LOGO_BRAND = LOGO_BRAND;
		@Prop({type: Boolean}) isLoading: boolean;

		@Prop({type: String}) loadingIcon: string;
		@Prop({type: String}) loadingTitle: string;
		@Prop({type: [String, Array]}) loadingMessage: string | string[];

		@Prop({type: String, default: "icon-check" }) successIcon: string;
		@Prop({type: String}) successTitle: string;
		@Prop({type: [String, Array]}) successMessage: string | string[];

		@Prop({type: Boolean}) showFailureState: boolean;
		@Prop({type: String, default: "icon-exclamation" }) failureIcon: string;
		@Prop({type: String}) failureTitle: string;
		@Prop({type: [String, Array]}) failureMessage: string | string[];

		@Prop({type: [String, Object]}) containerClass: string | any;
		@Prop({type: Boolean, default: false}) textColorLight: boolean;

		get isLoadingState()
		{
			return this.isLoading;
		}

		get isSuccessState()
		{
			return !this.isLoading && !this.showFailureState;
		}

		get isFailureState()
		{
			return !this.isLoading && this.showFailureState;
		}

		get getMessageArray()
		{
			let message = null;
			if (this.isLoadingState && this.loadingMessage)
			{
				message = this.loadingMessage;
			}
			else if (this.isSuccessState && this.successMessage)
			{
				message = this.successMessage;
			}
			else if (this.isFailureState && this.failureMessage)
			{
				message = this.failureMessage;
			}

			let messages = [];
			if (Array.isArray(message))
			{
				messages = message;
			}
			else if (message)
			{
				messages = [message];
			}
			return messages;
		}

		get getLoadingIcon()
		{
			return (this.loadingIcon) ? this.loadingIcon : DeviceInfo.getAppFontIcon();
		}
	}
