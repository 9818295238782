import {ConversationDto} from "@/open_api/generated";
import {Mail} from "@/lib/Mailbox/Mail.model";

export default class Conversation
{
	private _id: string = null;
	private _messages: Mail[] = [];

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(conversationDto: ConversationDto)
	{
		this._id = conversationDto.id;
		this._messages = conversationDto.messages.map((message) => new Mail(message));

		// sort messages. newest - oldest
		this._messages = this._messages.sort((mail1: Mail, mail2: Mail) =>
		{
			return mail2.createdAt.valueOf() - mail1.createdAt.valueOf();
		});
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get id(): string
	{
		return this._id;
	}

	get messages(): Mail[]
	{
		return this._messages;
	}

	get firstMessage(): Mail
	{
		return this.messages[0];
	}

	get lastMessage(): Mail
	{
		if (this.messages.length > 0)
		{
			return this.messages[this.messages.length - 1];
		}
		return null;
	}
}
