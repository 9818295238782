import {Moment} from "moment";
import {ClinicAppointmentType} from "@/open_api/generated";
import AuthStore from "@/lib/vuex/auth.store";
import {PatientUser} from "@/lib/patient/patientUser.model";
import DependentStore from "@/lib/vuex/modules/Dependent.store";
import {Patient} from "@/lib/patient/Patient";

export default class ClinicAppointment
{
	private readonly _id: string;
	private readonly _remoteId: string;
	private readonly _queuedAppointmentId: string;
	private readonly _patientUserId: string;
	private readonly _patientClinicAppointmentId: string;
	private readonly _isCancelled: boolean;
	private readonly _isVirtual: boolean;
	private readonly _isQueuedAppointment: boolean;
	private readonly _startDate: Moment;
	private readonly _endDate: Moment;
	private readonly _appName: string;
	private readonly _type: ClinicAppointmentType;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		id: string,
		remoteId: string,
		queuedAppointmentId: string,
		patientUserId: string,
		patientClinicAppointmentId: string,
		isCancelled: boolean,
		isVirtual: boolean,
		isQueuedAppointment: boolean,
		startDate: Moment,
		endDate: Moment,
		appName: string,
		type: ClinicAppointmentType)
	{
		this._id = id;
		this._remoteId = remoteId;
		this._queuedAppointmentId = queuedAppointmentId;
		this._patientUserId = patientUserId;
		this._patientClinicAppointmentId = patientClinicAppointmentId;
		this._isCancelled = isCancelled;
		this._isVirtual = isVirtual;
		this._isQueuedAppointment = isQueuedAppointment;
		this._startDate = startDate;
		this._endDate = endDate;
		this._appName = appName;
		this._type = type;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get id(): string
	{
		return this._id;
	}

	get remoteId(): string
	{
		return this._remoteId;
	}

	get queuedAppointmentId(): string
	{
		return this._queuedAppointmentId;
	}

	get patientUserId(): string
	{
		return this._patientUserId;
	}

	get patientClinicAppointmentId(): string
	{
		return this._patientClinicAppointmentId;
	}

	get appointmentPatient(): Patient
	{
		if (this.isDependentAppointment)
		{
			return DependentStore.dependent(this.patientUserId);
		}
		else
		{
			return AuthStore.loggedInPatient.profile;
		}
	}

	get isDependentAppointment(): boolean
	{
		return this._patientUserId !== AuthStore.loggedInUser.id;
	}

	get isCancelled(): boolean
	{
		return this._isCancelled;
	}

	get isVirtual(): boolean
	{
		return this._isVirtual;
	}

	get isQueuedAppointment(): boolean
	{
		return this._isQueuedAppointment;
	}

	get startDate(): Moment
	{
		return this._startDate;
	}

	get endDate(): Moment
	{
		return this._endDate;
	}

	get appName(): string
	{
		return this._appName;
	}

	get type(): ClinicAppointmentType
	{
		return this._type;
	}

	get isRegularType(): boolean
	{
		return this._type === ClinicAppointmentType.Regular;
	}

	get isChatType(): boolean
	{
		return this._type === ClinicAppointmentType.Chat;
	}

	get isOnDemandAudioType(): boolean
	{
		return this.type === ClinicAppointmentType.OnDemandAudioCall;
	}

	get isOneTimeLinkType(): boolean
	{
		return this._type === ClinicAppointmentType.OneTimeLink;
	}
}
