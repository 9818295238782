import StateTracker from "@/lib/telehealth/opentok/StateTracker";
import {Connection, Publisher, Session, Stream, Subscriber} from "@opentok/client";
import {OT_CUSTOM_SIGNAL, OT_EVENT_TYPE, OT_LOCAL_SIGNAL, OT_SIGNAL_ERROR_TYPE, SubscriberProperties} from "@/lib/telehealth/opentok/ot.types";
import TelehealthError, {TELEHEALTH_ERROR_TYPE} from "@/lib/telehealth/error/TelehealthError";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import EventLogger from "@/lib/telehealth/opentok/EventLogger";
import {toRaw} from "vue";

/**
 * responsible for sending messages to remote clients in the session
 */
export default class SessionCommunicator
{
	protected stateTracker: StateTracker;
	protected otSession: Session = null;
	protected onLogEventCallbacks: CallbackCollection = new CallbackCollection();

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(stateTracker: StateTracker, onLogEventCallbacks: CallbackCollection)
	{
		this.stateTracker = stateTracker;
		this.onLogEventCallbacks = onLogEventCallbacks;
	}

	public setOtSession(otSession: Session): void
	{
		this.otSession = otSession;
	}

	public clearOtSession(): void
	{
		this.otSession = null;
	}

	/**
	 * send a local signal. A local signal is not transmitted to remote clients
	 * @param type - the type of the signal
	 * @param data - the data
	 */
	public async localSignal(type: OT_LOCAL_SIGNAL, data: string): Promise<void>
	{
		const self = this.stateTracker.getSelf;

		if (self)
		{
			return await this.signal(type, data, self.connection);
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.LOGIC_ERROR, "Failed to send local signal. Cannot find self");
		}
	}

	/**
	 * broadcast a message to all connected clients
	 * @param type - the type of the signal
	 * @param data - the message to broadcast
	 */
	public async broadcast(type: OT_CUSTOM_SIGNAL, data: string): Promise<void>
	{
		return await this.signal(type, data);
	}

	/**
	 * send a message to the specified client
	 * @param type - the signal type
	 * @param data - the message
	 * @param toConnectionId - the connection id you want to send the signal to.
	 */
	public async signalByConnectionId(type: OT_CUSTOM_SIGNAL, data: string, toConnectionId: string): Promise<void>
	{
		const remoteClient = this.stateTracker.getRemoteClientByConnectionId(toConnectionId);
		if (remoteClient)
		{
			return await this.signal(type, data, remoteClient.connection);
		}
		else
		{
			throw new TelehealthError(
				TELEHEALTH_ERROR_TYPE.NO_SUCH_REMOTE,
				`The remote identified by ConnectionId: ${toConnectionId} does not exist`);
		}
	}

	/**
	 * send a message to the specified client
	 * @param type - the signal type
	 * @param data - the message
	 * @param toUserId - the userId you which to send the message to.
	 * (this may cause the message to be sent to multiple clients)
	 */
	public async signalByUserId(type: OT_CUSTOM_SIGNAL, data: string, toUserId: string): Promise<void>
	{
		const remoteClient = this.stateTracker.getRemoteClientByUserId(toUserId);
		if (remoteClient)
		{
			return await this.signal(type, data, remoteClient.connection);
		}
		else
		{
			throw new TelehealthError(
				TELEHEALTH_ERROR_TYPE.NO_SUCH_REMOTE,
				`The remote identified by UserId: ${toUserId} does not exist`);
		}
	}

	/**
	 * send a message to the specified client
	 * @param type - the signal type
	 * @param data - the message
	 * @param to - the connection of the client you wish to signal
	 */
	public signal(type: OT_CUSTOM_SIGNAL | OT_LOCAL_SIGNAL, data: string, to?: Connection): Promise<void>
	{
		return new Promise((resolve, reject) =>
		{
			const signalOptions: any = {
				type,
				data,
			};
			if (to)
			{
				signalOptions.to = to;
			}

			// during shutdown async events may attempt to signal, ignore.
			if (this.otSession.isConnected())
			{
				EventLogger.logOutboundSignal(type, data, to, this.stateTracker.getSelf, this.onLogEventCallbacks);

				this.otSession.signal(
					signalOptions,
					(error) =>
					{
						if (error)
						{
							if (error.name === OT_SIGNAL_ERROR_TYPE.NOT_CONNECTED)
							{
								console.warn(`Can't send signal ${type} due to session disconnect`);
							}
							else
							{
								throw new TelehealthError(TELEHEALTH_ERROR_TYPE.COMMUNICATION_ERROR, `${error.name}. ${error.message} On signal ${type}`);
							}
						}

						resolve();
					});
			}
			else
			{
				resolve();
			}
		});
	}

	/**
	 * stop publishing
	 * @param publisher - the publisher to un publish
	 */
	public unPublish(publisher: Publisher): void
	{
		this.otSession.unpublish(publisher);
	}

	/**
	 * start publishing
	 * @param publisher - the publisher to publish
	 */
	public publish(publisher: Publisher): Promise<void>
	{
		return new Promise((resolve, reject) =>
		{
			this.otSession.publish(publisher, (error) =>
			{
				if (error)
				{
					throw new TelehealthError(TELEHEALTH_ERROR_TYPE.STREAMING_ERROR,
						`Failed to publish stream with error: ${error.name}. ${error.message}`);
				}

				EventLogger.logOutboundSignal(
					OT_EVENT_TYPE.STREAM_CREATED,
					null,
					null,
					this.stateTracker.getSelf,
					this.onLogEventCallbacks);
				resolve();
			});
		});
	}

	/**
	 * subscribe to a AV stream
	 * @param stream - stream to subscribe to
	 * @param targetElement - element ot show the AV stream in
	 * @param properties - subscription properties
	 * @return a subscriber object
	 */
	public async subscribe(
		stream: Stream,
		targetElement: HTMLElement,
		properties: SubscriberProperties): Promise<Subscriber>
	{
		return new Promise((resolve, reject) =>
		{
			const subscriber = toRaw(this.otSession).subscribe(stream, targetElement, toRaw(properties), (error) =>
			{
				if (error)
				{
					throw new TelehealthError(TELEHEALTH_ERROR_TYPE.STREAMING_ERROR,
						`Failed to subscribe to stream with error: ${error.name}. ${error.message}`);
				}
				resolve(subscriber);
			});
		});
	}

	/**
	 * unsubscribe from an AV stream
	 */
	public unsubscribe(subscriber: Subscriber): void
	{
		this.otSession.unsubscribe(subscriber);
	}

	/**
	 * Force a remote to disconnect from the session. Only moderators can do that.
	 * @param connectionId - the remote to disconnect
	 */
	public forceDisconnect(connectionId: string): Promise<boolean>
	{
		const client = this.stateTracker.getRemoteClientByConnectionId(connectionId);

		if (client)
		{
			return new Promise((resolve, reject) =>
			{
				this.otSession.forceDisconnect(client.connection, (error) =>
				{
					if (error)
					{
						console.error(error);
						reject(false);
					}
					else
					{
						resolve(true);
					}
				});
			});
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.NO_SUCH_REMOTE, "No remote for connection ID: " + connectionId);
		}
	}
}
