

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {PatientUserDto} from "@/open_api/generated";
	import MicroButton from "@/components/Buttons/MicroButton.vue";
	import VerificationStatus from "@/views/clinic_user/appointment/components/VerificationStatus.vue";
	import {Appointment} from "@/lib/models/Appointment";

	@Options({
		components: {VerificationStatus, MicroButton},
	})
	export default class PatientInfoHeader extends Vue
	{
		@Prop({type: Object}) public appointment: Appointment;
		@Prop({type: Object}) public patient: PatientUserDto;
		@Prop({type: Object}) public remotePatient: PatientUserDto;
		@Prop({type: String}) public remoteProfileId: string;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		/**
		 * open a new browser tab with the specified url
		 * @param url
		 */
		public openBrowserTab(url: string): void
		{
			window.open(url);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get patientDisplayName(): string
		{
			return `${this.remotePatient.first_name}, ${this.remotePatient.last_name}`;
		}

		get isDependent(): boolean
		{
			return this.patient != null && this.patient.is_dependent;
		}
	}
