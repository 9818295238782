import {RouteAuthType} from "@/router/types";
import NavigatorBookingLandingPage from "@/views/patient_user/fusion/NavigatorBookingLandingPage.vue";
import {RouteRecordRaw} from "vue-router";

export enum FusionRoutes {
	NavigatorBookingLandingPage = "FusionNavigatorBookingLandingPage",
}

export const fusionRoutes: RouteRecordRaw[] = [
	{
		path: "/navigator/book/",
		name: FusionRoutes.NavigatorBookingLandingPage,
		props: true,
		component: NavigatorBookingLandingPage,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
