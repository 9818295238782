
	import {Options, Prop, Vue} from "vue-property-decorator";
	import {PublicAPI} from "@/lib/services/Api";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";

	@Options({})
	export default class AppointmentConfirmation extends Vue
	{
		@Prop(String) public readonly clinicId;
		@Prop(String) public readonly appointmentNo;

		isLoading = true;
		errorMessage: string = null;

		// ==========================================================================
		// Vue life cycle
		// ==========================================================================

		public async created()
		{
			const reminderId = this.$route.query.reminder_id as string;

			if (!reminderId)
			{
				this.isLoading = false;
				this.errorMessage = "No reminder_id specified";
			}
			else
			{
				try
				{
					await PublicAPI().clinicAppointmentConfirm(this.clinicId, this.appointmentNo, reminderId);
				}
				catch (error)
				{
					if (error instanceof ErrorResponse)
					{
						this.errorMessage = error.message;
					}
					else
					{
						this.errorMessage = "You can try again or contact the clinic for more info";
					}
				}
				finally
				{
					this.isLoading = false;
				}
			}
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public routeHome()
		{
			if (this.AuthStore.isLoggedIn())
			{
				this.$mhaRouterReplace(this.Route.Home);
			}
			else
			{
				this.$mhaRouterReplace(this.Route.Login.Home);
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get hasErrors(): boolean
		{
			return Boolean(this.errorMessage);
		}

		get showCloseButton(): boolean
		{
			return OrganizationConfigurationStore.appointmentConfirmedShowCloseButton;
		}
	}
