
	import {Options, Vue, Prop} from "vue-property-decorator";

	import BackgroundRibbon from "@/components/Cosmetic/BackgroundRibbon.vue";
	import BackgroundImage from "@/components/Cosmetic/BackgroundImage.vue";

	@Options({components: {BackgroundImage, BackgroundRibbon}})
	export default class BackgroundStyleWrapper extends Vue
	{
		// for enabling various background patterns
		@Prop({default: false, type: Boolean}) backgroundImage: boolean;
		@Prop({default: false, type: Boolean}) backgroundRibbons: boolean;

		// set hidden overflow on the background image for smaller pages.
		// this prevents the image stretching the page vertically below the content footer etc.
		// because I couldn't get this to work dynamically
		@Prop({default: false, type: Boolean}) backgroundHideOverflow: boolean;
	}

