
	import {Options, Vue, Watch} from "vue-property-decorator";
	import TelehealthBase from "@/components/telehealth/TelehealthBase.vue";
	import OpenTokTelehealth from "@/lib/telehealth/opentok/OpenTokTelehealth";
	import PageWrapper from "@/components/Layout/PageWrapper.vue";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import AlertService from "@/lib/alert/AlertService";
	import PulsingIndicator from "@/components/Cosmetic/PulsingIndicator.vue";
	import WaitingRoomOverlay from "@/views/patient_user/telehealth/components/WaitingRoomOverlay.vue";
	import OnDemandBookingTelehealthWaitingRoomOverlay from "@/views/patient_user/odb/telehealth/OnDemandBookingTelehealthWaitingRoomOverlay.vue";
	import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";

	@Options({
		components: {
			OnDemandBookingTelehealthWaitingRoomOverlay,
			AppChip,
			PageWrapper,
			ChatView,
			PulsingIndicator,
			WaitingRoomOverlay,
		},
	})
	export default class TelehealthTextChat extends TelehealthBase
	{
		public ButtonColor = ButtonColor;
		public ButtonColorPattern = ButtonColorPattern;
		public showBottomDeadSpace = false;

		// are we currently chatting with some one?
		protected chatting = false;

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async onCreated(): Promise<void>
		{
			this.telehealthEngine = new OpenTokTelehealth(false);
			this.telehealthEngine.setClientInfo(await this.getlocalClientData());

			// when some one else enters the session enter the room (hide waiting room and show chat interface).
			this.telehealthEngine.onRemoteConnected(() => this.enterRoom(), this);
		}

		public async onMounted(): Promise<void>
		{
			// initialize the telehealth engine
			await this.telehealthEngine.initialize(await this.fetchSessionData(), null, null);
			this.telehealthEngine.onRemoteStateChange(this.onRemoteClientListChange);
			this.telehealthEngine.onRemoteConnected(this.onRemoteClientListChange);
			this.telehealthEngine.onRemoteDisconnected(this.onRemoteClientListChange);
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		/**
		 * @param showConfirmationDialog - if true show an "Are you sure alert" to the user.
		 * @param routeToHomePage - [optional default true] if true the patient will be routed to the home page.
		 */
		public async onExit(showConfirmationDialog: boolean, routeToHomePage = true): Promise<void>
		{
			if (!showConfirmationDialog || await this.presentLeaveChatAlert())
			{
				await this.endCall();

				if (routeToHomePage && !OrganizationConfigurationStore.telehealthGoToOverlayOnCallExit)
				{
					this.$mhaRouterPush(this.Route.Home);
				}
			}
		}

		/**
		 * called when the list of remote clients changes.
		 */
		public onRemoteClientListChange(): void
		{
			this.chatting = this.remoteConnected();
			// Vue has trouble tracking changes in the telehealth engine. Recompute page.
			this.$forceUpdate();
		}

		/**
		 * git a comma seperated list of remote names (list of people in chat).
		 * Cannot be a getter because Vue caching will mess up reactivity
		 */
		public getRemoteNames(): string
		{
			return this.telehealthEngine.remoteClients.filter((client) => !!client.firstName && !!client.lastName)
				.map((client) => client.fullName).join(", ");
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		/**
		 * change indicator color based on remote connection status (if some one else is in the chat).
		 */
		get chatStateIndicatorClasses(): any
		{
			return {
				connected: this.chatting,
				"not-connected": !this.chatting,
			};
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		/**
		 * present an alert message to the user, asking if they are sure they want to leave the chat.
		 * @return promise that resolves to true if the patient wants to leave or false if not.
		 */
		protected async presentLeaveChatAlert(): Promise<boolean>
		{
			let leave = false;

			await AlertService.alert(
				this.$mhat("TelehealthTextChat.LeaveChatTitle"),
				this.$mhat("TelehealthTextChat.LeaveChatConfirmationMessage"),
				[
					{
						text: this.$mhat("TelehealthTextChat.Leave"),
						handler: () => leave = true,
					},
					{
						text: this.$mhat("TelehealthTextChat.Stay"),
					},
				]);

			return leave;
		}
	}
