import { Patient } from "@/lib/patient/Patient";
import { GeoLocation } from "@/lib/types/FindClinic";
import { ClinicsFindDependentController } from "@/views/patient_user/clinic/ClinicsFindDependentController";
import { ClinicsFindController } from "@/views/patient_user/clinic/ClinicsFindController";

export default class ClinicsFindControllerFactory
{
	/**
	 * create a new clinics find controller based on the type of patient
	 * @param patientUser - the patient
	 * @param restrictClinics - restrict the clinic list by controller dependent criteria.
	 * @param geoLocation - the location of the patient (used for clinic lookup).
	 */
	public static createClinicsFindControllerByPatient(
		patientUser: Patient, restrictClinics: boolean, geoLocation?: GeoLocation)
	{
		if (patientUser.isDependent)
		{
			return new ClinicsFindDependentController(patientUser, geoLocation, restrictClinics);
		}
		return new ClinicsFindController(patientUser, geoLocation);
	}
}
