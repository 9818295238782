

	import {Options, Prop, Vue} from "vue-property-decorator";
	import SignupHeaderBar from "./components/SignupHeaderBar.vue";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import SignupForm from "@/views/patient_user/login/components/SignupForm.vue";
	import LoadingQueue from "@/lib/LoadingQueue";
	import {PublicAPI} from "@/lib/services/Api";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {ProvinceCode} from "@/open_api/generated";
	import {Patient} from "@/lib/patient/Patient";
	import {ProfileController} from "@/views/patient_user/profile/controllers/ProfileController";
	import {UserProfileController} from "@/views/patient_user/profile/controllers/UserProfileController";
	import defaultBackground from "@/assets/images/graphics/common/background.jpg";
	import telehealthImage from "@/assets/images/graphics/signup/screens/telehealth-woman-baby-doctor-cropped@3x.png";
	import homeScreenIphoneImage from "@/assets/images/graphics/signup/screens/home-screen-device-iphone@3x.png";
	import appointmentScreenIphoneImage from "@/assets/images/graphics/signup/screens/appointment-screen-device-iphone@3x.png";
	import DeviceFrame from "@/views/patient_user/login/components/DeviceFrame.vue";
	import AppDownload from "@/views/patient_user/login/components/AppDownload.vue";
	import {HostType} from "@/router/types";
	import {Route} from "@/router/router";
	import PasswordController from "@/views/patient_user/login/controllers/PasswordController";
	import DeviceInfo from "@/lib/DeviceInfo";
	import HeaderLogo from "@/components/Header/HeaderLogo.vue";
	import {AppDownloadLayout} from "@/views/patient_user/login/components/types";

	import featuredInImage from "@/assets/images/cloud_md/branded-signup/news-featured.jpg";

	import iconPhone from "@/assets/images/cloud_md/branded-signup/icon-phone.png";
	import iconScope from "@/assets/images/cloud_md/branded-signup/icon-scope.png";
	import iconDoc from "@/assets/images/cloud_md/branded-signup/icon-doc.png";
	import device2 from "@/assets/images/cloud_md/branded-signup/device-2-1024x986.png";
	import reviewRating1 from "@/assets/images/cloud_md/branded-signup/review-rating-1.png";
	import reviewRating2 from "@/assets/images/cloud_md/branded-signup/review-rating-2.png";
	import reviewImage1 from "@/assets/images/cloud_md/branded-signup/review-1.png";
	import reviewImage2 from "@/assets/images/cloud_md/branded-signup/review-2.png";
	import reviewImage3 from "@/assets/images/cloud_md/branded-signup/review-3.png";

	import AppBadges from "@/components/External/AppBadges.vue";
	import HowCanWeHelpCard from "@/views/public/whitelabel/components/HowCanWeHelpCard.vue";
	import FBService from "@/lib/FB/FBService";

	@Options({
		components: {
			AppDownload,
			SignupForm,
			SignupHeaderBar,
			CorporateFooter,
			DeviceFrame,
			HeaderLogo,
			AppBadges,
			HowCanWeHelpCard,
		},
	})
	export default class SignupPageBranded extends Vue
	{
		@Prop({type: String}) clinicName: string;

		public clinic: ClinicProfile = null;
		public loadingQueue: LoadingQueue = new LoadingQueue();
		public profileController: ProfileController = new UserProfileController();
		public passwordController: PasswordController = new PasswordController();
		public backgroundImage = defaultBackground;
		public telehealthImage = telehealthImage;
		public homeScreenIphoneImage = homeScreenIphoneImage;
		public appointmentScreenIphoneImage = appointmentScreenIphoneImage;
		public AppDownloadLayout = AppDownloadLayout;

		public featuredInImage = featuredInImage;
		public device2 = device2;

		public iconPhone = iconPhone;
		public iconScope = iconScope;
		public iconDoc = iconDoc;

		public reviewRating1 = reviewRating1;
		public reviewRating2 = reviewRating2;
		public reviewImage1 = reviewImage1;
		public reviewImage2 = reviewImage2;
		public reviewImage3 = reviewImage3;

		async created()
		{
			// log page view for marketing conversion tracking.
			FBService.logCloudMdLandingPageView();

			this.profileController.userProfile = Patient.fillFromQueryString(this.$route.query);
			this.loadingQueue.pushLoadingState();
			try
			{
				// for cloudMD page only
				if (this.$isCloudMd && this.clinicName && this.clinicName.toLowerCase() === HostType.CLOUDMD)
				{
					await this.fetchCloudMd();
				}
				// for individual clinic pages in MHA
				else if (!this.$isCloudMd && this.clinicName)
				{
					await this.fetchClinic();
				}
				else
				{
					this.toErrorPage();
				}
			}
			finally
			{
				this.loadingQueue.popLoadingState();
			}
		}

		public async fetchClinic()
		{
			const {data} = await PublicAPI().getClinicByName(this.clinicName, true);
			if (data)
			{
				this.clinic = new ClinicProfile(data);
			}
			else
			{
				this.toErrorPage();
			}
		}

		public async fetchCloudMd()
		{
			this.loadingQueue.pushLoadingState();
			const provinceCode = this.profileController.userProfile.healthCareProvinceCode as ProvinceCode;

			try
			{
				const {data} = await PublicAPI().getCloudMd(provinceCode, true);
				this.clinic = new ClinicProfile(data);
			}
			finally
			{
				this.loadingQueue.popLoadingState();
			}
		}

		public onSignupSuccess()
		{
			// record landing page signup event for marketing conversion rate tracking.
			FBService.logCloudMdLandingPageSignupComplete();
		}

		public toErrorPage()
		{
			this.$mhaRouterReplace({name: Route.Public.ErrorPage});
		}

		get brandIcon()
		{
			return DeviceInfo.getAppFontIcon();
		}

		get downloadText()
		{
			return `Stay connected at home or from your mobile device.
				Available now on the App Store and Google Play.

				Access to care when you need it, CloudMD is always accepting new patients.`;
		}

		get cloudMDSuccessMessages()
		{
			const success = `An email has been sent to ${this.profileController.userProfile.email} with instructions on how to confirm your account.`;
			const step0 = "Almost done!  Your next steps:";
			const step1 = "1. Open your confirmation email";
			const step2 = "2. Confirm your account";
			const step3 = "3. Download the app";
			const step4 = "4. See a doctor";

			return [success, step0, step1, step2, step3, step4];
		}
	}
