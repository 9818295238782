import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "event-spinner-container" }
const _hoisted_2 = {
  key: 0,
  class: "p-t-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-center": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_EventStateSpinner, {
          isLoading: _ctx.loading,
          showFailureState: _ctx.failed,
          loadingIcon: "icon-clinic",
          successIcon: "icon-tele-doc",
          failureIcon: "icon-exclamation",
          loadingTitle: _ctx.$mhat('OrgProviderGroupBookingRedirect.LoadingTitle'),
          failureTitle: _ctx.failureMessage
        }, null, 8, ["isLoading", "showFailureState", "loadingTitle", "failureTitle"])
      ]),
      (_ctx.failed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_BaseButton, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onStartOver()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$mhat('OrgProviderGroupBookingRedirect.GoBackText')), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}