import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/lib/vuex/store";
import {PublicAPI} from "@/lib/services/Api";
import {PublicConfigConstants} from "@/open_api/generated";

@Module({dynamic: true, store, name: "configStore", namespaced: true})
class ConfigStore extends VuexModule
{
	_supportedProvinceCodes: string[] = [];
	_configConstants: PublicConfigConstants = {};

	@Action({rawError: true})
	public async fetchAll()
	{
		const {data} = await PublicAPI().getSupportedProvinceCodes();
		this.setProvinceCodes(data);

		const configResponse = await PublicAPI().getConfigConstants();
		this.setConfigConstants(configResponse.data);
	}

	@Mutation
	setProvinceCodes(provinceCodes: string[])
	{
		this._supportedProvinceCodes = provinceCodes;
	}

	@Mutation
	setConfigConstants(constants: PublicConfigConstants)
	{
		this._configConstants = constants;
	}

	get supportedProvinceCodes()
	{
		return this._supportedProvinceCodes;
	}

	get configConstants(): PublicConfigConstants
	{
		return this._configConstants;
	}
}

export default getModule(ConfigStore);
