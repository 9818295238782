import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {OrganizationFontDto} from "@/open_api/generated";
import OrganizationFont from "@/lib/organization/model/OrganizationFont";

export default class OrganizationFontDtoToOrganizationFontConverter extends AbstractConverter<OrganizationFontDto, OrganizationFont>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: OrganizationFontDto): OrganizationFont
	{
		return new OrganizationFont(from.id, from.asset_type, new URL(from.url));
	}
}
