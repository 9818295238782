import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
import {TelehealthClientState} from "@/lib/telehealth/models/TelehealthClient";
import TelehealthError, {TELEHEALTH_ERROR_TYPE} from "@/lib/telehealth/error/TelehealthError";

export default class SessionClientToTelehealthClientConverter extends AbstractConverter<TELEHEALTH_STATE, TelehealthClientState>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: TELEHEALTH_STATE): TelehealthClientState
	{
		switch (from)
		{
			case TELEHEALTH_STATE.NOT_CONNECTED: {
				return TelehealthClientState.NotConnected;
			}
			case TELEHEALTH_STATE.OUT_OF_ROOM: {
				return TelehealthClientState.OutOfRoom;
			}
			case TELEHEALTH_STATE.IN_ROOM: {
				return TelehealthClientState.InRoom;
			}
			case TELEHEALTH_STATE.INITIAL_STATE: {
				return TelehealthClientState.InitialState;
			}
			default: {
				throw new TelehealthError(TELEHEALTH_ERROR_TYPE.LOGIC_ERROR, "Invalid telehealth state");
			}
		}
	}
}
