

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class MessageLayoutWrapper extends Vue
	{
		@Prop({type: String}) clinicHeaderText: string;
		@Prop({type: String}) subjectHeaderText: string;
		@Prop({type: String}) bodyHeaderText: string;
		@Prop({type: String}) attachmentsHeaderText: string;

		@Prop({type: Boolean, default: true}) hasClinic: boolean;
		@Prop({type: Boolean, default: true}) hasSubject: boolean;
		@Prop({type: Boolean, default: true}) hasBody: boolean;
		@Prop({type: Boolean, default: true}) hasAttachments: boolean;
		@Prop({type: Boolean, default: false}) compose: boolean;

		get getClinicHeaderText()
		{
			return this.clinicHeaderText ? this.clinicHeaderText : this.$mhat("MessageLayoutWrapper.ClinicHeaderText");
		}

		get getSubjectHeaderText()
		{
			return this.subjectHeaderText ? this.subjectHeaderText : this.$mhat("MessageLayoutWrapper.SubjectHeaderText");
		}

		get getBodyHeaderText()
		{
			return this.bodyHeaderText ? this.bodyHeaderText : this.$mhat("MessageLayoutWrapper.BodyHeaderText");
		}

		get getAttachmentsHeaderText()
		{
			return this.attachmentsHeaderText ? this.attachmentsHeaderText : this.$mhat("MessageLayoutWrapper.AttachmentsHeaderText");
		}
	}
