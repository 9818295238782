import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {ProviderTransfer} from "@/open_api/generated";
import Provider from "@/lib/clinic/providerProfile.model";

export default class ProviderTransferToProviderConverter extends AbstractConverter<ProviderTransfer, Provider>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: ProviderTransfer): Provider
	{
		if (!from)
		{
			return null;
		}

		return new Provider(from.id, from.remote_id, from.first_name, from.last_name, from.prefix, from.suffix, from.specialty);
	}
}
