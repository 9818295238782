
export default class PhoneFormatUtils
{
	public static formatPhoneNumber(phoneNumber: string, stripInvalid = true, limitLength = true): string
	{
		if (phoneNumber)
		{
			phoneNumber = this.clearFormattingCharacters(phoneNumber);
			phoneNumber = this.stripLeadingOne(phoneNumber);
			if (stripInvalid)
			{
				phoneNumber = this.removeInvalidCharacters(phoneNumber);
			}
			if (limitLength)
			{
				phoneNumber = this.limitLength(phoneNumber);
			}
			phoneNumber = this.insertDigitSeparators(phoneNumber);
			phoneNumber = this.bracketFirstThreeDigits(phoneNumber);
		}
		return phoneNumber;
	}

	// checks if a phone number is of the correct format
	public static isCorrectFormat(phoneNumber: string): boolean
	{
		return Boolean(phoneNumber.match(/\(\d{3}\)\s\d{3}-\d{4}/));
	}

	// insert separators to get the phone number to format (xxx) xxx-xxxx
	private static insertDigitSeparators(phoneNumber: string): string
	{
		if (phoneNumber.length >= 3)
		{
			phoneNumber = phoneNumber.replace(/^\(?(\d{3})/, "$1 ");
		}
		if (phoneNumber.length >= 7)
		{
			phoneNumber = phoneNumber.replace(/(\s\d{3})/, "$1-");
		}

		return phoneNumber;
	}

	// bracket the first 3 digits of the phone number like (xxx) xxx-xxxx
	private static bracketFirstThreeDigits(phoneNumber: string): string
	{
		if (phoneNumber.trim().length >= 3)
		{
			// put the first 3 digits in brackets
			return phoneNumber.replace(/^(\d{3})/, "($1)");
		}
		return phoneNumber;
	}

	// remove all formatting characters from the phone number
	private static clearFormattingCharacters(phoneNumber: string): string
	{
		// remove all "(", " ", "-" or ")" form phone number
		return phoneNumber.replace(/[()\s-]/g, "");
	}

	// remove characters not allowed in a phone number
	private static removeInvalidCharacters(phoneNumber: string): string
	{
		return phoneNumber.replace(/[^\d]/g, "");
	}

	// limit the length of the phone number to 10 digits (strip every thing after).
	// Assumes a "clean" phone number with no formatting
	private static limitLength(phoneNumber: string): string
	{
		return phoneNumber.slice(0, 10);
	}

	// strip leading one if applicable
	private static stripLeadingOne(phoneNumber: string): string
	{
		if (phoneNumber.length > 10 && phoneNumber[0] === "1")
		{
			return phoneNumber.slice(1);
		}
		return phoneNumber;
	}
}
