
	import ClinicsHeader from "./components/ClinicsHeader.vue";
	import {MyClinicsController} from "@/views/patient_user/clinic/MyClinicsController";
	import ClinicsActionBar from "@/views/patient_user/clinic/components/ClinicsActionBar.vue";
	import ClinicCard from "@/components/Buttons/ClinicCard.vue";
	import ClinicSearchSpinner from "@/components/EventState/ClinicSearchSpinner.vue";
	import {ClinicSearchType} from "@/components/EventState/types";
	import {ClinicEvent, EventBus} from "@/lib/utils/EventBus";
	import {Options, Vue} from "vue-property-decorator";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import ClinicModalController from "@/views/patient_user/clinic/ClinicModalController";
	import {RouteType} from "@/router/types";

	@Options({components: {ClinicSearchSpinner, ClinicCard, ClinicsActionBar, ClinicsHeader}})
	export default class ClinicsMobile extends ClinicModalController
	{
		controller: MyClinicsController = new MyClinicsController();
		ClinicSearchType = ClinicSearchType;

		afterCreated()
		{
			this.controller.getConnectedClinics();
			EventBus.$on(ClinicEvent.DELETE, () =>
			{
				this.controller.getConnectedClinics();
			});
		}

		goToRoute(clinic: ClinicProfile)
		{
			this.$mhaRouterReplace({
				name: this.$route.name as RouteType,
				params: { clinicId: clinic.id },
			});
		}

		get shouldShowSpinner()
		{
			return this.controller.isLoading || this.controller.noClinics;
		}

		get spinnerFailure()
		{
			const results = {message: null};

			if (this.controller.noClinics)
			{
				results.message = this.$mhat("ClinicsMobile.NoClinicsMessage");
			}

			return results;
		}

		get clinics()
		{
			return this.controller.connectedClinics;
		}

		get activeClinics()
		{
			return this.controller.activeClinics;
		}

		get pendingClinics()
		{
			return this.controller.pendingClinics;
		}

		get deletedClinicId()
		{
			if (this.controller.deletedClinic)
			{
				return this.controller.deletedClinic.id;
			}
		}
	}
