import {RouteAuthType} from "@/router/types";

import {Route} from "@/router/router";
import {RouteRecordRaw} from "vue-router";

const Home = () => import(/* webpackChunkName: "HomePage" */ "@/views/patient_user/home/HomePage.vue");

export enum HomeRoute {
	Home = "Home",
	HomeRedirect = "HomeRedirect",
}

export const homeRoutes: RouteRecordRaw[] = [

	{ // Virtual redirect route. This route redirects to the correct home page depending on organization.
		// This is important because some organization home pages are public while others are not.
		path: "/",
		name: HomeRoute.HomeRedirect,
		component: null,
		meta: {
			brandedRoute: true,
		},
		beforeEnter(to, from, next)
		{
			const nextRoute = Object.assign({}, to);
			nextRoute.name = Route.Home;

			next(nextRoute);
		},
	},
	{
		path: "/home",
		name: HomeRoute.Home,
		component: Home,
		props: true,
		meta: {
			page: 0,
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
