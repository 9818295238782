import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39281ada"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "z-position-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundImage = _resolveComponent("BackgroundImage")!
  const _component_BackgroundRibbon = _resolveComponent("BackgroundRibbon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["background-wrapper", {'hide-overflow': _ctx.backgroundHideOverflow}])
    }, [
      (_ctx.backgroundImage)
        ? (_openBlock(), _createBlock(_component_BackgroundImage, _normalizeProps(_mergeProps({ key: 0 }, _ctx.$attrs)), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "background", {}, undefined, true)
            ]),
            _: 3
          }, 16))
        : (_ctx.backgroundRibbons)
          ? (_openBlock(), _createBlock(_component_BackgroundRibbon, _normalizeProps(_mergeProps({ key: 1 }, _ctx.$attrs)), {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "background", {}, undefined, true)
              ]),
              _: 3
            }, 16))
          : _createCommentVNode("", true)
    ], 2),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}