
	import {Options, Prop, Vue, Watch} from "vue-property-decorator";
	import {IonSplitPane, IonMenu, IonPage} from "@ionic/vue";

	@Options({components: {IonSplitPane, IonMenu, IonPage}})
	export default class TelehealthWrapper extends Vue
	{
		@Prop(String) public readonly contentId: string;
		@Prop(Boolean) public readonly chatVisible: boolean;

		protected CHAT_COLLAPSE_SIZE_PX = 1200;

		public mounted(): void
		{
			// emit initial menu close if menu will not be displayed due to screen size.
			if (window.innerWidth < this.CHAT_COLLAPSE_SIZE_PX)
			{
				this.onMenuClose();
			}
		}

		public onSplitPaneVisibilityChange(event): void
		{
			this.$emit("update:chatVisible", event.detail.visible);
		}

		public onMenuClose(): void
		{
			this.$emit("update:chatVisible", false);
		}

		@Watch("chatVisible")
		public onChatVisibilityChange(visible): void
		{
			const menu = (this.$refs.splitMenu as typeof IonSplitPane).$el as HTMLIonMenuElement;
			if (visible)
			{
				menu.open(true);
			}
			else
			{
				menu.close();
			}
		}
	}
