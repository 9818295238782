
	import {LOGO_BRAND} from "@/assets/AppIcons";
	import {getResources} from "@/lib/utils/Resource";

	const {protocol, hostname} = location;
	const clinicLoginRoute = `${protocol}//${hostname}/clinic_users/sign_in`;

	export default {
		name: "LoginPageHeader",
		props: {
			headerText: {
				type: String,
			},
		},

		data()
		{
			return {
				headerProps: {
					headerIcon: LOGO_BRAND,
					headerText: getResources().app_name ? getResources().app_name : this.$mhat("LoginPageHeader.HeaderText"),
					noBack: true,
					buttonOpts: {
						icon: "icon-logout",
						text: this.$mhat("LoginPageHeader.ClinicLoginButtonText"),
						action: () =>
						{
							location.href = clinicLoginRoute;
						},
					},
				},
			};
		},
	};
