
	import {Options, Vue} from "vue-property-decorator";
	import {PatientAPI, PublicAPI} from "@/lib/services/Api";
	import {SessionData} from "@/lib/telehealth/models/sessionData.model";
	import NotificationService from "@/components/Notification/NotificationService";
	import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
	import TelehealthPatientIos from "@/views/patient_user/appointment/TelehealthPatientIos.vue";
	import TelehealthWrapper from "@/components/telehealth/TelehealthWrapper.vue";
	import PatientTelehealthHeader from "@/views/patient_user/appointment/components/PatientTelehealthHeader.vue";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import PublicWaitingRoomOverlay from "@/views/public/PublicWaitingRoomOverlay.vue";
	import IOSTelehealth from "@/lib/telehealth/ios/IOSTelehealth";
	import LogEvent from "@/lib/telehealth/models/LogEvent";
	import {reactive} from "vue";
	import TelehealthInterface, {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
	import {IonPage} from "@ionic/vue";
	import PublicTelehealthApiService from "@/lib/telehealth/api/PublicTelehealthApiService";

	@Options({
		components: {PublicWaitingRoomOverlay, TelehealthWrapper, PatientTelehealthHeader, ChatView, IonPage},
	})
	export default class PublicTelehealthIos extends TelehealthPatientIos
	{
		// ==========================================================================
		// Vue Life Cycle Hooks
		// ==========================================================================

		public async onCreated(): Promise<void>
		{
			this.telehealthEngine = reactive(new IOSTelehealth()) as TelehealthInterface;
			this.telehealthApiService = new PublicTelehealthApiService(this.appointmentId, this.$route.query.token as string);
		}

		public async onMounted(): Promise<void>
		{
			await this.telehealthEngine.initialize(this.callData(await this.fetchSessionData()), null, null);
		}

		// ==========================================================================
		// Private Methods
		// ==========================================================================

		protected onCallStateChange(state: TELEHEALTH_STATE): void
		{
			// Vue has a really hard time tracking changes caused by the native iOS code.
			// force update
			if (this.$refs.ionPage)
			{
				(this.$refs.ionPage as typeof IonPage).$forceUpdate();
			}
		}

		protected async fetchSessionData(): Promise<SessionData>
		{
			try
			{
				return new SessionData((await PublicAPI().getAppointmentTelehealthSession(this.appointmentId,
					this.$route.query.token as string)).data.session_token);
			}
			catch (e)
			{
				NotificationService.notify({
					event: NotifyEvent.Generic,
					type: NotificationType.Dismiss,
					severity: NotificationSeverity.Critical,
					title: "Telehealth Link Invalid",
					message: "Could not connect you to the telehealth session. Insure the link you entered is correct",
				});

				throw e;
			}
		}
	}
