import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppointmentsHeader = _resolveComponent("AppointmentsHeader")!
  const _component_AppointmentPage = _resolveComponent("AppointmentPage")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, {
    contentHeaderRemoveFalseHeaderSpace: "",
    contentWidthFull: ""
  }, {
    "content-header": _withCtx(() => [
      (_ctx.isMobile)
        ? (_openBlock(), _createBlock(_component_AppointmentsHeader, {
            key: 0,
            "button-opts": _ctx.headerButtonOpts,
            "header-text": this.$mhat('AppointmentInfo.MobileHeaderText'),
            noBack: ""
          }, null, 8, ["button-opts", "header-text"]))
        : _createCommentVNode("", true)
    ]),
    "content-top": _withCtx(() => [
      _createVNode(_Transition, { name: "vue-fade" }, {
        default: _withCtx(() => [
          (_ctx.appointment && _ctx.clinicProfile)
            ? (_openBlock(), _createBlock(_component_AppointmentPage, {
                key: 0,
                appointment: _ctx.appointment,
                clinic: _ctx.clinicProfile,
                onRoute: _ctx.handleRoute,
                onAppointmentCancelled: _ctx.onAppointmentCancelled
              }, null, 8, ["appointment", "clinic", "onRoute", "onAppointmentCancelled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    "content-center": _withCtx(() => [
      (!_ctx.appointment || !_ctx.clinicProfile)
        ? (_openBlock(), _createBlock(_component_EventStateSpinner, {
            key: 0,
            "success-icon": "icon-calendar",
            "loading-title": "Loading Appointment Details...",
            "is-loading": _ctx.isLoading
          }, null, 8, ["is-loading"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}