import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import {PatientAPI} from "@/lib/services/Api";
import router from "@/router/router";
import {Vue} from "vue-property-decorator";
import PatientProfileModal from "@/views/patient_user/clinic/components/PatientProfileModal.vue";
import CannotConnectToClinicModal from "@/components/Modals/Patient/CannotConnectToClinicModal.vue";
import {ProfileController} from "@/views/patient_user/profile/controllers/ProfileController";
import AuthStore from "@/lib/vuex/auth.store";
import DependentStore from "@/lib/vuex/modules/Dependent.store";
import {UserProfileController} from "@/views/patient_user/profile/controllers/UserProfileController";
import {DependentProfileController} from "@/views/patient_user/profile/controllers/DependentProfileController";
import {modalController} from "@ionic/vue";

export default class PatientModalService
{
	// ==========================================================================
	// Static Methods
	// ==========================================================================

	/**
	 * display the incomplete profile modal. This modal allows the patient to fill out the fields required to connect
	 * to the given clinic.
	 * @param clinic - clinic the patient is trying to connect to.
	 * @param patientId - the id of the patient for whom the modal is to be displayed.
	 * @param missingFields - a list of the missing fields that need to be filled out
	 */
	public static async displayIncompleteProfileModal(
		clinic: ClinicProfile | string, patientId: string,
		missingFields = null): Promise<void>
	{
		if (typeof clinic === "string")
		{
			clinic = new ClinicProfile((await PatientAPI().getClinic(clinic)).data);
		}

		const props = {
			active: true,
			profileController: PatientModalService.getProfileController(patientId),
			clinic,
			handleSubmit: () =>
			{
				router.go(0);
			},
			handleCancel: () =>
			{
				router.back();
			},
			visibleProfileFields: missingFields,
			showInstructionText: true,
		};

		const modal = await modalController.create(
			{
				component: PatientProfileModal,
				showBackdrop: true,
				cssClass: "fullscreen",
				componentProps: props,
			});

		await modal.present();
	}

	/**
	 * display a modal indicating to the patient that they cannot currently be connected to the
	 * specified clinic.
	 * @param clinic - the clinic to which the patient cannot connect
	 */
	public static async displayCannotConnectModal(clinic: ClinicProfile| string): Promise<void>
	{
		if (typeof clinic === "string")
		{
			clinic = new ClinicProfile((await PatientAPI().getClinic(clinic)).data);
		}

		const modal = await modalController.create(
			{
				component: CannotConnectToClinicModal,
				showBackdrop: true,
				cssClass: "fullscreen",
				componentProps: {clinic},
			});

		await modal.present();
		await modal.onDidDismiss();
	}

	// ==========================================================================
	// Protected Static Methods
	// ==========================================================================

	/**
	 * gets the appropriate profile controller for the patient Id.
	 * @param patientId - the patient for which the profile controller is to be constructed
	 * @protected - a profile controller of the appropriate type for the patient
	 */
	protected static getProfileController(patientId: string): ProfileController
	{
		if (AuthStore.loggedInPatient.id === patientId)
		{
			return new UserProfileController();
		}
		return new DependentProfileController(DependentStore.dependentMap[patientId]);
	}
}
