import TelehealthInterface, {AV_ERROR, TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import {ChatItem} from "@/views/patient_user/telehealth/types";
import LogEvent from "@/lib/telehealth/models/LogEvent";
import {TeleCallState} from "@/lib/telehealth/models/telehealth.types";
import {VideoDisplayMode} from "@/lib/telehealth/models/VideoDisplayMode";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";

export default abstract class TelehealthInterfaceBase implements TelehealthInterface
{
	protected localClientInfo: TelehealthClient = null;

	// callbacks
	protected onRemoteConnectedCallbacks: CallbackCollection = new CallbackCollection();
	protected onChatMessageCallbacks: CallbackCollection = new CallbackCollection();
	protected onChatTypingCallbacks: CallbackCollection = new CallbackCollection();
	protected onInboundCallCallbacks: CallbackCollection = new CallbackCollection();
	protected onInboundCallCancelCallbacks: CallbackCollection = new CallbackCollection();
	protected onCallEndCallbacks: CallbackCollection = new CallbackCollection();
	protected onKickedCallbacks: CallbackCollection = new CallbackCollection();
	protected onAVDeviceErrorCallbacks: CallbackCollection = new CallbackCollection();
	protected onRemoteStateChangeCallbacks: CallbackCollection = new CallbackCollection();
	protected onCallStateChangeCallbacks: CallbackCollection = new CallbackCollection();
	protected onRemoteDisconnectedCallbacks: CallbackCollection = new CallbackCollection();
	protected onLogEventCallbacks: CallbackCollection = new CallbackCollection();
	protected onRemoteEnterRoomCallbacks: CallbackCollection = new CallbackCollection();

	// ==========================================================================
	// Abstract Methods
	// ==========================================================================

	abstract initialize(initParam: any, videoSelector: string, videoPreviewSelector: string): Promise<void>;

	abstract destroy(): void;

	abstract enterRoom(): Promise<void>;

	abstract leaveRoom(): Promise<void>;

	abstract callRemote(callDisplay: string): Promise<boolean>;

	abstract callSpecificRemote(remoteId: string): Promise<boolean>;

	abstract cancelCallRemote(): Promise<void>;

	abstract notifyRemotesOfCallEnd(): Promise<void>;

	abstract changeVideoDisplayMode(mode: VideoDisplayMode): void;

	abstract enableVideo(): void;

	abstract disableVideo(): void;

	abstract cycleCamera(): void;

	abstract muteMicrophone(mute: boolean): void;

	abstract sendChatMessage(msg: ChatMessage): Promise<void>;

	abstract sendTypingSignal(): Promise<void>;

	abstract kickRemote(remoteId: string): Promise<boolean>;

	abstract readonly callState: TELEHEALTH_STATE;

	abstract readonly remoteClients: TelehealthClient[];

	abstract readonly isVideoEnabled: boolean;

	abstract readonly isMuted: boolean;

	abstract readonly videoDisplayMode: VideoDisplayMode;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * set this clients telehealth info. This will be broadcast to other clients.
	 * @param clientInfo - the client info.
	 */
	setClientInfo(clientInfo: TelehealthClient): void
	{
		this.localClientInfo = clientInfo;
	}

	public onRemoteConnected(callback: (remoteId: string) => void, context?: any): void
	{
		this.addCallback(callback, this.onRemoteConnectedCallbacks, context);
	}

	public onRemoteDisconnected(callback: (remoteId: string) => void, context?: any): void
	{
		this.addCallback(callback, this.onRemoteDisconnectedCallbacks, context);
	}

	public onRemoteStateChange(callback: (client: TelehealthClient) => void, context?: any): void
	{
		this.addCallback(callback, this.onRemoteStateChangeCallbacks, context);
	}

	public onRemoteEnterRoom(callback: (client: TelehealthClient) => void, context?: any): void
	{
		this.addCallback(callback, this.onRemoteEnterRoomCallbacks, context);
	}

	public onCallStateChange(callback: (callState: TELEHEALTH_STATE) => void, context?: any): void
	{
		this.addCallback(callback, this.onCallStateChangeCallbacks, context);
	}

	public onChatMessage(callback: (remoteId: string, chatMessage: ChatMessage) => void, context?: any): void
	{
		this.addCallback(callback, this.onChatMessageCallbacks, context);
	}

	public onChatTyping(callback: (remoteId: string) => void, context?: any): void
	{
		this.addCallback(callback, this.onChatTypingCallbacks, context);
	}

	public onInboundCall(callback: (remoteId: string, callDisplay: string) => Promise<boolean>, context?: any): void
	{
		this.addCallback(callback, this.onInboundCallCallbacks, context);
	}

	public onInboundCallCancel(callback: (remoteId: string) => Promise<void>, context?: any): void
	{
		this.addCallback(callback, this.onInboundCallCancelCallbacks, context);
	}

	public onCallEnd(callback: (remoteId: string) => Promise<boolean>, context?: any): void
	{
		this.addCallback(callback, this.onCallEndCallbacks, context);
	}

	public onKicked(callback: () => void, context?: any): void
	{
		this.addCallback(callback, this.onKickedCallbacks, context);
	}

	public onAVDeviceError(callback: (error: AV_ERROR) => void, context?: any): void
	{
		this.addCallback(callback, this.onAVDeviceErrorCallbacks, context);
	}

	public onLogEvent(callback: (event: LogEvent) => void, context?: any): void
	{
		this.addCallback(callback, this.onLogEventCallbacks, context);
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	/**
	 * map call state to legacy call state.
	 */
	get legacyCallState(): TeleCallState
	{
		switch (this.callState)
		{
			case TELEHEALTH_STATE.IN_ROOM:
				return TeleCallState.IN_CALL;
			case TELEHEALTH_STATE.OUT_OF_ROOM:
			default:
				return TeleCallState.PENDING;
		}
	}

	/**
	 * true / false indicating if AV features are enabled.
	 * Default implementation always returns true.
	 */
	get isAVEnabled(): boolean
	{
		return true;
	}

	/**
	 * indicate if this telehealth engine is connected to a session or not.
	 */
	get isConnected(): boolean
	{
		return this.callState !== TELEHEALTH_STATE.NOT_CONNECTED;
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	/**
	 * helper function to reduce callback adding to one line.
	 * @param callback - callback to add
	 * @param collection - the collection to add the callback to
	 * @param context - context to bind to "this"
	 * @protected
	 */
	protected addCallback(callback: any, collection: CallbackCollection, context?: any): void
	{
		callback = this.setContext(callback, context);
		collection.addCallback(callback);
	}

	/**
	 * assign a context to the callback
	 * @param callback - callback
	 * @param context - the context to assign
	 * @protected the callback with the context assigned
	 */
	protected setContext(callback: any, context: any): any
	{
		if (context)
		{
			return callback.bind(context);
		}
		return callback;
	}
}
