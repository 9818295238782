
	import {Options, Prop} from "vue-property-decorator";

	import moment from "moment";
	import MessageRead from "./components/MessageRead.vue";
	import MessageCompose from "./components/MessageCompose.vue";
	import {MailboxController} from "@/lib/Mailbox/Mailbox.controller";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import modalBaseController from "@/lib/modal/modalBaseController";

	@Options({components: {MessageCompose, MessageRead}})
	export default class MessageView extends modalBaseController
	{
		@Prop() controller: MailboxController;
		@Prop({type: Boolean}) composeOnly: boolean;

		public currentTime = moment();
		public selectedClinic = null;

		public onCreated()
		{
			if (this.composeOnly && !this.controller.isComposing)
			{
				this.controller.composeMail();
			}
		}

		public onArchive(): void
		{
			this.controller.setSelectedArchived();
			this.dismissModal();
		}

		public onUnArchive(): void
		{
			this.controller.setSelectedArchived();
			this.dismissModal();
		}

		public onCancelCompose()
		{
			this.controller.cancelComposing();
			if (this.composeOnly)
			{
				this.dismissModal();
			}
		}

		public onSendMail(composed)
		{
			this.controller.sendMail(composed).then(() =>
			{
				this.controller.refreshSelectedMessage(this as any);
				this.dismissModal();
			}).catch((error: ErrorResponse) =>
			{
				console.info(error.message);
			});
		}

		// Override
		public onRouterBack()
		{
			if (this.controller.isComposing && this.controller.isReplying)
			{
				this.onCancelCompose();
			}
			else
			{
				this.dismissModal();
			}
		}
	}
