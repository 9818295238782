
	import PasswordField from "@/components/Inputs/PasswordField.vue";
	import SecurityHeaderBar from "./components/SecurityHeaderBar.vue";
	import {UserProfileController} from "./controllers/UserProfileController";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";

	export default {
		name: "SecurityChangePassword",
		components: {PasswordField, SecurityHeaderBar},

		data()
		{
			return {
				controller: new UserProfileController(),
				ButtonColor,
				ButtonColorPattern,
			};
		},

		methods: {
			updatePassword(element)
			{
				this.controller.passwords[element.id] = element.value;
			},
			onSave()
			{
				this.controller.updatePassword().then(
					(response) =>
					{
						this.$mhaRouterPush(this.Route.Settings.Security);
					},
					(errors) =>
					{
						// TODO
					},
				);
			},
			onCancel()
			{
				this.$mhaRouterPush(this.Route.Settings.Security);
			},
		},
	};
