import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!

  return (_openBlock(), _createBlock(_component_ion_fab_button, {
    class: _normalizeClass(["round-button md ion-activatable ion-focusable hydrated", _ctx.buttonClasses()]),
    disabled: _ctx.disabled,
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class", "disabled", "onClick"]))
}