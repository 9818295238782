import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {OneTimeCodeDto} from "@/open_api/generated";
import OneTimeCode from "@/lib/oneTimeCode/model/OneTimeCode";
import moment from "moment";

export default class OneTimeCodeDtoToOneTimeCodeConverter extends AbstractConverter<OneTimeCodeDto, OneTimeCode>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: OneTimeCodeDto): OneTimeCode
	{
		return new OneTimeCode(from.code_type, from.code, moment(from.expire_at_utc));
	}
}
