

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {IonFooter, IonPage} from "@ionic/vue";

	@Options({components: {IonFooter, IonPage}})
	export default class PageWrapper extends Vue
	{
		@Prop({type: Object}) footerStyleOverride: any;
	}
