import {IOSCallEvent} from "@/views/patient_user/telehealth/types";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import MHABackendLogger from "@/lib/utils/MHABackendLogger";

/**
 * listens for native telehealth events
 */
export default class NativeEventListener
{
	protected listeners: Map<string, CallbackCollection> = new Map<string, CallbackCollection>();

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor()
	{

	}

	public setupNativeEventListeners(): void
	{
		window.addEventListener("callEvent", this.onNativeCallEvent.bind(this), false);
	}

	public removeNativeEventListeners(): void
	{
		window.removeEventListener("callEvent", this.onNativeCallEvent.bind(this), false);
	}

	public addNativeEventListener(callEvent: IOSCallEvent, callback: (detail: any) => void, context: any): void
	{
		if (context)
		{
			callback = callback.bind(context);
		}

		if (this.listeners.has(callEvent))
		{
			this.listeners.get(callEvent).addCallback(callback);
		}
		else
		{
			this.listeners.set(callEvent, new CallbackCollection([callback]));
		}
	}

	// ==========================================================================
	// Private Methods
	// ==========================================================================

	/**
	 * called when native code emits call events
	 * @param event - the native event
	 * @protected
	 */
	protected async onNativeCallEvent(event: CustomEvent): Promise<void>
	{
		const callEvent = event.detail.type as IOSCallEvent;
		if (this.listeners.has(callEvent))
		{
			// decode native data
			let data = null;
			if (event.detail.data)
			{
				data = atob(event.detail.data);
			}

			await this.listeners.get(callEvent).call(data);
		}
	}
}
