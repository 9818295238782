

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {IonCheckbox, IonLabel} from "@ionic/vue";

	@Options({
		emits: ["click"],
		components: {IonCheckbox, IonLabel},
	})
	export default class Checkbox extends Vue
	{
		@Prop({type: Boolean}) checked: boolean;
	}
