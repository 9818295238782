import {Capacitor, Plugins} from "@capacitor/core";

export interface AddedListener
{
	remove: () => void;
}

function keyboardPluginAvailable(): boolean
{
	return Capacitor.isPluginAvailable("Keyboard");
}

export function addKeyboardListener(showCb: () => void, hideCb: () => void): AddedListener
{
	if (keyboardPluginAvailable())
	{
		const showListener = Plugins.Keyboard.addListener("keyboardWillShow", showCb);
		const hideListener = Plugins.Keyboard.addListener("keyboardWillHide", hideCb);

		const remove = () =>
		{
			showListener.remove();
			hideListener.remove();
		};

		return { remove };
	}
}
