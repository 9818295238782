import {Patient} from "@/lib/patient/Patient";
import {Countries, SubRegions, TimeZones} from "@/lib/utils/Geodata";
import {Validator} from "@/lib/utils/Validator";
import {NotificationSeverity, NotificationType, NotifyEvent, WebNotification} from "@/lib/types/Notifier";
import {PatientLoginService} from "@/lib/services/login/PatientLogin.service";
import {PatientProfileField} from "@/lib/types/Profile";
import ValidationList from "@/lib/utils/ValidationList";
import {VALIDATION_TYPE} from "@/lib/utils/Validation";
import DeviceInfo from "@/lib/DeviceInfo";
import ConfigStore from "@/lib/vuex/modules/Config.store";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
import {ErrorCode} from "@/open_api/generated";
import {ref, Ref, toRef, unref, UnwrapRef} from "vue";
import {$mhat} from "@/i18n";

export abstract class ProfileController
{
	protected static emitProfileError(errors: ErrorResponse = null)
	{
		let message = $mhat("ProfileController.SomethingWentWrongMessage");
		if (errors.is(ErrorCode.Validation))
		{
			for (const error in errors.data.validation_errors)
			{
				if (errors.data.validation_errors.hasOwnProperty(error))
				{
					const fieldErrors = errors.data.validation_errors[error].join(` ${$mhat("ProfileController.Or")} `);
					switch (error.toString())
					{
						case "current_password":
							message = `${$mhat("ProfileController.PasswordText")} ${fieldErrors}`;
							break;
						case "email":
							message = `${$mhat("ProfileController.EmailText")} ${fieldErrors}`;
							break;
					}
				}
			}
		}

		WebNotification.$emit({
			event: NotifyEvent.ProfileUpdateError,
			type: NotificationType.Dismiss,
			severity: NotificationSeverity.Reminder,
			title: $mhat("ProfileController.GenericErrorTitle"),
			message,
			dismiss: {
				message: $mhat("ProfileController.DismissButtonText"),
			},
		});
	}

	protected _userProfile: Ref<UnwrapRef<Patient>>;
	private _supportedProvinces: string[] = [];

	protected fieldValidations = ValidationList.create().concatAll(
		Validator.phoneNumber(this, "userProfile.cellPhone", false),
		Validator.userProfileHealthNumber(this, "userProfile", false),
		Validator.dateOfBirth(this, "userProfile.birthDate"),
		// more validations here...
	);

	protected constructor()
	{
		this.supportedProvinces = ConfigStore.supportedProvinceCodes;
	}

	public abstract onSave();

	/// // Validation /////

	public canSaveProfile(): boolean
	{
		return this.isValidHealthNumber();
	}

	public isValidEmail(): boolean
	{
		return Validator.emailAddress(this.userProfile.email);
	}

	public getPhoneValidation(required: boolean): ValidationList
	{
		if (required)
		{
			return Validator.required(this.userProfile, "cellPhone")
				.concat(this.fieldValidations.getByType(VALIDATION_TYPE.PATIENT_CELL));
		}
		else
		{
			return this.fieldValidations.getByType(VALIDATION_TYPE.PATIENT_CELL);
		}
	}

	public isValidBirthDate(): boolean
	{
		return Validator.date(this.userProfile.birthDate);
	}

	public isValidHealthVersionNumber(nullable = true): boolean
	{
		if (this.userProfile.healthNumberVersion)
		{
			return Validator.maxLength(this.userProfile.healthNumberVersion, 2);
		}
		return nullable;
	}

	public isValidHealthNumber(nullable = true): boolean
	{
		if (this.userProfile.healthNumber)
		{
			return Validator.healthCardNumber(this.userProfile.healthCareProvinceCode, this.userProfile.healthNumber) &&
				(!this.showHinVersion() || this.isValidHealthVersionNumber(true));
		}

		return nullable;
	}

	public getHealthNumberValidation(required: boolean)
	{
		if (required)
		{
			return Validator.required(this.userProfile, "healthNumber")
				.concat(this.fieldValidations.getByType(VALIDATION_TYPE.PATIENT_HEALTH_NUMBER));
		}
		else
		{
			return this.fieldValidations.getByType(VALIDATION_TYPE.PATIENT_HEALTH_NUMBER);
		}
	}

	public getDateOfBirthValidation(required: boolean): ValidationList
	{
		if (required)
		{
			return Validator.required(this, "userProfile.birthDate", "* Invalid date of birth")
				.concat(Validator.dateOfBirth(this, "userProfile.birthDate"));
		}

		return Validator.dateOfBirth(this, "userProfile.birthDate");
	}

	/**
	 * add or remove the required validation from date of birth
	 * @param required - whether or not to require the field
	 */
	public setDateOfBirthRequired(required: boolean)
	{
		if (required)
		{
			if (this.fieldValidations.getByType(VALIDATION_TYPE.PATIENT_DOB)
				.getByType(VALIDATION_TYPE.REQUIRED_FIELD).length === 0)
			{
				const dobValidation = this.fieldValidations.getByType(VALIDATION_TYPE.PATIENT_DOB)
					.concat(Validator.required(this, "userProfile.birthDate", "* Invalid date of birth RRRRRR"));

				this.fieldValidations.removeByType(VALIDATION_TYPE.PATIENT_DOB);
				this.fieldValidations = this.fieldValidations.concat(dobValidation);
			}
		}
		else
		{
			const dobValidation = this.fieldValidations.getByType(VALIDATION_TYPE.PATIENT_DOB);
			dobValidation.removeByType(VALIDATION_TYPE.REQUIRED_FIELD);

			this.fieldValidations.removeByType(VALIDATION_TYPE.PATIENT_DOB);
			this.fieldValidations = this.fieldValidations.concat(dobValidation);
		}
	}

	public isValidFirstName(): boolean
	{
		return Validator.isPresent(this.userProfile.firstName);
	}

	public isValidLastName(): boolean
	{
		return Validator.isPresent(this.userProfile.lastName);
	}

	public isValidSex(): boolean
	{
		return this.sexOptions().map((option) => option.value).includes(this.userProfile.sex);
	}

	public getFieldValidations(): ValidationList
	{
		return this.fieldValidations;
	}

	public isFieldsValid()
	{
		return this.fieldValidations.isValid();
	}

	public isRequiredFieldValid(fieldName: string)
	{
		let valid = false;
		switch (fieldName)
		{
			case PatientProfileField.FIRST_NAME: valid = this.isValidFirstName(); break;
			case PatientProfileField.LAST_NAME: valid = this.isValidLastName(); break;
			case PatientProfileField.SEX: valid = this.isValidSex(); break;
			case PatientProfileField.HEALTH_NUMBER: valid = this.isValidHealthNumber(false); break;
			case PatientProfileField.HEALTH_NUMBER_VERSION: valid = this.isValidHealthNumber(false); break;
			case PatientProfileField.BIRTH_DATE: valid = this.isValidBirthDate(); break;
			case PatientProfileField.EMAIL: valid = this.isValidEmail(); break;
			case PatientProfileField.CELL_PHONE: valid = this.getPhoneValidation(true).isValid(); break;
			default: valid = Validator.isPresent(this.userProfile[fieldName]); break;
		}
		return valid;
	}

	public isRequiredFieldsValid(fieldNames: string[] = []): boolean
	{
		let valid = true;
		for (const fieldName of fieldNames)
		{
			valid = valid && this.isRequiredFieldValid(fieldName);
			if (!valid)
			{
				break;
			}
		}

		return valid;
	}

	/// / Input Handlers /////

	public profileInput(profile)
	{
		this.userProfile[profile.id] = profile.value;
	}

	/// / Dropdown Options ////

	public subregionLabel()
	{
		if (this.isUS())
		{
			return $mhat("ProfileController.StateRegion");
		}

		return $mhat("ProfileController.ProvinceRegion");
	}

	public postalLabel()
	{
		if (this.isUS())
		{
			return $mhat("ProfileController.USPostal");
		}

		return $mhat("ProfileController.CanadaPostal");
	}

	public sexOptions()
	{
		return [
			{value: "M", name: $mhat("ProfileController.SexMale")},
			{value: "F", name: $mhat("ProfileController.SexFemale")},
		];
	}

	public currentSex()
	{
		return this.sexOptions().find((sex) => sex.value === this.userProfile.sex);
	}

	public subregionOptions()
	{
		const results = SubRegions[this.userProfile.countryCode || "CA"];

		if (results)
		{
			return results;
		}

		return [];
	}

	public hinSubregionOptions()
	{
		const options = this.subregionOptions();
		if (DeviceInfo.isCloudMd)
		{
			return options.filter((province) => this.supportedProvinces.includes(province.value));
		}

		return options;
	}

	public currentSubregion()
	{
		return this.subregionOptions().find((subRegion) => subRegion.value === this.userProfile.addressProvinceCode);
	}

	public currentCountry()
	{
		return Countries.find((country) => country.value === this.userProfile.countryCode);
	}

	public currentHealthCareProvince()
	{
		return SubRegions.CA.find((province) => province.value === this.userProfile.healthCareProvinceCode);
	}

	public currentTimeZone()
	{
		return TimeZones.find((timeZone) => timeZone.value === this.userProfile.timeZone);
	}

	public showHinVersion()
	{
		const ONTARIO_PROVINCE_CODE = "ON";
		const hinProvince = this.currentHealthCareProvince();

		return (hinProvince && hinProvince.value === ONTARIO_PROVINCE_CODE);
	}

	/// / Getters Setters ////

	public get userProfile(): Patient
	{
		return unref(this._userProfile) as Patient;
	}

	public set userProfile(value: Patient)
	{
		this._userProfile = ref(value);
	}

	get supportedProvinces(): string[]
	{
		return this._supportedProvinces;
	}

	set supportedProvinces(value: string[])
	{
		this._supportedProvinces = value;
	}

	// Button Handlers //

	public onCustomerSupport()
	{
		const customerSupport =
			"https://docs.google.com/forms/d/e/1FAIpQLScl-8-2vjAhi7_qoIIGr5DRRxH2PnaE88y_TqLg4q2S48NQ-g/viewform";
		window.open(customerSupport);
	}

	public onToggleEmail()
	{
		if (!this.userProfile.emailNotifications)
		{
			WebNotification.$emit({
				event: NotifyEvent.InvalidLogin,
				type: NotificationType.Dismiss,
				severity: NotificationSeverity.Reminder,
				title: $mhat("ProfileController.GenericNotificationTitle"),
				message: $mhat("ProfileController.TurnOffEmailNotificationWarning"),
				dismiss: {
					message: $mhat("ProfileController.ConfirmationButtonText"),
				},
			});
		}
	}

	public onLogOut()
	{
		new PatientLoginService().logout();
	}

	protected abstract getProfile(): Patient;

	private isUS(): boolean
	{
		return this.userProfile.countryCode === "US";
	}
}
