

	import {Options, Vue, Prop} from "vue-property-decorator";
	import DeviceFrame from "@/views/patient_user/login/components/DeviceFrame.vue";
	import AppBadges from "@/components/External/AppBadges.vue";
	import iphoneScreenImageFront from "@/assets/images/graphics/signup/screens/iPhone-homescreen@2x.png";
	import iphoneScreenImageBack from "@/assets/images/graphics/signup/screens/iPhone-background.png";
	import OrgImage from "@/components/Organization/OrgImage.vue";
	import ClinicLogo from "@/views/patient_user/clinic/components/ClinicLogo.vue";
	import {AppDownloadGraphicType, AppDownloadLayout} from "@/views/patient_user/login/components/types";

	@Options({components: {OrgImage, AppBadges, DeviceFrame, ClinicLogo}})
	export default class AppDownload extends Vue
	{
		@Prop({type: Boolean}) dark: boolean;
		@Prop({type: String, default: AppDownloadGraphicType.BRANDED}) version: string;
		@Prop({type: String, default: AppDownloadLayout.STANDARD}) layout: AppDownloadLayout;

		@Prop({type: String, default: iphoneScreenImageFront}) iphoneFrontScreen;
		@Prop({type: String, default: iphoneScreenImageBack}) iphoneBackScreen;

		public AppDownloadLayout = AppDownloadLayout;

		get showLogo()
		{
			return this.version === AppDownloadGraphicType.BRANDED;
		}

		get headerText()
		{
			switch (this.version)
			{
			case AppDownloadGraphicType.ORG: {
				return this.$mhat("AppDownload.OrgHeaderText");
			}
			case AppDownloadGraphicType.BRANDED:
			default:
			{
				return this.$mhat("AppDownload.DefaultHeaderText");
			}
			}
		}

		get messageText()
		{
			return this.$mhat("AppDownload.MessageText");
		}

		get headerClassList()
		{
			switch (this.version)
			{
			case AppDownloadGraphicType.ORG: {
				return ["header-text", "org"];
			}
			case AppDownloadGraphicType.BRANDED:
			default:
			{
				return ["header-text"];
			}
			}
		}

		get backDeviceHeight(): number
		{
			if (this.layout === AppDownloadLayout.REVERSED)
			{
				return 525;
			}
			return 499;
		}

		get frontDeviceHeight(): number
		{
			if (this.layout === AppDownloadLayout.REVERSED)
			{
				return 499;
			}
			return 525;
		}

		get frontDeviceWidth(): number
		{
			if (this.layout === AppDownloadLayout.REVERSED)
			{
				return 238;
			}
			return 251;
		}

		get backDeviceWith(): number
		{
			if (this.layout === AppDownloadLayout.REVERSED)
			{
				return 251;
			}
			return 238;
		}
	}
