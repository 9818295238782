

	import {Options, Vue} from "vue-property-decorator";

	@Options({})
	export default class WhitelabelMore extends Vue
	{
		public onIconClick()
		{
			this.$emit("click");
		}
	}
