import router from "@/router/router";
import DeviceInfo from "@/lib/DeviceInfo";
import NotificationService from "@/components/Notification/NotificationService";
import {NotificationSeverity, NotificationType, NotifyEvent} from "@/lib/types/Notifier";
import {getResources} from "@/lib/utils/Resource";

// before each route check native app version if it is out of date show alert.
router.beforeEach(async (to, from, next) =>
{
	next();

	if (!(await DeviceInfo.isNativeAppUpToDate()))
	{
		if (DeviceInfo.isIOS())
		{
			showiOSUpdateAlert();
		}
		else if (DeviceInfo.isAndroid())
		{
			showAndroidUpdateAlert();
		}
	}
});

function showiOSUpdateAlert(): void
{
	NotificationService.notify(
		{
			event: NotifyEvent.Generic,
			type: NotificationType.Dismiss,
			severity: NotificationSeverity.Warning,
			title: "Critical App Update!",
			message: `Your ${getResources().app_name} app is critically out of date and is not` +
			 `able to perform telehealth appointments. Please update your ${getResources().app_name} now`,
			dismiss: {
				message: "App Store",
				callback: () => window.open(getResources().links.app_store_link),
			},
		});
}

function showAndroidUpdateAlert(): void
{
	NotificationService.notify(
		{
			event: NotifyEvent.Generic,
			type: NotificationType.Dismiss,
			severity: NotificationSeverity.Warning,
			title: "New App Update!",
			message: `Please update the ${getResources().app_name} App`,
			dismiss: {
				message: "Play Store",
				callback: () => window.open(getResources().links.play_store_link),
			},
		});
}
