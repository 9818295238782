

	import DeviceInfo from "@/lib/DeviceInfo";
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ActionButtonOpts} from "@/components/Buttons/types";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {modalController, IonIcon, IonBackButton} from "@ionic/vue";
	import {arrowBack} from "ionicons/icons";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";

	@Options({
		components: {IonIcon, IonBackButton},
	})
	export default class MobileHeaderBar extends Vue
	{
		@Prop({type: String}) readonly backRoute: string;
		@Prop({type: Boolean, default: false}) useIonicBack: boolean;
		@Prop({type: Function}) readonly onBackClick: () => void;
		@Prop({type: Boolean, default: false}) readonly noBack: boolean;
		@Prop({type: String}) readonly headerText: string;
		@Prop({type: String}) readonly fontSize: string;
		@Prop({type: [String, Boolean, Object]}) readonly hasClose: string | boolean | any;
		@Prop({type: Function}) readonly onCloseClick: () => void;
		@Prop({type: Object}) readonly buttonOpts: ActionButtonOpts;
		@Prop({type: Boolean, default: false}) readonly overlay: boolean;
		@Prop({type: String, default: DeviceInfo.getAppFontIcon()}) icon: string;
		@Prop({type: Object}) headerStyleOverride: any;

		public arrowBack = arrowBack;

		public handleClose()
		{
			if (this.onCloseClick)
			{
				this.onCloseClick();
			}
			else if (this.$router)
			{
				this.$mhaRouterPush(this.closeRoute);
			}
			else
			{
				modalController.dismiss();
			}
		}

		public handleClickBack()
		{
			if (this.onBackClick)
			{
				this.onBackClick();
			}
			else if (this.backRoute)
			{
				this.$mhaRouterPush(this.backRoute);
			}
			else if ((this.$store.state as any)?.route?.from?.name)
			{
				const prevRoute = this.$router.options.history.state.back;
				const crossFrameService = new CrossFrameCommunicationService();

				if (crossFrameService.hasParentFrame && !prevRoute)
				{
					// let any parent frames know we want to go back to the beginning of booking.
					crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateBack});
				}
				else
				{
					this.$router.back();
				}
			}
			else
			{
				this.$mhaRouterPush(this.Route.Home);
			}
		}

		get defaultBackRoute()
		{
			// ionic requires legacy routing... guhhhh.
			if (OrganizationStore.isWhiteLabel)
			{
				return this.backRoute ? this.backRoute : `/org/${OrganizationStore.organizationProfile.url_friendly_name}`;
			}
			else
			{
				return this.backRoute ? this.backRoute : "/";
			}
		}

		get closeRoute(): any
		{
			return this.hasClose ? this.Route.Home : this.hasClose;
		}

		get isMobile()
		{
			return DeviceInfo.isMobile();
		}
	}
