import {RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";

const Home = () => import(/* webpackChunkName: "ClinicUser" */ "@/views/clinic_user/home/home.vue");
const Error = () => import(/* webpackChunkName: "ClinicUser" */ "@/views/clinic_user/home/error.vue");

export enum ClinicUserHomeRoute {
	Home = "ClinicUserHome",
	Error = "ClinicUserError",
}

export const clinicUserHomeRoutes: RouteRecordRaw[] = [
	{
		path: "/clinic_user",
		name: ClinicUserHomeRoute.Home,
		component: Home,
		props: true,
		meta: {
			page: 0,
			routeAuth: RouteAuthType.ClinicUser,
		},
	},
	{
		path: "/clinic_user/error",
		name: ClinicUserHomeRoute.Error,
		component: Error,
		props: true,
		meta: {
			page: 0,
			routeAuth: RouteAuthType.ClinicUser,
		},
	},
];
