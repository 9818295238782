
	import {Vue, Options, Prop, Watch} from "vue-property-decorator";
	import PhoneFormatUtils from "@/lib/utils/PhoneFormatUtils";

	@Options({
		emits: ["input"],
		components: {},
	})
	export default class PhoneField extends Vue
	{
		@Prop({type: String})
		private value;

		@Prop({type: String})
		private id;

		private FIELD_MAX_LENGTH = 14;

		// react to external modifications of phone number
		@Watch("value", {immediate: true})
		onPhoneChangeProp()
		{
			if (this.value !== undefined)
			{
				this.$emit("input",
					{
						id: this.id,
						value: PhoneFormatUtils.formatPhoneNumber(this.value),
					});
			}
		}

		// react to internal modifications of phone number
		public onPhoneChange(phone)
		{
			// format phone number
			phone.value = PhoneFormatUtils.formatPhoneNumber(phone.value);
			this.$emit("input", phone);
		}
	}
