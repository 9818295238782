
export enum CrossFrameMessageType
{
	// Indicates the parent frame should navigate backward away from the current page (the one showing the iframe)
	NavigateBack = "navigateBack",
	// tell parent frame it should go to a messenger system
	NavigateToMessenger = "navigateToMessenger",
	// tell parent frame it should go to the login page
	NavigateToLogin = "navigateToLogin",
	// tell parent frame it should go to the home page
	NavigateToHome = "navigateToHome",
}
