import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import moment from "moment";
import MhaDocument from "@/lib/document/model/MhaDocument";
import {ChatMessageType} from "@/open_api/generated";

export default class ChatMessageFactory
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * create a new chat message
	 * @param message - the message text
	 */
	public newMessage(message: string): ChatMessage
	{
		return new ChatMessage(null, message, ChatMessageType.Text, null, null, moment());
	}

	/**
	 * create a new document chat message
	 * @param document - the document to send
	 */
	public newDocumentMessage(document: MhaDocument): ChatMessage
	{
		return new ChatMessage(null, document.id, ChatMessageType.Document, null, null, moment());
	}
}
