import {Moment} from "moment";
import {DATE_FORMAT} from "@/constants";

export default class ScheduleSlot
{
	private readonly _startDateTime: Moment;
	private readonly _durationMinutes: number;
	private readonly _providerId: string;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * create schedule slot
	 * @param startDateTime - the time at which the slot starts
	 * @param durationMinutes - the duration of the slot in minutes
	 * @param providerId - the id of the provider which the slot belongs to
	 */
	public constructor(startDateTime: Moment, durationMinutes: number, providerId: string)
	{
		this._startDateTime = startDateTime;
		this._durationMinutes = durationMinutes;
		this._providerId = providerId;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get startDateTime(): Moment
	{
		return this._startDateTime;
	}

	get startDateString(): string
	{
		return this._startDateTime.format(DATE_FORMAT.DATE_STRING);
	}

	get durationMinutes(): number
	{
		return this._durationMinutes;
	}

	get providerId(): string
	{
		return this._providerId;
	}
}
