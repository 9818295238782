import ValidationList from "@/lib/utils/ValidationList";
import Validation, { VALIDATION_TYPE } from "@/lib/utils/Validation";
import PhoneFormatUtils from "@/lib/utils/PhoneFormatUtils";
import moment from "moment";

export class Validator
{
	static readonly BASE_10 = 10;

	/**
	 * Allow postal codes of <letter><number><letter><optional space><letter><number><letter> format
	 * @param postalCode
	 */
	public static postalCode(postalCode: string): boolean
	{
		const regex = RegExp(/[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/);
		return regex.test(postalCode);
	}

	/**
	 * Allow email addresses if they have <characters>@<characters>.<characters> format
	 * @param email
	 */
	public static emailAddress(email: string): boolean
	{
		const regex = RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
		return regex.test(email);
	}

	/**
	 * Allow passwords with length >= 8
	 *
	 * @param password password to test
	 */
	public static password(password: string): boolean
	{
		return Boolean(password && password.trim().length >= 8);
	}

	/**
	 * Allow any value except for empty or all whitespace
	 *
	 * @param value String to test
	 */
	public static isPresent(value: string): boolean
	{
		return Boolean(value && value.trim());
	}

	public static exactLength(value: string, length: number): boolean
	{
		return (this.minLength(value, length) && this.maxLength(value, length));
	}

	public static minLength(value: string, minLength: number): boolean
	{
		return Boolean(value && value.length >= minLength);
	}

	public static maxLength(value: string, maxLength: number): boolean
	{
		return Boolean(value && value.length <= maxLength);
	}

	/**
	 * Allow an empty value for the string, otherwise run a validation on it
	 *
	 * @param value string to test
	 * @param validation  Validation function to run on the string to test, if not empty
	 */
	public static optional(value: string, validation: (string) => boolean): boolean
	{
		if (value.length === 0)
		{
			return true;
		}

		return validation.call(this, value);
	}

	/**
	 * validate the health number field of the userProfile object.
	 * @param object - object to validate
	 * @param property - property that is of type userProfile
	 * @param required - if false validate null and undefined as true
	 */
	public static userProfileHealthNumber(object: any, property: string, required: boolean): ValidationList
	{
		const healthNumberValidation = ValidationList.create();
		healthNumberValidation.push(Validation.buildValidation(
			(userProfile) =>
			{
				if (userProfile.healthNumber)
				{
					return this.healthCardNumber(userProfile.healthCareProvinceCode, userProfile.healthNumber) &&
										!this.isFakeHealthCardNumber(userProfile.healthCareProvinceCode, userProfile.healthNumber);
				}
				else
				{
					return !required;
				}
			},
			object,
			property,
			" * Invalid Format",
			VALIDATION_TYPE.PATIENT_HEALTH_NUMBER));

		return healthNumberValidation;
	}

	/**
	 * construct and return phone validation object.
	 * @param object - object to validate
	 * @param property - the property of the object to check.
	 * @param required - if false validate null and undefined values as true.
	 */
	public static phoneNumber(object: any, property: string, required: boolean): ValidationList
	{
		let phoneValidations = ValidationList.create();
		phoneValidations.push(Validation.buildValidation(
			(num) =>
			{
				if (num == null || num === "")
				{
					return true;
				}
				else
				{
					const digitString = [...num].filter((char) => char.match(/\d/));
					return (digitString.length < 12 && PhoneFormatUtils.isCorrectFormat(num));
				}
			}, object, property, " * Must be of format (xxx) xxx-xxxx", VALIDATION_TYPE.PATIENT_CELL));

		if (required)
		{
			phoneValidations = phoneValidations.concat(this.required(object, property));
		}
		return phoneValidations;
	}

	/**
	 * validate that a date of birth is valid
	 * @param object
	 * @param property
	 */
	public static dateOfBirth(object: any, property: string): ValidationList
	{
		const validationList = ValidationList.create();
		validationList.push(
			Validation.buildValidation((date) =>
			{
				return date === null || date === "" || moment(date).isValid();
			}, object, property, "* Invalid date of birth", VALIDATION_TYPE.PATIENT_DOB));

		return validationList;
	}

	/**
	 * construct required validation. Validating that the property is not null or undefined
	 * @param object - object to check
	 * @param property - property to check
	 * @param message - the error message to emit when field is not present.
	 */
	public static required(object: any, property: string, message = ""): ValidationList
	{
		const requiredValidation = ValidationList.create();
		requiredValidation.push(Validation.buildValidation((num) => num, object, property, message, VALIDATION_TYPE.REQUIRED_FIELD));
		return requiredValidation;
	}

	/**
	 * Validate if the duplicate string matches the reference.  If a validation function is provided, then
	 * validate the duplicate string using that validation function.
	 *
	 * @param reference original string
	 * @param duplicate string to match
	 * @param validation (optional) validation to run on the duplicate
	 */
	public static matches(reference: string, duplicate: string, validation: (string) => boolean): boolean
	{
		const result = reference === duplicate;

		if (validation)
		{
			return result && validation.call(this, duplicate);
		}

		return result;
	}

	public static date(value: string): boolean
	{
		// eslint-disable-next-line no-useless-escape
		const regex = RegExp(/^\d{4}[\-]((((0[13578])|(1[02]))[\-](([0-2][0-9])|(3[01])))|(((0[469])|(11))[\-](([0-2][0-9])|(30)))|(02[\-][0-2][0-9]))$/);

		return regex.test(value);
	}

	/**
	 * Validate health numbers via checksum only.  Does not account for if the number is actually issued or valid
	 * for a specific patient.
	 * @param provinceCode Two letter province code
	 * @param cardNumber Personal health number
	 * @param versionCode Optional version code
	 */
	public static healthCardNumber(provinceCode: string, cardNumber: string): boolean
	{
		if (!Validator.isPresent(cardNumber))
		{
			return false;
		}

		switch (provinceCode)
		{
			case "BC":
			{
				return Validator.validateHealthCardBC(cardNumber);
			}
			case "ON":
			{
				return Validator.validateHealthCardON(cardNumber);
			}
			default:
			{
				return true;
			}
		}
	}

	/**
	 * check if the health card number is an "obvious" fake.
	 * This is defined as:
	 * Any HIN that is a single number repeated (000000000, 222222222)
	 * Any HIN that is a solid sequence (123456789, 123454321, 112233445)
	 * Any HIN that is two digits repeated (121212121)
	 * @param provinceCode - the province of the health card
	 * @param cardNumber - the card number to check
	 */
	public static isFakeHealthCardNumber(provinceCode: string, cardNumber: string): boolean
	{
		if (process.env.NODE_ENV !== "development" && !["BC", "ON"].includes(provinceCode))
		{
			let lastDigit = cardNumber.charAt(0);
			let sequence = true;
			for (const digit of cardNumber)
			{
				if (isNaN(parseInt(digit, 10)))
				{
					continue;
				}

				// check for repeated, two digits or sequence
				if (Math.abs(parseInt(lastDigit, 10) - parseInt(digit, 10)) > 1)
				{
					sequence = false;
				}

				lastDigit = digit;
			}

			return sequence;
		}
		return false;
	}

	/**
	 * Validate BC health card number
	 * @param cardNumber
	 */
	private static validateHealthCardBC(cardNumber)
	{
		if (cardNumber.length >= 10)
		{
			return this.validateHealthCardBCMod11(cardNumber);
		}
		else if (cardNumber.length === 8)
		{
			return this.numberAlphaCheck(cardNumber);
		}
		else
		{
			return this.validateHealthCardBCMod10(cardNumber);
		}
	}

	/**
	 * Validate BC PHN numbers
	 * https://www2.gov.bc.ca/assets/gov/health/practitioner-pro/software-development-guidelines/app_d.pdf
	 * @param phn BC health number
	 */
	private static validateHealthCardBCMod11(phn: string): boolean
	{
		// Spec states that health numbers are 13 digits, from  which leading zeroes that should be ignored.
		// Health cards have 10 digits on them.  If the leading zeroes are stripped from the 13 digit number,
		// It should match the health card number.  We'll strip any leading zeroes just to be safe.

		phn = phn.replace(/^0+/, "");

		if (!Validator.numberLengthCheck(phn, 10) ||
			!Validator.numberAlphaCheck(phn))
		{
			return false;
		}

		// Spec states the the first non-zero number must be 9, so might as well check for it.
		if (parseInt(phn.substring(0, 1), 10) !== 9)
		{
			return false;
		}

		const checkSumDigit = parseInt(phn.substring(9, 10), 10);
		const digitWeights = [2, 4, 8, 5, 10, 9, 7, 3];
		let sum = 0;

		Array.from(phn.substring(1, 9)).forEach((digit, index) =>
		{
			const element = parseInt(digit, 10);
			sum += (element * digitWeights[index]) % 11;
		});

		return (11 - (sum % 11)) === checkSumDigit;
	}

	//

	/**
	 * Deprecated Medical Services Plan Correctional Services Number.  This has been replaced by PHN (Mod11 Scheme)
	 * https://www2.gov.bc.ca/assets/gov/health/practitioner-pro/medical-services-plan/teleplan-v4-4.pdf
	 * Section 1.14.3
	 * @param cardNumber
	 */
	private static validateHealthCardBCMod10(cardNumber: string): boolean
	{
		// This number is usually 9 digits.  If less than 9, it should be left padded with 0s
		if (cardNumber.length < 9)
		{
			cardNumber = cardNumber.padStart(9, "0");
		}

		if (!Validator.numberLengthCheck(cardNumber, 9) ||
			!Validator.numberAlphaCheck(cardNumber))
		{
			return false;
		}

		const checkSumDigit = this.digitAt(cardNumber, 8);

		let sumA = 0;
		for (let i = 6; i >= 0; i -= 2)
		{
			sumA += this.digitAt(cardNumber, i);
		}

		let sumB = 0;
		for (let j = 7; j > 0; j -= 2)
		{
			sumB += this.doubleAndAddDigitAt(cardNumber, j);
		}

		const sumC = (sumA + sumB).toString();
		return (10 - parseInt(sumC.slice(-1), 10)) === checkSumDigit;
	}

	/**
	 * Validate ON health card numbers
	 * http://health.gov.on.ca/english/providers/pub/ohip/tech_specific/pdf/5_13.pdf
	 * @param cardNumber ON health number
	 */
	private static validateHealthCardON(cardNumber: string): boolean
	{
		if (!Validator.numberLengthCheck(cardNumber, 10) ||
			!Validator.numberAlphaCheck(cardNumber))
		{
			return false;
		}

		let sum = 0;
		const checkSumDigit = parseInt(cardNumber.charAt(9), Validator.BASE_10);
		Array.from(cardNumber.substring(0, 9)).forEach((digitStr, index) =>
		{
			let digit = parseInt(digitStr, Validator.BASE_10);

			if ((index % 2) === 0)
			{
				const doubled = digit * 2;
				digit = (Math.floor(doubled / 10)) + (doubled % 10);
			}
			sum += digit;
		});
		return (checkSumDigit === ((10 - (sum % 10)) % 10));
	}

	/**
	 * Return false if the string representation of a number is not the specified length
	 * @param number
	 * @param length
	 */
	private static numberLengthCheck(number: string, length: number): boolean
	{
		return number.length === length;
	}

	/**
	 * Return false if the string representation of a number contains an alphabetic character;
	 * @param number
	 */
	private static numberAlphaCheck(number: string): boolean
	{
		return !isNaN(parseInt(number, 10));
	}

	/**
	 * Return the digit at the specified index
	 * @param number
	 * @param index
	 */
	private static digitAt(number: string, index: number): number
	{
		return parseInt(number[index], 10);
	}

	/**
	 * Double the digit at index, then add the digits of the result together.
	 * ie:  7 ==> (7 * 2) ==> 14 ==> 1 + 4 ==> 5
	 * @param number
	 * @param index
	 */
	private static doubleAndAddDigitAt(number: string, index: number)
	{
		const doubled = parseInt(number[index], 10) * 2;
		return Math.floor(doubled / 10) + doubled % 10;
	}
}
