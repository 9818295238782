import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrgImage = _resolveComponent("OrgImage")!
  const _component_WhitelabelHeaderBar = _resolveComponent("WhitelabelHeaderBar")!
  const _component_HeaderBarForgot = _resolveComponent("HeaderBarForgot")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_CorporateFooter = _resolveComponent("CorporateFooter")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.forgotPassword && _ctx.forgotPassword(...args)), ["prevent"]))
  }, [
    _createVNode(_component_PageWrapper, {
      backgroundImage: _ctx.isWhiteLabelMode,
      backgroundHideOverflow: "",
      height: "100vh"
    }, _createSlots({
      "content-header": _withCtx(() => [
        (_ctx.isWhiteLabelMode)
          ? (_openBlock(), _createBlock(_component_WhitelabelHeaderBar, {
              key: 0,
              "organization-id": _ctx.organization.id
            }, null, 8, ["organization-id"]))
          : (_openBlock(), _createBlock(_component_HeaderBarForgot, {
              key: 1,
              headerText: this.$mhat('LoginForgot.HeaderText')
            }, null, 8, ["headerText"]))
      ]),
      _: 2
    }, [
      (_ctx.isWhiteLabelMode)
        ? {
            name: "background",
            fn: _withCtx(() => [
              _createVNode(_component_OrgImage, {
                "asset-type": _ctx.ORG_ASSET_TYPE.ODB_HERO_IMAGE,
                "alt-image": _ctx.backgroundImage,
                "atl-text": "Hero Image"
              }, null, 8, ["asset-type", "alt-image"])
            ])
          }
        : undefined,
      (!_ctx.resetSent)
        ? {
            name: "content-top",
            fn: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass({'whitelabel-content': _ctx.isWhiteLabelMode})
              }, [
                _createElementVNode("p", {
                  class: _normalizeClass({'text-light': _ctx.isWhiteLabelMode})
                }, _toDisplayString(this.$mhat("LoginForgot.ForgotPasswordInstructionText")), 3),
                _createVNode(_component_BaseInput, {
                  compact: "",
                  placeholder: this.$mhat('LoginForgot.EmailFieldText'),
                  label: this.$mhat('LoginForgot.EmailFieldText'),
                  hideLabel: true,
                  id: "email",
                  value: _ctx.email,
                  valid: _ctx.validEmail,
                  "padding-left": "",
                  "icon-left": "icon-user",
                  onInput: _ctx.updateEmail
                }, null, 8, ["placeholder", "label", "value", "valid", "onInput"]),
                _createVNode(_component_BaseButton, {
                  primary: "",
                  type: "submit",
                  disabled: !_ctx.validEmail
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(this.$mhat("LoginForgot.ContinueButtonText")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ], 2)
            ])
          }
        : {
            name: "content-center",
            fn: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass({'whitelabel-content': _ctx.isWhiteLabelMode})
              }, [
                _createVNode(_component_EventStateSpinner, {
                  showFailureState: _ctx.isError,
                  isLoading: _ctx.isLoading,
                  loadingTitle: "Working...",
                  successTitle: this.$mhat('LoginForgot.SuccessMessage'),
                  successMessage: _ctx.emailSentMessage,
                  failureTitle: this.$mhat('LoginForgot.FailureMessage'),
                  failureMessage: _ctx.errorMessage,
                  "text-color-light": _ctx.isWhiteLabelMode
                }, null, 8, ["showFailureState", "isLoading", "successTitle", "successMessage", "failureTitle", "failureMessage", "text-color-light"]),
                (!_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_RouteButton, {
                      key: 0,
                      primary: "",
                      route: _ctx.homeRoute
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(this.$mhat("LoginForgot.ReturnToLoginButtonText")), 1)
                      ]),
                      _: 1
                    }, 8, ["route"]))
                  : _createCommentVNode("", true)
              ], 2)
            ])
          },
      (!_ctx.isWhiteLabelMode)
        ? {
            name: "content-bottom",
            fn: _withCtx(() => [
              _createVNode(_component_CorporateFooter)
            ])
          }
        : {
            name: "content-footer",
            fn: _withCtx(() => [
              _createVNode(_component_CorporateFooter, {
                branded: "",
                "brand-white-bg": "",
                "show-support-link": false
              })
            ])
          }
    ]), 1032, ["backgroundImage"])
  ], 32))
}