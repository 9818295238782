import BaseService from "@/lib/services/BaseService";
import MhaDocument from "@/lib/document/model/MhaDocument";
import {ClinicAPI} from "@/lib/services/Api";
import MhaDocumentToRemoteDocumentCreateTransferConverter
	from "@/lib/document/conversion/MhaDocumentToRemoteDocumentCreateTransferConverter";

export default class RemoteDocumentService extends BaseService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * save a document to the patients chart
	 * @param patientId - the patient to whom the document will be saved
	 * @param document - the document to save
	 */
	public async saveDocumentToPatientChart(patientId: string, document: MhaDocument): Promise<void>
	{
		await ClinicAPI().clinicSaveDocumentToPatientChart(patientId, await (new MhaDocumentToRemoteDocumentCreateTransferConverter()).convert(document));
	}
}
