

	import {Options, Vue, Prop} from "vue-property-decorator";
	import BackgroundStyleWrapper from "@/components/Cosmetic/BackgroundStyleWrapper.vue";
	import {IonContent} from "@ionic/vue";

	@Options({components: {BackgroundStyleWrapper, IonContent}})
	export default class ContentWrapper extends Vue
	{
		inheritAttrs: false;

		/* === Content General Options === */

		// if true a slot called #content will be provided. This slot fills the enter height of the screen.
		@Prop({default: false, type: Boolean}) contentFullHeight: boolean;

		// prevent notch correction padding on the ion-content tag. for embedded/nested pages
		@Prop({default: false, type: Boolean}) contentNotchCorrectionDisabled: boolean;

		// different content width options
		@Prop({default: true, type: Boolean}) contentWidthMobile: boolean;
		@Prop({default: false, type: Boolean}) contentWidthMid: boolean;
		@Prop({default: false, type: Boolean}) contentWidthFull: boolean;

		@Prop({type: String}) contentWidthOverride: string;
		@Prop({type: Object}) contentStyleOverride: any;

		// content content alignment options
		@Prop({default: false, type: Boolean}) contentAlignLeft: boolean;
		@Prop({default: true, type: Boolean}) contentAlignCenter: boolean;
		@Prop({default: false, type: Boolean}) contentAlignRight: boolean;

		/* === Content Header Options === */

		// set this if you don't want padding on the content header
		// overrides contentPaddingHorizontal
		@Prop({default: false, type: Boolean}) contentHeaderPaddingDisabled: boolean;

		// set this to false if you really really don't want the false header space.
		// removing this will throw off alignment of top aligned content (top/center slots)
		// with other pages that include a header or header space
		@Prop({default: false, type: Boolean}) contentHeaderRemoveFalseHeaderSpace: boolean;

		@Prop(Boolean) disableBackground: boolean;

		/* === Content Body Options === */

		// set this if you don't want padding on the content body
		@Prop({default: false, type: Boolean}) contentBodyPaddingDisabled: boolean;
		// set this if you don't want the extra margin on the bottom of the content body
		@Prop({default: false, type: Boolean}) contentBodyBottomMarginDisabled: boolean;

		get contentClass()
		{
			const {contentNotchCorrectionDisabled, disableBackground} = this;
			return {
				"no-notch-correct": contentNotchCorrectionDisabled,
				"background-disabled": disableBackground,
			};
		}

		get contentHeaderClass()
		{
			const {contentHeaderPaddingDisabled, contentHeaderRemoveFalseHeaderSpace} = this;
			const classList = [];

			if (contentHeaderRemoveFalseHeaderSpace)
			{
				classList.push("no-header-space");
			}
			if (!contentHeaderPaddingDisabled)
			{
				classList.push("padding-horizontal");
			}
			return classList.join(" ");
		}

		get contentBodyClass()
		{
			const {contentWidthMobile, contentWidthMid, contentWidthFull} = this;
			if (contentWidthFull)
			{
				return "content-width-full";
			}
			else if (contentWidthMid)
			{
				return "content-width-mid";
			}
			else if (contentWidthMobile)
			{
				return "content-width-mobile";
			}
			return null;
		}

		get contentBodyBottomSlotClass()
		{
			const {contentBodyBottomMarginDisabled} = this;
			const classList = [];

			classList.push(this.contentBodySlotClass);
			if (!contentBodyBottomMarginDisabled)
			{
				classList.push("extra-margin-bottom");
			}
			return classList.join(" ");
		}

		get contentBodySlotClass()
		{
			const {contentBodyPaddingDisabled} = this;
			if (!contentBodyPaddingDisabled)
			{
				return "padding-horizontal";
			}
			return null;
		}

		get contentBodyStyle()
		{
			if (this.contentWidthOverride)
			{
				return {
					maxWidth: this.contentWidthOverride,
				};
			}
		}
	}
