import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a8b34b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-left" }
const _hoisted_2 = { class: "header-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ActionButton = _resolveComponent("ActionButton")!
  const _component_CloseButton = _resolveComponent("CloseButton")!
  const _component_HeaderBar = _resolveComponent("HeaderBar")!

  return (_openBlock(), _createBlock(_component_HeaderBar, {
    overlay: _ctx.overlay,
    headerStyleOverride: _ctx.headerStyleOverride
  }, {
    left: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.noBack && _ctx.useIonicBack)
          ? (_openBlock(), _createBlock(_component_ion_back_button, {
              key: 0,
              mode: "ios",
              text: "",
              onClick: _ctx.handleClickBack,
              "default-href": _ctx.defaultBackRoute
            }, null, 8, ["onClick", "default-href"]))
          : (!_ctx.noBack && !_ctx.useIonicBack)
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 1,
                class: "back-arrow-icon",
                icon: _ctx.arrowBack,
                onClick: _ctx.handleClickBack
              }, null, 8, ["icon", "onClick"]))
            : _createCommentVNode("", true),
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.icon)
        }, null, 2),
        _createElementVNode("div", {
          class: "header-text",
          style: _normalizeStyle({fontSize: _ctx.fontSize})
        }, _toDisplayString(_ctx.headerText), 5)
      ])
    ]),
    right: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.buttonOpts)
          ? (_openBlock(), _createBlock(_component_ActionButton, {
              key: 0,
              opts: _ctx.buttonOpts
            }, null, 8, ["opts"]))
          : (_ctx.hasClose)
            ? (_openBlock(), _createBlock(_component_CloseButton, {
                key: 1,
                onClick: _ctx.handleClose
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["overlay", "headerStyleOverride"]))
}