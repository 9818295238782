import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e9a1cf3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "clinic-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectMenu = _resolveComponent("SelectMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SelectMenu, {
      "hide-label": "",
      id: "clinic-filter",
      options: _ctx.filtersAsOptions,
      selected: _ctx.toSelectOpt(_ctx.activeFilter),
      "input-select": _ctx.activeFilter,
      "input-value": _ctx.inputValue,
      nostyle: "",
      onSelection: _ctx.updateFilter,
      onInput: _ctx.onInput
    }, null, 8, ["options", "selected", "input-select", "input-value", "onSelection", "onInput"])
  ]))
}