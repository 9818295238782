export enum TELEHEALTH_ERROR_TYPE {
	GENERIC,
	LOGIC_ERROR,
	CONNECTION_ERROR,
	COMMUNICATION_ERROR,
	STREAMING_ERROR,
	NO_SUCH_REMOTE,
	PERMISSION_ERROR,
	NOT_IMPLEMENTED,
	ENGINE_DESTROYED_ERROR,
	ENGINE_COMPONENT_NOT_LOADED,
}

export default class TelehealthError extends Error
{
	protected _type: TELEHEALTH_ERROR_TYPE;

	constructor(type: TELEHEALTH_ERROR_TYPE, msg: string)
	{
		super(msg);
		this._type = type;
	}

	get type(): TELEHEALTH_ERROR_TYPE
	{
		return this._type;
	}
}
