import {RouteLocation, RouteLocationRaw} from "vue-router";
import {EventBus} from "@/lib/utils/EventBus";

export enum NotificationType
{
	Confirm = "confirm",
	Dismiss = "dismiss",
	Swipe = "swipe",
}

export enum NotificationSeverity
{
	Critical = "critical",
	Warning = "warning",
	Reminder = "reminder",
}

export interface NotifyAction
{
	message: string;
	callback?: () => void;
}

export enum NotifyEvent
{
	Generic = "GenericNotification",
	AccountConfirmed = "AccountConfirmed",
	BetaSite = "BetaSite",
	ProfileUpdateError = "ProfileUpdateError",
	PasswordResetError = "PasswordResetError",
	InvalidLogin = "InvalidLogin",
	EmailTaken = "EmailTaken",
	NetworkError = "NetworkError",
	NoClinics = "NotifyNoClinics",
	NoAppointments = "NoAppointments",
	NoInternet = "NoInternet",
	ConnectionErrorMHA = "ConnectionErrorMHA",
	ConnectionErrorJuno = "ConnectionErrorJuno",
	AppointmentReminder = "AppointmentReminder",
	AppointmentStarted = "AppointmentStarted",
	TeleHealthCall = "TeleHealthCall",
	ConfirmDeactivate = "ConfirmDeactivate",
	CancelClinicConnection = "CancelClinicConnection",
	ClinicRequestSent = "ClinicRequestSent",
	NoPatientRecordFound = "NoPatientRecordFound",
}

export interface Notification
{
	event: NotifyEvent;
	type: NotificationType;
	severity: NotificationSeverity;
	title: string;
	message: string;
	confirm?: NotifyAction;
	dismiss?: NotifyAction;
	route?: RouteLocationRaw;
	timeout?: number;
	icon?: string;
}

export const WebNotificationEvent = "WebNotificationEvent";
export const WebNotificationClear = "WebNotificationClear";

export const WebNotification = {

	$emit(notification: Notification)
	{
		EventBus.$emit(WebNotificationEvent, notification);
	},

	$on(notifyEvent: NotifyEvent, callback: (notification) => void)
	{
		EventBus.$on(WebNotificationEvent, (notification: Notification) =>
		{
			if (notification.event === notifyEvent)
			{
				callback(notification);
			}
		});
	},

	$all(callback: (notification) => void)
	{
		EventBus.$on(WebNotificationEvent, callback);
	},

	$clear(event?: NotifyEvent)
	{
		EventBus.$emit(WebNotificationClear, event);
	},

	sendGenericTimeoutNotifier(title: string, message: string, timeout = 2500)
	{
		if (message)
		{
			WebNotification.$emit({
				event: NotifyEvent.Generic,
				type: NotificationType.Swipe,
				severity: NotificationSeverity.Reminder,
				title,
				message,
				timeout,
			});
		}
	},
};
