import {Moment} from "moment";
import {MediaDevicePermissions} from "@/lib/mediaDevices";
import {UserType} from "@/open_api/generated";

export interface DeviceInfo
{
	name: string;
	version: string;
	os: string;
}

export enum TeleCallState
{
	PENDING = "pending",
	INBOUND = "inbound",
	OUTBOUND = "outbound",
	IN_CALL = "in_call",
	CALL_ENDED = "call_ended",
}

export interface PeerData extends MediaDevicePermissions
{
	device: DeviceInfo;
	email: string;
	firstName: string;
	lastName: string;
	userType: UserType;
}

export interface VideoControlState
{
	visible: boolean;
	canEnd: boolean;
	videoEnabled: boolean;
	muted: boolean;
	callTime: string;
	disabled: boolean;
	handleMouseMove?: (event: MouseEvent) => void;
	handleMouseUp?: (event: MouseEvent) => void;
}

export enum MessageOrigin
{
	Received = "RECEIVED",
	Sent = "SENT",
}

export enum ChatItemType
{
	MESSAGE, TYPING, EVENT, DOCUMENT
}

export interface ChatItem
{
	id?: string;
	type: ChatItemType;
	message: string;
	receivedAt?: Moment;
	messageOrigin?: MessageOrigin;
}
