
	import {Options, Prop, Vue} from "vue-property-decorator";
	import ModalBaseController from "@/lib/modal/modalBaseController";
	import BaseModal from "@/components/Modals/BaseModal.vue";
	import MhaDocument from "@/lib/document/model/MhaDocument";
	import DocumentPreview from "@/components/Documents/DocumentPreview.vue";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import SmallButton from "@/components/Buttons/SmallButton.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import PageWrapper from "@/components/Layout/PageWrapper.vue";

	@Options({components: {PageWrapper, SmallButton, BaseButton, DocumentPreview, BaseModal}})
	export default class DocumentPreviewModal extends ModalBaseController
	{
		@Prop({type: Object, required: true}) document: MhaDocument;

		public ButtonColorPattern = ButtonColorPattern;
		public ButtonColor = ButtonColor;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public downloadDocument(): void
		{
			this.document.saveToLocalComputer();
		}
	}
