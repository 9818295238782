

	import {Options, Vue, Prop} from "vue-property-decorator";
	import AuthStore from "@/lib/vuex/auth.store";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";

	@Options({})
	export default class Error extends Vue
	{
		@Prop({type: Object}) error: ErrorResponse;

		get firstName(): string
		{
			return AuthStore.loggedInClinic?.firstName;
		}

		get errorMessage()
		{
			if (this.error)
			{
				return this.error.message;
			}
			return "An unknown error has occurred";
		}
	}
