

	import {Options, Vue, Prop} from "vue-property-decorator";
	import DefaultPopover from "@/components/Popover/DefaultPopover.vue";
	import {ClinicPatientAccessDto, PatientUserDto} from "@/open_api/generated";
	import moment from "moment";
	import {DateUtils} from "@/lib/utils/Date";
	import {ClinicAPI} from "@/lib/services/Api";
	import {popoverController} from "@ionic/vue";

	@Options({components: {DefaultPopover}})
	export default class VerificationCancelPopover extends Vue
	{
		@Prop({type: Object}) public patient: PatientUserDto;
		@Prop({type: Object}) public connectionStatus: ClinicPatientAccessDto;

		public popoverController = popoverController;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async cancelVerification(): Promise<void>
		{
			await ClinicAPI().cancelVerifyPatientClinicConnection(this.patient.id);
			await popoverController.dismiss(true);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get verificationDate(): string
		{
			return DateUtils.humanDateString(moment(this.connectionStatus.clinic_verified_at));
		}
	}
