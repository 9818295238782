
import {Options, Vue, Prop, Watch} from "vue-property-decorator";
import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import ClinicView from "@/views/patient_user/clinic/ClinicView.vue";
import {RouteType} from "@/router/types";
import { Patient } from "@/lib/patient/Patient";
import PatientService from "@/lib/services/PatientService";
import {modalController} from "@ionic/vue";

@Options({})
export default class ClinicModalController extends Vue
{
	@Prop() clinicId: string;
	@Prop({type: String, default: null})
	public dependentId?: string;

	protected patientUser: Patient = null;
	clinicModal: HTMLIonModalElement;

	// ==========================================================================
	// Vue lifecycle hooks
	// ==========================================================================

	async created()
	{
		this.patientUser = await PatientService.getDependentOrParentById(this.dependentId);
		this.afterCreated();
	}

	public afterCreated()
	{
		// override to run code after created vue life cycle hook.
	}

	beforeUnmount()
	{
		this.dismissModal();
	}

	// ==========================================================================
	// Vue Hooks
	// ==========================================================================

	@Watch("clinics")
	onClinicsChange(newClinics): void
	{
		if (newClinics && this.clinicId && !this.clinicModal)
		{
			this.presentModal(this.clinicId);
		}
	}

	@Watch("clinicId")
	onClinicIdChange(newId: string)
	{
		if (newId)
		{
			this.presentModal(newId);
		}
		else
		{
			this.dismissModal();
		}
	}

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	async presentModal(clinicId: string)
	{
		await this.dismissModal();

		const clinic = this.clinics.find((c) => c.id === clinicId);

		if (clinic)
		{
			this.clinicModal = await modalController
				.create({
					component: ClinicView,
					cssClass: "fullscreen",
					showBackdrop: true,
					componentProps: {
						parentCtx: this,
						clinicId: clinic.id,
						patientUser: this.patientUser,
					},
				});

			const routeName = this.$route.name;
			this.clinicModal.onDidDismiss()
				.then((dismissResult) =>
				{
					// if dismissResult.data === true the modal is telling us not to route. It has already triggered a routing operation.
					if (this.$route.name === routeName && !dismissResult.data)
					{
						this.clinicModal = null;
						this.$mhaRouterReplace({name: routeName as RouteType, params: {clinicId: undefined}});
					}
				});

			await this.clinicModal.present();
		}
	}

	async dismissModal()
	{
		if (this.clinicModal)
		{
			await this.clinicModal.dismiss();
			this.clinicModal = null;
		}
	}

	// Implement in Vue templates
	get clinics(): ClinicProfile[]
	{
		return [];
	}
}
