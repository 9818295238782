import {Module, getModule, VuexModule, MutationAction, Action} from "vuex-module-decorators";
import {Patient} from "@/lib/patient/Patient";
import store from "@/lib/vuex/store";
import DependentService from "@/lib/dependents/Dependent.service";
import {PatientProfileMap} from "@/lib/types/Profile";

@Module({dynamic: true, store, name: "dependentStore", namespaced: true})
class DependentStore extends VuexModule
{
	_dependents: Patient[] = [];

	@MutationAction({ mutate: ["_dependents"]})
	public async fetchDependents()
	{
		try
		{
			const dependents = await DependentService.getDependents();
			return {_dependents: dependents};
		}
		catch (error)
		{
			console.log(error);
		}
	}

	get dependentMap(): PatientProfileMap
	{
		return this._dependents.reduce((acc, dependent) =>
		{
			acc[dependent.id] = dependent;
			return acc;
		}, {} as PatientProfileMap);
	}

	get hasDependents(): boolean
	{
		return this.dependents.length > 0;
	}

	get dependents()
	{
		return this._dependents;
	}

	get dependent(): (string) => Patient
	{
		return (dependentId: string) =>
		{
			return this.dependentMap[dependentId];
		};
	}
}

export default getModule(DependentStore);
