import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-935d73d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-view-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageCompose = _resolveComponent("MessageCompose")!
  const _component_MessageRead = _resolveComponent("MessageRead")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.controller.isComposing)
      ? (_openBlock(), _createBlock(_component_MessageCompose, {
          key: 0,
          clinics: _ctx.controller.messageableClinics,
          replying: _ctx.controller.isReplying,
          "mail-reply": _ctx.controller.activeMail,
          "is-loading": _ctx.controller.isLoading,
          onSend: _cache[0] || (_cache[0] = (composed) => _ctx.onSendMail(composed)),
          onCancel: _ctx.onCancelCompose
        }, null, 8, ["clinics", "replying", "mail-reply", "is-loading", "onCancel"]))
      : (_ctx.controller.activeMail)
        ? (_openBlock(), _createBlock(_component_MessageRead, {
            key: 1,
            mail: _ctx.controller.activeMail,
            onArchive: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onArchive())),
            onUnarchive: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onUnArchive())),
            onReply: _cache[3] || (_cache[3] = ($event: any) => (_ctx.controller.replyToActive())),
            onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dismissModal()))
          }, null, 8, ["mail"]))
        : _createCommentVNode("", true)
  ]))
}