import {Moment} from "moment";
import {FileSharerPlugin} from "@byteowls/capacitor-filesharer";
import {Capacitor, Plugins} from "@capacitor/core";
import IllegalStateError from "@/lib/error/IllegalStateError";
import FileUtil from "@/lib/utils/FileUtil";

export default class MhaDocument
{
	private readonly _id: string;
	private readonly _name: string;
	private readonly _type: string;
	private readonly _description: string;
	private readonly _createdAt: Moment;
	private readonly _updatedAt: Moment;
	private readonly _url: string;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(id: string,
	                   name: string,
	                   type: string,
	                   description: string,
	                   createdAt: Moment,
	                   updatedAt: Moment,
	                   url: string)
	{
		this._id = id;
		this._name = name;
		this._type = type;
		this._description = description;
		this._createdAt = createdAt;
		this._updatedAt = updatedAt;
		this._url = url;
	}

	/**
	 * save this document to the persons local computer
	 */
	public async saveToLocalComputer(): Promise<void>
	{
		let fileSharer: FileSharerPlugin = null;

		if (Capacitor.isPluginAvailable("FileSharerPlugin"))
		{
			fileSharer = Plugins.FileSharerPlugin as FileSharerPlugin;
		}
		else if (Capacitor.isPluginAvailable("FileSharer"))
		{
			fileSharer = Plugins.FileSharer as FileSharerPlugin;
		}
		else
		{
			throw new IllegalStateError("No file sharer plugin available.");
		}

		await fileSharer.share({
			filename: this.name,
			base64Data: await FileUtil.getAuthenticatedFileAsBase64(this.url),
			contentType: this.type,
		});
	}

	/**
	 * get document data in base64
	 */
	public async base64Data(): Promise<string>
	{
		return await FileUtil.getAuthenticatedFileAsBase64(this.url);
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get id(): string
	{
		return this._id;
	}

	get name(): string
	{
		return this._name;
	}

	get type(): string
	{
		return this._type;
	}

	get description(): string
	{
		return this._description;
	}

	get createdAt(): Moment
	{
		return this._createdAt;
	}

	get updatedAt(): Moment
	{
		return this._updatedAt;
	}

	get url(): string
	{
		return this._url;
	}

	get canPreview(): boolean
	{
		const type = this.type;
		return !!type.match("^image/.*");
	}

	get isPdf(): boolean
	{
		return this.type === "application/pdf";
	}
}
