

	import {Options, Vue, Prop} from "vue-property-decorator";

	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {NotifyEvent, WebNotification} from "@/lib/types/Notifier";
	import PatientProfileModal from "@/views/patient_user/clinic/components/PatientProfileModal.vue";
	import defaultBackground from "../../../assets/images/graphics/clinics/image-clinic@2x.png";
	import ClinicLogo from "@/views/patient_user/clinic/components/ClinicLogo.vue";
	import ClinicOperatingInfo from "@/views/patient_user/clinic/components/ClinicOperatingInfo.vue";
	import DeviceInfo from "@/lib/DeviceInfo";
	import ClinicContactInfo from "@/views/patient_user/clinic/components/ClinicContactInfo.vue";
	import ClinicInfoContainer from "@/views/patient_user/clinic/components/ClinicInfoContainer.vue";
	import ClinicProviderInfo from "@/views/patient_user/clinic/components/ClinicProviderInfo.vue";
	import ClinicAboutInfo from "@/views/patient_user/clinic/components/ClinicAboutInfo.vue";
	import ClinicViewHeader from "@/views/patient_user/clinic/components/ClinicViewHeader.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import {Patient} from "@/lib/patient/Patient";
	import {modalController} from "@ionic/vue";

	@Options({
		components: {
			ClinicAboutInfo,
			ClinicProviderInfo,
			ClinicInfoContainer,
			ClinicViewHeader,
			PatientProfileModal,
			ClinicLogo,
			ClinicContactInfo,
			ClinicOperatingInfo,
			DependentSubheader,
		},
	})
	export default class ClinicPage extends Vue
	{
		@Prop({type: Object}) public readonly clinic!: ClinicProfile;
		@Prop() public readonly patientUser: Patient;

		public beforeUnmount()
		{
			WebNotification.$clear(NotifyEvent.CancelClinicConnection);
		}

		public async closeModal()
		{
			if (modalController)
			{
				await modalController.dismiss();
			}
		}

		get isMobile()
		{
			return DeviceInfo.isMobile();
		}

		get clinicHeaderStyles()
		{
			return { backgroundImage: `url(${defaultBackground})` };
		}
	}
