import {HostType, RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";

const ClinicsFind = () => import(/* webpackChunkName: "Clinics" */ "@/views/patient_user/clinic/ClinicsFind.vue");

export enum DependentClinicsRoute
{
	FindClinic = "ClinicFindClinicDependent",
	FindParentClinics = "FindParentClinicsDependent",
}

export const dependentClinicsRoute: RouteRecordRaw[] = [
	{// like Clinics.FindClinic but, for dependents
		path: "/clinics/dependent/:dependentId/find/:clinicId?",
		name: DependentClinicsRoute.FindClinic,
		component: ClinicsFind,
		props: true,
		meta: {
			restrictHosts: [HostType.CLOUDMD],
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
	{// like  DependentClinics.FindClinic but, restricted to clinics connected to the parent
		path: "/clinics/dependent/:dependentId/parent/find/:clinicId?",
		name: DependentClinicsRoute.FindParentClinics,
		component: ClinicsFind,
		props: (route) =>
		{
			return {
				...route.params,
				restrictClinics: true,
			};
		},
		meta: {
			restrictHosts: [HostType.CLOUDMD],
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];
