

	import {Options, Vue, Prop} from "vue-property-decorator";
	import DeviceInfo from "@/lib/DeviceInfo";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {getResources} from "@/lib/utils/Resource";
	import {HomeRoute} from "@/router/patient_user/home.routes";

	@Options({components: {}})
	export default class HomePageHeaderBar extends Vue
	{
		@Prop({type: Boolean, default: true}) showDependents: boolean;
		@Prop({type: Boolean, default: true}) ShowAccountsDropdown: boolean;

		public dropDownActive = false;

		beforeUnmount()
		{
			document.querySelector("ion-content").onclick = undefined;
		}

		public dropDown()
		{
			this.dropDownActive = !this.dropDownActive;

			const windowSelector = document.querySelector("ion-content");
			windowSelector.onclick = this.dropDownActive ? this.checkClick.bind(this) : undefined;
		}

		public checkClick(e: MouseEvent)
		{
			const dropContainer = document.querySelector(".dropdown-container");

			if (!e.composedPath().includes(dropContainer))
			{
				this.dropDownActive = false;
			}
		}

		public logOut()
		{
			this.$emit("logout");
		}

		get appIcon()
		{
			return DeviceInfo.getAppFontIcon();
		}

		/**
		 * if true show a back to CMD/MHA button in place of the logout button
		 */
		get showBackToAppButton(): boolean
		{
			return OrganizationStore.isWhiteLabel && DeviceInfo.isNativeApp();
		}

		get logOutOptions(): any
		{
			return {
				icon: "icon-logout",
				text: this.$mhat("HomePageHeaderBar.LogOutText"),
				action: this.logOut.bind(this),
			};
		}

		get backToAppOptions(): any
		{
			return {
				icon: "icon-close",
				text: this.$mhat("HomePageHeaderBar.BackToAppText", {appName: getResources().app_name}),
				action: () => this.$mhaRouterPush(HomeRoute.Home),
			};
		}
	}
