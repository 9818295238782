
	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class BackgroundRibbon extends Vue
	{
		@Prop({type: String}) backgroundVerticalOffset: string;
		@Prop({type: String}) backgroundVerticalOverlap: string;

		get containerStyle()
		{
			return {
				"padding-top": this.backgroundVerticalOffset,
			};
		}

		get svgStyle()
		{
			return {
				"margin-top": this.backgroundVerticalOverlap ? "-" + this.backgroundVerticalOverlap : "0",
			};
		}

		get overlapStyle()
		{
			return {
				height: this.backgroundVerticalOverlap ? this.backgroundVerticalOverlap : "0",
			};
		}
	}

