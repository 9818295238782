
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ActionButtonOpts} from "@/components/Buttons/types";
	import {IonButton} from "@ionic/vue";

	@Options({
		name: "ActionButton",
		components: {IonButton},
	})
	export default class ActionButton extends Vue
	{
		@Prop() readonly opts: ActionButtonOpts;

		handleAction()
		{
			if (this.opts.action)
			{
				this.opts.action();
			}
			else
			{
				this.$emit("click");
			}
		}
	}
