import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecurityHeaderBar = _resolveComponent("SecurityHeaderBar")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_SecurityHeaderBar)
    ]),
    "content-top": _withCtx(() => [
      (_ctx.controller.userProfile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", null, _toDisplayString(this.$mhat("SecurityHome.UpdateEmailOrPasswordInstructionText")), 1),
            _createElementVNode("p", null, _toDisplayString(this.$mhat("SecurityHome.HowToProceedText")), 1),
            _createVNode(_component_TextField, {
              label: this.$mhat('SecurityHome.EmailFieldLabel'),
              id: "email",
              disabled: "",
              value: _ctx.controller.userProfile.email
            }, null, 8, ["label", "value"]),
            _createVNode(_component_PasswordField, {
              label: this.$mhat('SecurityHome.PasswordFieldLabel'),
              id: "currentPasswordFake",
              disabled: "",
              value: "****************"
            }, null, 8, ["label"])
          ]))
        : _createCommentVNode("", true)
    ]),
    "content-bottom": _withCtx(() => [
      _createVNode(_component_BaseButton, {
        pattern: _ctx.ButtonColorPattern.FILL,
        color: _ctx.ButtonColor.PRIMARY,
        onClick: _ctx.onChangePassword
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("SecurityHome.ChangePasswordButtonText")), 1)
        ]),
        _: 1
      }, 8, ["pattern", "color", "onClick"]),
      _createVNode(_component_BaseButton, { onClick: _ctx.onChangeEmail }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("SecurityHome.ChangeEmailButtonText")), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_BaseButton, {
        pattern: _ctx.ButtonColorPattern.TRANSPARENT,
        color: _ctx.ButtonColor.DANGER,
        onClick: _ctx.onDeactivate
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(this.$mhat("SecurityHome.DeactivateAccountButtonText")), 1)
        ]),
        _: 1
      }, 8, ["pattern", "color", "onClick"])
    ]),
    _: 1
  }))
}