import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    fill: "clear",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleAction())),
    class: "transparent"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.opts.text), 1),
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.opts.icon)
      }, null, 2)
    ]),
    _: 1
  }))
}