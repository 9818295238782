export const DATE_FORMAT = {
	MONTH_DISPLAY: "MMMM",
	MONTH_YEAR: "MMMM YYYY",
	DATE_STRING: "YYYY-MM-DD",
	DATETIME: "YYYY-MM-DDTHH:mm:ss",
	TIME: "HH:mm:ss",
	HUMAN_DATETIME: "YYYY-MM-DD h:mm a",
	H_MM_A: "h:mm a", // 9:30 am, 5:30 pm
};

export const mobileCutoffWidth = 768;

export enum WorkFlows
{
	"Home" = 1,
	"Account",
	"Appointments",
	"Booking",
	"Clinics",
	"Messages",
	"Login",
}

export enum ValidationRules
{
	UNKNOWN = "unknown",
	APPOINTMENT_AVAILABLE = "appointment_is_available",
	PERIOD_TYPE_CUTOFF_DAY = "cuttoff_day",
	PERIOD_TYPE_BLACKOUT_NOW_UNTIL_HOUR = "blackout_now_until_hour",
	PERIOD_TYPE_BLACKOUT_NOW_UNTIL_DAY = "blackout_now_until_day",
	PRIMARY_PROVIDER_ONLY = "primary_provider_only",
}
