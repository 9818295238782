import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "action-box left" }
const _hoisted_2 = {
  key: 0,
  class: "action-box"
}
const _hoisted_3 = {
  key: 1,
  class: "action-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createBlock(_component_ion_footer, { ref: "bottomNav" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["footer-bar", {single: $props.single, full: $props.full, two: $props.two}])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "left-link")
        ]),
        (!$props.single && !$props.two)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "center-link")
            ]))
          : _createCommentVNode("", true),
        (!$props.single)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "right-link")
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }, 512))
}