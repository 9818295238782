import {RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";
import uuidv4 from "uuid/v4";

const ClinicUserLogin = () => import(/* webpackChunkName: "ClinicUserLogin" */ "@/views/clinic_user/login/Login.vue");

export enum ClinicUserLoginRoute
{
	Home = "ClinicUserLoginHome",
}

export const clinicUserLoginRoutes: RouteRecordRaw[] = [
	{
		path: "/clinic_user/login",
		name: ClinicUserLoginRoute.Home,
		component: ClinicUserLogin,
		meta: {
			routeId: uuidv4(),
			routeAuth: RouteAuthType.NoAuth,
		},
	},
];
