
export default class ProviderGroup
{
	private _id: string;
	private _groupName: string;

	/**
	 * create provider group
	 * @param id - the id of the provider group
	 * @param groupName - the provider group name
	 */
	constructor(id: string, groupName: string)
	{
		this.id = id;
		this.groupName = groupName;
	}

	// ==========================================================================
	// Setters
	// ==========================================================================

	set id(value: string)
	{
		this._id = value;
	}

	set groupName(value: string)
	{
		this._groupName = value;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get id()
	{
		return this._id;
	}

	get groupName()
	{
		return this._groupName;
	}
}
