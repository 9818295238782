/**
 * Interface implemented by every telehealth engine. This interface abstracts the telehealth implementation away from
 * the telehealth GUI. Only talk to such engines through this interface.
 */
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import {ChatItem} from "@/views/patient_user/telehealth/types";
import LogEvent from "@/lib/telehealth/models/LogEvent";
import {TeleCallState} from "@/lib/telehealth/models/telehealth.types";
import {VideoDisplayMode} from "@/lib/telehealth/models/VideoDisplayMode";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";

export enum TELEHEALTH_STATE {
	NOT_CONNECTED = "unknown",
	OUT_OF_ROOM = "outOfRoom",
	IN_ROOM = "inRoom",
	INITIAL_STATE = OUT_OF_ROOM,
}

export enum AV_ERROR {
	PERMISSION_DENIED,
	HARDWARE_FAULT,
	NO_DEVICES,
	POOR_CONNECTION,
}

export default interface TelehealthInterface
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * call to initialize the telehealth engine
	 * @param initParam - engine specific initialization object
	 * @param videoSelector - a selector to be used to identify the DOM element in to which
	 * the video should be injected.
	 * @param videoPreviewSelector - a selector to be used to identify the DOM element in to which
	 * a preview of the users published video is to be displayed.
	 */
	initialize(initParam: any, videoSelector: string, videoPreviewSelector: string): Promise<void>;

	/**
	 * destroy the telehealth engine. YOU MUST CALL THIS or the telehealth session will
	 * continue to run in the background.
	 */
	destroy();

	/**
	 * enter the video room
	 */
	enterRoom(): Promise<void>;

	/**
	 * leave the video room
	 */
	leaveRoom(): Promise<void>;

	/**
	 * Call remote clients (Invite any clients in state, "OUT_OF_ROOM", in to the state "IN_ROOM").
	 * @param callDisplay - a string to be presented to the remote you are calling. Think a phone's call display.
	 * @return true / false to indicate if the remote has accepted the call or not. Note with multiple remotes
	 * if one accepts this method will return true
	 */
	callRemote(callDisplay: string): Promise<boolean>;

	/**
	 * just like callRemote but only calls a specific remote as indicated by remoteId
	 * @param remoteId - the remote client to call
	 */
	callSpecificRemote(remoteId: string): Promise<boolean>;

	/**
	 * Cancel an outgoing call. Basically this just notifies the remote clients that the call should be canceled.
	 * Currently what this would mean is that the incoming call alert will dismiss itself.
	 */
	cancelCallRemote(): Promise<void>;

	/**
	 * notify remote clients that the call has "ended". This is just an indication that the call has ended
	 * it does not effect the state of the call in any way. Different clients will act differently to this message.
	 * In the case of a patient they will transition to the call end screen and leave the Room.
	 */
	notifyRemotesOfCallEnd(): Promise<void>;

	/**
	 * change how the video from remote clients is presented to the user
	 * @param mode
	 */
	changeVideoDisplayMode(mode: VideoDisplayMode): void;

	/**
	 * enable video feed
	 */
	enableVideo(): void;

	/**
	 * disable video feed
	 */
	disableVideo(): void;

	/**
	 * cycle to the next available camera on the device.
	 */
	cycleCamera(): void;

	/**
	 * mute or unmute the mic
	 * @param mute
	 */
	muteMicrophone(mute: boolean): void;

	/**
	 * send a chat message to every one in the call.
	 * @param msg - the message to send
	 */
	sendChatMessage(msg: ChatMessage): Promise<void>;

	/**
	 * sends a typing signal to remote clients. Use this to indicate that this client is typing
	 */
	sendTypingSignal(): Promise<void>;

	/**
	 * kick a remote client
	 * @param remoteId - the remoteId identifying the client.
	 * @return true / false indicating success of the kick.
	 */
	kickRemote(remoteId: string): Promise<boolean>;

	/**
	 * set this clients telehealth info. This will be broadcast to other clients.
	 * @param clientInfo - the client info.
	 */
	setClientInfo(clientInfo: TelehealthClient): void;

	// ==========================================================================
	// Getters
	// ==========================================================================

	/**
	 * gets the current call state
	 */
	readonly callState: TELEHEALTH_STATE;

	/**
	 * Get the current video display mode.
	 */
	readonly videoDisplayMode: VideoDisplayMode;

	/**
	 * gets the current call state in legacy format. Mostly required
	 * because Juno depends on these values.
	 */
	readonly legacyCallState: TeleCallState;

	/**
	 * gets a list of remote clients in the call
	 */
	readonly remoteClients: TelehealthClient[];

	/**
	 * true / false indicating if AV features are enabled.
	 */
	readonly isAVEnabled: boolean;

	/**
	 * indicates if video is enabled or not. This is false for example, in audio calls.
	 */
	readonly isVideoEnabled: boolean;

	/**
	 * indicates if the microphone is muted or not
	 */
	readonly isMuted: boolean;

	/**
	 * indicate if this telehealth engine is connected to a session or not.
	 */
	readonly isConnected: boolean;

	// ==========================================================================
	// Callbacks
	// ==========================================================================

	/**
	 * A callback to be called when a remote client connects to the call
	 * @param callback - the callback to call. remoteId is the client's id who connected to the call
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onRemoteConnected(callback: (remoteId: string) => void, context?: any): void;

	/**
	 * called when a remote client disconnects from the session
	 * @param callback - a callback to be called with the remoteId of the client that has disconnected
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onRemoteDisconnected(callback: (remoteId: string) => void, context?: any): void;

	/**
	 * called when the call state changes in the telehealth engine.
	 * @param callback - the callback to call when the telehealth call state changes.
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onCallStateChange(callback: (callState: TELEHEALTH_STATE) => void, context?: any): void;

	/**
	 * called when a remote client changes state.
	 * @param callback - a callback to be called. It will be provided with the updated client object
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onRemoteStateChange(callback: (client: TelehealthClient) => void, context?: any): void;

	/**
	 * called when a remote client enters the room.
	 * @param callback - called when the remote client enters the room. It will be provided with the updated client object
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onRemoteEnterRoom(callback: (client: TelehealthClient) => void, context?: any): void;

	/**
	 * A callback to be called when a remote client initiates a call. This will only occur
	 * if the client is in the "OUT_OF_ROOM" state. Calling a client already in the room has no effect.
	 * @param callback - the callback to call. should return true / false indicating if the call
	 * is to be accepted or rejected. remoteId is the id of the client calling you
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onInboundCall(callback: (remoteId: string, callDisplay: string) => Promise<boolean>, context?: any): void;

	/**
	 * A callback to be called when a remote client indicates that a call should be canceled. The most obvious thing
	 * to do in this case is to dismiss the accept call modal
	 * @param callback - the callback to be called
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onInboundCallCancel(callback: (remoteId: string) => Promise<void>, context?: any): void;

	/**
	 * a callback to be called when a remote client signals that the call has ended. This is not a definitive indication
	 * that the call has ended, rather it is saying that at least one remote is indicating such. This message will only be
	 * delivered when in the state IN_ROOM. The callback will return a true / false. If true the telehealth engine
	 * will transition to the OUT_OF_ROOM state stopping all outgoing and incoming AV streams. If false no action is taken
	 * and the call continues as if nothing happened.
	 * @param callback - callback returning true / false as indicated above.
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onCallEnd(callback: (remoteId: string) => Promise<boolean>, context?: any): void;

	/**
	 * A callback to be called when a remote client sends a chat message.
	 * @param callback - callback that will be provided with the chat message.
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onChatMessage(callback: (remoteId: string, chatMessage: ChatMessage) => void, context?: any): void;

	/**
	 * A callback to be called when a remote client is typing
	 * @param callback - callback to call when a remote client is typing. remoteId is the client who is typing
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onChatTyping(callback: (remoteId: string) => void, context?: any): void;

	/**
	 * A callback to be called when you are forcefully removed from the "IN_ROOM" state by another client
	 * @param callback - called when you get kicked
	 * @param context - a value to be assigned to "this" in the callback
	 */
	onKicked(callback: () => void, context?: any): void;

	/**
	 * A callback that is called if there is a problem accessing the user's AV devices
	 * @param callback
	 * @param context
	 */
	onAVDeviceError(callback: (error: AV_ERROR) => void, context?: any): void;

	/**
	 * A callback that is called when the telehealth engine emits a log event. A common use case would be to log this event
	 * @param callback
	 * @param context
	 */
	onLogEvent(callback: (event: LogEvent) => void, context?: any): void;
}
