
	import {Options, Vue, Prop} from "vue-property-decorator";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import ClinicConnectionRedirectBaseController from "@/lib/patient/clinicConnectionRedirectBaseController";
	import {Route} from "@/router/router";

	@Options({})
	export default class OnDemandBookingRedirect extends ClinicConnectionRedirectBaseController
	{
		@Prop({type: String}) organizationId: string;
		protected organization = null;

		created()
		{
			this.organization = OrganizationStore.organizationProfile;

			if (this.organization)
			{
				this.initialize(this.organization.primary_clinic_id);
			}
			else
			{
				this.showErrorState(this.$mhat("OnDemandBookingRedirect.NoOrgErrorMessage"));
			}
		}

		protected redirectNoConnection()
		{
			this.$mhaRouterReplace(
				{
					name: this.Route.Public.Organization.Home,
					params: {organizationId: this.organizationId},
				},
			);
		}

		protected redirectConnected()
		{
			const {organizationId} = this;
			this.$mhaRouterReplace({
				name: Route.odbBookingRoute.BookingReason,
				params: { organizationId },
			});
		}
	}
