import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "signup-spinner" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EventStateSpinner, {
      isLoading: _ctx.isLoading,
      loadingTitle: _ctx.loadingTitle,
      loadingMessage: _ctx.loadingMessage,
      successTitle: _ctx.successTitle,
      successMessage: _ctx.successMessage,
      successIcon: _ctx.successIcon,
      showFailureState: _ctx.isError,
      failureTitle: this.$mhat('SignupSpinner.FailureTitle'),
      failureMessage: this.$mhat('SignupSpinner.FailureMessage'),
      "container-class": {'dark-mode': _ctx.darkStyle}
    }, null, 8, ["isLoading", "loadingTitle", "loadingMessage", "successTitle", "successMessage", "successIcon", "showFailureState", "failureTitle", "failureMessage", "container-class"]),
    (_ctx.isError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BaseButton, {
            color: _ctx.ButtonColor.PRIMARY,
            patterns: _ctx.ButtonColorPattern.DEFAULT,
            onClick: _ctx.onReset
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.$mhat("SignupSpinner.BackToSignUpButtonText")), 1)
            ]),
            _: 1
          }, 8, ["color", "patterns", "onClick"])
        ]))
      : (!_ctx.isLoading)
        ? _renderSlot(_ctx.$slots, "default", { key: 1 })
        : _createCommentVNode("", true)
  ]))
}