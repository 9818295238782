

	import {Options, Vue, Prop, Watch} from "vue-property-decorator";

	import uuidv4 from "uuid/v4";

	import {SelectOption} from "@/lib/types/Components";
	import {FilterDropdownOpts} from "@/components/Inputs/types";
	import InputLayout from "@/components/Inputs/InputLayout.vue";

	const OPTION_HEIGHT = 56;

	@Options({
		emits: ["click", "input", "selection"],
		components: {InputLayout},
	})
	export default class SelectMenu extends Vue
	{
		@Prop({type: String}) id: string;
		@Prop({type: String}) label: string;
		@Prop({type: Boolean, default: false}) hideLabel: boolean;
		@Prop({type: Boolean, default: false}) hideValidationMessage: boolean;
		@Prop({type: Boolean}) disabled: boolean;
		@Prop({type: Boolean}) nostyle: boolean;
		@Prop({type: Boolean}) compact: boolean;
		@Prop({type: Boolean}) required: boolean;
		@Prop({type: Boolean, default: true}) valid: boolean;
		@Prop({type: [Object, String]}) selected: SelectOption;
		@Prop({type: Array}) options: SelectOption[];
		@Prop({type: Boolean}) checks: boolean;
		@Prop({type: Object}) inputSelect: FilterDropdownOpts;
		@Prop({type: String}) inputValue: string;
		@Prop({type: Number, default: 5}) maxShown: number;
		@Prop({type: String, default: "Please select an option"}) placeholder: string;
		@Prop() defaultAutoscrollValue: any;
		@Prop({type: Boolean, default: true}) externalPristine: boolean;

		public active = false;
		public ionInputEl: Vue = null;
		public uuidv4 = uuidv4;
		// if true the dropdown should drop up!
		public dropUp = false;

		private pristine = true;

		mounted()
		{
			// Required for disabling unique select menus on window click handler
			(this.$refs.selectMenu as HTMLIonActionSheetElement).setAttribute(`select-menu-${uuidv4()}`, "");
		}

		beforeUnmount()
		{
			const windowSelector = this.getWindowSelector();

			if (windowSelector)
			{
				windowSelector.onclick = undefined;
			}
		}

		public setInputEl(ionInputEl: Vue)
		{
			this.ionInputEl = ionInputEl;
		}

		public onClick(e)
		{
			const windowSelector = this.getWindowSelector();
			if (windowSelector)
			{
				if (windowSelector.onclick)
				{
					windowSelector.onclick(e);
				}

				windowSelector.onclick = this.active ? undefined : this.checkWindowClick.bind(this);

				if (!this.disabled)
				{
					this.active = !this.active;

					// when the dropdown opens, scroll to the selected value, or a default if it was set
					const scrollToValue = (this.selected) ? this.selected.value : this.defaultAutoscrollValue;
					if (this.active && scrollToValue)
					{
						this.$nextTick(() =>
						{
							const container = this.$refs.optionsContainer as HTMLIonActionSheetElement;
							const selectedIndex = this.options.findIndex((option) => option.value === scrollToValue);
							container.scrollTop = OPTION_HEIGHT * selectedIndex;
						});
					}

					// recalculate if we should drop up or down.
					this.dropUp = this.shouldDropUp();
				}
			}

			this.$emit("click", e);
		}

		public optionSelected(option)
		{
			option.id = this.id;
			this.$emit("selection", option);
			this.active = false;
			this.pristine = false;
		}

		public checkWindowClick(e: MouseEvent)
		{
			if (!e || !e.composedPath().includes((this.$refs.selectMenu as HTMLIonActionSheetElement)))
			{
				const windowSelector = this.getWindowSelector();

				if (windowSelector)
				{
					windowSelector.onclick = undefined;
				}

				this.active = false;
			}
		}

		public getWindowSelector()
		{
			if (this.$refs.selectMenu)
			{
				return (this.$refs.selectMenu as HTMLIonActionSheetElement).closest("ion-content");
			}
		}

		@Watch("selected")
		selectedWatch()
		{
			if (this.ionInputEl)
			{
				this.ionInputEl.$el.setFocus();
			}
		}

		get optionHeight()
		{
			return OPTION_HEIGHT + "px";
		}

		get maxHeight()
		{
			return (OPTION_HEIGHT * this.maxShown) + "px";
		}

		get isValid()
		{
			return this.valid || this.isPristine;
		}

		get isPristine()
		{
			return this.pristine && this.externalPristine;
		}

		// if active raise zIndex of outer container.
		get zIndex(): string
		{
			if (this.active)
			{
				return "600";
			}
			else
			{
				return "0";
			}
		}

		/**
		 * calculate if the dropdown should drop down or up.
		 * @return true = should drop up. false = should drop down.
		 * @protected
		 */
		protected shouldDropUp(): boolean
		{
			if (this.options && this.$refs.selectMenu)
			{
				// calculate if we must drop up or down
				const selectMenu = this.$refs.selectMenu as HTMLElement;
				const spaceBelowPx = window.innerHeight - selectMenu.getClientRects()[0].bottom;
				const requiredSpacePx = OPTION_HEIGHT * Math.min(this.options.length, this.maxShown);

				return spaceBelowPx < requiredSpacePx;
			}
			else
			{
				return false;
			}
		}
	}
