import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-017d11d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "textarea" }
const _hoisted_2 = ["value", "maxlength", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("textarea", {
      ref: "messageInput",
      onInput: _cache[0] || (_cache[0] = ({target})=>_ctx.$emit('input', target.value)),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus'))),
      autofocus: "",
      value: $props.value,
      maxlength: $props.maxlength,
      placeholder: $props.placeholder
    }, null, 40, _hoisted_2)
  ]))
}