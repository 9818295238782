import {LoginRoute} from "@/router/patient_user/login.routes";
import {SignupRoute} from "@/router/patient_user/signup.routes";
import {HomeRoute} from "@/router/patient_user/home.routes";
import {OrganizationRoute} from "@/router/public/org.routes";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import {SettingsRoute} from "@/router/patient_user/settings.routes";
import {DependentsRoute} from "@/router/patient_user/dependents.routes";
import {DependentClinicsRoute} from "@/router/patient_user/dependentClinics.routes";
import {ClinicsRoutes} from "@/router/patient_user/clinics.routes";
import {AppointmentsRoute} from "@/router/patient_user/appointment.routes";
import {MailRoute} from "@/router/patient_user/inbox.routes";
import {BookingRoute, DependentBookingRoute} from "@/router/patient_user/booking.routes";
import {ODBBookingRoute} from "@/router/patient_user/odb.routes";
import {OrgSettingsRoute} from "@/router/patient_user/org.settings.routes";
import {OrgDependentsRoute} from "@/router/patient_user/org.dependents.routes";
import {appointmentRouteCollection, AppointmentRouteCollection} from "@/router/patient_user/AppointmentRouteCollection";
import FusionRouteCollection from "@/router/patient_user/FusionRouteCollection";
import AqsBookingRouteCollection from "@/router/patient_user/AqsBookingRouteCollection";
import OrganizationConfigurationStore from "@/lib/vuex/modules/OrganizationConfigurationStore";
import BookingRouteCollection, {bookingRouteCollection} from "@/router/patient_user/BookingRouteCollection";
import InboxRouteCollection, {inboxRouteCollection} from "@/router/patient_user/InboxRouteCollection";
import {loginRouteCollection, LoginRouteCollection} from "@/router/patient_user/LoginRouteCollection";
import {clinicsRouteCollection, ClinicsRouteCollection} from "@/router/patient_user/ClinicsRouteCollection";
import {
	DependentClinicsRouteCollection,
	dependentClinicsRouteCollection,
} from "@/router/patient_user/DependentClinicsRouteCollection";

export class PatientRouteCollection
{
	get Login(): LoginRouteCollection
	{
		return loginRouteCollection;
	}

	get LoginPage(): typeof LoginRoute.Home | OrganizationRoute.Login
	{
		if (OrganizationStore.isWhiteLabel)
		{
			return OrganizationRoute.Login;
		}
		return LoginRoute.Home;
	}

	get Signup(): typeof SignupRoute
	{
		return SignupRoute;
	}

	get Home(): string
	{
		if (OrganizationStore.isWhiteLabel)
		{
			return OrganizationStore.organizationConfiguration.routes.home;
		}
		return HomeRoute.Home;
	}

	get Settings(): typeof SettingsRoute | typeof OrgSettingsRoute
	{
		if (OrganizationStore.isWhiteLabel)
		{
			return OrgSettingsRoute;
		}
		else
		{
			return SettingsRoute;
		}
	}

	get Dependents(): typeof DependentsRoute | typeof OrgDependentsRoute
	{
		if (OrganizationStore.isWhiteLabel)
		{
			return OrgDependentsRoute;
		}
		else
		{
			return DependentsRoute;
		}
	}

	get DependentClinics(): DependentClinicsRouteCollection
	{
		return dependentClinicsRouteCollection;
	}

	get Clinics(): ClinicsRouteCollection
	{
		return clinicsRouteCollection;
	}

	get Appointments(): AppointmentRouteCollection
	{
		return appointmentRouteCollection;
	}

	get Inbox(): InboxRouteCollection
	{
		return inboxRouteCollection;
	}

	get Booking(): any
	{
		return bookingRouteCollection;
	}

	get AqsBooking(): AqsBookingRouteCollection
	{
		return new AqsBookingRouteCollection();
	}

	get odbBookingRoute(): typeof ODBBookingRoute
	{
		return ODBBookingRoute;
	}

	get Fusion(): FusionRouteCollection
	{
		return new FusionRouteCollection();
	}
}
