import TelehealthInterfaceBase from "@/lib/telehealth/TelehealthInterfaceBase";
import {SessionData} from "@/lib/telehealth/models/sessionData.model";
import {UserType} from "@/open_api/generated";
import AuthStore from "@/lib/vuex/auth.store";
import OT, {Session} from "@opentok/client";
import TelehealthError, {TELEHEALTH_ERROR_TYPE} from "@/lib/telehealth/error/TelehealthError";
import {SignalDispatcher} from "@/lib/telehealth/opentok/SignalDispatcher";
import {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
import StateTracker from "@/lib/telehealth/opentok/StateTracker";
import SessionCommunicator from "@/lib/telehealth/opentok/SessionCommunicator";
import CallManager from "@/lib/telehealth/opentok/CallManager";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import ChatManager from "@/lib/telehealth/opentok/ChatManager";
import StreamManager from "@/lib/telehealth/opentok/StreamManager";
import {VideoDisplayMode} from "@/lib/telehealth/models/VideoDisplayMode";
import {reactive} from "vue";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";

export default class OpenTokTelehealth extends TelehealthInterfaceBase
{
	// number of times the engine will attempt to join the telehealth session, before giving up.
	protected readonly JOIN_SESSION_ATTEMPT_MAX = 3;

	protected sessionData: SessionData = null;
	protected otSession: Session = null;
	// true if this telehealth engine has been destroyed
	protected destroyed = false;

	// telehealth components
	protected signalDispatcher: SignalDispatcher = new SignalDispatcher(this.onLogEventCallbacks);
	protected stateTracker: StateTracker = null;
	protected sessionCommunicator: SessionCommunicator = null;
	protected callManager: CallManager = null;
	protected chatManager: ChatManager = null;
	protected streamManager: StreamManager = null;

	// ==========================================================================
	// Public Interface Methods
	// ==========================================================================

	/**
	 * build the open tok telehealth engine.
	 * @param avEnabled - [optional default true] if true the stream manager will be initialized to handle AV streaming.
	 */
	constructor(avEnabled = true)
	{
		super();

		// setup telehealth components
		this.stateTracker = reactive(new StateTracker(
			this.signalDispatcher,
			null,
			this.onRemoteConnectedCallbacks,
			this.onRemoteStateChangeCallbacks,
			this.onRemoteDisconnectedCallbacks,
			this.onCallStateChangeCallbacks,
			this.onRemoteEnterRoomCallbacks)) as StateTracker;

		this.sessionCommunicator = new SessionCommunicator(this.stateTracker, this.onLogEventCallbacks);
		this.stateTracker.setSessionCommunicator(this.sessionCommunicator);

		if (avEnabled)
		{
			this.streamManager = reactive(new StreamManager(
				this.sessionCommunicator,
				this.signalDispatcher,
				this.stateTracker,
				this.onAVDeviceErrorCallbacks)) as StreamManager;
		}

		this.callManager = reactive(new CallManager(
			this.signalDispatcher,
			this.stateTracker,
			this.sessionCommunicator,
			this.streamManager,
			this.onInboundCallCallbacks,
			this.onCallEndCallbacks,
			this.onInboundCallCancelCallbacks,
			this.onKickedCallbacks)) as CallManager;

		this.chatManager = reactive(new ChatManager(
			this.stateTracker,
			this.signalDispatcher,
			this.sessionCommunicator,
			this.onChatMessageCallbacks,
			this.onChatTypingCallbacks)) as ChatManager;
	}

	public async initialize(
		sessionData: SessionData,
		videoSelector: string,
		videoPreviewSelector: string): Promise<void>
	{
		if (this.destroyed)
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.ENGINE_DESTROYED_ERROR, "This Telehealth Engine is destroyed!");
		}

		if (this.streamManager)
		{
			this.streamManager.setVideoPreviewSelector(videoPreviewSelector);
			this.streamManager.setVideoSelector(videoSelector);
		}
		this.sessionData = sessionData;

		await this.joinSession();
	}

	public destroy(): void
	{
		if (this.isConnected)
		{
			if (this.streamManager)
			{
				this.streamManager.stopStreaming();
				this.streamManager.destroyPublisher();
				this.streamManager.unsubscribeFromAllStreams();
			}
			this.clearEventHandlers();
			this.otSession.disconnect();
		}

		this.otSession = null;
		this.destroyed = true;
	}

	public async enterRoom(): Promise<void>
	{
		await this.callManager.transitionToRoom();
	}

	public async leaveRoom(): Promise<void>
	{
		await this.callManager.transitionToOutOfRoom();
	}

	public callRemote(callDisplay: string): Promise<boolean>
	{
		return this.callManager.callRemote(callDisplay);
	}

	public callSpecificRemote(remoteId: string): Promise<boolean>
	{
		return this.callManager.callSpecificRemote(remoteId);
	}

	public cancelCallRemote(): Promise<void>
	{
		return this.callManager.cancelCallRemote();
	}

	public notifyRemotesOfCallEnd(): Promise<void>
	{
		return this.callManager.signalEndCall();
	}

	public changeVideoDisplayMode(mode: VideoDisplayMode): void
	{
		if (this.streamManager)
		{
			this.streamManager.changeVideoDisplayMode(mode);
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.ENGINE_COMPONENT_NOT_LOADED, "StreamManager is not loaded");
		}
	}

	/**
	 * enable video feed.
	 */
	public enableVideo(): void
	{
		if (this.streamManager)
		{
			this.streamManager.publishVideo(true);
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.ENGINE_COMPONENT_NOT_LOADED, "StreamManager is not loaded");
		}
	}

	/**
	 * disable video feed.
	 */
	public disableVideo(): void
	{
		if (this.streamManager)
		{
			this.streamManager.publishVideo(false);
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.ENGINE_COMPONENT_NOT_LOADED, "StreamManager is not loaded");
		}
	}

	public cycleCamera(): void
	{
		if (this.streamManager)
		{
			this.streamManager.cycleCamera();
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.ENGINE_COMPONENT_NOT_LOADED, "StreamManager is not loaded");
		}
	}

	public muteMicrophone(mute: boolean): void
	{
		if (this.streamManager)
		{
			this.streamManager.enableAudio(!mute);
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.ENGINE_COMPONENT_NOT_LOADED, "StreamManager is not loaded");
		}
	}

	public async sendChatMessage(msg: ChatMessage): Promise<void>
	{
		await this.chatManager.sendChatMessage(msg);
	}

	public async sendTypingSignal(): Promise<void>
	{
		await this.chatManager.sendTypingSignal();
	}

	public kickRemote(remoteId: string): Promise<boolean>
	{
		return this.callManager.kickRemote(remoteId);
	}

	/**
	 * set this clients telehealth info.
	 * @param clientInfo - the client info.
	 */
	public setClientInfo(clientInfo: TelehealthClient): void
	{
		this.localClientInfo = clientInfo;

		if (this.isSessionConnected)
		{
			this.stateTracker.updateSelf(clientInfo);
		}
	}

	// ==========================================================================
	// Getter Interface Methods
	// ==========================================================================

	get callState(): TELEHEALTH_STATE
	{
		return this.stateTracker.state;
	}

	get videoDisplayMode(): VideoDisplayMode
	{
		if (this.streamManager)
		{
			return this.streamManager.videoDisplayMode;
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.ENGINE_COMPONENT_NOT_LOADED, "StreamManager is not loaded");
		}
	}

	get remoteClients(): TelehealthClient[]
	{
		const clients = this.stateTracker.clients;
		return clients
			.filter((client) => !client.isSelf)
			.map((client) => client.toTelehealthClient);
	}

	/**
	 * true / false indicating if AV features are enabled.
	 */
	get isAVEnabled(): boolean
	{
		return !!this.streamManager;
	}

	/**
	 * indicates if video is published or not. This is false for example, in audio calls.
	 */
	get isVideoEnabled(): boolean
	{
		if (this.streamManager)
		{
			return this.streamManager.videoEnabled;
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.ENGINE_COMPONENT_NOT_LOADED, "StreamManager is not loaded");
		}
	}

	get isMuted(): boolean
	{
		if (this.streamManager)
		{
			return !this.streamManager.audioEnabled;
		}
		else
		{
			throw new TelehealthError(TELEHEALTH_ERROR_TYPE.ENGINE_COMPONENT_NOT_LOADED, "StreamManager is not loaded");
		}
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	/**
	 * join the telehealth session
	 * @param attempt - the attempt number of this join. If the connection fails and
	 * attempt number is < JOIN_SESSION_ATTEMPT_MAX then another attempt will automatically be made
	 * @protected
	 */
	protected joinSession(attempt = 0): Promise<void>
	{
		this.clearEventHandlers();

		// initialize session
		this.otSession = OT.initSession(this.sessionData.apiKey, this.sessionData.sessionId);
		this.setupEventHandlers();

		// connect to session
		return new Promise((resolve, reject) =>
		{
			this.otSession.connect(this.sessionData.token, async (error) =>
			{
				if (error)
				{
					if (++attempt < this.JOIN_SESSION_ATTEMPT_MAX)
					{
						// try connection again
						try
						{
							await this.joinSession(attempt);
						}
						catch (error)
						{
							return reject(error);
						}
					}
					else
					{
						return reject(new TelehealthError(
							TELEHEALTH_ERROR_TYPE.CONNECTION_ERROR,
							`Failed to connect to telehealth session with error: ${error.name}. ${error.message}`));
					}
				}
				else
				{
					this.stateTracker.createSelf(this.localClientInfo);
					this.signalDispatcher.setSelf(this.stateTracker.getSelf);
				}
				resolve();
			});
		});
	}

	/**
	 * setup OT event handlers
	 * @protected
	 */
	protected setupEventHandlers(): void
	{
		this.signalDispatcher.listenForEvents(this.otSession);
		this.stateTracker.setOtSession(this.otSession);
		this.sessionCommunicator.setOtSession(this.otSession);
	}

	/**
	 * clear all event handlers form the OT session object
	 * @protected
	 */
	protected clearEventHandlers(): void
	{
		if (this.otSession)
		{
			this.otSession.off();
			this.stateTracker.clearOtSession();
			this.sessionCommunicator.clearOtSession();
		}
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get userType(): UserType
	{
		return AuthStore.loggedInUser.userType;
	}

	/**
	 * returns true if the we are connected to the OT session
	 */
	get isSessionConnected(): boolean
	{
		return this.otSession && this.otSession.isConnected();
	}
}
