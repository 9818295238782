/**
 * represents a collection of callback functions
 */
export default class CallbackCollection
{
	protected _callbacks: any[];

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(callbackArray?: any[])
	{
		if (callbackArray)
		{
			this._callbacks = callbackArray;
		}
		else
		{
			this._callbacks = [];
		}
	}

	/**
	 * add callback to the collection
	 * @param callback - the callback to add
	 * @param context - a value to be assigned to "this" in the callback
	 */
	public addCallback(callback: any, context?: any): void
	{
		if (context)
		{
			callback = callback.bind(context);
		}

		this._callbacks.push(callback);
	}

	/**
	 * call the callbacks in this collection. returning any return values
	 * as an array where each element is the return value of one callback
	 * @param args - arguments to pass in to the callback functions
	 * @return an array of results from the callback functions in this collection
	 */
	public async call(...args: any): Promise<any[]>
	{
		const returnValues: any[] = [];
		for (const hook of this._callbacks)
		{
			let returnVal = hook(...args);

			if (returnVal instanceof Promise)
			{
				returnVal = await returnVal;
			}

			returnValues.push(returnVal);
		}

		return returnValues;
	}

	/**
	 * call the callbacks in this collection.
	 * @param args - arguments to pass in to the callback functions
	 * @return a bool indicating if every callback was truthy or not. If all callbacks return true then true is returned
	 * if even one callback returns false then false is returned
	 */
	public async callTruthy(...args: any): Promise<boolean>
	{
		const results = await this.call(...args);

		if (results.length === 0 || results.reduce((acc, val) => acc = acc && val))
		{
			return true;
		}
		return false;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get callbacks(): any[]
	{
		return this._callbacks;
	}
}
