

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ClinicConnectionStatus, ClinicPatientAccessDto, PatientUserDto} from "@/open_api/generated";
	import {ClinicAPI} from "@/lib/services/Api";
	import VerificationPopover from "@/views/clinic_user/appointment/components/VerificationPopover.vue";
	import PopoverService from "@/lib/popover/PopoverService";
	import VerificationCancelPopover from "@/views/clinic_user/appointment/components/VerificationCancelPopover.vue";
	import {DateUtils} from "@/lib/utils/Date";
	import moment from "moment";
	import {getResources} from "@/lib/utils/Resource";

	@Options({})
	export default class VerificationStatus extends Vue
	{
		@Prop({type: Object}) public patient: PatientUserDto;
		@Prop({type: String}) public remoteProfileId: string;
		public patientConnectionState: ClinicPatientAccessDto = null;

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async mounted(): Promise<void>
		{
			await this.loadPatientConnectionInformation();
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public async onClick(event: MouseEvent): Promise<void>
		{
			if (this.canVerify)
			{
				// display verification modal
				const result: boolean = await PopoverService.showPopover(
					VerificationPopover,
					{patient: this.patient, remoteProfileId: this.remoteProfileId},
					event);

				if (result)
				{
					await this.loadPatientConnectionInformation();
				}
			}
			else if (this.isVerified)
			{
				// display cancel verification modal
				const result: boolean = await PopoverService.showPopover(
					VerificationCancelPopover,
					{patient: this.patient, connectionStatus: this.patientConnectionState},
					event);

				if (result)
				{
					await this.loadPatientConnectionInformation();
				}
			}
		}

		/**
		 * load patient connection information form server.
		 */
		public async loadPatientConnectionInformation(): Promise<void>
		{
			if (this.patient)
			{
				this.patientConnectionState = (await ClinicAPI().getPatientClinicConnection(this.patient.id)).data;
			}
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get verificationStatusMessage(): string
		{
			if (this.isDependent)
			{
				return "A dependent cannot be verified.";
			}
			else if (this.patientConnectionState)
			{
				switch (this.patientConnectionState.link_status)
				{
				case ClinicConnectionStatus.Verified:
					return "Verified";
				case ClinicConnectionStatus.Confirmed:
				case ClinicConnectionStatus.PendingClinicConfirm:
					return "Patient has not been verified. Click here to verify patient.";
				default:
					return "Cannot verify patient.";
				}
			}
			else
			{
				return `Patient does not have a ${getResources().app_name} account.`;
			}
		}

		get verificationStatusClass(): string
		{
			if (this.isDependent)
			{
				return "unknown";
			}
			else if (this.patientConnectionState)
			{
				switch (this.patientConnectionState.link_status)
				{
				case ClinicConnectionStatus.Verified:
					return "verified";
				default:
					return "not-verified";
				}
			}
			else
			{
				return "unknown";
			}
		}

		get verificationIcon(): string
		{
			if (this.patientConnectionState)
			{
				switch (this.patientConnectionState.link_status)
				{
				case ClinicConnectionStatus.Verified:
					return "icon-check";
				default:
					return "icon-delete";
				}
			}
			else
			{
				return "icon-question";
			}
		}

		get verificationTooltip(): string
		{
			if (this.isDependent)
			{
				return "Dependents cannot be verified";
			}
			else if (this.patientConnectionState)
			{
				switch (this.patientConnectionState.link_status)
				{
				case ClinicConnectionStatus.Verified:
					return "This patient was verified on " +
						`${DateUtils.humanDateString(moment(this.patientConnectionState.clinic_verified_at))} by ` +
						`${this.patientConnectionState.verifier_user_name}`;
				case ClinicConnectionStatus.Confirmed:
				case ClinicConnectionStatus.PendingClinicConfirm:
					return "Update this patients status to \"Verified\" if you have verified their identity and confirmed " +
						"they match the demographic details on record.";
				default:
					return "Cannot verify patient.";
				}
			}
			else
			{
				return `Patient must have a ${getResources().app_name} account before they can be verified.`;
			}
		}

		get isDependent(): boolean
		{
			return this.patient != null && this.patient.is_dependent;
		}

		get isVerified(): boolean
		{
			return this.patientConnectionState &&
				this.patientConnectionState.link_status === ClinicConnectionStatus.Verified;
		}

		get canVerify(): boolean
		{
			return this.patientConnectionState &&
				this.patientConnectionState.link_status !== ClinicConnectionStatus.Verified &&
				(
					this.patientConnectionState.link_status === ClinicConnectionStatus.Confirmed ||
					this.patientConnectionState.link_status === ClinicConnectionStatus.PendingClinicConfirm
				) &&
				!this.isDependent;
		}
	}
