

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";

	@Options({})
	export default class ClinicAboutInfo extends Vue
	{
		@Prop({type: Object}) clinic: ClinicProfile;
	}
