

	import {Options, Vue, Prop} from "vue-property-decorator";
	import BannerNotificationItem from "../BannerNotificationItem.vue";
	import {Route} from "@/router/router";
	import {PatientNotificationAppointment} from "@/open_api/generated";
	import BannerNotification from "@/lib/notification/model/BannerNotification";

	@Options({components: {BannerNotificationItem}})
	export default class TelehealthNotificationItem extends Vue
	{
		@Prop(Object) notificationData: BannerNotification;

		public async onJoinClick(): Promise<void>
		{
			this.notificationData.action();
		}

		get showJoinButton(): boolean
		{
			return !!this.notificationData.action && !!this.notificationData.actionTitle;
		}
	}
