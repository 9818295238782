
export default class Messageable
{
	protected _id: string;
	protected _name: string;
	protected _type: string;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(id: string, name: string, type: string)
	{
		this._id = id;
		this._name = name;
		this._type = type;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get id(): string
	{
		return this._id;
	}

	get type(): string
	{
		return this._type;
	}

	get name(): string
	{
		return this._name;
	}
}
