
	import AppHeader from "@/components/Header/AppHeader.vue";
	import {IMAGE_BRANDED} from "@/assets/AppIcons";
	import {IonFooter} from "@ionic/vue";

	export default {
		name: "BaseModal",
		components: {AppHeader, IonFooter},
		props: {
			active: Boolean,
			header: Boolean,
			contentWidthFull:
				{ // if true content area will be full width
					type: Boolean,
					default: false,
				},
			footer: Boolean,
			error: Boolean,
			headerTitle: String,
			closeText: String,
			title: {
				type: String,
				default: "",
			},
		},

		data()
		{
			return {
				IMAGE_BRANDED,
			};
		},

		methods: {
			closeModal()
			{
				this.$emit("close");
			},
		},
	};
