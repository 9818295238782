import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_HeaderBar = _resolveComponent("HeaderBar")!

  return (_openBlock(), _createBlock(_component_HeaderBar, null, {
    left: _withCtx(() => [
      _createVNode(_component_ion_chip, {
        outline: "",
        color: "danger",
        onClick: _ctx.onExit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.$mhat("TelehealthHeader.ExitButtonText")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    right: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }))
}