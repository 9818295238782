import {RouteAuthType} from "@/router/types";
import {RouteRecordRaw} from "vue-router";
import {ClinicAPI} from "@/lib/services/Api";
// @ts-ignore
import {TELEHEALTH_MODE} from "@/components/telehealth/TelehealthMode";
import {ClinicUserRoute} from "@/router/clinic_user/index";
import TelehealthAudioEmbedded from "@/views/clinic_user/appointment/TelehealthAudioEmbedded.vue";

const TelehealthClinicUser = () => import(
	/* webpackChunkName: "TelehealthClinicUser" */ "@/views/clinic_user/appointment/Telehealth.vue");

export enum ClinicUserAppointmentRoute
{
	Telehealth = "ClinicUserTelehealth",
	TelehealthAudio = "ClinicUserTelehealthAudio",
	TelehealthAqs = "ClinicUserTelehealthAqs",
	TelehealthRedirect = "ClinicUserTelehealthRedirect",
}

export const clinicUserAppointmentRoutes: RouteRecordRaw[] = [
	{
		path: "/clinic_user/appointments/:appointmentId/session",
		name: ClinicUserAppointmentRoute.Telehealth,
		props: true,
		component: TelehealthClinicUser,
		meta: {
			routeAuth: RouteAuthType.ClinicUser,
		},
	},
	{
		path: "/clinic_user/appointments/:appointmentId/audio/session",
		name: ClinicUserAppointmentRoute.TelehealthAudio,
		props: true,
		component: TelehealthAudioEmbedded,
		meta: {
			routeAuth: RouteAuthType.ClinicUser,
		},
	},
	{
		path: "/clinic_user/aqs/queue/:queueId/queued_appointment/:queuedAppointmentId/session",
		name: ClinicUserAppointmentRoute.TelehealthAqs,
		props: (route) =>
		{
			return Object.assign({telehealthMode: TELEHEALTH_MODE.AQS}, route.params);
		},
		component: TelehealthClinicUser,
		meta: {
			routeAuth: RouteAuthType.ClinicUser,
		},
	},
	{
		path: "/clinic/:clinicId/telehealth/appointment/:remoteId",
		name: ClinicUserAppointmentRoute.TelehealthRedirect,
		props: true,
		component: null,
		beforeEnter: async ({params}, from, next) =>
		{
			try
			{
				const clinicAppointmentTransfer =
						await ClinicAPI().getLocalAppointment(parseInt(params.remoteId as string, 10));
				next({
					name: ClinicUserAppointmentRoute.Telehealth,
					params: {appointmentId: clinicAppointmentTransfer.data.id},
				});
			}
			catch (error)
			{
				next({
					name: ClinicUserRoute.Error,
					params: {error},
				});
			}
		},
		meta: {
			routeAuth: RouteAuthType.ClinicUser,
		},
	},
];
