import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {AppointmentBookSuccess} from "@/open_api/generated";
import AppointmentBookResponse from "@/lib/appointment/model/AppointmentBookResponse";

export default class AppointmentBookSuccessToAppointmentBookResponseConverter extends AbstractConverter<AppointmentBookSuccess, AppointmentBookResponse>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: AppointmentBookSuccess): AppointmentBookResponse
	{
		return new AppointmentBookResponse(from.status);
	}
}
