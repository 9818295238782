import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MailHeader = _resolveComponent("MailHeader")!
  const _component_SelectMenu = _resolveComponent("SelectMenu")!
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_CardFeed = _resolveComponent("CardFeed")!
  const _component_FloatingActionButton = _resolveComponent("FloatingActionButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, { contentHeaderRemoveFalseHeaderSpace: "" }, {
    "content-header": _withCtx(() => [
      (_ctx.$isMobile)
        ? (_openBlock(), _createBlock(_component_MailHeader, {
            key: 0,
            "has-close": {name: _ctx.Route.Inbox.Home}
          }, null, 8, ["has-close"]))
        : _createCommentVNode("", true)
    ]),
    "content-top": _withCtx(() => [
      _createVNode(_component_SelectMenu, {
        id: "mailbox-select",
        hideLabel: "",
        options: _ctx.mailboxOptions,
        selected: _ctx.selectedMailboxOption,
        onSelection: _ctx.onSelectMailbox
      }, null, 8, ["options", "selected", "onSelection"]),
      _createVNode(_component_CardFeed, {
        "is-empty": _ctx.controller.noMail
      }, {
        feed: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allMail, (mail) => {
            return (_openBlock(), _createBlock(_component_MessageCard, {
              mail: mail,
              key: mail.id,
              active: _ctx.controller.isSelected(mail, true),
              clickable: "",
              onClick: ($event: any) => (_ctx.selectMail(mail))
            }, null, 8, ["mail", "active", "onClick"]))
          }), 128))
        ]),
        empty: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(this.$mhat("MessageFeed.NoMessages")), 1)
        ]),
        _: 1
      }, 8, ["is-empty"])
    ]),
    "fixed-footer": _withCtx(() => [
      _createVNode(_component_FloatingActionButton, {
        icon: "icon-write",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.composeNew()))
      })
    ]),
    _: 1
  }))
}