
	import {LOGO_PROFILE} from "@/assets/AppIcons";

	export default {
		name: "HeaderBarPrivacy",

		props: {
			headerText: {
				type: String,
			},
		},

		data()
		{
			return {
				headerProps: {
					headerIcon: LOGO_PROFILE,
					headerText: this.headerText ? this.headerText : this.$mhat("HeaderBarPrivacy.HeaderText"),
				},
			};
		},
	};
