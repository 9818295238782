
	import BookingController, {setBookingParams} from "@/views/patient_user/booking/BookingController";
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import ClinicService from "@/lib/clinics/Clinic.service";
	import {isBlank} from "@/lib/utils/prototype/String";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";
	import {
		bookingForward,
		dynamicBookingRoute,
		getBookingClinicService,
	} from "@/views/patient_user/booking/BookingRoutingHelper";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import {ErrorCode} from "@/open_api/generated";
	import {NotificationSeverity} from "@/lib/types/Notifier";
	import NotificationService from "@/components/Notification/NotificationService";
	import ProviderGroup from "@/lib/clinic/providerGroup.model";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {Options, Prop, Vue} from "vue-property-decorator";
	import PatientAppointmentService from "@/lib/appointment/service/PatientAppointmentService";

	@Options({components: {BookingHeaderBar, DependentSubheader }})
	export default class BookingProviderGroup extends Vue
	{
		@Prop({type: String}) clinicId: string;
		@Prop({type: String}) dependentId: string;
		@Prop({type: String}) appointmentMethod: string;
		@Prop({type: Boolean, default: true}) hasBackButtons: boolean;
		@Prop({type: Boolean, default: true, required: false }) hasClose: boolean;
		@Prop({type: String}) rescheduleRemoteAppointmentId: string;

		public isLoading = true;
		public selectingProviderGroup = false;
		public providerGroups: ProviderGroup[] = [];
		public clinicService: ClinicService = null;

		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		async beforeRouteEnter(to, from, next)
		{
			const dependentId = to.params.dependentId;
			const clinicId = to.params.clinicId;
			const rescheduleRemoteAppointmentId = to.query.rescheduleRemoteAppointmentId;
			const clinicService = getBookingClinicService(dependentId);
			let providerGroups;

			// If we're rescheduling an appointment, only use the groups that the provider from the rescheduled appointment belongs to
			if (rescheduleRemoteAppointmentId)
			{
				const patientAppointmentService = new PatientAppointmentService();
				const appointment = await patientAppointmentService.getRemoteAppointment(clinicId, to.query.rescheduleRemoteAppointmentId);

				providerGroups = await clinicService.getClinicProviderGroupsByProvider(clinicId, appointment.providerId);
			}
			else
			{
				providerGroups = await clinicService.getClinicProviderGroups(clinicId);
			}

			// Route to provider booking page if there are no provider groups
			if (providerGroups.length === 0)
			{
				return bookingForward(dynamicBookingRoute(dependentId).Provider,
					{
						dependentId: dependentId,
						clinicId: clinicId,
						organizationId: OrganizationStore.organizationProfile?.url_friendly_name,
					},
					next,
					to.query);
			}
			else if (providerGroups.length === 1)
			{
				const bookableProvidersNotInGroup = await clinicService.getClinicProvidersNotInGroup(clinicId, providerGroups[0].id);

				// If there is a bookable provider not in the group, route to the provider group selection page, unless you're rescheduling an appointment.
				// Else, route to the provider selection page for the group
				if (bookableProvidersNotInGroup.length > 0 && !rescheduleRemoteAppointmentId)
				{
					next();
				}
				else
				{
					return bookingForward(dynamicBookingRoute(dependentId).ProviderGroupProvider,
						{
							dependentId: dependentId,
							clinicId: clinicId,
							providerGroupId: providerGroups[0].id,
							organizationId: OrganizationStore.organizationProfile?.url_friendly_name,
						},
						next,
						to.query);
				}
			}
			else
			{
				next();
			}
		}

		public async created(): Promise<void>
		{
			this.clinicService = getBookingClinicService(this.dependentId);
			setBookingParams.call(this);
			this.controller.clearAppointmentState();

			try
			{
				const controller: BookingController = this.controller;

				// If we're rescheduling an appointment, only show the groups that the provider from the rescheduled appointment belongs to
				if (this.rescheduleRemoteAppointmentId)
				{
					const patientAppointmentService = new PatientAppointmentService();
					const appointment = await patientAppointmentService.getRemoteAppointment(controller.clinicId, this.rescheduleRemoteAppointmentId);

					this.providerGroups = await this.clinicService.getClinicProviderGroupsByProvider(controller.clinicId, appointment.providerId);
				}
				else
				{
					this.providerGroups = await this.clinicService.getClinicProviderGroups(controller.clinicId);
				}
			}
			catch (error)
			{
				if (error instanceof ErrorResponse && (error as ErrorResponse).is(ErrorCode.RemotePatientInactive))
				{
					NotificationService.notificationDismiss(this.$mhat("BookingProviderGroup.ClinicConnectionProblem"), error.message, NotificationSeverity.Warning);
				}
			}
			finally
			{
				this.isLoading = false;
			}
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public setSelected(selected: ProviderGroup)
		{
			this.isLoading = true;
			this.selectingProviderGroup = true;
			this.controller.providerGroupId = selected.id;

			this.$mhaRouterPush(this.toProviderGroupProviderRoute);
		}

		public providerRoute()
		{
			this.controller.providerGroupId = null;

			return this.toProviderRoute;
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get showSpinner()
		{
			return this.isLoading;
		}

		get controller()
		{
			return BookingControllerStore.bookingController;
		}

		get isDependentMode()
		{
			return !isBlank(this.dependentId);
		}

		get toProviderGroupProviderRoute()
		{
			const {appointmentMethod, dependentId} = this;
			const clinicId = this.controller.clinicId;
			const providerGroupId = this.controller.providerGroupId;
			return {
				name: this.isDependentMode ? Route.Booking.Dependent.ProviderGroupProvider : Route.Booking.ProviderGroupProvider,
				params: {appointmentMethod, dependentId, clinicId, providerGroupId},
				query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId},
			};
		}

		get toProviderRoute()
		{
			const {appointmentMethod, dependentId} = this;
			const clinicId = this.controller.clinicId;
			return {
				name: this.isDependentMode ? Route.Booking.Dependent.Provider : Route.Booking.Provider,
				params: {appointmentMethod, dependentId, clinicId},
				query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId},
			};
		}

		get loadingMessage()
		{
			return this.selectingProviderGroup ? this.$mhat("BookingProviderGroup.ProviderGroupSelectedLoadingMessage") : this.$mhat("BookingProviderGroup.LoadingTitle");
		}
	}
