import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import {RouteRecordRaw} from "vue-router";

const ORGANIZATION_ROUTE_PATH_PREFIX = "/org/:organizationId";
export const ORGANIZATION_ROUTE_NAME_SUFFIX = "-whitelabel";

/**
 * generate all white label routes from the list of routes.
 * @param routes - the list of routes to generate white label routes for.
 */
export function generateWhitelabelRoutes(routes: RouteRecordRaw[]): RouteRecordRaw[]
{
	const orgRoutes: RouteRecordRaw[] = [];
	routes.forEach((route) =>
	{
		if (route.meta?.brandedRoute && !route.path.includes(":organizationId"))
		{
			let newRoute: any = {};
			newRoute = Object.assign(newRoute, route);
			newRoute.path = ORGANIZATION_ROUTE_PATH_PREFIX + newRoute.path;
			if (newRoute.alias)
			{
				newRoute.alias = ORGANIZATION_ROUTE_PATH_PREFIX + newRoute.alias;
			}

			if (newRoute.children)
			{
				newRoute.children = newRoute.children.slice();
				for (const [index, childRoute] of newRoute.children.entries())
				{
					// clone child routes and add white label versions.
					newRoute.children[index] = Object.assign({}, childRoute);
					newRoute.children[index].name = childRoute.name + ORGANIZATION_ROUTE_NAME_SUFFIX;
				}
			}

			newRoute.name = newRoute.name + ORGANIZATION_ROUTE_NAME_SUFFIX;
			orgRoutes.push(newRoute);
		}
	});
	return orgRoutes;
}

/**
 * returns the passed in route if not in white label mode, or returns the whitelabel version
 * @param routeName - the route get
 */
export function getRouteOrWhitelabelRoute(routeName: string)
{
	if (OrganizationStore.isWhiteLabel)
	{
		return routeName + ORGANIZATION_ROUTE_NAME_SUFFIX;
	}
	return routeName;
}
