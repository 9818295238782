import router from "@/router/router";
import * as API from "@/open_api/generated";
import AuthStore from "@/lib/vuex/auth.store";
import {PatientLoginService} from "@/lib/services/login/PatientLogin.service";
import {ProviderLoginService} from "@/lib/services/login/ProviderLogin.service";
import DeviceInfo from "@/lib/DeviceInfo";

const baseApiUrl = `${location.protocol}//${location.hostname}/api/rest/v1/${DeviceInfo.getHostUri()}`;

// TODO: Remove BaseApiService usage
export class BaseApiService
{
	private _isLoading = false;

	public patientApi()
	{
		if (this.noAuthAccess)
		{
			this.onAuthError();
		}
		else
		{
			const apiKey = this.loginToken?.tokenStr;
			const apiConfig = new API.Configuration({apiKey});
			return API.PatientApiFactory(apiConfig, baseApiUrl);
		}
	}

	private onAuthError()
	{
		this.pushToLogin();
	}

	private pushToLogin()
	{
		const currentLocation: string = router.currentRoute.value.path;
		let loginLocation = "/login";

		if (currentLocation !== "/login")
		{
			loginLocation += "?push_route=" + currentLocation;
		}

		new PatientLoginService().logout();
		new ProviderLoginService().logout();
		router.push(loginLocation);
	}

	get noAuthAccess()
	{
		return !this.loginToken || this.loginToken.isExpired;
	}

	get loginToken()
	{
		return AuthStore.loginToken;
	}

	get isLoading(): boolean
	{
		return this._isLoading;
	}

	set isLoading(value: boolean)
	{
		this._isLoading = value;
	}
}
