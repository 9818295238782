

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";

	@Options({})
	export default class ClinicProviderInfo extends Vue
	{
		@Prop({type: Object}) clinic: ClinicProfile;

		private perPage = 8;

		public providerList(page)
		{
			const list = this.clinic.providerNames;
			const startIndex = page * this.perPage;
			let endIndex = startIndex + this.perPage;
			if (endIndex > list.length)
			{
				endIndex = list.length;
			}

			return list.slice(startIndex, endIndex);
		}

		get isMultiPage()
		{
			return this.pageCount > 1;
		}

		get pageCount()
		{
			return Math.ceil(this.clinic.providerNames.length / this.perPage);
		}
	}
