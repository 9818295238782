import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
import {ClinicEvent, EventBus} from "@/lib/utils/EventBus";
import Services from "@/lib/services/Services";

export class MyClinicsController
{
	private _autoSelectClinic = false;
	private _connectedClinics: ClinicProfile[] = [];
	private _selectedClinic: ClinicProfile = null;

	private _deletedClinic: ClinicProfile = null;

	private _isLoading = true;

	constructor(autoSelectClinic?: boolean)
	{
		this.autoSelectClinic = autoSelectClinic;
		this.getConnectedClinics();

		EventBus.$on(ClinicEvent.CONNECT, this.getConnectedClinics.bind(this));
	}

	public getConnectedClinics()
	{
		this.isLoading = true;

		Services.PatientClinics.connectedClinics().then((clinics) =>
		{
			this.isLoading = false;
			this.connectedClinics = clinics;

			if (this.autoSelectClinic)
			{
				this.selectFirstClinic();
			}
		});
	}

	private selectFirstClinic()
	{
		if (this.activeClinics.length > 0)
		{
			this.selectedClinic = this.activeClinics[0];
		}
		else
		{
			this.selectedClinic = this.pendingClinics[0];
		}
	}

	get noClinics(): boolean
	{
		return this.connectedClinics.length === 0 && !this.isLoading;
	}

	get activeClinics()
	{
		return this.connectedClinics.filter((clinic: ClinicProfile) =>
		{
			return clinic.patientCanBook;
		});
	}

	get pendingClinics()
	{
		return this.connectedClinics.filter((clinic: ClinicProfile) =>
		{
			return !clinic.patientCanBook;
		});
	}

	get activeClinicsWithDependentBooking()
	{
		return this.activeClinics.filter((clinic: ClinicProfile) =>
		{
			return clinic.allowsDependentBooking;
		});
	}

	get connectedClinics(): ClinicProfile[]
	{
		return this._connectedClinics;
	}

	set connectedClinics(value: ClinicProfile[])
	{
		this._connectedClinics = value;
	}

	get selectedClinic(): ClinicProfile
	{
		return this._selectedClinic;
	}

	set selectedClinic(value: ClinicProfile)
	{
		this._selectedClinic = value;
	}

	get deletedClinic(): ClinicProfile
	{
		return this._deletedClinic;
	}

	set deletedClinic(value: ClinicProfile)
	{
		this._deletedClinic = value;
	}

	get isLoading(): boolean
	{
		return this._isLoading;
	}

	set isLoading(value: boolean)
	{
		this._isLoading = value;
	}

	get autoSelectClinic(): boolean
	{
		return this._autoSelectClinic;
	}

	set autoSelectClinic(value: boolean)
	{
		this._autoSelectClinic = value;
	}
}
