
	import {Options, Vue} from "vue-property-decorator";
	import TermsAndConditionsText from "@/components/Text/TermsAndConditionsText.vue";
	import CloudMDTermsAndConditionsText from "@/components/Text/CloudMDTermsAndConditionsText.vue";
	import DeviceInfo from "@/lib/DeviceInfo";
	import modalBaseController from "@/lib/modal/modalBaseController";

	@Options({components: {CloudMDTermsAndConditionsText, TermsAndConditionsText}})
	export default class SignupEULAModal extends modalBaseController
	{
		get isCloudMD(): boolean
		{
			return DeviceInfo.isCloudMd;
		}
	}
