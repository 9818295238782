
	import {Options, Vue, Prop} from "vue-property-decorator";
	import KioskStore from "@/lib/vuex/modules/Kiosk.store";
	import {KioskWorkflow} from "@/views/kiosk_user/types";
	import AppChip from "@/components/Buttons/AppChip/AppChip.vue";
	import TelehealthWrapper from "@/components/telehealth/TelehealthWrapper.vue";
	import PatientTelehealthHeader from "@/views/patient_user/appointment/components/PatientTelehealthHeader.vue";
	import ChatView from "@/components/telehealth/ChatView.vue";
	import KioskWaitingRoomOverlay from "@/views/kiosk_user/KioskWaitingRoomOverlay.vue";
	import TelehealthBase from "@/components/telehealth/TelehealthBase.vue";
	import OpenTokTelehealth from "@/lib/telehealth/opentok/OpenTokTelehealth";
	import {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
	import LogEvent from "@/lib/telehealth/models/LogEvent";
	import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
	import {VideoDisplayMode} from "@/lib/telehealth/models/VideoDisplayMode";
	import {ProvinceCode} from "@/open_api/generated";
	import AlertService from "@/lib/alert/AlertService";
	import KioskConstants from "@/views/kiosk_user/KioskConstants";
	import {alertController, IonPage} from "@ionic/vue";
	import VideoViewForIonSlides from "@/components/telehealth/VideoViewForIonSlides.vue";

	@Options({
		components: {
			IonPage,
			AppChip,
			TelehealthWrapper,
			PatientTelehealthHeader,
			VideoViewForIonSlides,
			ChatView,
			KioskWaitingRoomOverlay,
		},
	})
	export default class KioskTelehealth extends TelehealthBase
	{
		@Prop({type: String, default: ProvinceCode.BC}) public kioskLocation: string;

		private timeout = null;
		private REJOIN_ALERT_TIMEOUT = 30000;
		protected rejoinAlert: HTMLIonAlertElement = null;
		protected rejoinAlertTimer = null;

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public async onMounted()
		{
			// initialize the telehealth engine
			await this.telehealthEngine.initialize(
				await this.fetchSessionData(),
				"#subscriber",
				"#publisher");

			// setup waiting room timeout
			KioskStore.stopTimeout();
			KioskStore.setTimeoutSeconds(KioskConstants.WAITING_ROOM_TIMEOUT_SECONDS);
			KioskStore.setOnTimeoutCb(this.handleKioskTimeout);
			KioskStore.startTimeout();
		}

		public async onDestroyed(): Promise<void>
		{
			KioskStore.stopTimeout();
		}

		// ==========================================================================
		// Protected Methods
		// ==========================================================================

		protected async handleKioskTimeout(): Promise<void>
		{
			let didTimeout = false;
			if (!this.inRoom())
			{
				didTimeout = await AlertService.dismissAlertWithTimeout(
					this.$mhat("KioskTelehealth.TimeoutErrorHeaderText"),
					this.$mhat("KioskTelehealth.TimeoutErrorMessage", {timeoutSeconds: KioskConstants.ALERT_TIMEOUT_SECONDS}),
					KioskConstants.ALERT_TIMEOUT_SECONDS * 1000);
			}

			if (didTimeout)
			{
				this.exitCall(true);
			}
			else
			{
				KioskStore.startTimeout();
			}
		}

		public async exitCall(forceExit = false): Promise<void>
		{
			await this.leaveRoom();
			await this.notifyRemotesOfCallEnd();
			this.clearCallStateQueryParam();
			KioskStore.setKioskWorkflow(KioskWorkflow.Finished);

			if (forceExit)
			{
				KioskStore.setKioskWorkflow(KioskWorkflow.Home);
				this.$mhaRouterReplace(this.Route.Kiosk.Home, {params: {kioskLocation: this.kioskLocation}});
			}
			else
			{
				const stayInSession = await this.showRejoinAlert();

				if (!stayInSession)
				{
					this.timeout = setTimeout(() =>
					{
						KioskStore.setKioskWorkflow(KioskWorkflow.Home);
					}, 9500);
				}
			}
		}

		protected async dismissCurrentAlert(): Promise<void>
		{
			if (this.rejoinAlert)
			{
				await this.rejoinAlert.dismiss();
				clearTimeout(this.rejoinAlertTimer);
			}
			await super.dismissCurrentAlert();
		}

		protected async onCallEnd(remoteId: string): Promise<boolean>
		{
			this.callOver = true;
			this.exitCall();

			// we have already explicitly handled the leave room transition.
			return false;
		}

		private async showRejoinAlert()
		{
			let stayInSession = false;

			this.rejoinAlertTimer = setTimeout(() =>
			{
				alertController.getTop().then((alert) => alert?.dismiss());
				this.$mhaRouterReplace(this.Route.Kiosk.Home, {params: {kioskLocation: this.kioskLocation}});
			}, this.REJOIN_ALERT_TIMEOUT);

			let message = this.$mhat("KioskTelehealth.RejoinCallMessage");
			if (KioskStore.didNetworkDisconnect)
			{
				message = this.$mhat("KioskTelehealth.RejoinCallAfterNetworkErrorMessage");
			}

			this.rejoinAlert = await alertController.create({
				header: this.$mhat("KioskTelehealth.SessionEndedNotificationHeader"),
				message,
				buttons: [
					{
						text: this.$mhat("KioskTelehealth.StayButtonText"),
						handler: () =>
						{
							this.callOver = false;
							this.enterRoom();
							stayInSession = true;
							clearTimeout(this.rejoinAlertTimer);
						},
					},
					{
						text: this.$mhat("KioskTelehealth.LeaveButtonText"),
						handler: () =>
						{
							this.$mhaRouterReplace(this.Route.Kiosk.Home, {params: {kioskLocation: this.kioskLocation}});
						},
					},
				],
			});
			await this.rejoinAlert.present();

			// wait for alert to dismiss
			await this.rejoinAlert.onDidDismiss();

			return stayInSession;
		}
	}
