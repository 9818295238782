import {PublicRoute} from "@/router/public";
import {ClinicUserRoute} from "@/router/clinic_user";
import {KioskUserRoute} from "@/router/kiosk_user";
import {PatientRouteCollection} from "@/router/patient_user/PatientRouteCollection";
import {UserType} from "@/open_api/generated";
import {Route} from "@/router/router";

// Base of the routes tree. All routes in the program should be accessible through this object.
// extends PatientRouteCollection for route legacy support.
export class AppRouteCollection extends PatientRouteCollection
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * get the appropriate login page for the given user.
	 * @param userType - the type of user to get the login page for.
	 */
	public loginPageByUserType(userType: UserType): string
	{
		switch (userType)
		{
			case UserType.KioskUser:
				return this.Kiosk.Home;
			case UserType.ClinicUser:
				return this.ClinicUser.Login;
			case UserType.PatientUser:
				return this.LoginPage;
			default:
				return this.LoginPage;
		}
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get Public(): typeof PublicRoute
	{
		return PublicRoute;
	}

	get ClinicUser(): typeof ClinicUserRoute
	{
		return ClinicUserRoute;
	}

	get Kiosk(): typeof KioskUserRoute
	{
		return KioskUserRoute;
	}
}
