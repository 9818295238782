import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_FooterBar = _resolveComponent("FooterBar")!

  return (_openBlock(), _createBlock(_component_FooterBar, null, {
    "right-link": _withCtx(() => [
      _createVNode(_component_ion_fab_button, {
        class: "right-side",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$mhaRouterPush(_ctx.Route.Clinics.FindClinic)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: $data.ICON_ADD,
            alt: "Add Clinic",
            class: "round-button"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}