import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-269b9dc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data", "onClick"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "tabBar",
    class: _normalizeClass(["mha-tab-bar", _ctx.customClass])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabBarItems, (item) => {
      return (_openBlock(), _createElementBlock("button", {
        type: "button",
        class: _normalizeClass(["mha-tab-button", {'disabled' : item.disabled}]),
        data: item,
        key: item.icon,
        onClick: ($event: any) => (_ctx.onClick(item))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(item.icon)
        }, null, 2),
        (item.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(item.label), 1))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    }), 128))
  ], 2))
}