export default class LoadingQueue
{
	private loadingList = [];

	public pushLoadingState()
	{
		this.loadingList.push(true);
	}

	public popLoadingState()
	{
		this.loadingList.pop();
	}

	get isLoading()
	{
		return this.loadingList.length > 0;
	}
}
