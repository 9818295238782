import {AxiosPromise, AxiosResponse} from "axios";
import {Appointment} from "../models/Appointment";
import {DependentAPI, PatientAPI} from "./Api";
import {DATE_FORMAT} from "@/constants";
import {AppointmentBookSuccess, AppointmentTransfer, BookSlotData} from "@/open_api/generated/api";
import {AppointmentDayMap} from "@/lib/types/Appointment";

export default class AppointmentService
{
	public static parseAppointmentsResponse(response: AxiosResponse<AppointmentTransfer[]>): AppointmentDayMap
	{
		const appointmentDays: AppointmentDayMap = {};

		if (response)
		{
			const availableAppointments = response.data;

			Object.keys(availableAppointments).forEach((date) =>
			{
				const appointmentTOs: AppointmentTransfer[] = availableAppointments[date];
				appointmentDays[date] = appointmentTOs.map((appointmentTO) => new Appointment(appointmentTO));
			});
		}
		return appointmentDays;
	}

	/*
	 * Book an appointment in Juno
	 */
	public static bookAppointment(
		clinicId: string, providerId: string, appointmentTypeId: string, slotData: BookSlotData): AxiosPromise<AppointmentBookSuccess>
	{
		return PatientAPI().patientClinicAppointmentBook(clinicId, {
			slot_data: slotData,
			provider_id: providerId,
			appointment_type_id: appointmentTypeId,
		});
	}

	/*
	 * Book an appointment in Juno for a dependent user
	 */
	public static bookDependentAppointment(
		dependentId: string, clinicId: string, providerId: string, appointmentTypeId: string, slotData: BookSlotData): AxiosPromise<AppointmentBookSuccess>
	{
		return DependentAPI().dependentClinicAppointmentBook(dependentId, clinicId, {
			slot_data: slotData,
			provider_id: providerId,
			appointment_type_id: appointmentTypeId,
		});
	}

	private static getAppointmentTransfer(appointment: Appointment): AppointmentTransfer
	{
		return {
			patient_id: appointment.patientId,
			clinic_id: appointment.clinicId,
			provider_id: appointment.providerId,
			reason: appointment.reason,
			start_datetime: appointment.startDateTime.format(DATE_FORMAT.DATETIME),
			end_datetime: appointment.endDateTime.format(DATE_FORMAT.DATETIME),
			duration_minutes: appointment.durationMinutes,
			remote_id: appointment.remoteTemplateId,
		};
	}
}
