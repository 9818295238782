import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_label = _resolveComponent("ion-label")!

  return (_openBlock(), _createElementBlock("div", {
    class: "mha-checkbox",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createVNode(_component_ion_checkbox, {
      mode: "md",
      value: _ctx.checked,
      checked: _ctx.checked
    }, null, 8, ["value", "checked"]),
    _createVNode(_component_ion_label, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    })
  ]))
}