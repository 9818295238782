
	import { Options, Prop, Vue } from "vue-property-decorator";
	import {ButtonColor, ButtonColorPattern, ButtonSize} from "@/components/Buttons/types";
	import {IonFabButton} from "@ionic/vue";

	@Options({
		emits: ["click"],
		components: {IonFabButton},
	})
	export default class RoundButton extends Vue
	{
		@Prop({type: String, default: ButtonColorPattern.DEFAULT}) buttonStyle;
		@Prop({type: String, default: ButtonColor.PRIMARY}) color;
		@Prop({type: String, default: ButtonSize.DEFAULT}) size: string;
		@Prop({type: Boolean, default: false}) noBoxShadow: boolean;
		@Prop({type: Boolean, default: false}) disabled;
		@Prop({type: Boolean, default: false}) toggle;
		@Prop(Boolean) public readonly active: boolean;

		onClick(event)
		{
			if (!this.disabled)
			{
				this.$emit("click", event);
			}
		}

		buttonClasses(): any
		{
			return [
				this.buttonStyle,
				this.disabled ? "disabled" : "",
				this.active ? "fill toggle-on" : "",
				this.color,
				this.size,
				this.noBoxShadow ? "no-box-shadow" : "",
			];
		}
	}
