import axios from "axios";
import DeviceInfo from "@/lib/DeviceInfo";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";

axios.interceptors.request.use((config) =>
{
	if (DeviceInfo.isIOS())
	{
		config.headers["Client-Origin"] = "ios_app";
	}
	else if (DeviceInfo.isAndroid())
	{
		config.headers["Client-Origin"] = "android_app";
	}

	const organization = OrganizationStore.organizationProfile;
	if (organization)
	{
		config.headers["Client-Organization"] = organization.url_friendly_name;
	}

	return config;
});
