import { ClinicsFindController } from "@/views/patient_user/clinic/ClinicsFindController";
import { Patient } from "@/lib/patient/Patient";
import { GeoLocation } from "@/lib/types/FindClinic";
import Services from "@/lib/services/Services";
import { ClinicProfile } from "@/lib/clinic/clinicProfile.model";
import { Route } from "@/router/router";
import {RouteLocationRaw} from "vue-router";
import {$mhat} from "@/i18n";

export class ClinicsFindDependentController extends ClinicsFindController
{
	private _noParentClinics = false;

	constructor(patientUser?: Patient, geoLocation?: GeoLocation, restrictToParentClinics?: boolean)
	{
		super(patientUser, geoLocation);
		this.clinicService = Services.DependentClinics(patientUser.id);

		if (restrictToParentClinics)
		{
			this.restrictClinicList = true;
			this.restrictedClinicsMessage = $mhat("ClinicsFindDependentController.CurrentClinicsMessage");
		}
	}

	public loadClinicsList()
	{
		this.isLoading = true;

		if (this.restrictClinicList)
		{
			this.loadUnconnectedParentClinics();
		}
		else
		{
			this.geoLocation ? this.findClinicsWithGeoLocation(this.geoLocation) : this.findClinicsWithCoordinates();
		}
	}

	/**
	 * load all clinics that are connected to the parent but, not the dependent
	 * that can be connected to the dependent.
	 */
	private async loadUnconnectedParentClinics()
	{
		this.hasLocationAccess = true;
		this._noParentClinics = false;
		try
		{
			const dependentClinics = await this.clinicService.connectedClinics();
			this.clinics = (await Services.PatientClinics.connectedClinics()).filter((clinic: ClinicProfile) =>
			{
				const dependentConnected = dependentClinics.reduce(
					(acc, depClinic) => acc = depClinic.id === clinic.id || acc
					, false);

				return clinic.patientCanBook && clinic.allowsDependentBooking && !dependentConnected;
			});

			this._noParentClinics = this.clinics.length === 0;
		}
		finally
		{
			this.isLoading = false;
		}
	}

	public noRestrictionsRoute(): RouteLocationRaw
	{
		return {
			name: Route.DependentClinics.FindClinic,
		};
	}

	get noClinics(): boolean
	{
		return !this.isLoading && !this._noParentClinics && (!this.clinics || this.clinics.length === 0);
	}
}
