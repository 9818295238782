
	export default {
		name: "PasswordField",
		inheritAttrs: false,
		props: {
			label: {
				type: String,
				default: "Password",
			},
			disabled: Boolean,
		},

		methods: {

		},

		computed: {

		},
	};
