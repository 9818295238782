

	import {Options, Vue} from "vue-property-decorator";

	import {Validator} from "@/lib/utils/Validator";
	import {AccountAPI} from "@/lib/services/Api";
	import HeaderBarForgot from "./components/ForgotHeaderBar.vue";
	import CorporateFooter from "@/views/patient_user/login/CorporateFooter.vue";
	import {OrganizationProfileDto, PatientEmail} from "@/open_api/generated";
	import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";
	import defaultBackground from "@/assets/images/graphics/common/background.jpg";
	import OrganizationStore, {ORG_ASSET_TYPE} from "@/lib/vuex/modules/OrganizationStore";
	import WhitelabelHeaderBar from "@/views/public/whitelabel/components/WhitelabelHeaderBar.vue";
	import OrgImage from "@/components/Organization/OrgImage.vue";

	@Options({components: {OrgImage, HeaderBarForgot, CorporateFooter, WhitelabelHeaderBar}})
	export default class LoginForgot extends Vue
	{
		public email = "";
		public resetSent = false;
		public isLoading = false;
		public isError = false;
		public errorMessage;
		public backgroundImage = defaultBackground;
		public organization: OrganizationProfileDto = null;
		private ORG_ASSET_TYPE = ORG_ASSET_TYPE;

		created()
		{
			this.organization = OrganizationStore.organizationProfile;
			this.errorMessage = this.$mhat("LoginForgot.GenericErrorMessage");
		}

		public updateEmail(event)
		{
			this.email = event.value;
		}

		public forgotPassword()
		{
			const emailBody: PatientEmail = {email: this.email};

			this.isLoading = true;
			this.resetSent = true;

			AccountAPI().forgotPassword(emailBody).then(
				(success) =>
				{
					this.isLoading = false;
				},
				(error: ErrorResponse) =>
				{
					this.isError = true;
					this.errorMessage = error.message;
					this.isLoading = false;
				},
			);
		}

		public onSubmit()
		{
			this.forgotPassword();
		}

		get validEmail()
		{
			return Validator.emailAddress(this.email);
		}

		get emailSentMessage()
		{
			return [
				this.$mhat("LoginForgot.EmailSentSuccessMessage"),
			];
		}

		get isWhiteLabelMode()
		{
			return Boolean(this.organization);
		}

		get homeRoute()
		{
			if (this.isWhiteLabelMode)
			{
				const organizationId = this.organization.id;
				return {
					name: this.Route.Public.Organization.Home,
					params: {organizationId},
				};
			}
			return this.Route.Login.Home;
		}
	}
