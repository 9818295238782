import {Connection, Stream, Subscriber} from "@opentok/client";
import {MediaDeviceStatus} from "@/lib/mediaDevices";
import {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import {UserType} from "@/open_api/generated";
import {DeviceInfo} from "@/lib/telehealth/models/telehealth.types";
import SessionClientToTelehealthClientConverter from "@/lib/telehealth/conversion/SessionClientToTelehealthClientConverter";

export default class SessionClient
{
	public connection: Connection;

	protected _localConnection: Connection;
	protected _tokenData: any;
	protected _device: DeviceInfo;
	protected _email: string;
	protected _firstName: string;
	protected _lastName: string;
	protected _audioStatus: MediaDeviceStatus;
	protected _videoStatus: MediaDeviceStatus;
	protected _state: TELEHEALTH_STATE = TELEHEALTH_STATE.NOT_CONNECTED;
	protected _legacyClient = true;
	protected _stream: Stream = null;
	protected _subscriber: Subscriber = null;
	// set this flag is used to prevent "concurrent" subscription attempts.
	// when true isSubscribable will return false. This only works because js is single threaded.
	protected _attemptingToSubscribe = false;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 *
	 * @param otConnection - the opentok connection through which this user is connected
	 * @param otLocalConnection - the local connection. i.e. "session.connection".
	 * Used to determine if this object is our self.
	 */
	constructor(otConnection: Connection, otLocalConnection: Connection)
	{
		this.connection = otConnection;
		this._localConnection = otLocalConnection;
		this._tokenData = JSON.parse(otConnection.data);
	}

	public setState(state: TELEHEALTH_STATE): void
	{
		this._state = state;
	}

	public updateFromPeerData(data: string): void
	{
		const remoteInfo = JSON.parse(data);
		this._device = remoteInfo.device as DeviceInfo;
		this._email = remoteInfo.email;
		this._firstName = remoteInfo.firstName;
		this._lastName = remoteInfo.lastName;
		this._audioStatus = remoteInfo.audio;
		this._videoStatus = remoteInfo.video;

		// legacy clients will not send TELEHEALTH_STATE information. Also don't update state if self
		// TODO adjust legacy (only legacy client left is the iOS native code)
		if (remoteInfo.state && !this.isSelf)
		{
			this._state = remoteInfo.state;
			this._legacyClient = false;
		}
	}

	/**
	 * update this object with the info contained in the telehealth client object
	 * @param client - the telehealth client to update from
	 */
	public updateFromTelehealthClient(client: TelehealthClient): void
	{
		this._device = client.deviceInfo;
		this._email = client.email;
		this._firstName = client.firstName;
		this._lastName = client.lastName;
		this._audioStatus = client.audioStatus;
		this._videoStatus = client.videoStatus;
		this._legacyClient = false;
	}

	public setStream(stream: Stream): void
	{
		this._stream = stream;
	}

	public setSubscriber(subscriber: Subscriber): void
	{
		this._subscriber = subscriber;
	}

	public setAudioStatus(status: MediaDeviceStatus): void
	{
		this._audioStatus = status;
	}

	public setVideoStatus(status: MediaDeviceStatus): void
	{
		this._videoStatus = status;
	}

	/**
	 * signal that you are attempting to subscribe to this client's stream.
	 * other "threads" will see this client as unsubscribable. DONT forget to unlock.
	 */
	public lockSubscriptionAttempt(): void
	{
		this._attemptingToSubscribe = true;
	}

	/**
	 * Call to signal that you are done attempting to subscribe (wither successful or not)
	 */
	public unlockSubscriptionAttempt(): void
	{
		this._attemptingToSubscribe = false;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get userId(): string
	{
		return this._tokenData.id;
	}

	get userType(): UserType
	{
		return this._tokenData.user_type;
	}

	get email(): string
	{
		return this._email;
	}

	get name(): string
	{
		return `${this._firstName} ${this._lastName}`;
	}

	get firstName(): string
	{
		return this._firstName;
	}

	get lastName(): string
	{
		return this._lastName;
	}

	get hasAudio(): boolean
	{
		return this._audioStatus === MediaDeviceStatus.GRANTED;
	}

	get hasVideo(): boolean
	{
		return this._videoStatus === MediaDeviceStatus.GRANTED;
	}

	get state(): TELEHEALTH_STATE
	{
		return this._state;
	}

	get stream(): Stream
	{
		return this._stream;
	}

	get subscriber(): Subscriber
	{
		return this._subscriber;
	}

	get isLegacyClient(): boolean
	{
		return this._legacyClient;
	}

	get isAttemptingToSubscribe(): boolean
	{
		return this._attemptingToSubscribe;
	}

	/**
	 * convert this object to a JSON string. Used to signal peers of our status.
	 */
	get toJson(): string
	{
		return JSON.stringify({
			device: this._device,
			email: this.email,
			firstName: this.firstName,
			lastName: this.lastName,
			audio: this._audioStatus,
			video: this._videoStatus,
			state: this.state,
		});
	}

	/**
	 * get a copy of this object as a telehealth client object
	 */
	get toTelehealthClient(): TelehealthClient
	{
		return new TelehealthClient(
			this._device,
			this.email,
			this.firstName,
			this.lastName,
			this.userType,
			this._audioStatus,
			this._videoStatus,
			this.connection.connectionId,
			this.isPublishing,
			new SessionClientToTelehealthClientConverter().convert(this.state),
		);
	}

	/**
	 * true if this client is you... i.e. the local client.
	 */
	get isSelf(): boolean
	{
		return this.connection.connectionId === this._localConnection.connectionId;
	}

	/**
	 * check if we are subscribed to this client
	 */
	get isSubscribed(): boolean
	{
		return this.subscriber !== null;
	}

	/**
	 * check if we can subscribe to this client
	 */
	get isSubscribable(): boolean
	{
		return this.isPublishing && !this.isAttemptingToSubscribe;
	}

	get isPublishing(): boolean
	{
		return this.stream !== null;
	}
}
