import BannerNotification from "@/lib/notification/model/BannerNotification";
import ClinicAppointment from "@/lib/appointment/model/ClinicAppointment";
import {DATE_FORMAT} from "@/constants";
import {BannerNotificationType} from "@/lib/notification/model/BannerNotificationType";
import router, {Route} from "@/router/router";
import {$mhat} from "@/i18n";

export default class BannerNotificationFactory
{
	// ==========================================================================
	// Class Methods
	// ==========================================================================

	/**
	 * create banner notification for upcoming appointment
	 * @param appointment - the appointment to make the notification for
	 * @return - a new notification
	 */
	public static notificationForAppointment(appointment: ClinicAppointment): BannerNotification
	{
		const appointmentStartDateTime = appointment.startDate.format(DATE_FORMAT.H_MM_A);
		const message = appointment.isDependentAppointment
			? $mhat("BannerNotificationFactory.DependentAppointmentNotificationMessage",
			        {dependentFirstName: appointment.appointmentPatient.firstName, appointmentStartDateTime: appointmentStartDateTime})
			: $mhat("BannerNotificationFactory.PatientAppointmentNotificationMessage",
			        {appointmentStartDateTime: appointmentStartDateTime});

		const actionTitle = appointment.isVirtual
			? $mhat("BannerNotificationFactory.JoinButtonText")
			: null;

		return new BannerNotification($mhat("BannerNotificationFactory.NotificationTitle"),
		                              message,
		                              BannerNotificationType.Appointment,
		                              () => router.push(
		                              	{
				                              name: Route.Appointments.TelehealthForClinicAppointment(appointment),
				                              params: {appointmentId: appointment.patientClinicAppointmentId},
		                              	}),
		                              actionTitle,
		                              appointment.startDate);
	}
}
