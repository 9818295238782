
	import {Options, Vue, Prop} from "vue-property-decorator";
	import EventStateSpinner from "@/components/EventState/EventStateSpinner.vue";
	import {EventStateSpinnerModalProps} from "@/components/EventState/types";
	import {BaseButtonOpts} from "@/components/Buttons/types";
	import modalBaseController from "@/lib/modal/modalBaseController";
	import {modalController} from "@ionic/vue";

	@Options({components: {EventStateSpinner}})
	export default class EventStateSpinnerModal extends modalBaseController
	{
		@Prop({type: Array}) successButtonProps: BaseButtonOpts[];
		@Prop({type: Array}) failureButtonProps: BaseButtonOpts[];

		// computed properties don't update if passed directly so they are wrapped in a function closure
		@Prop({type: Function, required: true}) spinnerModalPropsCall: () => EventStateSpinnerModalProps;

		protected modal = null;

		public onCreated()
		{
			modalController.getTop().then((modal) =>
			{
				this.modal = modal;
			});
		}

		public buttonClick(callback)
		{
			this.dismissModal();
			callback();
		}

		// Override
		public dismissModal()
		{
			this.unregisterRouterGuard();
			if (this.modal)
			{
				this.modal.dismiss();
			}
		}

		// Override
		protected onRouterBack()
		{
			this.dismissModal();
			const defaultCancelAction = this.spinnerModalPropsCall().defaultCancelAction;
			if (defaultCancelAction)
			{
				defaultCancelAction();
			}
		}

		get showSuccess()
		{
			return !this.spinnerModalPropsCall().isLoading && !this.spinnerModalPropsCall().showFailureState;
		}

		get showFailure()
		{
			return !this.spinnerModalPropsCall().isLoading && this.spinnerModalPropsCall().showFailureState;
		}
	}
