import {Options, Vue, Prop} from "vue-property-decorator";
import router from "@/router/router";
import {modalController} from "@ionic/vue";

@Options({})
export default class ModalBaseController extends Vue
{
	private routerGuardUnregisterCallback = null;

	// ==========================================================================
	// Vue lifecycle
	// ==========================================================================

	/* subclasses should not override this, use the onCreated hook instead */
	public created()
	{
		/* block further routing actions while this modal is open
		 * routing actions will instead trigger custom functionality while in the modal
		 */
		this.routerGuardUnregisterCallback = router.beforeEach(
			(to, from) =>
			{
				this.onRouterBack();
				return false;
			});

		this.onCreated();
	}

	public unmounted()
	{
		this.unregisterRouterGuard();
	}

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * Helper method to hook into Vue lifecycle from sub class
	 */
	public onCreated()
	{
	}

	/**
	 * dismiss the modal
	 * @param dismissData - [optional] data to send to dismiss
	 */
	public dismissModal(dismissData = null)
	{
		this.unregisterRouterGuard();
		if (modalController)
		{
			modalController.dismiss(dismissData);
		}
	}

	protected unregisterRouterGuard()
	{
		if (this.routerGuardUnregisterCallback)
		{
			this.routerGuardUnregisterCallback();
		}
	}

	/* perform an action on the route back.
	 * by default this should close the current modal,
	 * but could also do a step back within the modal workflow, etc. */
	protected onRouterBack()
	{
		this.dismissModal();
	}
}
