import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41733a6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message" }
const _hoisted_2 = { class: "font-color-neutral-grey mha-font-body-sm m-b-8" }
const _hoisted_3 = { class: "mha-font-body-md" }
const _hoisted_4 = {
  key: 0,
  class: "m-b-32"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttachmentViewer = _resolveComponent("AttachmentViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(this.$mhat("Message.FromMessage")) + " ", 1),
      _createElementVNode("b", null, _toDisplayString(_ctx.message.senderName), 1),
      _createTextVNode(" " + _toDisplayString(this.$mhat("Message.MessageReceivedOn", {messageDate: _ctx.formatMessageDate(_ctx.message)})), 1)
    ]),
    _createElementVNode("pre", _hoisted_3, _toDisplayString(_ctx.message.body), 1),
    (_ctx.message.hasAttachments)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_AttachmentViewer, { mail: _ctx.message }, null, 8, ["mail"])
        ]))
      : _createCommentVNode("", true)
  ]))
}