import {OTSessionInfo} from "@/open_api/generated";

export class SessionData
{
	public readonly token: string;
	public readonly sessionId: string;
	public readonly tokenType: string;
	public readonly clinicId: string;
	public readonly appointmentId: string;
	public readonly apiKey: string;

	constructor(sessionInfo: OTSessionInfo)
	{
		this.token = sessionInfo.token;
		this.sessionId = sessionInfo.session_id;
		this.tokenType = sessionInfo.token_type;
		this.clinicId = sessionInfo.clinic_id;
		this.appointmentId = sessionInfo.appointment_id;
		this.apiKey = sessionInfo.api_key;
	}
}
