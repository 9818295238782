
	import {Options, Vue, Prop} from "vue-property-decorator";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import modalBaseController from "@/lib/modal/modalBaseController";
	import router from "@/router/router";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";

	@Options({})
	export default class CannotConnectToClinicModal extends modalBaseController
	{
		@Prop({type: ClinicProfile}) public clinic: ClinicProfile;
		public ButtonColor = ButtonColor;
	}
