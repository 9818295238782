import {ClinicProfile} from "../clinic/clinicProfile.model";
import {
	AppointmentType,
	Clinic as ClinicTransfer,
	ProviderGroupTransfer,
	ProviderTransfer,
} from "../../open_api/generated/api";
import {BaseApiService} from "../services/BaseApiService";
import Provider from "@/lib/clinic/providerProfile.model";
import ProviderGroup from "@/lib/clinic/providerGroup.model";
import ProviderGroupTransferToProviderGroupConverter from "@/lib/clinic/converter/ProviderGroupTransferToProviderGroupConverter";
import ProviderTransferToProviderConverter from "@/lib/clinic/converter/ProviderTransferToProviderConverter";

export default abstract class ClinicService extends BaseApiService
{
	protected constructor()
	{
		super();
	}

	public allClinics()
	{
		return this.getClinics(false, false);
	}

	public async isConnectedToClinic(clinicID): Promise<boolean>
	{
		const clinics = await this.connectedClinics();
		const matchingClinics = clinics.filter((clinic) => clinic.id === clinicID);
		return matchingClinics.length > 0;
	}

	public connectedClinics()
	{
		return this.getClinics(false, false, false, true);
	}

	public async getLinkableClinics()
	{
		const response = await this.patientClinics(false, false, true, false);
		return response.data.clinics.map((clinicTransfer: ClinicTransfer) => new ClinicProfile(clinicTransfer));
	}

	public bookableClinics()
	{
		return this.getClinics(true, false);
	}

	public messageableClinics()
	{
		return this.getClinics(false, true);
	}

	public async getClinicsInLocRange(provinceCode, city, address, postalCode): Promise<ClinicProfile[]>
	{
		this.isLoading = true;
		const response = await this.viewableClinicsInLocRange(
			provinceCode, city, address, postalCode,
		);
		this.isLoading = false;

		return response.data.map((clinicTransfer: ClinicTransfer) =>
														 {
															 return new ClinicProfile(clinicTransfer);
														 });
	}

	public async getClinicsInCoordRange(latitude, longitude): Promise<ClinicProfile[]>
	{
		this.isLoading = true;
		const response = await this.viewableClinicsInCoordRange(latitude, longitude);
		this.isLoading = false;

		return response.data.map((clinicTransfer: ClinicTransfer) =>
														 {
															 return new ClinicProfile(clinicTransfer);
														 });
	}

	public async getClinicNoCache(clinicId: string): Promise<ClinicProfile>
	{
		this.isLoading = true;
		const response = await this.patientClinic(clinicId);
		this.isLoading = false;
		return new ClinicProfile(response.data);
	}

	public async getClinic(clinicId: string): Promise<ClinicProfile>
	{
		return this.getClinicNoCache(clinicId);
	}

	public async getClinicProviderGroup(clinicId: string, providerGroupId: string): Promise<ProviderGroup>
	{
		const response = await this.patientClinicProviderGroup(clinicId, providerGroupId);

		return new ProviderGroupTransferToProviderGroupConverter().convert(response.data);
	}

	public async getClinicProviderGroups(clinicId: string): Promise<ProviderGroup[]>
	{
		const response = await this.patientClinicProviderGroups(clinicId);

		return new ProviderGroupTransferToProviderGroupConverter().convertList(response.data);
	}

	public async getClinicProviderGroupsByProvider(clinicId: string, providerId: string): Promise<ProviderGroup[]>
	{
		const response = await this.patientClinicProviderGroupsByProvider(clinicId, providerId);

		return new ProviderGroupTransferToProviderGroupConverter().convertList(response.data);
	}

	public async getClinicProviderGroupProviders(clinicId:string, providerGroupId: string): Promise<Provider[]>
	{
		const response = await this.patientClinicProviderGroupProviders(clinicId, providerGroupId);

		return new ProviderTransferToProviderConverter().convertList(response.data);
	}

	public async getClinicProviders(clinicId: string): Promise<Provider[]>
	{
		this.isLoading = true;
		const response = await this.patientClinicProviders(clinicId);
		this.isLoading = false;

		return new ProviderTransferToProviderConverter().convertList(response.data.providers);
	}

	public async getClinicProvidersNotInGroup(clinicId: string, providerGroupId: string): Promise<Provider[]>
	{
		const clinicProviders = await this.getClinicProviders(clinicId);
		const clinicProviderGroupProviders = await this.getClinicProviderGroupProviders(clinicId, providerGroupId);

		return clinicProviders.filter(
			provider => !clinicProviderGroupProviders.find(
				groupProvider => groupProvider.id === provider.id,
			),
		);
	}

	public abstract patientClinicProvider(clinicId, providerId): Promise<any>;

	public abstract clinicAvailableAppointments(
		clinicId,
		providerId,
		appointmentTypeId,
		startDate,
		endDate): Promise<any>;

	public abstract getCloudMdClinic(): Promise<ClinicProfile>;

	public abstract connectToClinic(clinicId, connectionRequest): Promise<ClinicProfile>;

	public abstract disconnectFromClinic(clinicId): Promise<ClinicProfile>;

	public abstract getAppointmentTypes(clinicId, providerId): Promise<AppointmentType[]>;

	public abstract getAppointmentType(clinicId, appointmentTypeId): Promise<AppointmentType>;

	protected abstract viewableClinicsInLocRange(provinceCode, city, address, postalCode): Promise<any>;

	protected abstract viewableClinicsInCoordRange(latitude, longitude): Promise<any>;

	protected abstract patientClinics(bookableOnly, messageableOnly, availableOnly, connectedOnly): Promise<any>;

	protected abstract patientClinic(clinicId): Promise<any>;

	protected abstract patientClinicProviderGroups(clinicId): Promise<any>;

	protected abstract patientClinicProviderGroupsByProvider(clinicId, providerId): Promise<any>;

	protected abstract patientClinicProviderGroupProviders(clinicId, providerGroupId): Promise<any>;

	protected abstract patientClinicProviders(clinicId): Promise<any>;

	protected abstract patientClinicProviderGroup(clinicId, providerGroupId): Promise<any>;

	protected async getClinics(
		onlyBookable: boolean,
		onlyMessageable: boolean,
		availableOnly = false,
		connectedOnly = false): Promise<ClinicProfile[]>
	{
		const response = await this.patientClinics(onlyBookable, onlyMessageable, availableOnly, connectedOnly);
		return response.data.clinics.map((clinicTransfer: ClinicTransfer) =>
																		 {
																			 return new ClinicProfile(clinicTransfer);
																		 });
	}
}
