/* import {ToastOptions, LoadingOptions} from "@ionic/core"; */
import {ToastOptions, LoadingOptions} from "@ionic/core";
import {loadingController, toastController} from "@ionic/vue";
// importing anything from @ionic/core is causing linter errors
export interface CustomLoaderOpts
{
	loaderOpts?: LoadingOptions; // LoadingOptions
	toastOpts?: ToastOptions; // ToastOptions
}

export const defaultToastOpts: ToastOptions = {
	message: "Updated",
	duration: 1500,
	buttons: [
		{
			text: "Close",
		},
	],
	mode: "ios",
};

export const profileSavedToastOpts: ToastOptions = {
	message: "Profile Saved",
	duration: 1500,
	buttons: [
		{
			text: "Close",
		},
	],
	mode: "ios",
};

// Called within Vue templates via ".call(this)" for context
export async function presentIonLoader<T>(asyncRequest: () => Promise<T>, opts: CustomLoaderOpts = {}): Promise<T>
{
	await dismissActive.call(this);

	const {loaderOpts, toastOpts} = opts;

	const loader = await loadingController.create(loaderOpts);
	await loader.present();

	try
	{
		const response: T = await asyncRequest();
		await loader.dismiss();

		if (toastOpts)
		{
			const toast = await toastController.create(toastOpts);
			await toast.present();
		}

		return response;
	}
	catch (err)
	{
		await loader.dismiss();
		throw err;
	}
}

async function dismissActive()
{
	const currentLoader = await loadingController.getTop();
	const currentToast = await toastController.getTop();

	if (currentLoader)
	{
		await currentLoader.dismiss();
	}

	if (currentToast)
	{
		await currentToast.dismiss();
	}
}
