
	import {Options, Vue, Prop} from "vue-property-decorator";

	import {Mail} from "@/lib/Mailbox/Mail.model";
	import AttachmentViewer from "@/views/patient_user/mail/components/AttachmentViewer.vue";

	@Options({components: {AttachmentViewer}})
	export default class Message extends Vue
	{
		@Prop({type: Object}) public message: Mail;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public formatMessageDate(message: Mail)
		{
			return message.createdAt.format("LLLL");
		}
	}
