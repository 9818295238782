
	import {LOGO_BOOKING} from "@/assets/AppIcons";
	import {Options, Prop, Vue} from "vue-property-decorator";
	import {PeerData} from "@/lib/telehealth/models/telehealth.types";
	import {alertController, IonChip, IonLabel} from "@ionic/vue";

	@Options({components: {IonChip, IonLabel}})
	export default class TelehealthHeader extends Vue
	{
		@Prop(Object) public readonly peerData: PeerData;

		headerProps = {
			headerText: "Telehealth",
			headerIcon: LOGO_BOOKING,
			hasClose: true,
		};

		public onExit()
		{
			alertController
				.create({
					header: this.$mhat("TelehealthHeader.ExitAlertHeader"),
					message: this.$mhat("TelehealthHeader.ExitAlertMessage"),
					buttons: [
						{
							text: this.$mhat("TelehealthHeader.Decline"),
						},
						{
							text: this.$mhat("TelehealthHeader.Accept"),
							handler: () => this.$emit("exit"),
						},
					],
				})
				.then((alertEl) =>
				{
					alertEl.present();
				});
		}
	}

