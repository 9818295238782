import {Vue} from "vue-property-decorator";
import {LoginToken} from "@/lib/models/LoginToken";
import AuthStore, {LOGIN_TOKEN_KEY, UserModel} from "@/lib/vuex/auth.store";
import {Route} from "@/router/router";
import {deleteCookie} from "@/lib/utils/cookies";

export abstract class BaseLoginService
{
	public loginTokenKey = LOGIN_TOKEN_KEY;
	public authStore = AuthStore;

	public abstract login(email: string, password: string): Promise<UserModel>;

	public async isLoggedIn(): Promise<boolean>
	{
		this.getLoggedInToken();

		if (this.authStore.isLoggedIn() && !this.authStore.loggedInUser)
		{
			await this.authStore.fetchLoggedInUser();
		}

		return this.authStore.isLoggedIn();
	}

	// TODO don't pass components to services
	public logout(instance: Vue = null)
	{
		this.authStore.clearLoginToken();
		this.authStore.clearLoggedInUser();
		localStorage.removeItem(this.loginTokenKey);
		deleteCookie(this.loginTokenKey);

		if (instance)
		{
			instance.$mhaRouterPush(Route.Login.Home);
		}
	}

	protected setLoggedInUser(user: UserModel): UserModel
	{
		this.authStore.setLoggedInUser(user);
		return this.authStore.loggedInUser;
	}

	public setLoggedInToken(loginToken: LoginToken)
	{
		this.authStore.setLoginToken(loginToken);
		localStorage.setItem(this.loginTokenKey, loginToken.tokenStr);
	}

	private getLoggedInToken(): LoginToken
	{
		if (!this.authStore.loginToken)
		{
			const token: string = localStorage.getItem(this.loginTokenKey);

			try
			{
				this.setLoggedInToken(new LoginToken(token));
			}
			catch (error)
			{
				return null;
			}
		}
		return this.authStore.loginToken;
	}
}
