import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fe8ffb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhitelabelHeaderBar = _resolveComponent("WhitelabelHeaderBar")!
  const _component_HeaderBarForgot = _resolveComponent("HeaderBarForgot")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_CorporateFooter = _resolveComponent("CorporateFooter")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resetPassword && _ctx.resetPassword(...args)), ["prevent"]))
  }, [
    _createVNode(_component_PageWrapper, {
      backgroundImage: _ctx.isWhiteLabelMode,
      backgroundHideOverflow: ""
    }, _createSlots({
      "content-header": _withCtx(() => [
        (_ctx.isWhiteLabelMode)
          ? (_openBlock(), _createBlock(_component_WhitelabelHeaderBar, {
              key: 0,
              "organization-id": _ctx.organization.id
            }, null, 8, ["organization-id"]))
          : (_openBlock(), _createBlock(_component_HeaderBarForgot, {
              key: 1,
              headerText: this.$mhat('LoginReset.HeaderText')
            }, null, 8, ["headerText"]))
      ]),
      _: 2
    }, [
      (_ctx.isWhiteLabelMode)
        ? {
            name: "background",
            fn: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.backgroundImage,
                alt: "Hero Image"
              }, null, 8, _hoisted_1)
            ])
          }
        : undefined,
      (!_ctx.reset)
        ? {
            name: "content-top",
            fn: _withCtx(() => [
              _createElementVNode("p", {
                class: _normalizeClass({'dark-mode': _ctx.isWhiteLabelMode})
              }, _toDisplayString(this.$mhat("LoginReset.ResetPasswordInstructionText")), 3),
              _createVNode(_component_PasswordField, {
                label: this.$mhat('LoginReset.NewPasswordFieldLabel'),
                id: "password",
                data: _ctx.password,
                valid: _ctx.validPassword,
                onInput: _ctx.inputPassword
              }, null, 8, ["label", "data", "valid", "onInput"]),
              _createVNode(_component_PasswordField, {
                label: this.$mhat('LoginReset.ConfirmPasswordFieldLabel'),
                id: "confirmPassword",
                data: _ctx.confirmPassword,
                valid: _ctx.validConfirmPassword,
                onInput: _ctx.inputConfirmPassword
              }, null, 8, ["label", "data", "valid", "onInput"]),
              _createVNode(_component_BaseButton, {
                type: "submit",
                primary: "",
                disabled: !_ctx.canSubmit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("LoginReset.ResetPasswordButtonText")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          }
        : {
            name: "content-center",
            fn: _withCtx(() => [
              _createVNode(_component_EventStateSpinner, {
                showFailureState: _ctx.isError,
                isLoading: _ctx.loadingQueue.isLoading,
                loadingTitle: this.$mhat('LoginReset.LoadingTitle'),
                successTitle: this.$mhat('LoginReset.PasswordResetSuccessText'),
                successMessage: _ctx.resetSuccessMessage,
                failureTitle: this.$mhat('LoginReset.PasswordResetFailureText'),
                failureMessage: _ctx.resetErrorMessage,
                textColorLight: _ctx.isWhiteLabelMode
              }, null, 8, ["showFailureState", "isLoading", "loadingTitle", "successTitle", "successMessage", "failureTitle", "failureMessage", "textColorLight"]),
              (!_ctx.loadingQueue.isLoading && _ctx.isError)
                ? (_openBlock(), _createBlock(_component_RouteButton, {
                    key: 0,
                    primary: "",
                    route: _ctx.routeForgot
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("LoginReset.TryAgainButtonText")), 1)
                    ]),
                    _: 1
                  }, 8, ["route"]))
                : _createCommentVNode("", true)
            ])
          },
      (!_ctx.isWhiteLabelMode)
        ? {
            name: "content-bottom",
            fn: _withCtx(() => [
              _createVNode(_component_CorporateFooter)
            ])
          }
        : {
            name: "content-footer",
            fn: _withCtx(() => [
              _createVNode(_component_CorporateFooter, {
                branded: "",
                "brand-white-bg": "",
                "show-support-link": false
              })
            ])
          }
    ]), 1032, ["backgroundImage"])
  ], 32))
}