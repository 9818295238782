

	import {Options, Vue, Prop} from "vue-property-decorator";
	import router from "@/router/router";
	import {RouteLocationNormalized, NavigationGuardNext} from "vue-router";
	import {popoverController, IonContent} from "@ionic/vue";

	@Options({components: {IonContent}})
	export default class PopoverBase extends Vue
	{
		protected routerGuardUnregisterFunction: () => void = null;

		// ==========================================================================
		// Vue life cycle hooks
		// ==========================================================================

		public mounted(): void
		{
			this.routerGuardUnregisterFunction = router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void =>
			{
				popoverController.dismiss();
				next();
			});
		}

		public beforeUnmount()
		{
			this.routerGuardUnregisterFunction();
		}
	}
