import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c1b8c9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "event-state-spinner" }
const _hoisted_2 = { class: "event-state-icon" }
const _hoisted_3 = { class: "icon-container" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "spinner"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["event-state-container", {containerClass: _ctx.containerClass, 'text-color-light': _ctx.textColorLight}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_Transition, { name: "vue-fade" }, {
            default: _withCtx(() => [
              (_ctx.isFailureState)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    class: _normalizeClass(_ctx.failureIcon)
                  }, null, 2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_Transition, { name: "vue-fade" }, {
            default: _withCtx(() => [
              (_ctx.isSuccessState)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                    (!_ctx.successIcon)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.LOGO_BRAND,
                          alt: "Brand Logo"
                        }, null, 8, _hoisted_5))
                      : (_openBlock(), _createElementBlock("i", {
                          key: 1,
                          class: _normalizeClass(_ctx.successIcon)
                        }, null, 2))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_Transition, { name: "vue-fade" }, {
            default: _withCtx(() => [
              (_ctx.isLoadingState)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    (!_ctx.getLoadingIcon)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.LOGO_BRAND,
                          alt: "Brand Logo"
                        }, null, 8, _hoisted_7))
                      : (_openBlock(), _createElementBlock("i", {
                          key: 1,
                          class: _normalizeClass(_ctx.getLoadingIcon)
                        }, null, 2))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(_Transition, { name: "vue-fade" }, {
        default: _withCtx(() => [
          (_ctx.isLoadingState)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    (_ctx.isFailureState && _ctx.failureTitle)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_9, _toDisplayString(_ctx.failureTitle), 1))
      : _createCommentVNode("", true),
    (_ctx.isSuccessState && _ctx.successTitle)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_10, _toDisplayString(_ctx.successTitle), 1))
      : _createCommentVNode("", true),
    (_ctx.isLoadingState && _ctx.loadingTitle)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_11, _toDisplayString(_ctx.loadingTitle), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMessageArray, (message, index) => {
      return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(message), 1))
    }), 128))
  ], 2))
}