import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-center": _withCtx(() => [
      (_ctx.errorMessage !== null && _ctx.errorTitle !== null)
        ? (_openBlock(), _createBlock(_component_EventStateSpinner, {
            key: 0,
            isLoading: false,
            successIcon: "icon-exclamation",
            successTitle: _ctx.errorTitle,
            successMessage: _ctx.errorMessage
          }, null, 8, ["successTitle", "successMessage"]))
        : (_ctx.errorCode === 404)
          ? (_openBlock(), _createBlock(_component_EventStateSpinner, {
              key: 1,
              isLoading: false,
              successIcon: "icon-exclamation",
              successTitle: _ctx.$mhat('ErrorPage.404Title'),
              successMessage: _ctx.$mhat('ErrorPage.404Message')
            }, null, 8, ["successTitle", "successMessage"]))
          : (_ctx.errorCode === 403)
            ? (_openBlock(), _createBlock(_component_EventStateSpinner, {
                key: 2,
                isLoading: false,
                successIcon: "icon-exclamation",
                successTitle: _ctx.$mhat('ErrorPage.403Title'),
                successMessage: _ctx.$mhat('ErrorPage.403Message')
              }, null, 8, ["successTitle", "successMessage"]))
            : (_openBlock(), _createBlock(_component_EventStateSpinner, {
                key: 3,
                isLoading: false,
                successIcon: "icon-exclamation",
                successTitle: _ctx.$mhat('ErrorPage.ErrorTitle'),
                successMessage: _ctx.$mhat('ErrorPage.ErrorMessage', {code: _ctx.errorCode})
              }, null, 8, ["successTitle", "successMessage"])),
      _createVNode(_component_RouteButton, {
        primary: "",
        route: _ctx.Route.Home
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$mhat("ErrorPage.GoHome")), 1)
        ]),
        _: 1
      }, 8, ["route"])
    ]),
    _: 1
  }))
}