import {Capacitor, Plugins} from "@capacitor/core";
import AuthStore from "@/lib/vuex/auth.store";
import DeviceInfo from "@/lib/DeviceInfo";

enum ClientMessage
{
	GetAppVersion = "GetAppVersion",
	CreateVideoView = "CreateVideoView",
	OpenVideoView = "OpenVideoView",
	CloseVideoView = "CloseVideoView",
	DestroyVideoView = "DestroyVideoView",
	LockPageView = "LockPageView",
	UserLogin = "UserLogin",
	SetPushTokens = "SetPushTokens",
	GetSessionConnection = "GetSessionConnection",
	SendChatMessage = "SendChatMessage",
	SendTypingSignal = "SendTypingSignal",
	StopRinging = "StopRinging",
	EnableVideo = "EnableVideo",
}

// Specific to iOS native opentok library
export enum OTSessionConnectionStatus
{
	CONNECTED = "connected",
	CONNECTING = "connecting",
	DISCONNECTING = "disconnecting",
	FAILED = "failed",
	NOT_CONNECTED = "notConnected",
	RECONNECTING = "reconnecting",
}

export interface IOSCallData
{
	sessionId: string;
	sessionToken: string;
	appointmentId: string;
	apiKey: string;
	handle: string;
	voip: boolean;
	videoEnabled: boolean;
}

/*
 * NativeBridge class methods execute callback methods made available by the iOS and Android
 * WebView implementations for MyHealthAccess and CloudMD
 */
export class NativeBridge
{
	public static onUserLogin(token: string)
	{
		this.execute(ClientMessage.UserLogin, { token });
	}

	/**
	 * tell the native client to update it's push tokens (send them to the MHA server).
	 */
	public static setPushTokens()
	{
		this.execute(ClientMessage.SetPushTokens, { token: AuthStore.loggedInUser.loginToken.tokenStr});
	}

	public static lockPageView(lock: boolean)
	{
		this.execute(ClientMessage.LockPageView, { lock });
	}

	public static createVideoView(callData: IOSCallData)
	{
		this.execute(ClientMessage.CreateVideoView, callData);
	}

	public static openVideoView()
	{
		this.execute(ClientMessage.OpenVideoView, {});
	}

	public static closeVideoView()
	{
		this.execute(ClientMessage.CloseVideoView, {});
	}

	public static publishVideo(enable: boolean)
	{
		this.execute(ClientMessage.EnableVideo, {enabled: enable});
	}

	public static destroyVideoView()
	{
		this.execute(ClientMessage.DestroyVideoView, {});
	}

	public static async getSessionConnectionStatus(): Promise<OTSessionConnectionStatus>
	{
		if (DeviceInfo.isIOS())
		{
			try
			{
				const response = await this.execute(
					ClientMessage.GetSessionConnection, {}) as {connectionStatus: OTSessionConnectionStatus};

				return response?.connectionStatus;
			}
			catch (e)
			{
				console.error("Error fetching iOS native session connection status", e);
			}
		}
	}

	/**
	 * send chat message to remote clients in the telehealth session
	 * @param message - the chat message to send
	 */
	public static async sendChatMessage(message: string): Promise<void>
	{
		await this.execute(ClientMessage.SendChatMessage, {message});
	}

	/**
	 * send a typing signal to remote clients in the telehealth session
	 */
	public static async sendTypingSignal(): Promise<void>
	{
		await this.execute(ClientMessage.SendTypingSignal, {});
	}

	/*
	* tell phones that they should stop ringing if applicable.
	*/
	public static async stopRinging(): Promise<void>
	{
		await this.execute(ClientMessage.StopRinging, {});
	}

	public static async getAppVersion(): Promise<string>
	{
		if (DeviceInfo.isIOS())
		{
			const response = await this.execute(ClientMessage.GetAppVersion, {});

			// @ts-ignore
			return response.version;
		}
		else if (DeviceInfo.isAndroid())
		{
			const promise: Promise<any> = this.waitForNativeResponse(ClientMessage.GetAppVersion);
			await this.execute(ClientMessage.GetAppVersion, {});

			let version = (await promise);
			if (version)
			{
				// trim off android package name
				version = version.replace(/(\d+\.\d+\.\d+).*/, "$1");
			}
			return version;
		}
	}

	private static async execute(messageId: string, data: any): Promise<any>
	{
		if (DeviceInfo.isIOS())
		{
			if (Capacitor.isPluginAvailable("NativeBridgePlugin"))
			{
				return await Plugins.NativeBridgePlugin.execute({messageId, ...data});
			}
			else
			{
				// Deprecated as of MHA-1296. Kept for backwards compatibility. Remove in future
				// @ts-ignore
				return await window.webkit.messageHandlers.jsHandler.postMessage({messageId, ...data});
			}
		}
		else if (DeviceInfo.isAndroid())
		{
			// @ts-ignore
			return await window.androidBridge.postMessage(messageId, JSON.stringify(data));
		}
	}

	/**
	 * Wait for message.
	 * @param message - the native message to wait for
	 * @param timeout - waiting timeout in ms
	 * @return the data or null if a timeout occurs
	 */
	private static waitForNativeResponse(message: string, timeout = 5000): Promise<any>
	{
		return Promise.race([
			new Promise((resolve, reject) =>
			{
				window.addEventListener(message, (event: any) =>
				{
					resolve(event.data);
				}, {once: true});
			}),
			new Promise((resolve) =>
			{
				window.setTimeout(() => resolve(null), timeout);
			}),
		]);
	}
}
