import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {TelehealthChatMessageDto} from "@/open_api/generated";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import moment from "moment";

export default class TelehealthChatMessageDtoToChatMessageConverter
	extends AbstractConverter<TelehealthChatMessageDto, ChatMessage>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	public convert(from: TelehealthChatMessageDto): ChatMessage
	{
		return new ChatMessage(from.id, from.message, from.message_type, from.sender_id, from.sender_type, moment(from.created_at));
	}
}
