

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {PatientUserDto, ProvinceCode, Sex} from "@/open_api/generated";
	import moment from "moment";
	import PhoneFormatUtils from "@/lib/utils/PhoneFormatUtils";
	import {ClinicAPI} from "@/lib/services/Api";

	@Options({})
	export default class PatientDetails extends Vue
	{
		@Prop({type: Object}) public patient: PatientUserDto;
		@Prop({type: Object}) public remotePatient: PatientUserDto;

		private readonly BIRTH_DATE_FORMAT = "MMMM Do YYYY";

		// ==========================================================================
		// Getters
		// ==========================================================================

		get healthNumberWithVersion(): string
		{
			if (this.remotePatient.health_care_province_code === ProvinceCode.ON)
			{
				// Ontario append version code
				return `${this.remotePatient.health_number} - ${this.remotePatient.health_number_version}`;
			}
			return this.remotePatient.health_number;
		}

		get patientSex(): string
		{
			return this.remotePatient.sex === Sex.M ? "Male" : "Female";
		}

		get patientBirthDate(): string
		{
			return moment(this.remotePatient.birth_date).format(this.BIRTH_DATE_FORMAT);
		}

		get patientPhoneNumber(): string
		{
			if (this.isDependent)
			{
				return PhoneFormatUtils.formatPhoneNumber(this.patient.guardian.cell_phone);
			}
			return PhoneFormatUtils.formatPhoneNumber(this.remotePatient.cell_phone);
		}

		get guardianDisplayName(): string
		{
			return `${this.patient.guardian.first_name}, ${this.patient.guardian.last_name}`;
		}

		get isDependent(): boolean
		{
			return this.patient != null && this.patient.is_dependent;
		}
	}
