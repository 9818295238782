import DeviceInfo from "../DeviceInfo";

export const fbInitPromise =
Promise.race(
	[
		new Promise((resolve, reject) =>
		{
			window.fbAsyncInit = function()
			{
				FB.init({
					appId: "2286368548326779",
					xfbml: true,
					version: "v6.0",
				});
				FB.AppEvents.setAppVersion(DeviceInfo.appVersion);
				FB.AppEvents.logPageView();
				resolve();
			};
		}),
		new Promise((resolve, reject) =>
		{
			window.setTimeout(() =>
			{
				if (DeviceInfo.isCloudMd && DeviceInfo.isProduction())
				{
					reject("Facebook Initialization Timeout");
				}
				else
				{
					resolve();
				}
			}, 5000);
		}),
	]);
fbInitPromise.catch((err) =>
{ /* suppress */ });

if (DeviceInfo.isCloudMd && DeviceInfo.isProduction() && typeof FB === "undefined")
{
	(function (d, s, id)
	{
		var js; var fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id))
		{
			return;
		}
		js = d.createElement(s);
		js.id = id;
		js.src = "https://connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore(js, fjs);
	}(document, "script", "facebook-jssdk"));
}
