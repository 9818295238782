import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c626e0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_ClinicLogo = _resolveComponent("ClinicLogo")!
  const _component_ProfileInputFields = _resolveComponent("ProfileInputFields")!
  const _component_LoaderButton = _resolveComponent("LoaderButton")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_ctx.controller)
    ? (_openBlock(), _createBlock(_component_PageWrapper, { key: 0 }, {
        "content-header": _withCtx(() => [
          _createVNode(_component_AppHeader, {
            title: _ctx.headerTitle,
            onClose: _ctx.closeButtonOpts.handler
          }, null, 8, ["title", "onClose"])
        ]),
        "content-top": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ClinicLogo, { clinic: _ctx.clinic }, null, 8, ["clinic"])
          ]),
          _createVNode(_component_ProfileInputFields, {
            includeSectionHeaders: "",
            includeFieldLabels: "",
            onProfileChange: _ctx.onProfileChange,
            controller: _ctx.controller,
            inputIds: _ctx.profileFields,
            requiredInputIds: _ctx.profileFields
          }, null, 8, ["onProfileChange", "controller", "inputIds", "requiredInputIds"]),
          _createVNode(_component_LoaderButton, {
            pattern: _ctx.ButtonColorPattern.FILL,
            color: _ctx.ButtonColor.PRIMARY,
            disabled: !_ctx.allFieldsValid,
            loading: false,
            onClick: _ctx.handleClinicConnectionRequest
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(this.$mhat("RequiredProfileModal.UpdateInfoButtonText")), 1)
            ]),
            _: 1
          }, 8, ["pattern", "color", "disabled", "onClick"]),
          (_ctx.isDependent)
            ? (_openBlock(), _createBlock(_component_RouteButton, {
                key: 0,
                route: {name: _ctx.Route.Home}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(this.$mhat("RequiredProfileModal.CancelButtonText")), 1)
                ]),
                _: 1
              }, 8, ["route"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}