import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeaderBar = _resolveComponent("MobileHeaderBar")!

  return (_openBlock(), _createBlock(_component_MobileHeaderBar, _mergeProps({
    icon: _ctx.icon,
    "header-text": _ctx.headerText,
    "no-back": _ctx.noBack,
    "has-close": _ctx.hasClose,
    "button-opts": _ctx.buttonOpts,
    "back-route": _ctx.backRoute
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["icon", "header-text", "no-back", "has-close", "button-opts", "back-route"]))
}