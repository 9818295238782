

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({components: {}})
	export default class PulsingIndicator extends Vue
	{
		@Prop({type: Boolean, default: true}) pulsing: boolean;

		// ==========================================================================
		// Getters
		// ==========================================================================

		get indicatorClasses(): any
		{
			return {
				"pulse-active": this.pulsing,
			};
		}
	}
