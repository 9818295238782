

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {IonFabButton, IonFab} from "@ionic/vue";

	@Options({
		emits: ["click"],
		components: {IonFabButton, IonFab},
	})
	export default class FloatingActionButton extends Vue
	{
		@Prop({type: String, default: "icon-add"}) icon: string;
		@Prop({type: String, default: "bottom"}) vertical: string;
		@Prop({type: String, default: "end"}) horizontal: string;
		@Prop({type: Boolean}) disabled: boolean;
	}
