import AbstractConverter from "@/lib/conversion/AbstractConverter";
import MhaDocument from "@/lib/document/model/MhaDocument";
import {RemoteDocumentCreateTransfer} from "@/open_api/generated";
import FileUtil from "@/lib/utils/FileUtil";

export default class MhaDocumentToRemoteDocumentCreateTransferConverter extends AbstractConverter<MhaDocument, Promise<RemoteDocumentCreateTransfer>>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	public async convert(from: MhaDocument): Promise<RemoteDocumentCreateTransfer>
	{
		return {
			name: from.name,
			base64_data: await from.base64Data(),
		};
	}
}
