import {OneTimeCodeType} from "@/lib/oneTimeCode/model/OneTimeCodeType";
import {Moment} from "moment";

export default class OneTimeCode
{
	private _type: OneTimeCodeType;
	private _code: string;
	private _expireAt: Moment;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	public constructor(type: OneTimeCodeType, code: string, expireAt: Moment)
	{
		this._type = type;
		this._code = code;
		this._expireAt = expireAt;
	}

	// ==========================================================================
	// Getter methods
	// ==========================================================================

	get type(): OneTimeCodeType
	{
		return this._type;
	}

	get code(): string
	{
		return this._code;
	}

	get expireAt(): Moment
	{
		return this._expireAt;
	}
}
