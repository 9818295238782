import SessionClient from "@/lib/telehealth/opentok/SessionClient";
import {Connection, Session} from "@opentok/client";
import {SignalDispatcher} from "@/lib/telehealth/opentok/SignalDispatcher";
import {
	ConnectionEvent,
	OT_CUSTOM_SIGNAL,
	OT_EVENT_TYPE,
	OT_LOCAL_SIGNAL,
	SignalEvent,
	StreamEvent,
} from "@/lib/telehealth/opentok/ot.types";
import {TELEHEALTH_STATE} from "@/lib/telehealth/TelehealthInterface";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import TelehealthError, {TELEHEALTH_ERROR_TYPE} from "@/lib/telehealth/error/TelehealthError";
import SessionCommunicator from "@/lib/telehealth/opentok/SessionCommunicator";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import {reactive, ref, Ref, unref, UnwrapRef} from "vue";

/**
 * responsible for managing the state of the telehealth call
 */
export default class StateTracker
{
	protected sessionClients: SessionClient[] = reactive([]);
	protected otSession: Session = null;
	protected signalDispatcher: SignalDispatcher = null;
	protected sessionCommunicator: SessionCommunicator = null;
	protected onRemoteConnectedCallbacks: CallbackCollection = null;
	protected onRemoteStateChangeCallbacks: CallbackCollection = null;
	protected onRemoteDisconnectedCallbacks: CallbackCollection = null;
	protected onCallStateChangeCallbacks: CallbackCollection = null;
	protected onRemoteEnterRoomCallbacks: CallbackCollection = null;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		signalDispatcher: SignalDispatcher,
		sessionCommunicator: SessionCommunicator,
		onRemoteConnectedCallbacks: CallbackCollection,
		onRemoteStateChangeCallbacks: CallbackCollection,
		onRemoteDisconnectedCallbacks: CallbackCollection,
		onCallStateChangeCallbacks: CallbackCollection,
		onRemoteEnterRoomCallbacks: CallbackCollection)
	{
		this.signalDispatcher = signalDispatcher;
		this.sessionCommunicator = sessionCommunicator;
		this.onRemoteConnectedCallbacks = onRemoteConnectedCallbacks;
		this.onRemoteStateChangeCallbacks = onRemoteStateChangeCallbacks;
		this.onRemoteDisconnectedCallbacks = onRemoteDisconnectedCallbacks;
		this.onCallStateChangeCallbacks = onCallStateChangeCallbacks;
		this.onRemoteEnterRoomCallbacks = onRemoteEnterRoomCallbacks;
		this.setupSessionHooks();
	}

	/**
	 * Used to get around circular dependence. SessionCommunicator depends on StateTracker and vice versa. Yup I don't
	 * like it ether.
	 * @param communicator
	 */
	public setSessionCommunicator(communicator: SessionCommunicator): void
	{
		this.sessionCommunicator = communicator;
	}

	public setOtSession(otSession: Session): void
	{
		this.otSession = otSession;
	}

	public clearOtSession(): void
	{
		this.sessionClients = reactive([]);
		this.otSession = null;
	}

	/**
	 * create the client object representing our self.
	 */
	public createSelf(clientInfo?: TelehealthClient): void
	{
		const client = new SessionClient(this.otSession.connection, this.otSession.connection);
		if (clientInfo)
		{
			client.updateFromTelehealthClient(clientInfo);
		}
		client.setState(TELEHEALTH_STATE.INITIAL_STATE);
		this.sessionClients.push(client);
	}

	/**
	 * update the object representing our self
	 * @param clientInfo
	 */
	public updateSelf(clientInfo: TelehealthClient): void
	{
		const self = this.getSelf;
		if (self)
		{
			self.updateFromTelehealthClient(clientInfo);
			// notify remotes of change
			this.sessionCommunicator.broadcast(OT_CUSTOM_SIGNAL.PEER_META_DATA, self.toJson);
		}
		else
		{
			throw new TelehealthError(
				TELEHEALTH_ERROR_TYPE.LOGIC_ERROR,
				"Failed to update self. Could not find self!");
		}
	}

	public getRemoteClientByConnectionId(connectionId: string): SessionClient
	{
		return this.sessionClients.find((client) => client.connection.connectionId === connectionId) as SessionClient;
	}

	public getRemoteClientByUserId(userId: string): SessionClient
	{
		return this.sessionClients.find((client) => client.userId === userId) as SessionClient;
	}

	public removeRemoteClientByConnectionId(connectionId: string)
	{
		this.sessionClients = reactive(this.sessionClients
			.filter((client) => client.connection.connectionId !== connectionId)) as SessionClient[];
	}

	/**
	 * determine if the passed connect is our connection.
	 * @param connection - the connection to inspect
	 */
	public isSelfConnection(connection: Connection): boolean
	{
		return this.otSession?.connection.connectionId === connection.connectionId;
	}

	/**
	 * check if an event was triggered by the local client
	 * @param event - the event to check.
	 * @return true / false indicating if the event originated from this client
	 */
	public isMyEvent(event: SignalEvent): boolean
	{
		return this.isSelfConnection(event.from);
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	/**
	 * get the session client which represents us!
	 */
	get getSelf(): SessionClient
	{
		return this.sessionClients.find((client) => client.isSelf) as SessionClient;
	}

	get state(): TELEHEALTH_STATE
	{
		if (this.getSelf)
		{
			return this.getSelf.state;
		}
		return TELEHEALTH_STATE.NOT_CONNECTED;
	}

	get inRoom(): boolean
	{
		return this.state === TELEHEALTH_STATE.IN_ROOM;
	}

	get outOfRoom(): boolean
	{
		return this.state === TELEHEALTH_STATE.OUT_OF_ROOM;
	}

	/**
	 * get all clients in the session
	 */
	get clients(): SessionClient[]
	{
		return this.sessionClients as SessionClient[];
	}

	/**
	 * true if at least one client in the session is a legacy client
	 */
	get hasLegacyClients(): boolean
	{
		return this.clients.reduce((acc: boolean, val) => acc = acc || val.isLegacyClient, false);
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	/**
	 * setup signal hooks. Should only be called once at construction time
	 * @protected
	 */
	protected setupSessionHooks(): void
	{
		this.signalDispatcher.addSignalHandler(OT_EVENT_TYPE.CONNECTION_CREATED, this.onClientConnect, this);
		this.signalDispatcher.addSignalHandler(OT_EVENT_TYPE.CONNECTION_DESTROYED, this.onClientDisconnect, this);
		this.signalDispatcher.addSignalHandler(OT_EVENT_TYPE.STREAM_CREATED, this.onStreamCreated, this);
		this.signalDispatcher.addSignalHandler(OT_EVENT_TYPE.STREAM_DESTROYED, this.onStreamDestroyed, this);

		this.signalDispatcher.addSignalHandler(OT_CUSTOM_SIGNAL.PEER_META_DATA, this.onIncomingPeerData, this);

		this.signalDispatcher.addSignalHandler(OT_LOCAL_SIGNAL.STATE_CHANGE, this.onLocalStateChange, this);
	}

	protected async onClientConnect(event: ConnectionEvent): Promise<void>
	{
		if (!this.getRemoteClientByConnectionId(event.connection.connectionId))
		{
			const client = new SessionClient(event.connection, this.otSession.connection);
			this.sessionClients.push(client);

			if (!this.isSelfConnection(event.connection))
			{
				await this.onRemoteConnectedCallbacks.call(event.connection.connectionId);
			}
		}
	}

	protected onClientDisconnect(event: ConnectionEvent): void
	{
		this.removeRemoteClientByConnectionId(event.connection.connectionId);

		// notify callbacks
		this.onRemoteDisconnectedCallbacks.call(event.connection.connectionId);
	}

	protected onStreamCreated(event: StreamEvent): void
	{
		const client = this.getRemoteClientByConnectionId(event.stream.connection.connectionId);
		if (client)
		{
			client.setStream(event.stream);

			if (!client.isSelf)
			{
				this.onRemoteStateChangeCallbacks.call(client.toTelehealthClient);
			}
		}
		else
		{
			console.error("Received a stream creation event for client we don't know about!");
		}
	}

	protected onStreamDestroyed(event: StreamEvent): void
	{
		const client = this.getRemoteClientByConnectionId(event.stream.connection.connectionId);
		if (client)
		{
			client.setStream(null);

			if (!client.isSelf)
			{
				this.onRemoteStateChangeCallbacks.call(client);
			}
		}
		else
		{
			console.error("Received a stream destroyed event for client we don't know about!");
		}
	}

	protected onIncomingPeerData(event: SignalEvent): void
	{
		const client = this.getRemoteClientByConnectionId(event.from.connectionId);
		if (client)
		{
			const initialClientState = client.state;

			client.updateFromPeerData(event.data);

			const newClientState = client.state;

			if (!this.isSelfConnection(event.from))
			{
				this.onRemoteStateChangeCallbacks.call(client.toTelehealthClient);
			}

			// Check if the remote has entered the room
			if (!client.isSelf && initialClientState === TELEHEALTH_STATE.OUT_OF_ROOM && newClientState === TELEHEALTH_STATE.IN_ROOM)
			{
				this.onRemoteEnterRoomCallbacks.call(client.toTelehealthClient);
			}
		}
		else
		{
			console.error("Received peer data update from client that we don't know about!");
		}
	}

	protected onLocalStateChange(event: SignalEvent): void
	{
		const self = this.getSelf;
		if (self)
		{
			self.setState(event.data as TELEHEALTH_STATE);
			// notify listeners of change
			this.onCallStateChangeCallbacks.call(self.state);
			// notify remotes of change
			this.sessionCommunicator.broadcast(OT_CUSTOM_SIGNAL.PEER_META_DATA, self.toJson);
		}
	}
}
