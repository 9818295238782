

	import {Options, Prop, Vue} from "vue-property-decorator";
	import Services from "@/lib/services/Services";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";
	import {Route} from "@/router/router";
	import BaseButton from "@/components/Buttons/BaseButton.vue";
	import CrossFrameCommunicationService from "@/lib/integration/iframe/service/CrossFrameCommunicationService";
	import {CrossFrameMessageType} from "@/lib/integration/iframe/model/CrossFrameMessageType";

	@Options({
		components: {BaseButton},
	})
	export default class ContactClinicRouteButton extends Vue
	{
		@Prop() clinicId: string;
		clinic: ClinicProfile = null;

		// ==========================================================================
		// Vue Lifecycle
		// ==========================================================================

		public mounted()
		{
			Services.PatientClinics.getClinic(this.clinicId)
				.then((clinic: ClinicProfile) =>
				{
					this.clinic = clinic;
				});
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public toClinicMessaging(): void
		{
			const crossFrameService = new CrossFrameCommunicationService();
			if (crossFrameService.hasParentFrame)
			{
				// let any parent frames know they should go to a messenger page.
				crossFrameService.sendAsyncMessage({type: CrossFrameMessageType.NavigateToMessenger});
			}

			this.$mhaRouterPush(this.messageClinicRoute);
		}

		// ==========================================================================
		// Computed Properties
		// ==========================================================================

		get isDisabled(): boolean
		{
			return Boolean(!this.clinic || !this.clinic.patientCanMessage);
		}

		get messageClinicRoute()
		{
			const {clinicId} = this;
			return {
				name: Route.Inbox.Home,
				query: {
					compose: true,
					clinicId,
				},
			};
		}
	}
