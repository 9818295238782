import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e1155b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "verification-code text-center m-t-48" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeaderBar = _resolveComponent("MobileHeaderBar")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_MobileHeaderBar, {
        headerText: this.$mhat('AccountIdCodePage.HeaderText')
      }, null, 8, ["headerText"])
    ]),
    "content-top": _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(this.$mhat("AccountIdCodePage.VerificationCodeInstructionText")), 1),
      _createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.formattedCode), 1)
    ]),
    _: 1
  }))
}