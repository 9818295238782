

	import {Options, Vue, Prop, Watch} from "vue-property-decorator";

	import {Mail} from "@/lib/Mailbox/Mail.model";
	import {MailboxType} from "@/lib/Mailbox/Mailbox.types";
	import MailHeader from "./MailHeader.vue";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import AttachmentViewer from "./AttachmentViewer.vue";
	import {TabBarItem} from "@/components/Menu/types";
	import MessageLayoutWrapper from "@/views/patient_user/mail/components/MessageLayoutWrapper.vue";
	import Conversation from "@/lib/Mailbox/Conversation.model";
	import {MailboxService} from "@/lib/Mailbox/Mailbox.service";
	import Message from "@/views/patient_user/mail/components/Message.vue";

	@Options({components: {Message, MessageLayoutWrapper, AttachmentViewer, MailHeader}})
	export default class MessageRead extends Vue
	{
		@Prop({type: Object, required: true}) mail: Mail;

		MailboxType = MailboxType;
		ButtonColor = ButtonColor;
		ButtonColorPattern = ButtonColorPattern;

		public conversation: Conversation = null;

		// ==========================================================================
		// Vue hooks
		// ==========================================================================

		@Watch("mail", {immediate: true})
		public async onMailChange(newMail: Mail): Promise<void>
		{
			if (newMail)
			{
				this.conversation = await (new MailboxService()).getConversation(newMail.conversationId);
			}
		}

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public archiveMessage()
		{
			this.$emit("archive", this.mail);
		}

		public unArchiveMessage()
		{
			this.$emit("unarchive", this.mail);
		}

		public onClose(): void
		{
			this.$emit("close");
		}

		public onReplyToMessage()
		{
			this.$emit("reply", this.mail);
		}

		// ==========================================================================
		// Getters
		// ==========================================================================

		get isMailArchived(): boolean
		{
			return Boolean(this.mail.mailbox === MailboxType.Archive);
		}

		get canArchiveMail(): boolean
		{
			return Boolean(this.mail.mailbox === MailboxType.Inbox);
		}

		get canReplyToMail(): boolean
		{
			return Boolean(this.mail.mailbox === MailboxType.Inbox);
		}

		get clinicName(): string
		{
			if (this.mail.isSenderMe)
			{
				return this.mail.recipientNames;
			}
			return this.mail.senderName;
		}

		get tabBarItems()
		{
			const items = [];
			if (this.isMailArchived)
			{
				items.push({
					icon: "icon-archive",
					label: this.$mhat("MessageRead.UnarchiveButtonLabel"),
					click: () => this.unArchiveMessage(),
				} as TabBarItem);
			}
			else
			{
				items.push({
					icon: "icon-archive",
					label: this.$mhat("MessageRead.ArchiveButtonLabel"),
					click: () => this.archiveMessage(),
					disabled: !this.canArchiveMail,
				} as TabBarItem);
			}

			items.push({
				icon: "icon-reply",
				label: this.$mhat("MessageRead.ReplyButtonLabel"),
				disabled: this.mail.isSenderMe,
				click: () => this.onReplyToMessage(),
			} as TabBarItem);
			return items;
		}

		get headerButtonOpts()
		{
			return {
				icon: "icon-close",
				text: this.$mhat("MessageRead.CloseButtonLabel"),
				action: () => this.onClose(),
			};
		}
	}
