

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {LOGO_BRAND} from "@/assets/AppIcons";

	@Options({})
	export default class SignupHeaderBar extends Vue
	{
		@Prop({type: String}) headerText: string;

		// ==========================================================================
		// Getters
		// ==========================================================================

		get headerProps(): any
		{
			return {
				headerIcon: LOGO_BRAND,
				headerText: this.headerText ? this.headerText : this.$mhat("SignupHeaderBar.HeaderText"),
				hasClose: false,
				backRoute: "/login",
				...this.$attrs,
			};
		}
	}
