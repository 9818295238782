import {MediaDeviceStatus} from "@/lib/mediaDevices";
import {UserType} from "@/open_api/generated";
import {DeviceInfo} from "@/lib/telehealth/models/telehealth.types";

export enum TelehealthClientState {
	NotConnected= "unknown",
	OutOfRoom = "outOfRoom",
	InRoom = "inRoom",
	InitialState = OutOfRoom,
}

export default class TelehealthClient
{
	protected _remoteId: string;
	protected _device: DeviceInfo;
	protected _email: string;
	protected _firstName: string;
	protected _lastName: string;
	protected _audioStatus: MediaDeviceStatus;
	protected _videoStatus: MediaDeviceStatus;
	protected _userType: UserType;
	protected _publishing: boolean; // indicates if the remote is publishing a stream
	protected _state: TelehealthClientState;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	constructor(
		device: DeviceInfo,
		email: string,
		firstName: string,
		lastName: string,
		userType: UserType,
		audioStatus?: MediaDeviceStatus,
		videoStatus?: MediaDeviceStatus,
		remoteId?: string,
		publishing?: boolean,
		state?: TelehealthClientState)
	{
		this._remoteId = remoteId;
		this._device = device;
		this._email = email;
		this._firstName = firstName;
		this._lastName = lastName;
		this._userType = userType;
		this._audioStatus = audioStatus;
		this._videoStatus = videoStatus;
		this._publishing = publishing;
		this._state = state;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get remoteId(): string
	{
		return this._remoteId;
	}

	get deviceInfo(): DeviceInfo
	{
		return this._device;
	}

	get email(): string
	{
		return this._email;
	}

	get firstName(): string
	{
		return this._firstName;
	}

	get lastName(): string
	{
		return this._lastName;
	}

	get fullName(): string
	{
		return `${this.firstName} ${this.lastName}`;
	}

	get audioStatus(): MediaDeviceStatus
	{
		return this._audioStatus;
	}

	get hasAudioAccess(): boolean
	{
		return this.videoStatus === MediaDeviceStatus.GRANTED;
	}

	get videoStatus(): MediaDeviceStatus
	{
		return this._videoStatus;
	}

	get hasVideoAccess(): boolean
	{
		return this.videoStatus === MediaDeviceStatus.GRANTED;
	}

	get userType(): UserType
	{
		return this._userType;
	}

	get isPatientUser(): boolean
	{
		return this.userType === UserType.PatientUser;
	}

	get isKioskUser(): boolean
	{
		return this.userType === UserType.KioskUser;
	}

	get isClinicUser(): boolean
	{
		return this.userType === UserType.ClinicUser;
	}

	get isOneTimeUser(): boolean
	{
		return this.userType === UserType.MyHealthAccessPublicOneTimeTelehealthToken;
	}

	// true if the telehealth client is a type of patient
	get isATypeOfPatient(): boolean
	{
		return this.isOneTimeUser || this.isKioskUser || this.isPatientUser;
	}

	get isPublishing(): boolean
	{
		return this._publishing;
	}

	get state(): string
	{
		return this._state;
	}
}
