import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhitelabelHeaderBar = _resolveComponent("WhitelabelHeaderBar")!
  const _component_SignupHeaderBar = _resolveComponent("SignupHeaderBar")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_SignupForm = _resolveComponent("SignupForm")!
  const _component_CorporateFooter = _resolveComponent("CorporateFooter")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      (_ctx.isWhitelabelMode)
        ? (_openBlock(), _createBlock(_component_WhitelabelHeaderBar, {
            key: 0,
            backRoute: _ctx.backRouteHref,
            hasClose: "",
            "on-close-click": _ctx.onClose,
            "organization-id": _ctx.organization.id
          }, null, 8, ["backRoute", "on-close-click", "organization-id"]))
        : (_openBlock(), _createBlock(_component_SignupHeaderBar, {
            key: 1,
            headerText: this.$mhat('SignupPage.HeaderText'),
            "no-back": _ctx.isBrandedMode,
            hasClose: _ctx.isBrandedMode,
            backRoute: _ctx.backRouteHref,
            "on-close-click": _ctx.onClose
          }, null, 8, ["headerText", "no-back", "hasClose", "backRoute", "on-close-click"]))
    ]),
    "content-bottom": _withCtx(() => [
      _createVNode(_component_CorporateFooter, {
        "show-support-link": !_ctx.isWhitelabelMode
      }, null, 8, ["show-support-link"])
    ]),
    _: 2
  }, [
    (!_ctx.loadingQueue.isLoading)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            _createVNode(_component_SignupForm, {
              "profile-controller": _ctx.profileController,
              "password-controller": _ctx.passwordController,
              titleText: _ctx.titleText,
              "use-org-logo": _ctx.isWhitelabelMode,
              "success-button-props": _ctx.successButtonProps,
              clinic: _ctx.clinic,
              "forgot-password-route": _ctx.forgotRoute
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RouteButton, {
                  pattern: _ctx.ButtonColorPattern.FILL,
                  color: _ctx.ButtonColor.PRIMARY,
                  route: _ctx.Route.Login.Home
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(this.$mhat("SignupPage.BackToLoginButtonText")), 1)
                  ]),
                  _: 1
                }, 8, ["pattern", "color", "route"])
              ]),
              _: 1
            }, 8, ["profile-controller", "password-controller", "titleText", "use-org-logo", "success-button-props", "clinic", "forgot-password-route"])
          ])
        }
      : undefined
  ]), 1024))
}