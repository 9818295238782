import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/lib/vuex/store";
import {KioskAppointmentRequest, KioskClinicDto} from "@/open_api/generated";
import {createProfileDTO, PatientProfileField, ProfileDTO} from "@/lib/types/Profile";
import {KioskAPI} from "@/lib/services/Api";
import {Patient} from "@/lib/patient/Patient";
import {KioskWorkflow} from "@/views/kiosk_user/types";
import {HealthCard} from "@/lib/utils/healthCardParser";
import {SessionData} from "@/lib/telehealth/models/sessionData.model";

@Module({dynamic: true, store, name: "kioskStore", namespaced: true})
class KioskStore extends VuexModule
{
	private _kioskClinics: KioskClinicDto[] = null;
	private _kioskLocation = "BC";

	_kioskWorkflow: KioskWorkflow = KioskWorkflow.Home;
	_appointmentSession: SessionData = null;

	_patientProfile: ProfileDTO = createProfileDTO();
	_appointmentReason = "";

	_requiredFields: PatientProfileField[] = [];
	_invalidFields: PatientProfileField[] = [];
	_warningFields: PatientProfileField[] = [];

	_timeout = null;
	private _onTimeoutCb: () => void = null;

	_timeoutSeconds = 240; // Default timeout after 4 minutes

	_didNetworkDisconnect = false; // If true telehealth ended due to network disconnect

	@Action
	public async fetchRequiredFields()
	{
		try
		{
			this.setRequiredFields((await KioskAPI().kioskRequiredFields(this.kioskClinicId)).data as PatientProfileField[]);
		}
		catch (err)
		{
			console.error(err);
		}
	}

	@Action
	public async getKioskClinics()
	{
		try
		{
			this.setKioskClinics((await KioskAPI().kioskClinics()).data);
		}
		catch (err)
		{
			console.error(err);
		}
	}

	@Action({rawError: true})
	public async submitForm()
	{
		const payload: KioskAppointmentRequest = {
			patient_profile: Patient.toDTO(this.patientProfile),
			reason: this.appointmentReason,
		};

		const response = await KioskAPI().kioskBookAppointment(this.kioskClinicId, payload);

		return response;
	}

	@Action
	public async clear(loadClinicInfo = false)
	{
		this.stopTimeout();
		this.setKioskWorkflow(KioskWorkflow.Home);
		this.setAppointmentSession(null);
		this.setPatientProfile(createProfileDTO());
		this.setAppointmentReason("");
		this.setInvalidFields([]);
		this.setWarningFields([]);
		this.setDidNetworkDisconnect(false);
		if (loadClinicInfo)
		{
			await this.getKioskClinics();
			await this.fetchRequiredFields();
		}
	}

	@Action
	public startTimeout()
	{
		this.stopTimeout();
		this.setTimeout(setTimeout(() =>
		{
			this.stopTimeout();
			this.onTimeoutCb();
		}, this._timeoutSeconds * 1000));
	}

	@Action
	public stopTimeout()
	{
		clearTimeout(this._timeout);
	}

	@Action
	public resetTimeout()
	{
		this.stopTimeout();
		this.startTimeout();
	}

	@Action
	public setProfileFromHealthCard(healthCard: HealthCard)
	{
		const {dobYear, dobMonth, dobDay} = healthCard.data;

		const patientProfile: ProfileDTO = {
			firstName: healthCard.data.firstName,
			lastName: healthCard.data.lastName,
			birthDate: `${dobYear}-${dobMonth}-${dobDay}`,
			address1: healthCard.data.address,
			city: healthCard.data.city,
			addressProvinceCode: healthCard.data.province,
			postalCode: healthCard.data.postal,
			healthCareProvinceCode: healthCard.data.province,
			healthNumber: healthCard.data.hin,
			healthNumberVersion: healthCard.data.versionCode,
			sex: healthCard.data.sex ? healthCard.data.sex : "",
			cellPhone: this.patientProfile.cellPhone,
			countryCode: this.patientProfile.countryCode,
		};

		const missedFields = Object.entries(patientProfile).reduce((acc, val) =>
		{
			if (this._requiredFields.includes(val[0] as PatientProfileField) && !val[1])
			{
				acc.push(val[0]);
			}
			return acc;
		}, []);

		this.setWarningFields(missedFields);
		this.setPatientProfile(patientProfile);
	}

	@Mutation
	public setTimeout(timeout)
	{
		this._timeout = timeout;
	}

	@Mutation
	public setKioskWorkflow(workflow: KioskWorkflow)
	{
		this._kioskWorkflow = workflow;
	}

	@Mutation
	public setPatientProfile(patientProfile: ProfileDTO)
	{
		this._patientProfile = patientProfile;
	}

	@Mutation
	public setAppointmentReason(reason: string)
	{
		this._appointmentReason = reason;
	}

	@Mutation
	public setAppointmentSession(sessions: SessionData): void
	{
		this._appointmentSession = sessions;
	}

	@Mutation
	public setInvalidFields(invalidFields: PatientProfileField[]): void
	{
		this._invalidFields = invalidFields;
	}

	@Mutation
	public setWarningFields(warningFields: PatientProfileField[]): void
	{
		this._warningFields = warningFields;
	}

	@Mutation
	public resolveField(fieldId: PatientProfileField): void
	{
		this._invalidFields = this._invalidFields.filter((field) => field !== fieldId);
		this._warningFields = this._warningFields.filter((field) => field !== fieldId);
	}

	@Mutation
	public setTimeoutSeconds(timeoutSeconds: number)
	{
		this._timeoutSeconds = timeoutSeconds;
	}

	@Mutation
	setOnTimeoutCb(value: () => void)
	{
		this._onTimeoutCb = value;
	}

	@Mutation
	public setRequiredFields(reqFields: PatientProfileField[])
	{
		this._requiredFields = reqFields;
	}

	@Mutation
	public setKioskClinics(kioskClinics: KioskClinicDto[])
	{
		this._kioskClinics = kioskClinics;
	}

	@Mutation
	public setKioskLocation(kioskLocation: string): void
	{
		this._kioskLocation = kioskLocation;
	}

	@Mutation
	public setDidNetworkDisconnect(didDisconnect: boolean): void
	{
		this._didNetworkDisconnect = didDisconnect;
	}

	get appointmentReason(): string
	{
		return this._appointmentReason;
	}

	get patientProfile(): ProfileDTO
	{
		return this._patientProfile;
	}

	get kioskWorkflow(): KioskWorkflow
	{
		return this._kioskWorkflow;
	}

	get appointmentSession(): SessionData
	{
		return this._appointmentSession;
	}

	get requiredFields(): PatientProfileField[]
	{
		return this._requiredFields;
	}

	get invalidFields(): PatientProfileField[]
	{
		return this._invalidFields;
	}

	get warningFields(): PatientProfileField[]
	{
		return this._warningFields;
	}

	get onTimeoutCb(): () => void
	{
		return this._onTimeoutCb;
	}

	get kioskLocation(): string
	{
		return this._kioskLocation;
	}

	get kioskClinicId(): string
	{
		return this._kioskClinics.find((clinic) => clinic.location === this._kioskLocation).clinic_id;
	}

	get didNetworkDisconnect(): boolean
	{
		return this._didNetworkDisconnect;
	}
}

export default getModule(KioskStore);
