

	import {Options, Vue, Prop} from "vue-property-decorator";
	import {IMAGE_BRANDED, IMAGE_BRANDED_LIGHT} from "@/assets/AppIcons";

	@Options({})
	export default class HeaderLogo extends Vue
	{
		@Prop() readonly center: boolean;
		@Prop(String) readonly src: string;
		@Prop(String) readonly alt: string;
		@Prop({type: Boolean, default: false}) fullWidth: boolean;
		@Prop({type: Boolean, default: false}) logoLight: boolean;

		brandLogo: StringConstructor = IMAGE_BRANDED;

		public created(): void
		{
			if (this.logoLight)
			{
				this.brandLogo = IMAGE_BRANDED_LIGHT;
			}
			else
			{
				this.brandLogo = IMAGE_BRANDED;
			}
		}

		get logoSrc(): string | StringConstructor
		{
			return this.src ? this.src : this.brandLogo;
		}

		get logoAlt(): string
		{
			if (this.alt)
			{
				return this.alt;
			}
			else
			{
				return this.$isCloudMd ? "CloudMD Logo" : "MyHealthAccess Logo";
			}
		}
	}
