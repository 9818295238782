import {OrganizationRoute} from "@/router/public/org.routes";
import {PublicRoute} from "@/router/public/public.routes";
import {PublicAppointmentRoutes} from "@/router/public/appointment.routes";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import {OrgPublicRoute} from "@/router/public/org.public.routes";

export class PublicRouteCollection
{
	// ============= Public =================
	// Access should be refactored to publicRouteCollection.public.xxxx
	get TermsConditions(): typeof PublicRoute.TermsConditions | typeof OrgPublicRoute.TermsConditions
	{
		if (OrganizationStore.isWhiteLabel)
		{
			return OrgPublicRoute.TermsConditions;
		}
		else
		{
			return PublicRoute.TermsConditions;
		}
	}

	get PrivacyPolicy(): typeof PublicRoute.PrivacyPolicy | typeof OrgPublicRoute.PrivacyPolicy
	{
		if (OrganizationStore.isWhiteLabel)
		{
			return OrgPublicRoute.PrivacyPolicy;
		}
		else
		{
			return PublicRoute.PrivacyPolicy;
		}
	}

	get CookiesNotEnabled(): typeof PublicRoute.CookiesNotEnabled | typeof OrgPublicRoute.CookiesNotEnabled
	{
		if (OrganizationStore.isWhiteLabel)
		{
			return OrgPublicRoute.CookiesNotEnabled;
		}
		else
		{
			return PublicRoute.CookiesNotEnabled;
		}
	}

	get ErrorPage(): typeof PublicRoute.ErrorPage | typeof OrgPublicRoute.ErrorPage
	{
		if (OrganizationStore.isWhiteLabel)
		{
			return OrgPublicRoute.ErrorPage;
		}
		else
		{
			return PublicRoute.ErrorPage;
		}
	}

	// ============= Public Appointment=================
	// Access should be refactored to publicRouteCollection.publicAppointment.xxxx
	get OneTimeTelehealth(): typeof PublicAppointmentRoutes.OneTimeTelehealth
	{
		return PublicAppointmentRoutes.OneTimeTelehealth;
	}

	get Confirm(): typeof PublicAppointmentRoutes.Confirm
	{
		return PublicAppointmentRoutes.Confirm;
	}

	get Organization(): typeof OrganizationRoute
	{
		return OrganizationRoute;
	}
}
