import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createBlock(_component_BaseInput, _mergeProps({
    "padding-left": "",
    "icon-left": "icon-lock",
    label: $props.label,
    disabled: $props.disabled,
    type: "password",
    autocomplete: "off"
  }, _ctx.$attrs), null, 16, ["label", "disabled"]))
}