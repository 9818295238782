
	import WaitingRoomOverlay from "@/views/patient_user/telehealth/components/WaitingRoomOverlay.vue";
	import {Prop, Options} from "vue-property-decorator";
	import OnDemandBookingWaitingRoom from "@/views/patient_user/odb/telehealth/OnDemandBookingWaitingRoom.vue";
	import OnDemandExamRoom from "@/views/patient_user/odb/telehealth/OnDemandExamRoom.vue";

	@Options({components: {OnDemandBookingWaitingRoom, OnDemandExamRoom}})
	export default class OnDemandBookingTelehealthWaitingRoomOverlay extends WaitingRoomOverlay
	{
		public inExamRoom = false;

		// ==========================================================================
		// Public Methods
		// ==========================================================================

		public onExit()
		{
			this.$emit("exit");
		}
	}
