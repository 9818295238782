

	import {Options, Vue, Prop} from "vue-property-decorator";

	import SubheaderWithColour from "@/components/Header/SubheaderWithColour.vue";
	import {Patient} from "@/lib/patient/Patient";

	@Options({components: {SubheaderWithColour}})
	export default class DependentSubheader extends Vue
	{
		@Prop() dependent: Patient;
		@Prop() colorClass: string;
		@Prop() headerClass: string;
		@Prop() text: string;
		@Prop({type: String})
		textPrefix: string;

		// ==========================================================================
		// Getters
		// ==========================================================================

		get headerProps(): any
		{
			const textPrefix = this.textPrefix ? this.textPrefix : this.$mhat("DependentSubheader.DefaultTextPrefix");
			return {
				colorClass: this.colorClass,
				headerClass: this.headerClass,
				headerText: `${textPrefix} ${this.dependent ? this.dependent.fullName : this.$mhat("DependentSubheader.UnknownDependent")}`,
				textPrefix: textPrefix,
			};
		}
	}
