import {CrossFrameMessage} from "@/lib/integration/iframe/model/CrossFrameMessage";

export default class CrossFrameCommunicationService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * post a cross frame message asynchronously
	 * @param message - the message to send
	 * @param target - the window to send the message to. Defaults to the parent window
	 */
	public sendAsyncMessage(message: CrossFrameMessage, target: Window = window.parent): void
	{
		target.postMessage(message, "*");
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get hasParentFrame(): boolean
	{
		return window.parent.location !== window.location;
	}
}
