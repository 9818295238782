import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalHeader = _resolveComponent("ModalHeader")!
  const _component_ClinicOperatingInfo = _resolveComponent("ClinicOperatingInfo")!
  const _component_page_wrapper = _resolveComponent("page-wrapper")!

  return (_openBlock(), _createBlock(_component_page_wrapper, null, {
    "content-header": _withCtx(() => [
      _createVNode(_component_ModalHeader, {
        "header-text": this.$mhat('OnDemandBookingHoursModal.HeaderText')
      }, null, 8, ["header-text"])
    ]),
    "content-top": _withCtx(() => [
      _createVNode(_component_ClinicOperatingInfo, {
        "operating-hours-list": _ctx.operatingHoursList,
        "show-header": false
      }, null, 8, ["operating-hours-list"])
    ]),
    _: 1
  }))
}