
export enum AppDownloadGraphicType
{
	ORG = "organization",
	BRANDED = "branded",
}

export enum AppDownloadLayout
{
	STANDARD = "standard-layout",
	REVERSED = "reversed-layout",
}

export enum SIGNUP_STATUS
{
	ERROR = -1,
	NOT_SUBMITTED,
	PENDING,
	SUCCESS,
	SUCCESS_NO_AUTOCONNECT,
}
