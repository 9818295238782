
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import AuthStore from "@/lib/vuex/auth.store";
	import {bookingForward, dynamicBookingRoute, getBookingClinicService} from "@/views/patient_user/booking/BookingRoutingHelper";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import DependentStore from "@/lib/vuex/modules/Dependent.store";
	import {Patient} from "@/lib/patient/Patient";
	import {ClinicSearchType} from "@/components/EventState/types";
	import {ButtonColor, ButtonColorPattern} from "@/components/Buttons/types";
	import {Route} from "@/router/router";
	import DeviceInfo from "@/lib/DeviceInfo";
	import {ClinicProfile} from "@/lib/clinic/clinicProfile.model";

	export default {
		name: "BookingHome",

		data()
		{
			return {
				dependentId: null,
				isLoading: true,
				ClinicSearchType,
				ButtonColor,
				ButtonColorPattern,
				Route,
			};
		},

		created()
		{
			BookingControllerStore.setBookingController(null);
			this.isLoading = false;
		},

		async beforeRouteEnter(to, from, next)
		{
			if (!DependentStore.hasDependents)
			{
				if (DeviceInfo.isCloudMd)
				{
					// if CloudMd w/ no dependents skip past provider and clinic selection.
					const cloudMdClinic: ClinicProfile =
						await getBookingClinicService().getCloudMdClinic();
					return bookingForward(Route.Booking.AppointmentType,
						{
							clinicId: cloudMdClinic.id,
							providerOrGroupId: cloudMdClinic.primaryProvider?.id,
						},
						next);
				}
				else
				{
					// if no dependents skip patient selection
					return bookingForward(Route.Booking.Clinic, {}, next);
				}
			}

			return next();
		},

		methods: {
			async routeClinic(dependentId?: string)
			{
				this.isLoading = true;
				this.dependentId = dependentId;
				await this.$mhaRouterPush(
					{
						name: dynamicBookingRoute(dependentId).Clinic,
						params: {dependentId},
					});
			},
		},

		computed: {
			loggedInUser()
			{
				return AuthStore.loggedInUser;
			},

			dependents(): Patient[]
			{
				return DependentStore.dependents;
			},

			shouldShowSpinner()
			{
				return this.isLoading;
			},
		},

		components: {
			BookingHeaderBar,
		},
	};
