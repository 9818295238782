
	export default {
		name: "Dropdown",

		props: {
			active: Boolean,
			position: Object,
			dropDownButton: HTMLElement,
		},

		mounted()
		{
			document.onclick = this.checkClick.bind(this);
		},

		methods: {
			checkClick(ev: MouseEvent)
			{
				// @ts-ignore
				if (!ev.path.includes(this.dropDownButton))
				{
					this.$emit("close");
				}
			},
		},
	};
