import {AqsAppointmentDto, ErrorCode} from "@/open_api/generated";
import {AqsPatientAPI} from "@/lib/services/Api";
import {QueuedAppointmentType} from "@/lib/appointment/aqs/model/QueuedAppointmentType";
import {ErrorResponse} from "@/lib/models/Errors/ErrorResponse";

export default class QueuedAppointmentService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * get queued appointment
	 * @param clinicId - the clinic the queued appointment is booked in
	 * @param queueId - the queue the appointment is booked in
	 * @param appointmentId - the id of the queued appointment
	 */
	public async getQueuedAppointment(clinicId: string, queueId: string, appointmentId: string): Promise<AqsAppointmentDto>
	{
		return (await AqsPatientAPI().getQueuedAppointment(clinicId, queueId, appointmentId)).data;
	}

	/**
	 * book a new AQS appointment.
	 * @param clinicId - the clinic to book the appointment in to.
	 * @param queueId - the AQS queue to book the appointment in to.
	 * @param type - [default Video] the queued appointment type to book.
	 * @param reason - [default null] the reason for the appointment.
	 * @param notes - [default null] any notes about the appointment.
	 * @param force - [default false] if true an appointment even in the case of duplicate booking.
	 * if a duplicate booking occurs the old appointment will be removed and a new appointment added.
	 */
	public async bookQueuedAppointment(
		clinicId: string,
		queueId: string,
		type: QueuedAppointmentType = QueuedAppointmentType.Video,
		reason: string = null,
		notes: string = null,
		force = false): Promise<AqsAppointmentDto>
	{
		try
		{
			return (await AqsPatientAPI().createAppointment(clinicId, queueId, {reason, notes, communication_type: type})).data;
		}
		catch (error)
		{
			if (error instanceof ErrorResponse)
			{
				if (error.code === ErrorCode.AqsDuplicateBooking && force)
				{
					await this.dequeueAllAppointments(clinicId, queueId);
					return await this.bookQueuedAppointment(clinicId, queueId, type, reason, notes, false);
				}
				else
				{
					throw error;
				}
			}
			else
			{
				throw error;
			}
		}
	}

	/**
	 * remove an appointment form the Queue
	 * @param clinicId - the clinic who's queue is to be edited
	 * @param queueId - the queue to remove the appointment from
	 * @param appointmentId - the appointment to remove
	 */
	public async dequeueAppointment(clinicId: string, queueId: string, appointmentId: string): Promise<void>
	{
		await AqsPatientAPI().dequeueQueuedAppointment(clinicId, queueId, appointmentId);
	}

	/**
	 * remove all appointments booked by the logged in patient from the queue
	 * @param clinicId - the clinic who's queue is being changed
	 * @param queueId - the queue to update
	 */
	public async dequeueAllAppointments(clinicId: string, queueId: string): Promise<void>
	{
		const existingAppointments: AqsAppointmentDto[] =
			(await AqsPatientAPI().getQueuedAppointments(clinicId, queueId)).data;

		// clear existing appointments
		for (const appointment of existingAppointments)
		{
			await this.dequeueAppointment(clinicId, queueId, appointment.id);
		}
	}
}
