import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loader-container", {small: $props.small}])
  }, [
    _createVNode(_component_Loader, {
      loading: "",
      class: _normalizeClass({loading: $props.loading})
    }, null, 8, ["class"]),
    _createVNode(_component_BaseButton, _mergeProps({ small: $props.small }, _ctx.$attrs, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
      class: {loading: $props.loading}
    }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 16, ["small", "class"])
  ], 2))
}