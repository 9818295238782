

	import {Options, Vue, Prop} from "vue-property-decorator";

	// SVG filter that can be applied to a div. It applies lightening and sharpening operations
	@Options({})
	export default class VideoFilter extends Vue
	{
		@Prop({type: Number, default: 0.25}) public sharpeningStrength;

		// sharpening convolution
		get sharpeningKernel(): string
		{
			const pVal = this.sharpeningStrength * 12 + 1;
			const nVal = -1 * this.sharpeningStrength;
			return `
			0 0 ${nVal} 0 0
			0 ${nVal} ${nVal} ${nVal} 0
			${nVal} ${nVal} ${pVal} ${nVal} ${nVal}
			0 ${nVal} ${nVal} ${nVal} 0
			0 0 ${nVal} 0 0
			`;
		}

		// convert to black and white
		get bwColorKernel(): string
		{
			return `
				0.333 0.333 0.333 0 0
				0.333 0.333 0.333 0 0
				0.333 0.333 0.333 0 0
				0     0     0     1 0
			`;
		}

		// invert color and crush to black, creating luminance mask
		get colorInvertKernel(): string
		{
			return `
			-0.8 0 0 0 0.1
			0 -0.8 0 0 0.1
			0 0 -0.8 0 0.1
			0 0 0 1 0`;
		}
	}
