import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8da211cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "selected-content"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "no-select"
}
const _hoisted_4 = { class: "scroll-overflow" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "icon-check"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_input_layout = _resolveComponent("input-layout")!

  return (_openBlock(), _createBlock(_component_input_layout, {
    label: _ctx.label,
    "for-input-id": _ctx.label,
    "hide-label": _ctx.hideLabel,
    "label-style": {'z-index': _ctx.zIndex},
    disabled: _ctx.disabled,
    required: _ctx.required,
    "is-valid": _ctx.isValid,
    "hide-validation-message": _ctx.hideValidationMessage,
    compact: _ctx.compact,
    pristine: _ctx.isPristine,
    "no-style": _ctx.nostyle,
    onClick: _withModifiers(_ctx.onClick, ["stop"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["select-menu", {disabled: _ctx.disabled, required: _ctx.required, 'valid': _ctx.isValid, 'pristine': _ctx.isPristine}]),
        ref: "selectMenu"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["options", {active: _ctx.active, disabled: _ctx.disabled, required: _ctx.required, 'invalid': !_ctx.isValid, 'valid': _ctx.isValid, 'input-select': _ctx.inputSelect, nostyle: _ctx.nostyle, 'pristine': _ctx.isPristine, 'drop-up': _ctx.dropUp}])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["option selected", {disabled: _ctx.disabled}]),
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["stop"]))
          }, [
            (_ctx.inputSelect)
              ? (_openBlock(), _createBlock(_component_BaseInput, {
                  key: 0,
                  "hide-label": "",
                  autocomplete: "off",
                  "icon-left": _ctx.inputSelect.icon,
                  placeholder: _ctx.inputSelect.placeholder,
                  value: _ctx.inputValue,
                  required: _ctx.required,
                  valid: _ctx.isValid,
                  nostyle: _ctx.nostyle,
                  onInput: _cache[0] || (_cache[0] = (payload) => _ctx.$emit('input', payload)),
                  onMounted: _ctx.setInputEl
                }, null, 8, ["icon-left", "placeholder", "value", "required", "valid", "nostyle", "onMounted"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_ctx.selected)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.selected.name), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.placeholder), 1)),
                  (!_ctx.disabled)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 2,
                        class: _normalizeClass(["icon-chevron-down", {rotate: _ctx.active}])
                      }, null, 2))
                    : _createCommentVNode("", true)
                ]))
          ], 2)
        ], 2),
        (_ctx.active && !_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["dropdown-container", {'drop-up': _ctx.dropUp}])
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(["options-list", {active: _ctx.active, 'input-select': _ctx.inputSelect, nostyle: _ctx.nostyle, required: _ctx.required, 'invalid': !_ctx.isValid, 'pristine': _ctx.isPristine}]),
                  style: _normalizeStyle({'max-height': this.maxHeight}),
                  ref: "optionsContainer"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "option",
                      style: _normalizeStyle({height: _ctx.optionHeight}),
                      onClick: _withModifiers(() => _ctx.optionSelected(option), ["stop"])
                    }, [
                      _createElementVNode("span", null, [
                        (option.icon)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: _normalizeClass(option.icon)
                            }, null, 2))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(option.name), 1)
                      ]),
                      (_ctx.checks)
                        ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                        : _createCommentVNode("", true)
                    ], 12, _hoisted_5))
                  }), 256))
                ], 6)
              ])
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["label", "for-input-id", "hide-label", "label-style", "disabled", "required", "is-valid", "hide-validation-message", "compact", "pristine", "no-style", "onClick"]))
}