import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.active)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "dropdown",
        style: _normalizeStyle($props.position)
      }, [
        _renderSlot(_ctx.$slots, "dropItems", {}, undefined, true)
      ], 4))
    : _createCommentVNode("", true)
}