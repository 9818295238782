import AbstractConverter from "@/lib/conversion/AbstractConverter";
import {DocumentTransfer} from "@/open_api/generated";
import MhaDocument from "@/lib/document/model/MhaDocument";
import moment from "moment";

export default class DocumentTransferToMhaDocumentConverter extends AbstractConverter<DocumentTransfer, MhaDocument>
{
	// ==========================================================================
	// AbstractConverter Implementation
	// ==========================================================================

	convert(from: DocumentTransfer): MhaDocument
	{
		return new MhaDocument(from.id,
		                       from.name,
		                       from.type,
		                       from.description,
		                       moment(from.created_at),
		                       moment(from.updated_at),
		                       from.url);
	}
}
