import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "booking-content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mha text italic-font small separator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookingHeaderBar = _resolveComponent("BookingHeaderBar")!
  const _component_DependentSubheader = _resolveComponent("DependentSubheader")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_EventStateSpinner = _resolveComponent("EventStateSpinner")!
  const _component_ContactClinicRouteButton = _resolveComponent("ContactClinicRouteButton")!
  const _component_RouteButton = _resolveComponent("RouteButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_BookingHeaderBar, {
        "header-text": this.$mhat('BookingProvider.HeaderText'),
        noBack: !$props.hasBackButtons,
        hasClose: $props.hasClose
      }, null, 8, ["header-text", "noBack", "hasClose"])
    ]),
    _: 2
  }, [
    (!$options.showSpinner)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            ($options.isDependentMode)
              ? (_openBlock(), _createBlock(_component_DependentSubheader, {
                  key: 0,
                  dependent: $options.controller.dependent
                }, null, 8, ["dependent"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", null, _toDisplayString(this.$mhat("BookingProvider.WhichProviderText")), 1),
              ($props.providerGroupId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_BaseButton, {
                      onClick: _cache[0] || (_cache[0] = () => $options.seeNextAvailable())
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(this.$mhat("BookingProvider.SeeNextAvailableText")), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_3, _toDisplayString(this.$mhat("BookingProvider.Or")), 1)
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.providers, (provider) => {
                return (_openBlock(), _createBlock(_component_BaseButton, {
                  key: provider.id,
                  onClick: () => $options.setSelected(provider)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(provider.fullNameWithPrefixSuffix()), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ])
          ])
        }
      : {
          name: "content-center",
          fn: _withCtx(() => [
            _createVNode(_component_EventStateSpinner, {
              "is-loading": $data.isLoading,
              "loading-icon": "icon-user-md",
              "loading-title": $data.loadingMessage,
              "show-failure-state": $options.noProviders,
              "failure-icon": "icon-user-md",
              "failure-title": this.$mhat('BookingProvider.LoadingFailureTitle'),
              "failure-message": $options.failureMessage
            }, null, 8, ["is-loading", "loading-title", "show-failure-state", "failure-title", "failure-message"]),
            ($options.noProviders)
              ? (_openBlock(), _createElementBlock("div", { key: 0 }, [
                  _createVNode(_component_ContactClinicRouteButton, { clinicId: $props.clinicId }, null, 8, ["clinicId"]),
                  _createVNode(_component_RouteButton, {
                    pattern: $data.ButtonColorPattern.TRANSPARENT,
                    color: $data.ButtonColor.PRIMARY,
                    route: _ctx.Route.Home
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(this.$mhat("BookingProvider.CloseWindow")), 1)
                    ]),
                    _: 1
                  }, 8, ["pattern", "color", "route"])
                ]))
              : _createCommentVNode("", true)
          ])
        }
  ]), 1024))
}