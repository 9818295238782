import {RouteAuthType} from "@/router/types";
import {RouteLocation, RouteLocationRaw, RouteRecordRaw} from "vue-router";
import OrganizationStore from "@/lib/vuex/modules/OrganizationStore";
import OrganizationService from "@/lib/organization/service/OrganizationService";
import OrgBookingRedirect from "@/views/patient_user/booking/OrgBookingRedirect.vue";
import OrgProviderGroupBookingRedirect from "@/views/patient_user/booking/OrgProviderGroupBookingRedirect.vue";
import OrgRescheduleBookingRedirect from "@/views/patient_user/booking/OrgRescheduleBookingRedirect.vue";

const BookingHome = () => import(/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingHome.vue");
const BookingClinic = () => import(/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingClinic.vue");
const BookingProvider = () => import(/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingProvider.vue");
const BookingProviderGroup = () => import(/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingProviderGroup.vue");
const BookingBrandedRedirect = () => import(/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingBrandedRedirect.vue");
const BookingAppointmentType = () => import(
	/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingAppointmentType.vue");
const BookingDisclaimer = () => import(/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingDisclaimer.vue");
const BookingNextAvailability = () => import(
	/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingNextAvailability.vue");
const BookingCalendar = () => import(/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingCalendar.vue");
const BookingAppointmentTime = () => import(
	/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingAppointmentTime.vue");
const BookingReason = () => import(/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingReason.vue");
const BookingConfirm = () => import(
	/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingConfirm.vue");
const BookingFinal = () => import(/* webpackChunkName: "Booking" */ "@/views/patient_user/booking/BookingFinal.vue");

export interface BookingParams
{
	appointmentMethod?: string;
	dependentId?: string;
	clinicId?: string;
	providerId?: string;
	appointmentTypeId?: string;
	appointmentDate?: string;
	appointmentId?: string;
}

export enum BookingRoute
{
	BrandedRedirect = "BookingBrandedRedirect",
	Home = "BookingHome",
	Clinic = "BookingClinic",
	ProviderGroup = "BookingProviderGroup",
	Provider = "BookingProvider",
	ProviderGroupProvider = "BookingProviderGroupProvider",
	AppointmentType = "BookingAppointmentType",
	Notes = "BookingNotes",
	NextAvailability = "BookingNextAvailability",
	Calendar = "BookingCalendar",
	AppointmentTime = "BookingAppointmentTime",
	Reason = "BookingAppointmentReason",
	Confirm = "BookingConfirm",
	BookAppointment = "BookingBookAppointment",
	OrganizationBookingRedirect = "OrganizationBookingRedirect",
	OrganizationProviderGroupBookingRedirect = "OrganizationProviderGroupBookingRedirect",
	OrganizationRescheduleBookingRedirect = "OrganizationRescheduleBookingRedirect",
}

export enum DependentBookingRoute
{
	Home = "DependentBookingHome",
	Clinic = "DependentBookingClinic",
	ProviderGroup = "DependentBookingProviderGroup",
	Provider = "DependentBookingProvider",
	ProviderGroupProvider = "DependentBookingProviderGroupProvider",
	AppointmentType = "DependentBookingAppointmentType",
	Notes = "DependentBookingNotes",
	NextAvailability = "DependentBookingNextAvailability",
	Calendar = "DependentBookingCalendar",
	AppointmentTime = "DependentBookingAppointmentTime",
	Reason = "DependentBookingAppointmentReason",
	Confirm = "DependentBookingConfirm",
	BookAppointment = "DependentBookingBookAppointment",
}

export const patientBookingRoutes: RouteRecordRaw[] = [
	{
		path: "/book/clinic/:clinicId/branded/redirect",
		name: BookingRoute.BrandedRedirect,
		component: BookingBrandedRedirect,
		props: true,
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book",
		name: BookingRoute.Home,
		component: BookingHome,
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic",
		name: BookingRoute.Clinic,
		component: BookingClinic,
		props: (route) =>
		{
			return Object.assign({hasBackButtons: !(route.query.has_back === "false")}, route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId",
		name: BookingRoute.Provider,
		component: BookingProvider,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/group",
		name: BookingRoute.ProviderGroup,
		component: BookingProviderGroup,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/group/:providerGroupId",
		name: BookingRoute.ProviderGroupProvider,
		component: BookingProvider,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/provider/:providerOrGroupId",
		name: BookingRoute.AppointmentType,
		component: BookingAppointmentType,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/provider/:providerOrGroupId/type/:appointmentTypeId/disclaimer",
		name: BookingRoute.Notes,
		component: BookingDisclaimer,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/provider/:providerOrGroupId/type/:appointmentTypeId/available",
		name: BookingRoute.NextAvailability,
		component: BookingNextAvailability,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/provider/:providerOrGroupId/type/:appointmentTypeId/calendar",
		name: BookingRoute.Calendar,
		component: BookingCalendar,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/provider/:providerOrGroupId/type/:appointmentTypeId/:appointmentDate",
		name: BookingRoute.AppointmentTime,
		component: BookingAppointmentTime,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/provider/:providerOrGroupId/type/:appointmentTypeId/" +
			":appointmentDate/:appointmentId/reason",
		name: BookingRoute.Reason,
		component: BookingReason,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/provider/:providerOrGroupId/type/:appointmentTypeId/" +
			":appointmentDate/:appointmentId/confirm",
		name: BookingRoute.Confirm,
		component: BookingConfirm,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/:clinicId/provider/:providerOrGroupId/type/:appointmentTypeId/" +
			":appointmentDate/:appointmentId/book",
		name: BookingRoute.BookAppointment,
		component: BookingFinal,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/clinic/org_booking_redirect",
		name: BookingRoute.OrganizationBookingRedirect,
		component: OrgBookingRedirect,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},

	{
		path: "/book/clinic/org_provider_group_booking_redirect/group/:providerGroupId",
		name: BookingRoute.OrganizationProviderGroupBookingRedirect,
		component: OrgProviderGroupBookingRedirect,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},

	{
		path: "/book/clinic/org_reschedule_booking_redirect/appointment/:rescheduleRemoteAppointmentId",
		name: BookingRoute.OrganizationRescheduleBookingRedirect,
		component: OrgRescheduleBookingRedirect,
		props: true,
		meta: {
			routeAuth: RouteAuthType.Patient,
			brandedRoute: true,
		},
	},
];

export const dependentBookingRoutes: RouteRecordRaw[] = [
	{
		path: "/book/dependent/:dependentId/clinic",
		name: DependentBookingRoute.Clinic,
		component: BookingClinic,
		props: true,
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId",
		name: DependentBookingRoute.Provider,
		component: BookingProvider,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/group",
		name: DependentBookingRoute.ProviderGroup,
		component: BookingProviderGroup,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/group/:providerGroupId",
		name: DependentBookingRoute.ProviderGroupProvider,
		component: BookingProvider,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/provider/:providerOrGroupId",
		name: DependentBookingRoute.AppointmentType,
		component: BookingAppointmentType,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/provider/:providerOrGroupId/" +
			"type/:appointmentTypeId/disclaimer",
		name: DependentBookingRoute.Notes,
		component: BookingDisclaimer,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/provider/:providerOrGroupId/" +
			"type/:appointmentTypeId/available",
		name: DependentBookingRoute.NextAvailability,
		component: BookingNextAvailability,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/provider/:providerOrGroupId/" +
			"type/:appointmentTypeId/calendar",
		name: DependentBookingRoute.Calendar,
		component: BookingCalendar,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/provider/:providerOrGroupId/" +
			"type/:appointmentTypeId/:appointmentDate",
		name: DependentBookingRoute.AppointmentTime,
		component: BookingAppointmentTime,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/provider/:providerOrGroupId/" +
			"type/:appointmentTypeId/:appointmentDate/:appointmentId/reason",
		name: DependentBookingRoute.Reason,
		component: BookingReason,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/provider/:providerOrGroupId/" +
			"type/:appointmentTypeId/:appointmentDate/:appointmentId/confirm",
		name: DependentBookingRoute.Confirm,
		component: BookingConfirm,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
	{
		path: "/book/dependent/:dependentId/clinic/:clinicId/provider/:providerOrGroupId/" +
			"type/:appointmentTypeId/:appointmentDate/:appointmentId/book",
		name: DependentBookingRoute.BookAppointment,
		component: BookingFinal,
		props: (route) =>
		{
			return Object.assign(
				{
					hasClose: !(route.query.hasClose === "false"),
					hasBackButtons: !(route.query.has_back === "false"),
					rescheduleRemoteAppointmentId: route.query.rescheduleRemoteAppointmentId,
				},
				route.params);
		},
		meta: {
			brandedRoute: true,
		},
	},
];

patientBookingRoutes.map((route) =>
{
	if (!route.meta)
	{
		route.meta = {};
	}

	Object.assign(route.meta,
	 {
			routeAuth: RouteAuthType.Patient,
	 });
});

dependentBookingRoutes.map((route) =>
{
	if (!route.meta)
	{
		route.meta = {};
	}

	Object.assign(route.meta,
		{
			routeAuth: RouteAuthType.Patient,
		});
});

export const bookingRoutes: RouteRecordRaw[] = [
	...patientBookingRoutes,
	...dependentBookingRoutes,
];
