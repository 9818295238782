// @ts-nocheck

import {Connection, Event} from "@opentok/client";
import LogEvent from "@/lib/telehealth/models/LogEvent";
import {LogLevel} from "@/lib/telehealth/models/LogLevel";
import CallbackCollection from "@/lib/utils/CallbackCollection";
import SessionClient from "@/lib/telehealth/opentok/SessionClient";
import {OT_CUSTOM_SIGNAL, OT_EVENT_TYPE, OT_EVENT_TYPES} from "@/lib/telehealth/opentok/ot.types";

export default class EventLogger
{
	protected static readonly EVENT_BLACKLIST: string[] = [OT_CUSTOM_SIGNAL.CHAT_TYPING];

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * log an inbound signal event
	 * @param signalType - the type of the signal
	 * @param event - the opentok event object
	 * @param selfClient - the session client of self
	 * @param notifyCallbacks - callbacks to notify of the event
	 */
	public static logInboundSignal(
		signalType: string,
		event: Event<string, any>,
		selfClient: SessionClient,
		notifyCallbacks: CallbackCollection): void
	{
		if (this.EVENT_BLACKLIST.includes(signalType))
		{ // abort if signal is blacklisted
			return;
		}

		// figure out the from user
		const fromUserId = this.getUserIdFromToken(this.getTokenDataFromEvent(event));
		const fromUserType = this.getUserTypeFromToken(this.getTokenDataFromEvent(event));

		if (fromUserId && fromUserId !== selfClient.userId)
		{ // incoming message
			const logEvent = new LogEvent(
				signalType,
				LogLevel.INFO,
				event.data || null,
				fromUserId,
				fromUserType,
				selfClient.userId,
				selfClient.userType);

			notifyCallbacks.call(logEvent);
		}
		else if (OT_EVENT_TYPES.includes(signalType))
		{ // self OT message. i.e. outgoing
			this.logOutboundSignal(
				signalType,
				event.data || null,
				null,
				selfClient,
				notifyCallbacks);
		}
	}

	public static logOutboundSignal(
		signalType: string,
		message: string,
		toConnection: Connection,
		selfClient: SessionClient,
		notifyCallbacks: CallbackCollection,
	)
	{
		if (this.EVENT_BLACKLIST.includes(signalType))
		{ // abort if signal is blacklisted
			return;
		}

		const logEvent = new LogEvent(
			signalType,
			LogLevel.INFO,
			message,
			selfClient.userId,
			selfClient.userType,
			EventLogger.getUserIdFromToken(toConnection?.data),
			EventLogger.getUserTypeFromToken(toConnection?.data),
		);

		notifyCallbacks.call(logEvent);
	}

	// ==========================================================================
	// Protected Methods
	// ==========================================================================

	/**
	 * get user id from a session token
	 * @param sessionToken - the token to get the user id from
	 * @return the user id or null
	 */
	protected static getUserIdFromToken(sessionToken: string)
	{
		try
		{
			const data = JSON.parse(sessionToken);
			if (data.id)
			{
				return data.id;
			}
			return null;
		}
		catch (error)
		{
			return null;
		}
	}

	/**
	 * get user type from a session token
	 * @param sessionToken - the token to get the user type from
	 * @return the user type or null
	 */
	protected static getUserTypeFromToken(sessionToken: string)
	{
		try
		{
			const data = JSON.parse(sessionToken);
			if (data.user_type)
			{
				return data.user_type;
			}
			return null;
		}
		catch (error)
		{
			return null;
		}
	}

	protected static getTokenDataFromEvent(event: Event<string, any>): string
	{
		if (event.from)
		{
			return event.from.data;
		}
		else if (event.connection)
		{
			return event.connection.data;
		}
		else if (event.stream?.connection)
		{
			return event.stream.connection.data;
		}
		else
		{
			return null;
		}
	}
}
