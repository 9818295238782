
	export default {
		name: "ClinicsHeader",

		props: {
			headerText: {
				type: String,
				required: false,
			},
			overlay: Boolean,
			paddingHorizontal: Boolean,
		},

		data()
		{
			return {
				headerProps: {
					icon: "icon-clinic",
					headerText: this.headerText ? this.headerText : this.$mhat("ClinicsHeader.HeaderText"),
					overlay: this.overlay,
					paddingHorizontal: this.paddingHorizontal,
				},
			};
		},
	};
