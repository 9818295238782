
export default class AppointmentCancelResponse
{
	private readonly _remoteAppointmentId: string;
	private readonly _success: boolean;

	public constructor(remoteAppointmentId: string, success: boolean)
	{
		this._remoteAppointmentId = remoteAppointmentId;
		this._success = success;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get remoteAppointmentId(): string
	{
		return this._remoteAppointmentId;
	}

	get success(): boolean
	{
		return this._success;
	}
}
