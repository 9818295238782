import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mha text separator"
}
const _hoisted_2 = {
  key: 1,
  class: "mha text separator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicsHeader = _resolveComponent("ClinicsHeader")!
  const _component_ClinicCard = _resolveComponent("ClinicCard")!
  const _component_CardFeed = _resolveComponent("CardFeed")!
  const _component_ClinicSearchSpinner = _resolveComponent("ClinicSearchSpinner")!
  const _component_FloatingActionButton = _resolveComponent("FloatingActionButton")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, null, _createSlots({
    "content-header": _withCtx(() => [
      _createVNode(_component_ClinicsHeader)
    ]),
    "fixed-footer": _withCtx(() => [
      _createVNode(_component_FloatingActionButton, {
        icon: "icon-search",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$mhaRouterPush({name: _ctx.Route.Clinics.FindClinic})))
      })
    ]),
    _: 2
  }, [
    (!_ctx.shouldShowSpinner)
      ? {
          name: "content-top",
          fn: _withCtx(() => [
            _createVNode(_component_CardFeed, null, {
              feed: _withCtx(() => [
                (_ctx.pendingClinics.length > 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(this.$mhat("ClinicsMobile.PendingClinics")), 1))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingClinics, (clinic) => {
                  return (_openBlock(), _createBlock(_component_ClinicCard, {
                    key: clinic.id,
                    clinic: clinic,
                    active: clinic === _ctx.controller.selectedClinic,
                    onClick: ($event: any) => (_ctx.goToRoute(clinic)),
                    "has-notification": true,
                    clickable: "",
                    deleted: clinic.id === _ctx.deletedClinicId
                  }, null, 8, ["clinic", "active", "onClick", "deleted"]))
                }), 128)),
                (_ctx.activeClinics && _ctx.activeClinics.length > 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(this.$mhat("ClinicsMobile.YourClinics")), 1))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeClinics, (clinic) => {
                  return (_openBlock(), _createBlock(_component_ClinicCard, {
                    key: clinic.id,
                    clinic: clinic,
                    active: clinic === _ctx.controller.selectedClinic,
                    deleted: clinic.id === _ctx.deletedClinicId,
                    clickable: "",
                    onClick: ($event: any) => (_ctx.goToRoute(clinic))
                  }, null, 8, ["clinic", "active", "deleted", "onClick"]))
                }), 128))
              ]),
              empty: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(this.$mhat("ClinicsMobile.NoClinics")), 1)
              ]),
              _: 1
            })
          ])
        }
      : {
          name: "content-center",
          fn: _withCtx(() => [
            _createVNode(_component_ClinicSearchSpinner, {
              "search-type": _ctx.ClinicSearchType.CONNECTED,
              "is-loading": _ctx.controller.isLoading,
              "show-failure": !_ctx.controller.isLoading,
              "failure-title": _ctx.spinnerFailure.title,
              "failure-message": _ctx.spinnerFailure.message
            }, null, 8, ["search-type", "is-loading", "show-failure", "failure-title", "failure-message"])
          ])
        }
  ]), 1024))
}