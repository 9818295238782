
	export default {
		name: "CardFeed",

		props: {
			isEmpty: Boolean,
			fixed: Boolean,
		},

		methods: {
			onScroll()
			{
				const {feedScroll, feedContainer} = this.$refs;
				const heightOfBottom = feedContainer.clientHeight - feedScroll.clientHeight;

				if (feedScroll.scrollTop >= heightOfBottom)
				{
					this.$emit("scroll-bottom");
				}
			},
		},
	};
