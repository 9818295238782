import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5af829a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "clinic-about-info"
}
const _hoisted_2 = { class: "info-header" }
const _hoisted_3 = { class: "info-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.clinic)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h6", _hoisted_2, _toDisplayString(this.$mhat("ClinicAboutInfo.AboutUsHeader")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.clinic.about), 1)
      ]))
    : _createCommentVNode("", true)
}