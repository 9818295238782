

	import {Options, Vue, Prop} from "vue-property-decorator";

	@Options({})
	export default class MailHeader extends Vue
	{
		@Prop({type: String}) headerText: string;
		@Prop({type: String, default: "icon-chat"}) icon: string;
		@Prop({type: Boolean}) noBack: boolean;
		@Prop({type: Number}) notificationCount: number;
		@Prop({type: [String, Object, Boolean]}) hasClose: string | boolean | any;
		@Prop({type: Object}) buttonOpts: any;

		public inheritAttrs = false;

		get getHeaderText()
		{
			return this.headerText ? this.headerText : this.$mhat("MailHeader.HeaderText");
		}
	}
