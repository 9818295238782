import {ErrorCode, ErrorResponseInterface} from "@/open_api/generated";

export class ErrorResponse extends Error
{
	/* Instance Properties */

	private readonly _code: ErrorCode;
	private readonly _message: string;
	private readonly _data: any;

	constructor(errorResponse: ErrorResponseInterface)
	{
		super(errorResponse.message);
		this._code = errorResponse.code;
		this._message = errorResponse.message;
		this._data = errorResponse.data;
	}

	/* Instance Methods */

	public is(errorCode: ErrorCode): boolean
	{
		return this.code === errorCode;
	}

	/* Computed Properties */

	get isAuthError(): boolean
	{
		return this.code === ErrorCode.Authentication;
	}

	/* Getters */

	get code(): ErrorCode
	{
		return this._code;
	}

	get message(): string
	{
		return this._message;
	}

	get data(): any
	{
		return this._data;
	}
}
