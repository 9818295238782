

	import {Options, Vue, Prop} from "vue-property-decorator";
	import WhitelabelPrimaryLogo from "@/views/public/whitelabel/components/WhitelabelPrimaryLogo.vue";

	@Options({components: {WhitelabelPrimaryLogo}})
	export default class WhitelabelContentLayout extends Vue
	{
		@Prop({type: String}) title: string;
		@Prop({type: String}) text: string;
		@Prop({type: Boolean, default: false}) logoLight: boolean;
		@Prop({type: Boolean, default: false}) centerContent: boolean;

		get layoutRootClasses()
		{
			return {
				"align-center": this.centerContent,
			};
		}
	}
