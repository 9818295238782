
	import { Options, Vue } from "vue-property-decorator";
	import { Pharmacy } from "@/lib/kiosk/pharmacy.model";
	import KioskBranded from "@/views/kiosk_user/components/KioskBranded.vue";
	import KIOSK_DEFAULT_LOGO from "@/assets/images/kiosk/default_logo.svg";
	import {IonContent} from "@ionic/vue";

	@Options({
		components: {KioskBranded, IonContent},
	})
	export default class KioskWrapper extends Vue
	{
		private contentObserver: ResizeObserver = null;

		mounted()
		{
			this.$nextTick(() =>
			{
				this.setupContentObserver();
			});

			setTimeout(() =>
			{
				if (this.$refs.bodyContent)
				{
					// add transition after first second to prevent slow sizing on initial load.
					(this.$refs.bodyContent as HTMLElement).style.transition = "max-height 1.5s ease, min-height 1.5s ease";
				}
			}, 1000);
		}

		unmounted()
		{
			if (this.contentObserver)
			{
				this.contentObserver.disconnect();
			}
		}

		// watch body content for size changes
		public setupContentObserver()
		{
			if (this.$refs.bodyInnerContent)
			{
				this.contentObserver = new ResizeObserver(() =>
				{
					this.onContentChange();
				});

				this.contentObserver.observe(this.$refs.bodyInnerContent as HTMLElement);
			}
		}

		// fired when body content changes size
		public onContentChange()
		{
			// apply smooth content area resizing animation
			if (this.$refs.bodyInnerContent && this.$refs.bodyContent)
			{
				const contentHeight: number = (this.$refs.bodyInnerContent as HTMLElement).clientHeight;
				this.setBodyHeight(contentHeight);
			}
		}

		private setBodyHeight(height: number): void
		{
			(this.$refs.bodyContent as HTMLElement).style.maxHeight = height.toString() + "px";
			(this.$refs.bodyContent as HTMLElement).style.minHeight = height.toString() + "px";
		}

		get pharmacy(): Pharmacy
		{
			return this.AuthStore.loggedInKiosk?.pharmacy;
		}

		get pharmacyLogo(): {url: string, alt: string}
		{
			if (Boolean(this.pharmacy) && Boolean(this.pharmacy.logoUrl))
			{
				const {protocol, hostname} = location;
				return {
					url: this.pharmacy.logoUrl,
					alt: `${this.pharmacy.name} Logo`,
				};
			}
			else
			{
				return {
					url: KIOSK_DEFAULT_LOGO.toString(),
					alt: "CloudMD Virtual Clinic Logo",
				};
			}
		}
	}
