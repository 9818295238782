import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b156fec"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "feed-items",
  ref: "feedContainer"
}
const _hoisted_2 = {
  key: 1,
  class: "empty-feed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onScroll && $options.onScroll(...args))),
    ref: "feedScroll",
    class: "feed-view"
  }, [
    (!$props.isEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "feed", {}, undefined, true)
        ], 512))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "empty", {}, undefined, true)
        ])),
    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
  ], 544))
}