
export default class AppointmentBookResponse
{
	private readonly _status: string;

	public constructor(status: string)
	{
		this._status = status;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get status(): string
	{
		return this._status;
	}
}
