import TelehealthApiServiceInterface from "@/lib/telehealth/api/TelehealthApiServiceInterface";
import LogEvent from "@/lib/telehealth/models/LogEvent";
import {SessionData} from "@/lib/telehealth/models/sessionData.model";
import {TeleCallState} from "@/lib/telehealth/models/telehealth.types";
import {AqsPatientAPI, PatientAPI} from "@/lib/services/Api";
import {BrowserInfo} from "detect-browser";
import TelehealthClient from "@/lib/telehealth/models/TelehealthClient";
import AuthStore from "@/lib/vuex/auth.store";
import ChatMessage from "@/lib/telehealth/models/ChatMessage";
import ChatMessageToTelehealthChatMessageDtoConverter from "@/lib/telehealth/conversion/ChatMessageToTelehealthChatMessageDtoConverter";
import TelehealthChatMessageDtoToChatMessageConverter from "@/lib/telehealth/conversion/TelehealthChatMessageDtoToChatMessageConverter";
import {TelehealthChatMessageDto} from "@/open_api/generated";

export default class AqsTelehealthApiService implements TelehealthApiServiceInterface
{
	protected _clinicId: string;
	protected _queueId: string;
	protected _queuedAppointmentId: string;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * build aqs telehealth api
	 * @param clinicId - the clinic the telehealth appt is for
	 * @param queueId - the queue the AQS appointment is in
	 * @param queuedAppointmentId - the queued appointment to connect to.
	 */
	public constructor(clinicId: string, queueId: string, queuedAppointmentId: string)
	{
		this._clinicId = clinicId;
		this._queueId = queueId;
		this._queuedAppointmentId = queuedAppointmentId;
	}

	// ==========================================================================
	// TelehealthApiServiceInterface Implementation
	// ==========================================================================

	/**
	 * fetch telehealth session data.
	 */
	public async fetchSessionData(): Promise<SessionData>
	{
		return new SessionData((await AqsPatientAPI().getTelehealthSession(
			this._clinicId,
			this._queueId,
			this._queuedAppointmentId)).data);
	}

	/**
	 * log a telehealth event to the server.
	 * @param event - the event to log.
	 */
	public async logEvent(event: LogEvent): Promise<void>
	{
		return await AqsPatientAPI().logEventToPatientAqsTelehealthSession(
			this._clinicId,
			this._queueId,
			this._queuedAppointmentId,
			event.asTelehealthLogEventDto);
	}

	/**
	 * Ping the telehealth session. Providing the server with the current state of the telehealth call.
	 * @param callState - the current state of the call.
	 */
	public async pingSession(callState: TeleCallState): Promise<void>
	{
		return await AqsPatientAPI().pingAqsTelehealthSession(
			this._clinicId,
			this._queueId,
			this._queuedAppointmentId,
			{status: callState.toString()});
	}

	/**
	 * get information about the local telehealth client.
	 * @param browserInfo - information about the users browser
	 */
	public async getLocalClientData(browserInfo: BrowserInfo): Promise<TelehealthClient>
	{
		const user = AuthStore.loggedInUser;

		return new TelehealthClient(
			browserInfo,
			user.email,
			user.firstName,
			user.lastName,
			user.userType,
		);
	}

	/**
	 * record a chat message for this telehealth session.
	 * @param message - the message to record
	 * @return the newly recorded message
	 */
	public async recordChatMessage(message: ChatMessage): Promise<ChatMessage>
	{
		const chatMessageDto: TelehealthChatMessageDto = (await AqsPatientAPI().addPatientChatMessageToQueuedAppointment(
			this._clinicId,
			this._queueId,
			this._queuedAppointmentId,
			new ChatMessageToTelehealthChatMessageDtoConverter().convert(message))).data;

		return new TelehealthChatMessageDtoToChatMessageConverter().convert(chatMessageDto);
	}

	/**
	 * load chat history from DB.
	 * @return array of chat messages for the telehealth appointment.
	 */
	public async getChatHistory(): Promise<ChatMessage[]>
	{
		return new TelehealthChatMessageDtoToChatMessageConverter()
			.convertList((await AqsPatientAPI().getPatientQueuedAppointmentChatMessages(
				this._clinicId,
				this._queueId,
				this._queuedAppointmentId)).data);
	}
}
