import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c37df084"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "message-item clinic"
}
const _hoisted_3 = { class: "message-content-container" }
const _hoisted_4 = {
  key: 1,
  class: "message-item subject"
}
const _hoisted_5 = { class: "message-content-container" }
const _hoisted_6 = {
  key: 2,
  class: "message-item body"
}
const _hoisted_7 = { class: "message-content-container" }
const _hoisted_8 = { class: "message-content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasClinic)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", {
            class: _normalizeClass(["header", {compose: _ctx.compose}])
          }, _toDisplayString(_ctx.getClinicHeaderText), 3),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "clinic", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasSubject)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["header", {compose: _ctx.compose}])
          }, _toDisplayString(_ctx.getSubjectHeaderText), 3),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "subject", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasBody)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", {
            class: _normalizeClass(["header", {compose: _ctx.compose}])
          }, _toDisplayString(_ctx.getBodyHeaderText), 3),
          _createElementVNode("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "body", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["message-item attachments", {'hide-attachments' : !_ctx.hasAttachments}])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["header", {compose: _ctx.compose}])
      }, _toDisplayString(_ctx.getAttachmentsHeaderText), 3),
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "attachments", {}, undefined, true)
      ])
    ], 2)
  ]))
}