import {RouteAuthType} from "@/router/types";
import AccountIdCodePage from "@/views/patient_user/profile/AccountIdCodePage.vue";
import {RouteRecordRaw} from "vue-router";

const SecurityHome = () => import(/* webpackChunkName: "Profile" */ "@/views/patient_user/profile/SecurityHome.vue");
const RequiredProfileModal = () => import(
	/* webpackChunkName: "Profile" */ "@/views/patient_user/cloud_md/components/RequiredProfileModal.vue");
const SecurityChangeEmail = () => import(/* webpackChunkName: "Profile" */ "@/views/patient_user/profile/SecurityChangeEmail.vue");
const SecurityChangePassword = () =>
	import(/* webpackChunkName: "Profile" */ "@/views/patient_user/profile/SecurityChangePassword.vue");
const ProfileEdit = () => import(/* webpackChunkName: "Profile" */ "@/views/patient_user/profile/AccountHome.vue");
const PrivacyHome = () => import(/* webpackChunkName: "Profile" */ "@/views/patient_user/profile/PrivacyHome.vue");

export enum SettingsRoute
{
	Profile = "SettingsProfile",
	RequiredEdit = "SettingsProfileRequired",
	Security = "SettingsSecurity",
	SecurityEmail = "SettingsSecurityEmail",
	SecurityPassword = "SettingsSecurityPassword",
	Privacy = "SettingsPrivacy",
	AccountIdCode = "AccountIdCode",
}

export const settingsRoutes: RouteRecordRaw[] = [
	{
		path: "/account/profile",
		name: SettingsRoute.Profile,
		component: ProfileEdit,
		meta: {
			routeAuth: RouteAuthType.Patient,
		},
	},
	{
		path: "/profile/required:dependentId?",
		name: SettingsRoute.RequiredEdit,
		component: RequiredProfileModal,
		meta: {
			routeAuth: RouteAuthType.Patient,
		},
	},
	{
		path: "/account/security",
		name: SettingsRoute.Security,
		component: SecurityHome,
		meta: {
			routeAuth: RouteAuthType.Patient,
		},
	},
	{
		path: "/account/security/email",
		name: SettingsRoute.SecurityEmail,
		component: SecurityChangeEmail,
		meta: {
			routeAuth: RouteAuthType.Patient,
		},
	},
	{
		path: "/account/security/password",
		name: SettingsRoute.SecurityPassword,
		component: SecurityChangePassword,
		meta: {
			routeAuth: RouteAuthType.Patient,
		},
	},
	{
		path: "/account/privacy",
		name: SettingsRoute.Privacy,
		component: PrivacyHome,
		meta: {
			routeAuth: RouteAuthType.Patient,
		},
	},
	{
		path: "/account/id_code",
		name: SettingsRoute.AccountIdCode,
		component: AccountIdCodePage,
		meta: {
			routeAuth: RouteAuthType.Patient,
		},
	},
];
