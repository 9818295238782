import {BaseApiService} from "@/lib/services/BaseApiService";
import {ComposedMail, MailboxType} from "@/lib/Mailbox/Mailbox.types";
import {Mail} from "@/lib/Mailbox/Mail.model";
import {PatientAPI} from "@/lib/services/Api";
import Conversation from "@/lib/Mailbox/Conversation.model";

export class MailboxService extends BaseApiService
{
	constructor()
	{
		super();
	}

	public getPatientMail(mailboxType: MailboxType, offset: number, limit: number): Promise<Mail[]>
	{
		this.isLoading = true;

		return new Promise<Mail[]>((resolve, reject) =>
		{
			PatientAPI().patientMessages(mailboxType, offset, limit)
				.then((response) =>
				{
					const patientMail: Mail[] = response.data.map((mailTO) => new Mail(mailTO));
					resolve(patientMail);
				})
				.catch((error) =>
				{
					reject(error);
				})
				.finally(() =>
				{
					this.isLoading = false;
				});
		});
	}

	/**
	 * get conversation for patient.
	 * @param conversationId - the conversation id to get
	 * @return the conversation
	 */
	public async getConversation(conversationId): Promise<Conversation>
	{
		return new Conversation((await PatientAPI().getPatientConversation(conversationId)).data);
	}

	public archiveMail(messageId: string)
	{
		this.isLoading = true;

		return PatientAPI().archiveMessages(messageId)
			.finally(() =>
			{
				this.isLoading = false;
			});
	}

	public unarchiveMail(messageId: string)
	{
		this.isLoading = true;

		return PatientAPI().unArchiveMessages(messageId)
			.finally(() =>
			{
				this.isLoading = false;
			});
	}

	public sendMail(composedMail: ComposedMail)
	{
		this.isLoading = true;
		return PatientAPI().sendMessage(composedMail.clinic.id, {
			message: composedMail.message,
			conversation_id: composedMail.conversationId,
			attachments: composedMail.attachments,
		})
			.finally(() =>
			{
				this.isLoading = false;
			});
	}

	public readMail(mailboxType: MailboxType, messageId: string)
	{
		this.isLoading = true;

		return PatientAPI().setReadMail(mailboxType, {message_ids: [messageId]})
			.finally(() =>
			{
				this.isLoading = false;
			});
	}
}
