
	import BookingHeaderBar from "./components/BookingHeaderBar.vue";
	import DependentSubheader from "@/components/Header/DependentSubheader.vue";
	import BookingControllerStore from "@/lib/vuex/modules/BookingControllerStore";
	import DeviceInfo from "@/lib/DeviceInfo";
	import {isBlank} from "@/lib/utils/prototype/String";
	import {setBookingParams} from "@/views/patient_user/booking/BookingController";
	import {Route} from "@/router/router";
	import PatientAppointmentService from "@/lib/appointment/service/PatientAppointmentService";

	export default {
		name: "BookingReason",

		props: {
			dependentId: String,
			clinicId: String,
			providerOrGroupId: String,
			appointmentTypeId: String,
			appointmentDate: String,
			appointmentId: String,
			hasClose: {
				type: Boolean,
				required: false,
				default: true,
			},
			rescheduleRemoteAppointmentId: String,
		},

		data()
		{
			return {
				isMobile: DeviceInfo.isMobile(),
			};
		},

		created()
		{
			setBookingParams.call(this);

			if (!this.controller.selectedSlot)
			{
				this.$mhaRouterReplace(this.prevRoute);
			}
		},

		async mounted(): Promise<void>
		{
			if (this.rescheduleRemoteAppointmentId)
			{
				const patientAppointmentService = new PatientAppointmentService();
				const appointment = await patientAppointmentService.getRemoteAppointment(this.clinicId, this.rescheduleRemoteAppointmentId);
				this.controller.reason = appointment.reason;
			}
		},

		methods: {
			handleInput(input: string)
			{
				this.controller.reason = input;
			},

			addReason()
			{
				this.$mhaRouterPush(this.nextRoute);
			},

			back()
			{
				this.$mhaRouterPush(this.prevRoute);
			},
		},

		computed: {
			validReason()
			{
				const reason = this.controller.reason;
				return Boolean(reason) && reason.trim().length > 0;
			},

			controller()
			{
				return BookingControllerStore.bookingController;
			},

			nextRoute()
			{
				const {params} = this;
				const name = this.isDependentMode ? Route.Booking.Dependent.Confirm : Route.Booking.Confirm;
				return {name, params, query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId}};
			},

			prevRoute()
			{
				const {params} = this;
				const name = this.isDependentMode ? Route.Booking.Dependent.NextAvailability : Route.Booking.NextAvailability;
				return {name, params, query: {hasClose: this.hasClose, rescheduleRemoteAppointmentId: this.rescheduleRemoteAppointmentId}};
			},

			params()
			{
				const {dependentId, clinicId, providerOrGroupId, appointmentTypeId, appointmentDate, appointmentId} = this;
				return {dependentId, clinicId, providerOrGroupId, appointmentTypeId, appointmentDate, appointmentId};
			},

			isDependentMode()
			{
				return !isBlank(this.dependentId);
			},
		},

		components: {BookingHeaderBar, DependentSubheader},
	};
