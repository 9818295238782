import {ClinicAPI} from "@/lib/services/Api";
import {ChatItem} from "@/views/patient_user/telehealth/types";
import {ChatItemType, MessageOrigin} from "@/lib/telehealth/models/telehealth.types";
import DocumentService from "@/lib/document/service/DocumentService";

export default class EncounterNoteService
{
	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * write an encounter note to a patients chart
	 * @param patientId - the patient who's chart the note will be written to.
	 * @param note - the note to write
	 */
	public async writeEncounterNote(patientId: string, note): Promise<void>
	{
		await ClinicAPI().writeToEncounterNote(patientId, {note});
	}

	/**
	 * write chat items to the patients chart
	 * @param patientId - the patient who's chart the note will be written to.
	 * @param patientName - the name of the patient in the chat
	 * @param providerName - the name of the provider in the chat
	 * @param chatItems - chat items from which the note will be composed
	 */
	public async writeChatItemsToEncounterNote(patientId: string, patientName: string, providerName: string, chatItems: ChatItem[])
	{
		const documentService = new DocumentService();

		let chatHistory = "Chat History:";
		for (const item of chatItems)
		{
			const senderName: string = item.messageOrigin.toUpperCase() === MessageOrigin.Received ? patientName : providerName;

			let message = item.message;
			if (item.type === ChatItemType.DOCUMENT)
			{
				const document = await documentService.getDocument(item.message);
				message = `see ${document.name} in Docs`;
			}

			chatHistory += `\n${senderName}: ${message}`;
		}

		await this.writeEncounterNote(patientId, chatHistory);
	}
}
