import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ded47d02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "appointment-feed-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppointmentsHeader = _resolveComponent("AppointmentsHeader")!
  const _component_SelectMenu = _resolveComponent("SelectMenu")!
  const _component_AppointmentCard = _resolveComponent("AppointmentCard")!
  const _component_PageWrapper = _resolveComponent("PageWrapper")!

  return (_openBlock(), _createBlock(_component_PageWrapper, { contentHeaderRemoveFalseHeaderSpace: "" }, _createSlots({
    "content-top": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ((_ctx.dependents && _ctx.dependents.length > 0))
          ? (_openBlock(), _createBlock(_component_SelectMenu, {
              key: 0,
              id: "dependent-select",
              hideLabel: "",
              options: _ctx.selectUserOptions,
              selected: _ctx.selectedUser,
              onSelection: _ctx.dependentSelected
            }, null, 8, ["options", "selected", "onSelection"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upcomingAppointments, (appointment) => {
          return (_openBlock(), _createBlock(_component_AppointmentCard, {
            active: _ctx.isActive(appointment),
            key: appointment.compositeId,
            appointment: appointment,
            onClick: _ctx.selected,
            clickable: ""
          }, null, 8, ["active", "appointment", "onClick"]))
        }), 128)),
        _createElementVNode("p", {
          class: "mha text separator",
          id: "separator",
          style: _normalizeStyle(_ctx.previousVisible)
        }, _toDisplayString(this.$mhat("AppointmentsFeed.PreviousAppointmentsTitle", {numberOfPreviousAppointments: _ctx.previousAppointments.length})), 5),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previousAppointments, (appointment) => {
          return (_openBlock(), _createBlock(_component_AppointmentCard, {
            pattern: _ctx.SelectionCardColorPattern.FADED,
            active: _ctx.isActive(appointment),
            key: appointment.compositeId,
            appointment: appointment,
            clickable: "",
            onClick: _ctx.selected,
            previous: ""
          }, null, 8, ["pattern", "active", "appointment", "onClick"]))
        }), 128))
      ])
    ]),
    _: 2
  }, [
    (_ctx.isMobile)
      ? {
          name: "content-header",
          fn: _withCtx(() => [
            _createVNode(_component_AppointmentsHeader)
          ])
        }
      : undefined
  ]), 1024))
}