
	export default {
		name: "TypingLoader",

		props: {
			loading: Boolean,
			hide: Boolean,
			absolute: Boolean,
			top: String,
		},

		computed: {
			visibility()
			{
				if (this.hide)
				{
					return {
						visibility: this.loading ? "visible" : "hidden",
					};
				}
				return null;
			},
		},
	};
