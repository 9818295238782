import {Moment} from "moment";
import {BannerNotificationType} from "@/lib/notification/model/BannerNotificationType";

export default class BannerNotification
{
	private readonly _title: string;
	private readonly _message: string;
	private readonly _type: BannerNotificationType;
	private readonly _notificationDatetime: Moment;
	private readonly _action: () => void;
	private readonly _actionTitle: string;

	// ==========================================================================
	// Public Methods
	// ==========================================================================

	/**
	 * create new banner notification
	 * @param title - notification title
	 * @param message - notification message
	 * @param type - the type of this notification
	 * @param action -  [optional] action to be invoked when notification clicked.
	 * @param actionTitle -  [optional]  title to show on the action button.
	 * @param notificationDatetime - [optional] time to which the notification pertains. Used to decide order of notifications can be null.
	 */
	public constructor(title: string,
	                   message: string,
	                   type: BannerNotificationType,
	                   action: () => void = null,
	                   actionTitle: string = null,
	                   notificationDatetime: Moment = null)
	{
		this._title = title;
		this._message = message;
		this._action = action;
		this._actionTitle = actionTitle;
		this._type = type;
		this._notificationDatetime = notificationDatetime;
	}

	/**
	 * compare this banner notification with another one.
	 * returning whether this notification appears before or after (use in array.sort())
	 * @param other - notification to compare against
	 * @return - ordering of this notification when compared to the other
	 */
	public diff(other: BannerNotification)
	{
		if (!this.notificationDatetime && !other.notificationDatetime)
		{
			return 0;
		}
		else if (!this.notificationDatetime)
		{
			return 1;
		}
		else if (!other.notificationDatetime)
		{
			return -1;
		}
		else
		{
			return this.notificationDatetime.diff(other.notificationDatetime);
		}
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get isAppointmentType(): boolean
	{
		return this._type === BannerNotificationType.Appointment;
	}

	get title(): string
	{
		return this._title;
	}

	get message(): string
	{
		return this._message;
	}

	get type(): BannerNotificationType
	{
		return this._type;
	}

	get action(): () => void
	{
		return this._action;
	}

	get actionTitle(): string
	{
		return this._actionTitle;
	}

	get notificationDatetime(): Moment
	{
		return this._notificationDatetime;
	}
}
