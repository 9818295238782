import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29ceaf33"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "virtual-care-banner" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "message" }
const _hoisted_5 = { class: "slot-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhitelabelPrimaryLogo = _resolveComponent("WhitelabelPrimaryLogo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WhitelabelPrimaryLogo, { class: "logo-constraints" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.titleText), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.messageText), 1),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}