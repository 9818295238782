
	import {Options, Vue} from "vue-property-decorator";
	import {IonChip, IonLabel} from "@ionic/vue";

	@Options({
		emits: ["click"],
		components: {IonChip, IonLabel},
	})
	export default class AppChipBase extends Vue
	{
		/** Style with CSS variables
		 *  --app-chip-text-color - The color of the text
		 *  --app-chip-background-color - the color of the app chip background
		 *  --app-chip-background-hover-color - like background color but for when the element is hovered
		 *  --app-chip-border-color - the color of the app chip border
		 *  --app-chip-border-hover-color - like border color but for when the element it hovered
		 *  --app-chip-border-style - the style of the app-chip border
		 */
	}
