export enum ClinicSearchType
{
	CONNECTED = 1,
	UNCONNECTED,
}

export interface EventStateSpinnerModalProps
{
	isLoading: boolean;
	loadingIcon?: string;
	loadingTitle?: string;
	loadingMessage?: string | string[];

	successIcon?: string;
	successTitle?: string;
	successMessage?: string | string[];

	showFailureState?: boolean;
	failureIcon?: string;
	failureTitle?: string;
	failureMessage?: string | string[];
	defaultCancelAction?: () => void;

	containerClass?: string;
}
