

	import {Options, Vue} from "vue-property-decorator";
	import ReviewCard from "@/views/public/whitelabel/components/ReviewCard.vue";

	import imgReviewerA from "@/assets/images/graphics/odb/reviewers/reviewer-A.png";
	import imgReviewerB from "@/assets/images/graphics/odb/reviewers/reviewer-B.png";
	import imgReviewerC from "@/assets/images/graphics/odb/reviewers/reviewer-C.png";

	@Options({components: {ReviewCard}})
	export default class ReviewCards extends Vue
	{
		public imgReviewerA = imgReviewerA;
		public imgReviewerB = imgReviewerB;
		public imgReviewerC = imgReviewerC;

		get reviewTextA()
		{
			return "“The app allows me to see my doctor sooner than if I had to make time to physically attend an appointment." +
				" I can even contact my doctor from work on my lunch break!”";
		}

		get reviewTextB()
		{
			return "“I was surprised at how easy it was to sign up and get an appointment right away." +
				" I have seen a few different doctors and found them to be responsive." +
				" I felt at ease and motivated to take charge of my health with CloudMD.”";
		}

		get reviewTextC()
		{
			return "“My experience with telemedicine was great." +
				" My GP wasn’t available until the end of the month and I needed my prescriptions renewed." +
				" I found it very efficient.”";
		}
	}
