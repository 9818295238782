export default class DOMUtils
{
	public static offset(el: HTMLElement): { top: number, left: number }
	{
		const rect = el.getBoundingClientRect();
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

		return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
	}
}
