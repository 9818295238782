import CallbackCollection from "@/lib/utils/CallbackCollection";

/**
 * @deprecated Dont use the event bus. Only for old code compatibility.
 * When writing new code you should not need to depend on global event bus. Perhaps try a store.
 */
export class EventBus
{
	protected static eventListenerMap: Map<string, CallbackCollection> = new Map();

	// ==========================================================================
	// Vue life cycle
	// ==========================================================================

	/**
	 * emit an event to all listeners
	 * @param event - the event type to emit
	 * @param data - the data to pass to listeners
	 */
	public static $emit(event: string, data?: any): void
	{
		if (this.eventListenerMap.has(event))
		{
			this.eventListenerMap.get(event).call(data);
		}
	}

	/**
	 * register a listener for a specific type of event.
	 * @param event - the event to listen for
	 * @param callback - the callback to call when the event is triggered
	 */
	public static $on(event: string, callback: (data?: any) => void)
	{
		if (!this.eventListenerMap.has(event))
		{
			this.eventListenerMap.set(event, new CallbackCollection());
		}

		this.eventListenerMap.get(event).addCallback(callback);
	}
}

export enum LoginEvent
{
	LoginSuccess = "LoginSuccess",
	LoginFailure = "LoginFailure",
}

export enum InboxEvent
{
	MailSelect = "MailSelect",
	NewController = "NewController",
}

export enum ClinicEvent
{
	ClinicSelect = "ClinicSelect",
	DELETE = "ClinicDelete",
	CONNECT = "ClinicConnect",
}
